import axios from 'axios'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDialogActionContext } from '../../context/dialog.context'
import { getUserEmail } from './userinfo'
import { createActivityLog } from '../../utils/apiCall'
import { getLogData } from '../../helpers/common'
import { useCriteriaContext } from "../../context/criteria.context";
import { logout } from './Login'
import {compressAndEncode, compressAndEncode_pass} from '../model/utils'
//https://react-bootstrap.github.io/forms/validation/

const ChangePassword = (props) => {
    const [show, setShow] = useState(true)
    const dialogActionContext = useDialogActionContext()
    const criteria = useCriteriaContext()

    // const [pwdData, setPwdData] = useState({ pwd: '', rePwd: '' })
    // const [pwd, setPwd] = useState('')
    // const [rePwd, setRePwd] = useState('')

    const [ form, setForm ] = useState({})
    const [ errors, setErrors ] = useState({})
    const setField = (field, value) => {
        setForm({
          ...form,
          [field]: value
        })
        // console.log('handle change ', field, value, errors, form)
        // Check and see if errors exist, and remove them from the error object:
        if ( !!errors[field] ) setErrors({
            ...errors,
            [field]: null
        })
  
      }

    // const [userInfo, setUserInfo] = useState(getUserInfo())
    // const [validated, setValidated] = useState(false);

    const findFormErrors = () => {
        const { pwd, repwd, currentPwd} = form
        const newErrors = {}
        // Password errors
        if (!currentPwd || currentPwd === '') {
            newErrors.currentPwd = 'Current Password cannot be blank!';
        }
        if ( !pwd || pwd === '' ) newErrors.pwd = 'Password cannot be blank!'
        else if ( pwd.length < 8 ) newErrors.pwd = 'Password is less than 8 chars!'
        else if ( pwd.length > 15 ) newErrors.pwd = 'Password is more than 15 chars!'
        // Confirm Password errors
        if ( !repwd || repwd === '' ) newErrors.repwd = 'Confirm password cannot be blank'
        else if (repwd !== pwd)  newErrors.repwd = 'Confirm password does not match Password'
        if (pwd === currentPwd) newErrors.pwd = 'New password cannot be the same as the current password'
    
        return newErrors
    }
    const handleSubmit = e => {
        e.preventDefault()
        const { currentPwd, pwd, repwd } = form;
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
          // We got errors!
          setErrors(newErrors)
        } 
        else {
            const email = getUserEmail();
            // Check if the current password is correct
            axios.post('/auth/login', { email: email, password: compressAndEncode_pass(currentPwd) })
                .then(response => {
                    if (response && response.status === 200) {
                        // Proceed with changing the password
                        requestPasswordChange(pwd);
                        createActivityLog(getLogData(criteria, { featureId: `change-user-password`, usecase: `change user password`, category: 'change-password' }));
                        } 
                    })
                .catch(error => {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        currentPwd: 'Current Password is incorrect'
                    }));
                });
            }
      }
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const requestPasswordChange = () => {
        console.log(form)
        const { pwd, repwd,currentPwd} = form

        // const email = userInfo ? userInfo.user.email : ''
        const email = getUserEmail()
        if (pwd) {

            const data = JSON.stringify({
                email: email,
                password: pwd,
            })
            return axios({
                method: 'POST',
                url: '/auth/changepwd',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                data,
            })
                .then((res) => {
                    dialogActionContext.closeDialog()
                    alert('Password changed successfully.\nYou will be logged off from your active session shortly.\nPlease login again with your new password.');
                    logout()
                })
                .catch((err) => {
                    alert('Some error occured')
                    dialogActionContext.closeDialog()
                })
        }

    }
    /*
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
    
        setValidated(true);
    };*/
    /*
    const handleChange = (e) => {
        setPwdData({ ...pwdData, [e.target.name]: e.target.value })
    }
*/
/*
    useEffect(() => {
        let data = localStorage.getItem('user')
        if (data) {
            data = JSON.parse(data)
            setUserInfo(data)
        }
    }, [])
*/
    const FormInput = ({id, type, inputId, label}) => {
        console.log(id, type, inputId, label)
        return (
            <Form.Group controlId={id} className="pb-4" >
                <Form.Label>{label}</Form.Label>
                <Form.Control
                    onChange={ e => setField(inputId, e.target.value) }
                    type={type}
                    placeholder={label}
                    isInvalid={ !!errors[inputId] }

                />
                <Form.Control.Feedback type='invalid'>
                    { errors[inputId] }
                </Form.Control.Feedback>
            </Form.Group>
        )
    }
    return (
        <Form className="px-4 py-4" onSubmit={handleSubmit} >
        {/* <FormInput id="formPassword1" type="password"  inputId="pwd"  label="Enter Password" />
        <FormInput id="formPassword2" type="password"  inputId="repwd" label="Re-Enter Password" /> */}
             <Form.Group controlId="formPassword0" className="pb-4">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                    onChange={e => setField("currentPwd", e.target.value)}
                    type="password"
                    placeholder="Enter Current Password"
                    isInvalid={!!errors.currentPwd}
                />
                <Form.Control.Feedback type='invalid'>
                    {errors.currentPwd}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPassword1" className="pb-4" >
                <Form.Label>Enter Password</Form.Label>
                <Form.Control
                    onChange={ e => setField("pwd", e.target.value) }
                    type="password"
                    placeholder="Enter Password"
                    isInvalid={ !!errors.pwd }

                />
                <Form.Control.Feedback type='invalid' className="text-white h6">
                    { errors.pwd }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPassword2" className="pb-4" >
                <Form.Label>ReEnter Password</Form.Label>
                <Form.Control
                    onChange={ e => setField("repwd", e.target.value) }
                    type="password"
                    placeholder="Confirm Password"
                    isInvalid={ !!errors.repwd }

                />
                <Form.Control.Feedback type='invalid' className="text-white h6">
                    { errors.repwd }
                </Form.Control.Feedback>
            </Form.Group>

        <Button style={{background: '#01668D', color: 'white', position: 'relative', left: '85%'}} variant="float-right" onClick={handleSubmit} type='submit'> Save </Button>
        </Form>

    )
    
}

export default ChangePassword;