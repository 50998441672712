import metric_type_data from "./metric_type_data"
import { Color } from 'three'

const color1 = "#EE74E1"
const color2 = "#2EB785"

const minColor = new Color(color1)
const maxColor = new Color(color2)

const encodedColors = [
  'red',
  'green',
  'blue',
  'maroon',
  'brown',
  'olive',
  'teal',
  'navy',
  'orange',
  'yellow',
  'lime',
  'cyan',
  'purple',
  'magenta',
  'pink',
  'burlywood',
  'darkgray',
  'blueviolet',
  'skyblue',
  'gray',
]

const modes = {
  0: 'IDLE',
  1: 'MANUAL',
  2: 'WIND-STOW',
  3: 'SNOW-STOW',
  4: 'CLEAN-STOW',
  5: 'NIGHT-STOW',
  6: 'EMERGENCY-STOW',
  7: 'COMMERROR-STOW',
  8: 'AUTO',
  9: 'CYCLE-TEST',
  10: 'UNKNOWN',
  11: 'HAIL-STOW',
  12: 'AROM',
  13: 'WLOCK_STOW',
  14: 'WLOCK_REL_STOW',
  15: 'RSTMANUAL',
  16: 'LOCK-CALIB-MODE',
  17: 'LOWBAT-STOW'
}

const trackingModes = {	
  0	: "initialization",
  1: "tracking",
  2	: "antishading",
  3	: "overnight position",
  4	: "smart stow",
  5	: "manual stow overwrite",
}

const motor = {
  "1": 'Positive Rotation',
  "0": 'Off',
  "-1": 'Negative Rotation'
}

const errorCodes = {
  0: 'NO FAULT',
  1: 'Over Current Fault',
  2: 'Minimum Battery Voltage fault',
  4: 'Board Temperature Exceed fault',
  6: 'Motor Stall Fault',
  7: 'Zigbee Fault',
  8: 'Communication Fault',
  9: 'Inclinometer Fault',
  10: 'SPI Flash Fault',
  11: 'OTA Fault',
  12: 'EEPROM Fault',
  13: 'RTC Failure',
  14: 'Unknown Fault',
  15: 'Locking System Fault',
  16: 'Locked Moved Fault',
  17: 'Low Battery Stow Fault',
  18: 'Mechanical Overload Fault',
  33: 'ZC Blocked State',
  34: 'Set Command Flag Enabled',
  35: 'Mechanical Overload Occurred',
  19: 'Battery Charger Fault',
  20: 'Estop Fault',
  21: 'BLE Fault',
  22: 'External EEPROM Fault',
  23: 'Low temperature fault',
  36: 'Input Ac power not detected',
  37: 'Heating pad not detected',
  38: 'Energy Save State'
}

const errorCodesGC = {
  0: 'undefined 0',
  1: 'undefined 1',
  2: 'undefined 2',
  3: 'undefined 3',
  4: 'undefined 4',
  5: 'not charging',
  6: 'no data available',
  7: 'battery overvoltage',
  8: 'moved wrong direction',
  9: 'no motion',
  10: 'battery critical stow',
  11: 'battery low stow',
  12: 'overcurrent',
  13: 'e stop pressed',
  14: 'comm loss'
}

const getColorFromEncodings = (encodings, defaultColors) => {
  return encodings.reduce((acc, curr) => {
    if (curr.color) {
      if (curr.value === "0") 
        acc.color1 = curr.color
      if (curr.value === "1")
        acc.color2 = curr.color
    }

    return acc
  }, defaultColors)
}

const processBinaryMetric = (metricValue, color, encodings) => {
  let colors = { color1: 'green', color2 : 'red'}
  if (encodings) {
    colors = getColorFromEncodings(encodings, colors)
  }

  if (metricValue === 0) {
    color.set(colors.color1)
  } else if (metricValue === 1) {
    color.set(colors.color2)
  } else {
    color.setRGB(0, 0, 0)
  }
}


const processDynamicMetric = (metricValue, range, color, minMaxValue, encodings) => {
  const minValue = minMaxValue ? minMaxValue.minValue : Math.min(...range)
  const maxValue = minMaxValue ? minMaxValue.maxValue : Math.max(...range)

  let colors =   { color1, color2 }
  if (encodings) {
    colors = getColorFromEncodings(encodings, colors)
  }

  const minColor = new Color(colors.color1)
  const maxColor = new Color(colors.color2)

  const gradient = (metricValue - minValue) / (maxValue - minValue)
  color.lerpColors(minColor, maxColor, gradient)
}

const processEncodedMetric = (metricValue, range, color, encodings) => {
  const index = encodings ? encodings.map(t => t.value).indexOf(metricValue.toString()) : range.indexOf(metricValue)
  const colorValue = index < 0 ? 'black' : encodings ? encodings[index].color || encodedColors[index] : encodedColors[index]
  color.set(colorValue)
}

const processEpochMetric = (metricValue, minMaxValue, color) => {
  const minValue = minMaxValue.minValue
  const maxValue = minMaxValue.maxValue 

  const gradient = (metricValue - minValue) / (maxValue - minValue)
  color.lerpColors(minColor, maxColor, gradient)
}

const metricDecode = (metricName, metricValue, encodings) => {
  if (metricValue === undefined || metricValue === null)
    return "-"
  if (encodings) {
    const encoding = encodings.filter(e => e.value === metricValue.toString())
    return encoding.length > 0 ? encoding[0].label : metricValue.toString()
  }
  if (metricName === 'currentMode:SR')
    return modes[metricValue]
  else if (metricName === 'motor:SR')
    return motor[metricValue.toString()]
  else if (metricName === 'errorCode:SR')
    return errorCodes[metricValue]
  else 
    return metricValue
}

const getLabel = (metricName, metricValue, metricType, encodings) => {
  const metricDetails = metricType ? { valueType: metricType } : metric_type_data[metricName]
  if (metricDetails) {
    switch (metricDetails.valueType) {
      case "Binary":
        return metricValue === 0 ? 'No' : metricValue === 1 ? "Yes" : "-"
      case "Dynamic":
        return metricValue !== null && metricValue !== undefined ? metricValue.toFixed(2) : "-"
      case "Encoded":
        return metricDecode(metricName, metricValue, encodings)
      case "Epoch":
        return metricValue !== null && metricValue !== undefined ? metricValue : "-"
    }
  } else {
    return ''
  }
}

const getLabels = (metricName, minMaxValue, metricData, selectedDevices, metricType, encodings) => {
  const metricDetails = metricType ? { valueType: metricType, range: encodings ? encodings.map(t => t.value) : [] } : metric_type_data[metricName]
  let summary = []
  let colors = {}
  if (metricDetails && metricName) {
    switch (metricDetails.valueType) {
      case "Binary":
        summary = getSummaryBinary(metricData, selectedDevices)
        colors = { color1: 'green'   , color2: 'red'}
        if (encodings)
          colors = getColorFromEncodings(encodings, colors)
        return {
          type: 'Fixed',
          value: [
            { label: 'No', color: colors.color1, count: summary.filter(t => t.label === 'No').length > 0 ?  summary.filter(t => t.label === 'No')[0].value : 0 },
            { label: 'Yes', color: colors.color2, count: summary.filter(t => t.label === 'Yes').length > 0 ?  summary.filter(t => t.label === 'Yes')[0].value : 0 }
          ]
        }
      case "Dynamic":
        const minValue = minMaxValue ? minMaxValue.minValue : Math.min(...metricDetails.range)
        const maxValue = minMaxValue ? minMaxValue.maxValue : Math.max(...metricDetails.range)
        colors =   { color1, color2 }
        if (encodings)
          colors = getColorFromEncodings(encodings, colors)
        
        return {
          type: 'Varying', 
          value: [
            { label: minValue, color: colors.color1 },
            { label: maxValue, color: colors.color2 }
          ]
        }
      case "Encoded":
        summary = getSummaryEncoded(metricData, selectedDevices)
        const labels = metricDetails.range.map((val, i) => ({ 
          label: metricDecode(metricName, val, encodings), 
          color: encodings ? encodings[i].color ||  encodedColors[i] : encodedColors[i],
          count: summary.filter(t => t.label === val.toString()).length > 0 ? summary.filter(t => t.label === val.toString())[0].value : 0
        })).sort((a, b) => a.count < b.count ? 1 : -1)
        return {
          type: 'Fixed',
          value: labels
        }
      case "Epoch":
        return {
          type: 'Epoch', 
          value: [
            { label: minMaxValue.minValue, color: color1 },
            { label: minMaxValue.maxValue, color: color2 }
          ]
        }
    }
  } else {
    return null
  }
}


const processMetricData = (metricName, metricData, deviceId, color, minMaxValue, metricType, encodings) => {
    const metricValue = metricData[deviceId]
    
    if (metricValue !== undefined && metricValue !== null) {
      const metricDetails = metricType ? { valueType: metricType } : metric_type_data[metricName]
      if (metricDetails) {
        switch (metricDetails.valueType) {
          case "Binary":
            processBinaryMetric(metricValue, color, encodings)
            break ;
          case "Dynamic":
            processDynamicMetric(metricValue, metricDetails.range, color, minMaxValue, encodings)
            break ;
          case "Encoded":
            processEncodedMetric(metricValue, metricDetails.range, color, encodings)
            break ;
          case "Epoch":
            processEpochMetric(metricValue, minMaxValue, color)
            break ;
      }
      } else {
        color.setRGB(0, 0, 0)
      }
    } else {
      color.setRGB(0, 0, 0)
    } 
}

const processMetricData2 = (metricData, deviceId, color) => {
    const metricValue = metricData[deviceId]
    if (metricValue !== undefined) {
      if (metricValue < 8) {
        color.setRGB(1, 0, 0)
      } else if (metricValue === 8) {
        color.setRGB(0, 1, 0)
      } else if (metricValue > 8) {
        color.setRGB(0, 0, 1)
      }
    } else {
      color.setRGB(1, 1, 0)
    } 
}


const getSummaryBinary = (metricData, selectedDevices) => {
  const metrics = metricData ? metricData.metrics: {}
  const devices = selectedDevices.length > 0 ? selectedDevices : Object.keys(metrics)
  const summary = devices.reduce((acc, curr) => {
    const value = metrics[curr]
    if (value === 1) {
      acc[0].value++
    } else if (value === 0) {
      acc[1].value++
    }

    return acc
  }, [{"label": "Yes","value": 0}, {"label": "No","value": 0}])

  return summary
}

const getSummaryDynamic = metricData => {
  const metrics = metricData ? metricData.metrics: {}
  const devices = Object.keys(metrics)
  const summary = devices.reduce((acc, curr) => {
    const value = metrics[curr]
    if (value !== null && value !== undefined)
    if (value >= 50) {
      acc[0].value++
    } else {
      acc[1].value++
    }

    return acc
  }, [{"label": "Above mean","value": 0}, {"label": "Below mean","value": 0}])

  return summary
}

const getSummaryEncoded = (metricData, selectedDevices) => {
  const metrics = metricData ? metricData.metrics: {}
  const devices = selectedDevices.length > 0 ? selectedDevices : Object.keys(metrics)
  const summary = devices.reduce((acc, curr) => {
    const value = metrics[curr]
    if (value !== null && value !== undefined)
    if (acc.filter(t => t.label === value.toString()).length > 0)
      acc = acc.map(t => t.label === value.toString() ? {label: t.label, value: t.value + 1}: t)
    else 
      acc.push({label: value.toString(), value: 1})

    return acc
  }, []).sort((a, b) => a.value > b.value ? -1 : 1)

  return summary
}

const getSummary = (metricName, metricData) => {
  if (metricData) {
    const metricDetails = metric_type_data[metricName]
    if (metricDetails) {
      switch (metricDetails.valueType) {
        case "Binary":
          return getSummaryBinary(metricData)
        case "Dynamic":
          return getSummaryDynamic(metricData)
        case "Encoded":
          return getSummaryEncoded(metricData)
        case "Epoch":
          return getSummaryDynamic(metricData)
      }
    }
  }

  return []
}

export {
  processMetricData,
  getLabels,
  getLabel,
  getSummary,
  modes,
  errorCodes,
  trackingModes,
  errorCodesGC
}
