import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './table.css';
import StackedChart2 from './stackedchartindividual';
import StackedChart1 from './stackednarcharttotal';
import {AiOutlineDownload} from 'react-icons/ai'


// Functional Component: SessionTable
const SessionTable = ({ sessionData, refresh, setRefresh }) => {
  // State variables for individual session data and total session data
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  // useEffect hook to update data1 when individual session data changes
  useEffect(() => {
    setData1(sessionData.individual_session_times || []);
  }, [sessionData.individual_session_times, refresh]);

  // useEffect hook to update data2 when total session data changes
  useEffect(() => {
    setData2(sessionData.total_time_of_all_sessions || []);
  }, [sessionData.total_time_of_all_sessions, refresh]);

  // Function to handle CSV download for Individual Session Times
  // Function to handle CSV download for Total Time of All Sessions
  const downloadTable = (tableId, data, columns) => {
    const table = document.getElementById(tableId);
    const csvData = [columns.map((column) => column.text)].concat(
      data.map((row) => columns.map((column) => row[column.dataField]))
    );
  
    // Trigger download using CSVLink
    const csvLink = document.createElement('a');
    const csvBlob = new Blob([csvData.map((row) => row.join(',')).join('\n')], {
      type: 'text/csv;charset=utf-8',
    });
    csvLink.href = URL.createObjectURL(csvBlob);
    csvLink.download = `${tableId}_data.csv`;
    csvLink.click();
  };

  // Columns for React Bootstrap Table
  const columns1 = [
    { dataField: 'user_id', text: 'Email ID' },
    { dataField: 'session_counter', text: 'Session Counter' },
    { dataField: 'session_time', text: 'Session Time (seconds)' },
    { dataField: 'start_date', text: 'Date' },
    { dataField: 'start_time', text: 'Start Time' },
    { dataField: 'end_time', text: 'End Time' },
  ];

  const columns2 = [
    { dataField: 'user_id', text: 'Email ID' },
    { dataField: 'start_date', text: 'Date' },
    { dataField: 'number_of_sessions', text: 'Number of Sessions' },
    { dataField: 'total_time', text: 'Total Time (seconds)' },
  ];
  
  // Pagination options for React Bootstrap Table
  const options = {
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };

  return (
    <div>
      {data1.length > 0 ? (
        <div>
          <div>
          <h2>Individual Session Times</h2>
          <div class = "canvas-container">
          <StackedChart2 data={data1}/>
          </div>
        </div>
                 
        <div style={{ position: 'relative', marginTop: '30px' }}>
        <BootstrapTable
              id="Individual Session Times"
              data={data1.map((row, index) => ({ ...row, id: index + 1 }))}
              columns={columns1}
              keyField="id"
              pagination={paginationFactory({ ...options, page: 1 })}
                  />
           <button
              onClick={() => downloadTable('Individual Session Times', data1, columns1)}
              style={{
                position: 'absolute',
                top: '-19px',
                right: '12px',
                margin: '18px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '25px',
                color: '#004059'
              }}
            >
              <AiOutlineDownload />
            </button>
        </div>
        </div>
      ) : (
        <div>
          <p>"Select or change the Start Date and End date"</p>
        </div>
      )}

      {data2.length  > 0 ? (
        <div>
          <div>
          <h2>Total Time of All Sessions</h2>
          <div class = "canvas-container">
          <StackedChart1 data={data2}/>
        </div>
        </div>
        <div style={{ position: 'relative', marginTop: '30px' }}>
          {/* Move the BootstrapTable outside of the row */}
          <BootstrapTable
              id="Total Time of All Sessions"
              data={data2.map((row, index) => ({ ...row, id: index + 1 }))}
              columns={columns2}
              keyField="id" // Use 'id' as the keyField
              pagination={paginationFactory({ ...options, page: 1 })}
            />
           <button
              onClick={() => downloadTable('Total Time of All Sessions', data2, columns2)}
              style={{
                position: 'absolute',
                top: '-10px',
                right: '20px',
                margin: '10px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '25px',
                color: '#004059'
              }}
            >
              <AiOutlineDownload />
            </button>
        </div>
        </div>
      ) : null}

      {sessionData.overall_average_time_of_all_sessions ? (
        <div>
          <h2>Overall Average Time of All Sessions: <span style={{ display: 'inline-block' }}><h5>{sessionData.overall_average_time_of_all_sessions} seconds</h5></span></h2>
        </div>
      ) : null}
    </div>
  );
};

export default SessionTable;
