import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import './diffuse.css';
import { getCrumbsData } from './crumbsData';
import { domainsByCriteria } from '../layout/criteria';
import { useCriteriaContext } from '../../context/criteria.context';
import SingleSelectDropDownControl from './singleSelectDropdown';
import MultiSelectDropDownControl from './multiSelectDropdown';
import { postJSONData } from "../model/store.swr";
import { isAdminUser } from "../auth/userinfo";

// Styled component for displaying error messages
const Error = styled.span`
  padding: 0px;
  padding-top: 30px;
  color: red;
`;

// Styled components for custom radio buttons
const CustomRadioWrapper = styled.div` 
  display: flex;
  flex-direction: column;
`;

const CustomRadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 13px;
`;

const CustomRadioInput = styled.input`
  appearance: none;
  background-color: #fff;
  border: 2px solid #01668D;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-right: 10px;

  &:checked::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #01668D;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const DisableOptimization = ({diffuseStatus}) => { 
  const criteria = useCriteriaContext();
  const domainArray = domainsByCriteria(criteria);
  const siteValueIndex = domainArray.indexOf('site');
  const siteValue = siteValueIndex !== -1 ? criteria.site : null;
  const portfoliosIndex = domainArray.indexOf('pf');
  const portfoliosValue = portfoliosIndex !== -1 ? criteria.pf : null;

  const [zones, setZones] = useState([]);
  const [devices, setDevices] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedOption, setSelectedOption] = useState('device_id');
  const [trackerIdAvailable, setTrackerIdAvailable] = useState(true); 
  const [error, setError] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const isAdmin = isAdminUser();

    // Fetch zones when portfolios or site values change
  useEffect(() => {
    const payload = {portfolios: '', user: '', pf: portfoliosValue, site: siteValue};
    getCrumbsData(payload, setZones, setError);
    setSelectedZone(null); 
    setSelectedDevices([]);
 }, [portfoliosValue, siteValue]);

   // Fetch devices or trackers based on selected zone and option
  useEffect(() => {
    if (selectedZone) {
      const payload = {
        site_id: siteValue,
        zone_id: selectedZone.value,
        selectedOption: selectedOption,
      };

      console.log('Data submitted:', payload);
      const apiUrl = "/meta?querytype=meta_diffuse_optimization&restype=json"; 
      postJSONData(apiUrl, payload, (response) => {
        console.log('Server response:', response);

        if (!response || response === 0 || response.status === 'completed') {
          console.error('Empty or null response received');
          setDevices([]);
          setTrackers([]);
          return;
        }

        const devicesData = response.map(device => ({
          value: device.device_id,
          label: device.device_id,
          isDisabled: device.diffuse_disabled,
        }));
        console.log('Devices:', devicesData);
        const trackersData = response.map(device => ({
          value: device.tracker_id,
          label: device.tracker_id,
          isDisabled: device.diffuse_disabled,
        }));
        console.log('Trackers:', trackersData);
        setDevices(devicesData);
        setTrackers(trackersData);

                if (trackersData.some(track => track.value === null)) {
                  setTrackerIdAvailable(false); 
                  setError('Tracker ID details is not available in the SUNOPS database.');
                } else {
                  setTrackerIdAvailable(true);
                  setError('');
                }        

        if (selectedOption === 'device_id') {
          setSelectedDevices(devicesData.filter(device => device.isDisabled));
        } else if (selectedOption === 'tracker_id') {
          setSelectedDevices(trackersData.filter(tracker => tracker.isDisabled));
        }
      });
    }

  }, [selectedOption, selectedZone]);

    // Handle zone change
  const handleZoneChange = (selectedOption) => {
    if (isAdmin) {
    setSelectedZone(selectedOption);
    }
  };

    // Handle devices change
  const handleDevicesChange = (selectedOptions) => {
    if (isAdmin) {
    setSelectedDevices(selectedOptions);
    setIsChanged(true);
    }
  };

    // Handle option change
  const handleOptionChange = (e) => {
    if (isAdmin) {
    setSelectedOption(e.target.value);
    setError(''); 
    setSelectedDevices([]);
    }
  };

  const handleSave = () => {
    const payload = {
      site_id: siteValue,
      zone_id: selectedZone ? selectedZone.value : null,
      selectedOption: selectedOption,
      devices: (selectedOption === 'device_id' ? devices : trackers).map(device => ({
        id: device.value,
        isSelected: selectedDevices.some(selectedDevice => selectedDevice.value === device.value),
      })),
    };

    console.log('Data submitted:', payload);
    const apiUrl = "/meta?querytype=meta_diffuse_disable_devices&restype=json"; 
    postJSONData(apiUrl, payload, (response) => {
      console.log('Server response:', response);
    });
      const data = {
        site_id: siteValue,
        diffuse_status: diffuseStatus,
        }
    postJSONData("/diffuse-pulsar-data?restype=json",data,(res) => {console.log("checking response",res)})
    setIsChanged(false);
  };

  const handleCancel = () => {
    if (selectedOption === 'device_id') {
      setSelectedDevices(devices.filter(device => device.isDisabled));
    } else if (selectedOption === 'tracker_id') {
      setSelectedDevices(trackers.filter(tracker => tracker.isDisabled));
    }
    setIsChanged(false);
  };

  return (
    <>
        <div className="col-6 pl-0">
        <div className="chart-card1">
          <div className="chart-card-header1">
            <h5 style={{ fontWeight: 600, fontSize: '18px', lineHeight: '15px', color: 'rgb(76, 67, 69)', padding: '10px 0px 10px 6px' }}>
              Disable Irradiance Optimization
            </h5>
          </div>
          <Row></Row>
          <Row></Row>
          <div className="chart-card-body1">
          <Row>
                <Col md={12}>
                  {error && <Error>{error}</Error>}
                </Col>
            </Row>
            <Form>
              <Form.Group as={Row}>
              <Form.Label className="card-title" column sm={5}>
                  Zone(s)
                </Form.Label>
                <Col sm={6}>
                  <SingleSelectDropDownControl
                    options={zones}
                    value={selectedZone}
                    onChange={handleZoneChange}
                  />
                </Col>
                <Col sm={1}></Col>
                </Form.Group>

                <Form.Group as={Row}> 
                <Form.Label className="card-title" column sm={5}>
                  Select Options
                </Form.Label>
                <Col sm={6}>
                  <CustomRadioWrapper>
                    <CustomRadioLabel>
                      <CustomRadioInput
                        type="radio"
                        name="selectOption"
                        value="device_id"
                        checked={selectedOption === 'device_id'}
                        onChange={handleOptionChange}
                      />
                      Device ID
                    </CustomRadioLabel>
                    <CustomRadioLabel>
                      <CustomRadioInput
                        type="radio"
                        name="selectOption"
                        value="tracker_id"
                        checked={selectedOption === 'tracker_id'}
                        onChange={handleOptionChange}
                      />
                      Tracker ID
                    </CustomRadioLabel>
                  </CustomRadioWrapper>
                </Col>
                <Col sm={1}></Col>
              </Form.Group>

              {trackerIdAvailable && ( // Conditionally render based on tracker_id availability
                <Form.Group as={Row}>
                  <Form.Label className="card-title" column sm={5}>
                    {selectedOption === 'device_id' ? 'Device(s)' : 'Tracker(s)'}
                  </Form.Label>
                  <Col sm={6}>
                    <MultiSelectDropDownControl
                      options={selectedOption === 'device_id' ? devices : trackers}
                      selectedOptions={selectedDevices}
                      onChange={handleDevicesChange}
                    />
                  </Col>
                </Form.Group>
              )}

              <Row></Row>
              <Row></Row>
                  <Form.Group as={Row}>
                      <Col md={5}></Col>
                            <Col md={6}>
                                <Button className="btn-secondary" onClick={handleSave} style={{ width: '47%' }} disabled={!isChanged} >
                                  Save
                                </Button>
                                <Button className="btn-secondary" style={{ width: '47%', marginLeft: '11px' }} onClick={handleCancel} disabled={!isChanged}>
                                  Cancel
                                </Button>
                             </Col>
                        <Col sm={1}></Col>
                  </Form.Group>
             </Form>
           </div>
         </div>
      </div>
    </>
  );
};

export default DisableOptimization;



