import _ from "lodash";

export function maxOfArray(values) {
    //const values = _.pullAt(arr, [0])
    // console.log(values)
    const idxs = _.zipWith(...values, (...args) => { return _.max(args) })
    // _.pullAt(idxs, ignoreCols);
    // console.log(idxs)
    //.map((e,i) => e === 0? i: -1).filter(f => f > -1);
    return idxs
}

export function sumOfArray(values) {
    //const values = _.pullAt(arr, [0])
    // console.log(values)
    const idxs = _.zipWith(...values, (...args) => { return _.sum(args) })
    // _.pullAt(idxs, ignoreCols);
    // console.log(idxs)
    //.map((e,i) => e === 0? i: -1).filter(f => f > -1);
    return idxs

}

export function stripTimestampData(data, iTimestamp){
    return data.filter((f,i)=> i !== iTimestamp )
}

export function maxPercentages(values) {

    const sum = values.reduce((s,k) => s += k, 0);

    return values.map(k => (k/sum * 100).toFixed(2));

}

export function stripTimestamp(data, iTimestamp){
    const columns = stripTimestampData(data.columns, iTimestamp )
    const values = stripTimestampData(data.values, iTimestamp )
    const indexes = stripTimestampData(data.indexes, iTimestamp )
    return {columns: columns, values: values, indexes: indexes}
}

export function aggregatedData(data, aggr_f){
    const aggdata = aggr_f(data.values)
    return data.columns.map((e, i) => { return {name: e, value: aggdata[i] } })
}
