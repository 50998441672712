import React, { memo, useEffect, useMemo, useState } from "react";
import Select from "react-select"
import styled from 'styled-components';
import { useStoreActionContext } from "../../context/store.context";
import { useUserContext } from "../../context/user.context";
import { customDropDownStyles } from "../controls/multiselect_control";
import VirtualizedSelect from 'react-virtualized-select'


const StyledDiv = styled.div `
    display: inline-block;
    width: 300px; 
    margin: 0px 0px 1px 10px
`

const DomainSearchBox = memo(() => {
    const userContext = useUserContext()
    const storeAction = useStoreActionContext()
    const [page, setPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [domain, setDomain] = useState("");
    const options = useMemo(()=>  userContext.deviceOptions, [userContext.deviceOptions])
    
    useEffect(()=> {
        if (domain ) {
            // console.log("Selected domain ", domain)
            storeAction.setDomain(domain.value)
        }
        
    }, [domain])

    // const customFilter = createFilter({ ignoreAccents: false });
    // const customComponents = {
    //   ClearIndicator: (props) => (
    //     <components.ClearIndicator className="btn-clear" {...props}>Clear</components.ClearIndicator>
    //   )
    // };
    
    const customStyles = useMemo(
        () => (customDropDownStyles), []
    );

    const onInputChange = (value) => {
        setInputValue(value)
        setPage(1)
    }

    const addOptions = () => {
        setIsLoading(true)
        setTimeout(() => {
            setPage(page + 1)
            setIsLoading(false)
        }, 0);
    }
    
    const handleMenuScrollToBottom = () => {
        addOptions()
    }

    const optionsFiltered = inputValue ? options.filter(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) : options
    const optionsPaged = [
        ...optionsFiltered.slice(0, page * 20),
        isLoading ? { value: 'loading', label: 'Loading more...', disabled: true } : {}
      ];

    return (
        <StyledDiv className="search-input">
        { options && options.length ? 

            <VirtualizedSelect
                options={optionsPaged}
                onChange={setDomain}
                onMenuScrollToBottom={handleMenuScrollToBottom}
                styles={customStyles}
                isClearable={true}
                selectComponent={Select}
                inputValue={inputValue}
                onInputChange={onInputChange}
                placeholder={"Search RC / Array / Site"}
            />
        : 
             <VirtualizedSelect
                // options={optionsPaged}
                onChange={setDomain}
                onMenuScrollToBottom={handleMenuScrollToBottom}
                styles={customStyles}
                isClearable={true}
                selectComponent={Select}
                inputValue={inputValue}
                onInputChange={onInputChange}
                placeholder={"Search RC / Array / Site"}
            />
            }
      </StyledDiv>
    );
})



export default DomainSearchBox;