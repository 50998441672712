import React, { useState } from 'react';
import ReactEcharts from "echarts-for-react";

const Chart = ({ data }) => {
    // State variable to manage selected series in the legend
    const [selectedSeries, setSelectedSeries] = useState(['Stow', 'Hail Probability', 'Max. Hail Size']);
    
    if (!data) return null; // If data is not available, return null

    // Destructuring data object to extract necessary properties
    const { formattedTimestamps, actions, hailProbability, maxHailSize, modifiedDirection } = data;

    // Define chart options for stacked line chart with slider and zoom
    const chartOptions = {
        // Tooltip configuration
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                animation: false
            }
        },
        // Legend configuration
        legend: {
            data: ['Hail Probability', 'Stow' , 'Max. Hail Size'],
            selected: {
                'Hail Probability': selectedSeries.includes('Hail Probability'),
                'Stow': selectedSeries.includes('Stow'),
                'Max. Hail Size': selectedSeries.includes('Max. Hail Size'),
            },
            selectedMode: 'multiple',
            itemGap: 30 // Adjust the gap between legend items as needed
        },
        // X-axis configuration
        xAxis: {
            type: 'category',
            data: formattedTimestamps,
            title: {
                display: true,
                text: 'Date & Time',
            },
            axisLabel: {
                rotate: 45 // Rotate the labels at a 45-degree angle
            }
        },
        // Y-axis configuration
        yAxis: [
            {
                type: 'value',
                position: 'right',
                name: 'Max. Hail Size (Inches)',
                max: selectedSeries.includes('Max. Hail Size') ? 5 : undefined, // Set a fixed maximum value of 5 when 'Max. Hail Size' is selected
            },
            {
                type: 'value',
                position: 'left',
                name: 'Hail Probability (%)',
                min: 0, // Min range for Hail Probability
                max: 100, // Max range for Hail Probability
            }
        ],
        // Data zoom configuration
        dataZoom: [
            {
                type: 'slider',
                xAxisIndex: 0,
                start: 0,
                end: 100
            },
            {
                type: 'inside',
                xAxisIndex: 0,
                start: 0,
                end: 100
            },
        ],
        // Series configuration
        series: [
            {
                type: 'line',
                data: hailProbability,
                name: 'Hail Probability',
                stack: 'hailProbability', 
                smooth: true,
                show: selectedSeries.includes('Hail Probability'),
                itemStyle: {
                    color: 'blue'
                },
                areaStyle: {
                    color: 'rgba(0, 0, 255, 0.1)'
                },
                yAxisIndex: 1
            },
            {
                type: 'line',
                data: maxHailSize,
                name: 'Max. Hail Size',
                stack: 'maxHailSize', 
                smooth: true,
                show: selectedSeries.includes('Max. Hail Size'),
                itemStyle: {
                    color: 'green'
                },
                areaStyle: {
                    color: 'rgba(0, 255, 0, 0.1)'
                },
                yAxisIndex: 0 
            },
            {
                type: 'line',
                data: actions, 
                name: 'Stow',
                stack: 'stow',
                smooth: true,
                lineStyle: {
                    opacity: 0 // Set the opacity of the line to zero to make it invisible
                },
                markArea: {
                    silent: true, 
                    data: (() => {
                        const areas = [];
                        let start = null;
                        for (let i = 0; i < actions.length; i++) {
                            if (actions[i] > 0 && start === null) {
                                start = i;
                            } else if (actions[i] === 0 && start !== null) {
                                areas.push([{
                                    xAxis: start
                                }, {
                                    xAxis: i
                                }]);
                                start = null;
                            }
                        }
                        if (start !== null) {
                            areas.push([{
                                xAxis: start
                            }, {
                                xAxis: actions.length - 1
                            }]);
                        }
                        return areas;
                    })(),
                    itemStyle: {
                        color: 'rgba(255, 0, 0, 0.1)'
                    }
                }
            }                    
        ],        
        // Tooltip configuration
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                animation: false,

            },
            // Custom formatter for tooltip
            formatter: function(params) {
                const timestamp = params[0].axisValue;
                let content = timestamp + '<br/>';
                params.forEach(param => {
                    const seriesName = param.seriesName;
                    const value = param.value;
                    let valueSuffix = '';
                    
                    if (seriesName === 'Stow') {
                        content += value === 5 ? 'State: Stow <br/>' : 'State: No Stow <br/>';
                    } else if (seriesName === 'Actions') { // Assuming the action series is named 'Actions'
                        if (value === 'Release') {
                            content += 'State: Release <br/>';
                        } else {
                            content += 'State: ' + value + '<br/>';
                        }
                    } else {
                        if (seriesName === 'Hail Probability') {
                            valueSuffix = ' %';
                        } else if (seriesName === 'Max. Hail Size') {
                            valueSuffix = ' Inches';
                        }
                        content += `${seriesName}: ${value}${valueSuffix}<br/>`;
                    }
                });
            
                // Append modified direction to tooltip if available
                const index = formattedTimestamps.indexOf(timestamp);
                if (index !== -1 && data.modifiedDirection && data.modifiedDirection[index]) {
                    const direction = (data.modifiedDirection[index]);
                    content += `Direction: ${direction}<br/>`;
                }
                return content;
            }
        },
        grid: {
            bottom: 150 // Adjust the bottom margin to create space for the dataZoom
        },
    };    
    
    // Function to handle legend item click
    const handleLegendClick = (legendName) => {
        setSelectedSeries(prevState => {
            if (prevState.includes(legendName)) {
                return prevState.filter(name => name !== legendName);
            } else {
                return [...prevState, legendName];
            }
        });
    };

    return (
        <div>
            <ReactEcharts option={chartOptions} onEvents={{ 'legendselectchanged': (params) => handleLegendClick(params.name) }} style={{ width: "100%", height: "475px" }}/>
        </div>
    );
};

export default Chart;

