import React, { useMemo, useState } from "react";
import nextId from "react-id-generator";
import { useCriteriaContext } from "../context/criteria.context";
import { useDataviewActionContext } from "../context/dataview.context";
import { useUserContext } from "../context/user.context";
import { Accordion } from "../layout/accordian.vertical/accordian";
import { filterMetricsAsPerCriteria, getMetricsAsOptions } from "../model/metadata";
import { getLogData } from "../helpers/common"
import { toJS } from 'mobx';
import { useStore } from "../context/store.context"
import { isDevice, isPortfolio, isSite, isZone } from "../components/layout/criteria";
import {usePageContext} from "../context/page.context"

const MetricLinkItem = (props) => {
    const dataviewaction = useDataviewActionContext()
    const [data] = useState(props.value)
    const c = useCriteriaContext()
    const level = isPortfolio(c) ? 'pf' : isSite(c) ? 'site' : isZone(c) ? 'zone' : isDevice(c) ? 'rc' : {}
    const pageContext = usePageContext()
    //console.log("data.....",data)

    return (
        <h6 className="datalink" key={nextId()}
            // onClick={() =>  props.onSelect(data) }
            onClick={() => dataviewaction.setView(props.value)}
        >{data.title}</h6>
    )
}
const MetricHeadItems = (props) => {
    // const items = props.data.filter(f => f.head === props.head).sort((a, b) => a.title > b.title)
    return (
        <>
            {props.data.map(e => <MetricLinkItem value={e} key={nextId()} />)}
        </>
    )

}
export const MetricsListBaseChart = (props) => {

    const heads = [...new Set(props.data.map(item => item.head))].sort((a, b) => a > b);

    //const finalOption = mergeOptions(props.data)
    //console.log("final metrics " ,props.data)
    return (
        <Accordion open={0}>
            {heads.map(h => {
                const headItems = props.data.filter(f => f.head === h).sort((a, b) => a.title > b.title)
                return (
                    <Accordion.Item key={nextId()}>
                        <Accordion.Header>{h}</Accordion.Header>
                        <Accordion.Body>
                            <MetricHeadItems data={headItems} />
                        </Accordion.Body>
                    </Accordion.Item>
                )
            })}

        </Accordion>
    );
}
const groupDesc = { 'AR': 'RC Alerts', 'AZ': 'ZC Alerts', 'VR': 'RC Data', 'VZ': 'ZC Data', 'SR': 'RC Status', 'SZ': 'ZC Status', 'VI': 'Inverter', 'PR': 'RC Performance' }

function parseMetricsPerGroup(data, criteria) {

    function getHead(gid) {
        return (gid in groupDesc) ? groupDesc[gid] : 'Others'
    }

    function getUrl(metric) {
        return "readings1byid-t_" + metric
    }

    function getGroupType(cr) {
        //return cr.site === ""? 'pf': cr.zone === ''? 'sites': cr.device === ''? 'zones': 'rcs'
        return cr.site === "" ? 'pf' : cr.zone === '' ? 'zones' : cr.device === '' ? 'zones' : 'rcs'

    }
    
    // metricParser1(data, args, criteria)
    // console.log("Metrics@@@@@@@@@@@@@@@@@@@@ " , data.metrics, criteria)
    const gt = getGroupType(criteria)
    //console.log("group type", gt, criteria)
    // const mlist = (criteria.site === "") ? filterMetricList(data, ['Z']) : (criteria.zone.length > 0) ? filterMetricList(data, ['R','I']) : data;
    const mlist = filterMetricsAsPerCriteria(data, criteria);
    // console.log(mlist)
    // const mlist = (criteria.site === "") ? filterMetricList(data.metrics.values, ['Z']) : (criteria.zone.length > 0) ? filterMetricList(data.metrics.values, ['R']) : data.metrics.values;
    return mlist.map(e => { return { head: getHead(e.group_id), metricGroup: e.group_id, type: "time", title: e.metric, url: getUrl(e.metric), grouptype: gt, interval: criteria.interval } })
}

const MetricDataViewPicker = (props) => {
    // const dataviewaction = useDataviewActionContext()
    const userContext = useUserContext()
    const criteria = useCriteriaContext()
    const metricsData = useMemo(() => { return parseMetricsPerGroup(userContext.metrics, criteria) }, [userContext.metrics, criteria]) //useState([]);


    // const props2 = Object.assign(props, {onSelect: onSelect })

    // console.log("metrics list %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%", props)
    // function onSelect(id){
    //     dataviewaction.setView(id)
    // }
    //const finalOption = mergeOptions(props.data)
    //console.log("final metrics " ,props.data)
    return (metricsData && metricsData.length > 0) && MetricsListBaseChart({ data: metricsData });

}


/*

function filterMetricByType(v, mtype) {
    console.log(mtype)
    return v.filter(e => mtype.includes(e[1].charAt(1)))//.sort((a,b) => a[0] > b[0])
}

function metricDataViewParser(data, args, criteria) {
    // metricParser1(data, args, criteria)
    // console.log("Metrics@@@@@@@@@@@@@@@@@@@@ " , data.metrics, criteria)
    const gt = getGroupType(criteria)
    //console.log("group type", gt, criteria)
    const mlist = (criteria.site === "") ? filterMetricList(data.metrics.values, ['Z']) : (criteria.zone.length > 0) ? filterMetricList(data.metrics.values, ['R']) : data.metrics.values;

    // const mlist = (criteria.site === "") ? filterMetricList(data.metrics.values, ['Z']) : (criteria.zone.length > 0) ? filterMetricList(data.metrics.values, ['R']) : data.metrics.values;
    return mlist.map(e => { return { head: getHead(e), type: "time", title: getTitle(e), url: getUrl(e), grouptype: gt, interval: criteria.interval } })
}

export function metricViewParser(data, args, criteria) {
    const mlist = data.metrics.values;
    return mlist.map(e => { return { head: getHead(e), title: e[0], metric: e[0], group: e[1] } })
}

/*
const urls = [
    { name: "metrics", url: "unique_metrics", nocache: true},
]

const comps = {
    title: 'Metrics',
    chart: MetricDataViewPicker, 
    urls: urls,
    noheader: true,
    nocard: true,
    default: [],
    parser: {f: metricDataViewParser, args: {  } } 
}

const MetricsList = withData({ ...comps }) //withData(PerfChartBase, urls)
*/

const MetricsList = MetricDataViewPicker;
export default MetricsList;
