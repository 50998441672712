import React, { useEffect, useMemo, useState, useContext } from 'react';
import nextId from "react-id-generator";
import styled from 'styled-components';
import './layout.css';
import { useModelContext } from '../../context/model.context';
import { usePageContext } from "../../context/page.context";
import { useDialogActionContext } from "../../context/dialog.context"
import { ChartEditor } from "../../forms/editchart"
import { getLogData, getLevel } from '../../helpers/common';
import { useCriteriaContext } from "../../context/criteria.context";
import criteria from './criteria';
import {AiOutlineHome, AiOutlineBarChart, AiOutlineDownload, AiOutlineFileText} from 'react-icons/ai'
import {BsGrid3X2} from 'react-icons/bs'
import {GiSolarPower} from 'react-icons/gi'
import {LiaEditSolid} from 'react-icons/lia'
import { GrAnalytics } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import { SlEnergy } from "react-icons/sl";
import ReactTooltip from "react-tooltip";
import { isHailAdminUser } from '../auth/userinfo';

const data = [
	{
		id: 'My Charts',
		items: [
			{ id: 'Errors', title: 'Errors' },
			{ id: 'Modes', title: 'Modes' },
			{ id: 'Angles', title: 'Angles' },
		],
		selected: ''
	},
	{
		id: 'My Charts',
		items: [
			{ id: 'Errors', title: 'Errors' },
			{ id: 'Modes', title: 'Modes' },
			{ id: 'Angles', title: 'Angles' },
		],
		selected: ''
	}

]
const SideNavbarContainer = styled.div`
    max-height: calc(100vh - 100px); /* Adjust the height as needed */
    overflow-y: auto;
    overflow-x: hidden;
	&::-webkit-scrollbar {
		width: 4px; /* Reduce the thickness */
	}
	`

const StyledNavItem = styled.li`
    cursor: pointer;
	padding: 1px 2px; /* Adjust padding to reduce the gap */
	// margin-left: 22px;
	font-size: 14px;
	font-weight: 700;
	color: #014059;
	// margin-bottom: 2px;

	// :hover {
	// 	background-color: #D6F3FF;
		
	// 	// border-bottom: solid thin #4C4345;

	// };
	color: ${props => (props.selected ? '#4C4345' : 'inherit')};
	// border-bottom: ${props => (props.selected ? 'solid thin #4C4345;' : '')};
`



const selectMetric = (id, setSelectedMetric) => {
	setSelectedMetric(id)
}

const subClick = (data, setSelectedMetric) => {
	if (data.group === 'Overview') {
		// setSubMenuData({subMenuId: data.id, isFirst: data.first})
		// scrollView(data.id, data.first)
		//createActivityLog(getLogData(criteria, {featureId: `view ${level} ${props.title}`.toLowerCase().split(" ").join("-"), usecase: `view ${level} ${props.title} chart`.toLowerCase(), category: 'overview', level: level}))
		// const level = getLevel(criteria)
		// data.keys.forEach(key =>  createActivityLog(getLogData(criteria, {featureId: key.toLowerCase().split(" ").join("-"), usecase: `${key} chart`.toLowerCase(), category: 'overview', level: level})))
	} else if (data.group === 'Model Viewer') {
		selectMetric(data.id, setSelectedMetric)
	}
}

const Icon = ({title}) => {
	if(title === 'Overview'){
		return <AiOutlineHome size="26px" color="#004059" data-for={title} data-tip={title}/>
	}else if(title === 'Live Status'){
		return <BsGrid3X2 size="26px" color="#004059" data-for={title} data-tip={title}/>
	}else if (title === 'Model Viewer'){
		return <GiSolarPower size="26px" color="#004059" data-for={title} data-tip={title}/>
	}else if(title === 'My Dashboards'){
		return <AiOutlineBarChart size="26px" color="#004059" data-for={title} data-tip={title}/>
	}else if(title === 'User Analytics'){
		return <GrAnalytics size="26px" color="#004059" data-for={title} data-tip={title}/>
	}else if (title === 'Site Maintenance') {
		return <AiOutlineFileText  size="26px" color="#004059" data-for={title} data-tip={title}/>		
	}else if(title === 'Settings'){
		return <IoSettingsOutline size="26px" color="#004059" data-for={title} data-tip={title}/>
	}else if(title === 'Energy Insights'){
		return <SlEnergy size="26px" color="#004059" data-for={title} data-tip={title}/>
	}else{
		return <AiOutlineDownload size="26px" color="#004059" data-for={title} data-tip={title}/>
	}
}

export const SubMenu = ({items, onSelect, show, setShow}) => {
	const modelContext = useModelContext()
	const Criteria = useCriteriaContext()
	const pageContext = usePageContext()
	const dialogActionContext = useDialogActionContext()
	const newChartProps = { loading: true, selectOnly: false }

	return (
		<div style={{ position: 'fixed' }}>
		<ul  className='sidebar-submenu' style={{display: show ? 'block' : 'none', top: `-${(items.length)*21}px`}}>	
			<>
				{items.length > 0 ? <li className="main-heading">My Dashboards
					<LiaEditSolid size="20px" onClick={(e)=>{
						dialogActionContext.openDialog({ showDialog: true, title: 'Manage Charts', customClass:'manageCharts', Content: ChartEditor, args: newChartProps })
					}}/>
				</li> : <li className="main-heading">
					<button type="button" className="btn btn-primary px-3" onClick={(e)=>{
						dialogActionContext.openDialog({ showDialog: true, title: 'Manage Charts', customClass:'manageCharts', Content: ChartEditor, args: newChartProps })
					}} style={{backgroundColor: '#014059'}}>Create a Dashboard</button>
				</li>}
				{items.map((item,index) => (
					<li className="sub-heading" key={index}  style={{backgroundColor: pageContext.page === item.id ? '#D6F3FF' : ''}} onClick={(e) => {
						e.stopPropagation()
						onSelect(item.id)
						modelContext.setSelectedMetric(null)
						setTimeout(() => {
							setShow(false)
						},300)
						// modelContext.setSelectedMetric(null)
					}}>{item.title}</li>
				))}
			</>
		</ul>
		</div>)
}


const NavItem = (props) => {
	// const id = useState(props.data.id)
	const modelContext = useModelContext()
	const pageContext = usePageContext()
	const Criteria = useCriteriaContext()
	const elementId = props.groupType ? 'My Dashboards' : props.items[0].title
	const [show, setShow] = useState(false)

	useEffect(() => {
		const elementId = pageContext.page
		if(elementId !== 'My Dashboards'){
			props.onSelect(elementId)
			modelContext.setSelectedMetric(null)
		}else{
			setShow(!show)
		}
	}, [pageContext.page])
	
	return (
		<>
			<StyledNavItem 
				style={{background: elementId === pageContext.page || (elementId === 'My Dashboards' && pageContext.isChart)? '#D6F3FF' : ''}} 
				onClick={(e) => {
					if(elementId !== 'My Dashboards'){
						props.onSelect(elementId)
						modelContext.setSelectedMetric(null)
					}else{
						setShow(!show)
					}
				}}
				// modelContext.setSelectedMetric(null)
				><div  style={{padding: '12px',position: 'relative', left: '5px'}}>
					<Icon title={elementId}/>
					{
						elementId === 'My Dashboards' ? <SubMenu onSelect={props.onSelect} show={show} setShow={setShow}  items={props.items} groupType={props.groupType}/> : null
					}
				</div>
			</StyledNavItem>
			<ReactTooltip
				id={elementId}
				place={"right"}
				className={`sidebar-tooltip ${elementId == 'My Dashboards' ? 'dash' : ''}`}
			/>
		</>
		
	)
}


const NavGroup = (props) => {
	const dialogActionContext = useDialogActionContext();
	const newChartProps = { loading: true, selectOnly: false }
	const [selected, setSelected] = useState(props.selected)
	const group = useMemo(() => getGroup(), [selected])
	const activeClass = useMemo(() => getActiveClass(), [group])
	// console.log("PRINTING PROPSS",selected, props.data.items)

	function getGroup() {
		// console.log(selected, "Seeking group" , props.data)
		if (props.data) {
			const groups = props.data.items.filter((f) => f.id === selected)
			// console.log(group && group.length > 0 ? group[0].items: "")
			if (groups && groups.length > 0) {
				// console.log(selected, "found group ", groups)
				return groups[0]
			}
		}
	}
	function getActiveClass(){
		// console.log(selected, group, activeClass)
		if (group) {
			// console.log(group.id, group.items)
			props.onSelect(group.id)
			return 'has-sub active'
		}else {
			return 'has-sub'
		}

	}
	
	return (
		<div style={{marginBottom : '20px'}}>
			<NavItem items={props.data.items} groupType={props.data.grouptype} selected={selected} onSelect={setSelected} key={nextId()}/>
		</div>
	)
	
}



const SideNavbar = (props) => {
	// const data  = useState(props.data)
	const [selected, setSelected] = useState(props.selected)
	

	useEffect(() => {
		props.onSelect(selected)
	}, [selected])

	const style = {}
	if(isHailAdminUser()){
		style['paddingTop'] = '72px'
	}
	function getValueBetweenPageAndPath(url) {
		const startIndex = url.indexOf("?page=") + 6; // +6 to exclude "?page="
		const endIndex = url.indexOf("&path=");
		if (startIndex !== -1 && endIndex !== -1) {
			return url.substring(startIndex, endIndex);
		}
		return null; // Return null if either marker is not found
	}
	let currentPath = window.location.search
	currentPath = decodeURI(getValueBetweenPageAndPath(currentPath))
	useEffect(() => {
        // Clear the selected value when the URL changes for settings and user analytics
		if (currentPath === "settings" || currentPath === "useranalytics" || currentPath === "diffuse" || currentPath === "Data Import") {
        setSelected(currentPath);} 
	},[currentPath]);

	return (
		<>
		
			<div id="sidebar" className="sidebar" style={style}>
				<div>

					{/*<!-- begin sidebar nav --> */}
					<ul className="nav">
					
					<SideNavbarContainer className="sidebar-content">
							{props.data && props.data.length > 0 && props.data.filter(f=> f.visible).map((e) =>
							<NavGroup data={e} selected={selected} onSelect={setSelected}  key={nextId()}/>)}
					</SideNavbarContainer>
					
					</ul>
					{/* <!-- end sidebar nav --> */}
				</div>
			</div>

			{/* <div className="sidebar-bg"></div> */}
		</>
		// <!-- end #sidebar -->
	)

}



export default SideNavbar;