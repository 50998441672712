import React, { useState, useRef, useEffect } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import styled from 'styled-components'
import { AiFillCaretDown, AiFillCaretRight, AiFillFilter, AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import Sort from './Sort'
import paginationFactory from 'react-bootstrap-table2-paginator';

import { useRealtimeContext } from '../../context/realtime.context';
import Filters2 from './Filters2';
import criteria from '../layout/criteria';
import { useCriteriaContext } from "../../context/criteria.context";
import { createActivityLog } from '../../utils/apiCall';
import { getLogData, getLevel } from '../../helpers/common';

const Options = styled.div`
    background: white; 
    position: absolute; 
    border: 0px solid rgba(0, 0, 0, .15);
    box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, .175);
    border-radius: 4px;
    padding: 20px;
    min-width: 240px;
    max-height: 400px; /* Set max height for scroll */
    overflow-y: auto; /* Add vertical scroll if content exceeds max height */
    /* Customize scrollbar */
    &::-webkit-scrollbar {
        width: 4px; /* Set the width of the scrollbar */
    }
`

const Error = styled.span`
    padding: 20px;
    padding-top: 30px;
    color: red;
`

export default props => {
    const realtimeContext = useRealtimeContext()
    const { productsShown, error, filterRowAttrs, toggleFilter, selectFilter, sortRowAttrs, rowAttrs }  = realtimeContext
    const criteria = useCriteriaContext()
    const options = {
        sizePerPage: 10,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true
    };

    const ref = useRef(null)

    const [open, setOpen] = useState("")

    useEffect(() => {
        const level = getLevel(criteria)
        if(level !== 'pf'){
            createActivityLog(getLogData(criteria, {featureId: `view-${level}-realtime`,  usecase: `view ${level} real time data`, category: 'live-status', level: level}))
        }
    },[criteria.site, criteria.zone, criteria.device, criteria.Te, criteria.Ts])
    
    useEffect(() => {
        const close = (event) => {
            if (ref.current && !ref.current.contains(event.target))
                setOpen("")
        }
        document.addEventListener('click', close, true)
        return () => {
            document.removeEventListener('click', close, true)
        }
    })
    
    const headerFormatter = (column, colIndex, components) => {
        const value = (open !== column.text) ? column.text : ""
        const filterRowAttr = filterRowAttrs.filter(t => t.dataField === column.dataField)
        const sortRowAttr = sortRowAttrs.filter(t => (t.sort === 'asc' || t.sort === 'desc') && t.dataField === column.dataField)
        return <React.Fragment>
                <span onClick={() => setOpen(value)}>{column.text} {open === column.text   ? <AiFillCaretRight /> :  <AiFillCaretDown />}</span>
                {filterRowAttr.length > 0 && filterRowAttr[0].selected ? <AiFillFilter size="12px" color="#014059"/> : null}
                {sortRowAttr.length > 0 ? sortRowAttr[0].sort === 'asc' ? <AiOutlineArrowUp size="12px" color="#014059" /> : <AiOutlineArrowDown size="12px" color="#014059" /> : null}
                {open === column.text ? <Options style={{ 
                    right: column.dataField    === 'last_updated' ? 5 : 'auto'
                }} ref={ref}>
                    <Sort dataField={column.dataField}/>
                    {filterRowAttr.length > 0 ? <Filters2 
                        selected={filterRowAttr[0].selected}
                        open={filterRowAttr[0].open}
                        type={filterRowAttr[0].type}
                        filterData={filterRowAttr[0].filterData}
                        toggleFilter={toggleFilter}
                        dataField={filterRowAttr[0].dataField}
                        selectedFilters={filterRowAttr[0].selectedFilters}
                        select={selectFilter}
                    /> : null}
                    
                </Options> : null}
            </React.Fragment>
        
    }

    const columns = rowAttrs
        .filter(e => e.selected)
        .map( t => ({...t, headerFormatter }))

    return <div>
        {!error ? (columns.length > 0 ? <BootstrapTable 
            keyField='device_id' 
            data={ productsShown } 
            wrapperClasses="realtime-table"
            columns={columns}  
            pagination={paginationFactory(options) } 
        /> : null) : <Error>{error}</Error>}
    </div>
}
