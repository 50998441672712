import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import "./accordian.css";

export class Accordion extends React.Component {
    state = {
        open: this.props.open
    };

    toggleSection = index => () => {
        this.setState(({ open }) => ({
            open: index === open ? undefined : index
        }));
    };

    render() {
        return (
            <div className="accordion">
                {React.Children.map(this.props.children, (child, index) => {
                    if (child.type !== AccordionItem) return null;
                    return React.cloneElement(child, {
                        isOpen: child.props.open || this.state.open === index,
                        onClick: this.toggleSection(index)
                    });
                })}
            </div>
        );
    }
}

Accordion.propTypes = {
    open: PropTypes.number
};

const AccordionItem = ({ children, isOpen, onClick }) => (
    <Card>
        {React.Children.map(children, child => {
            if (child.type === AccordionHeader) {
                return React.cloneElement(child, { isOpen, onClick });
            }
            if (child.type === AccordionBody) {
                return React.cloneElement(child, { isOpen });
            }
            return null;
        })}
    </Card>
);



const AccordionHeader = ({ children, isOpen, onClick }) => (
    <CardHeader onClick={onClick}>
        {isOpen ? <span className="accordian-arrow-up">
            <img src="../assets/img/icon/icon-chevron-up.svg" width="24" height="24" />
        </span> : <span className="accordian-arrow-down">
            <img src="../assets/img/icon/icon-chevron-down.svg" width="24" height="24" />
        </span>}
        <h5 className="mb-0"   >
            {/* <Button> */}
            {children}
            {/* </Button> */}
        </h5>
    </CardHeader>
);

const AccordionBody = ({ children, isOpen }) => (
    <Collapse isOpen={isOpen}>
        <CardBody>{children}</CardBody>
    </Collapse>
);

Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
