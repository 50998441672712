import React, { useState, useRef, useEffect, useMemo } from 'react'
import ReactDOMServer from 'react-dom/server';
import { 
  InstancedMesh,
  Object3D,
  Raycaster,
  Vector2,
  MeshBasicMaterial,
  Vector3,
  Matrix4,
  AxesHelper,
  Color,
  Box3,
  Mesh,
  BoxHelper
} from 'three'
import { toJS } from 'mobx';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { InfoTooltip } from '../../model/info-tooltip'
import styled from "styled-components";
import { useUserContext } from "../../context/user.context";
import { useCriteriaContext } from "../../context/criteria.context";
import { getUrlFromCriteria } from '../layout/criteria';
import { useModelContext } from '../../context/model.context';
import { useRealtimeContext } from '../../context/realtime.context'
import { modelPage, usePageContext } from '../../context/page.context';
import MetricsHeatmap from './metrics_heatmap';
import { processMetricData, getLabels , getLabel, getSummary } from './heatmap';
import { SliderControl } from '../controls/slidercontrol'

import Container3d from './container-3d/react-container-3d';
import CubeView from './container-3d/react-cubeview';
import { createActivityLog } from '../../utils/apiCall';
import { getLogData, getLevel } from '../../helpers/common';
import './container-3d/react-cubeview.css';

const InfoStyle = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  text-align: center;
  background: #ffa2b0;
  padding: 5px 10px;
  border-radius: 0px 0px 4px 4px;
  z-index: 1000;
`

const LabelPanelStyle = styled.div`
  position: absolute; 
  max-height: 376px;
  overflow: scroll;
  left: ${props => props.left}px;
  right: ${props => props.right}px;
  top: ${props => props.top}px;
  bottom: ${props => props.bottom}px;
  min-width: ${props => props.minWidth || 200}px; 
  display: flex;
  padding: 20px 12px 8px 20px;
  flex-direction: column;
  gap: 10px;
  background-color: #F7F8F9;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 0px;
`

const LabelPanelHeading = styled.span`
  font-weight: 800;
`


const FixedColorCount = styled.span`
  display: flex;
  justify-content: space-between;
  width: 50px;
`

const FixedLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
`

const ContinuousRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
`

const FixedColor = styled.div`
  width: 20px;
  height: 20px;
  background: ${props => props.color};
`

const ActionButton = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border-width: 0px;
`

const GradientLabelRow = styled.div`
  display: flex;
  flex-direction: column;
`

const GradientColor = styled.div`
  width: 100%; 
  height: 20px;  
  background-image: ${props => `linear-gradient(to right, ${props.color1} , ${props.color2})`}
`

const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
`

const TimeSeries  = styled.div`
  position: absolute; 
  bottom: 34px; 
  left: 100px; 
  width: calc(100% - 200px); 
  text-align: center;
`

const DateLabel = styled.span`
  padding: 4px;
  font-weight: 700; 
  color: #014059;
`



const ModelViewer = props => {

  const viewerRef = useRef(null)
  const cubeRef = useRef(null)
  const containerRef = useRef(null)
  const [loadingMetrics, setLoadingMetrics] = useState(false)
  const [labels, setLabels] = useState(null)
  const [metric, setMetric] = useState('')
  const [isMetricCleared, setMetricCleared] = useState(false)
  const [metricData, setMetricData] = useState([])
  const [summary, setSummary] = useState([])
  const [modeData, setModeData] = useState([])
  const [angleData, setAngleData] = useState([])
  const [errorData, setErrorData] = useState([])
  const [timeValue, setTimeValue] = useState(null)
  const [timeLabel, setTimeLabel] = useState('')
  const [minMaxValue, setMinMaxValue] = useState(null)
  const [metricType, setMetricType] = useState(null)
  const [encodings, setEncodings] = useState(null)
  const [radioMerged, setRadioMerged] = useState("yes")
  const [tableSelected, setTableSelected] = useState({
    tableName: "-",
    deviceId: "-",
    zoneId: "-"
  })
  const [metricsToggle, setMetricsToggle] = useState(null)
  const [resetLabels, setResetLabels] = useState(false)
  const tableColor = { r: 0, g: 0, b: 0.5019607843137255 }
  const width= window.innerWidth - 260
    const height = window.innerHeight - 150
    const userContext = useUserContext()
    const criteriaContext = useCriteriaContext()
    const modelContext = useModelContext()
    const pageContext  =  usePageContext()

    const realtimeContext = useRealtimeContext()
  
    const [currentCriteria, setCurrentCriteria] = useState({})

    const { loading, error,  clear, setClear, setLoading, importingMetric, setImportingMetric } = modelContext
    const { realtimeMetric, setRealtimeMetric } = realtimeContext 

    useEffect(() => {
      const zone = modelContext.selectedZone
      const device = modelContext.selectedDevice
      if (!device)
        if (zone) 
          zoom(zone, 'zone')
        else
          zoom('', 'site')

    }, [modelContext.selectedZone])


    useEffect(() => {
      const zone = modelContext.selectedZone
      const device = modelContext.selectedDevice
      if (zone && device) 
        zoom(device, 'device')
      if (zone && !device)
        zoom(zone, 'zone')

    }, [modelContext.selectedDevice])

    
    useEffect(() => {
      if (importingMetric) {
        onMetricChange(importingMetric.metricName, importingMetric.metricData, importingMetric.minMaxValue, importingMetric.metricType, importingMetric.encodings)  
        setImportingMetric(null)
      }
    }, [importingMetric])
    
    
    
    useEffect(() => {
      const level = getLevel(criteriaContext)
      if(level !== 'pf' && metric && (currentCriteria.metric !== metric || currentCriteria.site !== criteriaContext.site || currentCriteria.zone !== criteriaContext.zone || currentCriteria.device !== criteriaContext.device || currentCriteria.Te !== criteriaContext.Te || currentCriteria.Ts !== criteriaContext.Ts || currentCriteria.merged !== radioMerged)){
        setCurrentCriteria({...criteriaContext, metric: metric, merged: radioMerged})
        createActivityLog(getLogData(criteriaContext, {featureId: `view-${level}-modelviewer`,  usecase: `view ${level} modelviewer`, category: 'model-viewer', level: level, metrics: metric, details: JSON.stringify({merge: radioMerged})}))
      }else if(level !== 'pf' && isMetricCleared && (currentCriteria.site !== criteriaContext.site || currentCriteria.zone !== criteriaContext.zone || currentCriteria.device !== criteriaContext.device || currentCriteria.Te !== criteriaContext.Te || currentCriteria.Ts !== criteriaContext.Ts || currentCriteria.merged !== radioMerged)){
        setCurrentCriteria({...criteriaContext, metric: '',merged: radioMerged})
        createActivityLog(getLogData(criteriaContext, {featureId: `view-${level}-modelviewer`,  usecase: `view ${level} modelviewer`, category: 'model-viewer', level: level, metrics: metric, details: JSON.stringify({merge: radioMerged})}))
      }
    }, [criteriaContext.site, criteriaContext.zone, criteriaContext.device, criteriaContext.Te, criteriaContext.Ts, metric,radioMerged])


    

    useEffect(() => {
      modelContext.setSelectedDevice('')
      modelContext.setSelectedZone('')

    }, [criteriaContext.site])

    useEffect(() => {
      modelContext.setSelectedZone(criteriaContext.zone || "")
      modelContext.setSelectedDevice(criteriaContext.device || "")
      setResetLabels(true)
  }, [ criteriaContext.zone, criteriaContext.device ])

    useEffect(() => {
      if (modeData && angleData && errorData && modeData.length > 0 && angleData.length > 0 && errorData.length > 0) {
        setLoadingMetrics(false)
      } else {
        setLoadingMetrics(true)
      }
    }, [modeData, angleData, errorData])

    useEffect(() => {
      if (pageContext.page === modelPage) {
        setCanvasSize()
      }
    }, [ pageContext])

    useEffect(() => {
      if (resetLabels) {
        const selectedDevices = getSelectedDevices()
        const labels = getLabels(metric, minMaxValue, metricData.length > 0 ? metricData[timeValue] : null, selectedDevices, metricType, encodings)
        setLabels(labels)
        setResetLabels(false)
      }
    }, [resetLabels])

    useEffect(() => {
      if (clear) {
        setClear(false)
        clearScene()
      }
    }, [clear])

    useEffect(() => {
      
      window.addEventListener('resize', () => {
        setCanvasSize()
      })
      console.log("i am rendering only once....")
    }, [])
  
    useEffect(() => {
      if (containerRef.current) {
        setupCamera(containerRef.current.getCamera())
      }
      if (modelContext.model) {
        importScene(modelContext.model)
        setLoading (false)
      }

      console.log("i am rendering on menu selection....")

      return () => {
        if (modelContext.model) {
          const scene = containerRef.current.getScene()
          const regions = scene.getObjectByName('regions')

          if (regions)
          modelContext.model.add(regions)
          let terrain = scene.getObjectByName("dxf-terrain")
          if (!terrain) {
            terrain = scene.getObjectByName("terrain-site")
          }  
          if (!terrain) {
            terrain = scene.getObjectByName("flat-site")
          }
          if (terrain)
            modelContext.model.add(terrain)
        }
      }
      
    }, [modelContext.model])

    const setupCanvas = (scene, camera, renderer) => {
      renderer.setClearColor(0xcecece)
      setupCamera(camera)
    }

    const setupCamera = camera => {
      camera.position.set(0, 6, 0)
      camera.lookAt(new Vector3())
      if (cubeRef.current)
        cubeRef.current.setAngles(0, 0)
    }


    const highlightDevices = (id, type) => {
      let devicePositions = []
      let selected = {
        tableName: "-",
        deviceId: type === 'device' ? id : "-",
        zoneId: type === 'zone' ? id : "-"
      }
      const scene = containerRef.current.getScene();
      const layoutGroups = scene.children.filter(t => t.name === "pvLayout")
      if (!layoutGroups || layoutGroups.length === 0)
        return [devicePositions, selected]

      const color = new Color(0, 1, 0)
      const black = new Color().set("#cecece")
      
      layoutGroups.forEach(instancedMesh => {
        const deviceIds = type === 'zone' ? instancedMesh.userData.zoneIds : instancedMesh.userData.deviceIds
        deviceIds.forEach((deviceId, index) => {
          if (type === 'site') {
            const matrixPosition = new Matrix4()
            instancedMesh.getMatrixAt(index, matrixPosition)
            const position = new Vector3()
            position.setFromMatrixPosition(matrixPosition)
            devicePositions.push(position)

            const existingColor = instancedMesh.userData.colors[index]
            instancedMesh.setColorAt(index, color.setRGB(existingColor[0], existingColor[1], existingColor[2]))
            instancedMesh.userData.selectedZ[index] = 0
          } else {
            if (deviceId === id || (type === 'devices' && id.indexOf(deviceId) >= 0)) {
              if (type === 'device' && selected.zoneId === '-') {
                selected.zoneId = instancedMesh.userData.zoneIds[index]
                selected.tableName = instancedMesh.userData.tableNames[index]
              }
              const matrixPosition = new Matrix4()
              instancedMesh.getMatrixAt(index, matrixPosition)
              const position = new Vector3()
              position.setFromMatrixPosition(matrixPosition)
              if (type === 'zone' || (typeof id === 'object' && id.length > 1)) {
                devicePositions.push(position)
              } else {
                const geometryPositions = instancedMesh.geometry.attributes.position.array
                devicePositions = geometryPositions.reduce((acc, curr, index) => {
                  if (index % 3 === 0) {
                    acc.push(new Vector3(
                      geometryPositions[index] + position.x, 
                      geometryPositions[index + 1] + position.y, 
                      geometryPositions[index + 2] + position.z
                    ))
                  }
  
                  return acc
                }, [])
              }
              // instancedMesh.setColorAt(index, color.setRGB(0, 1, 0))
              if (type !== 'devices') {
                const existingColor = instancedMesh.userData.colors[index]
                instancedMesh.setColorAt(index, color.setRGB(existingColor[0], existingColor[1], existingColor[2]))
                instancedMesh.userData.selectedZ[index] = 1
              }
            } else {
              if (type !== 'devices') {
                const existingColor = instancedMesh.userData.colors[index]
                instancedMesh.setColorAt(index, color.setRGB(existingColor[0], existingColor[1], existingColor[2]).lerp(black, 0.8))
                // instancedMesh.setColorAt(index, color)
                instancedMesh.userData.selectedZ[index] = 0
              }
            }
          }
        })
        if (type !== 'devices')
          instancedMesh.instanceColor.needsUpdate = true
      })

      return [devicePositions, selected]
    }

    const zoom = (id, type) => {
      const camera = containerRef.current.getCamera();
      const controls = containerRef.current.getControls()
      const devices = highlightDevices(id, type)
      const devicePositions = devices[0]
      if (devicePositions.length === 0)
        return 

      const offset = type === 'site' ? 0.7 : 1;
      const boundingBox = new Box3()
      devicePositions.forEach(position => {
        boundingBox.expandByPoint(position)
      })
      
      const center  = new  Vector3()
      boundingBox.getCenter(center)

      const size = new Vector3()
      boundingBox.getSize(size)

      const maxDim = Math.max( size.x, size.y, size.z );
      const fov = camera.fov * ( Math.PI / 180 );
      let cameraZ = maxDim === 0 ? 0.2 : Math.abs( maxDim / 4 * Math.tan( fov * 2 ) );

      cameraZ *= offset; 

      camera.position.x = center.x
      camera.position.z = center.z
      camera.position.y = cameraZ

      camera.updateProjectionMatrix();

      if ( controls ) {
        controls.target = center;
      } else {
          camera.lookAt( center )
      }

      if (type === 'zone')
      modelContext.setSelectedDevice('')

      setTableSelected(devices[1])
      // removeSelection()
    }

    // const removeSelection = () => {debugger
    //   if (!containerRef.current)
    //     return
    //   const scene = containerRef.current.getScene()
    //   const renderer = containerRef.current.getRenderer()
    //   const layoutGroups = scene.children.filter(t => t.name === "pvLayout")
    //     if (!layoutGroups || layoutGroups.length === 0)
    //       return   
    //   layoutGroups.forEach(instancedMesh => {
    //     instancedMesh.userData.selected = instancedMesh.userData.selected.map(t => 0)
    //   })

    //   renderer.domElement.addEventListener( 'pointermove', onPointerMove );
    // }


    var updateAngles2 = (phi, theta)=>{
      if (cubeRef.current) {
          cubeRef.current.setAngles(phi, theta);
      }
    }

    const clearScene = () => {
      if (containerRef.current) {
        const scene = containerRef.current.getScene()
        while(scene && scene.children.length > 0){ 
          scene.remove(scene.children[0]); 
        }
      }
      setLabels(null)
      setMetric('')
      setMetricData([])
      setTimeValue(null)
      setMetricType(null)
      setEncodings(null)
      modelContext.setSelectedMetric(null)
    }

    const setCanvasSize = () => {
      if (containerRef.current) {
        const camera = containerRef.current.getCamera()
        const renderer = containerRef.current.getRenderer()
        // const width= viewerRef.current ? viewerRef.current.clientWidth - 5 : 0
        const width= window.innerWidth - 90
        const height = window.innerHeight - 135
        camera.aspect = width/height
        camera.updateProjectionMatrix()
        renderer.setSize( width, height );
      }
    }


    var updateAngles = (phi, theta) => {
      if(containerRef.current)
          containerRef.current.setAngles(phi, theta);
    };

    const setupScene = (sceneObj, scene) => {
      // setLoading(false)
      const regions = sceneObj.getObjectByName('regions')
      
      if (regions)
      scene.add(regions)
      let terrain = sceneObj.getObjectByName("dxf-terrain")
      if (!terrain) {
        terrain = sceneObj.getObjectByName("terrain-site")
      }  
      if (!terrain) {
        terrain = sceneObj.getObjectByName("flat-site")
      }
      if (terrain)
        scene.add(terrain)
      const pvLayout = sceneObj.getObjectByName('pvLayout')
      pvLayout.children.forEach(mesh => {
        createInstancedMesh(mesh, scene)
      })
    }

    const importScene = async (model) => {
      setupTableSelection()
      // setLoading(true)
      // setError('')
      const scene = containerRef.current.getScene();
      scene.add(new AxesHelper(5))
      
      setupScene(model, scene)
      if (criteriaContext.device)
          zoom(criteriaContext.device, 'device')
      else if (criteriaContext.zone)
        zoom(criteriaContext.zone, 'zone')
      
      if (realtimeMetric) {
        onMetricChange(realtimeMetric.metricName, realtimeMetric.metricData, realtimeMetric.minMaxValue, realtimeMetric.metricType, realtimeMetric.encodings) 
        const metrics = realtimeMetric.metricData[0].metrics
        const devices = Object.keys(metrics).filter(t => metrics[t] === 1)
        zoom(devices, 'devices')
      }
    }



    const createSelectionBox = (geometry, name) => {
      const box = new Mesh(geometry, new MeshBasicMaterial())
      const boxHelper = new BoxHelper(box, 0xffff00)
      boxHelper.name = "selectionBox" + name
      boxHelper.visible = false
      return boxHelper
    }

    const showSelectionBox = (scene, name, positionMatrix) => {
      const selectionBoxes = scene.children.filter(t => t.name.indexOf("selectionBox") >= 0)
      selectionBoxes.forEach(selectionBox => selectionBox.visible = false)
      const selectionBox = scene.getObjectByName("selectionBox" + name)
      selectionBox.visible = true
      selectionBox.position.setFromMatrixPosition(positionMatrix)
      selectionBox.updateMatrix()
    }

    const hideSelectionBox = (scene) => {
      const selectionBoxes = scene.children.filter(t => t.name.indexOf("selectionBox") >= 0)
      selectionBoxes.forEach(selectionBox => selectionBox.visible = false)
    }

    const createInstancedMesh = (pvLayout, scene) => {
      const meshes = pvLayout.children
      if (meshes.length === 0)
        return
      const instancedMesh = new InstancedMesh(
        meshes[0].geometry,
        new MeshBasicMaterial(),
        meshes.length
      )
      instancedMesh.name = "pvLayout"
      instancedMesh.userData.tableNames = []
      instancedMesh.userData.deviceIds = []
      instancedMesh.userData.zoneIds = []
      instancedMesh.userData.colors = []
      instancedMesh.userData.selected = []
      instancedMesh.userData.selectedZ = []

      scene.add(instancedMesh)

      const selectionBox = createSelectionBox(meshes[0].geometry, meshes[0].name)
      scene.add(selectionBox)

      var dummy = new Object3D();
      const color = new Color().setRGB(tableColor.r, tableColor.g, tableColor.b)
      for ( var i = 0; i < instancedMesh.count; i ++ ) {
        const position = new Vector3().addVectors(pvLayout.position, meshes[i].position)
        dummy.position.copy(position);
        dummy.updateMatrix();
        instancedMesh.setMatrixAt( i, dummy.matrix );
        instancedMesh.setColorAt( i, color );
        instancedMesh.userData.tableNames.push(meshes[i].name)
        
        const deviceId = meshes[i].userData.deviceId
        const zoneId = meshes[i].userData.zoneId
        instancedMesh.userData.deviceIds.push(deviceId)
        instancedMesh.userData.zoneIds.push(zoneId)
        instancedMesh.userData.colors.push([tableColor.r, tableColor.g, tableColor.b])
        instancedMesh.userData.selected.push(0)
        instancedMesh.userData.selectedZ.push(0)
      }
      
      instancedMesh.instanceMatrix.needsUpdate = true;
      instancedMesh.instanceColor.needsUpdate = true
    }

    const onPointerMove = ( event ) => {
      const pointer = new Vector2();
      const renderer = containerRef.current.getRenderer()
      const scene = containerRef.current.getScene()
      pointer.x = ( (event.offsetX) / renderer.domElement.width ) * 2 - 1;
      pointer.y = - ( (event.offsetY) / renderer.domElement.height ) * 2 + 1;

      const layoutGroups = scene.children.filter(t => t.name === "pvLayout")
      if (!layoutGroups || layoutGroups.length === 0)
        return 

      let selected = false
      layoutGroups.forEach(instancedMesh => {
        if (instancedMesh.userData.selected.filter(t => t === 1).length > 0)
          selected = true
      })

      if (!selected)
      getIntersectingMesh(false, pointer)
    }
    
    const onPointerDown = ( event ) => {
      const pointer = new Vector2();
      const renderer = containerRef.current.getRenderer()
      const scene = containerRef.current.getScene()
      pointer.x = ( (event.offsetX) / renderer.domElement.width ) * 2 - 1;
      pointer.y = - ( (event.offsetY) / renderer.domElement.height ) * 2 + 1;

      const layoutGroups = scene.children.filter(t => t.name === "pvLayout")
      if (!layoutGroups || layoutGroups.length === 0)
        return 
      layoutGroups.forEach(instancedMesh => {
        instancedMesh.userData.selected = instancedMesh.userData.selected.map(t => 0)
      })
      getIntersectingMesh(true, pointer)
      setMetricsToggle(Math.random())
      // modelContext.setSelectedDevice("")
      // modelContext.setSelectedZone("")
    }

    const getIntersectingMesh = (tableSelection, pointer)=> {
      const scene = containerRef.current.getScene()
      const renderer = containerRef.current.getRenderer()
      const color = new Color()
      const layoutGroups = scene.children.filter(t => t.name === "pvLayout")
      if (!layoutGroups || layoutGroups.length === 0)
        return     

      const selectedZone = layoutGroups
        .reduce((acc, curr) => acc + curr.userData.selectedZ.filter(t => t === 1).length, 0)
      
      
      const raycaster = new Raycaster()

      const camera = containerRef.current.getCamera()
      // update the picking ray with the camera and pointer position
      raycaster.setFromCamera( pointer, camera );
      // calculate objects intersecting the picking ray
      const intersects = raycaster.intersectObjects( layoutGroups, true );
      if(intersects.length) {
        const instanceId = intersects[0].instanceId
        if (selectedZone > 0) {
          if (intersects[0].object.userData.selectedZ[instanceId] === 1) {
            const tableData = intersects[0].object.userData
            const matrix = new Matrix4()
            intersects[0].object.getMatrixAt(instanceId, matrix)
            showSelectionBox(scene, tableData.tableNames[0], matrix)
            setTableSelected({
              tableName: tableData.tableNames[instanceId],
              deviceId: tableData.deviceIds[instanceId],
              zoneId: tableData.zoneIds[instanceId]
            })
          } else {
            hideSelectionBox(scene)
            resetTableSelected()
            if (tableSelection) {
              renderer.domElement.addEventListener( 'pointermove', onPointerMove );
            }
          }
        } else {
          const tableData = intersects[0].object.userData
          const matrix = new Matrix4()
          intersects[0].object.getMatrixAt(instanceId, matrix)
          showSelectionBox(scene, tableData.tableNames[0], matrix)
          setTableSelected({
            tableName: tableData.tableNames[instanceId],
            deviceId: tableData.deviceIds[instanceId],
            zoneId: tableData.zoneIds[instanceId]
          })
        }
      } else {
        hideSelectionBox(scene)
        resetTableSelected()
        if (tableSelection) {
          renderer.domElement.addEventListener( 'pointermove', onPointerMove );
        }
      }
    }

    //taken from https://threejs.org/docs/index.html?q=raycas#api/en/core/Raycaster
    const setupTableSelection = () => {
      if (!containerRef.current)
      return
      
      const renderer = containerRef.current.getRenderer()
      
      if (renderer.domElement) {
          renderer.domElement.addEventListener( 'pointermove', onPointerMove );      
          renderer.domElement.addEventListener( 'pointerdown', (event) => {
          renderer.domElement.removeEventListener('pointermove', onPointerMove)
          onPointerDown(event)
        } );      
      }
    }

    const resetTableSelected = () => {
      // if (tableSelected.tableName !== "-") {
        setTableSelected({
          tableName: "-",
          deviceId: "-",
          zoneId: "-",
        })
      // }
    }

    const getMinMaxValue = metricData => {
      const minMaxValue = metricData.length > 0 ? metricData.reduce((acc, curr) => {
        const minMaxValue1 = Object.keys(curr.metrics).reduce((acc1, key, index) => {
          const value = curr.metrics[key]
          if (value === null)
            return acc1
          if (index === 0) {
            acc1.minValue = value
            acc1.maxValue = value
          } else {
            if (value > acc1.maxValue) {
              acc1.maxValue = value
            }

            if (value < acc1.minValue) {
              acc1.minValue = value
            }
          }

          return acc1

        }, {minValue: null, maxValue: null})

        if (acc.minValue === null || acc.minValue > minMaxValue1.minValue)
          acc.minValue = minMaxValue1.minValue
        if (acc.maxValue === null || acc.maxValue < minMaxValue1.maxValue)
          acc.maxValue = minMaxValue1.maxValue
        
        return acc
      }, {minValue: null, maxValue: null}): {minValue: null, maxValue: null}

      return minMaxValue
    }

    const onDefaultChange = (metricName) => {
      if (metricName.indexOf('errorCode') === 0) {
        onMetricChange(metricName, errorData)
      } else if (metricName.indexOf('currentMode') === 0) {
        onMetricChange(metricName, modeData)
      } else if (metricName.indexOf('currentAngle') === 0) {
        onMetricChange(metricName, angleData)
      }
    }

    const getSelectedDevices = () => {
      if (!containerRef.current)
        return []
      const scene = containerRef.current.getScene()
      const layoutGroups = scene.children.filter(t => t.name === "pvLayout")
        if (!layoutGroups || layoutGroups.length === 0)
          return  []   

      const selectedDevices = layoutGroups
        .reduce((acc, curr) => {
          const devices = criteriaContext.device ? curr.userData.deviceIds.filter(t => t === criteriaContext.device) : 
            criteriaContext.zone ? 
              curr.userData.deviceIds.filter((t, i) => curr.userData.zoneIds[i] === criteriaContext.zone) :
              []
          acc = acc.concat(devices)
          return acc
        }, [])

      return selectedDevices
    }
    
    const onMetricChange = (metricName, metricData, minMax, metricType, encodings) => {
      const timeValue = metricData.length > 0 ? metricData.length - 1 : null
      const minMaxValue = minMax || getMinMaxValue(metricData)
      generateHeatmap(metricName, metricData, timeValue, minMaxValue, metricType, encodings)
      setMinMaxValue(minMaxValue)
      setMetricData(metricData)
      setTimeValue(timeValue)
      setTimeLabel(metricData.length > 0 && metricData[timeValue] ? metricData[timeValue].timestamp : '')
      setMetric(metricName)
      setMetricType(metricType || null)
      setEncodings(encodings || null)
      setMetricCleared(false)
      setResetLabels(true)
      
      // const summary = metricData.length > 0 ? getSummary(metricName, metricData[timeValue]) : []
      // setSummary(summary)
    }



    const onTimeValueChange = (timeValue) => {
      setTimeValue(timeValue)
      setTimeLabel(metricData[timeValue].timestamp)
      generateHeatmap(metric, metricData, timeValue, minMaxValue)
      setResetLabels(true)
      // const summary = metricData.length > 0 ? getSummary(metric, metricData[timeValue]) : []
      // setSummary(summary)
    }

    const generateHeatmap = (metricName, metricData, timeValue, minMaxValue, metricType, encodings) => {
      if (!containerRef.current)
        return
      const scene = containerRef.current.getScene()
      const layoutGroups = scene.children.filter(t => t.name === "pvLayout")
        if (!layoutGroups || layoutGroups.length === 0)
          return     

      const color = new Color()
      const black = new Color().set("#cecece")
      const level = metricName.split(":")[1]
      const selectedZone = layoutGroups
        .reduce((acc, curr) => acc + curr.userData.selectedZ.filter(t => t === 1).length, 0)
      layoutGroups.forEach(instancedMesh => {
        const deviceIds = instancedMesh.userData.deviceIds
        deviceIds.forEach((deviceId, i) => {
          if (!deviceId || metricData.length === 0 || timeValue === null) {
            instancedMesh.setColorAt( i, color.setRGB(0, 0, 0) )
          }
          else if (deviceId === "not-table") {
            instancedMesh.setColorAt( i, color.setRGB(1, 1, 1) )
          }
          else {
            const id = level.indexOf("Z") >= 0 ? instancedMesh.userData.zoneIds[i] : instancedMesh.userData.deviceIds[i]
            processMetricData(metricName, metricData[timeValue].metrics, id, color, minMaxValue, metricType, encodings)

            instancedMesh.setColorAt( i, color)
          }

          instancedMesh.userData.colors[i] = [color.r, color.g, color.b]

          if (selectedZone > 0) {
            if (instancedMesh.userData.selectedZ[i] !== 1)
              instancedMesh.setColorAt( i, color.lerp(black, 0.8) )
          } 

          // if (tableSelected && (deviceId === tableSelected.deviceId))
          //   instancedMesh.setColorAt( i, color.set("#014059") )
        })

        instancedMesh.instanceColor.needsUpdate = true        
      })
    }

    const clearMetric = () => {
      const scene = containerRef.current ? containerRef.current.getScene() : null
      const layoutGroups = scene ? scene.children.filter(t => t.name === "pvLayout") : []
        if (!layoutGroups || layoutGroups.length === 0)
          return     

      const color = new Color()
      const black = new Color().set("#cecece")
      const selectedZone = layoutGroups
        .reduce((acc, curr) => acc + curr.userData.selectedZ.filter(t => t === 1).length, 0)

      layoutGroups.forEach(instancedMesh => {
        for ( var i = 0; i < instancedMesh.count; i ++ ) {
          instancedMesh.setColorAt( i, color.setRGB(tableColor.r, tableColor.g, tableColor.b))
          instancedMesh.userData.colors[i] = [tableColor.r, tableColor.g, tableColor.b]

          if (selectedZone > 0) {
            if (instancedMesh.userData.selectedZ[i] !== 1)
              instancedMesh.setColorAt( i, color.lerp(black, 0.8) )
          } 
        }

        instancedMesh.instanceColor.needsUpdate = true
      })


      setLabels(null)
      setMetric('')
      setMetricCleared(true)
      setMetricData([])
      setTimeValue(null)
      modelContext.setSelectedMetric(null)
    }

    const getUrls = (zoneId, deviceId) => {
      let zoneUrl = ""
      let deviceUrl = ""
      if (zoneId && zoneId !== "-") {
        const criteria = useCriteriaContext()
        const cs = toJS(criteria)
  
        const zoneCriteria = { zone: zoneId, device: "", grouptype: "rcs" }
        zoneUrl = getUrlFromCriteria(Object.assign(cs, zoneCriteria)).url

        if (deviceId && deviceId !== "-") {
          const deviceCriteria = { zone: zoneId, device: deviceId, grouptype: "rcs" }
          deviceUrl = getUrlFromCriteria(Object.assign(cs, deviceCriteria)).url
        }
  
        return {
          zoneUrl,
          deviceUrl
        }
      }

      return {
        zoneUrl: "",
        deviceUrl: ""
      }
    }
    


    const urls = getUrls(tableSelected.zoneId, tableSelected.deviceId)
    
    const selectedTime = timeLabel ? moment(timeLabel) : moment()
    const timeString = selectedTime.format("hh:mm A")
    const dateString = selectedTime.format("DD MMM")

    const marks = metricData.reduce((acc, curr, index) => {
      const date = moment(curr.timestamp).format("DD MMM")
      if (index === 0)
        acc[0] = date
      else {
        const keys = Object.keys(acc)
        if (date !== acc[keys[keys.length -1]])
          acc[index] = date
      }

      return acc
    }, {})

    const metricLabelData = metric.split(":")
    const metricLabel = metricLabelData.length > 1 ? 
      metricLabelData[0] + (metricLabelData[1].indexOf("Z") >= 0 ? " (ZC)" : " (RC)") :
      metricLabelData[0]

    let metricLabelValue = ''
    let modeLabelValue = ''
    let angleLabelValue = ''
    let errorLabelValue = ''
    if (metricData.length > 0 && timeValue !== null && metricData[timeValue] && tableSelected && tableSelected.deviceId && metricLabelData.length > 1) {
      const metricValue = metricData[timeValue].metrics[
        metricLabelData[1].indexOf("Z") >= 0 ? 
          tableSelected.zoneId : 
          tableSelected.deviceId
        ]
        metricLabelValue = getLabel(metric, metricValue, metricType, encodings)
        const modeValue = modeData && modeData[timeValue] ? modeData[timeValue].metrics[tableSelected.deviceId] : null
        modeLabelValue = getLabel("currentMode:SR", modeValue)
        const angleValue = angleData && angleData[timeValue] ? angleData[timeValue].metrics[tableSelected.deviceId] : null
        angleLabelValue = getLabel("currentAngle:SR", angleValue)
        const errorValue = errorData && errorData[timeValue] ? errorData[timeValue].metrics[tableSelected.deviceId] : null
        errorLabelValue = getLabel("errorCode:SR", errorValue)
    }
   
    return <div style={{position: 'relative', marginLeft: 5}}>
      {loading ? <InfoStyle><span>Loading...</span></InfoStyle> : null}
      {loadingMetrics && !loading && !error ? <InfoStyle><span>Loading metric data..</span></InfoStyle> : null}
      {error ? <InfoStyle><span>{error}</span></InfoStyle> : null}
      {!loading && !error ? <div><MetricsHeatmap 
        metricsToggle={metricsToggle}
        onMetricChange={onMetricChange} 
        clearMetric={clearMetric}
        updateModeData={(_, data) => setModeData(data)}
        updateAngleData={(_, data) => setAngleData(data)}
        updateErrorData={(_, data) => setErrorData(data)}
        updateMetricName={setMetric}
        modeData={modeData}
        angleData={angleData}
        errorData={errorData}
        isDisabled={loadingMetrics}
        onDefaultChange={onDefaultChange}
        setRadioMerged={setRadioMerged}
      /></div> : null}
      <div style={{position: 'absolute', right: 18, top: 14, width: 150, height: 150}}>
        <CubeView 
          aspect={1} 
          hoverColor={0x0088FF} 
          cubeSize={2} 
          zoom={6} 
          antialias={true} 
          width={150}
          height={150}
          onUpdateAngles={updateAngles} 
          ref={cubeRef}
        />
      </div>
      <InfoTooltip content={ReactDOMServer.renderToString(<div>
          <h5>Navigation</h5>
          <div>
            <h6>Mouse:</h6>
            <div>Shift+Drag: Rotation</div>
            <div>Middle mouse Drag: Panning</div>
            <div>Middle mouse Scroll: Zoom in/out</div>
          </div>
          <div style={{paddingTop: 20}}>
            <h6>Trackpad:</h6>
            <div>Shift+Drag: Rotation</div>
            <div>Two finger Drag: Panning</div>
            <div>Two finger move: Zoom in/out</div>
          </div>
        </div>)} 
        style={{position: 'absolute', right: 20, top: 20}}
      />
      {labels ? <LabelPanelStyle className="model_zoom" left={14} bottom={84}>
          <LabelPanelHeading>{metricLabel}</LabelPanelHeading>
          {labels.type === 'Fixed' ? labels.value.map(t => <FixedLabelRow>
            <span>{t.label}</span>
            <FixedColorCount>
              <FixedColor color={t.color}></FixedColor>
              <span>{t.count}</span>
            </FixedColorCount>
          </FixedLabelRow>) : labels.value && labels.value.length > 1 ? <GradientLabelRow>
            <GradientColor color1={labels.value[0].color} color2={labels.value[1].color} />
            <LabelRow>
              <span>{labels.value[0].label}</span>
              <span>{labels.value[1].label}</span>
            </LabelRow>
          </GradientLabelRow> : null}
          <FixedLabelRow>
            <span>Unknown</span>
            {labels.type === 'Fixed' ? 
              <FixedColorCount>
                <FixedColor color={'black'} />
                <span>-</span>
              </FixedColorCount> : 
              <FixedColor color={'black'} />}
          </FixedLabelRow>
      </LabelPanelStyle> : null}
      {!loading && !error && tableSelected ? <LabelPanelStyle className="model_zoom" right={18} bottom={84} minWidth={280}>
        <LabelPanelHeading>Tracker details</LabelPanelHeading>
        <LabelRow>
          <span>Tracker name</span>
          <span>{tableSelected.tableName}</span>
        </LabelRow>
        <LabelRow>
          <span>Device id</span>
          {urls.deviceUrl ? 
            <span 
              style={{color: "#014059", cursor: "pointer"}} 
              onClick={() => window.open(urls.deviceUrl) }
            >
              {tableSelected.deviceId} <img 
                src="../assets/img/icon/icon-chart.svg" 
                alt="Chart" 
                width="16" 
                height="16" 
                style={{marginTop: -6}}
              />
            </span> :
            <span>{tableSelected.deviceId}</span>
          }
        </LabelRow>
        <LabelRow>
          <span>Zone id</span>
          {urls.zoneUrl ? 
            <span 
              style={{color: "#014059", cursor: "pointer"}} 
              onClick={() => window.open(urls.zoneUrl) }
            >
              {tableSelected.zoneId} <img 
                src="../assets/img/icon/icon-chart.svg" 
                alt="Chart" 
                width="16" 
                height="16" 
                style={{marginTop: -6}}
              />
            </span> :
            <span>{tableSelected.zoneId}</span> 
          }
        </LabelRow>
        {metricLabel && metricLabel.indexOf("currentMode") < 0 ? <LabelRow>
          <span>Mode</span>
          <span>{modeLabelValue}</span>
        </LabelRow> : null}
        {metricLabel && metricLabel.indexOf("currentAngle") < 0 ? <LabelRow>
          <span>Angle</span>
          <span>{angleLabelValue}</span>
        </LabelRow> : null}
        {/* {metricLabel && metricLabel.indexOf("errorCode") < 0 && metricLabel.indexOf("Over_current_Fault_01") < 0 ? <LabelRow> */}
        {metricLabel && metricLabel.indexOf("errorCode") < 0 ? <LabelRow>
          <span>Error</span>
          <span>{errorLabelValue}</span>
        </LabelRow> : null}
        {metricLabel ? <LabelRow>
          <span>{metricLabel}</span>
          <span>{metricLabelValue}</span>
        </LabelRow> : null}
      </LabelPanelStyle> : null}
      {/* {summary.length > 0 ? <LabelPanelStyle right={18} top={160} minWidth={280}>
        <LabelPanelHeading>Summary</LabelPanelHeading>
        {summary.map(t => <LabelRow>
          <span>{t.label}</span>
          <span>{t.value}</span>
        </LabelRow>)}
      </LabelPanelStyle> : null} */}
      {/* <LabelPanelStyle right={18} top={160}>
          <LabelPanelHeading>Tools</LabelPanelHeading>  
            <ContinuousRow>
              <ActionButton>
                <img 
                  src="../assets/img/icon/icon-chart.svg" 
                  alt="Go To" 
                  width="16" 
                  height="16" 
                  style={{marginTop: -1, marginLeft: -3}}
                />
              </ActionButton>
              <ActionButton>
                <img 
                  src="../assets/img/icon/icon-info.svg" 
                  alt="Go To" 
                  width="16" 
                  height="16" 
                  style={{marginTop: -1, marginLeft: -3}}
                />
              </ActionButton>
              
            </ContinuousRow>
      </LabelPanelStyle> */}
      {labels && timeValue !== null && metricData.length > 0 && Object.keys(marks).length > 1 ? <TimeSeries>
        <SliderControl 
          onChange={onTimeValueChange}
          minValue={0}
          marks={marks}
          maxValue={metricData.length - 1}
          value={timeValue}
          label={timeString}
        />
        {marks && Object.keys(marks).length > 36 ? <DateLabel>{dateString}</DateLabel> : null}
      </TimeSeries> : null}
      <Container3d
        aspect={width/height}
        addControls={true}
        addGrid={false}
        ref={containerRef}
        key={"c3d"}
        height={height}
        width={width}
        setup={setupCanvas}
        onUpdateAngles={updateAngles2}
      />
    </div>
  }

  export default ModelViewer