import React from "react"
import MetricsList from "../../forms/metrics"
import MetricsDownloaderViewer from "../../forms/metrics_download"


export const MetricsViewer = () => {
    return (
      <div className="col-2 d-none d-lg-block right-sidebar">
        <MetricsDownloaderViewer />
        <MetricsList />
      </div>
  
    )
  }