import React, { useMemo } from 'react';
import Select from 'react-select';

export const customDropDownStyles = {
  option: (provided, state) => ({
      ...provided,
      // borderBottom: "2px dotted green",
      color: state.isSelected ? "#014059" : "#014059",
      backgroundColor: state.isSelected ? "#D6F3FF" : state.isFocused ? "#D6F3FF6e" : "rgba(255, 255, 255, 1)",
      // backgroundColor: state.isFocused ? null : "rgba(255, 255, 255, 0.9)"
      //textAlign: "left",
      fontSize: "14px",
      fontWeight: "400",
      //backgroundColor: "rgba(0, 0, 0, 0.05)",
      color: "#014059", 
      padding: "4px 8px",         
  }),
  menu: (provided, state) => ({
      ...provided,
      zIndex: 9999, // Adjust the z-index as needed
      backgroundColor: "rgba(255, 255, 255, 1)",
      color: "#014059", 
      paddingLeft: "4px",
      top: "auto", // Remove the fixed top position
      marginTop: "10px" // Add margin to create space between header and dropdown
    }),
  groupHeading: (provided, state) => ({
      ...provided,
      // backgroundColor: "rgba(0, 0, 0, 0.5)",
      fontSize: "14px",
      padding: "4px 8px",
      // paddingLeft: "4px",
      // paddingRight: "2px",
      // paddingBottom: "8px",
      // paddingTop: "2px",
      borderBottom: "gray",
      borderWidth: "1px",
      color: "#014059",
      fontWeight: "600"
  }),
  control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "hsl(0, 0%, 95%)" : "#ffffff",
      borderColor: state.isDisabled ? "hsl(0, 0%, 90%)" : "#014059",
      color: "#014059",
      fontSize: "14px",
  }),
  input: (provided, state) => ({
      ...provided,
      color: "#014059",
      padding: "0px",
      innerHeight: "0px",
  }),
  singleValue: (provided, state) => ({
      ...provided,
      // color: "#006cff;"
      color: "#014059",
      fontSize: "14px",
      fontWeight: "400",
  }),
  multiValue: (provided, state) => ({
      ...provided,
      // color: "#006cff;"
      color: "#ffffff",
      backgroundColor: "#9FA9BC",
      borderRadius: "50px",
      padding: "0px",
      fontSize: "12px"
  }),
  multiValueLabel: (provided, state) => ({
      ...provided,
      // color: "#006cff;"
      color: "#fff",
      fontSize: "100%",
      fontWeight: "500",        
  }),
  multiValueRemove: (provided, state) => ({
      ...provided,
  ':hover': {
     backgroundColor: "#9FA9BC",
   },  
  }),
  loadingIndicator: (provided, state) => ({
      ...provided,
      color: "#014059"
  }),
  dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "hsl(0, 0%, 90%)"  : "#014059"
  }),
  clearIndicator: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "hsl(0, 0%, 90%)"  : "#014059"
  }),

}

const SingleDropDownControl = (props) => {
  const name = "singleSelect";

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      props.handleChange(selectedOption);
    }
  };

  const customStyles = useMemo(() => (customDropDownStyles), []); // Assuming customDropDownStyles is defined elsewhere

  return (
    <div className={`option ${props.multi ? 'multiSelectOption' : ''}`}>
      <Select
        key={name}
        styles={customStyles}
        placeholder={props.title}  // Ensure the placeholder is set to the title
        isMulti={props.multi || false}
        isClearable={props.isClearable || false}
        isLoading={props.loading}
        isDisabled={props.loading || props.isDisabled}
        isSearchable={true}
        value={props.value}
        onChange={handleChange}
        options={props.options}
        maximumSelectionLength={2}
      />
    </div>
  );
};

export default SingleDropDownControl;