import { axisStyle } from "./components/common";
import { maxOfArray, maxPercentages, stripTimestampData } from "./echart.datafuncs";
import { labelOptionABC } from "./echarts.label";

export const linebarTemplate = {
    smooth: true,
    areaStyle: { opacity: 0.4 },
    linestyle: { width: 0.5, opacity: 0.5 },
    // emphasis: { 
    //     lineStyle: {width: 0.5}, 
    //     areaStyle: {opacity: 0.6}, 
    //     blurScope: "series",
    //     label: { show: true, formatter: '{a}', offset: [-40,10],  } ,

    // },
    symbol: 'none',
    symbolSize: 0
}



export const defaultOption = {
    grid: [
        { id: 'time', left: '8%', width: '95%', bottom: "25%", top: "10%" },
        { id: 'majority', right: '5%', width: '5%', bottom: "25%", top: "10%", z: 20, },
        { id: 'top', left: '5%', left: '15%', width: '55%', height: '15%', top: "0%", containLabel: true, z: 10 },
    ],
    xAxis: [
        axisStyle("Datetime", -40),
        // { gridIndex: 1, show: false}, // pie summary
        { gridIndex: 1, show: false, type: 'value', inverse: true }, // bar summary
        { gridIndex: 2, show: false, type: 'value' },
        //axisStyle("", -40, 1)
    ],
    yAxis: [
        axisStyle("", 10, "end"),
        // { type: 'category', gridIndex: 1, show: false, }, // pie summary
        {
            type: 'category', gridIndex: 1, show: false,
            // position: 'right', 
            axisLabel: { inside: true, verticalAlign: 'top', },
        }, // bar summary
        { type: 'category', gridIndex: 2, show: false, data: [] },

        //axisStyle("Uptime %", 30, 1)
    ],
    legend: { type: 'scroll', itemGap: 35, bottom: '5%', left: "5%", orient: 'horizontal', /*data: legenddata, selector: true,*/ textStyle: { color: "#4C4345", fontWeight: "400", fontFamily: "'Montserrat', sans-serif;", fontSize: "10" } },
    toolbox: {
        show: false,
        orient: "horizontal",
        left: "76%", bottom: "92%",
        iconStyle: { borderColor: 'rgba(228, 228, 228, 0.8)' },
        //emphasis: {iconStyle: {borderColor: 'rgba(28, 28, 228, 0.8)'}},
        feature: {
            dataView: { readOnly: false },
            //magicType: { type: ["line", "bar", 'stack'] },
            restore: {},
            saveAsImage: {}
        }
    },
    axisPointer: {
        link: { xAxisIndex: 'all' },
        label: {
            color: '#333',
            backgroundColor: '#eee'
        },
        snap: true
    },

    tooltip: {
        confine: true,
        axisPointer: { type: 'cross', snap: true },
        backgroundColor: '#B7D0DA',
        borderWidth: 0,
        extraCssText: 'max-height:400px; width: 170px; overflow:auto; white-space:pre-wrap;',
        textStyle: { fontSize: 14, color: "#014059", overflow: 'break' }

    },
    dataZoom: [
        { type: 'inside', start: 0, end: 100 },
        { type: 'slider', xAxisIndex: 0, show: true, top: '95%', left: '10%', right: '20%', width: '80%', height: '3%', start: 0, end: 100 },
        // { type: 'slider', yAxisIndex: 0, show: true, filterMode: 'empty', width: 12, height: '70%', handleSize: 8, showDataShadow: false,left: '93%' }

    ],
}

export const summaryGrid = {
    grid: [
        { id: 'time', left: '4%', width: '80%', bottom: "25%", top: "10%" },
        // { id: 'majority', left: '80%', width: '50%', bottom: "25%", top: "10%", z: 20, },
        { id: 'majority', left: '80%', width: '15%', bottom: "25%", top: "10%", z: 20, },
        { id: 'top', left: '15%', width: '55%', height: '15%', top: "0%", containLabel: true, z: 10 },
    ],
}


export const pieTemplate = {
    type: 'pie',
    radius: [0, "35%"],
    // top: '0%',
    height: 'auto',
    center: ["95%", "50%"],
    // left: 'right',
    width: "auto",
    z: 10,
    zlevel: 10,
    emphasis: {
        focus: 'series',
        blurScope: 'coordinateSystem'
    },
    tooltip: { trigger: 'item', formatter: '{b0}: {c0}', 
        position: ['90%', '50%'], 
    },
    itemStyle: {
        borderColor: '#fff',
        borderWidth: 1,
        opacity: 0.75
    },
    label: labelOptionABC,
    labelLine: {
        show: true,
        length2: 15,
    },
    labelLayout: function (params) {
        return {
          x: '90%',
        //   x: params.rect.x + 10,
          y: params.rect.y + params.rect.height /3,
        //   verticalAlign: 'middle',
          align: 'right',          
          moveOverlap: 'shiftY',
          hideOverlap: false
        };
    },
    xAxisIndex: 1,
    yAxisIndex: 1,

};

export const summaryBarTemplate = {
    type: 'bar',
    label: {
        position: 'top', show: true, formatter: '{b}: {@score}',
        color: '#eee', 
        align: 'right'
    },
    xAxisIndex: 1,
    yAxisIndex: 1,
    barWidth: "10%",
    barMaxWidth: "25%",
    barMinWidth: "10%"
};

export function getPieMajoritySeries(data, iTimestamp) {
    const summaryTotal = stripTimestampData(maxOfArray(data.values), iTimestamp)
    const columns = stripTimestampData(data.columns, iTimestamp)
    const majorityData = columns.map((e, i) => { return { name: e, value: summaryTotal[i] } })
    return Object.assign({}, pieTemplate, { name: 'majority', data: majorityData })

}

const maxMajorityBarYAxisTemplate = {
        type: "category",
      //   data: [],
        position: 'left',
        gridIndex: 1,
        show: true
}
const maxMajorityBarXAxisTemplate = {
        type: "value",
        position: "right",
        inverse: true,
        gridIndex: 1,
        show: true
}
const maxMajorityBarSeriesTemplate = {
    name: "max-majority",
    type: "bar",
    barWidth: "10",
    xAxisIndex: 1,
    yAxisIndex: 1,
  //   data: []
}

const maxMajorityBarTemplate = {
    color: ["#3398DB"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow"
      }
    },
    grid: {},
    yAxis: [{
      type: "category",
    //   data: [],
      position: 'left',
      
    }],
    xAxis: [{
      type: "value",
      position: "right",
      inverse: true
    }],
    series: [{
      name: "",
      type: "bar",
      barWidth: "60%",
    //   data: []
    }]
  
}

export function getMaxBarMajoritySeries(data, iTimestamp) {
    const summaryTotal = stripTimestampData(maxOfArray(data.values), iTimestamp)
    const columns = stripTimestampData(data.columns, iTimestamp)
    const majorityData = columns.map((e, i) => { return { name: e, value: summaryTotal[i], xAxisIndex: 1,  yAxisIndex: 1, type: 'bar' } })
    // const dataoptions = { yAxis: [{ data: columns}], series: [ {name: 'max-majority', data: summaryTotal} ] }
    // const series =  Object.assign({}, maxMajorityBarSeriesTemplate, {  data: summaryTotal})
    // const yAxis =  Object.assign({}, maxMajorityBarYAxisTemplate, {   data: columns })
    // const xAxis =  Object.assign({}, maxMajorityBarXAxisTemplate, {  })
    // const options = {series: series, yAxis: yAxis, xAxis: xAxis}
    // console.log(columns, summaryTotal, options)
    // return options
    // return Object.assign({}, maxMajorityBarSeriesTemplate, { name: 'majority', data: majorityData })
    return majorityData


}

const majoritybartemplate = {
    type: 'bar',
    // z: 10,
    stack: 'total',
    label: {
        show: true
    },
    emphasis: {
        focus: 'series'
    },
    xAxisIndex: 2,
    yAxisIndex: 2,
    // data: [320, 302, 301, 334, 390, 330, 320]

}
export function getBarMajoritySeries(data, iTimestamp) {
    const summaryTotal = stripTimestampData(maxOfArray(data.values), iTimestamp)
    const percentValues = maxPercentages(summaryTotal)
    const majorityData = stripTimestampData(data.columns, iTimestamp).map((e, i) => { return Object.assign({}, majoritybartemplate, { name: e, data: [percentValues[i]] }) })
    return majorityData

}

export function getBarMajoritySeries2(data, iTimestamp) {
    const summaryTotal = stripTimestampData(maxOfArray(data.values), iTimestamp)
    const percentValues = maxPercentages(summaryTotal)
    const columns = stripTimestampData(data.columns, iTimestamp)
    const majorityData = columns.map((e, i) => { return { name: e, value: percentValues[i] } })
    return Object.assign({}, majoritybartemplate, { name: 'majority', data: majorityData })

    // const majorityData = stripTimestampData(data.columns, iTimestamp).map((e, i) => { return Object.assign({}, majoritybartemplate, { name: e, data: [percentValues[i]] }) })
    // return majorityData

}