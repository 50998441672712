import React, { memo, useEffect, useMemo, useState } from "react";
import WindowedSelect, { components, createFilter } from "react-windowed-select";
import axios from 'axios'
import styled from 'styled-components';
import { customDropDownStyles } from "../controls/multiselect_control";
import { useUserContext } from "../../context/user.context";
import { useCriteriaContext } from "../../context/criteria.context";
import { useModelContext } from "../../context/model.context";
import { getAccessToken } from '../auth/userinfo'

const StyledDiv = styled.div `
    display: inline-block;
    width: 180px;
    margin: 3px 16px auto 5px; 
`

export default () => {
    const [options, setOptions] = useState([])

    const modelContext = useModelContext()

    const option = options.filter(t => t.value === modelContext.selectedVersion)
    const selectedOption = option.length > 0 ? option[0] : null

    const customFilter = createFilter({ ignoreAccents: false });
    const customComponents = {
      ClearIndicator: (props) => (
        <components.ClearIndicator className="btn-clear" {...props}>Clear</components.ClearIndicator>
      )
    };
    
    const customStyles = useMemo(
        () => (customDropDownStyles), []
    );

    
    const userContext = useUserContext()
    const criteriaContext = useCriteriaContext()
    const url = useMemo(() => {
      if (criteriaContext.site.length > 0) {
        const site = userContext.sites.filter(site => site.site_id === criteriaContext.site)
        if (site.length > 0 && site[0].model_url) {
          const _url = (site[0].model_url)
          return _url.replace("sunops/layout", "sunops/versions")
        }

        return ""
      }
    }, [userContext.sites, criteriaContext.site])

    useEffect(() => {
        if (url) {
            
            let token = getAccessToken()
        
            axios({
                url: `model/versions?url=${url}`,
                headers: {
                    'x-access-token': token,
                    'Content-Type': 'application/json'
                },
                method: 'GET',
            }).then(result => {
                const allOptions = result.data.map(t => ({
                    label: `Version ${t.name}${t.notes ? " - " : ""}${t.notes.substring(0, 99)}${t.notes.length > 99 ? '..' : ''}`,
                    value: t.name
                }))
                setOptions(allOptions)
            }).catch(err => {
                console.log(err)
            })
        }
        else
            setOptions([])
    }, [url])

    useEffect(() => {
        modelContext.setSelectedVersion('')
    }, [criteriaContext.site])

    const changeVersion = version => {

        // setSelectedOption(version)
        modelContext.setSelectedVersion(version ? version.value || '' : '')
    }

    return <StyledDiv class="version-select">
        <WindowedSelect
            style={{width: 100}}
            className="version-select-child"
            options={options}
            onChange={option => changeVersion(option)}
            value={selectedOption}
            components={customComponents}
            isClearable={true}
            filterOption={customFilter}
            styles={customStyles}
            placeholder={"Select Version"}
        />
        <style>
            {`.version-select-child .css-r0lpgd-menu{
                z-index: 5 !important;
            }`}
        </style>
    </StyledDiv>
}

