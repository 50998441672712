import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import Maintenance from './maintenance'
import Activities from './activities'
import axios from 'axios'
import { getAccessToken } from '../auth/userinfo'
import { AiOutlinePlus, AiOutlineTable, AiOutlinePicCenter } from 'react-icons/ai'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range' 
import { Button, Badge } from 'react-bootstrap'
import { useNotificationContext } from '../../context/notification.context'
import { useCriteriaContext } from '../../context/criteria.context'
        
const Heading = styled.div`
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .add-button {
        background: #014059;
        font-size: 16px;
        

        :active, :hover, :focus {
            background: #014059 !important;
        }
    }

    .section {
        flex-direction: row;
        display: flex;
        gap: 16px;
        align-items: center;
    }

    .timezone {
        font-size: 12px;
        color: #8693ab;
    }
`
    
const Heading2 = styled.div`
    font-size: 16px;
    font-weight: 500;
    padding: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`

export const InfoStyle = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  text-align: center;
  background: ${props => props.type === 'success' ? '#d1e7dd' : '#ffa2b0'};
  padding: 5px 10px;
  border-radius: 0px 0px 4px 4px;
  z-index: 1000;
`

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;

    .button {
        color: #bbbbbb;
        cursor:  pointer;
        margin-bottom: -3px;
    }

    .active {
        color: #014059;
    }

    .badge {
        color: #0140598a;
        cursor: pointer;
    }

    .badge.active {
        background: #014059 !important;
        color: white;
    }
`

function StageFilter(props) {
    const stages = [
        { label: 'All', value: 'all' },
        { label: 'Ongoing', value: 'ongoing' },
        { label: 'Not started', value: 'not-started' },
        { label: 'Completed', value: 'complete' },
    ]
    return (
        <Actions>
            {stages.map(stage => <Badge pill   
                bg="light" 
                className={`${props.stage === stage.value ? 'active' : ''}`} 
                onClick={() => props.setStage(stage.value)}
            >
                {stage.label}
            </Badge>)}
        </Actions>
    );
  }

export default props => {

    const [screen, setScreen] = useState("new")
    const [allActivities, setAllActivities] =   useState([])
    const [activities, setActivities] = useState([])
    const [error, setError] = useState(null)
    const [showNew, setShowNew] = useState(false)
    const [timezone, setTimezone] = useState(moment().tz())
    const [view, setView] = useState('grid')
    const [stage, setStage] = useState('all')

    const criteria = useCriteriaContext()

    const notificationContext =  useNotificationContext()

    
    const { showOngoing, setShowOngoing } =  notificationContext

    const urlGet = (query) => {
        let token = getAccessToken()
        return axios({
            url: `maintenance?querytype=${query}&pf=${criteria.pf}&restype=json&project=depcom`,
            method: 'GET',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        })
      }

    const getScopeFromCriteria = (site, zone, device) => {
        let scope = site
        if (zone) {
            scope = scope + "/" + zone
        }
        if (device) {
            scope = scope + "/" + device
        }
        
        return scope
    }

    const getActivities = (allActivities, stage) => {
        const currentDate = moment()
        if (stage === 'ongoing') {
            const totalActivities = allActivities.filter(t => 
                (t.end_date === null || moment(t.end_date).isAfter(currentDate, 'day')) && 
                moment(t.start_date).isSameOrBefore(currentDate, 'day')
            )
            const oldActivities = totalActivities.filter(t => t.old)
            const otherActivities = totalActivities.filter (t => !t.old)
            return [...oldActivities, ...otherActivities]
        } else if (stage === 'not-started') {
            return allActivities.filter(t => 
                moment(t.start_date).isAfter(currentDate, 'day')
            )
        } else if (stage === 'complete') {
            return allActivities.filter(t => 
                moment(t.start_date).isSameOrBefore(currentDate, 'day') &&
                moment(t.end_date).isSameOrBefore(currentDate, 'day')
            )
        } else {
            return allActivities
        }
    }

    const endActivity = (id, end_date) => {
        setAllActivities(allActivities.map(t => t.id === id ? {...t, end_date, job_lock: 'ending'} : t))
        setActivities(activities.map(t => t.id === id ? {...t, end_date, job_lock: 'ending'} : t))
    }

    const setReminder = (id, remind_after2) => {
        setAllActivities(allActivities.map(t => t.id === id ? {...t, remind_after2} : t))
        setActivities(activities.map(t => t.id === id ? {...t, remind_after2} : t))
    }
  
    useEffect(() => {
        urlGet('activities')
          .then(res => {
            const allActivities = res.data.map(t => ({...t, scope: getScopeFromCriteria(t.site_id, t.zone_id, t.device_id)}))
            const activities   = getActivities(allActivities, showOngoing ? 'ongoing' : stage)
            setAllActivities(allActivities)
            
            setActivities(activities)
            if (showOngoing) {
                setStage('ongoing')
                setShowOngoing(false)
            }
          })
          .catch(err => {
            setError({ message: err.message, type: 'error' })
          })
    }, [criteria.pf])

    useEffect(() => {
        let timeout = null
        if (error) {
            timeout = setTimeout(() => {
                setError(null)
            }, 3000)
        }

        return () => clearTimeout(timeout)
    }, [error])

    useEffect(() => {
        let timeout = null
        if (showNew) {
            timeout = setTimeout(() => {
                setShowNew(false)
            }, 3000)
        }

        return () => clearTimeout(timeout)
    }, [showNew])

    useEffect(() => {
        const activities = getActivities(allActivities, stage)

        setActivities(activities)
    }, [stage])


    return <Fragment>
        {error ? <InfoStyle type={error.type}> {error.message} </InfoStyle> : null}
        <Heading>
            <h3>{screen === 'new' ? 'Log maintenance activity' : 'Logged maintenance activities'}</h3>
            <div className="section">
                <span className="timezone">Timezone: {timezone || 'UTC'}</span>
                { screen === 'list' ? <Button className="add-button" onClick={() => setScreen(screen === 'new' ? 'list' : 'new')}><AiOutlinePlus /> Add New Activity</Button> : null }
            </div>
        </Heading>
        {screen === 'new' ? <Fragment>
            <Maintenance 
                setError={setError}
                addActivity={activity => {
                    const totalActivities =  [activity, ...allActivities]
                    setAllActivities(totalActivities)
                    const activities = getActivities(totalActivities, stage)
                    setActivities(activities)
                }} 
                setShowNew={setShowNew}
                setTimezone={setTimezone}
            />
            {allActivities.length > 0 ? <Heading2>
                <span>Recent activities</span>
                <StageFilter stage={stage} setStage={setStage}/>
                <Actions>
                    <AiOutlinePicCenter 
                        className={`button ${view === 'grid' ? 'active' : ''}`} 
                        onClick={() => setView('grid')}
                    />
                    <AiOutlineTable 
                        className={`button ${view === 'table' ? 'active' : ''}`} 
                        onClick={() => setView('table')}
                    />
                </Actions>
            </Heading2> : null }
            {activities.length > 0 ? <Activities 
                type="recent" 
                setScreen={setScreen} 
                activities={activities}
                showNew={showNew}
                view={view}
                timezone={timezone}
                endActivity={endActivity}
                setReminder={setReminder}
            /> : null }
        </Fragment> : <Activities type="list" activities={activities}/>}

    </Fragment>
}