import criteria, { isDevice, isPortfolio, isSite, isZone } from "../components/layout/criteria";
import { useCriteriaContext } from "../context/criteria.context";

export function getToolTipNonZero(iTimestamp, title) {
    const criteria = useCriteriaContext();
    return {
        trigger: "axis",
        confine: true,
        axisPointer: { type: 'cross', snap: true },
        backgroundColor: '#B7D0DA',
        extraCssText: 'max-height:400px; width: 570px; display: inline; overflow:auto; white-space:pre-wrap;',
        textStyle: { color: "#014059", fontSize: "14px", overflow: 'break' },
        enterable: true,
        formatter: (params) => {
            // console.log(params)
            // return '';
            if (params.length === 0) return ''
            var tt = `<h5><b>${params[0].data[iTimestamp]}</b></h5>`
            if((title === 'Errors Summary' || title === 'Modes Summary') && !isDevice(criteria)){
                tt += `<h5><b>No. of Devices</b></h5>`
            }
            else if (title === 'Errors Summary' && isDevice(criteria)){
                tt += `<h5><b></b></h5>`
            }
            else if(title === 'Modes Summary' && isDevice(criteria)){
                tt += `<h5><b></b></h5>`
            }

            tt += params.sort((a, b) => b.data[b.seriesIndex + 1] - a.data[a.seriesIndex + 1]).map((p, i) => {
                const wrap = (i % 4) === 0 ? "<br/>" : ""
                const val = p.data[p.seriesIndex + 1]
                const col = p.seriesName
                if ((title === 'Errors Summary' || title === "Modes Summary") && isDevice(criteria)){
                    return val !== null && val !== 0 ? `<div><b style="color: #014059;">&#8226; <span style="font-size: 1em;">${col}</span></b></div>` : '';
                }
                return val !== null ? `<b>${val}</b>  -  ${col}, ${wrap} ` : ''
            }
            ).join('')
            return tt
        },
    }
}

function group(items, key) {
    // get unique values for grouping key
    const unique = [
        ...new Set(items.map(item => item[key]))
    ];

    // will be ascending by default
    unique.sort();

    // sorting all of the results by title field
    const sortFn = (a, b) => a.title > b.title;

    const sortItems = (val) => {
        // filters the result set to items sharing the current group field value
        let sorted = items.filter(item => item[key] === val);
        // sort by title
        sorted.sort(sortFn);
        return sorted;
    }

    // reduce to a Map (which preserves insertion order and maintains the group key sorting)
    return unique.reduce((map, cur) => map.set(cur, sortItems(cur)), new Map());
}
export function getToolTipCategory(iTimestamp) {
    return {
        trigger: "axis",
        confine: true,
        axisPointer: { type: 'cross', snap: true },
        backgroundColor: '#B7D0DA',
        extraCssText: 'max-height:400px; width:auto; overflow:auto; white-space:pre-wrap;',
        textStyle: { color: "#014059", fontSize: "14px", overflow: 'break' },
        enterable: true,
        formatter: (params) => {
            // console.log(params)
            if (params.length === 0) return ''
            var tt = ''
            tt += `<h5><b>${params[0].data[iTimestamp]}</b></h5>`
            tt += "<table><tr>"
            const groupedItems = group(params, 'value')
            // console.log(params, groupedItems)
            for (let [category, items] of groupedItems) {
                // const s = items.map((p, i) => {
                //     // const val = p.value[i +1 ]
                //     // const col = p.dimensionNames[i +1]
                //     const val = p.data[p.seriesIndex + 1]
                //     const col = p.seriesName

                //     return val !== null ? `<b>${col} - ${val}</b><br/>` : ''
                // }).join('')
                // tt += `<td>${s}</td>`
                // tt += `<h6>availability average: <b>${category[2]}</b></h6>`
                tt += `<h6>impact: <b>${category[3]}</b></h6>`
                tt += `<h6>availability: <b>${category[1]}</b></h6>`
            }
            tt += '</tr></table>'

            return tt
        },
    }
}

