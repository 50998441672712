import { modes, errorCodes, trackingModes, errorCodesGC } from "../modelviewer/heatmap"
import Alerts from "./Alerts"
import React from 'react'


export const columns = [
    {
        dataField: "tracker_id",
        text: "Row #",
        selected: false,
    },
    {
        dataField: "site_id",
        text: "Site",
        selected: false,
    },
    {
        dataField: "zone_id",
        text: "Zone",
        selected: false,
    },
    {
        dataField: "device_id",
        headerStyle: { width: 100 },
        text: "DID",
        selected: true,
    },
    {
        dataField: "rover_offline",
        text: "Status",
        selected: true,
        style: function callback(cell, row) {
            return { color: cell === 1 ? "red" : "green" };
        },
        formatter: (cell, row) => (cell === 1 ? "Offline" : "Online"),
        type: "encoded",
    },
    {
        dataField: "errorCode",
        style: { display: "flex", flexDirection: "column", justifyContent:"space-between", gap: "5" },
        text: "Alerts",
        selected: true,
        formatter: (cell) => <Alerts cellData={cell}/>,
        formatter2: (cell) => cell ? cell.split(",").map(t => errorCodes[t.trim()]).join(",") : "",
        type: "encoded",
    },
    {
        dataField: "errorCodeGC",
        style: { wordBreak: "break-all" },
        text: "Error Code",
        selected: true,
        formatter: (cell) => (!errorCodesGC[cell] ? "" : errorCodesGC[cell]),
        type: "encoded",
        gc: true
    },
    {
        dataField: "currentAngle",
        text: "Angle",
        selected: true,
        type: "dynamic",
    },
    {
        dataField: "currentMode",
        style: { wordBreak: "break-all" },
        text: "Mode",
        selected: true,
        formatter: (cell) => modes[cell],
        type: "encoded",
    },
    {
        dataField: "pvVoltage",
        text: "PV Voltage",
        selected: true,
        type: "dynamic",
    },
    {
        dataField: "pvCurrent",
        text: "PV Current",
        selected: true,
        type: "dynamic",
    },
    {
        dataField: "batteryVoltage",
        text: "Battery Voltage",
        selected: true,
        type: "dynamic",
    },
    {
        dataField: "batteryCurrent",
        text: "Battery Current",
        selected: true,
        type: "dynamic",
    },
    {
        dataField: "comm loss",
        text: "Comm Loss",
        selected: true,
        type: "encoded",
        style: function callback(cell, row) {
            return { color: cell === 1 ? "red" : "green" };
        },
        formatter: (cell, row) => (cell === 1 ? "Yes" : "No"),
        gc: true
    },
    {
        dataField: "tracking disabled",
        text: "Tracking Disabled",
        selected: true,
        type: "encoded",
        style: function callback(cell, row) {
            return { color: cell === 1 ? "red" : "green" };
        },
        formatter: (cell, row) => (cell === 1 ? "Yes" : "No"),
        gc: true
    },
    {
        dataField: "tracker angle",
        text: "Tracker Angle",
        selected: true,
        type: "dynamic",
        gc: true
    },
    {
        dataField: "tracking mode",
        text: "Tracking Mode",
        selected: true,
        type: "encoded",
        formatter: (cell) => trackingModes[cell],
        gc: true
    },
    {
        dataField: "target angle",
        text: "Target Angle",
        selected: true,
        type: "dynamic",
        gc: true
    },
    {
        dataField: "last_updated",
        text: "Last Updated",
        selected: true,
        formatter: (cell) => {
            const diff = Math.floor(
                (Date.now() -
                    (cell.toString().length <= 10 ? cell * 1000 : cell)) /
                    1000
            );
            const minute = 60;
            const hour = 60 * minute;
            const day = 24 * hour;
            let duration;

            if (diff < minute)
                return `${diff} ${diff === 1 ? "second" : "seconds"} ago`;
            else if (diff < hour) {
                duration = Math.floor(diff / minute);
                return `${duration} ${
                    duration === 1 ? "minute" : "minutes"
                } ago`;
            } else if (diff < day) {
                duration = Math.floor(diff / hour);
                return `${duration} ${duration === 1 ? "hour" : "hours"} ago`;
            } else {
                duration = Math.floor(diff / day);
                return `${duration} ${duration === 1 ? "day" : "days"} ago`;
            }
        },
    },
];