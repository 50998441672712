import React, { Component } from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
// import GoogleLogin from 'react-google-login'
// import FacebookLogin from 'react-facebook-login'
import {compressAndEncode, compressAndEncode_pass} from '../model/utils'
import '../../App.css'
import styles from './auth.module.css'
import {validateEmail, Error, isEmptyField} from '../../helpers/validation-helpers'


export function logout(){
    var logoutUser = axios({
    method: 'GET',
    url: '/auth/close_session',
    // headers: { 'x-refresh-token': getRefreshToken(), 'Content-Type': 'application/json;charset=utf-8','credentials': 'include' }
    })
    .finally(() => {
      localStorage.setItem('user', '')
      localStorage.setItem('tkey', '')
      localStorage.setItem('timezones', '')
      window.location = window.location.origin
    });
}
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      errors: {}
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    let stateValue = value;
    if (name === "password") {
      stateValue = compressAndEncode_pass(value);
    }
    this.setState({ [name]: stateValue })
  }

  handleSubmit = () => {
    const { email, password } = this.state
    const errors = {}
    // Convert email to lowercase
    const lowercaseEmail = email.toLowerCase();
    if(isEmptyField(lowercaseEmail)){
      errors['email'] = 'Email is required'
      this.setState({errors: errors})
      return
    }else if(!validateEmail(lowercaseEmail)){
      errors['email'] = 'Email is invalid'
      this.setState({errors: errors})
      return
    }

    if(isEmptyField(password)){
      errors['password'] = 'Password is required'
      this.setState({errors: errors})
      return
    }

    if (password.length > 0 && lowercaseEmail.length > 0) {
      const data = JSON.stringify({ email: lowercaseEmail, password });
      this.props.loginUser(data)
    }
  }

  render() {
    const { loginError, loading, auth } = this.props

    return (
      <>
        <div className={styles.loginbody}>
          <div className={styles.main1}>
            <img src="/page-cover1.jpg"></img>
          </div>
          <div className={styles.loginbox}>
            <div className={styles.loginboxbpad}>
              <div
                style={{ marginBottom: '2rem' }}
                className={styles.maintittle}
              >
                Sign In
              </div>
              {loginError ? (
                <h4 className="pl-5 pb-4">Username or Password is incorrect</h4>
              ) : null}
              <div>
                <label className={styles.registerLabel}>
                  Email<span style={{ color: 'red' }}>*</span>
                </label>
                <div>
                  <input
                    placeholder="Email"
                    className={styles.inputTag}
                    value={this.state.email}
                    name="email"
                    onChange={this.handleChange}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        this.handleSubmit();
                      }
                    }}
                  />
                  <Error type='email' errors={this.state.errors}/>
                </div>
                <label className={styles.registerLabel}>
                  Password<span style={{ color: 'red' }}>*</span>
                </label>
                <div>
                  <input
                    placeholder="password"
                    className={styles.inputTag}
                    value={this.state.orgName}
                    name="password"
                    type="password"
                    onChange={this.handleChange}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        this.handleSubmit();
                      }
                    }}
                  />
                  <Error type='password' errors={this.state.errors}/>
                </div>
                <div className={styles.anchorTagDiv} style={{display: 'flex', marginRight: '3rem', justifyContent: 'end'}}>
                  <a href="#" onClick={() => this.props.setStep(1)}>Forgot password?</a>
                </div>
                <div className={styles.sendbutton}>
                  <button
                    className={styles.registerBtn + ' ' + styles.loginBtn}
                    onClick={this.handleSubmit}
                    disabled={loading}
                  >
                    {loading ? 'Authenticating...' : 'Login'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Login
