//import logo from './logo.svg';
import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter as Router
// } from 'react-router-dom'
import { SWRConfig } from "swr";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";
import {
  getUserID,
  getUserToken,
  getIdleTimeoutInterval
} from "./components/auth/userinfo";
import { MenuBar } from "./components/layout/navmenu";
import UserChartNav from "./components/layout/usercharts";
import Dialog from "./layout/dialog";
import Route from "./navigation/route";
import HomeViewBase1 from "./components/pages/home.page";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IdleTimeOutHandler from "./components/auth/IdleTimeOutHandler";
import { usePageNotFoundContext } from "./context/pageNotFound.context";
//import { useUserContext } from "./context/user.context";
//import PortfolioUptimeLast7days from './components/layout/stats'
// {/* <Suspense fallback={<div>Loading...</div>}>
// </Suspense> */}

const PageNotFound = () => {
  const style = {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }

  const redirectToDashBoard = () => {
    localStorage.setItem("activePf",'')
    window.location.href = window.location.origin
    window.reload()
  }
  
  return (<div style={style}>
    <h1 style={{fontSize: '50px'}}>404</h1>
    <h1 style={{fontSize: '25px'}}>The page you were looking for could not be found. <br/><span style={{paddingLeft: '70px'}}>We suggest you go back to Dashboard.</span></h1>
    <button onClick={redirectToDashBoard} className="btn btn-secondary active mt-2">Back to Dashboard</button>
  </div>)
}

function App() {

  const pageNotFoundContext = usePageNotFoundContext();
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        fetcher: (...args) => fetch(...args).then((res) => res.json()),
      }}
    >
      <div className="App">
        {getIdleTimeoutInterval() > 0 && <IdleTimeOutHandler />}
        {!pageNotFoundContext.isPageNotFound ? (
          <div
            id="page-container"
            className="container-fluid fade page-sidebar-fixed show"        
          >
            <MenuBar />
            <Dialog />
            <UserChartNav userid={getUserID()} />  
            <ToastContainer />
            <div id="content" className="content">
              <Route path="/">
                <HomeViewBase1 />
              </Route>
            </div>
          </div>
        ) : <PageNotFound/>}

      </div>
    </SWRConfig>
  );
}


export default App;
