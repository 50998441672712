import React, { useState } from 'react'
import styled from 'styled-components';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
import { AiOutlineCheck } from 'react-icons/ai'
import { useRealtimeContext } from '../../context/realtime.context';

const StyledDiv = styled.div `
    display: inline-block;
    width: 180px;
    margin: 3px 16px auto 5px; 

    .menu {
        width: 97%;
    }
`

export default props => {
    const realtimeContext = useRealtimeContext()
    const { rowAttrs, selectColumn } = realtimeContext;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    
    return (
        <StyledDiv>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>Select Columns</DropdownToggle>
                <DropdownMenu className="menu">
                    {rowAttrs.map(t => 
                        <DropdownItem onClick={() => selectColumn(t.dataField)} toggle={false}>
                            <AiOutlineCheck style={{visibility: t.selected ? 'visible' : 'hidden'}}/>  {t.text}
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        </StyledDiv>
    );
      
}