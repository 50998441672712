import React, { useMemo, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from "react-tooltip";
import useSWR from 'swr';
import { useDataviewActionContext } from '../../context/dataview.context';
import { useStore, useStoreActionContext } from '../../context/store.context';
import { InfoTooltip } from '../../model/info-tooltip';
import { DownloadCsv_Zone_Summary_table } from '../chart/csv.download';
import NotifyTooltip from '../../model/notify-tooltip'
import { Card4Opacity80 as Card, CardSummary } from '../layout/Card';
import { FetchData2 } from '../model/store.swr';
import { TooltipHelpContent } from '../pages/tooltip-help-content';
import TableContainer from './TableContainer';
import { ColumnAlarms, ColumnErrors, ColumnUptime, ColumnUptimeImpact, alarmData, findDevice, getDeviceName, getLastSeenTime, renderColumn, uptimeImpact,ColumnExpectedPower,ColumnActualPower,ColumnPowerVariance } from './summary.row';
import { usePageContext, energyMeterPage, usePageActionContext } from '../../context/page.context';
import { createActivityLog, getStatsData } from '../../utils/apiCall';
import { getLogData, getWeightedAvg, handleStatsAsync } from '../../helpers/common';  
import { GetFQURL } from '../model/store.swr';
import _ from 'lodash'
import { useAvailabilityContext } from '../../context/availability.context';


const urls = [
    {name: 'summaryData', url: "overview_summary"},
]



const RcSummaryTable = (props) => {

    const [response, setResponse] = useState({})
    const [error, setError] = useState({})    
    const pageContext = usePageContext();
    const pageName = pageContext.page;
    const criteria = useStore().criteria
    const dataviewaction = useDataviewActionContext()
    const pageActionContext = usePageActionContext()
    const storeAction = useStoreActionContext()
    const availabilityContext = useAvailabilityContext()

    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Escape') {
            event.preventDefault();
            var popup = document.getElementById("myPopup");
            popup.classList.remove("show");
          }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []);

      useEffect(() => {
        // if(criteria.zone){
        //     createActivityLog(getLogData(criteria, {featureId: `view-zone-summary`, usecase: `view zone summary`, category: 'overview', level: 'zone'}))
        // }
      },[criteria.zone, criteria.Te, criteria.Ts])


      
    useEffect(() => {
        setResponse({})
        setError({})
        const url = GetFQURL(urls[0], criteria, 'json', false,[],null,null,null, {})
        handleStatsAsync(url,setResponse,setError,availabilityContext)
        // getStatsData(url,setResponse,setError)
    },[criteria.device, criteria.Ts, criteria.Te])

    // const response = FetchData2(useSWR, criteria, urls)

    console.log("checking response....",response)

    if (_.isEmpty(response)) return <CardSummary>Loading ...</CardSummary>
    const res_availability = Array.isArray(response) ? (response[0].availability_avg || null) : (response.data.availability_avg || null)
    const availability_avg = res_availability[0].availability
    const res_tracker_communication = Array.isArray(response) ? (response[0].tracker_communication_avg || null) : (response.data.tracker_communication_avg || null)
    const tracker_communication_avg = res_tracker_communication && res_tracker_communication[0].tracker_communication
    

    const tooltipContent = TooltipHelpContent.RCSummary
    const tooltip = (
        <div>
            <h5>{tooltipContent.header}</h5>
            {tooltipContent.body}
        </div>
    )

    return (
        <CardSummary>
            {/* <a href="#"> */}
            <div className="popup">
                <div className="card-body">
                    <h5 className="card-title">RC Summary <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} /></h5>
                    <div className="text-white h6 card-text"><span>Availability Avg. : {availability_avg ? `${availability_avg}%` : 'No Data'}</span>{availabilityContext.withMaintenance ? 
                        <NotifyTooltip  content =   "Adjusted for maintenance activities"    onClick={() => pageActionContext.setPage("Site Maintenance", [], false)}/>
                        : null}
                    </div>
                    <div className="text-white h6 card-text"><span>Tracker Comms : {tracker_communication_avg ? `${tracker_communication_avg}%` : 'No Data'}</span></div>
                </div>
            </div>
        </CardSummary>
    );

}


//export default withRouter(PortfolioSummaryTable);
export default RcSummaryTable;
