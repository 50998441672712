import React from 'react'
import { MetricsViewer } from '../layout/metricsview';
import DataviewClient from '../../layout/dataview';

const Downloads = ()  => {
    return (
        <div className="row">
            <div className="col-lg-10 col-xl-10 col-12">
                <div className="row zc-table">
                    <DataviewClient />
                </div>
            </div>
            <MetricsViewer />

        </div>
    )
}

export default Downloads