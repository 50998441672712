import zlib from 'react-zlib-js';
//import Zlib from 'react-zlib-js'
const pallette = ["#F44336","#E91E63","#9C27B0","#00BCD4","#FF9800","#8BC34A","#FFC107"]
//const pallette = ["#ffa600","#ff6361", "#bc5090","#58508d"]
export function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  
export function colorIndex(id){
    const colorindex = Math.abs(id % pallette.length)
    const color = pallette[colorindex]
    return color
}
export function getColorCode(metric){
    return getRandomColor()

}
const errorCodeTitle = {
  2:	'Over current Fault',
  4:	'Minimum Battery Voltage fault',
  10:	'Board Temperature Exceed fault',
  40:	'Motor Stall Fault',
  80:	'Zigbee Fault',
  100:	'Communication Fault',
  200:	'Inclinometer Fault',
  400:	'SPI Flash memory Fault',
  800:	'OTA Fault',
  1000:	'EEPROM Fault',
  2000:	'RTC Fault',
  4000:	'Unknown Fault'
};


export function getErrorCodeTitles(){
  return errorCodeTitle
}

export function getErrorCodeTitle(errorCode){
  return errorCodeTitle[errorCode]
}


const errorCodeDesc = {
  0: 'NO FAULT',
  1: 'Over Current Fault',
  2: 'Minimum Battery Voltage fault',
  4: 'Board Temperature Exceed fault',
  6: 'Motor Stall Fault',
  7: 'Zigbee Fault',
  8: 'Communication Fault',
  9: 'Inclinometer Fault',
  10: 'SPI Flash Fault',
  11: 'OTA Fault',
  12: 'EEPROM Fault',
  13: 'RTC Failure',
  14: 'Unknown Fault',
  15: 'Locking System Fault',
  16: 'Locked Moved Fault',
  17: 'Low Battery Stow Fault',
  18: 'Mechanical Overload Fault',
  33: 'ZC Blocked State',
  34: 'Set Command Flag Enabled',
  35: 'Mechanical Overload Occurred',
  19: 'Battery Charger Fault',
  20: 'Estop Fault',
  21: 'BLE Fault',
  22: 'External EEPROM Fault',
  23: 'Low temperature fault',
  36: 'Input Ac power not detected',
  37: 'Heating pad not detected',
  38: 'Energy Save State'
};

export function getAllErrorCodeDesc(){
  return errorCodeDesc
}

export function parseErrorCode(code) {
  const number = code;// >> 1;
  const binary = number.toString(2);
  const result = {};

  [...binary].reverse().forEach((e,i)  =>  {
    //console.log(i, e)
    if (e === '1') {
      //const index = getFirstSetBitPos(binary, i+1)
      const index = i+1;
      const desc = errorCodeDesc[index]
      if (desc !== undefined) {
        result[index] = desc
        

      }
    }
  })//.filter(f => f !== undefined)
  //console.log(code, ':' , binary , [...binary], result);

  //const index = getFirstSetBitPos(result, 0)
  return result
}

// TODO: Delete it
export function compressAndEncode_pass(data) {
  try {
    var compressedData = zlib.gzipSync(Buffer.from(data, 'utf-8'));
    return compressedData.toString("base64");
  } catch (err) {
    return "";
  }
}

export function compressAndEncode(data) {
  //  return data;
  try {
    var compressedData = zlib.gzipSync(Buffer.from(data, 'utf-8'));
    return compressedData.toString("base64");
  } catch (err) {
    return "";
  }
}

export function decodeAndDecompress(compressedBase64Data) {
  //  return compressedBase64Data;
  try {
    const compressedBuffer = Buffer.from(compressedBase64Data, 'base64');
    const decompressedData = zlib.gunzipSync(compressedBuffer);
    return decompressedData.toString('utf-8');
  } catch (err) {
    console.error('Error while decompressing and decoding:', err);
    return "";
  }
}