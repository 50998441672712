import React, { useState } from "react";
import ContextFactory from "./base.context";

const DialogContext = React.createContext();
const DialogActionContext = React.createContext();

// const defaultDialog = {Dialoglist, setDialogList} //{time: '', domain: ''}
export const useDialogContext = ContextFactory('DialogContext', DialogContext)
export const useDialogActionContext = ContextFactory('DialogActionContext', DialogActionContext)

const DialogContextProvider = (props) => {
    
    const initialize = (data) => {
        setState({...state, ...data})
        // console.log(data, state)
    }
    
    const clear = () => {
        setState({...state, ...initState})
        // console.log(state)

    }

    const initState = {
        showDialog: false, 
        showApply: false,
        title: '', 
        size: 10,
        Content: ()=> { return (<>No Content</>) },
        customClass:''
    } 

    const initActionState = {
        openDialog: initialize,
        closeDialog: clear,
    } 
    
    const [state, setState] = useState(initState)
    const [actionState, setActionState] = useState(initActionState)

    return (
        <DialogContext.Provider value={state}>
            <DialogActionContext.Provider value={actionState}>
                {props.children}
            </DialogActionContext.Provider>
        </DialogContext.Provider>
    )
}

export default DialogContextProvider
