import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import React,{useMemo} from "react";
import { isPortfolio, isSite, isZone } from "../../components/layout/criteria";
import { TooltipHelpContent } from "../../components/pages/tooltip-help-content";
import { defaultBarOption } from "../barchart";
import { defaultOption, getBarMajoritySeries, getPieMajoritySeries, linebarTemplate, summaryGrid } from "../echarts.series";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { getToolTipCategory, getToolTipNonZero } from "../echarts.tooltip";

import { useStoreActionContext } from "../../context/store.context";
import { useCriteriaContext } from "../../context/criteria.context";
import { getSites } from "../../model/metadata";
//import { useCommonActionContext } from "../../context/common.context";

function makeOptions(data, args) {

    if (!data) return defaultBarOption

    const iTimestamp = data.indexes[args.options.timeColumn]
    const iPlaceholder = data.indexes["placeholder"]

    // const majorityData = sumArray(data.values).map((e,i) => { return  i !== iTimestamp ? {name: data.columns[i], value: e}: undefined })
    const majorityPieSeries = args.options.showMajority ? getPieMajoritySeries(data, iTimestamp) : []
    const majorityBarSeries = args.options.showPercent ? getBarMajoritySeries(data, iTimestamp) : []
    const grid = args.options.showMajority ? summaryGrid : {}
    const itemColor = args.options && args.options.colors ? { itemStyle: { color: (si) => { return args.options.colors[si.seriesName] } } } : {}
    // console.log(args, itemColor)
    const xType = args.options.typeX && args.options.typeX === 'category' ? 'category' : 'time'
    const placehonderTemplate = {
        name: 'Placeholder',
        type: 'bar',
        stack: 'Total',
        itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
        },
        emphasis: {
            itemStyle: {
                borderColor: 'transparent',
                color: 'transparent'
            }
        },
    }

    const historicalSeries = data.columns.map((e, i) => {
        if (i !== iTimestamp) {
            if (i === iPlaceholder) {
                return Object.assign({}, placehonderTemplate, { encode: { x: iTimestamp, y: i, tooltip: [i] } },)
            } else {
                return Object.assign({}, linebarTemplate, { name: "Impact", type: 'bar', stack: "Total", encode: { x: iTimestamp, y: i, tooltip: [i] } },)
            }

        }
    })


    const option = {
        //legend: { type: 'scroll', top: '5%', height: '90%', right: 0, orient: 'vertical', data: legenddata, selector: true, textStyle: { color: "#eee"} },
        dataset: {
            source:  xType === 'time' ? data.values.map(value => {
                const timestamp = value[0]
                const utcTime = moment.utc(timestamp).format()
                const siteTime = moment(utcTime).format('YYYY-MM-DD HH:mm:ss')
                const otherValues = value.slice(1)
                return [siteTime, ...otherValues]
            }) : data.values,
            dimensions: data.columns
        },
        xAxis: [
            {
                type: xType, nameLocation: 'middle', name: args.options.titleX || '',
                axisLabel: (xType === 'category') ? { interval: 0, rotate: 45 } : {},
            }
        ],
        yAxis: [
            {
                name: args.options.titleY || '',
                scale: true, nameLocation: 'middle', nameGap: 45,
                splitLine: { lineStyle: { width: 1, color: "rgba(127,127,127,0.5" } },
                nameTextStyle: { align: 'center', fontWeight: 'bolder', fontSize: 12, color: '#eee' },
                min: args.options.yAxisMin

            },
        ],
        legend: { data: ["impact"] },
        series: _.concat(historicalSeries, majorityPieSeries, majorityBarSeries),
        // tooltip: args.options.tooltip && args.options.tooltip === 'category'? getToolTipCategory(iTimestamp): getToolTipNonZero(iTimestamp)
        tooltip: args.options.typeX && args.options.typeX === 'category' ? getToolTipCategory(iTimestamp) : getToolTipNonZero(iTimestamp)

    };
    console.log("after merge",_.merge({}, defaultBarOption, option, grid))
    return _.merge({}, defaultBarOption, option, grid);

}


function mergeOptions(data, args, criteria) {

    if (!data || Object.keys(data).length === 0) return defaultBarOption
    const colType = isPortfolio(criteria) ? "site_id" : isSite(criteria) ? "zone_id" : isZone(criteria) ? "device_id" : "device_id"
    const uptime = data.uptime
    const iUptime = uptime.indexes["uptime"]
    const iDevicecount = uptime.indexes["devicecount"]
    const iId = uptime.indexes[colType]
    console.log(data)
    // const uptimeAvg = uptime.values.reduce((acc, e) => acc + (e[iUptime] * e[iDevicecount]), 0) / uptime.values.reduce((acc, e) => acc + e[iDevicecount], 0)
    const uptimeAvg = data.availabilityAvg
    // const uptimeAvg = data
    var parentCount = 0
    if (uptime && uptime.values.length > 0) {
        // const parentdevice = data.device
        // const iParentDeviceCount = parentdevice.indexes["devicecount"]
        // parentCount = parentdevice.values[0][iParentDeviceCount]
        uptime.values.forEach(v => {
            if(isPortfolio(criteria) && v[iUptime] !== 'No Data'){
                parentCount+=v[iDevicecount]
            }else{
                parentCount+=v[iDevicecount]
            }
        })
    }

    console.log("parent count",parentCount, data)
    /*
    const impact = uptime.map((item, i) => Object.assign({}, item,
        (parentdevice && parentdevice.length> 0) ? uptimeImpact(item, parentdevice[0]): defaultUptimeImpactValue
    ))
    */
    var values = []
    var placeholder = uptimeAvg
    console.log("availability",uptime)
    const impactArray = []
    uptime.values.map((u) => {
        // console.log(u[iUptime], u[iDevicecount], u[iDevicecount], parentCount)
        if(u[iUptime] !== 'No Data'){
            const impactValue = (100 * ((1 - u[iUptime] / 100) * u[iDevicecount] / parentCount)).toFixed(3)
            impactArray.push({ id: u[iId], impact: parseFloat(impactValue), availability:  u[iUptime]})
        }
    })

    console.log("impactArray",impactArray)
    const uptimeImpactSum = impactArray.reduce((acc, e) => acc + e.impact, 0)
    // console.log(uptimeAvg, "Uptime impact sum " , uptimeImpactSum)
    const sortedImpactArray = impactArray.sort((a, b) => b.impact - a.impact)
    console.log(sortedImpactArray)
    sortedImpactArray.forEach((u, i) => {
        //const impactValue = (100*((1- u[iUptime] / 100)* u[iDevicecount]/parentCount)).toFixed(3)
        // const impact = parseFloat(u.impact)
        // console.log(u)
        if (placeholder < 99) {
            values.push([u.id, `${u.availability}%`, placeholder && placeholder.toFixed(2), u.impact])
            placeholder += u.impact
        }
    })
    const alldata = { 'columns': ["id", "availability","placeholder", "impact"], "indexes": { "id": 0, "availability": 1, "placeholder": 2, "impact": 3}, "values": values }
    // console.log(uptimeAvg, alldata)
    const argsValue = _.merge({}, args, { options: { yAxisMin: uptimeAvg && uptimeAvg.toFixed(2), timeColumn: "id", typeX: "category", showMajority: false, showPercent: false } })
    const impactOptions = makeOptions(alldata, argsValue)
    return impactOptions;

}

export const UptimeImpactBaseChart = ({data,onRenderComplete,setChartPriority}) => {
    //const finalOption = mergeOptions(props.data)
    // console.log("finale" ,props.data)
    const propValue = useMemo(()=>(data),[data])
    const storeAction = useStoreActionContext()
    const criteria = useCriteriaContext()
   // const commonAction = useCommonActionContext()

    const onChartClick = (e) => {
        const data = e.data[0]
        const value = data.indexOf("/") > 0 ? data.split("/")[1] : data

        //commonAction.setPriorityChart('availability_chart',true);
        setChartPriority()
        if (isPortfolio(criteria)) 
            storeAction.setSite(value)
        else if (isSite(criteria))
            storeAction.setZone(value)
        else if (isZone(criteria))
            storeAction.setDevice(value)    
    }

    return (
        <UptimeImpactBaseChart2 onChartClick={onChartClick} id={1} data={propValue} onRenderComplete={onRenderComplete}/>
    )
}
export const UptimeImpactBaseChart2 = React.memo(props => {
    return (
        <ReactEcharts className="mw-100 mh-100"
            option={props.data}
            onChartReady={props.onRenderComplete}
            opts={{ renderer: "svg", }}
            style={{ height: "50vh", left: 0, top: 0 }}
            onEvents= {{
                'click': props.onChartClick
            }}
        />
    )
},(prevProps, nextProps) => _.isEqual(1, 1))

export function getUptimeImpactComps(criteria, uc) {

    const keyf = function (o) { return ['site_id', 'zone_id', 'device_id'] } //{ return o.site_id + '-' + o.zone_id}
    var grouptype = {}
    if (criteria && Object.keys(criteria).length > 0) {
        const gt = isPortfolio(criteria) ? "pf" : isSite(criteria) ? "sites" : isZone(criteria) ? "zones" : "rcs"
        // urls = urls.map((e) => Object.assign({},e, { grouptype: grouptype}))
        grouptype = { grouptype: gt }
    }

    var urls = [
        { name: "uptime", url: "availability_summary"}
    ]

    
    const getUrls = async (criteria) => {
        if(!isPortfolio(criteria)){
            return urls
        }
        const data = await new Promise((resolve,reject) => {
            getSites(criteria, resolve, reject)
        })
    
        if(Array.isArray(data)){
            if(data.length){
                var tenantList = []
                data.forEach(s => {
                    if(s.portfolios.includes(criteria.pf)){
                        tenantList.push(s.tenant)
                    }
                })
                tenantList = [...new Set(tenantList)]
                if(tenantList.length > 1){
                    return [
                        { name: "uptime", url: "availability_summary_combined"}
                    ]
                }else{
                    return urls
                }
            }
        }
    }

    
    const comps = { 
        title: 'Availability Impact Summary – Path to 99%', 
        tooltip: TooltipHelpContent.UptimeImpact,
        chart: UptimeImpactBaseChart,
        urls: getUrls,
        default: defaultOption,
        parser: { f: mergeOptions, args: {} }
    }
    return comps
}

/*
// console.log(comps)
const UptimeImpactSummary = withData({ ...comps }) //withData(PerfChartBase, urls)

// const UptimeSummary = compose(withData)(UptimeSummaryBaseChart)
export default UptimeImpactSummary;
*/