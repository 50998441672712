import React from 'react';

export const SidebarData = [
  {
    title: 'Portfolio',
    path: '/',
    icon: <i className="fab fa-simplybuilt"></i>, //<AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Sites',
    path: '/site.page',
    icon: <i className="fab fa-simplybuilt"></i>, //<IoIcons.IoIosPaper />,
    cName: 'nav-text'
  },
  {
    title: 'Zones',
    path: '/zone.page',
    icon: <i className="fab fa-simplybuilt"></i>, //<FaIcons.FaCartPlus />,
    cName: 'nav-text'
  },
  {
    title: 'Devices',
    path: '/rc.page',
    icon: <i className="fab fa-simplybuilt"></i>, //<FaIcons.FaCartPlus />,
    cName: 'nav-text'
  },
  /*
  {
    title: 'Team',
    path: '/team',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Messages',
    path: '/messages',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  },
  {
    title: 'Support',
    path: '/support',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  }*/
];


export const SidebarData2 = [
  {
    title: 'Overview',
    path: '/',
    icon: <i className="fas fa-receipt"></i>, //<AiIcons.AiFillHome />,
    cName: 'nav-text active'
  },
  {
    title: 'Uptime',
    path: '/site.page',
    icon: <i className="fas fa-clock"></i>, //<IoIcons.IoIosPaper />,
    cName: 'nav-text',
  },
  {
    title: 'Angles',
    path: '/site.page',
    icon: <i className="fab fa-autoprefixer"></i>, //<IoIcons.IoIosPaper />,
    // cName: 'has-sub closed',
    cName: 'nav-text',
  //   submenu: [{
  //     title: 'current',
  //     path: '/site.page',
  //     icon: <i className="fab fa-simplybuilt"></i>, //<IoIcons.IoIosPaper />,
  //     cName: 'nav-text',
  //   },
  //   {
  //     title: 'Historical',
  //     path: '/site.page',
  //     icon: <i className="fab fa-simplybuilt"></i>, //<IoIcons.IoIosPaper />,
  //     cName: 'nav-text',
  //   },
  // ]    

  },
  {
    title: 'Modes',
    path: '/rc.page',
    icon: <i className="fab fa-medium-m"></i>, //<FaIcons.FaCartPlus />,
    // cName: 'has-sub closed',
    cName: 'nav-text',
  },
  {
    // <i className="fas fa-sensor-on"></i>
    title: 'Sensors',
    path: '/rc.page',
    icon: <i className="fab fa-mixcloud"></i>, //<FaIcons.FaCartPlus />,
    cName: 'nav-text',
  },
  {
    // <i className="fas fa-sensor-on"></i>
    title: 'Errors',
    path: '/rc.page',
    icon: <i className="fa fa-exclamation-triangle"></i>, //<FaIcons.FaCartPlus />,
    cName: 'nav-text',
  },
  {
    title: 'Health',
    path: '/rc.page',
    icon: <i className="fas fa-h-square"></i>, //<FaIcons.FaCartPlus />,
    cName: 'nav-text'
  },
];