import React, { createContext, useState, useEffect } from "react";
import ContextFactory from "./base.context";
import { postJSONData } from '../components/model/store.swr';
import { getUserPortfolios, getUserEmail } from "../components/auth/userinfo";
import { isPortfolio, isSite, isDevice, isZone } from "../components/layout/criteria";
import { useCriteriaContext } from "./criteria.context";
import { handleStatsAsync } from "../helpers/common"; 
import { usePageContext } from "./page.context"
import { GetFQURL } from "../components/model/store.swr";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { getStatsData } from '../utils/apiCall';
import _ from 'lodash'

const AvailabilityContext = createContext()

export const useAvailabilityContext = ContextFactory('Availability', AvailabilityContext) 

export const AvailabilityContextProvider = (props) => {
    const [availability, setAvailability] = useState(0)
    const [activeZones, setActiveZones] = useState([])
    const [impactSummaryData, setImpactSummaryData] = useState({}) 
    const [modeSummaryData, setModeSummaryData] = useState([])
    const [withMaintenance, setWithMaintenance] = useState(false)
    const [withMaintenance7day, setWithMaintenance7day] = useState(false)
    const [maintenanceActivities, setMaintenanceActivities] = useState(0)
    const [maintenanceError, setMaintenanceError]  = useState("")

    const criteria = useCriteriaContext()


    //load page context
    const pageContext  = usePageContext()

    // useEffect(() => {
    //     if(criteria.device){
    //         const urls = [{ name: "availability_avg", url: "rc_availability_avg"}]
    //         const url = GetFQURL(urls[0], criteria, 'json', false,[],null,null,null, {})
    //         handleStatsAsync(url, setAvailabilityData, null, null)
    //     }
    // },[criteria.device, criteria.Ts, criteria.Te])


    useEffect(() => {
        const url = `maintenance?querytype=stats&pf=${criteria.pf}&site=${criteria.site}&zone=${criteria.zone}&start_date=${moment(criteria.Ts).format()}&end_date=${moment(criteria.Te).format()}&user=${getUserEmail()}&restype=json&project=depcom`
        if (pageContext.page === "Overview" || pageContext.page.indexOf("My Dashboards") === 0)
        getStatsData(url,response => {
            var count = 0, count7day = 0, countWithTO = 0//, count30day = 0
            if (response && response.length>0 && response.filter(f => f["stats"] !== 0).length > 0) {
                count = response.find(f => f["stats"] !== 0)["stats"]
            }

            if (response && response.length>0 && response.filter(f => f["statsWithTO"] !== 0).length > 0) {
                countWithTO = response.find(f => f["statsWithTO"] !== 0)["statsWithTO"]
            }

            const withMaintenance = _.isEmpty(response) || countWithTO === 0 ? false : true
            setWithMaintenance(withMaintenance)
            if (response && response.length>0 && response.filter(f => f["stats7day"] !== 0).length > 0) {
                count7day = response.find(f => f["stats7day"] !== 0)["stats7day"]
            }

            const withMaintenance7day = _.isEmpty(response) || count7day === 0 ? false : true
            setWithMaintenance7day(withMaintenance7day)

            // if (response && response.length>0 && response.filter(f => f["stats30day"] !== 0).length > 0) {
            //     count30day = response.find(f => f["stats30day"] !== 0)["stats30day"]
            // }

            // const _count30day = _.isEmpty(response) || count30day === 0 ? 0 : count30day
            // setCount30day(_count30day)

            setMaintenanceActivities(_.isEmpty(response) ? 0 : count)
            setMaintenanceError("")
        },err => {
            setMaintenanceError(err)
            setWithMaintenance(false)
        })
        
    },[criteria.pf, criteria.site, criteria.zone, criteria.Ts, criteria.Te, pageContext.page])


    useEffect(() => {
        // var data = {}
        // if(availability.length){
        //     var devicecount = 0
            
        //     availability.forEach(item => devicecount+=item.devicecount)
        //     var  columns, indexes

        //     var availabilityData

        //     console.log("availability in context",availability)

        //     if(isPortfolio(criteria)){
        //         devicecount = 0
        //         availability.filter(a => a.uptime !== null).forEach(item => devicecount+=item.devicecount)
        //         columns = ['site_id', 'uptime', 'devicecount']
        //         availabilityData = availability.filter(a => a.uptime !== null).map(item => [item.site_id, item.uptime, item.devicecount])
        //     }else if(isSite(criteria)){
        //         columns = ['site_id', 'zone_id', 'uptime', 'devicecount']
        //         availabilityData = availability.map(item => [item.site_id,item.zone_id, item.uptime, item.devicecount])
        //     }else{
        //         columns = ['site_id', 'zone_id', 'device_id', 'uptime', 'devicecount']
        //         availabilityData = availability.map(item => [item.site_id, item.zone_id, item.device_id, item.uptime, item.devicecount])
        //     }

        //     indexes = Object.assign({}, ...columns.map((p, i) => ({ [p]: i })));
        //     console.log("data is++++",availabilityData)
        //     data['uptime'] = { columns: columns, indexes: indexes, values: availabilityData }
        //     data['device'] = {columns: ['devicecount'], indexes: {devicecount: 0}, values: [[devicecount]]}
        //     setImpactSummaryData(data)
        // }
    },[availability])


    return(
        <AvailabilityContext.Provider value={{
            availability: availability,
            setAvailability: setAvailability,
            impactSummaryData: impactSummaryData,
            activeZones: activeZones,
            setActiveZones: setActiveZones,
            setModeSummaryData: setModeSummaryData,
            modeSummaryData: modeSummaryData,
            withMaintenance,
            maintenanceActivities,
            maintenanceError,
            withMaintenance7day
         }} >
            {props.children}
        </AvailabilityContext.Provider>
    )
} 

