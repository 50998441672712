import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import useSWR from 'swr';
import { useStore } from '../../context/store.context';
import { applyToSampleByTime, getStackedAreadata } from "../chartjs/chartjs.data";
import StackedAreaChart from '../chartjs/stackedAreachart';
import { Card6 as Card, CardHeader } from "../layout/Card";
import { getTimeInMinutes } from '../layout/criteria';
import { FetchData2 } from "../model/store.swr";
import { getColorCode, getErrorCodeTitle } from "../model/utils";
import { DownloadCsv_Error_Summary } from './csv.download';

const intervals = {'hour': 10, 'day': 30, 'week': 60 * 24}

const ErrorSummaryLines = (props) => {
    //const response = FetchData(useSWR, urls)
    const criteria = useStore().criteria
    // change 24 hours and below to 10 minutes slice
    const key = Object.keys(intervals).find(k => criteria.timetype.includes(k))
    var interval = criteria.interval;
    if (key){
        interval = intervals[key];
    } 

    // const interval = criteria.timetype.includes('hour') ? 10 : 30;
    //const interval = criteria.interval;
    
    const urls = [
        { name: "errorsummary", url: "rc_error_code_summary", slice: interval },
    ]
    const start_time = moment(criteria.Te)
    const response = FetchData2(useSWR, criteria, urls)
    if (!response.isReady) return <Card>Loading Error Summary</Card>
    const data = response.data.errorsummary;
    const sampledData = applyToSampleByTime(data, getTimeInMinutes(criteria), interval)
    if (!sampledData)    return <Card>Error Loading !!!</Card>
    const startsec = (start_time.valueOf() / 1000).toFixed(0)
    const tdf = sampledData.map((e) => { return { errorCode: e.errorCode, ts: (startsec - (e.time_key * interval * 60)), errorCount: e.errorCount } })
    const ds = getStackedAreadata(tdf, "ts", "errorCode", "ts", "errorCount", getErrorCodeTitle, getColorCode, 'true')
    const dps = { datasets: ds }
    console.log("errorsummary", dps)
    return (
        <>
            <Card >
                <CardHeader title={'Error - Summary'} >
                    <DownloadCsv_Error_Summary data={ds} filename={"error-summary"+criteria.timetype}/>
                </CardHeader>
                {/* <LineChart data={dps} options={getChartJSOptions("Count")} /> */}
                <StackedAreaChart data={dps} ylabel={'Error Count'} />
            </Card>
        </>
    );

}

export const PortfolioErrorSummaryLines = observer(ErrorSummaryLines) 
export const SiteErrorSummaryLines =  observer(ErrorSummaryLines) 
export const ZoneErrorSummaryLines =  observer(ErrorSummaryLines) 

//export default observer(PortfolioErrorSummaryLines);
