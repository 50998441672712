import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import useSWR from 'swr';
import { useStore } from '../../context/store.context';
import { getStackedBarOptions } from '../chartjs/hrbar.options';
import SingleStackedBar from '../d3customchart/SingleStackedBar';
import { Card2, CardHeader } from '../layout/Card';
import { ModeDomainToChart } from '../model/mode.data';
import { FetchData2 } from '../model/store.swr';
import { DownloadCsv_Operational_Modes } from './csv.download';



const urls = [
    { name: "allmodes", url: "all_unique_modes"}, 
    { name: "modemajoritytotal", url: "mode_majority_total" },
]

const ZonesModeMajority = (props) => {
    const criteria = useStore().criteria

    //const response = FetchData(useSWR, urls)
    const response = FetchData2(useSWR, criteria, urls)

    if (!response.isReady) return <Card2>Loading 2</Card2>

    function filter(data){
        return (data.site_id === props.site_id && data.zone_id === props.zone_id)
    }

    const dps = ModeDomainToChart(response.data.modemajoritytotal, response.data.allmodes, filter)
    const options = getStackedBarOptions()
    return (
         <>
        <Card2 >
            <CardHeader title={'Operational Modes'} >
                <DownloadCsv_Operational_Modes data={response.data} filename={"operational_Modes_overview_"+criteria.timetype} />
            </CardHeader>
            <SingleStackedBar height={50} data={dps.datasets} heading="Operational Modes" />
        </Card2>
         </>
    );
        
}


export default ZonesModeMajority;