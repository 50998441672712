import React, { useMemo,useEffect, useState } from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import useSWR from 'swr';
import { useDataviewActionContext } from '../../context/dataview.context';
import { useStore, useStoreActionContext } from '../../context/store.context';
import { InfoTooltip } from '../../model/info-tooltip';
import { DownloadCsv_Site_Summary_table } from '../chart/csv.download';
import { Card4Opacity80 as Card, CardSummary } from '../layout/Card';
import { FetchData2 } from '../model/store.swr';
import { TooltipHelpContent } from '../pages/tooltip-help-content';
import NotifyTooltip from '../../model/notify-tooltip'
import TableContainer from './TableContainer';
import { ColumnAlarms, ColumnErrors, ColumnUptime, ColumnUptimeImpact,ColumnDeviceCount, alarmData, findZone, getLastSeenTime, getZoneName, renderColumn, uptimeImpact,ColumnExpectedPower,ColumnActualPower,ColumnPowerVariance, ColumnTrackerComm } from './summary.row';
import { usePageContext, energyMeterPage, usePageActionContext } from '../../context/page.context';
import { createActivityLog, getStatsData } from '../../utils/apiCall';
import { getLogData, getWeightedAvg, handleStatsAsync } from '../../helpers/common';
import { GetFQURL } from '../model/store.swr';
import _ from 'lodash' 
import { useAvailabilityContext } from '../../context/availability.context';

function getZone(row) {
    return renderColumn(row, findZone, getZoneName)
}

function getUptime(row) {
    return renderColumn(row, findZone, ColumnUptime)
}

function getErrors(row) {
    return renderColumn(row, findZone, ColumnErrors)
}

function getTrackerComm(row) {
    return renderColumn(row, findZone, ColumnTrackerComm)
}

function getAlarms(row) {
    return renderColumn(row, findZone, ColumnAlarms)
}
function getUptimeImpact(row) {
    return renderColumn(row, findZone, ColumnUptimeImpact)
}
function getExpectedPower(row) {
    return renderColumn(row, findZone, ColumnExpectedPower)
}
function getActualPower(row) {
    return renderColumn(row, findZone, ColumnActualPower)
}
function getVariance(row) {
    return renderColumn(row, findZone, ColumnPowerVariance)
}

function getDeviceCount(row) {
    return renderColumn(row, findZone, ColumnDeviceCount)
}

const columns_list = [
    {
        Header: "Zone Name",
        accessor: "zoneId",
        Cell: (row) => {
            return getZone(row)
        }
    },
    {
        Header: "AVAILABILITY",
        accessor: "uptime",
        Cell: (row) => {
            return getUptime(row)
        }
    },
    {
        Header: () => <span>AVAILABILITY <br/>Impact</span>,
        accessor: "uptimeImpact",
        Cell: (row) => {
            return getUptimeImpact(row)
        }
    },
    {
        Header: () => <span>Device <br/>Count</span>,
        accessor: "deviceCount",
        Cell: (row) => {
            return getDeviceCount(row)
        }
    },
    {
        Header: "Alarms",
        accessor: "alarms",
        Cell: (row) => {
            return getAlarms(row)
        }
    },
    {
        Header: "Errors",
        accessor: "errorCount",
        Cell: (row) => {
            return getErrors(row)
        }
    },
    {
        Header: "Tracker Comms",
        accessor: "tracker_communication",
        Cell: (row) => {
            return getTrackerComm(row)
        }
    },
    {
        Header: () => <span>Last Sent</span>,
        accessor: "lastUpdated",
        Cell: (row) => {
            //const rv = isNaN(parseInt(row.value)) ? "" : parseInt(row.value)
            //return <span data-tip={rv}>{rv}</span>;
            return getLastSeenTime(row)
        }
    },

]
const energymeter_columns_list = [
    {
        Header: "Zone Name",
        accessor: "zoneId",
        Cell: (row) => {
            return getZone(row)
        }
    },
    {
        Header: () => <span>Energy<br/>Estimated(KW)</span>,
        accessor: "expected_power",
        Cell: (row) => {
            return getExpectedPower(row)
        }
    },
    {   
        Header: () => <span>Energy<br/>Actual(KW)</span>,
        accessor: "actual_power",
        Cell: (row) => {
            return getActualPower(row)
        }
    },
    {
        Header: "Variance (KW)",
        accessor: "errorCount",
        Cell: (row) => {
            return getVariance(row)
        }
    },
    {
        Header: () => <span>Last Sent</span>,
        accessor: "lastUpdated",
        Cell: (row) => {
            return getLastSeenTime(row)
        }
    },
];

function parseData(alldata, props) {
    const siteId = props.site_id;
    //console.log("parseData - SS", alldata)
    const zones = alldata.zones //filterBySite(alldata.zones, siteId);
    const down = alldata.down //filterBySite(alldata.down, siteId);
    const errors = alldata.errors //filterBySite(alldata.errors, siteId);
    const uptime = alldata.uptime //filterBySite(alldata.uptime, siteId);
    // const preverrors = filterBySite(alldata.preverrors, siteId);
    // const prevuptime = filterBySite(alldata.prevuptime, siteId);
    const rcerrors7days = alldata.errordetails //filterBySite(alldata.errordetails, siteId);
    const zcalarms = alldata.zcalarms
    const parentdevice = alldata.device
    const tracker_communication = alldata.tracker_communication

    // const energy = alldata.energy_details

    // const zones = filterBySite(alldata.zones, siteId);
    // const down = filterBySite(alldata.down, siteId);
    // const errors = filterBySite(alldata.errors, siteId);
    // const uptime = filterBySite(alldata.uptime, siteId);
    // // const preverrors = filterBySite(alldata.preverrors, siteId);
    // // const prevuptime = filterBySite(alldata.prevuptime, siteId);
    // const rcerrors7days = filterBySite(alldata.errordetails, siteId);
    // const zcalarms = alldata.zcalarms

    //console.log(zcalarms)

    var deviceCount = 0
    if(uptime.length){
        uptime.forEach(u => deviceCount += u.devicecount)
    }

    const uptimeAll = uptime.map((item, i) => Object.assign({}, item,
        item.uptime !== null ? uptimeImpact(item, {devicecount: deviceCount}) : {uptimeImpact: 'No Data', uptime: 'No Data'}

    ))
    // console.log("all uptime", toJS(uptimeAll))

    const errorsAll = errors.map((item, i) => Object.assign({}, item,
        // { prevErrors: preverrors.find(x => item.zone_id === x.zone_id) }
    ))
    // console.log("all errors", toJS(errorsAll))

    // const zcAlarmsAll = zcalarms.map()
    const data = uptimeAll.map((item, i) => Object.assign({}, item,
        down.find(x => item.zone_id === x.zone_id),
        { rcerrors: rcerrors7days.find(x => item.zone_id === x.zone_id) ? rcerrors7days.find(x => item.zone_id === x.zone_id) : 0 },
        errorsAll.find(x => item.zone_id === x.zone_id) ? errorsAll.find(x => item.zone_id === x.zone_id) : {...item, errorCount: 0},
        uptimeAll.find(x => item.zone_id === x.zone_id) ? uptimeAll.find(x => item.zone_id === x.zone_id) : {...item, uptime: 'No Data', uptimeImpact: 'No Data'},
        tracker_communication.find(x => item.zone_id === x.zone_id) ? tracker_communication.find(x => item.zone_id === x.zone_id) : {...item, tracker_communication: 'No Data'},
        alarmData(zcalarms.filter(x => item.zone_id === x.zone_id && x.value === 1))
       // ,energy.find(x => item.zone_id === x.id),
        //{ alarms: zcalarms.filter(x => item.zone_id === x.zone_id && x.value === 1).map(e => e.metric) }
    ))
    ReactTooltip.rebuild();

    return data;
}

// const urls = [
//     { name: "zones", url: "depcom-all_unique_zones.json" },
//     { name: "down", url: "depcom-latest_down_zonesby1days.json" },
//     { name: "errors", url: "depcom-latest_errors_zonesby1days.json" },
//     { name: "uptime", url: "depcom-latest_uptime_zonesby1days.json" },
//     { name: "preverrors", url: "depcom-previous_errors_zonesby1days.json" },
//     { name: "prevuptime", url: "depcom-previous_uptime_zonesby1days.json" },
//     { name: "errordetails", url: "depcom-errors_detail_zonesby1days.json" },
// ]

const urls = [
    {name: 'summaryData', url: "overview_summary"},
    // { name: "zones", url: "all_unique_zones", slice: -1 },
    // // { name: "down", url: "latest_down" },
    // { name: "down", url: "rc_down_summary" },
    // // { name: "errors", url: "latest_errors" },
    // { name: "errors", url: "error_summary_card" },
    // // { name: "uptime", url: "latest_uptime" },
    // { name: "uptime", url: "availability_summary" },
    // // { name: "preverrors", url: "previous_errors" },
    // // { name: "prevuptime", url: "previous_uptime" },
    // { name: "errordetails", url: "errors_detail", grouptype: 'sites' },
    // { name: "zcalarms", url: "latest_zc_alarms" },
    // { name: "device", url: "device_summary", grouptype: "sites" },
    // { name: "availability_avg", url: "rc_availability_avg"},
    { name: "energy_details", url: "expected_actual",  path:"/energy", aggr: "avg"}

]

const SiteSummaryTable = (props) => {
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})
    const pageContext = usePageContext();
    const pageName = pageContext.page;
    const history = useHistory();
    const pageActionContext  =   usePageActionContext()
    const dataviewaction = useDataviewActionContext()

    const columns = useMemo(() => pageName === energyMeterPage ? energymeter_columns_list : columns_list, []); 

    //const response = FetchData(useSWR, urls)
    const storeAction = useStoreActionContext()
    const criteria = useStore().criteria
    const availabilityContext = useAvailabilityContext()

    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Escape') {
            event.preventDefault();
            var popup = document.getElementById("myPopup");
            popup.classList.remove("show");
          }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []);

      useEffect(() => {
        if(criteria.site){
            createActivityLog(getLogData(criteria, {featureId: `view-site-summary`, usecase: `view site summary`, category: 'overview', level: 'site'}))
        }
      },[criteria.site,criteria.Te, criteria.Ts])

      useEffect(() => {
        setResponse({})
        setError({})
        const url = GetFQURL(urls[0], criteria, 'json', false,[],null,null,null, {})
        handleStatsAsync(url, setResponse, setError, availabilityContext)
    },[criteria.site, criteria.Ts, criteria.Te])

    

    


    
    // const response = FetchData2(useSWR, criteria, urls)

    if (_.isEmpty(response)) return <CardSummary>Loading ...</CardSummary>
    console.log("site summary",response)
    var res_availability = Array.isArray(response) ? (response[0].availability_avg || null) : (response.data.availability_avg || null)
    // const activeZones = Array.isArray(response) ? response[0].zones.map(z => z.zone_id) : response.data && response.data.zones.map(z => z.zone_id)
    // res_availability = res_availability.filter(a => activeZones.includes(a.zone_id))
    const availability_avg = res_availability[0].availability
    const res_tracker_communication = Array.isArray(response) ? (response[0].tracker_communication_avg || null) : (response.data.tracker_communication_avg || null)
    const tracker_communication_avg = res_tracker_communication && res_tracker_communication[0].tracker_communication
    var updatedResponse =  Array.isArray(response) ? [...response] : {...response}

    if(Array.isArray(updatedResponse)){
        updatedResponse[0].availability_avg = res_availability
    }else{
        updatedResponse.data.availability_avg = res_availability
    }

    const data = parseData(Array.isArray(updatedResponse) ? updatedResponse[0] : updatedResponse.data, props)


    function handleRowClick(site, history) {
        // goToPage("Zones", site, criteria, history)
        storeAction.setZone(site.zone_id)

        dataviewaction.clearView()
    }
    const totalchildren = data.length
   

    // console.log("Site summary", uptimeavg, data )

    const errordata = data.map(d => d.errorCount || 0)
    const errorcount = errordata.reduce((a, b) => a + b, 0)
    function handleClick(e) {
        e.preventDefault();
        var popup = document.getElementById("myPopup");
        popup.classList.toggle("show");
        if(popup.classList.contains("show")){
            createActivityLog(getLogData(criteria, {featureId: `view-site-summary-details`, usecase: `view site summary detailed data`, category: 'overview', level: 'site'}))
        }
    }

    const tooltipContent = TooltipHelpContent.SiteSummary
    const tooltip = (
        <div>
            <h5>{tooltipContent.header}</h5>
            {tooltipContent.body}
        </div>
    )

    return (
        <CardSummary >
            {/* <a href="#"> */}
            <div className="popup" >
                <div className="card-body" onClick={handleClick}>
                    <h5 className="card-title">Site Summary <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} /></h5>
                    <div className="text-white h6 card-text"><span>Total Zones : {totalchildren}</span></div>
                    {/* <div className="text-white h6 card-text"><span>Availability Avg. : {uptimeavg}%</span></div> */}
                    <div className="text-white h6 card-text"><span>Availability Avg. : {availability_avg ? `${availability_avg}%` : 'No Data'}</span>{availabilityContext.withMaintenance ? 
                        <NotifyTooltip  content =   "Adjusted for maintenance activities"    onClick={() => pageActionContext.setPage("Site Maintenance", [], false)}/>
                        : null}
                    </div>
                    <div className="text-white h6 card-text"><span>Tracker Comms : {tracker_communication_avg ? `${tracker_communication_avg}%` : 'No Data'}</span></div>
                    {/* <div className="text-white h6 card-text"><span>Error total : {errorcount}</span></div> */}
                </div>

                <span className="popuptext" id="myPopup">
                    {/* A Simple Popup! */}
                    <div className="col-sm-12 no-gutters ">
                        <Card>
                            <div className="mb-3 popup-header">
                                {/* <b className="mb-3">{pageName === energyMeterPage?"Summary-Energy":"Summary"}</b> */}
                                <b className="mb-3">{pageName === energyMeterPage?"Summary-Energy":"Site Summary"}</b>
                                <span className="ml-2">
                                    {/* <img src="../assets/img/icon/icon-info.svg" width="16" height="16" data-toggle="popover" data-trigger="hover" data-title="Availability %" data-placement="top" data-content="Portfolio Summary" /> */}
                                    {/* <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Uptime %" data-placement="top" data-content="Portfolio Summary"></i> */}
                                </span>
                                <span className="ml-12 float-right bg-transparent d-flex">
                                    <DownloadCsv_Site_Summary_table data={data} filename={"Sitesummary" + criteria.timetype} />
                                </span>
                            </div>
                            <div className="d-flex  align-items-center mb-1">

                                <TableContainer
                                    columns={columns}
                                    data={data}
                                    trHandler={handleRowClick}
                                // renderRowSubComponent={renderRowSubComponent}
                                />
                            </div>

                        </Card>
                    </div>
                </span>
            </div>
            <div className="stats-desc text-right py-2 px-2 " onClick={handleClick} style={{ "position": "absolute", "bottom": "0", "right": "0", "cursor": "pointer" }}>
                <span >View Zones <span className="fas fa-angle-double-down	"></span></span></div>
        </CardSummary>
    );
}


//export default withRouter(PortfolioSummaryTable);
export default SiteSummaryTable;
