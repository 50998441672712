import React, { useEffect, useRef } from 'react'
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import styled from 'styled-components'

const DraftEditor = styled.div`
    border: 1px solid #014059;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;

    .public-DraftEditor-content {
        height: 350px;
        overflow: auto;
    }
` 

export default props => {
    
    const ref = useRef()

    const content = props.content || EditorState.createEmpty()

    // useEffect(() => {
    //     if (ref && ref.current && props.inFocus) {
    //         ref.current.focus()
    //         props.resetFocus()
    //     }
    // }, [props.inFocus])

    return props.readonly ?
        <Editor editorState={content} readOnly={true} /> : 
        <DraftEditor>
            <Editor editorState={content} onChange={props.setContent} className="editor" ref={ref} /*onFocus={() => props.setContent(EditorState.moveFocusToEnd(content))}*/ />
        </DraftEditor>
}