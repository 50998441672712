import React, { useState } from "react";
import "../../App.css";
import styles from "./auth.module.css";
import {BsFillArrowLeftCircleFill} from 'react-icons/bs'
import {validateEmail, Error, validatePassword, isEmptyField} from '../../helpers/validation-helpers'

const ForgetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [resetCode, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [errors, setErrors] = useState({})


  const stepOne = () => {
    return (
      <>
        <label className={styles.registerLabel}>
          Email<span style={{ color: "red" }}>*</span>
        </label>
        <div>
          <input
            placeholder="Email"
            className={styles.inputTag}
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
          <Error type='email' errors={errors}/>
        </div>
      </>
    );
  };

  const stepTwo = () => {
    return (
      <>
        <label className={styles.registerLabel}>
          Reset Code<span style={{ color: "red" }}>*</span>
        </label>
        <div>
          <input
            placeholder="Reset Code"
            className={styles.inputTag}
            value={resetCode}
            name="code"
            onChange={(e) => setCode(e.target.value)}
          />
          <Error type='code' errors={errors}/>
        </div>
        <label className={styles.registerLabel}>
          New Password<span style={{ color: "red" }}>*</span>
        </label>
        <div>
          <input
            placeholder="New Password"
            className={styles.inputTag}
            value={password}
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Error type='password' errors={errors}/>
        </div>
        <label className={styles.registerLabel}>
          Re-enter Password<span style={{ color: "red" }}>*</span>
        </label>
        <div>
          <input
            placeholder="Re-enter Password"
            className={styles.inputTag}
            value={rePassword}
            name="rePassword"
            type="password"
            onChange={(e) => setRePassword(e.target.value)}
          />
          <Error type='rePassword' errors={errors}/>
        </div>
      </>
    );
  };

  const handleSubmit = () => {
    const err = {}
    if(props.step === 1){
      if(isEmptyField(email)){
        err['email'] = 'Email is required'
        setErrors(err)
        return
      }else if(!validateEmail(email)){
        err['email'] = 'Email is invalid'
        setErrors(err)
        return
      }
    }else{
      
      if(isEmptyField(resetCode)){
        err['code'] = 'Reset Code is required'
        setErrors(err)
        return  
      }

      if(isEmptyField(password)){
        err['password'] = 'New Password is required'
        setErrors(err)
        return  
      }

      if(isEmptyField(rePassword)){
        err['rePassword'] = 'Re-enter Password is required'
        setErrors(err)
        return
      }

      if(password !== rePassword){
        err['rePassword'] = 'Password and Re-entered Password are not same'
        setErrors(err)
        return
      }


    }

    props.step === 1 ? props.getResetCode({ email: email }) : props.resetPassword({ resetcode: resetCode, email: email, password: password, rePassword: rePassword })
  }


  return (
    <>
      <div className={styles.loginbody}>
        <div className={styles.main1}>
          <img src="/page-cover1.jpg"></img>
        </div>
        <div className={styles.loginbox}>
          <div className={styles.loginboxbpad}>
            <div>
              <BsFillArrowLeftCircleFill className={styles.backBtn} onClick={() => props.setStep(props.step === 2 ? 1 : 0)} size='30px' style={{margin: '0.5rem 0rem 0rem 0.5rem', background: 'white', color: '#006cff', borderRadius: '50%'}}/>
            </div>
            <div style={{ marginBottom: "2rem" }} className={styles.maintittle}>
              {props.step === 1 ? "Forgot Password" : "Reset Password"}
            </div>
            <div>
              {props.step === 1 ? stepOne() : stepTwo()}
              <div className={styles.sendbutton}>
                <button
                  className={styles.registerBtn + " " + styles.loginBtn}
                  onClick={handleSubmit}
                  disabled={props.loading}
                >
                  {props.loading ? 'Loading....' : props.step === 1 ? 'Get Code' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
