import React, { useEffect, useState, useRef } from 'react'
import { Form, Button, Modal, ListGroup, Badge } from 'react-bootstrap'
import styled from 'styled-components'
import { FilePond } from 'react-filepond';
import { AiOutlineDelete, AiFillCaretLeft, AiFillPlusCircle, AiFillCaretDown} from 'react-icons/ai'
import axios from 'axios'
import { getAccessToken, getUserEmail } from '../auth/userinfo'
import Dropdown from 'react-bootstrap/Dropdown';
import { useModelContext } from '../../context/model.context';
import 'filepond/dist/filepond.min.css';
import { useCriteriaContext } from '../../context/criteria.context'
import ButtonControl from '../controls/button_control';
import { ChromePicker } from 'react-color';

const customMetricGroup = "CM"
const InfoStyle = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  text-align: center;
  background: #ffa2b0;
  padding: 5px 10px;
  border-radius: 0px 0px 4px 4px;
  z-index: 1000;
`

const FixedColor = styled.div`
  width: 18px;
  height: 18px;
  background: ${props => props.color};
`

const DropdownColor = styled.div`
    display: flex;
    gap: 2px;
    padding: 2px;
    border: 1px solid #eeeeee;
    cursor: pointer;
    border-radius: 2px;
    align-items: center;
`

const CustomOverlay = styled.div`

    display: inline-block;

    .import-button {
        background: #014059;
        border-color: #014059;

        :active {
            background: #014059 !important;
        }
    }

    .overlay {
        position: absolute;
        z-index: 10;
        border: 1px solid rgba(0, 0, 0, .15);
        box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, .175);
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
    }

    .overlay-body {
        width: 850px;
        height: 500px;
        position: relative;
        overflow-y: scroll;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .filepond--wrapper {
        width: 65%;
    }

    .form {
        padding-bottom: 10px;
    }

    .import-button {
        background: #014059;
    }

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 8px;
        padding: 16px;
    }

    button.action {
        border: none;
        border-radius: 4px;
        padding: 4px 8px;
        color:   white;
    }

    button.primary {
        background: green;
    }

    button.secondary {
        background: black;
    }

    .entry {
        padding: 4px 0px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        align-items: center;
    }

    .form-check-label {
        padding-left: 8px;
        padding-top: 6px;
    }

    .all-metrics-link {
        font-size: 16px;
        font-weight: 700;

        padding-bottom: 10px;
    }

    .list-group-item {
        color: inherit;
        background-color: white;
    }

    .list-group-item .fw-bold {
        font-weight: 700;
        font-size: 14px;
    }
    
    .list-group {
        padding-top: 10px;
    }

    .badge {
        background-color: #014059 !important;
    }
`

const Cell = styled.div`
    width: 120px;
    height: 30px;
    border-top: 1px solid #cecece;
    border-right: 1px solid #cecece;
    display: inline-flex;
    align-items: center;
    padding-left: 4px;
`

const Row = props => {
    return <div style={{display: 'flex'}}>
        {props.values.map((value, i) => <Cell style={{borderLeft: i === 0 ? '1px solid #cecece' : 'none'}}>{value}</Cell>)}
    </div>
}

const Sheet = props => {
    return <div style={{maxHeight: 200, overflow: 'scroll'}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span><b>{props.heading}</b></span>
            {props.download ? <ButtonControl handleClick={props.downloadSample} /> : ''}
            {props.delete ? <AiOutlineDelete style={{fontSize: 16}} onClick={props.deleteData}/> : ''}
        </div>
        {props.data.map(row => <Row values={row} />)}
    </div>
}

const ColorEdit = props => {
    const [editing, setEditing] = useState(false)
    return <React.Fragment>
        <DropdownColor onClick={() => setEditing(true)} style={{borderColor: editing ? '#0140595c' : '#eeeeee'}}>
            <FixedColor color={props.encoding.color}/>
            <AiFillCaretDown color="#a5a2a2" style={{fontSize: 10}}/>
        </DropdownColor>
        {editing ? <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0}} onClick={() => setEditing(false)}></div> : null}
        {editing ? <div style={{position: 'absolute', top: 130, right: 130, zIndex: 100}}>
            <ChromePicker
                color={ props.encoding.color }
                onChangeComplete={ color => props.handleColorChange(props.encoding, color) }
                disableAlpha={true}
            />
        </div> : null}
    </React.Fragment>
}

export default props => {

    const exampleData = [
        ['DeviceId', 'Value'],
        ['A1022112', '4'],
        ['A1022113', '5'],
    ]

    const colors = ['red','green','blue','maroon','brown','olive',
        'teal','navy','orange','yellow','lime','cyan','purple',
        'magenta','pink','burlywood','darkgray','blueviolet',
        'skyblue','gray', "#b732cc", "#96341c", "#29bdab", "#edeff3",
        "#c3a5b4", "#e68f66", "#632819", "#3750db", "#d30b94", "#0ec434"]

    const [modalOpen, setModalOpen] = useState(false)

    const [csvData, setCsvData] = useState([])

    const [metricType, setMetricType] = useState('')

    const [step, setStep] = useState(1)

    const [metricName, setMetricName] = useState('')

    const [minValue, setMinValue] = useState('')
    const [maxValue, setMaxValue] = useState('')

    const [error, setError] = useState('')
    const [errorCode, setErrorCode] = useState("")
    const [encodings, setEncodings] = useState([])
    const [encodings2, setEncodings2] = useState([])
    const [addingEncoding, setAddingEncoding] = useState(false)
    const [label, setLabel] = useState('')
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [shared, setShared] = useState(true)

    const metricNameRef = useRef()
    const minValueRef = useRef()
    const maxValueRef = useRef()
    const step2Ref = useRef()
    const firstLabelRef = useRef()
    const lastLabelRef = useRef()

    const modelContext = useModelContext()
    
    const criteriaContext = useCriteriaContext()

    useEffect(() => {
        let timeout
        if (error) {
            timeout = setTimeout(() => {
                setError("")
                setErrorCode("")
            }, 3000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [error])

    useEffect(() => {
        if (errorCode === "metricName") {
            metricNameRef.current.focus()
        } else if (errorCode === "minValue") {
            minValueRef.current.focus()
        } else if (errorCode === "maxValue") {
            maxValueRef.current.focus()
        }
    }, [errorCode])

    useEffect(() => {
        if (addingEncoding) {
            step2Ref.current.scroll({behavior: 'smooth', top: 10000})
            lastLabelRef.current.focus()
            setAddingEncoding(false)
        }
    }, [addingEncoding])

    useEffect(() => {
        if (step === 2 && metricType === 'Dynamic')
            minValueRef.current.focus()
        if (step === 2 && metricType === 'Encoded' && firstLabelRef.current)
            firstLabelRef.current.focus()
    }, [step])

    const getRandomColor = () => {
        const rand = Math.random()
        let hexValue = Math.floor(rand * 16777215).toString(16)

        if (hexValue.length === 5)
            hexValue = "0" + hexValue
        return "#" + hexValue
    }

    const handleChange = (value, changeFn, maxValue) => {
        if (value.length <= maxValue)
            changeFn(value)
    }

    const addEncoding = (e) => {
        e.preventDefault()
        
        if (label.trim() === "") {
            setError("Please enter label")
            setErrorCode("label")
            return
        }
        
        if (value.trim() === ""){
            setError("Please enter value")
            setErrorCode("value")
            return
        }

        if (isNaN(value)) {
            setError( "Value should be numeric")
            setErrorCode("value")
            return 
        }

        if (encodings.filter(t => t.value === value.trim()).length > 0) {
            setError("Value already added in possible values")
            setErrorCode("value")
            return
        }

        setEncodings(encodings.concat({value: value.trim(), label: label.trim(), color: getRandomColor() }))
        setAddingEncoding(false)
        setLabel('')
        setValue('')  
    }

    const applyConversion = (value, metricType) => {
        if (metricType === "Binary") {
            const t = value.replace('\r', '').toLowerCase().trim()
            return t.toLowerCase() === "yes" || t.toLowerCase() === "true" || t === "1" ? 1 : 0
        } else if (metricType === "Dynamic") {
            return parseFloat(value)
        } else if (metricType === "Encoded") {
            return parseInt(value).toString()
        } else {
            return value
        }
    }

    const isInvalid = (value, metricType) => {
        if (metricType === 'Binary') {
            const t = value.trim().toLowerCase()
            const valid = ['yes', 'no', 'true', 'false', '1', '0']
            return valid.indexOf(t) < 0
        } else {
            return value.trim() === '' || isNaN(value)
        }
    }

    const importCustomMetric = () => {

        if (metricName.trim() === "") {
            setError("Please enter metric name")
            setErrorCode("metricName")
            return
        }

        if (modelContext.importedMetrics.filter(t => t.metric === metricName && t.site  === criteriaContext.site).length > 0) {
            setError("This metric already exists!")
            setErrorCode("metricName")
            return
        }

        if (metricType === "Dynamic") {
            if (minValue.trim() === "") {
                setError("Please enter minimum value")
                setErrorCode("minValue")
                return
            }
            
            if (maxValue.trim() === "") {
                setError("Please enter maximum value")
                setErrorCode("maxValue")
                return
            }

            
            if (isNaN(minValue)) {
                setError("Minimum value should be numeric")
                setErrorCode("minValue")
                return
            }
            
            if (isNaN(maxValue)) {
                setError("Maximum value should be numeric")
                setErrorCode("maxValue")
                return
            }
            
            const min = +minValue
            const max = +maxValue
            if (min >= max) {
                setError("Minimum value should be less than maximum value")
                setErrorCode("minValue")
                return 
            }
        }

        const finalEncodings = encodings.concat(encodings2)
        if (metricType === "Encoded") {
            if (finalEncodings.filter(t => t.label.trim() === "" || t.value.trim() === "").length > 0) {
                setError("Please enter label/value for all possible values")
                return
            }
            
            if ( finalEncodings.length === 0) {
                setError("Please enter possible metric values with labels")
                return
            }

            if (finalEncodings.filter(t => isNaN(t.value)).length > 0) {
                setError( "Value should be numeric")
                return
            }

            if (finalEncodings.reduce((acc, curr) => {
                if (!acc && finalEncodings.filter(t => t.label.trim() === curr.label.trim()).length > 1)
                    acc = true

                return acc
            }, false)) {
                setError("Duplicate labels present")
                return
            }
            
            if (finalEncodings.reduce((acc, curr) => {
                if (!acc && finalEncodings.filter(t => t.value.trim() === curr.value.trim()).length > 1)
                    acc = true

                return acc
            }, false)) {
                setError("Duplicate values present")
                return
            }

        } 

        const minMaxValue = {minValue, maxValue }
        const metrics = csvData.reduce((acc, curr, index) => {
            if (index === 0 || isInvalid(curr[1], metricType))
                return acc
            
            acc[curr[0]] = applyConversion(curr[1], metricType)

            return acc
        }, {})

        if (Object.keys(metrics).length === 0) {
            setError("No valid entry in uploaded data")
            return 
        }

        setLoading(true)
        
        const user = getUserEmail()

        const metricData = [{timestamp: 0, metrics: metrics}]
        const importedMetric = {
            disabled: false,
            group_id:  customMetricGroup,
            metric: metricName,
            tenant: "ftc",
            metricData: metricData, 
            minMaxValue, 
            metricType, 
            encodings: finalEncodings,
            site: criteriaContext.site,
            shared,
            user
        }

        saveMetric(importedMetric, criteriaContext.site, () => {
            
            
            modelContext.setImportedMetrics([...modelContext.importedMetrics, importedMetric])
            modelContext.setImportingMetric({metricName: metricName+":CM", metricData: metricData, minMaxValue, metricType, encodings: finalEncodings})

            hideModal()
            
        }, err => {
            setError(err)
            setLoading(false)
        })

        
    }

    const hideModal = () => {
        setStep(1)
        setModalOpen(false)
        setMetricType('')
        setMetricName('')
        setMinValue('')
        setMaxValue('')
        setEncodings([])
        setLabel('')
        setValue('')
        setCsvData([])
        setLoading(false)
    }

    const urlPost = (query, data) => {
        let token = getAccessToken()
        return axios({
            url: `custom_metrics?querytype=${query}&restype=json&project=depcom`,
            method: 'POST',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: data
        })
    }

    const saveMetric = (importedMetric, site, callback, errorCallback) => {
        const {
            group_id,
            metric,
            tenant,
            metricData,
            minMaxValue, 
            metricType, 
            encodings,
            shared,
            user
        } = importedMetric

        let requests = []

        const metricInfo = {site, metric, group_id, tenant, metricType, minValue: minMaxValue.minValue, maxValue: minMaxValue.maxValue, shared, user}
        const r1 = urlPost('metric_add', metricInfo)
        requests.push(r1)

        const r2 = urlPost('labels_add', {site, metric, labels: encodings})
        requests.push(r2)

        if (metricData && metricData.length > 0 && metricData[0].metrics) {
            const metrics = metricData[0].metrics
            const r3 = urlPost('readings_add', {site, metric, group_id, metrics: Object.keys(metrics).map(t => ({device: t, value: metrics[t], zone: 'zone'}))}) //TODO: zoneid
            requests.push(r3)
        }

        Promise.all(requests)
            .then(callback)
            .catch(err => errorCallback(err.message))
    }

    const prepulateMetrics = () => { 
        if (metricType === 'Binary') {
            setEncodings([
                { value: "0", label: 'No', color: 'green' },
                { value: "1", label: 'Yes', color: 'red' },
            ])
        } else if (metricType === 'Dynamic') {
            const minMaxValue = csvData.reduce((acc, curr, index) => {
                if (index === 0 || isInvalid(curr[1], metricType))
                    return acc
            
                const h = applyConversion(curr[1], metricType)
                if (acc.minValue === '' || acc.minValue > h)
                    acc.minValue = h

                if (acc.maxValue === '' || acc.maxValue < h)
                    acc.maxValue = h

                return acc
            }, {minValue: '', maxValue: ''})
            const { minValue, maxValue } = minMaxValue
            setMinValue(minValue.toString())
            setMaxValue(maxValue.toString())
            setEncodings([
                { value: "0", label: 'Minimum value', color: "#EE74E1" },
                { value: "1", label: 'Maximum value', color: "#2EB785" },
            ])
        } else if (metricType === 'Encoded') {
            const encodings = csvData.reduce((acc, curr , index) => {
                if (index === 0 || isInvalid(curr[1], metricType))
                    return acc

                const h = applyConversion(curr[1], metricType)
                if (acc.filter(t => t.value === h).length === 0) {
                    acc.push({ value: h, label: ''})
                }
                return acc
            }, []).slice(0, 30)
            .map((t,i) => ({...t, color: colors[i] }))

            setEncodings(encodings)
        }
    }

    const nextStep = () => {
        if (step === 1) {
            setStep(2)
            prepulateMetrics()
        }
        else
            importCustomMetric()
    }

    const cancelEncoding = () => {
        setValue('')
        setLabel('')
        setAddingEncoding(false)
    }

    const deleteEncoding = value => {
        setEncodings(encodings.filter(t => t.value !== value))
    }

    const downloadSample = () => {
        const model = modelContext.model
        let deviceIds = []
        
        model.children[0].children.forEach(t => {
            t.children.forEach(e => {
                const deviceId = e.userData.deviceId
                if (deviceId)
                    deviceIds.push(deviceId)
            })
        })

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "DeviceId,Value\r\n"

        deviceIds.forEach(function(deviceId) {
            let row = deviceId + ","
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "my_data.csv");
        document.body.appendChild(link); 

        link.click();
    }

    const handleChange2 = e => {
        setShared(e.target.checked)
    }

    const deleteMetric = (metric, site) => {
        
        modelContext.setImportedMetrics(modelContext.importedMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, deleting: true } : t))
        
        urlPost("metric_delete", {site, metric, group_id:  customMetricGroup})
            .then(() => {
                modelContext.setImportedMetrics(modelContext.importedMetrics.filter(t => !(t.site === site && t.metric === metric)))
            })
            .catch((err) => {
                setError(err.message)
                modelContext.setImportedMetrics(modelContext.importedMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, deleting: false } : t))
            })
    }

    

    const shareMetric = (shared, metric, site) => {
        modelContext.setImportedMetrics(modelContext.importedMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, sharing: true } : t))

        urlPost("metric_share", { shared, metric, site, group_id:  customMetricGroup })
            .then(() => {
                modelContext.setImportedMetrics(modelContext.importedMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, shared: shared, sharing: false } : t))
            })
            .catch(err => {
                setError(err.message)
                modelContext.setImportedMetrics(modelContext.importedMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, sharing: false } : t))
            })
    }


    const deleteCsvData = () => {
        setMetricType('')
        setMinValue('')
        setMaxValue('')
        setEncodings([])
        setEncodings2([])
        setStep(1)
        setCsvData([])
    }

    const setEncodingsTemp = (value, label) => {
        const e = encodings.map(t => t.value === value ? { ...t, label: label, editing: true } : t)
        setEncodings(e)
    }

    const handleColorChange = (encoding, color) => {
        const e = encodings.map(t => t.value === encoding.value ? { ...t, color: color.hex } : t)
        setEncodings(e)
    }
    
    const handleColorChange2 = (encoding, color) => {
        const e = encodings2.map(t => t.id === encoding.id ? { ...t, color: color.hex } : t)
        setEncodings2(e)
    }

    const addEncoding2 = () => {
        setEncodings2(encodings2.concat({id: Math.random(), value: "", label: "", color  :  getRandomColor()}))
        setAddingEncoding(true)
    }

    const deleteEncoding2 = id => {
        setEncodings2(encodings2.filter(t => t.id !== id))
    }

    const editEncoding2 = (id, label, value) => {
        setEncodings2(encodings2.map(t => t.id === id ? 
            {
                ...t, 
                label: label !== null ? label : t.label,
                value: value !== null ? value : t.value
            } : t))
    }

    const Step2 = <div style={{display: 'flex', flexDirection: 'column', gap: 20, height: 200, overflow: 'scroll'}} ref={step2Ref}>
            <span style={{fontSize: 16}}><AiFillCaretLeft onClick={() => setStep(1)} />  Metric Type: {metricType === 'Dynamic' ? 'Varying' : metricType}</span>
            {metricType === 'Dynamic' ? <div style={{display: 'flex', gap: 8}}>
                <div>
                    <span>Min value: </span>
                    <input value={minValue} onChange={e => handleChange(e.target.value, setMinValue, 10)} ref={minValueRef}></input>
                </div>
                <div>
                    <span>Max value: </span>
                    <input value={maxValue} onChange={e => handleChange(e.target.value, setMaxValue, 10)} ref={maxValueRef}></input>
                </div>
            </div> : metricType === 'Encoded' ? <div style={{fontSize: 16}}>
                <span style={{fontWeight: 700}}>Possible values: <br/></span>
                {encodings.length === 0 ? <span style={{fontSize: 14}}>No values added<br/></span> : null}
                {encodings.map((encoding,i) => <div className="entry" style={{borderTop: i === 0 ? '1px solid #eeeeee' : 'none', marginTop: i === 0 ? 8 : 0}}>
                    <span>{encoding.label.trim() !== "" && !encoding.editing ? <span>{encoding.label}</span> : <input value={encoding.label} onChange={e => handleChange(e.target.value, (t) => setEncodingsTemp(encoding.value, t), 24)} placeholder="Enter label" ref={i === 0 ? firstLabelRef : null}></input>} ({encoding.value})</span>
                    <div style={{display: 'flex', gap: 12, alignItems:  'center'}}>
                        <ColorEdit encoding={encoding} handleColorChange={handleColorChange} />
                        <AiOutlineDelete onClick={() => deleteEncoding(encoding.value)} style={{  cursor:  'pointer'}}/>
                    </div>
                </div>)}
                
                {encodings2.map((encoding, i) => <div className="entry" style={{borderTop: 'none', marginTop: 0}}>
                    <div style={{display: 'flex', gap: 2}}>
                        <input value={encoding.label} onChange={e => handleChange(e.target.value, t => editEncoding2(encoding.id, t, null), 24)} placeholder="Enter label" ref={i === encodings2.length - 1  ? lastLabelRef : null}></input>
                        <input value={encoding.value} onChange={e => handleChange(e.target.value, t => editEncoding2(encoding.id, null, t), 7)} placeholder="Enter value" style={{width: 160}}></input>
                    </div>
                    <div style={{display: 'flex', gap: 12, alignItems:  'center'}}>
                        <ColorEdit encoding={encoding} handleColorChange={handleColorChange2} />
                        <AiOutlineDelete onClick={() => deleteEncoding2(encoding.id)} style={{  cursor:  'pointer'}}/>
                    </div>
                </div>)}
                
                <div style={{fontSize: 14, paddingTop: 8}}>
                    <button className="action primary" onClick={addEncoding2}><AiFillPlusCircle style={{fontSize: 16}} /> Add value/label pair</button>
                </div>
            </div> : null}
            {metricType === 'Binary' || metricType === 'Dynamic' ? <div>
                <span style={{fontWeight: 700}}>Color coding: <br/></span>
                {encodings.map((encoding,i) => <div className="entry" style={{ marginTop: 0, padding: 10 }}>
                    <span>{encoding.label}</span>
                    <ColorEdit encoding={encoding} handleColorChange={handleColorChange} />
                </div>)}
            </div> : null}
        </div>

    const iMetrics = modelContext.importedMetrics.filter(t => t.site  === criteriaContext.site && (t.shared || (t.user === getUserEmail())))
    const AllMetrics = iMetrics.length > 0 ? <ListGroup as="ol" numbered>
            {iMetrics.map(t => <ListGroup.Item
                as="li"
                className="d-flex justify-content-between"
            >
                <div className="ms-2 me-auto">
                    <div className="fw-bold">{t.metric} {t.shared ? <Badge pill>Shared</Badge> : null}</div>
                    {t.metricType}
                </div>
                {t.user === getUserEmail() ? <div style={{ display: 'flex', gap: 10 }}>
                    <Button 
                        className="import-button" 
                        onClick={() => shareMetric(!t.shared, t.metric, t.site)} 
                        disabled={t.sharing}
                    >
                        {(t.shared ? 'Unshar' : 'Shar') + (t.sharing ? 'ing' : 'e')}
                    </Button>
                    <Button 
                        className="import-button"  
                        style={{backgroundColor: '#dc3545', borderColor: "#dc3545"}} 
                        onClick={() => deleteMetric(t.metric, t.site)} 
                        disabled={t.deleting}
                    >
                        {t.deleting ? 'Deleting' : 'Delete'}
                    </Button>
                </div> : null}
            </ListGroup.Item>)}
            
        </ListGroup> : <div>No metrics added</div>

    return !modelContext.error && !modelContext.loading ? <CustomOverlay>
        <Button 
            className="import-button" 
            onClick={() => setModalOpen(true)}
        >
            Import Custom Metric
        </Button>

        {modalOpen ? <Modal.Dialog className="overlay" show={modalOpen}>
            <Modal.Header className="overlay-header" closeButton onHide={hideModal}>
                <Modal.Title>Import Custom Metric</Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay-body">
                {error ? <InfoStyle><span>{error}</span></InfoStyle> : null}
                <div style={{paddingBottom: 30}}>
                    <Form className="form" onSubmit={e => e.preventDefault()}>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Enter Metric Name: </Form.Label>
                            <Form.Control
                                type="text"
                                autoFocus
                                value={metricName}
                                onChange={e => handleChange(e.target.value, setMetricName, 40)}
                                ref={metricNameRef}
                            />
                        </Form.Group>
                    </Form>
                    <p>{csvData.length === 0 ? 'Upload  CSV:' : ''}</p>
                    {csvData.length === 0 ? <div className="row">
                        <FilePond 
                            allowMultiple={false} 
                            beforeAddFile={file => {
                                if (file.fileExtension !== "csv") {
                                    setError("Please upload a csv file")
                                    return false
                                } else {
                                    return true
                                }
                            }}
                            server="/api" 
                            onupdatefiles={files => {
                                if (files.length > 0 && files[0].fileExtension === "csv") {
                                    var reader = new FileReader();
                                    reader.onload = function(e) {
                                        if (reader.result) {
                                            const rows = reader.result.split('\n')
                                            const csvData = rows
                                                .filter(row => row !== "")
                                                .map(row => row.split(','))
                                            setCsvData(csvData)
                                        }
                                    }
                                    reader.readAsText(files[0].file);
                                }
                            }}    
                        />
                        <Sheet heading="CSV Format:" data={exampleData} download={true} downloadSample={downloadSample}/>
                    </div> : <div style={{display: 'flex'}}>
                        <div style={{position: 'relative', width: '40%'}}>
                            <Sheet heading="CSV Data:" data={csvData} delete={true} deleteData={deleteCsvData}/>
                        </div>
                        <div style={{width: '60%', paddingLeft: 20, borderLeft: '1px solid #eeeeee', marginLeft: 20, display: 'flex', flexDirection: 'column', gap: 20}}>
                            {step === 1 ? <span style={{fontSize: 16}}>Select metric type:</span> : null}
                            
                            {step === 1 ? <Dropdown
                    
                                key={"down"}
                                id={`dropdown-button-drop-down`}
                                drop={"down"}
                                variant="secondary"
                                title={` Select metric type `}
                            >
                                <Dropdown.Item eventKey="1" onClick={() => setMetricType('Binary')} active={metricType === 'Binary'}>Binary (Yes/No)</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={() => setMetricType('Dynamic')} active={metricType === 'Dynamic'}>Varying (number)</Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={() => setMetricType('Encoded')} active={metricType === 'Encoded'}>Encoded (distinct values)</Dropdown.Item>
                            </Dropdown> : null}
                            {step === 2 ? Step2 : null}
                            {step === 2 ?  <Form.Check 
                                type={"checkbox"}
                                id={`share`}
                                label={`Share with everyone`}
                                checked={shared}
                                onChange={handleChange2}
                            />  : null}
                            {metricType !== '' ? <Button disabled={loading} className="import-button" onClick={nextStep}>{step === 1 ? 'Next' : loading ? 'Importing..' : 'Import'}</Button> : null}
                        </div>
                    </div>}
                </div>
                {csvData.length === 0 ?  <span className="all-metrics-link">Imported metrics</span> : null}
                {csvData.length === 0 ?   AllMetrics : null}
            </Modal.Body>

      </Modal.Dialog> : null}
    </CustomOverlay> : null
}

