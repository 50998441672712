import { unionBy } from "lodash";

export function comparator(a, b, sortColumn) {
    return a[sortColumn] - b[sortColumn]
}

export const getChartJSDataSets = (data, sortColumn, seriesColumn, xColumn, yColumn, labelPicker, colorPicker, fillType) => {

    fillType = typeof fillType !== 'undefined' ? fillType : 'false';
    //console.log(fillType)
    const keys = [...new Set(data.map(item => item[seriesColumn]))];
    //console.log("chartjsdata k", keys)
    const ds = keys.map((k, i) => {
        const sortedDs = data.filter(f => f[seriesColumn] === k).sort((a, b) => comparator(a, b, sortColumn))
        //console.log("sorted", seriesColumn, sortedDs)
        const d = sortedDs.map((e) => {
            return { x: new Date(e[xColumn] * 1000), y: e[yColumn] }
        })

        const c = colorPicker(k)
        const seriesLabel = labelPicker(k)
        if(fillType == 'true'){
            return {
                label: seriesLabel,
                borderColor: c, //colorIndex(i),
                backgroundColor: c,
                data: d,
                pointRadius: 1,
                borderWidth: 2
    
            }
        }else{
            return {
                label: seriesLabel,
                borderColor: c, //colorIndex(i),
                data: d,
                pointRadius: 1,
                borderWidth: 2
            }
        }
    })

    return ds
}


export const getChartJSDataSetsSingle = (data, sortColumn, seriesColumn, xColumn, yColumn, labelPicker, colorPicker) => {

    //console.log("chartjsdata k", keys)
    const sortedDs = data.sort((a, b) => comparator(a, b, sortColumn))
    //console.log("sorted", seriesColumn, sortedDs)
    const d = sortedDs.map((e) => {
        return { x: new Date(e[xColumn] * 1000), y: e[yColumn] }
    })

    const c = colorPicker(seriesColumn)
    const seriesLabel = labelPicker(seriesColumn)

    return [{
        label: seriesLabel,
        borderColor: c, //colorIndex(i),
        backgroundColor: c,
        fill: false,
        data: d,
        pointRadius: 1,
        borderWidth: 2

    }]

}

export const getUptimeDataSets = (data, sortColumn, seriesColumn, xColumn, yColumn, labelPicker, colorPicker) => {

    //console.log("chartjsdata k", keys)
    const sortedDs = data.sort((a, b) => comparator(a, b, sortColumn))
    // console.log("sorted", data, sortedDs)

    const labels = sortedDs.map((e) => {return new Date(e[xColumn] * 1000) })
    // console.log("labels", labels, sortedDs, "data", data) 

    data = sortedDs.map((e) => {return e[yColumn] })
    const backgroundColor = sortedDs.map((e) => {return "#4daf4a" })
    return { 
        labels: labels,
        datasets:[{label:"Uptime ", fontColor: "#fff", data: data, borderWidth: 4, backgroundColor: "rgba(0, 128, 0, 0.6)", borderColor: "#eee", borderWidth: 1}] //, 
    }
}

export const getStackedAreadata = (data, sortColumn, seriesColumn, xColumn, yColumn, labelPicker, colorPicker, fillType) => {

    fillType = typeof fillType !== 'undefined' ? fillType : 'false';
    //console.log(fillType)
    const keys = [...new Set(data.map(item => item[seriesColumn]))];
    //console.log("chartjsdata k", keys)
    const ds = keys.map((k, i) => {
        const sortedDs = data.filter(f => f[seriesColumn] === k).sort((a, b) => comparator(a, b, sortColumn))
        //console.log("sorted", seriesColumn, sortedDs)
        const d = sortedDs.map((e) => {
            return { x: new Date(e[xColumn] * 1000), y: e[yColumn] }
        })
        var colors = {"OFFLINE": '#af4a4a', "SNOW-STOW": '#377eb8',"MANUAL": '#984ea3',"WIND-STOW": '#ffff33',"AUTO": '#4daf4a',"EMERGENCY-STOW": '#4aaf9b'}
        const c = k in colors ? colors[k] : colorPicker(k)
        
        const seriesLabel = labelPicker(k)
        if(fillType == 'true'){
            return {
                label: seriesLabel,
                // borderColor: c, //colorIndex(i),
                backgroundColor: c,
                data: d,
                pointRadius: 1,
                borderWidth: 2
    
            }
        }else{
            return {
                label: seriesLabel,
                // borderColor: c, //colorIndex(i),
                data: d,
                pointRadius: 1,
                borderWidth: 2
            }
        }
    })

    return ds
}

function sampleRangeErrorSummary(data, sampleCount, errorCodes) {
    const sampleArray = [...Array(sampleCount).keys()]
    return errorCodes.map((code) => {
        const codeData = data.filter((d) => d.errorCode === code)
        const sampleData =  sampleArray.map((e) => {
            return Object.assign({ time_key: e, errorCode: code, errorCount: 0 })
        })
        const mergeData =  unionBy(codeData, sampleData, "time_key");
        return mergeData
    })
}

function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

export const applyToSampleByTime = (data, totalMinutes, interval) => {
    const minutes = Math.floor(totalMinutes / interval)
    const uniqueErrorCodes = [...new Set(data.map(item => item.errorCode))];
    // console.log("Unique Error codes", uniqueErrorCodes)
    if (minutes > 0 && uniqueErrorCodes) {
        const samples = flatten(sampleRangeErrorSummary(data, minutes, uniqueErrorCodes)) 
        return samples
    }
}

function sampleRangeModeSummary(data, sampleCount, modes) {
    const sampleArray = [...Array(sampleCount).keys()]
    return modes.map((mode) => {
        const modeData = data.filter((d) => d.mode === mode)
        const sampleData =  sampleArray.map((e) => {
            return Object.assign({ time_key: e, mode: mode, total: null })
        })
        const mergeData =  unionBy(modeData, sampleData, "time_key");
        return mergeData
    })

}

export const applyToSampleByTime2 = (data, totalMinutes, interval) => {
    const minutes = Math.floor(totalMinutes / interval)
    const uniqueCodes = [...new Set(data.map(item => item.mode))];
    //console.log(totalMinutes, interval, minutes, uniqueCodes)
    if (minutes > 0 && uniqueCodes) {
        const samples = flatten(sampleRangeModeSummary(data, minutes, uniqueCodes)) 
        return samples
    }
}
