import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { getUserPortfolios, isAdminUser,getAccessToken } from "../components/auth/userinfo";
import { defaultPortfolio } from "../components/layout/criteria";
import { GetFQURL, postJSONData } from "../components/model/store.swr";
import { useCriteriaContext } from "../context/criteria.context";
import { MetaSitesBaseView } from "./allsites";
import { createActivityLog } from "../utils/apiCall";
import { getLogData } from "../helpers/common";

export function fetchJSONData(url, onSuccess, onError) {
    
    let token = getAccessToken()
   

    fetch(url,{headers: {'Content-Type': 'application/json','x-access-token': token}})
        .then(res => res.json())
        .then(data => {
            // if (!ref.current) return null
            onSuccess(data)
        })
        .catch(err => {
            onError(err)
        });
}


const portfolioColumns = [
    { dataField: 'portfolio_id', text: 'Portfolio', editable: false, sort: true },
    // { dataField: 'user_id', text: 'User', sort: true, editable: false },
];

export const UserPortfolioBaseView = (props) => {
    const [urls, setUrls] = useState([{ name: "portfolios", url: "meta_portfolios", format: "json" }])
    const criteria = useCriteriaContext()
    const [urldata, setUrlData] = useState([]);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(props.args.loading);
    const mountedRef = useRef(true)
    const [reloadUrls, setReloadUrls] = useState(0)
    const [message, setMessage] = useState("Please wait loading  ...")
    const [portfolio, setPortfolio] = useState()
    const [newPortfolio, setNewPortfolio] = useState('')
    const [sites, setSites] = useState([])
    const [editable] = useState(isAdminUser())
    

 
    function fetchUrlData(url, action_f, msg ){
        mountedRef.current = true
        setMessage(msg)
        setLoading(true)
        // const url = GetFQURL(urls[0], criteria, "json", true)
        // console.log("Fetch Json " + url)

        // handleUrlChange()
        fetchJSONData(url, (data)=> {
            if (!mountedRef.current) return null
            // console.log('Checkout this JSON! ', data)
            // setColumns(data && data.length > 0 ? makeColumnsBT(Object.keys(data[0])) : [])
            // setUrlData(data);
            action_f(data)
            setLoading(false)

        }, (err)=>{
            if (!mountedRef.current) return null
            setLoading(false)
            throw err
        })

    }

    function filterUserPortfolios(data){
        const pfs = getUserPortfolios()
        if (pfs && pfs.length > 0) {
            const apfs = pfs.split(",").map(e=> e.trim())
            // console.log(apfs)
            const filteredData = data.filter(e => apfs.includes(e.portfolio_id))
            // console.log(filteredData)
            setUrlData(filteredData)
            return
        }
        setUrlData(data)

    }

    useEffect(() => {
        
        const url = GetFQURL(urls[0], criteria, "json", true);
        fetchUrlData(url, filterUserPortfolios, "Loading Portfolios .....")
        return () => {
            mountedRef.current = false
        }
    }, [urls, reloadUrls])

    function handleRowClick(row, history) {
        // console.log("Row Selected ", row)
        setPortfolio(row)
    }

    function reloadPortfolios() {
        setUrls(urls)
    }

    function handleSavePortfolio() {
        setLoading(true)
        setMessage("Saving " + newPortfolio)
        const value = newPortfolio
        if (value === "") {
            alert("Enter Portfolio Name")
            return
        }
        if (value === "All"){
            alert("All Portfolio is Not user Portfolio! Cannot Save!")
            return 
        }
        if (!sites || sites.length === 0) {
            alert("No Sites Selected !! ")
            return
        }
        postJSONData("/meta?querytype=meta_portfolios_update", { user_id: '', portfolio_id: value, sites: sites, action: "save" }, (json) => { onComplete(false, json) })
        createActivityLog(getLogData(criteria, {featureId: `create-portfolio`,  usecase: `create portfolio`, category: 'edit-portfolio', portfolio: value, sites: sites.join(",")}))
    }


    function handleDeletePortfolio() {
        const value = newPortfolio
        if (value === "") {
            alert("Enter Portfolio Name")
            return
        }
        if (value === defaultPortfolio) {
            alert("Cannot delete default portfolio ..")
            return 
        }
        setLoading(true)
        setMessage("deleting " + newPortfolio)
        postJSONData("/meta?querytype=meta_portfolios_update", { user_id: '', portfolio_id: value, action: "delete" }, (json) => { onComplete(true, json) })
        createActivityLog(getLogData(criteria, {featureId: `delete-portfolio`,  usecase: `delete portfolio`, category: 'edit-portfolio', portfolio: value}))
    }
    function onComplete(deleted, response) {
         setLoading(false); 
         
        if (deleted) {
            setMessage("Delete Sucessfull!");
            if (data && data.length > 0) {
                setPortfolio(data[0].portfolio_id)
                // setNewPortfolio(data[0].portfolio_id)
            } else {
                setPortfolio(null)
                // setNewPortfolio("")

            }
        } else {
            setMessage("Save Sucessfull!");
            setPortfolio(newPortfolio)
        }
        setReloadUrls(reloadUrls + 1); 

    }

    function onPortfolioChange(){
        if (portfolio) {
            const sites = urldata.filter(f => f.portfolio_id === portfolio).map(e => e.site_id)
            setSites(sites)
            // setNewPortfolio(portfolio_id)
            setNewPortfolio(portfolio)
        }

    }
    useEffect(() => {
        setMessage(portfolio + " Portfolio selected"  )
        onPortfolioChange()

    }, [portfolio, reloadUrls])

    useEffect(() => {
        setMessage("")
        // const allPortfolios = urldata.map(e=> { return {portfolio_id: e.portfolio_id, user_id: e.user_id} })
        // console.log(allPortfolios)
        // const unique = [...new Set(allPortfolios)]
        const unique = urldata.filter((v, i, a) => a.findIndex(t => (t.portfolio_id === v.portfolio_id && t.user_id === v.user_id)) === i).map((e, i) => { return { key: i, user_id: e.user_id, portfolio_id: e.portfolio_id } })
        // console.log(unique)
        setData(unique)
        onPortfolioChange()
        // setPortfolio("")
        // setNewPortfolio("")
    }, [urldata])

    const selectSitesProps = { loading: true, selectOnly: true, onSelect: setSites }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            // console.log(row)
            setPortfolio(row.portfolio_id)
            //   console.log(`clicked on row with index: ${rowIndex}`);
        },
        // onMouseEnter: (e, row, rowIndex) => {
        //   console.log(`enter on row with index: ${rowIndex}`);
        // }
    };

    const PortfolioView = () => {
        return (
            <>
            {(data) &&
            <BootstrapTable
                keyField='portfolio_id'
                data={data}
                columns={portfolioColumns}
                rowEvents={rowEvents}
                selectRow={{ mode: 'radio',   hideSelectColumn: true, clickToSelect: true, style: { backgroundColor: '#E7E9EE', color: '#014059', fontWeight:'600' }, selected: [portfolio] }}
            />
            }
            </>
        )
    }

    const portfolioSitesView = () => {
        return (
            <>
                {/* <Form>
                    <Form.Control type="text" placeholder="Portfolio Name"
                        value={newPortfolio} onChange={(e) => { setNewPortfolio(e.target.value) }}
                    />
                    <Button variant="primary" type="submit" onClick={handleSavePortfolio}>Save</Button>
                </Form> */}
                <div >
                    <input type="text" value={newPortfolio} onChange={(e) => setNewPortfolio(e.target.value)}>
                    </input> <button onClick={handleSavePortfolio} disabled={!editable}>Save </button>
                    {(newPortfolio !== "") ? <button onClick={handleDeletePortfolio}  disabled={!editable}>Delete </button> : ''}
                    <span className="m-5">{message}</span>
                </div>
                <div className="row edit-portfolio-table" style={{ maxHeight: "70%", width:"100%", display: "inline-block" }}>
                <MetaSitesBaseView args={selectSitesProps} sitesSelected={sites} />
                </div>
            </>
        )
    }
    const vheight = "70vh";
    return (
        // <div className="d-inline-block" style={{maxHeight: "70vh", overflow: "auto"}}>
        <div className="portfolio-wrapper" disabled={loading}>
            <div className="row"  style={{  maxHeight: vheight }}>
                <div className="col-3 portfolio-left-section"  style={{ overflow: "scroll", maxHeight: vheight, height: '61vh' }}>
                    <PortfolioView />
                </div>
                {/* <div className="row" style={{ overflow: "auto", display: "inline-block", maxHeight: "20vh" }}>
                <PortfolioView />
                </div> */}
                <div className="col-9 portfolio-right-section"  style={{ maxHeight: vheight }}>
                    {portfolioSitesView()}
                </div>
            </div>
            {/* <div className="row">
            </div> */}
             <style>
                {`
                    .portfolio-left-section thead th .order{
                        margin-left: 5px
                    }
                `}
            </style>

        </div>
        // </div>

    )


}