import CryptoJS from "crypto-js";
import Intervals from '../../config.json';
// import Intervals from '/etc/data/config.json';
const secretPass = "XkhZG4fW2t2W";


export const CSVToArray = (data, delimiter = ',') =>
  data.split(delimiter).filter(element => element !== '');

export function parseUserInfo(data){
  return JSON.parse(Buffer.from(data.type, 'base64').toString('ascii'))
}

export function isData(data){
  return data && data.type !== null
}

export function getUser(){
  const userInfo = getUserInfo() 
  return userInfo && userInfo.user
}

const getParsedUserData = () => localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))
export const getAccessToken = () => {
  var tokenObj = getTokenKey()
  return tokenObj ? JSON.parse(tokenObj['atoken']) : null;
}

export const getTokenKey = () => {
  var key = localStorage.getItem('tkey');
  if(key) {
    const bytes = CryptoJS.AES.decrypt(key, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  }
  return null;
 
}

export const setEncryptedToken = (tokenValue) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(tokenValue),
    secretPass
  ).toString();

  localStorage.setItem('tkey',data)
}

function getUserInfo() {
    let data = getParsedUserData()
    if (data) {
      return data.type && JSON.parse(Buffer.from(data.type, 'base64').toString('ascii'))
    }
}

export function getUserToken() {
  let data = getParsedUserData()
  return data && data.token
}

export function getUserId() {
  const userInfo = getUserInfo()
  return userInfo && userInfo.user.userId
}


//  export function getUserName() {
//     const userInfo = getUserInfo()
//     if (userInfo) {
//       const userName = userInfo ? userInfo.user.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : '';
//       return userInfo?userInfo.user.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase()):''
//     }
//   }

export function getUserName() {
    const userInfo = getUserInfo()
    if (userInfo) {
        const userName = userInfo ? userInfo.user.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : '';
        const fullName = userName != '' ? userName.split(' ') : '';
        // const initials = fullName != '' ? fullName.shift().charAt(0) + fullName.pop().charAt(0) : '';
        const initials = fullName != '' ? (fullName.length == 1) ? fullName.shift().charAt(0): fullName.shift().charAt(0) + fullName.pop().charAt(0) : '';
        return initials != '' ? initials : ''
    }
}

export function getUserID() {
    const userInfo = getUserInfo()
    if (userInfo) {
        const id = userInfo.user.email.replace(/[@\. ]/g, "");
        // console.log(id)
        return id
    }
  }
  export function getUserPortfolios() {
    const userInfo = getUserInfo()
    if (userInfo && userInfo.user && userInfo.user.portfolios) {
      // const pfs = CSVToArray(userInfo.user.portfolios);
      const pfs = userInfo.user.portfolios;
      // console.log(pfs)
      return pfs
    }
    return 
  }
  export function getUserEmail() {
    const userInfo = getUserInfo()
    if (userInfo && userInfo.user && userInfo.user.email) {
      // const pfs = CSVToArray(userInfo.user.portfolios);
      return userInfo.user.email;
    }
  }
  
  export function getUserOrg() {
    const userInfo = getUserInfo()
    if (userInfo && userInfo.user && userInfo.user.org) {
      // const pfs = CSVToArray(userInfo.user.portfolios);
      return userInfo.user.org;
    }
  }
  
  export function isAdminUser() {
    const userInfo = getUserInfo()
    if (userInfo){
      // const admins = ["anandhasmails@gmail.com", "amorse@ftcsolar.com", "mani@ftcsolar.com", "anarendra@ftcsolar.com", "gchopra_contractor@ftcsolar.com"]
      // return (admins.find(e => e === userInfo.user.email) !== undefined)
      return userInfo.access.isAdmin
    }
  }

  export function isHailAdminUser() {
    const userInfo = getUserInfo()
    if (userInfo){
      // const admins = ["anandhasmails@gmail.com", "amorse@ftcsolar.com", "mani@ftcsolar.com", "anarendra@ftcsolar.com", "gchopra_contractor@ftcsolar.com"]
      // return (admins.find(e => e === userInfo.user.email) !== undefined)
      return userInfo.access.hail_admin
    }
  }
  export function ischangePasswordPage() {
    const userInfo = getUserInfo()
    if (userInfo){
      const count=userInfo.access.changePassword
      if(count==null){
        return true
      }
      else{
        return false
      }
      
    }
  }

  export function ischangePasswordCountNull() {
    const userInfo = getUserInfo()
    if (userInfo){
      const count=userInfo.access.changePassword
      if(count==null){
        return true
      }
      else{
        return false
      }
    }
  }
  
  export function isNotificationServiceUser() {
    const userInfo = getUserInfo()
    if (userInfo){
      return userInfo.access.notifications_enabled
    }
  }

  export function isHailUser() {
    const userInfo = getUserInfo()
    if (userInfo && userInfo.access && userInfo.access.addOnFeature){
      const addOnFeatures = JSON.parse(userInfo.access.addOnFeature);
      const hailFeature = addOnFeatures.hail;
      return hailFeature
      
    }
  }

  export function isSunpathUser() {
    const userInfo = getUserInfo();
    if (userInfo && userInfo.access && userInfo.access.addOnFeature) {
      const addOnFeatures = JSON.parse(userInfo.access.addOnFeature);
      const sunpathFeature = addOnFeatures.sunpath;
      return sunpathFeature;
    }
  } 
  
  export function isIrradianceUser() {
    const userInfo = getUserInfo();
    if(userInfo){
      return userInfo.access.irradiance_admin;
    }
  }

  export function isController() {
    const userInfo = getUserInfo()
    return (userInfo && userInfo.deviceLogin) 
  }

  export function getTokenTimeoutInterval() {
    try {
      let interval = 0;
      if(Intervals && Intervals.tokenTimeoutDuration && Intervals.tokenTimeoutDuration>0)
      interval = Intervals.tokenTimeoutDuration;
      return interval;
    } catch (err) {
      return 0;
    }
  }
  export function getIdleTimeoutInterval() {
    try {
      let interval = 0;
      if(Intervals && Intervals.idleTimeoutDuration && Intervals.idleTimeoutDuration>0)
      interval = Intervals.idleTimeoutDuration;
      return interval;
    } catch (err) {
      return 0;
    }
  }


  
