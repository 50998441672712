import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SessionTable from './SessionTable';
import MultiDropDownControl from './multidropdown';
import SingleDropDownControl from './singledropdown';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './table.css';
import { getAccessToken } from '../auth/userinfo';
import { Toast } from 'react-bootstrap';

// Retrieve access token
let token = getAccessToken()

// TableComponent Functional Component
const TableComponent = () => {
    // State variables to manage dropdown values, date selection, session data, loading, errors, etc.
    const [dropdown1Options, setDropdown1Options] = useState([]); // [""]
    const [dropdown1Value, setDropdown1Value] = useState("");
    const [dropdown2Value, setDropdown2Value] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sessionData, setSessionData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [emailIds, setEmailIds] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    // Dropdown options for the first dropdown
    // const dropdown1Options = ['FTC', 'Depcom', 'PGR', 'ACT', 'Harman', 'Techtonic'];

    // Fetch organizations data
    const { data: orgs, error: orgError } = useSWR("/get_orgs", async (url) => {
        const token = getAccessToken();
        const response = await fetch(url, {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json",
            },
            cache: "no-store",
        });
        if (!response.ok) {
            throw new Error("Failed to fetch organizations data");
        }
        const data = await response.json();
        console.log("useranalytics", data);
        setDropdown1Options(data.orgs); // Update emailIds state
    });

    // Fetch email data based on the selected organization
    const { data: emailData, error: emailError } = useSWR(
        dropdown1Value ? `/get_email_ids?organization=${dropdown1Value}` : null,
        async (url) => {
            const token = getAccessToken();
            const response = await fetch(url, {
                headers: {
                    "x-access-token": token,
                    "Content-Type": "application/json",
                },
                cache: "no-store",
            });

            if (!response.ok) {
                throw new Error("Failed to fetch email IDs");
            }

            const data = await response.json();
            setEmailIds(data.emailIds); // Update emailIds state
            return data;
        }
    );
    // Reset state when the first dropdown changes
    useEffect(() => {
        setDropdown2Value("");
        setStartDate(null);
        setEndDate(null);
        setSessionData([]);
    }, [dropdown1Value]);

    // Reset state when the second dropdown changes
    useEffect(() => {
        setSessionData([]);
        setStartDate(null);
        setEndDate(null);
    }, [dropdown2Value]);

    // Fetch data from the backend based on user selections
    const fetchData = async () => {
        if (
            !dropdown1Value ||
            !Array.isArray(dropdown2Value) ||
            dropdown2Value.length === 0 ||
            !startDate ||
            !endDate
        ) {
            setShowAlert(true);
            return;
        }
        setFetchingData(true);
        setError(null);

        // Reset sessionData to an empty array
        setSessionData([]);

        // Prepare requestData here
        const requestData = {
            dropdown1Value,
            dropdown2Value:
                dropdown2Value.length > 1
                    ? dropdown2Value
                    : dropdown2Value[0] || null,
            startDate: startDate ? startDate.toISOString().split("T")[0] : null,
            endDate: endDate ? endDate.toISOString().split("T")[0] : null,
        };

        try {
            await new Promise((resolve) => setTimeout(resolve, 1000));

            // Fetch data from the backend
            const response = await fetch("/get_bigquery_data", {
                method: "POST",
                headers: {
                    "x-access-token": token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            // Parse and set the fetched data
            const responseData = await response.json();
            setSessionData(responseData);
            // console.log(responseData);
        } catch (error) {
            console.error("Error during API request:", error);
            setError("An error occurred while fetching data.");
            setSessionData([]);
        } finally {
            setFetchingData(false);
        }
    };

    // Handle checkbox change for the second dropdown
    const handleCheckboxChange = (selectedOption) => {
        const updatedSelection = [...dropdown2Value];
        if (updatedSelection.includes(selectedOption)) {
            updatedSelection.splice(
                updatedSelection.indexOf(selectedOption),
                1
            );
        } else {
            updatedSelection.push(selectedOption);
        }
        setDropdown2Value(updatedSelection);
    };

    return (
        <div>
            <div class="row">
                <div class="col-2">
                    <label>
                        <b class="chart-card-header">Select Organization:</b>
                    </label>
                    <SingleDropDownControl
                        title="Select Organization"
                        options={dropdown1Options.map((option) => ({
                            value: option,
                            label: option,
                        }))}
                        value={{ value: dropdown1Value, label: dropdown1Value }}
                        handleChange={(selectedOption) =>
                            setDropdown1Value(selectedOption.value)
                        }
                    />
                </div>
                <div class="col-4">
                    <label>
                        <b class="chart-card-header">Select User</b>
                    </label>
                    <MultiDropDownControl
                        options={emailIds.map((option) => ({
                            value: option,
                            label: option,
                        }))}
                        value={(Array.isArray(dropdown2Value)
                            ? dropdown2Value
                            : []
                        ).map((option) => ({ value: option, label: option }))}
                        handleChange={(selectedOptions) => {
                            if (selectedOptions) {
                                const updatedSelection = selectedOptions.map(
                                    (option) => option.value
                                );
                                setDropdown2Value(updatedSelection);
                            }
                        }}
                    />
                </div>
                <div class="col-2">
                    <label>
                        <b class="chart-card-header">Start Date</b>
                    </label>
                    <br />
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="custom-datepicker"
                        maxDate={endDate || new Date()}
                    />
                </div>

                <div class="col-2">
                    <label>
                        <b class="chart-card-header">End Date</b>
                    </label>
                    <br />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="custom-datepicker"
                        minDate={startDate}
                        maxDate={new Date()}
                    />
                </div>
                <div class="col-md-2">
                    <label class="marginlabels">
                        <b class="chart-card-header"></b>
                    </label>
                    <br />
                    <button class="btn btn-secondary" onClick={fetchData}>
                        Fetch Data
                    </button>
                </div>
            </div>

            <div class="row">
                <Toast
                    show={showAlert}
                    onClose={() => setShowAlert(false)}
                    delay={3000}
                    autohide
                >
                    <p
                        style={{
                            textAlign: "center",
                            color: "red",
                            marginTop: "10px",
                        }}
                    >
                        {" "}
                        Please fill in all the required fields.
                    </p>
                </Toast>
            </div>

            <div class="row">
                {/* Display session data */}
                <SessionTable
                    sessionData={sessionData}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
                {/* {(!sessionData || sessionData.length === 0) && <p>No data to display.</p>} */}
            </div>
            {fetchingData && (
                <p
                    style={{
                        textAlign: "center",
                        color: "#4C4345",
                        marginTop: "10px",
                    }}
                >
                    Loading...
                </p>
            )}
        </div>
    );
};

export default TableComponent;