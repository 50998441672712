import { useMemo } from "react";
import { useLocation } from "./use-location";

const Route = ({ path, children }) => {
    const location = useLocation()


    // const [currentPath, setCurrentPath] = useState(location.pathname)
    const currentPath = useMemo(() => location.pathname, [location])
    return currentPath.startsWith(path) ? children : null

}

export default Route;