export const viewIds = {
  overview: {
    pf: "pf-overview",
    site: "site-overview",
    zone: "zone-overview",
    rc: "rc-overview",
  },
  "live-status": {
    site: "site-livestatus",
    zone: "zone-livestatus",
    rc: "rc-livestatus",
  },
  "model-viewer": {
    site: "site-modelviewer",
    zone: "zone-modelviewer",
    rc: "rc-modelviewer",
  },
  downloads: {
    pf: "pf-downloads",
    site: "site-downloads",
    zone: "zone-downloads",
    rc: "rc-downloads",
  },
  "manage-chart": "manage-chart",
  "edit-sites": "edit-sites",
  "edit-portfolio": "edit-portfolio",
  "my-charts": "my-charts",
  "change-password": "change-password",
};

export const frequencyOptions = [
  { label: "Daily", value: "Daily" },
  { label: "Every Monday", value: "Monday" },
  { label: "Every Tuesday", value: "Tuesday" },
  { label: "Every Wednesday", value: "Wednesday" },
  { label: "Every Thursday", value: "Thursday" },
  { label: "Every Friday", value: "Friday" },
  { label: "Every Saturday", value: "Saturday" },
  { label: "Every Sunday", value: "Sunday" }
];

export const viewOptions = [
  { label: "Overview", value: "Overview" },
  { label: "Downloads", value: "Downloads" },
  { label: "My Dashboards", value: "My Dashboards" },
];

export const valueOptions = [
  { label: "Last Value", value: "last" },
  { label: "First Value", value: "first" },
  { label: "Average", value: "mean" },
  { label: "Minimum", value: "min" },
  { label: "Maximum", value: "max" },
]


export const dataFormatOptions = [
  { label: "Consolidated", value: "yes" },
  { label: "Individual", value: "no" },
]



export const featureOptions = {
  'Overview': [
    { label: "Historical Performance", value: "Historical Performance", data: { name: "rcuptime", url: "rc_uptime_daily_avg", generateReportPath: "/generate-report", scheduleReportPath: "/schedule-report", editReportPath: '/edit-report', dayMetric: true, level: ['pf','site','zone','device']}},
    { label: "Availability Impact Summary – Path to 99%", value: "Availability Impact Summary – Path to 99%", data: { name: "uptime", url: "availability_summary", scheduleReportPath: "/schedule-report", editReportPath: '/edit-report', generateReportPath: "/generate-report", dayMetric: true, level: ['pf','site','zone','device']}},
    { label: "Availability vs Errors", value: "Availability vs Errors", data: { name: "newperf", url: "performance_by_id", generateReportPath: "/generate-report", scheduleReportPath: "/schedule-report", editReportPath: '/edit-report', dayMetric: true, level: ['pf','site','zone','device']}},
    { label: "Errors Summary", value: "Errors Summary", data: { name: "result",url: "readings1byidcount_errorCode",slice: 60,grouptype: "rcs",metricGroup: "SR", scheduleReportPath: "/schedule-report", generateReportPath: "/generate-report", editReportPath: '/edit-report', dayMetric: true, level: ['pf','site','zone','device']}},
    { label: "Modes Summary", value: "Modes Summary", data: { name: "result",url: "readings1byidcount_currentMode",slice: 60,grouptype: "rcs",metricGroup: "SR", scheduleReportPath: "/schedule-report", generateReportPath: "/generate-report", editReportPath: '/edit-report', dayMetric: true, level: ['pf','site','zone','device']}},
    { label: "Wind Speed Summary (m/s)", value: "Wind Speed Summary (m/s)", data: { name: "result",url: "readings1byid0_WIND_SPEED",slice: 60,grouptype: "zones",metricGroup: "VZ", scheduleReportPath: "/schedule-report", generateReportPath: "/generate-report", editReportPath: '/edit-report', dayMetric: true, level: ['site','zone']}},
    { label: "Wind Direction Summary (degrees)", value: "Wind Direction Summary (degrees)", data: { name: "result",url: "readings1byid0_WIND_DIRECTION",slice: 60,grouptype: "zones",metricGroup: "VZ", scheduleReportPath: "/schedule-report", generateReportPath: "/generate-report", editReportPath: '/edit-report', dayMetric: true,level: ['site','zone']}},
    { label: "Flood Level Summary (mm)", value: "Flood Level Summary (mm)", data: { name: "result",url: "readings1byid0_FLOOD_LEVEL",slice: 60,grouptype: "zones",metricGroup: "VZ", scheduleReportPath: "/schedule-report", generateReportPath: "/generate-report", editReportPath: '/edit-report', dayMetric: true, level: ['site','zone']}},
    { label: "Snow Fall Summary (mm)", value: "Snow Fall Summary (mm)", data: { name: "result",url: "readings1byid0_SNOW_FALL",slice: 60,grouptype: "zones",metricGroup: "VZ", scheduleReportPath: "/schedule-report", generateReportPath: "/generate-report",editReportPath: '/edit-report',  dayMetric: true, level: ['site','zone']}},
    { label: "Active Power Summary", value: "Active Power Summary", data: { name: "result",url: "readings1byid0_Active Power",slice: 60,grouptype: "zones",metricGroup: "VI", scheduleReportPath: "/schedule-report", generateReportPath: "/generate-report", editReportPath: '/edit-report', dayMetric: true, level: ['site','zone']}},
    { label: "Current Angle Summary", value: "Current Angle Summary", data: { name: "result",url: "readings1byid0_currentAngle",slice: 60,grouptype: "rcs",metricGroup: "SR", scheduleReportPath: "/schedule-report", generateReportPath: "/generate-report", editReportPath: '/edit-report', dayMetric: true, level: ['device','zone']}},
  ]
}


export const organization_mapping = [{"FTC": "FTC"}, {"FTC Solar": "FTC"}, 
  {"ftcsolar": "FTC"}, {"FTCSolar": "FTC"}, {"Depcom": "Depcom", "Depcom Power": "Depcom"},
  {"PGR": "PGR"}, {"PG Renewables": "PGR"}, {"BlueRidge": "PGR"}, {"Act Power": "ACT"}, {"ActPowerServices": "ACT"},
  {"Techtonic": "Techtonic"}, {"techtonic": "Techtonic"}, {"Harman": "Harman"}, {"Invenergy": "Invenergy"}, {"UL": "UL"}
]

export const timeRange = [
  { label: "00:00", value: "00:00" },
  { label: "01:00", value: "01:00" },
  { label: "02:00", value: "02:00" },
  { label: "03:00", value: "03:00" },
  { label: "04:00", value: "04:00" },
  { label: "05:00", value: "05:00" },
  { label: "06:00", value: "06:00" },
  { label: "07:00", value: "07:00" },
  { label: "08:00", value: "08:00" },
  { label: "09:00", value: "09:00" },
  { label: "10:00", value: "10:00" },
  { label: "11:00", value: "11:00" },
  { label: "12:00", value: "12:00" },
  { label: "13:00", value: "13:00" },
  { label: "14:00", value: "14:00" },
  { label: "15:00", value: "15:00" },
  { label: "16:00", value: "16:00" },
  { label: "17:00", value: "17:00" },
  { label: "18:00", value: "18:00" },
  { label: "19:00", value: "19:00" },
  { label: "20:00", value: "20:00" },
  { label: "21:00", value: "21:00" },
  { label: "22:00", value: "22:00" },
  { label: "23:00", value: "23:00" },
];
