import React from 'react';
import styled, { keyframes } from 'styled-components';

const marqueeTop = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
`;

const MarqueeWrapper = styled.div`
  overflow: hidden;
  margin: 0 auto !important;
`;

const MarqueeBlock = styled.div`
  width: 100%;
  height: 8vh;
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  float: left;
`;

const MarqueeInner = styled.div`
  position: relative;
  display: inline-block;
  animation: ${marqueeTop} 150s linear infinite;
  animation-timing-function: linear;
  &:hover {
    animation-play-state: paused;
  }
`;

const MarqueeItem = styled.div`
  transition: all 0.2s ease-out;
  padding-bottom: 14px;
  font-size: 12px; opacity: 0.8;
`;

export default class MarqueeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsToShow: this.generateMarqueeItems(),
    };
  }

  generateMarqueeItems = () => {
    const { items } = this.props;
    const visibleItemsCount = 6; // Adjust this value to control the number of visible items in the marquee
    const marqueeItems = [];

    // Keep adding items to the list until it is long enough to fill the scrolling area seamlessly
    while (marqueeItems.length < items.length * visibleItemsCount) {
      marqueeItems.push(...items);
    }

    return marqueeItems;
  };

  render() {
    const { itemsToShow } = this.state;
    return (
      <MarqueeWrapper>
        <MarqueeBlock>
          <MarqueeInner>
            {itemsToShow &&
              itemsToShow.length > 0 &&
              itemsToShow.map((item, index) => (
                <MarqueeItem key={index}>{item}</MarqueeItem>
              ))}
          </MarqueeInner>
        </MarqueeBlock>
      </MarqueeWrapper>
    );
  }
}