import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from "axios";
import './hail.css';
import Chart from './lineChart';
import { useLocation } from '../../navigation/use-location';
import { getCriteriaFromLocation } from '../../components/layout/criteria';
import { getAccessToken } from '../auth/userinfo';
import styled from 'styled-components'
import { AiOutlineDownload } from 'react-icons/ai';

// Styled component for displaying error messages
const Error = styled.span`
  padding: 0px;
  padding-top: 30px;
  color: red;
`;

const Analytics = () => {
        // State variables to manage chart data, error, and loading status
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null); 
    const [isLoading, setIsLoading] = useState(true);

        // Getting start_time and end_time details using useLocation hook
    const location = useLocation();
    const cs = getCriteriaFromLocation(location);
    const selectedSite = cs.site
    const startTime = cs.Ts
    const endTime = cs.Te
    console.log("HailAnalytics", selectedSite, startTime, endTime)

    useEffect(() => {
                // Function to fetch data from server when component mounts or selected site, start time, or end time changes
        const fetchData = async () => {
            try {
                if (!selectedSite) {
                    setError("Analytics page is only accessible at the Site level. Please choose a Site.");
                    return;
                }
                setError(null);
                // Convert start and end time to ISO format
                const startDate1 = new Date(startTime.slice(0, 4), parseInt(startTime.slice(4, 6)) - 1, startTime.slice(6, 8), startTime.slice(9, 11), startTime.slice(11, 13));
                const endDate1 = new Date(endTime.slice(0, 4), parseInt(endTime.slice(4, 6)) - 1, endTime.slice(6, 8), endTime.slice(9, 11), endTime.slice(11, 13));
                const startDate = startDate1.toISOString().slice(0, 19).replace('T', ' ');
                const endDate = endDate1.toISOString().slice(0, 19).replace('T', ' ');
                                
                // Fetch data for selected site and time range
                const data = await fetchDataForSite(selectedSite, startDate, endDate);
                console.log('Data for site and time range:', data);

                // Process fetched data
                setChartData(processData(data));
            } catch (error) {
                console.error('Error fetching data for site and time range:', error);
            }finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [selectedSite, startTime, endTime]);

        // Function to fetch data for selected site and time range
    const fetchDataForSite = async (site, startDate, endDate) => {
        try {
            const token = getAccessToken();
            const response = await axios.post(
                "/meta?querytype=meta_forecast_analytics&restype=json",
                {
                    site: site,
                    startDate: startDate,
                    endDate: endDate
                },
                {
                    headers: {
                        'x-access-token': token
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching data for site and time range:', error);
            throw error;
        }
    };

    // Function to process fetched data
    const processData = (data) => {
        if (!Array.isArray(data)) {
            setError("No Hail forecast data is available for this site to generate the analytics.");
            return null; 
        }
        // Extract relevant data from fetched data
        const timestamps = data.map(entry => entry.timestamp);
        const actions = data.map(entry => entry.action === 'stow' ? 5 : 0);
        const parsedData = data.map(entry => JSON.parse(entry.avg_data));
        const hailProbability = parsedData.map(avgData => avgData && avgData.hail_probability !== undefined ? avgData.hail_probability : 0);
        const maxHailSize = parsedData.map(avgData => avgData && avgData.max_hail_size !== undefined ? avgData.max_hail_size : 0);
        const direction = parsedData.map(avgData => avgData && avgData.direction !== undefined ? avgData.direction : 0);

        // Combine data into objects for sorting
        const combinedData = timestamps.map((timestamp, index) => ({
            timestamp,
            action: actions[index],
            hailProbability: hailProbability[index],
            maxHailSize: maxHailSize[index],
            direction: direction[index]
        }));

        // Sort combined data by timestamp in increasing order
        combinedData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

        // Separate sorted data
        const sortedTimestamps = combinedData.map(entry => entry.timestamp);
        const sortedActions = combinedData.map(entry => entry.action);
        const sortedHailProbability = combinedData.map(entry => entry.hailProbability);
        const sortedMaxHailSize = combinedData.map(entry => entry.maxHailSize);
        const sortedDirection = combinedData.map(entry => entry.direction);

        // Convert timestamps to formatted strings
        const moment = require('moment');
        const formattedTimestamps = sortedTimestamps.map(timestamp => {
            const date = new Date(timestamp);
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        });

        const actions1 = combinedData.map(entry => {
            if (entry.action === 'no-action') {
                return 'No stow';
            } else if (entry.action === 'release') {
                return 'Release';
            } else {
                return entry.action;
            }
        });
        // Modify direction values for better readability
        const modifiedDirection = sortedDirection.map(direction => {
            switch(direction) {
                case 'N':
                    return 'North';
                case 'S':
                    return 'South';
                case 'E':
                    return 'East';
                case 'W':
                    return 'West';
                default:
                    return direction; 
            }
        });

        return {
            actions: sortedActions,
            hailProbability: sortedHailProbability,
            maxHailSize: sortedMaxHailSize,
            formattedTimestamps: formattedTimestamps,
            actions1: actions1,
            modifiedDirection: modifiedDirection
        };
    };
    

        // Function to handle CSV download
    const handleDownload = () => {
        const { formattedTimestamps, actions1, hailProbability, maxHailSize, modifiedDirection } = chartData;
        const columns = ['Timestamps(YYYY-MM-DD HH:mm:ss)', 'Actions', 'Hail Probability(%)', 'Max Hail Size(Inches)', 'Direction'];
        const rows = formattedTimestamps.map((timestamp, index) => ({
            'Timestamps(YYYY-MM-DD HH:mm:ss)': timestamp,
            'Actions': actions1[index],
            'Hail Probability(%)': hailProbability[index],
            'Max Hail Size(Inches)': maxHailSize[index],
            'Direction': modifiedDirection[index]
        }));

        // Format file name for CSV
        const startDateFormatted = startTime.replace(/\D/g, '').slice(0, 8); 
        const endDateFormatted = endTime.replace(/\D/g, '').slice(0, 8);
        const fileName = `Hail_${selectedSite}_${startDateFormatted}-${endDateFormatted}.csv`;

        // Create CSV content
        const csvContent = [
            columns.join(','),
            ...rows.map(row => columns.map(column => row[column]).join(','))
        ].join('\n');

        // Create Blob for CSV content
        const csvBlob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8',
        });
        
        // Create download link and trigger download
        const csvLink = document.createElement('a');
        csvLink.href = URL.createObjectURL(csvBlob);
        csvLink.download = fileName;
        csvLink.click();
    };

    return (
        <>
            {error ? (
                <Error>{error}</Error>
            ) : (
                <div className="chart-card1">
                    <Row>
                        <Col md={12} className="text-right">
                            <button
                                onClick={handleDownload}
                                style={{
                                    background: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontSize: '25px',
                                    color: '#004059'
                                }}
                            >
                                <AiOutlineDownload />
                            </button>
                        </Col>
                    </Row>
                    {isLoading && (
                    <p style={{ textAlign: "center", color: "#4C4345", marginTop: "10px",}}>Loading...</p>
                    )}
                    <Row>
                        <Col md={12}>
                            {chartData && <Chart data={chartData} />}
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
}

export default Analytics;
