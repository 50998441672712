import React, { useState , useEffect} from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './hail.css';
import ReactTooltip from 'react-tooltip';
import SwitchControl from './SwitchControl';
import { getUser, getUserEmail } from "../auth/userinfo";
import SingleDropDownControl from '../useranalytics/singledropdown';

/**
 * Functional component representing the form for adding Hail Event settings.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.onCancel - Callback function to handle form cancellation.
 * @param {Function} props.onSubmit - Callback function to handle form submission.
 */

const AddHailForm = ({ onCancel, onSubmit}) => {
  const [editable, setEditable] = useState(true);
  const [hail_notification, setHailNotification] = useState(false);
  const [auto_stow_enabled, setAutoStowEnabled] = useState(false)
  const [hail_feature_enabled, setHailFeatureEnabled] = useState(false)


  const user = getUser();
  const userName = user && user.name;

  const [data, setData] = useState({
    siteId: '',
    Latitude: '',
    Longitude: '',
    HailSize: '',
    HailProbability: '',
    SiteRadius: '',
    hail_notification: false,
    auto_stow_enabled: false,
    hail_feature_enabled: false,
    updatedBy: userName
  });
  
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const HailSizeOptions = ['0 inches','0.5 inches', '1 inches', '1.5 inches','2 inches'];
  const HailProbabilityOptions = ['0%', '10%','20%','30%','40%', '50%', '60%', '70%', '80%', '90%', '100%'] ;
  const SiteRadiusOptions = ['5 miles','10 miles', ' 15 miles','20 miles','25 miles' ,'30 miles','35 miles' ,'40 miles'];
  const [dropdown1Value, setDropdown1Value] = useState('');
  const [dropdown2Value, setDropdown2Value] = useState('');
  const [dropdown3Value, setDropdown3Value] = useState('');

  // Effect hook to monitor changes in input fields and update unsavedChanges state
  useEffect(() => {
    const hasChanges =
      data.siteId.trim() !== '' &&
      data.Latitude.trim() !== '' &&
      data.Longitude.trim() !== '' 
    setUnsavedChanges(hasChanges);
  }, [data.siteId, data.Latitude, data.Longitude]);
  
  // Toggle Hail Notification switch
  const handleHailNotificationToggle = () => {
    if (editable) {
      setHailNotification(!hail_notification);
      setData((prevData) => ({
        ...prevData,
        hail_notification: !prevData.hail_notification,
      }));
    }
  };

  // Toggle Auto Hail Stow switch
  const handleHailEnabledToggle = () => {
    if (editable) {
      setAutoStowEnabled(!auto_stow_enabled);
      setData((prevData) => ({
        ...prevData,
        auto_stow_enabled: !prevData.auto_stow_enabled,
      }));
    }
  };

  const handleHailFeatureEnabledToggle = () => {
    if (editable) {
      setHailFeatureEnabled(!hail_feature_enabled);
      setData((prevData) => ({
        ...prevData,
        hail_feature_enabled: !prevData.hail_feature_enabled,
      }));
    }
  };

  // Handle input changes in form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };  

  // Handle form submission
  const handleAddFormSubmit = () => {
    const transformedData = {
      ...data,
      HailSize: transformDropdownValue(dropdown1Value),
      HailProbability: transformDropdownValue(dropdown2Value),
      SiteRadius: transformDropdownValue(dropdown3Value),
    };
    onSubmit(transformedData);
    setUnsavedChanges(false);
    onCancel();
  };
  
    // Transform dropdown value to numeric format
  const transformDropdownValue = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, '');
    return numericValue;
  };
  

  return (
    <div className="chart-card1">
      <div className="chart-card-header1">
        <h5 style={{ fontWeight: 600, fontSize: '18px', lineHeight: '15px', color: 'rgb(76, 67, 69)', padding: '10px 3px 10px 6px' }}>
          Hail Event Settings
        </h5>
      </div>
      <Row>
        <Col md={11}></Col>
        <Col md={1}>
          <span
            className="tooltip-icon"
            data-tip="<div><b>IMPORTANT:</b><br />In order to automatically stow FTC trackers based on these notifications, <br/>the Auto Hail Stow setting must be toggled ON. <br/>If the customer's preference is to not stow automatically based on these notifications, <br/>then the Auto Hail Stow setting should be toggled OFF.</div>"
            data-html={true}
            data-place="right"
          >
            <img
              src="../assets/img/icon/icon-info.svg"
              width="20"
              height="20"
              size={28}
              alt="Info"
            />
          </span>
          <ReactTooltip effect="solid" />
        </Col>
      </Row>
      <Row></Row>

      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">SiteID<span style={{ color: 'red', paddingLeft: '4px' }}>*</span></label>
        </Col>
        <Col md={5}>
          <Form.Control
            type="text"
            name="siteId"
            value={data.siteId}
            onChange={handleInputChange}
            disabled={!editable}
            style={{
              background: 'white',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
            }}
          />
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Latitude<span style={{ color: 'red', paddingLeft: '4px' }}>*</span></label>
        </Col>
        <Col md={5}>
          <Form.Control
            type="number"
            name="Latitude"
            value={data.Latitude}
            onChange={handleInputChange}
            disabled={!editable}
            style={{
              background: 'white',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              paddingRight: '18px',
            }}
          />
          <div className="unit"></div>
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Longitude<span style={{ color: 'red', paddingLeft: '4px' }}>*</span></label>
        </Col>
        <Col md={5}>
          <Form.Control
            type="number"
            name="Longitude"
            value={data.Longitude}
            onChange={handleInputChange}
            disabled={!editable}
            style={{
              background: 'white',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              paddingRight: '18px',
            }}
          />
          <div className="unit"></div>
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Hail Feature Enabled</label>
        </Col>
        <Col md={5}>
        <SwitchControl
            value={hail_feature_enabled}
            handleChange={handleHailFeatureEnabledToggle}
            disabled={!editable}
          />
        </Col>
      </Row>

      
      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Auto Stow Enabled</label>
        </Col>
        <Col md={5}>
        <SwitchControl
            value={auto_stow_enabled}
            handleChange={handleHailEnabledToggle}
            disabled={!editable}
          />
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Hail Notifications Enabled</label>
        </Col>
        <Col md={5}>
        <SwitchControl
            value={hail_notification}
            handleChange={handleHailNotificationToggle}
            disabled={!editable}
          />
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col md={12}>
          <label className="mb-3 chart-card-header"><b>Hail Event Thresholds:</b></label>
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Hail Size</label>
        </Col>
        <Col md={5}>
        <SingleDropDownControl
            options={HailSizeOptions.map((option) => ({ value: option, label: option }))}
            value={{ value: dropdown1Value, label: dropdown1Value }}
            handleChange={(selectedOption) => setDropdown1Value(selectedOption.value)}
          />
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Hail Probability</label>
        </Col>
        <Col md={5}>
        <SingleDropDownControl
            options={HailProbabilityOptions.map((option) => ({ value: option, label: option }))}
            value={{ value: dropdown2Value, label: dropdown2Value }}
            handleChange={(selectedOption) => setDropdown2Value(selectedOption.value)}
          />
        </Col>
      </Row>

      <Row className="d-flex align-items-center">
        <Col md={7}>
          <label className="card-title">Forecast Radius</label>
        </Col>
        <Col md={5}>
        <SingleDropDownControl
            options={SiteRadiusOptions.map((option) => ({ value: option, label: option }))}
            value={{ value: dropdown3Value, label: dropdown3Value }}
            handleChange={(selectedOption) => setDropdown3Value(selectedOption.value)}
          />
        </Col>
      </Row>
      <Row></Row>
      <Row></Row>
      <Row className="d-flex align-items-center">
        <Col md={7}></Col>
        <Col md={5}>
          <Button className="btn-secondary" onClick={handleAddFormSubmit} style={{ width: '47%' }} disabled={!unsavedChanges}>
            Submit
          </Button>
          <Button className="btn-secondary" style={{ width: '47%', marginLeft: '11px' }} onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AddHailForm;
