import React, { useMemo, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from "react-tooltip";
import useSWR from 'swr';
import { useDataviewActionContext } from '../../context/dataview.context';
import NotifyTooltip from '../../model/notify-tooltip'
import { useStore, useStoreActionContext } from '../../context/store.context';
import { InfoTooltip } from '../../model/info-tooltip';
import { DownloadCsv_Zone_Summary_table } from '../chart/csv.download';
import { Card4Opacity80 as Card, CardSummary } from '../layout/Card';
import { FetchData2 } from '../model/store.swr';
import { TooltipHelpContent } from '../pages/tooltip-help-content';
import TableContainer from './TableContainer';
import { ColumnAlarms, ColumnErrors, ColumnUptime, ColumnUptimeImpact, alarmData, findDevice, getDeviceName, getLastSeenTime, renderColumn, uptimeImpact,ColumnExpectedPower,ColumnActualPower,ColumnPowerVariance, ColumnTrackerComm } from './summary.row';
import { usePageContext, energyMeterPage, usePageActionContext } from '../../context/page.context';
import { createActivityLog, getStatsData } from '../../utils/apiCall';
import { getLogData, getWeightedAvg, handleStatsAsync } from '../../helpers/common';  
import { GetFQURL } from '../model/store.swr';
import _ from 'lodash'
import { useAvailabilityContext } from '../../context/availability.context';


function getRC(row) {
    return renderColumn(row, findDevice, getDeviceName)
}

function getUptime(row) {
    return renderColumn(row, findDevice, ColumnUptime)
}

function getTrackerComm(row) {
    return renderColumn(row, findDevice, ColumnTrackerComm)
}

function getErrors(row) {
    return renderColumn(row, findDevice, ColumnErrors)
}
function getAlarms(row) {
    return renderColumn(row, findDevice, ColumnAlarms)
}
function getUptimeImpact(row) {
    return renderColumn(row, findDevice, ColumnUptimeImpact)
}
function getExpectedPower(row) {
    return renderColumn(row, findDevice, ColumnExpectedPower)
}
function getActualPower(row) {
    return renderColumn(row, findDevice, ColumnActualPower)
}
function getVariance(row) {
    return renderColumn(row, findDevice, ColumnPowerVariance)
}
function parseData(alldata, props) {
    const siteId = props.site_id;
    const zoneId = props.zone_id;
    //console.log(alldata)

    const rcs = alldata.rcs //filterByZone(alldata.rcs, siteId, zoneId);
    const down = alldata.down //filterByZone(alldata.down, siteId, zoneId);
    const errors = alldata.errors //filterByZone(alldata.errors, siteId, zoneId);
    const uptime = alldata.uptime //filterByZone(alldata.uptime, siteId, zoneId);
    const tracker_communication = alldata.tracker_communication
    // const preverrors = filterByZone(alldata.preverrors, siteId, zoneId);
    // const prevuptime = filterByZone(alldata.prevuptime, siteId, zoneId);
    const rcerrors7days = alldata.errordetails //filterByZone(alldata.errordetails, siteId, zoneId);
    const rcalarms = alldata.rcalarms
    const parentdevice = alldata.device
    //const energy = alldata.energy_details
    console.log("parentdevice",parentdevice)

    var deviceCount = 0
    if(uptime.length){
        uptime.forEach(u => deviceCount += u.devicecount)
    }
    /*
    const rcs = filterByZone(alldata.rcs, siteId, zoneId);
    const down = filterByZone(alldata.down, siteId, zoneId);
    const errors = filterByZone(alldata.errors, siteId, zoneId);
    const uptime = filterByZone(alldata.uptime, siteId, zoneId);
    // const preverrors = filterByZone(alldata.preverrors, siteId, zoneId);
    // const prevuptime = filterByZone(alldata.prevuptime, siteId, zoneId);
    const rcerrors7days = filterByZone(alldata.errordetails, siteId, zoneId);
    const rcalarms = alldata.rcalarms

    const uptimeAll = uptime.map((item, i) => Object.assign({}, item,
        // { prevUptime: prevuptime.find(x => item.device_id === x.device_id) }
    ))
    // console.log("all uptime", toJS(uptimeAll))

    const errorsAll = errors.map((item, i) => Object.assign({}, item,
        // { prevErrors: preverrors.find(x => item.device_id === x.device_id) }
    ))
    // console.log("all errors", toJS(errorsAll))
    */
    const uptimeAll = uptime.map((item, i) => Object.assign({}, item,
        item.uptime !== null ? uptimeImpact(item, {devicecount: deviceCount}) : {uptimeImpact: 'No Data', uptime: 'No Data'}
    ))
    const data = uptimeAll.map((item, i) => Object.assign({}, item,
        down.find(x => item.device_id === x.device_id),
        { rcerrors: rcerrors7days.find(x => item.device_id === x.device_id) },
        errors.find(x => item.device_id === x.device_id) ? errors.find(x => item.device_id === x.device_id) : {errorCount: 0},
        uptimeAll.find(x => item.device_id === x.device_id) ? uptimeAll.find(x => item.device_id === x.device_id) : {uptime: 'No Data', uptimeImpact: 'No Data'},
        tracker_communication.find(x => item.device_id === x.device_id) ? tracker_communication.find(x => item.device_id === x.device_id) : {tracker_communication: 'No Data'},
        alarmData(rcalarms.filter(x => item.device_id === x.device_id && x.value === 1))
        //,energy.find(x => item.zone_id === x.id),
        //{ alarms: rcalarms.filter(x => item.device_id === x.device_id && x.value === 1).map(e => e.metric) }
    ))
    
    ReactTooltip.rebuild();

    return data;
}
/*
const urls = [
    { name: "rcs", url: "depcom-all_unique_rcs.json" },
    { name: "down", url: "depcom-latest_down_rcsby1days.json" },
    { name: "errors", url: "depcom-latest_errors_rcsby1days.json" },
    { name: "uptime", url: "depcom-latest_uptime_rcsby1days.json" },
    { name: "preverrors", url: "depcom-previous_errors_rcsby1days.json" },
    { name: "prevuptime", url: "depcom-previous_uptime_rcsby1days.json" },
    { name: "errordetails", url: "depcom-errors_detail_rcsby1days.json" },
]
*/

const urls = [
    {name: 'summaryData', url: "overview_summary"},
    // { name: "rcs", url: "all_unique_rcs" },
    // // { name: "down", url: "latest_down" },
    // { name: "down", url: "rc_down_summary" },
    // // { name: "errors", url: "latest_errors" },
    // { name: "errors", url: "error_summary_card" },
    // // { name: "uptime", url: "latest_uptime" },
    // { name: "uptime", url: "availability_summary" },
    // // { name: "preverrors", url: "previous_errors" },
    // // { name: "prevuptime", url: "previous_uptime" },
    // { name: "errordetails", url: "errors_detail", grouptype: 'zones' },
    // { name: "rcalarms", url: "latest_rc_alarms" },
    // { name: "device", url: "device_summary", grouptype: "zones" },
    // { name: "availability_avg", url: "rc_availability_avg"},
    { name: "energy_details", url: "expected_actual",  path:"/energy", aggr: "avg"}

]


const columns_list = [
    {
        Header: "RC Name",
        accessor: "zoneId",
        Cell: (row) => {
            return getRC(row)
        }
    },
    {
        Header: "AVAILABILITY",
        accessor: "uptime",
        Cell: (row) => {
            return getUptime(row)
        }
    },
    {
        Header: () => <span>AVAILABILITY <br/>Impact</span>,
        accessor: "uptimeImpact",
        Cell: (row) => {
            return getUptimeImpact(row)
        }
    },
    {
        Header: "Errors",
        accessor: "errorCount",
        Cell: (row) => {
            return getErrors(row)
        }
    },
    {
        Header: "Tracker Comms",
        accessor: "tracker_communication",
        Cell: (row) => {
            return getTrackerComm(row)
        }
    },
    {
        Header: () => <span>Last Sent</span>,
        accessor: "lastUpdated",
        Cell: (row) => {
            /* Add data-tip */
            //const rv = isNaN(parseInt(row.value)) ? "" : parseInt(row.value)
            //return <span data-tip={rv}>{rv}</span>;
            return getLastSeenTime(row)
        }
    },

]
const energymeter_columns_list = [
    {
        Header: "RC Name",
        accessor: "zoneId",
        Cell: (row) => {
            return getRC(row)
        }
    },
    {
        Header: () => <span>Energy<br/>Estimated(KW)</span>,
        accessor: "expected_power",
        Cell: (row) => {
            return getExpectedPower(row)
        }
    },
    {   
        Header: () => <span>Energy<br/>Actual(KW)</span>,
        accessor: "actual_power",
        Cell: (row) => {
            return getActualPower(row)
        }
    },
    {
        Header: "Variance (KW)",
        accessor: "errorCount",
        Cell: (row) => {
            return getVariance(row)
        }
    },
    {
        Header: () => <span>Last Sent</span>,
        accessor: "lastUpdated",
        Cell: (row) => {
            return getLastSeenTime(row)
        }
    },
];

function onDeviceFilterHandler(e) {
    //setFilter(e.target.value)
    // this.setState({
    //     filter: e.target.value,
    //   })

    //console.log(filter)
}

const ZoneSummaryTable = (props) => {

    const [response, setResponse] = useState({})
    const [error, setError] = useState({})    
    const pageContext = usePageContext();
    const pageName = pageContext.page;
    const criteria = useStore().criteria
    const dataviewaction = useDataviewActionContext()
    const pageActionContext = usePageActionContext()
    const storeAction = useStoreActionContext()
    const availabilityContext = useAvailabilityContext()

    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Escape') {
            event.preventDefault();
            var popup = document.getElementById("myPopup");
            popup.classList.remove("show");
          }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []);

      useEffect(() => {
        if(criteria.zone){
            createActivityLog(getLogData(criteria, {featureId: `view-zone-summary`, usecase: `view zone summary`, category: 'overview', level: 'zone'}))
        }
      },[criteria.zone, criteria.Te, criteria.Ts])


      const columns = useMemo(() => columns_list, []);

    useEffect(() => {
        setResponse({})
        setError({})
        const url = GetFQURL(urls[0], criteria, 'json', false,[],null,null,null, {})
        handleStatsAsync(url,setResponse,setError,availabilityContext)
        // getStatsData(url,setResponse,setError)
    },[criteria.zone, criteria.Ts, criteria.Te])

    // const response = FetchData2(useSWR, criteria, urls)

    if (_.isEmpty(response)) return <CardSummary>Loading ...</CardSummary>
    const res_availability = Array.isArray(response) ? (response[0].availability_avg || null) : (response.data.availability_avg || null)
    const availability_avg = res_availability[0].availability
    const res_tracker_communication = Array.isArray(response) ? (response[0].tracker_communication_avg || null) : (response.data.tracker_communication_avg || null)
    const tracker_communication_avg = res_tracker_communication[0].tracker_communication
    console.log("response for zone",response);
    const data = parseData(Array.isArray(response) ? response[0] : response.data, props)
    // console.log("Zone.summary parsed data", data)

    function handleRowClick(site, history) {
        // goToPage('Devices', site, criteria, history)
        storeAction.setDevice(site.device_id)
        dataviewaction.clearView()
    }
    const totalchildren = data.length
    const uptimedata = data.filter(a => a.uptime).map(a => { return { uptime: a.uptime, devicecount: a.devicecount } });
    const uptimecount = uptimedata.length
    // const uptimeavg = uptimecount>0 ? (uptimedata.reduce((a, b) => (a + b)) / uptimedata.length).toFixed(2) : '';
    // const uptimeavg = (uptimedata.reduce((acc, e) => acc + (e.uptime * e.devicecount), 0) / uptimedata.reduce((acc, e) => acc + e.devicecount, 0)).toFixed(2)
    const uptimeavg = (uptimedata.reduce((acc, e) => acc + e.uptime , 0) / uptimedata.reduce((acc, e)=> acc + e.devicecount, 0)).toFixed(2)

    const errordata = data.map(d => d.errorCount || 0)
    const errorcount = errordata.reduce((a, b) => a + b, 0)

    function handleClick(e) {
        e.preventDefault();
        var popup = document.getElementById("myPopup");
        popup.classList.toggle("show");
        if(popup.classList.contains("show")){
            createActivityLog(getLogData(criteria, {featureId: `view-zone-summary-details`, usecase: `view zone summary detailed data`, category: 'overview', level: 'zone'}))
        }
    }

    const tooltipContent = TooltipHelpContent.ZoneSummary
    const tooltip = (
        <div>
            <h5>{tooltipContent.header}</h5>
            {tooltipContent.body}
        </div>
    )

    return (
        <CardSummary>
            {/* <a href="#"> */}
            <div className="popup">
                <div className="card-body" onClick={handleClick}>
                    <h5 className="card-title">Zone Summary <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} /></h5>
                    <div className="text-white h6 card-text"><span>Total RCs : {totalchildren}</span></div>
                    {/* <div className="text-white h6 card-text"><span>Availability Avg. : {uptimeavg} %</span></div> */}
                    <div className="text-white h6 card-text"><span>Availability Avg. : {availability_avg ? `${availability_avg}%` : 'No Data'}</span>{availabilityContext.withMaintenance ? 
                        <NotifyTooltip  content =   "Adjusted for maintenance activities"    onClick={() => pageActionContext.setPage("Site Maintenance", [], false)}/>
                        : null}
                    </div>
                    <div className="text-white h6 card-text"><span>Tracker Comms : {tracker_communication_avg ? `${tracker_communication_avg}%` : 'No Data'}</span></div>
                    {/* <div className="text-white h6 card-text"><span>Error total : {errorcount}</span></div> */}
                </div>

                <span className="popuptext" id="myPopup">
                    {/* A Simple Popup! */}
                    <div className="col-sm-12 no-gutters ">

                        <Card>
                            <div className="mb-3 popup-header">
                                {/* <b className="mb-3">{pageName === energyMeterPage?"Summary-Energy":"Summary"}</b> */}
                                <b className="mb-3">{pageName === energyMeterPage?"Summary-Energy":"Zone Summary"}</b>
                                <span className="ml-12 float-right bg-transparent d-flex">
                                    <DownloadCsv_Zone_Summary_table data={data} filename={"Zonesummary" + criteria.timetype} />
                                </span>
                                <span className="ml-2">
                                <img src="../assets/img/icon/icon-info.svg" width="16" height="16"  data-toggle="popover" data-trigger="hover" data-title="Availability %" data-placement="top" data-content="Portfolio Summary"/>
                                    {/* <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Uptime %" data-placement="top" data-content="Portfolio Summary"></i> */}
                                </span>
                            </div>
                            <div >

                                {/* <input value={filter} type="text" onChange={onDeviceFilterHandler.bind(this)}/> */}
                            </div>
                            <div className="d-flex  align-items-center mb-1">

                                <TableContainer
                                    columns={columns}
                                    data={data}
                                    trHandler={handleRowClick}
                                // filter={filter}
                                // renderRowSubComponent={renderRowSubComponent}
                                />
                            </div>

                        </Card>
                    </div>
                </span>
            </div>
            <div className="stats-desc text-right py-2 px-2 " onClick={handleClick} style={{ "position": "absolute", "bottom": "0", "right": "0", "cursor": "pointer" }}>
                <span >View Devices <span className="fas fa-angle-double-down	"></span></span></div>
        </CardSummary>
    );

}


//export default withRouter(PortfolioSummaryTable);
export default ZoneSummaryTable;
