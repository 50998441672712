import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Button } from 'reactstrap';
import styled from "styled-components";
import './controls.css'



const Main = styled("div")`
//   font-family: sans-serif;
//   background: #f0f0f0;
//   height: 100vh;
    display: inline-block;
`;

const DropDownContainer = styled("div")`
  width: 10.5em;
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
//   color: #3faffa;
//   background: #ffffff;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
//   background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
//   color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
`;

const modifiers={
    setMaxHeight: {
      enabled: true,
      order: 890,
      fn: (data) => {
        return {
          ...data,
          styles: {
            ...data.styles,
            overflow: 'auto',
            maxHeight: '50vh',
          },
        };
      },
    },
  }

// https://6-4-0--reactstrap.netlify.app/components/dropdowns/
const DropDownControl = (props) => {
    const [options] = useState(props.options);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.selected);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
      setSelectedOption(value);
      setIsOpen(false);
      console.log("value",value)
      props.onSelect(value)
    };
    const CrumbLabel = ({title}) => {return (<button className="btn btn-link  text-white p-0 m-0" ><h4>{title}</h4></button>) }

    // console.log(props.options)
    return (
        <Main>
        {/* <DropDownHeader onClick={toggling}>
          {selectedOption || props.selected}
        </DropDownHeader> */}
        {/* <CrumbLabel title={selectedOption} onClick={toggling}/>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map(option => (
                <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                  {option}
                </ListItem>
              ))}
            </DropDownList>
           </DropDownListContainer>
        )} */}
        <UncontrolledDropdown isOpen={isOpen} toggle={toggling} disabled={props.disabled? true: false}>
          <Button onClick={onOptionClicked(selectedOption)}className="dropdown-btn">
            { selectedOption === "" ?  props.label : selectedOption}
          </Button>
          <DropdownToggle
            caret
            className="dropdown-toggle-btn"
          />
          {/* <DropdownToggle caret className="dropdown-btn">
          { selectedOption === "" ?  props.label : selectedOption}
          </DropdownToggle> */}
          <DropdownMenu className="d-menu" modifiers={modifiers} >
            {/* <DropdownItem>Another Action</DropdownItem> */}

            {props.options.map(option => (
              <DropdownItem onClick={onOptionClicked(option)} key={Math.random()}>{option}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>

      </Main>

    )
}

export default DropDownControl;