import React, { useEffect, useMemo, useState, useRef, memo } from "react";
import { Button,  Alert } from 'react-bootstrap'
import styled from "styled-components";
import axios from "axios"
import ButtonControl from "../controls/button_control";
import { SingleSelectDropDownControl } from "../controls/multiselect_control";
import RadioControl from "../controls/radio_control";
import { GetFQURL } from "../model/store.swr";
import { useCriteriaContext } from "../../context/criteria.context";
import { useUserContext } from "../../context/user.context";
import { getMetricsAsOptions } from "../../model/metadata";
import { getLabel } from './heatmap.js'
import { useModelContext } from "../../context/model.context"
import { useRealtimeContext } from '../../context/realtime.context'
import moment from 'moment'
import {createActivityLog} from '../../utils/apiCall'
import { getLogData, getLevel } from "../../helpers/common"
import { toJS } from 'mobx';
import {detailsPage, usePageContext, usePageActionContext} from "../../context/page.context"
import { useStore } from "../../context/store.context"
import { isDevice, isPortfolio, isSite, isZone,getIntervalByCriteria } from "../layout/criteria";
import { getAccessToken, getUserEmail } from '../auth/userinfo'
import {compressAndEncode, decodeAndDecompress} from '../model/utils'

const groupDesc = { 'AR': 'RC Alerts', 'AZ': 'ZC Alerts', 'VR': 'RC Data', 'VZ': 'ZC Data', 'SR': 'RC Status', 'SZ': 'ZC Status' }
const customMetricGroup = "CM"
const realtimeSnapshotGroup = "RS"

function getGroupType(cr) {
    //return cr.site === ""? 'pf': cr.zone === ''? 'sites': cr.device === ''? 'zones': 'rcs'
    return cr.site === "" ? 'pf' : cr.zone === '' ? 'zones' : cr.device === '' ? 'zones' : 'rcs'

}
/*
function filterMetricList(v, f) {
    return v.filter(e => f.includes(e[1].charAt(1)))
}

function filterMetricByType(v, mtype) {
    console.log(mtype)
    return v.filter(e => mtype.includes(e[1].charAt(1)))//.sort((a,b) => a[0] > b[0])
}


function getMetricAsOptions(mlist){

    const options = Object.keys(groupDesc).map(gid => {
        const obj = {}

        obj['label'] = groupDesc[gid]
        obj['options'] = mlist.filter(f => f[1] === gid).sort((a, b) => a[0].toUpperCase() > b[0].toUpperCase()).map(e => {
            return { 'label': e[0], 'value': e[0] }
        })
        return obj
    })
    return options
}

function parseMetrics(data, args, criteria) {
    const gt = getGroupType(criteria);
    // const mtype = (criteria.zone.length > 0) ? ['R'] : (criteria.site === "") ? ['Z'] : (criteria.zone.length > 0) ? ['R'] : data.metrics.values;
    const mlist = (criteria.device.length > 0) ? filterMetricByType(data.metrics.values, ['R']) : data.metrics.values //filterMetricByType(data.metrics.values, ['Z'])
    // const heads = [...new Set(mlist.map(item => getHead(item)))];
    const options = getMetricAsOptions(mlist)

    // console.log(mlist, options)
    return { 'options': options, 'criteria': criteria }

}
*/
const mergeOptions = [
    { label: "Aggregated", value: "yes" },
    { label: "Instantaneous", value: "no" },

]
const intervalOptions = [
    { label: "1 min", value: 1 },
    { label: "5 mins", value: 5 },
    { label: "10 mins", value: 10 },
    { label: "30 mins", value: 30 },
    { label: "1 hour", value: 60 },
    { label: "12 hours", value: 12 * 60 },
    { label: "1 day", value: 24 * 60 },
]

const valueOptions = [
    { label: "Last Value", value: "last" },
    { label: "First Value", value: "first" },
    { label: "Average", value: "mean" },
    { label: "Minimum", value: "min" },
    { label: "Maximum", value: "max" },
]



function downloadMetricsData(options, filename, notify) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(options),
    }
    // notify(true)
    fetch(options.url, requestOptions)
        .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename + ".xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // notify(false)
        })
        .catch((err) => {
            return Promise.reject({ Error: 'Something Went Wrong', err });
            // notify(false)
        })
}

const getSiteLocalTime = timestamp => {
    const utcTime = moment.utc(timestamp)
    const siteTime = moment(utcTime.format())
    return siteTime.format("YYYY-MM-DD HH:mm:ss")
}

function downloadMetrics(metricName, metricData, merged, criteria, selectedDevices, metricType, encodings) {
    let csv = ""
    metricData.forEach(timeStampData => {
        const  metrics = Object.keys(timeStampData.metrics).reduce((acc, curr) => {
            if (selectedDevices.indexOf(curr) >= 0) {
                acc[curr] = timeStampData.metrics[ curr ]
            }

            return acc
        }, {})
        if (csv === "") {
            if(merged !== 'yes'){
                csv += "Timestamp,"
            }
            csv += Object.keys(metrics).join(",")
            csv += "\n"
        }

        if(merged !== 'yes'){
            csv += getSiteLocalTime(timeStampData.timestamp) + ","
        }
        
        const values = Object.values(metrics).map(value => {
            return getLabel(metricName, value, metricType, encodings)
        })
        csv += values.join(",")
        csv += "\n"
    })
    
    downloadFile("metrics.csv", csv)
    const level = getLevel(criteria)
    createActivityLog(getLogData(criteria, {featureId: `download-${level}-metrics`,  usecase: `download ${level} metrics data`, category: 'model-viewer', level: level, metrics: metricName, details: JSON.stringify({merge: merged})}))
}

function downloadFile(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
}

async function fetchMetricsData(options, notify, onMetricChange) {
    let token = getAccessToken();

    const requestOptions = {
        // mode: 'no-cors',
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': token,
            'Accept-Encoding': 'gzip' // Request gzip-encoded response
        },
    }
    notify(true)
    // const rawResponse = await fetch(options.url+`&merged=${options.merge}`, requestOptions);
    // const content = await rawResponse.json();
    let resData;
    try {
        const [base, queryParam] = options.url.split('?');
        const decodedURL = decodeAndDecompress(queryParam);
        const appendedData = decodedURL+`&merged=${options.merge}`;
        const reEncodedData = compressAndEncode(appendedData);
        const newURL = `${base}?${reEncodedData}`;
        const rawResponse = await fetch(newURL, requestOptions);        
        if (rawResponse.ok) {
            const contentEncoding = rawResponse.headers.get('content-encoding');
            if (contentEncoding === 'gzip') {
                const response = await rawResponse.json();
                //const inflatedData = pako.inflate(response, { to: 'string' });
                resData = JSON.parse(response);
            } else {
                resData = await rawResponse.json();
            }
        } else {
            console.error('Request failed with status:', rawResponse.status);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    
    const data = resData.map(curr => {
        return {
            timestamp: curr.timestamp, 
            metrics: Object.keys(curr).reduce((acc, key) => {
                if (key !== "timestamp") {
                    const newKey = options.group_id.indexOf("Z") >= 0 ? key.split("/")[0] : key.split("/")[1]//.split('\',')[0]
                    acc[newKey] = curr[key]
                }
                return acc
            }, {})
        }
    })

    onMetricChange(options.metrics[0]+":"+options.group_id, data)
    
    notify(false)
}

async function fetchMetricsData2(options) {
    let token = getAccessToken();

    const requestOptions = {
        // mode: 'no-cors',
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': token,
            'Accept-Encoding': 'gzip' // Request gzip-encoded response
        },
    }
    let resData;
    try {
        const [base, queryParam] = options.url.split('?');
        const decodedURL = decodeAndDecompress(queryParam);
        const appendedData = decodedURL+`&merged=${options.merge}`;
        const reEncodedData = compressAndEncode(appendedData);
        const newURL = `${base}?${reEncodedData}`;
        const rawResponse = await fetch(newURL, requestOptions);          
        if (rawResponse.ok) {
            const contentEncoding = rawResponse.headers.get('content-encoding');
            if (contentEncoding === 'gzip') {
                const response = await rawResponse.json();
                //const inflatedData = pako.inflate(response, { to: 'string' });
                resData = JSON.parse(response);
            } else {
                resData = await rawResponse.json();
            }
        } else {
            console.error('Request failed with status:', rawResponse.status);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    //const content = await JSON.parse(rawResponse.json());
    const data = resData.map(curr => {
        return {
            timestamp: curr.timestamp, 
            metrics: Object.keys(curr).reduce((acc, key) => {
                if (key !== "timestamp") {
                    const newKey = options.group_id.indexOf("Z") >= 0 ? key.split("/")[0] : key.split("/")[1]//.split('\',')[0]
                    acc[newKey] = curr[key]
                }
                return acc
            }, {})
        }
    })

    return { 
        id: options.metrics[0]+":"+options.group_id, 
        data: data
    }
}


const Styles = styled.div`
    z-index: 1;
    position: absolute;
    width: 315px;
    margin: 14px; 
    background-color: #F7F8F9;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 0px;

    .clickparent {
        padding: 6px 4px;
        width: 100%;
        margin: 0px;
        display: inline-block;
    }
    .clickparent:hover {
        // background-color: blue;

    }
    
    .realtime-snapshot {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        button.save {
            background: #014059;

            :active {
                background: #014059;
            }
        }

        button.cancel {
            background: black;

            :active {
                background: black;
            }
        }

        input {
            width: 100%;
            padding: 8px;
            font-size: 16px;
            border: 1px solid #cecece;
            border-radius: 4px;
        }

        .edit-note {
            color: #014059;
            text-decoration: underline;
            cursor:  pointer;
        }

        .note-input {
            border: 1px solid #cecece;
            font-size: 12px;
        }

        .alert {
            background-color: #ffa2b0;
            border-color: #ffa2b0;
            font-size: 14px;
            color: black;
        }
    }
`
const MetricsDownloaderViewerBase = (props) => {
    const modelContext = useModelContext()
    const criteria = useCriteriaContext()
    const pageAction = usePageActionContext()
    const userContext = useUserContext()
    const pageContext = usePageContext()
    const realtimeContext = useRealtimeContext()
    const snapshotNameRef = useRef()

    const notesRef = useRef()

    const { realtimeMetric, setRealtimeMetric, saveRealtimeSnapshot } = realtimeContext 
    const { importingMetric, importedMetrics, realtimeMetrics, setRealtimeMetrics } = modelContext

    const metricsData = useMemo(() => { 
        const userEmail = getUserEmail()
        return  getMetricsAsOptions(
            userContext.metrics
                .concat(importedMetrics.filter(t => t.site === criteria.site && (t.shared || (t.user === userEmail))))
                .concat(realtimeMetrics.filter(t => t.site === criteria.site && (t.shared || (t.user === userEmail))))
            , handleGroupSelection
        ) 
    }, [userContext.metrics, importedMetrics, realtimeMetrics]) //useState([]);
    
    // const pr = metricsData.filter(t => t.value === "PR")
    // const trackerAvailability = pr.length > 0 ? pr[0].options.filter(t => t.value.metric === "trackerAvailability") : []
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedData, setSelectedData] = useState(null)
    const [skipLoading, setSkipLoading] = useState(false)
    const [realtimeSnapshot, setRealtimeSnapshot] = useState(false)
    const [snapshotName, setSnapshotName] = useState("")

    const [savingSnapshot, setSavingSnapshot] = useState(false)

    const [savedSnapshot, setSavedSnapshot] = useState(false)

    const [note, setNote] = useState("")
    const [editNote, setEditNote] = useState(false)

    const [snapshotError, setSnapshotError] = useState("")

    // const [interval, setInterval] = useState(criteria.interval)
    const [valueType, setValueType] = useState("mean")
    const [merged, setMerged] = useState(mergeOptions[0].value)
    const [loading, setLoading] = useState(false);
    const [selectedEncodings, setSelectedEncodings] = useState([])
    const [selectedMetricData, setSelectedMetricData] = useState([])
    const sortAString = (a, b) => a.group_id > b.group_id ? 1 : a.group_id < b.group_id ? -1 : 0

    function handleGroupSelection(grpValue){
        return  
    }

    const saveSnapshot = () => {
        if (snapshotName.trim() === "") {
            setSnapshotError("Please enter snapshot name")
            snapshotNameRef.current.focus()
            return
        }
        if (realtimeMetrics.filter(t => t.metric.trim() === snapshotName.trim() && t.site === criteria.site).length > 0) {
            setSnapshotError("Snapshot name already exists")
            snapshotNameRef.current.focus()
            return            
        }
        setSavingSnapshot(true)
        const {
            metricName,
            metricData,
            modeData,
            angleData,
            errorData,
            minMaxValue,
            metricType,
            encodings
        } = realtimeMetric
        const metricParts = metricName.split(":")
        const userEmail = getUserEmail()
        const metricToSave = {
            disabled: false,
            group_id:  metricParts[1],
            metric: snapshotName,
            tenant: "ftc",
            metricData: metricData, 
            modeData,
            angleData,
            errorData,
            minMaxValue, 
            metricType, 
            encodings,
            site: criteria.site,
            shared: false,
            user: userEmail,
            note
        }
        
        saveRealtimeSnapshot(metricToSave, () => {
            setRealtimeMetric(null)
            setSavingSnapshot(false)
            setSavedSnapshot(true)
            
            setRealtimeMetrics([...realtimeMetrics, metricToSave])

            props.updateMetricName(snapshotName + ":RC")
        }, (err) => {
            setSavingSnapshot(false)
            setSnapshotError(err || "Error saving snapshot. Please try again later.")
        })
    }

    const back = (live) => {
        setSavedSnapshot(false)
        setRealtimeMetric(null)
        if (live)
            pageAction.setPage(detailsPage, [], false)
        else 
            setRealtimeSnapshot(false)
    }

    const urlGet = (query) => {
        let token = getAccessToken()
        return axios({
            url: `custom_metrics?querytype=${query}&restype=json&project=depcom`,
            method: 'GET',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        })
    }

    const convertDbtoMetricData = (data, metric) => {
        const metrics = data.reduce((acc, curr) => {
            acc[curr.device_id] = curr[metric]
            return acc
        }, {})
        return [{timestamp: 0, metrics}]
    }

    useEffect(() => {
        if (realtimeMetric === null) {
            const metricsData = getMetricsAsOptions(userContext.metrics, handleGroupSelection) 
            const pr = metricsData.filter(t => t.value === "PR")
            const trackerAvailability = pr.length > 0 ? pr[0].options.filter(t => t.value.metric === "trackerAvailability") : []
            setSelectedOption(trackerAvailability.length > 0 ? trackerAvailability[0] : null)
        } else {
            setRealtimeSnapshot(true)
            setSnapshotName(realtimeMetric.metricName.split(":")[0])

            let note = ""
            if (realtimeMetric.query) {
                note = note + "Searched by '" + realtimeMetric.query + "'" + "\n"
                note = note + "---\n"
            }

            if (realtimeMetric.filters.length > 0) {
                note = note + "Filters: \n\n" + realtimeMetric.filters.reduce((acc,  curr) => {
                    acc = acc + curr.text + ": " + (curr.type === 'encoded' ? curr.filters.join(", ") : `between ${curr.filters[0]} and ${curr.filters[1]}`)
                    acc = acc + "\n\n"
                    return acc
                }, "")  
            }

            note = note + "Taken at " + moment().format("DD/MM/YYYY hh:mm") + "\n"
            setNote(note)
        }
    }, [userContext.metrics])

    useEffect(() => {
        if (importingMetric) {    
            const gp = metricsData.filter(t => t.value === customMetricGroup)
            const importedMetric = gp.length > 0 ? gp[0].options.filter(t => (t.value.metric + ":" + t.value.group_id) === importingMetric.metricName) : []
            const selectedMetric = importedMetric.length > 0 ? importedMetric[0] : null
            if (selectedMetric) {
                setSelectedOption(selectedMetric)
                setSelectedEncodings(importingMetric.encodings)
                setSelectedMetricData(importingMetric.metricData)
                setSkipLoading(true)
            }
        }
      }, [importingMetric])

    useEffect(()=> {
 
        if (selectedOption){
            if (skipLoading) {
                setSkipLoading(false)
                return
            }
            if (selectedOption.value.metric === 'errorCode' || 
                selectedOption.value.metric === 'currentMode' ||
                selectedOption.value.metric === 'currentAngle'    
            ) {
                props.onDefaultChange(selectedOption.value.metric+":"+selectedOption.value.group_id)
            } else if (selectedOption.value.group_id === customMetricGroup || selectedOption.value.group_id === realtimeSnapshotGroup) {
                const importedMetric = selectedOption.value.group_id === customMetricGroup ? 
                    importedMetrics.filter(t => t.group_id === customMetricGroup && t.metric === selectedOption.value.metric && t.site === criteria.site) :
                    realtimeMetrics.filter(t => t.group_id === realtimeSnapshotGroup && t.metric === selectedOption.value.metric && t.site === criteria.site)
                if (importedMetric.length > 0) {
                    const metricName = importedMetric[0].metric + ":" + importedMetric[0].group_id
                    const { metricData, minMaxValue, metricType, encodings } = importedMetric[0]
                    if (metricData) {
                        props.onMetricChange(metricName, metricData, minMaxValue, metricType,  encodings)
                        setSelectedEncodings(encodings)
                        setSelectedMetricData(metricData)

                        if (selectedOption.value.group_id === realtimeSnapshotGroup) {
                            const { modeData, angleData, errorData } = importedMetric[0]

                            props.updateModeData(null, modeData)
                            props.updateAngleData(null, angleData)
                            props.updateErrorData(null, errorData)
                        }
                    } else {
                        let requests = []
                        const site = criteria.site
                        const metric = importedMetric[0].metric
                        const r1 = urlGet(`readings&site=${site}&metric=${metric}&group_id=${selectedOption.value.group_id}`)
                        requests.push(r1)

                        if (selectedOption.value.group_id === customMetricGroup) {
                            const r2 = urlGet(`labels&site=${site}&metric=${metric}`)
                            requests.push(r2)
                        }
                        
                        setLoading(true)
                        Promise.all(requests)
                            .then(responses => {
                                const metrics = responses.length > 0 ? responses[0].data.reduce((acc, curr) => {
                                    acc[curr.device_id] = curr.value
                                    return acc
                                }, {}) : {}
                                const metricData = [{timestamp: 0, metrics}]
                                const encodings = responses.length > 1 ? responses[1].data.map(t => ({label: t.label, value: t.value.toString(),  color: t.color})) : []
                                props.onMetricChange(metricName, metricData, minMaxValue, metricType,  encodings)
                                setLoading(false)
                                setSelectedEncodings(encodings)
                                setSelectedMetricData(metricData)

                                if (selectedOption.value.group_id === realtimeSnapshotGroup) {
                                    const modeData = responses.length > 0 ? convertDbtoMetricData(responses[0].data, 'mode') : {}
                                    const angleData = responses.length > 0 ? convertDbtoMetricData(responses[0].data, 'angle') : {}
                                    const errorData = responses.length > 0 ? convertDbtoMetricData(responses[0].data, 'error') : {}

                                    props.updateModeData(null, modeData)
                                    props.updateAngleData(null, angleData)
                                    props.updateErrorData(null, errorData)
                                }
                            })
                            .catch(err => {
                                setLoading(false)
                                setSelectedEncodings([])
                                setSelectedMetricData([])
                            })
                    }

                }
            } else {
                handleViewData()
            }
        }else{
            if (realtimeMetric === null)
                props.clearMetric()
        } 
            
    
        
    }, [selectedOption])

    

    useEffect(() => {
        if (modelContext.selectedMetric) {
            if (loading) {
                modelContext.setSelectedMetric(selectedOption.value.metric)
                return
            }
            let option = null
            
            metricsData.forEach(group => {
                group.options.forEach(o => {
                    if (o.value.metric === modelContext.selectedMetric)
                        option = o
                })
            })
            if (option)
                setSelectedOption(option)
        }
    }, [modelContext.selectedMetric])

    useEffect(() => {

        if (realtimeMetric === null) {
            props.updateModeData([])
            props.updateAngleData([])
            props.updateErrorData([])
            if (selectedOption && selectedOption.value.group_id !== customMetricGroup)
                handleViewData()

            handleErrorData((modeData, errorData) => {
                props.updateModeData(modeData.id, modeData.data)
                props.updateErrorData(errorData.id, errorData.data)
                if (selectedOption && selectedOption.value.metric === 'errorCode') 
                    props.onMetricChange(errorData.id, errorData.data)
            })
            fetchMetricsData(getUrlOptions(false, true, "currentAngle"), () => {}, props.updateAngleData)
            
            // handleBasicData()
        } else {
            props.updateModeData(null, realtimeMetric.modeData)
            props.updateAngleData(null, realtimeMetric.angleData)
            props.updateErrorData(null, realtimeMetric.errorData)
        }
    }, [criteria.Ts, criteria.Te, merged])

    useEffect(() => {
        if (realtimeSnapshot && snapshotNameRef.current) {
            snapshotNameRef.current.focus()
        }
    }, [realtimeSnapshot])

    useEffect(() => {
        let timeout = null
        if (snapshotError) {
            timeout = setTimeout(() => {
                setSnapshotError("")
            }, 3000)
        }

        return () => clearTimeout(timeout)

    }, [snapshotError])
    

    useEffect(() => {
        if (editNote && notesRef.current) {
            notesRef.current.focus()
            notesRef.current.selectionStart = notesRef.current.selectionEnd = 10000
        }
    }, [editNote])

    useEffect(() => {
        props.setRadioMerged(merged)
    },[merged])

    function getUrlOptions(download = true, merge = true, metric = null) {
        const groupType = 'zones'//getGroupType(criteria);
        //for model viewer keep interval 3hrs/180 min form 1-3 week
        var modalInterval = getIntervalByCriteria(criteria) === 60 ? 180 : null;
        const url = metric ? 
            GetFQURL({ name: 'downloadmetrics', path: download ? "/download" : "/query", url: `readings1byid-m_${metric}`, grouptype: groupType,  nocache: true, metricGroup: "SR" }, criteria, "json", false, ['zone', 'device'],modalInterval) :
            GetFQURL({ name: 'downloadmetrics', path: download ? "/download" : "/query", url: `readings1byid-m_${selectedOption.value.metric}`, grouptype: groupType,  nocache: true, metricGroup: selectedOption.value.group_id }, criteria, "json", false, ['zone', 'device'],modalInterval)
        // console.log("Checking Interval", interval, toJS(criteria), url)
        return { 'url': url, 'metrics': [metric || selectedOption.value.metric], 'aggr': valueType, 'download': download ? 'yes' : 'no', 'merge': merged, group_id: metric ? "SR" : selectedOption.value.group_id }
    }

    function getFileName(){
        const domain = `${criteria.site === ""? "allsites-": criteria.zone === ""? criteria.site + '-' : criteria.device === ''? criteria.site + '-' + criteria.zone + '-' :   criteria.site + '-' + criteria.zone + '-' + criteria.device + '-'}`
        const time = `${criteria.Ts + '-' + criteria.Te }`
        return  domain + time 
    }
    
    function handleErrorData(update) {
        ;
        Promise.all([
            fetchMetricsData2(getUrlOptions(false, true, "currentMode")),
            fetchMetricsData2(getUrlOptions(false, true, "errorCode"))
        ]).then(values => {
            ;
            const modeData = values[0]
            const errorData = values[1]
            let errorDataNew = { ...errorData, data: [] }

            if (modeData && modeData.data && modeData.data.length > 0) {
                modeData.data.forEach((timeStampData, i) => {
                    const devicesWithData = timeStampData.metrics ? 
                        Object.keys(timeStampData.metrics).filter(t => 
                            timeStampData.metrics[t] !== null && timeStampData.metrics[t] !== undefined
                        ) : 
                        []

                    const errorTimestamp = errorData.data.filter(t => t.timestamp === timeStampData.timestamp)
        
                    if (errorTimestamp.length > 0) {
                        const errorMetrics = devicesWithData.reduce((acc, curr) => {
                            acc[curr] = errorTimestamp[0].metrics[curr]
                            if (acc[curr] === undefined)
                                acc[curr] = 0
                                
                            return acc
                        }, {})
    
                        const errorTimestampData = { ...errorTimestamp[0], metrics: errorMetrics }
                        errorDataNew.data.push(errorTimestampData)
                    }
                })
            }

            update(modeData, errorDataNew)
        })
    }

    function handleBasicData() {
        handleErrorData((modeData, errorData) => {
            props.updateModeData(modeData)
            props.updateErrorData(errorData)
        })
        fetchMetricsData(getUrlOptions(false, true, "currentAngle"), () => {}, props.updateAngleData)
    }

    function handleViewData() {
        
        fetchMetricsData(getUrlOptions(false, true), setLoading, (metricName, metricData) => {
            props.onMetricChange(metricName, metricData)
            const metric = metricName.split(":")[0]
            if (metric === selectedOption.value.metric) {
                setSelectedData(metricData)
            }
        } )
    }

    function handleDownload() {
        let selectedDevices  = userContext.devices.filter(d => d.portfolio === criteria.pf && d.site_id === criteria.site)
        if (criteria.zone)
            if (criteria.device)
                selectedDevices = selectedDevices.filter(t => t.device_id === criteria.device && t.zone_id === criteria.zone)
            else
                selectedDevices = selectedDevices.filter(t => t.zone_id === criteria.zone)

            selectedDevices = selectedDevices.map(t => t.device_id)
        // downloadMetricsData(getUrlOptions(true, true), getFileName(), setLoading)
        let data = null
        let metricType
        let encodings
        const isCustomMetricOrRealtimeSnapshot = selectedOption.value.group_id === customMetricGroup || selectedOption.value.group_id ===  realtimeSnapshotGroup
        if (selectedOption.value.metric === 'errorCode')
            data = props.errorData
        else if (selectedOption.value.metric === 'currentMode')
            data = props.modeData
        else if (selectedOption.value.metric === 'currentAngle')
            data = props.angleData
        else if (isCustomMetricOrRealtimeSnapshot) {
            metricType = selectedOption.value.metricType
            encodings = selectedEncodings
            data = selectedMetricData
        } else
            data = selectedData
        if (data !== null) {
            const metric = selectedOption.value.metric + ":" + selectedOption.value.group_id
            downloadMetrics(metric, data, isCustomMetricOrRealtimeSnapshot ? "yes" : merged, criteria, selectedDevices, metricType, encodings)
        }
    }

    return (
        <Styles className="model_zoom">
            {!realtimeSnapshot ? <SingleSelectDropDownControl 
                options={metricsData} 
                handleChange={setSelectedOption} 
                title={'Select Heatmap Metric'} 
                value={selectedOption} 
                isClearable={true}
                loading={loading}
                isDisabled={props.isDisabled || realtimeSnapshot}
            >
            </SingleSelectDropDownControl> : null}
            {selectedOption && <>
                <div className="clickparent">
                    
                    {!loading ? <ButtonControl handleClick={handleDownload} /> :<div class="spinner-grow spinner-grow-sm mr-2 text-primary"  role="status" aria-hidden="true"> fetching... </div>} 
                    
                </div>
                {selectedOption && selectedOption.value && selectedOption.value.group_id !== customMetricGroup  && selectedOption.value.group_id !== realtimeSnapshotGroup ? <RadioControl value={merged} options={mergeOptions} handleChange={setMerged} disabled={loading}></RadioControl> : null}
                {/* <RadioControl value={interval} options={intervalOptions} handleChange={setInterval}></RadioControl>
                <RadioControl value={valueType} options={valueOptions} handleChange={setValueType}></RadioControl> */}
            </>}
            {realtimeSnapshot ? <div className="realtime-snapshot">
                {snapshotError ? <Alert key={"danger"} variant={"danger"}>
                    {snapshotError}
                </Alert> : null}
                <input 
                    onChange = {(e) => setSnapshotName(e.target.value)} 
                    value={snapshotName} 
                    disabled={savingSnapshot || savedSnapshot}
                    placeholder="Enter snapshot name"
                    ref={snapshotNameRef}
                />
                {savedSnapshot ? null : <span className="edit-note" onClick={() => setEditNote(!editNote)}>{editNote ? 'Done' : 'Edit Notes'}</span>}
                {editNote && !savedSnapshot ? <textarea className="note-input" onChange={e => setNote(e.target. value)} rows={8} ref={notesRef}>{note}</textarea> : null}
                {savedSnapshot ? <span>Snapshot saved</span> : <Button className="save"
                    disabled={savingSnapshot}
                    onClick={saveSnapshot}
                >
                    {savingSnapshot ? 'Saving snapshot..' : 'Save snapshot'}
                </Button>}
                <Button className="cancel"
                    onClick={() => back(true)}
                >
                    Back to Live Status
                </Button>
                {savedSnapshot ? <span 
                    className="edit-note" 
                    onClick={() => back(false)}
                    style={{textAlign: 'center'}}
                >All metrics</span> : null}
            </div> : null}

        </Styles>

    )

}

/*
const urls = [
    { name: "metrics", url: "all_unique_metrics", },
]

const comps = {
    title: 'Metrics',
    chart: MetricsDownloaderViewerBase,
    urls: urls,
    noheader: true,
    nocard: true,
    default: [],
    // parser: {f: metricParser, args: {} } 
    parser: { f: parseMetrics, args: {} }
}

const MetricsDownloaderViewer = withData({ ...comps }) //withData(PerfChartBase, urls)
*/
const MetricsHeatmap = memo(MetricsDownloaderViewerBase, (props1, props2) => {
    let memoize = true
    Object.keys(props1).forEach(key => {
        if (typeof props1[key] !== 'function' && props1[key] !== props2[key])
            memoize = false
    })
    return memoize
});
export default MetricsHeatmap;
