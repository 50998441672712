import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Card, Badge, Row, Col, Table, Pagination , ListGroup } from 'react-bootstrap'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range' 
import { AiOutlineArrowRight, AiOutlineArrowUp, AiOutlineArrowDown, AiOutlineMore, AiOutlineCheck } from "react-icons/ai";
import axios from 'axios'
import { getAccessToken, getUserEmail } from '../auth/userinfo'
import Datetime from 'react-datetime';
import { useNotificationContext } from '../../context/notification.context';
import "react-datetime/css/react-datetime.css";

import ReactTooltip from 'react-tooltip'

const Actions = styled.div`
    position: relative;

    .icon {
        cursor: pointer;
    }

    .menu {
        position: absolute;
        right: 10px;
        top: 20px;
        min-width: 128px;
        font-size: 12px;
        z-index: 10;
        background: white;
        border: 1px solid lightgray;
        border-radius: 4px;
        box-shadow: 0px 5px 15px lightgray;
    }

    .list-group {
    }
    
    .list-group-item {
        background: white;
        color: #014059;
        cursor:  pointer;

        :hover {
            background: #014059;
            color: white;
        }
    }

    .list-group-item.disabled {
        color: #0140594f !important;
    }

    .options {
        width: 248px;
        padding: 20px;
        z-index: 100;
        position: relative;

        .message {
            position: absolute;
            left: 50%;
            top: 0px;
            transform: translateX(-50%);
            background: lightgreen;
            padding: 2px 8px;
            border-radius: 2px;  
            width: max-content;     
        }
    }

    .ltr .rdtPicker {
        right: 0px;
        bottom: 40px;

        .table-bordered td, .table-bordered th {
            border: none !important;
        }

        .table td, .table th {
            padding: 0px;
            vertical-align: middle;
            border: none !important;
        }

        tr:hover {
            color: rgb(76, 67, 69);
            background-color: rgba(255, 255, 255, .25);
        }

        .rdtActive {
            background-color: #014059;
        }
        
    }

    .stack {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .icon {
            font-size: 20px;
        }
    }

    .reminder {
        padding-top: 8px;
        font-size: 14px;
    }
`

const Action = props => {
    const { message, heading, date, setDate, isValidDate, timezone, action, remindAfter} = props
    return <div className="options">
        {message.text ? <span className="message" style={{backgroundColor: message.type === 'error' ? 'pink' : 'lightgreen'}}>{message.text}</span> : null}
        <span>{heading}</span>
        <div className="stack">
            <Datetime 
                value={date}
                dateFormat="MMM DD, YYYY"
                onChange={setDate}
                isValidDate={isValidDate}
                displayTimeZone={timezone}
                className="ltr"
            />
            <AiOutlineCheck  className="icon"  onClick={action}/>
        </div>
        {remindAfter ? <div className="reminder">
            <span>Existing reminder:</span>
            <Badge  bg={"dark"} text={"light"}>{moment(remindAfter).tz(timezone || "Etc/UTC").format("MMM DD, YYYY hh:mm A")}</Badge>
        </div> : null}
    </div>
}

const ActivityActions = props => {
    const [open, setOpen] = useState(false)
    const [showEndTime, setShowEndTime] = useState(false)
    const [endTime, setEndTime] = useState(moment())
    
    const [message, setMessage] = useState({text: "", type: 'none'})
    const [snoozeMode, setSnoozeMode] = useState(false)
    const [remindAfter, setRemindAfter] = useState(moment())

    const notificationContext = useNotificationContext()

    const ref = useRef(null)

    const urlPost = (query, data) => {
        let token = getAccessToken()
        return axios({
            url: `maintenance?querytype=${query}&restype=json&project=depcom`,
            method: 'POST',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: data
        })
    }

    const isValidDate = date => {
        const currentDate = moment()
        return currentDate.isSameOrBefore(date, 'day')
    }

    const setReminder = id => {
        const user = getUserEmail()
        if (typeof remindAfter !== 'object') {
            setMessage({ type: "error", text: "Invalid date" })
            return
        }

        if (user !== props.user) {
            setMessage({ text: "You are not owner of this activity", type: 'error'})
            return
        }

        setMessage({type: 'progress', text: 'Saving..'})
        urlPost("snooze_one", {start_date: remindAfter, user: user, id: id})
        .then(() => {
            setMessage({ type: "success", text: "Done!" })
            props.setReminder(id, remindAfter)
        })
        .catch(err => {
            setMessage({ type: "error", text: err.message })
        })
    }

    const endActivity = (id, type, scope) => {
        
        const user =  getUserEmail()
        if (typeof endTime !== 'object') {
            setMessage({ text: "Invalid end time", type: 'error'})
            return
        }

        if (endTime.diff(props.startTime, 'minutes') < 30) {
            setMessage({ text: 'Activity should be atleast 30 minutes', type: 'error'})
            return
        }

        if (user !== props.user) {
            setMessage({ text: "You are not owner of this activity", type: 'error'})
            return
        }

        setMessage({type: 'progress', text: 'Ending activity..'})

        const data = { user, id, end_date: endTime, type, scope }
        urlPost("activity_end", data)
            .then((res) => {
                
                setMessage({type: 'success', text: 'Activity will be ended shortly!'})
                notificationContext.setJobId(res.data)
                // notificationContext.setJobId({
                //     id: res.data.job_id,
                //     message: `${type} activity for ${scope} has been ended. Please reload the application to see its impact.`,
                //     progressMessage: `${type} activity for ${scope} is being ended`
                // })
                // props.endActivity(id, endTime)
            })
            .catch(err => {
                
                setMessage({type: 'error', text: 'Error'})
            })
    }

    useEffect(() => {
        const close = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false)
                setShowEndTime(false)
                setSnoozeMode(false)
            }
        }

        document.addEventListener('click', close, true)
        return () => {
            document.removeEventListener('click', close, true)
        }
    }, [])

    useEffect(() => {
        let timeout
        if (message.type === 'success') {
            timeout = setTimeout(() => {
                setShowEndTime(false)
                setSnoozeMode(false)
                setOpen(false)
                setMessage({text: "", type: 'none'})
            }, 1000)
        }

        if (message.type ===  'error') {
            timeout = setTimeout(() => {
                setMessage({text: "", type: 'none'})
            }, 2000)
        }

        return () => clearTimeout(timeout)
    }, [message])

    return <Actions ref={ref}  className={props.className}>
        <AiOutlineMore   onClick={() => setOpen(true)}  className="icon"/>
        {open ? <div className="menu">
            <ListGroup>
                {!showEndTime && !snoozeMode ? <ListGroup.Item 
                    disabled={! props.ongoing || props.locked}
                    onClick={() => setShowEndTime(true)}
                >
                    End activity
                </ListGroup.Item> : null}
                {!showEndTime && !snoozeMode ? <ListGroup.Item 
                    disabled={! props.ongoing}
                    onClick={() => setSnoozeMode(true)}
                >
                    Remind me
                </ListGroup.Item> : null}
                {/*<ListGroup.Item>Delete activity</ListGroup.Item>*/}
            </ListGroup>
            {showEndTime || snoozeMode ?  <Action
                message={message}
                heading={snoozeMode ? "Remind on" : "End date"}
                date={snoozeMode ? remindAfter : endTime}
                setDate={snoozeMode ? setRemindAfter : setEndTime}
                isValidDate={snoozeMode ? isValidDate : () => true}
                timezone={props.timezone}
                action={snoozeMode ? () => setReminder(props.id) : () => endActivity(props.id, props.type, props.scope)}
                remindAfter={props.remindAfter}
            /> : null}
        </div> : null}
    </Actions>
}

const Activity = props => {
    const key = Math.random()
    const trackers_operational = props.trackers_operational === true || props.trackers_operational === "true"
    const startDate = moment.tz(props.start_date, props.timezone)
    const endDate = props.end_date ? moment.tz(props.end_date, props.timezone) : null

    const notificationContext = useNotificationContext()
    const locked = notificationContext.runningJobs.filter(t => t.job_detail === props.id).length > 0

    return <Card className="card" style={{ height: '100%'}}>
        <Badge className="badge1" bg={"dark"} text={"light"}>{props.equipment.toUpperCase()}</Badge><br />
        <ActivityActions   
            ongoing={endDate === null}  
            className="card-actions" 
            id={props.id} 
            endActivity={props.endActivity}  
            startTime={startDate}  
            timezone={props.timezone} 
            user={props.user}  
            type={props.type}  
            scope={props.scope}  
            remindAfter={props.remind_after2}
            setReminder={props.setReminder}
            locked={locked}
        />
        {props.new ? <Badge className="badge2" bg="danger">NEW</Badge> : null}
        {props.old && endDate === null  ? <Badge className="badge3" bg="danger">30 days old</Badge> : null}
        <Card.Body style={{marginBottom: 12}}>
        <Card.Title>
            <span className="heading">{props.type}</span><br />
            <span className="caption">{startDate.format("MMM DD YYYY HH:mm")} - {endDate ? endDate.format("MMM DD YYYY HH:mm") : "TBD"}</span>
        </Card.Title>
        <Card.Text>
            <span>Scope: {props.scope}</span><br />
            <span>{trackers_operational ? "Trackers operating " : "Trackers not operating "}</span>
            {trackers_operational ? 
                <AiOutlineArrowUp  className="icon green" /> : 
                <AiOutlineArrowDown className="icon red" />}
            <br />
            {/* <span>Equipment: {props.equipment}</span><br /><br /> */}
            <span data-tip data-for={"info"+key}>{props.notes.length > 30 ? props.notes.substring(0, 30) + ".." : props.notes}</span><br />
            {props.notes.length > 30 ? <ReactTooltip id={"info"+key} className="tooltip">
                <span>{props.notes}</span>
            </ReactTooltip> : null}
        </Card.Text>
        </Card.Body>
    </Card>
}

const Activities = styled.div`
    padding-top: ${props => props.type === 'recent' ? '0px' : '30px'};
    display: flex;
    width: 100%;

    .heading {
        font-size: 20px;
        color: #014059;
    }

    .caption {
        font-size: 12px;
    }

    .card {
        position: relative;
    }

    .badge1 {
        position: absolute;
        // left: 50%;
        // top: -8px;
        // transform: translateX(-50%);
        right: 8px;
        bottom: 8px;
    }

    .tooltip {
        max-width: 400px;
    }

    .badge2 {
        position: absolute;
        right: 3px;
        top: 3px;
    }

    .badge3 {
        position: absolute;
        top: -8px;
        left: 50%;
          transform: translateX(-50%);
    }

    .card-actions {
        position: absolute;
        right: 3px;
        top: 3px;
    }
    
    .icon {
        font-size: 16px;
        
        margin-top: -3px;
    }

    .green {
        color: green;
    }

    .red {
        color: red;
    }
`

const Button = styled.div`
    display: flex;
    flex-direction: column;
    color: #014059;
    cursor:  pointer;
    gap: 4px;
    height: 100%;
    justify-content: center;
    align-items: center;

    .icon-container {
        border: 1px solid #014059;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        font-size: 18px;
    }
`

const TableView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 20px;
`

const SwitchButton = props => {
    return props.type === 'recent' && props.count > 4 ? <Button
        onClick={() => props.setScreen('list')}
    >
        <div className="icon-container">
            <AiOutlineArrowRight />
        </div>
        <span>View All</span>
    </Button> : null
}

const TableData = props => {
    const notificationContext = useNotificationContext()

    return <Table striped bordered hover>
    <thead>
    <tr>
        <th>Type</th>
        <th>Equipment</th>
        <th>Scope</th>
        <th>Trackers Operating</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Notes</th>
    </tr>
    </thead>
    <tbody>
    {props.activities.map((activity, i) => {
        const trackers_operational = activity.trackers_operational === true || activity.trackers_operational === "true" ? "Yes" : "No"
        const key = Math.random()
        return <tr style={{ color: '#4C4345' }}>
            <td>{activity.type} {i === 0 && props.new ? <Badge className="badge2" bg="danger">NEW</Badge> : null}{ activity.old && !activity.end_date ? <Badge className="badge2" bg="danger">30 days old</Badge> : null}</td>
            <td>{activity.equipment}</td>
            <td>{activity.scope}</td>
            <td>{trackers_operational}</td>
            <td>{moment.tz(activity.start_date, props.timezone).format("MMM DD YYYY HH:mm")}</td>
            <td>{activity.end_date ? moment.tz(activity.end_date, props.timezone).format("MMM DD YYYY HH:mm")   : "TBD"}</td>
            <td>
                <span data-tip data-for={"info"+key}>{activity.notes.length > 30 ? activity.notes.substring(0, 30) + ".." : activity.notes}</span>
                {activity.notes.length > 30 ? <ReactTooltip id={"info"+key} className="tooltip">
                    <span>{activity.notes}</span>
                </ReactTooltip> : null}
            </td>
            <td><ActivityActions 
                    ongoing={activity.end_date === null}  
                    id = {activity.id}  
                    endActivity= {props.endActivity}   
                    startTime={moment.tz(activity.start_date, props.timezone)}  
                    timezone={props.timezone}  
                    user={activity.user} 
                    type={activity.type}  
                    scope={activity.scope}
                    remindAfter={activity.remind_after2}
                    setReminder={props.setReminder}
                    locked={notificationContext.runningJobs.filter(t => t.job_detail === activity.id).length > 0}
                />
            </td>
        </tr>
    })}
    </tbody>
</Table>
}

export default props => {

    const [active, setActive  ] =   useState(1)
    const pageSize = 5
    const pages = Math.ceil(props.activities.length / pageSize)
    let items = [];
    for (let number = 1; number <= pages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => setActive(number)} activeLabel=''>
                {number}
            </Pagination.Item>,
        );
    }

    const firstItem = (active - 1) * pageSize
    const allActivities = props.type === "recent" && props.view === 'grid' ?
      props.activities.slice(0, 4) : 
      props.activities.slice(firstItem, firstItem + pageSize)

    useEffect(() => {
        setActive(1)
    }, [props.activities])

    return props.view === 'grid' ? <Activities>
        <Row style={{width: props.type === 'recent' ? '100%' : 'auto'}}>
            {allActivities.map((t, i) => 
                <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
                    <Activity {...t} new={i===0 && props.showNew} screen={props.type} timezone={props.timezone} endActivity={props.endActivity} setReminder={props.setReminder}/>
                </Col>
            )}
        </Row>
        {/* <SwitchButton
            setScreen={props.setScreen}
            type={props.type}
            count={props.activities.length}
        /> */}
    </Activities> : <TableView>
        <TableData activities={allActivities}  new={props.showNew} timezone={props.timezone} endActivity={props.endActivity} setReminder={props.setReminder}/>
        <Pagination>
            <Pagination.First onClick={() => setActive(1)} disabled={active === 1}/>
            <Pagination.Prev onClick={() => setActive(active - 1)} disabled={active === 1}/>
            {items}
            <Pagination.Next onClick={() => setActive(active + 1)} disabled = {active === pages}/>
            <Pagination.Last onClick={() => setActive(pages)} disabled = {active === pages}/>
        </Pagination>
    </TableView>
}