import ReactEcharts from "echarts-for-react";
import React, { useMemo, useState } from 'react';
import nextId from "react-id-generator";
import withData from "../model/combined";
import { getChartDef } from './chartconfig';


const MyChartBase = (props1) => {
    const props = useMemo(() => props1, [props1])
    // console.log(props)
    //console.log(defaultOption)
    const onEvents = {
        // 'click': this.onChartClick,
        // 'legendselectchanged': this.onChartLegendselectchanged
    }

    // props.data.legend.textStyle.color = "red";

    return (
        props.data && <ReactEcharts
            className="clsEchart"
            option={props.data}
            // style={{ height: "80vh", left: 50, top: 50, width: "90vw" }}
            style={{ height: "50vh", left: 0, top: 0, width:"100%" }}
            opts={{ renderer: "svg" }}
            onChartReady={props.onRenderComplete}
            // showLoading={true}
            onEvents={onEvents}
            // ref={(e) => props.setChartRef(e)}
            lazyUpdate={true}

        />

    )
}

export function getComp(ud) {
    const charttype = getChartDef(ud.charttype)
    const grouptype = ud.grouptype ? { grouptype: ud.grouptype } : {}
    const metricGroup = ud.metricGroup ? { metricGroup: ud.metricGroup } : {}
    const path = ud.path? {path:ud.path}:{};
    const aggr = ud.aggr? {aggr:ud.aggr}:{};
    const url = Object.assign({}, { name: "result", url: ud.source, slice: ud.interval }, grouptype, metricGroup,path,aggr)
    return {
        title: ud.title,
        tooltip: ud.tooltip,
        chart: MyChartBase,
        urls: [url],
        default: charttype.default,
        hideEmpty: ud.hideEmpty,
        nocard: false,
        showTimezone: ud.showTimezone,
        // parser: { f: charttype.maker, args: { summary: false, valueX: ud.timeColumn, titleX: ud.x, titleY: ud.y, } }
        parser: { f: charttype.maker, args: { summary: false, options: ud.chartOptions, inteval: ud.interval, title: ud.title } }
    }
}

export function getComp2(ud, criteria) {
    console.log("@@@ ---@@@ ---@@ ", ud)
    const urls = ud.sources.map((src, i) => {
        const grouptype = src.grouptype ? { grouptype: src.grouptype } : {}
        const metricGroup = src.metricGroup ? { metricGroup: src.metricGroup } : {}
        return Object.assign({}, { name: src.id, url: src.source, slice: src.interval, aggr: src.aggr, metricType: src.metricType, metricName: src.metricName }, grouptype, metricGroup)

    })

    const charttype = getChartDef(ud.charttype)
    // const grouptype = ud.grouptype ? { grouptype: ud.grouptype } : {}
    // const urls = Object.assign({}, { name: "result", url: ud.source, slice: ud.interval }, grouptype)
    return {
        title: ud.title,
        chart: MyChartBase,
        urls: urls,
        default: charttype.default,
        hideEmpty: true,
        nocard: false,
        chartType: ud.charttype,
        chartMetaData: ud.chartMetaData,
        // parser: { f: charttype.maker, args: { summary: false, valueX: ud.timeColumn, titleX: ud.x, titleY: ud.y, } }
        parser: { f: charttype.maker, args: { summary: false, options: ud.chartOptions, inteval: ud.interval } }
    }
}


const GenericChart = (props) => {
    // console.log(props)
    const SomeChart = withData({ ...props.data })
    return <SomeChart />
}


export const UDChartItem = (props) => {
    // const ChartItem = React.memo(props => { return <GenericChart data={props.data} /> });
    const ChartItem = useMemo(() => withData({ ...props.data}),[])

    return (
        <ChartItem data={props.data} key={nextId()}/>
    )
}

export const UDChartRow = (props) => {
    // const vh = props.height ? `{props.height}`: "vh-25"
    return (
        <div className="row" key={nextId()}>
            {props.children}
        </div>
    )
}