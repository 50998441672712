
export const labelOptionBasicC = {
    alignTo: 'edge',
    formatter: '{c}',//'{time|{c}}\n{name|{b}}',
    minMargin: 5,
    edgeDistance: 10,
    //lineHeight: 15,
    rich: {
        time: {
            fontSize: 14,
            color: '#eee'
        }
    }
}

export const labelOptionABC = {
    // formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
    formatter: '  {c} : {b|{b}}  ',
    // backgroundColor: 'rgba(255,255,252, 0)', //'#F6F8FC',
    // borderColor: 'rgba(255,255,255, 1)',
    color: '#8693AB',
    fontSize: "10px",
    fontWeight: '400',
    fontFamily: 'Montserrat',
    position: 'left',
    minMargin: 0,
    overflow: 'truncate',
    borderWidth: 1,
    borderRadius: 4,
    rich: {
        c: {
            color: '#8693AB',
            fontSize: "10px",
            fontWeight: 'bold',
            lineHeight: 33
        },
        a: {
            color: '#8693AB',
            lineHeight: 22,
            align: 'center'
        },
        hr: {
            borderColor: '#8693AB',
            width: '100%',
            borderWidth: 1,
            height: 0
        },
        b: {
            color: '#8693AB',
            fontSize: 10,
            fontWeight: 'bold',
            lineHeight: 33
        },
        per: {
            color: '#8693AB',
            backgroundColor: '#8693AB',
            padding: [3, 4],
            borderRadius: 4
        }
    }
}
