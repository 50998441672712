import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { getAccessToken } from '../auth/userinfo';
import axios from "axios";


const ServiceForm = () => {
    // State variables to manage service data and loading status
    const [serviceData, setServiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Function to fetch data from server when the component mounts
        const fetchData = async () => {
            try {
            // Making a POST request to fetch service data
            const data = await urlPost("/meta?querytype=meta_forecast_status&restype=json", {});
            setServiceData(data); // Updating service data state
            } catch (error) {
                console.error('Error fetching service data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData(); 
    }, []);

    // Function to make a POST request with provided URL and data
    function urlPost(url, data) {
        let token = getAccessToken();
    
        return axios.post(url, data, {
            headers: {
                'x-access-token': token
            }
        })
        .then(response => response.data)
        .catch(error => {
            console.error('Error making POST request:', error);
            throw error; // Re-throw the error for handling in the caller
        });
    }

    // Mapping object for service names
    const serviceNameMapping = {
        'forecast_server': 'Weather Forecast Service',
        'middle_ware': 'FTC Middleware',
        'hail_server': 'Hail Service',
        'ack_server': 'Acknowledgment Service'
    };

    // Function to convert epoch time to local time with AM and PM, in "X hours ago", and in "X minutes ago" format
    const epochToLocalTime = (epoch) => {
        const currentDate = new Date();
        const timestampDate = new Date(epoch);
        const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / (1000 * 60); // Difference in minutes

        if (timeDifference < 60) {
            return `${Math.round(timeDifference)} minutes ago`;
        } else {
            const hourDifference = timeDifference / 60; // Difference in hours
            if (hourDifference < 24) {
                return `${Math.round(hourDifference)} hours ago`;
            } else if (hourDifference < 48) {
                return `1 day ago`;
            }else {
                const dateString = timestampDate.toLocaleDateString();
                const timeString = timestampDate.toLocaleTimeString(undefined, { hour12: true });
                return `${dateString}, ${timeString}`;
            }
        }
    };
    

    return (
        <div style = {{width: '100%'}}>
            <div className="chart-card1">
                <div className="chart-card-header1">
                    <h5 style={{ fontWeight: 600, fontSize: '18px', lineHeight: '15px', color: 'rgb(76, 67, 69)', padding: '10px 3px 10px 6px' }}>
                        Services
                    </h5>
                </div>
                <Row></Row>
                {isLoading && (
                <p
                    style={{
                        textAlign: "center",
                        color: "#4C4345",
                        marginTop: "10px",
                    }}
                >
                    Loading...
                </p>
            )}
                <Row className="d-flex align-items-center">
                    <Col md={2}>
                        <label className="mb-3 chart-card-header"><b>Status</b></label>
                    </Col>
                    <Col md={5}>
                        <label className="mb-3 chart-card-header"><b>Service Name</b></label>
                    </Col>
                    <Col md={5}>
                    <label className="mb-3 chart-card-header"><b>Last Updated At</b></label>
                </Col>
                </Row>
                {/* Render rows for service data */}
                {serviceData.map((service, index) => (
                    <Row className="d-flex align-items-center" key={index}>
                        <Col md={2}>
                            {service.service_status === 'active' ? <span style={{ color: 'green', fontSize: '24px' }}>✔</span> : <span style={{ color: 'red', fontSize: '24px' }}>✘</span>}
                        </Col>
                        <Col md={5}>
                            <label className="card-title">{serviceNameMapping[service.service_name]}</label>
                        </Col>
                        <Col md={5}>
                        <label className="mb-3 card-title">{epochToLocalTime(service.timestamp)}</label>
                    </Col>
                    </Row>
                ))}
            </div>
        </div>
    );
};

export default ServiceForm;
