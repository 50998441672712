import React, {useState, useEffect} from "react";
import { mailErrorLogs } from "./utils/apiCall";
// import { getUserEmail } from "./components/auth/userinfo";

const ErrorPage = (props) => {

  const style = {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }

  useEffect(() => {
    if(props.error && props.errorInfo){
      mailErrorLogs({error: props.error, errorInfo: props.errorInfo, url: window.location.href})
    }
  },[props.error, props.errorInfo])

  const redirectToDashBoard = () => {
    localStorage.setItem("activePf",'')
    window.location.href = window.location.origin
    window.reload()
  }
  
  return (<div style={style}>
    <h1 style={{fontSize: '50px'}}>Something went wrong.</h1>
    <h1 style={{fontSize: '25px'}}>SUNOPS team has been notified.  Please click “Back” to continue working.</h1>
    <button onClick={redirectToDashBoard} className="btn btn-secondary active mt-2">Back to SUNOPS</button>
  </div>)
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };

  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to an error reporting service
    this.setState({error: error.message, errorInfo: errorInfo})
    console.error("Error caught by Error Boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} errorInfo={this.state.errorInfo}/>
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
