
import moment from 'moment';
import { getTimeFormat } from '../chartjs/line.options';
import { getTimesByType } from '../layout/criteria';
import { getIntervalByUrl } from './store.swr';
import { colorIndex, getRandomColor } from './utils';

const fwswTitle = { wind_speed: "Wind Speed", flood_level: "Flood Level", snow_fall: "Snow Fall", wind_direction: "Wind Direction" };

export const getLabelTitles = () => {
    return fwswTitle
}
export const fwswOptions = [
    { label: "Wind Speed", value: "wind_speed", unit: 'm/s' },
    { label: "Flood Level", value: "flood_level", unit: 'mm' },
    { label: "Snow Fall", value: "snow_fall", unit: 'mm' },
    { label: "Wind Direction", value: "wind_direction", unit: 'deg' },
]



export const getFWSWOptionKeys = () => {
    return fwswOptions.map(k => k.value)
}

export const getFWSWOptionByValue = (value) => {
    const fwsw = fwswOptions.find(e => e.value === value)
    if (fwsw) return fwsw.label
    return "NA"
}
export const getFWSWUnit = (value) =>{
    const fwsw = fwswOptions.find(e => e.value === value)
    if (fwsw) return fwsw.unit
    return "NA"

}
export const allKeys = ["wind_speed", "flood_level", "snow_fall", "wind_direction"];

const colors = {
    "wind_speed": "purple",
    "flood_level": "green",
    "snow_fall": "orange",
    "wind_direction": "blue"
};

function values(selected, key) {

    var linedata = []
    const times = selected.map((e) => {
        const value = e[key]
        linedata.push(value)
        var obj = {}
        obj[e.time_key] = value
        return obj
        //return `<span style="width: 10px">${value} (${e.time_key * 15} mins) </span> `

    }).join("<br/>")

    const sum = linedata.reduce((acc, e) => acc + e, 0);
    const avg = (sum / linedata.length).toFixed(2) || 0;

    return { avg, linedata, times }
}

function grouped_avg(data) {
    const reduced = data.reduce(function (m, d) {
        if (!m[d.time_key]) {
            m[d.time_key] = { ...d, count: 1 };
            return m;
        }
        m[d.time_key].flood_level += d.flood_level;
        m[d.time_key].snow_fall += d.snow_fall;
        m[d.time_key].wind_direction += d.wind_direction;
        m[d.time_key].wind_speed += d.wind_speed;
        m[d.time_key].count += 1;
        return m;
    }, {});

    // Create new array from grouped data and compute the average
    const result = Object.keys(reduced).map((k) => {
        const item = reduced[k];
        const max = 10
        return {
            time_key: item.time_key,
            flood_level: (item.flood_level / item.count).toFixed(2),
            snow_fall: (item.snow_fall / item.count).toFixed(2),
            wind_direction: (item.wind_direction / item.count).toFixed(2),
            wind_speed: (item.wind_speed / item.count).toFixed(2)
        }
    })
    return result
}

function getTimeSeries(data) {
    const flood_level = {}
    const snow_fall = {}
    const wind_direction = {}
    const wind_speed = {}
    data.forEach((e) => {
        flood_level[e.time_key] = e.flood_level
        snow_fall[e.time_key] = e.snow_fall
        wind_direction[e.time_key] = e.wind_direction
        wind_speed[e.time_key] = e.wind_speed
    })
    const title = {
        display: true,
        text: 'Custom Chart Title'
    }


    return {
        wind_speed: { label: "Wind Speed", data: wind_speed, color: colorIndex(4) },
        flood_level: { label: "Flood Level", data: flood_level, color: colorIndex(1), },
        snow_fall: { label: "Snow Fall", data: snow_fall, color: colorIndex(2), pointRadius: 0, title: { fontColor: "#fff" } },
        wind_direction: { label: "Wind Direction", data: wind_direction, color: colorIndex(3) },
    }


}


export function parseFWSWData(data, criteria) {


    //console.log("FWSW_1",data)
    const selected = data.filter(e => criteria(e))
    const groupavg = grouped_avg(selected).sort(function (a, b) {
        return b.time_key - a.time_key;
    })
    console.log("fwsw grouped   ", groupavg)
    //return groupavg;
    //return getTimeSeries(groupavg)




    /*
    return {
        time_key: { title: "Time Key", values: values(selected, "time_key") },
        flood_level: { title: "Flood Level", values: values(selected, "flood_level") },
        wind_speed: { title: "Wind Speed", values: values(selected,  "wind_speed") },
        snow_fall: { title: "Snow Fall", values: values(selected,  "snow_fall") },
        wind_direction: { title: "Wind Direction", values: values(selected,  "wind_direction") },
    }
    */

    // //console.log("FWSW sumary ", JSON.stringify(data));
    // ReactTooltip.rebuild();

    // return data;
}

export const getMoment = () => { return moment() }

export const getDataTimes = (data) => {
    const times = data.filter(f => f.zone_id === data[0].zone_id).sort((a, b) => a.time_key - b.time_key).map((e) => e["time_key"])
    //console.log("fwsw times", times)
    return times
}

export const getDataSets = (data, sensortype, label) => {

    const zones = [...new Set(data.map(item => item.zone_id))];
    const ds = zones.map((z, i) => {
        const d = data.filter(f => f.zone_id === z).sort((a, b) => a.ts - b.ts).map((e) => {
            return { x: new Date(e.ts *1000), y: e[sensortype] }
        })
        // const d = data.filter(f => f.zone_id === z && f.metric === sensortype).sort((a, b) => a.ts - b.ts).map((e) => {
        //     return { x: new Date(e.ts *1000), y: e['value'] }
        // })

        const c = getRandomColor()
        return {
            label: (label) ? label : z,
            borderColor: c, //colorIndex(i),
            backgroundColor: c,
            fill: false,
            data: d,
            pointRadius: 1,
            borderWidth: 2

        }
    })

    return ds
}

export const formatLabels = (timesdata, startMoment, url, criteria) => {
    const times = getTimesByType(criteria.timetype)
    const interval = getIntervalByUrl(times, url)

    const d = Date.now()
    const millisecs = 60 * 1000

    const m = startMoment;


    return timesdata.map((e) => {
        return moment(e*1000).format(getTimeFormat())

        //return moment(m.valueOf()).subtract(moment.duration({ minutes: interval * e })).format(getTimeFormat())
        //xArray.push(new Date(d - (mins * e[xkey] * millisecs)))
    }
    )

}
export const DomainToChart = (data, keys) => {
    //console.log(keys)
    const fwsws = keys.split(",").map(function (item) {
        return item.trim();
    });
    const datasets = fwsws.map((k, i) => {
        const ds = {
            label: k,
            borderColor: getRandomColor(),
            data: data.map((e) => e[k])//{ var obj = {}; obj[ e['time_key']] =  e[k]; return obj } )
        }
        return Object.assign({}, ds)

    })
    return datasets
}

