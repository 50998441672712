import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { IdleTimeOutModal } from './IdleTimeOutModal'
import {logout} from './Login'
import { getIdleTimeoutInterval } from './userinfo'

const IdleTimeOutHandler = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [isLogout, setLogout] = useState(false)
    let timer = undefined;
    const events = ['click', 'load', 'keydown']
    const eventHandler = (eventType) => {
        if (!isLogout) {
            localStorage.setItem('lastInteractionTime', moment())
            if (timer) {
              //  props.onActive();
                startTimer();
            }
        }

    };

    useEffect(() => {
        addEvents();

        return (() => {

            removeEvents();
            clearTimeout(timer);
        })
    }, [])

    const startTimer = () => {
        //900000
        let timeOutInterval = getIdleTimeoutInterval() * 60000;
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {

            let lastInteractionTime = localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            
            if (isLogout) {
                clearTimeout(timer)
            } else {
                if (diff._milliseconds < timeOutInterval) {
                    startTimer();
                } else {
                    //props.onIdle();
                    setShowModal(true)
                }
            }
        }, timeOutInterval)
    }
    const addEvents = () => {

        events.forEach(eventName => {
            window.addEventListener(eventName, eventHandler)
        })

        startTimer();
    }
    const removeEvents = () => {
        events.forEach(eventName => {
            window.removeEventListener(eventName, eventHandler)
        })
    };
    const handleContinueSession = () => {
        setShowModal(false)
        setLogout(false)
    }
    const handleLogout = () => {
        removeEvents();
        clearTimeout(timer);
        setLogout(true)
        setShowModal(false)
        logout();
    }
    return (
        <div>
            {
                  showModal && 
                  <IdleTimeOutModal
                      showModal={showModal}
                      handleContinue={handleContinueSession}
                      handleLogout={handleLogout}
                  />
            }
          
        </div>
    )

}
export default IdleTimeOutHandler;