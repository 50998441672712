import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React, { useEffect, useMemo, useState } from "react";
import { useAuthContext } from '../Authentication';
import { logout } from '../components/auth/Login';
import { getUserID } from '../components/auth/userinfo';
import { getAllMetrics, getDevicesAsOptions, getOrgCharts, getPortfolioDevices, getSites, getUserCharts } from '../model/metadata';
import ContextFactory from "./base.context";
import { useCriteriaContext } from "./criteria.context";
import { useStore } from "./store.context";
import { useNotificationContext } from './notification.context';

const UserContext = React.createContext();
const UserActionContext = React.createContext();


const defaultUser = {} //{time: '', domain: ''}
export const useUserContext = ContextFactory('UserContext', UserContext)
export const useUserActionContext = ContextFactory('UserActionContext', UserActionContext)

const UserContextProvider = (props) => {
    // const store = useStore()
    const authState = useAuthContext()
    // const [user, setUser] = useState({}) //useMemo(()=> { if (authState.status === "success") { return getUserID() } }, [authState])
    const [userCharts, setUserCharts] = useState([])
    const [orgCharts, setOrgCharts] = useState([])
    const [metrics, setMetrics] = useState([])
    const [portfolios, setPortfolios] = useState([])
    const [organization, setOrganization] = useState("ftcsolar")
    const [tenants, setTenants] = useState({})

    const criteria = useCriteriaContext()
    const portfolio = useMemo(() => { return criteria.pf}, [criteria.pf])
    const [devices, setDevices] = useState([])
    const [sites, setSites] = useState([])
    const [inverters, setInverters] = useState([])
    const deviceOptions = useMemo(() => { return getDevicesAsOptions(devices) }, [devices]) 

    const [sitesLoaded, setSitesLoaded] = useState(false)

    const storeContext = useStore()

    const notificationContext = useNotificationContext()

    const navFilter = useMemo(() => {return storeContext.navFilter}, [storeContext.navFilter])

    // const data = useMemo(() => { return {user: authState, charts: userCharts, metrics: metrics, orgCharts: orgCharts, org: "ftcsolar"} }, [authState, userCharts, metrics, orgCharts])

    function onError(error){
        console.log(error)
        if(error.message && error.message.includes("JWT")){
            logout();
        }
    }

    function refreshCharts(){
        getUserCharts(getUserID(), setUserChartsFromData, onError)
        getOrgCharts(organization, getUserID(), setOrgCharts, onError)

    }
    function setUserChartsFromData(data) {
       // const checkArray = [];
       //data = data.filter((item)=>{
       //    if(!checkArray.includes(item.page)){
       //        checkArray.push(item.page)
       //      return true;
       //    }
       //    });
       console.log("dataAdded",data)
        setUserCharts(data);
    }
    function updateUserChart(data) {
        if(data && data.length >0)
        setUserCharts(data);
    }
    /*
    function updateDevices(devices){
        setDevices(devices)
        setDeviceOptions(getDevicesAsOptions(devices))
    }*/
    function setFilteredDevices(devices) {

        // console.log("SHOW ME NAV FILTEREERE ERE W", navFilter)
        if ('zone' in navFilter && navFilter.zone != "") {
            // console.log("Storing Devices after filtering ZONNNNNEESSS ")
            setDevices( devices.filter((e) => e.site_id == navFilter.site && e.zone_id == navFilter.zone))
            return
        }
        if ('site' in navFilter && navFilter.site != "") {
            // console.log("Storing Devices after filtering SITEEEESSSSSSSSS ")
            setDevices( devices.filter((e) => e.site_id == navFilter.site ))
            return
        }
        setDevices(devices)
        
    }
    function refreshDevices(){
        setTimeout(() => {
            // Code to execute after the timeout
            console.log('Timeout completed!');
            getPortfolioDevices(criteria, (data) => {
                setDevices(data.map(device => ({...device, portfolio: criteria.pf})))
            }, onError) 
          }, 3500); // 3000 milliseconds = 3 seconds
    }
    
    function refreshSites(){
        getSites(criteria, setSites, onError)
    }

    useEffect(()=> {
        if (authState.status === "success") 
        {  
            // refreshDevices();
            refreshCharts();
            // getAllMetrics(criteria, setMetrics, onError)
            // console.log(metrics)
            refreshSites()
        }
    },[authState])

    useEffect(() => {
        if ( criteria.site) {
            if (sites.length > 0) {
              const site = sites.filter(site => site.site_id === criteria.site)
              if (site.length > 0 && site[0].timezone) {
                  moment.tz.setDefault(site[0].timezone)
                  notificationContext.setTimezone(site[0].timezone)
              }
              else {
                  moment.tz.setDefault('Etc/UTC')
                  notificationContext.setTimezone('Etc/UTC')
              }

              getAllMetrics(criteria, setMetrics, onError)
              setSitesLoaded(true)
            }
        } else {
            moment.tz.setDefault('Etc/UTC')
            getAllMetrics(criteria, setMetrics, onError)
            setSitesLoaded(true)
            notificationContext.setTimezone('Etc/UTC')      
        }

        const ltimezones = localStorage.getItem("timezones")
        if (sites.length > 0 && !ltimezones) {
            const timezones = sites
                .filter(site => site.timezone)
                .map(site => ({ site_id: site.site_id, timezone: site.timezone }))
            if (timezones.length > 0) {
                localStorage.setItem("timezones", JSON.stringify(timezones))
            }
        }

      },  [sites, criteria.site])


    useEffect(() => {
        // console.log("all devices fetched ", devices)

    }, [devices])

    useEffect(()=>{
        // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$ Portfolio Changed $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4", portfolio)
        refreshDevices()
        if(sites.length){
            var tenantList = []
            sites.forEach(s => {
                if(s.portfolios.includes(criteria.pf)){
                    tenantList.push(s.tenant)
                }
            })
            tenantList = [...new Set(tenantList)]
            setTenants(tenantList) 
        }
    }, [portfolio, sites])
    /*
    useEffect(() => {
        console.log("User Context data changed ", data)
    }, [data])
    */
    useEffect(() => {
        // console.log("User Context data changed ")
    }, [authState, userCharts, metrics, orgCharts])

    const actions = {
        refreshCharts: refreshCharts,
        refreshMetrics: () => { getAllMetrics(criteria, setMetrics, onError) },
        updateUserChart: (data)=> updateUserChart(data),
        updateInverters: (data)=> setInverters(data)
    }


    return (
        <UserContext.Provider value={{user: authState, charts: userCharts, metrics: metrics, orgCharts: orgCharts, org: organization, devices: devices, sites: sites, deviceOptions: deviceOptions, tenants: tenants, inverters:inverters} }>
            <UserActionContext.Provider value={actions}>
                {sitesLoaded ? props.children : 'Loading...'}
            </UserActionContext.Provider>
        </UserContext.Provider>
    )
}

export default UserContextProvider
