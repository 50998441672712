import React from 'react'
import styled from 'styled-components'
import { AiOutlineGlobal } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { Button } from 'react-bootstrap'
import { columns } from './columns'


import { useRealtimeContext } from "../../context/realtime.context"
import { useModelContext } from "../../context/model.context"


import { modelPage, usePageActionContext } from "../../context/page.context";

const RealtimeOverlay = styled.div`
    display: inline-block;
    margin-left: 40px;
    position: absolute;
    top: 8px;
    right: ${props => props.hasFilters ? '140px' : '80px'};
    

    .overlay-button {
        background: #014059;
        border-color: #014059;
        cursor:  pointer;

        :active {
            background: #014059 !important;
        }

        .icon {
            font-size: 16px;
            margin-top: -3px;
            margin-right: 2px;
        }
    }
`

export default props => {
    const realtimeContext = useRealtimeContext()
    const pageAction = usePageActionContext()
    const modelContext = useModelContext()

    const { filterRowAttrs, searchApplied, products, productsShown, setRealtimeMetric, query } = realtimeContext
    const { realtimeMetrics, noModel } = modelContext

    const getMetricName = () => {
        const allNames = realtimeMetrics
            .map(t => t.metric)
            .filter(t => t.indexOf("live_status_filter_") === 0)
            .map(t => {
                const d = t.replace("live_status_filter_", "").trim()
                return isNaN(d) ? 0 : parseInt(d)
            })

        const allNamesSorted = allNames.sort((a, b) => a > b ? -1 : 1)
        return "live_status_filter_" + ((allNamesSorted[0] || "") + 1)

    }

    const getMetrics = (products, productsShown) => {
        const metricData = products.reduce((acc, curr) => {
            const e = productsShown.filter(t => t.device_id === curr.device_id)
            if (e.length > 0) {
                acc.filterData[curr.device_id] = 1
                acc.modeData[curr.device_id] = e[0].currentMode
                acc.angleData[curr.device_id] = e[0].currentAngle
                acc.errorData[curr.device_id] = e[0].errorCode
            } else {
                acc.filterData[curr.device_id] = 0
                acc.modeData[curr.device_id] = curr.currentMode
                acc.angleData[curr.device_id] = curr.currentAngle
                acc.errorData[curr.device_id] = curr.errorCode
            }
            return acc
        }, {filterData: {}, modeData: {}, angleData: {}, errorData: {}})
        return metricData
    }

    const getFiltersApplied = () => {
        return filterRowAttrs.map(curr => {
            const field = columns.filter(t => t.dataField === curr.dataField)
            const text = field.length > 0 ? field[0].text : curr.text

            return {
                dataField: curr.dataField,
                text: text,
                filters: curr.type === 'encoded' ? 
                    curr.filterData.length > curr.selectedFilters.length ? curr.filterData.filter(t => curr.selectedFilters.indexOf(t.value) >= 0).map(t => t.label) : [] : 
                    curr.filterData[0] < curr.selectedFilters[0] || curr.filterData[1]  > curr.selectedFilters[1] ? [curr.selectedFilters[0], curr.selectedFilters[1]] : [],
                type: curr.type
            }
            
        }).filter(t => t.filters.length > 0)
    }

    const viewOnSite = () => {
        if (noModel)
            return
        const data = getMetrics(products, productsShown)
        setRealtimeMetric({
            metricName: getMetricName() + ":RS",
            metricData: [{timestamp: 0, metrics: data.filterData}],
            modeData: [{timestamp: 0, metrics: data.modeData}],
            angleData: [{timestamp: 0, metrics: data.angleData}],
            errorData: [{timestamp: 0, metrics: data.errorData}],
            minMaxValue: {},
            metricType: "Binary",
            encodings: null,
            query: searchApplied ? query : "",
            filters: getFiltersApplied()
        })
        
        pageAction.setPage(modelPage, [], false)
    }

    return <RealtimeOverlay hasFilters={filterRowAttrs.filter(t => t.selected).length > 0} data-tip data-for='info'>
        {(filterRowAttrs.filter(t => t.selected).length > 0 || searchApplied) && productsShown.length > 0 && products.length > 0  ? <Button 
            className="overlay-button" 
            onClick={viewOnSite}
            style={{opacity: noModel ? .65 : 1}}
        >
            <AiOutlineGlobal className="icon"/> View on site
        </Button> : null}
        {noModel ? <ReactTooltip id='info'>
            <span>Please contact admin to setup model viewer for this site.</span>
        </ReactTooltip> : null}
    </RealtimeOverlay>
}


