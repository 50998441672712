import React from 'react'
import styled from 'styled-components'
import { AiOutlineTool }   from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'


const Notify = styled.span`
    .icon {
        margin-top: -3px;
        outline: none;
        color: #014059;
        font-size: 16px;
        margin-left: 4px;
    }
`

const NotifyTooltip = props => {
    const random = Math.random().toString()
    return <Notify>
        <AiOutlineTool data-for={random} data-tip={props.content}  className="icon" onClick={props.onClick}/>
        <ReactTooltip className='custom-tooltip' id={random} html={true} multiline={true} toolTipPointer={'right'} />
    </Notify>
}

export default NotifyTooltip