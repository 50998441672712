import React, { useState, useMemo, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { customDropDownStyles } from "../controls/multiselect_control";
import { useUserContext } from "../../context/user.context";
import { useCriteriaContext } from "../../context/criteria.context"
import { getAccessToken, getUserEmail } from '../auth/userinfo'
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range' 
import VirtualizedSelect from 'react-virtualized-select'
import Select from "react-select"
import Datetime from 'react-datetime';
import { useNotificationContext } from '../../context/notification.context';
import "react-datetime/css/react-datetime.css";

import { uuid } from 'uuidv4';

const Maintenance = styled.div`
    padding: 0px 8px;

    .form {
        padding-top: 20px;
    }

    .row {
        padding: 0px;
    }

    .col {
        padding: 0px;
    }

    .button {
        background: #014059;
        font-size: 16px;

        :active {
            background: #014059 !important;
        }
    }

    .submit {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
    }

    .input {
        border-color: #b7bdc0;
    }

    .custom-datepicker {
        font-size: 14px;
        color: #014059;
        border-color: #b7bdc0;
    }

    .form-control {
        border-color: #b7bdc0;
    }

    .rdtOpen .form-control {
        box-shadow: 0 0 0 1px #2684FF;
        
        :hover {
            border: 1px solid #2684FF;
        }
    }

    .rdtPicker {
        color: #014059;
        
        .rdtActive {
            background-color: #014059;
        }
    }

    .ltr .rdtPicker { 
        right: 0px;
    }


    .notes {
        resize: none;
        :focus {
            box-shadow: 0 0 0 1px #2684FF;
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
    
`

export default props => {

    const userContext = useUserContext()
    const criteria = useCriteriaContext()
    const notificationContext = useNotificationContext()
    
    let currentScope = criteria.site ? criteria.site : ""
    if (criteria.zone)
        currentScope += "/" + criteria.zone
    if (criteria.device)
        currentScope += "/" + criteria.device


    const [page, setPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [inputValue, setInputValue] = useState("")
    // const [activity, setActivity] = useState(null)
    const [type, setType] = useState(null)
    const [scope, setScope] = useState(currentScope ? { label: currentScope, value: currentScope } : null)
    const [stage, setStage] = useState(null)
    const [showStartTime, setShowStartTime] = useState(true)
    const [showEndTime, setShowEndTime] = useState(true)
    const [startTimeLabel, setStartTimeLabel] = useState("Start time")
    const [endTimeLabel, setEndTimeLabel] = useState("End time")
    const [trackersOperational, setTrackersOperational] = useState(null)
    const [equipment, setEquipment] = useState(null)
    const [startTime, setStartTime] = useState(moment())
    const [endTime, setEndTime] = useState(moment())
    const [notes, setNotes] = useState("")
    const [saving, setSaving] = useState(false)

    const { setError, addActivity, setShowNew } = props

    const types = [
        { value: "Scheduled maintenance", label: "Scheduled maintenance" },
        { value: "Replacement", label: "Replacement" },
        { value: "Repair", label: "Repair" },
        { value: "Troubleshooting", label: "Troubleshooting" },
        { value: "Site Outage", label: "Site Outage" },
    ]

    const trackerOperationalOptions = [
        { value: "false", label: "No" },
        { value: "true", label: "Yes" }
    ]

    const stageOptions = [
        { value: "ongoing", label: "Ongoing" },
        { value: "not-started", label: "Not started" },
        { value: "completed", label: "Completed" }
    ]

    const equipments = [
        { value: "Tracker-Structural", label: "Tracker-Structural" },
        { value: "Tracker-Mechanical", label: "Tracker-Mechanical" },
        { value: "Tracker-Electrical", label: "Tracker-Electrical" },
        { value: "Tracker-Controls", label: "Tracker-Controls" },
        { value: "Inverter", label: "Inverter" },
        { value: "Module", label: "Module" },
        { value: "DC Combiner", label: "DC Combiner" },
        { value: "Transformer", label: "Transformer" },
        { value: "Wiring", label: "Wiring" },
        { value: "Switchgear", label: "Switchgear" },
        { value: "Other", label: "Other" },
        { value: "None", label: "None" },
    ]
    

    const options = useMemo(()=>  {
        const devices = userContext.deviceOptions
        return devices.length > 0 ? devices : [{ value: 'loading', label: 'Loading devices..', isDisabled: true }]
    }, [userContext.deviceOptions])

    const customStyles = useMemo(
        () => ({
            ...customDropDownStyles, 
            control: (provided, state) => ({
                ...provided,
                backgroundColor: state.isDisabled ? "hsl(0, 0%, 95%)" : "#ffffff",
                borderColor: "#b7bdc0",
                color: "#014059",
                fontSize: "14px",
            }),
            placeholder: (provided, state) => ({
                ...provided,
                color: '#014059'
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                padding: '4px'
            }),
            clearIndicator: (provided, state) => ({
                ...provided,
                padding: "4px"
            })
        }), []
    );

    const addOptions = () => {
        setIsLoading(true)
        setTimeout(() => {
            setPage(page + 1)
            setIsLoading(false)
        }, 0);
    }
    
    const handleMenuScrollToBottom = () => {
        addOptions()
    }

    const onInputChange = (value) => {
        setInputValue(value)
        setPage(1)
    }

    const optionsFiltered = inputValue ? options.filter(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) : options
    const optionsPaged = [
        ...optionsFiltered.slice(0, page * 20),
        isLoading ? { value: 'loading', label: 'Loading more...', isDisabled: true } : {}
      ];

    const getScopeDetails = scope => {
        let site = "", zone = "", device = ""
        const scopeParts = scope.split("/")
        if (scopeParts.length > 0)
            site = scopeParts[0]
        if (scopeParts.length > 1)
            zone = scopeParts[1]
        if (scopeParts.length > 2)
            device = scopeParts[2]

        return { site, zone, device }
    }

    const saveActivity = (e) => {
        e.preventDefault()

        // if (!activity) {
        //     setError({ message: "Please select the maintenance activity", type: 'error' })
        //     return
        // }

        if (!type) {
            setError({ message: "Please select the maintenance type", type: 'error' })
            return
        }

        if (!scope) {
            setError({ message: "Please select the scope of impact", type: 'error' })
            return
        }

        if (!trackersOperational) {
            setError({ message: "Please select whether trackers are operational", type: 'error' })
            return
        }

        if (!stage) {
            setError({ message: "Please select the stage of the maintenance activity", type: 'error' })
            return
        }

        if (!equipment) {
            setError({ message: "Please select an equipment", type: 'error' })
            return
        }

        if (typeof startTime !== 'object') {
            setError({ message: "Invalid start time", type: 'error'})
            return
        }

        if (typeof endTime !== 'object' && showEndTime) {
            setError({ message: "Invalid end time", type: 'error'})
            return
        }

        if (endTime.diff(startTime, 'minutes') < 30 && showEndTime) {
            setError({ message: 'The activity should be atleast 30 minutes', type: 'error'})
            return
        }

        setSaving(true)

        const activity = {
            // activity: activity.value,
            type: type.value,
            scope: scope.value,
            // stage: stage.value,
            trackers_operational: trackersOperational.value,
            equipment: equipment.value,
            start_date: startTime,
            end_date: showEndTime ? endTime : null,
            notes: notes,
            user: getUserEmail(),
            id: uuid()
        }

        const { site, zone, device } = getScopeDetails(scope.value)

        const activityToSave = {...activity, site: site, zone: zone, device: device, scope: scope.value }
        urlPost("activity_add", activityToSave).then((res) => {
            setSaving(false)
            setError( { message: 'Activity will be logged soon!', type: 'success' })
            // addActivity(activity)
            // setShowNew(true)

            // setActivity(null)
            setType(null)
            setStage(null)
            setScope(null)
            setTrackersOperational(null)
            setEquipment(null)
            setStartTime(moment.tz(props.timezone))
            setEndTime(moment.tz(props.timezone))
            setNotes("")
            // notificationContext.setJobId({
            //     id: res.data.job_id,
            //     message: `${type.value} activity for ${scope.value} has been logged. Please reload the application to see its impact.`,
            //     progressMessage: `${type.value} activity for ${scope.value} is being logged`
            // })
            notificationContext.setJobId(res.data)
        }).catch(err => {
            setSaving(false)
            setError( { message: err.message, type: 'error' })
        })
    }

    const urlPost = (query, data) => {
        let token = getAccessToken()
        return axios({
            url: `maintenance?querytype=${query}&restype=json&project=depcom`,
            method: 'POST',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: data
        })
      }

    const handleTimezone = timezone => {
        props.setTimezone(timezone)
        const sTime = moment.tz(startTime, timezone)
        const eTime = moment.tz(endTime, timezone)
        setStartTime(sTime)
        setEndTime(eTime)
    }

    const isValidStartDate = (date) => {
        if (stage) {
            const currentDate = moment()
            if (stage.value === "not-started")
                return currentDate.isSameOrBefore(date, 'day')
            else if (stage.value === 'ongoing')
                return currentDate.isSameOrAfter(date, 'day')
            else if (stage.value === 'completed')
                return currentDate.isSameOrAfter(date, 'day')
        } 
            
        return false
    }

    const isValidEndDate = (date) => {
        if (stage) {
            const currentDate = moment()
            if (stage.value === "not-started")
                return currentDate.isSameOrBefore(date, 'day')
            else if (stage.value === 'ongoing')
                return currentDate.isSameOrBefore(date, 'day')
            else if (stage.value === 'completed')
                return currentDate.isSameOrAfter(date, 'day')
        } 
            
        return false
    }

    useEffect(() => {
        if (userContext.sites.length > 0 && scope) {
            const { site } = getScopeDetails(scope.value)
            const siteObj = userContext.sites.filter(t => t.site_id === site)
            if (siteObj.length > 0) {
                handleTimezone(siteObj[0].timezone)
            } else {
                handleTimezone(null)
            }
        } else {
            handleTimezone(null)
        }

    }, [scope, userContext.sites])

    useEffect(() => {
        if (equipment && equipment.value && equipment.value.indexOf("Tracker-") < 0) {
            setTrackersOperational({ value: "true", label: "Yes" })
        } else {
            setTrackersOperational(null)
        }
    }, [equipment])

    useEffect(() => {
        if (stage && stage.value) {
            if (stage.value === 'ongoing' || stage.value === 'not-started') {
                setShowEndTime(false)
                setShowStartTime(true)
                setStartTimeLabel(stage.value === 'ongoing' ? 
                    "When was the activity started?" : 
                    "When will the activity start?"
                )
            } else {
                setShowEndTime(true)
                setShowStartTime(true)
                setStartTimeLabel("When did the activity start?")
                setEndTimeLabel("When did the activity end?")
            }
        } else {
            setShowStartTime(false)
            setShowEndTime(false)
        }

        setStartTime(moment())
        setEndTime(moment())
    }, [stage])

    return <Maintenance>
        <Form className="form" onSubmit={saveActivity}>
            <Row>
                {/* <Col xs="12" lg="6" xl="4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2" size="lg">
                        <Form.Label>Activity</Form.Label>
                        <Select 
                            options={activities} 
                            onChange={setActivity}
                            styles={customStyles}
                            isClearable={true}
                            placeholder={"select maintenance activity"}  
                            value={activity}
                        />
                    </Form.Group>
                </Col> */}
                <Col xs="12" lg="6" xl="4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2" size="lg">
                        <Form.Label>Type of maintenance</Form.Label>
                        <Select 
                            options={types} 
                            onChange={setType}
                            styles={customStyles}
                            isClearable={true}
                            placeholder={"select maintenance type"}  
                            value={type}
                        />
                    </Form.Group>
                </Col>
                
                <Col xs="12" lg="6" xl="4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3" size="lg">
                        <Form.Label>Equipment</Form.Label>
                        <Select 
                            options={equipments} 
                            onChange={setEquipment}
                            styles={customStyles}
                            isClearable={true}
                            placeholder={"select equipment"}  
                            value={equipment}
                        />
                    </Form.Group>
                </Col>

                <Col xs="12" lg="6" xl="4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Scope of impact</Form.Label>
                        <VirtualizedSelect
                            options={optionsPaged}
                            onChange={setScope}
                            onMenuScrollToBottom={handleMenuScrollToBottom}
                            styles={customStyles}
                            isClearable={true}
                            selectComponent={Select}
                            inputValue={inputValue}
                            onInputChange={onInputChange}
                            placeholder={"select RC / Array / Site"}
                            value={scope}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" lg="6" xl="4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3" size="lg">
                        <Form.Label>Trackers operational during this time?</Form.Label>
                        <Select 
                            options={trackerOperationalOptions} 
                            onChange={setTrackersOperational}
                            styles={customStyles}
                            isClearable={true}
                            placeholder={"select if trackers are operational"}  
                            value={trackersOperational}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" lg="6" xl="4">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3" size="lg">
                        <Form.Label>Stage of activity</Form.Label>
                        <Select 
                            options={stageOptions} 
                            onChange={setStage}
                            styles={customStyles}
                            isClearable={true}
                            placeholder={"select the stage of maintenance activity"}  
                            value={stage}
                        />
                    </Form.Group>
                </Col>
           
                <Col xs="12" lg="6" xl="4">
                    <div style={{display: 'flex', justifyContent: 'space-between', gap: 20}}>
                        {showStartTime ? <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{width: '100%'}}>
                            <Form.Label>{startTimeLabel}</Form.Label>
                            <br />
                            <Datetime 
                                value={startTime}
                                dateFormat="MMM DD, YYYY"
                                onChange={setStartTime}
                                displayTimeZone={props.timezone}
                                isValidDate={isValidStartDate}
                            />
                        </Form.Group> : null}
                        {showEndTime ? <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{width: '100%'}}>
                            <Form.Label>{endTimeLabel}</Form.Label>
                            <br />
                            <Datetime 
                                value={endTime}
                                dateFormat="MMM DD, YYYY"
                                onChange={setEndTime}
                                isValidDate={isValidEndDate}
                                displayTimeZone={props.timezone}
                                className="ltr"
                            />
                        </Form.Group> : null}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control as="textarea" rows={3}  className="input notes" value={notes} onChange={e => setNotes(e.target.value)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <div className="submit">
                        <Button 
                            type="submit" 
                            className="button" 
                            disabled={saving}
                        >
                            {saving ? 'Logging' : 'Log'} activity
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    </Maintenance>
}