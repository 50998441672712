import React, { useEffect, useRef } from 'react';
import { GoogleApiWrapper} from 'google-maps-react'; // Importing necessary components from google-maps-react 
import config from './config.json'; // Importing Google Maps API key from configuration file
import { isAdminUser } from "../auth/userinfo"; // Importing isAdminUser function from auth/userinfo
import { Row } from 'react-bootstrap';

const SiteMap = ({ siteValue, latitude, longitude, google, radius }) => {
  console.log('siteValue:', radius);
  const mapRef = useRef(null); // Creating a ref to reference the map component

  useEffect(() => {
    if (!mapRef.current || !latitude || !longitude) return; // Exit early if mapRef, latitude, or longitude is not available

    // Creating a new Google Maps instance and setting up initial configuration
    const map = new google.maps.Map(mapRef.current, {
      center: { lat: latitude, lng: longitude },
      zoom: 9, // Setting initial zoom level
      mapTypeControl: false, // Disabling map type control
      streetViewControl: false // Disabling street view control
    });

    // Adding a marker to the map at provided latitude and longitude
    new google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map: map,
    });

    // Calculating radius in meters from provided radius value
    const radiusValue = parseFloat(radius.split(' ')[0]);
    const radiusInMeters = radiusValue * 1609.34;

    // Creating a circle overlay on the map with specified radius
    const circle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: map,
      center: { lat: latitude, lng: longitude },
      radius: radiusInMeters,
    });

    // Creating an info window to display radius information
    const infoWindow = new google.maps.InfoWindow({
      content: `<div>Forecast Radius: ${radiusValue} miles </div>`,
      closeButton: false // Disable the close button on the info window
    });

    // Adding mouseover event listener to display info window when hovering over the circle
    google.maps.event.addListener(circle, 'mouseover', () => {
      const circleBounds = circle.getBounds(); // Get the bounds of the circle
      const northeastCorner = circleBounds.getNorthEast(); // Get the northeast corner of the circle's bounds
      const offsetLat = -0.03; // Adjust this value as needed to position the tooltip outside the circle
      const offsetLng = -0.03; // Adjust this value as needed to position the tooltip outside the circle
      const infoWindowPosition = {
        lat: northeastCorner.lat() + offsetLat, // Adjust the latitude position
        lng: northeastCorner.lng() + offsetLng // Adjust the longitude position
      };
      infoWindow.setPosition(infoWindowPosition); // Set the adjusted position for the info window
      infoWindow.open(map);
    });

    // Adding mouseout event listener to close info window when mouse leaves the circle
    google.maps.event.addListener(circle, 'mouseout', () => {
      infoWindow.close();
    });
   }, [latitude, longitude, radius, google]);// Running useEffect when latitude, longitude, radius, or google object changes

  return (
    <div className="chart-card1">
      <div className="chart-card-header1">
        <div>
          <h5 style={{ fontWeight: 600, fontSize: '18px', lineHeight: '15px', color: 'rgb(76, 67, 69)', padding: '10px 0px 10px 6px' }}>
            {siteValue} Map
          </h5>
        </div>
      </div>
      <Row></Row>
      <div ref={mapRef} style={{ width: '100%', height: !isAdminUser() ? '497px' : '538px' }} />
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: config.apiKey
})(SiteMap);
