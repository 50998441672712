import moment from 'moment';
import { getTimeFormat } from '../chartjs/line.options';
import { getTimesByType } from '../layout/criteria';
import { getIntervalByUrl } from './store.swr';
import { getRandomColor } from './utils';



export const getMoment = () => { return moment() }

export const getAngleTimes = (data, keycolumn) =>{
    const times = data.filter(f => f[keycolumn]===data[0][keycolumn]).sort((a,b) => a.ts - b.ts).map((e) => e["ts"])
    //console.log("angles times", times)
    return times
}

export const getAngleDataSets = (data, start_dt, keycolumn, datacolumn, label) => {

    //console.log(start_dt.toDate())
    const keys = [...new Set(data.map(item => item['device_id']))];  
    //console.log("angles k", keys)
    const ds = data.map(kd => {
        const k = kd["device_id"]
        const angles = kd["angles"]
        //console.log(k, angles)
        const anglesObj = JSON.parse(angles);

        if (anglesObj){

        
            const d = anglesObj.sort((a,b) => a.ts - b.ts).map((e) => {
                //return { x: new Date((e[keycolumn]+start_secs) * 1000), y: e[datacolumn]}
                const dt = new Date(start_dt + 1000 * e[keycolumn]) //start_dt.add(e[keycolumn], "Seconds").toDate()
                return { x: dt, y: e[datacolumn]}
            })
        // const d = data.filter(f => f['device_id']===k)["angles"].sort((a,b) => a.ts - b.ts).map((e) => {
        //     return { x: new Date((e[keycolumn]+start_secs) * 1000), y: e[datacolumn]}
        // })
            
            const c = getRandomColor()
            const keylabel =  k

           return {
                label: (label)?label:keylabel, 
                borderColor: c, //colorIndex(i),
                backgroundColor: c,
                fill: false,
                data: d,
                pointRadius: 1,
                borderWidth: 2

            }
        }
    })

    return ds
}

export const formatLabels = (timesdata, startMoment, url, criteria) => {
    const interval = getIntervalByUrl(getTimesByType(criteria.timetype), url) 


    const m = startMoment;
    //console.log("raw", timesdata)

    const ts =  timesdata.map((e) => {
        return moment(e*1000).format(getTimeFormat())
            //return moment(m.valueOf()).subtract(moment.duration({ minutes: interval * e })).format(getTimeFormat())
            //xArray.push(new Date(d - (mins * e[xkey] * millisecs)))
        }
    )
    //console.log(ts)
    return ts

}