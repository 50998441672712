import React from 'react'
import styled from 'styled-components'
import { AiOutlineDownload } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { Button } from 'react-bootstrap'
import { CSVLink } from "react-csv";
import { useCriteriaContext } from "../../context/criteria.context"

import { useRealtimeContext } from "../../context/realtime.context"
import moment from 'moment'


const RealtimeDownload = styled.div`
    display: inline-block;
    margin-left: 56px;
    position: absolute;
    top: 8px;
    right: 20px;
    

    .download-button {
        background: white;
        cursor: pointer;
        border: white;
        width: 48px;
        height: 34px;
        color: #014059;

        :active {
            background: #014059 !important;
            color: white;
        }

        :disabled {
            background: white !important;
            color: #014059;
            cursor: auto;
        }


        .icon {
            font-size: 24px;
            margin-top: -3px;
            margin-right: 2px;
        }
    }
`

export default props => {

    const realtimeContext = useRealtimeContext()
    const { productsShown, rowAttrs } = realtimeContext

    const criteria = useCriteriaContext()
    const headers = rowAttrs
        .filter(t => t.selected && !t.gc)
        .map(t => ({label: t.text, key: t.dataField}))

    const formatters = rowAttrs
        .filter(t => t.selected && !t.gc)
        .reduce((acc, curr) => {
            if (curr.formatter2 || curr.formatter) {
                acc[curr.dataField] = curr.formatter2 ? curr.formatter2 : curr.formatter
            }

            return acc
        }, {})

    const productsShownModified = productsShown.map(t => {
        return Object.keys(t).reduce((acc, curr) => {
            acc[curr] = formatters[curr] ? formatters[curr](t[curr]) : t[curr]
            return acc
        }, {})
    })

    const filename = `LiveStatus_${criteria.site}_${moment().format("DD_MM_YYYY_HH_mm")}.csv`


    return <RealtimeDownload  data-tip data-for='info-download' data-tooltip-place='bottom'>
        {productsShown.length > 0 ? <CSVLink  data={productsShownModified} headers ={headers} filename={filename}>
            <Button 
                className= "download-button"
            >
                <AiOutlineDownload className="icon"/>
            </Button>
        </CSVLink> : <Button 
                className= "download-button"
                disabled
            >
                <AiOutlineDownload className="icon"/>
            </Button>}
        {productsShown.length > 0 ? <ReactTooltip id='info-download'>
            <span>Download CSV</span>
        </ReactTooltip> : null}
    </RealtimeDownload>
}