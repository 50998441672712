import { observe, toJS } from 'mobx';
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import ContextFactory from "./base.context";
import { useStore } from "./store.context";

const CriteriaContext = React.createContext();
const CriteriaActionContext = React.createContext();


const defaultCriteria = {} //{time: '', domain: ''}
export const useCriteriaContext = ContextFactory('CriteriaContext', CriteriaContext)
export const useCriteriaActionContext = ContextFactory('CriteriaActionContext', CriteriaActionContext)

const CriteriaContextProvider = (props) => {
    // const store = useStore()
    const criteria = useStore().criteria
    const [lcriteria, setLcriteria] = React.useState(defaultCriteria)

    observe(criteria, (change) => {
        console.log("****************** Store Criteria changed")

        setLcriteria(toJS(criteria))
    });
    function setTenant(tenant){
        setLcriteria(Object.assign(toJS(criteria), {tenant: tenant}))
    }

    useEffect(()=> {
        setLcriteria(criteria)
    },[criteria])

    const initActionState = {
        setContext: setLcriteria,
        setTenant: setTenant
    }
    const [actionState, setActionState] = React.useState(initActionState)

    return (
        <CriteriaContext.Provider value={lcriteria}>
            <CriteriaActionContext.Provider value={actionState}>
                {props.children}
            </CriteriaActionContext.Provider>
        </CriteriaContext.Provider>
    )
}

export default observer(CriteriaContextProvider)
