import React from 'react';
import { Bar } from 'react-chartjs-2';

const StackedChart2 = ({ data }) => {
  const chartData = {
    labels: data.map(entry => entry.user_id),
    datasets: [
      {
        label: 'Session Time (seconds)',
        data: data.map(entry => entry.session_time),
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'User ID',
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Session Time (seconds)',
          },
        },
      ],
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem, chartData) => {
         
          const dataIndex = tooltipItem.index;
          const dataset = chartData.datasets[tooltipItem.datasetIndex];
          const value = dataset ? dataset.data[dataIndex] : undefined; // Check if dataset and data are defined
          const userEntry = data[dataIndex];
      
          // Check if the required properties are defined before using them
          if (userEntry && value !== undefined) {
            return [
              `Date: ${userEntry.start_date}`,
              `Session Time: ${value.toFixed ? value.toFixed(2) : value} seconds`, // Check if toFixed is a function before calling it
              `Start Time: ${userEntry.start_time}`,
              `End Time: ${userEntry.end_time}`,
              `Session Counter: ${userEntry.session_counter}`,
            ];
          } else {
            return ['No data available']; // Provide a default message if data is missing
          }
        },
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

export default StackedChart2;
