import React from 'react';
import Select, { components } from 'react-select';
import './diffuse.css';

// Custom Option component to include checkboxes
const Option = (props) => {
  const handleChange = () => {
    if (props.isSelected) {
      props.setValue(props.getValue().filter(val => val.value !== props.data.value));
    } else {
      props.setValue([...props.getValue(), props.data]);
    }
  };

  return (
    <components.Option {...props}>
    <div onClick={handleChange} >
      <input className = 'inputstyle'
        type="checkbox"
        checked={props.isSelected}
        onChange={handleChange}
        onClick={e => e.stopPropagation()}
      />
      <label className = 'lablestyle'>{props.label}</label>
      </div>
    </components.Option>
  );
};

// Custom MultiValue component to customize selected items display
const MultiValue = (props) => {
  const { index, getValue } = props;
  const maxToShow = 1;
  const overflow = getValue().length - maxToShow;

  if (index < maxToShow) {
    return <components.MultiValue {...props} />;
  } else if (index === maxToShow) {
    return (
      <components.MultiValue {...props}>
        and {overflow} more...
      </components.MultiValue>
    );
  } else {
    return null;
  }
};

const MultiSelectDropDownControl = ({ options, selectedOptions, onChange }) => {
  const handleChange = (selectedOptions) => {
    onChange(selectedOptions || []); // Ensure deselection is handled properly
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 4,
      border: '1px solid #ced4da',
    }),
  };

  return (
    <Select
      placeholder="Select..."
      isMulti
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      styles={customStyles}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option, MultiValue }}
    />
  );
};

export default MultiSelectDropDownControl;
