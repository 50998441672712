import axios from 'axios'
import React, { useState } from 'react'
import App from './App'
import Login,{logout} from './components/auth/Login'
import ForgetPassword from './components/auth/ForgetPassword'
import ContextFactory from './context/base.context'
import UserContextProvider from './context/user.context'
import CommonContextProvider from './context/common.context'

import { RealtimeContextProvider } from "./context/realtime.context";
import { ModelContextProvider } from "./context/model.context";
import { NotificationContextProvider } from "./context/notification.context";
import { AvailabilityContextProvider } from "./context/availability.context";
import { positions, Provider, useAlert } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { isData, parseUserInfo, getUser,getTokenKey,setEncryptedToken,getTokenTimeoutInterval } from './components/auth/userinfo'

//import { Secret, decode, verify, sign } from 'jsonwebtoken';

const AuthContext = React.createContext()
export const useAuthContext = ContextFactory('AuthContext', AuthContext)

function AuthProvider({ children }) {
  // console.log('auth provider')
  const [state, setState] = React.useState({
    status: 'pending',
    error: null,
    user: null
  })

  const alert = useAlert()

  const [step, setStep] = useState(0)

  const [auth, setAuth] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [loading, setLoading] = useState(false)

  const resetPassword = async (data) => {
    setLoading(true)

    try{
      const response = await axios({
        method: 'POST',
        url: '/auth/resetpassword',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        data
      })
      if(response && response.status === 200){
        if(response.data.success){
          alert.success(response.data.errorMsg)
          setStep(0)
        }else{
          alert.error(response.data.errorMsg)
        }
      }
    }catch(err){
      alert.error(err.response && err.response.data.errorMsg)
    }

    setLoading(false)
  }

  const getResetCode = async (data) => {
    setLoading(true)
    try{
      const response = await axios({
        method: 'POST',
        url: '/auth/forgot',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        data
      })
      if(response && response.status === 200){
        if(response.data.forgotUpdated){
          setStep(2)
        }else{
          alert.error(response.data.errorMsg)
        }
      }
    }catch(err){
      alert.error(err.response && err.response.data.errorMsg)
    }

    setLoading(false)

  } 
  const generateToken = (userInfo,setUserState) =>{
    if(userInfo && userInfo!='') {
      var data = {"email":userInfo.email}
      return axios({
        method: 'POST',
        url: '/token/refresh_access',
        // headers: { 'x-refresh-token': getRefreshToken(), 'Content-Type': 'application/json;charset=utf-8','credentials': 'include' },
        data
        })
        .then((res) => {
          if(res.data.alive && res.headers['x-access-token']) {
            var tokenKey = {'atoken':JSON.stringify(res.headers['x-access-token'])}
            setTimeout(() => {
              setEncryptedToken(tokenKey);
            }, 500);
            if(setUserState) {
              setState({
                status: 'success',
                error: null,
                user: userInfo,
              })
            }
          } else {
            logout();
          }
        }).catch((err) => {
          logout();
        })
    }
  }
  const loginUser = (data) => {
    setLoading(true)
    return axios({
      method: 'POST',
      url: '/auth/login',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data,
    })
      .then((res) => {
        if (!res || !isData(res.data) || res.headers['x-access-token'] === undefined) { 
          setLoginError(true)
          setLoading(false)
          return 
        }
        const userInfo = parseUserInfo(res.data)
        
        if(!userInfo || !userInfo.access.isLoginSuccess){
          setLoginError(true)
          setLoading(false)
          return 
        }
        setAuth(true)
        setLoading(false)
        // var tokenKey = {'atoken':JSON.stringify(res.headers['x-access-token'])}
        // setTimeout(() => {
        //   setEncryptedToken(tokenKey);
        // }, 500);
        setState({
          status: 'success',
          error: null,
          user: userInfo.user,
        })
        var tokenKey = {'atoken':JSON.stringify(res.headers['x-access-token'])}
        setEncryptedToken(tokenKey);
        localStorage.setItem('user', JSON.stringify(res.data))
        //generateToken(res.data);
        window.location.reload()
        
      })
      .catch((err) => {
        alert.error(err.response && err.response.data.errorMsg)
        // setLoginError(true)
        setLoading(false)
      })
  } 
  React.useEffect(()=>{
    const userObj = getUser()
    if (userObj) {
      if(getTokenTimeoutInterval()>0)
      generateToken(userObj,true);
      var tokenInterval = getTokenTimeoutInterval()>0 ? setInterval(() => {
       generateToken(userObj,false);
      }, ((getTokenTimeoutInterval()-1)*60000)) : null;
    } else {
      setState({ status: 'error', error: { message: 'invalid' }, user: null })
    }
    return () => {
      clearInterval(tokenInterval);
    }
  }, [])

  return (
    <AuthContext.Provider value={state}>
      {state.status === 'pending' ? (
        'Loading...'
      ) : state.status === 'error' && step === 0 ? (
        <Login
          loginUser={(data) => loginUser(data)}
          loginError={loginError}
          loading={loading}
          auth={auth}
          setStep={setStep}
        />
      ) : state.status === 'error' && (step === 1 || step === 2) ? (
        <ForgetPassword
          getResetCode={getResetCode}
          step={step}
          setStep={setStep}
          resetPassword={resetPassword}
          loading={loading}
        />
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}

function useAuthState() {
  // console.log('auth state')
  const state = React.useContext(AuthContext)
  const isPending = state.status === 'pending'
  const isError = state.status === 'error'
  const isSuccess = state.status === 'success'
  const isAuthenticated = state.user && isSuccess
  return {
    ...state,
    isPending,
    isError,
    isSuccess,
    isAuthenticated,
  }
}

function UnauthenticatedApp() {
  return <><h5>Here....</h5></>
}

const AuthenticatedApp =() => {
  // console.log('user');
  return (
    <ModelContextProvider>
      <RealtimeContextProvider>
        <NotificationContextProvider>
          <AvailabilityContextProvider>
            <UserContextProvider>
              <CommonContextProvider>
              <App />
              </CommonContextProvider>
            </UserContextProvider>
          </AvailabilityContextProvider>
        </NotificationContextProvider>
      </RealtimeContextProvider>
    </ModelContextProvider>
  )
}

function Home() {
  const {user} = useAuthState()
  
  return (
    <>
    {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </>
    )
}

function Auth() {
  const options = {
    timeout: 5000,
    position: positions.TOP_RIGHT
  };
  
  return (
    <Provider template={AlertTemplate} {...options}>
      <AuthProvider>
        <Home />
      </AuthProvider>
    </Provider>
    
  )
}

export default Auth
