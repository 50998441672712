import { getUserId, getUserToken } from "../components/auth/userinfo"
import { isDevice, isPortfolio, isSite, isZone,isInverter } from "../components/layout/criteria";
import {viewIds} from '../constant/constant'
import { toJS } from "mobx";
import {createFeature, getStatsData} from '../utils/apiCall'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'


export const getLogData = (criteria, data={}) => {
    const viewId = data.level ? viewIds[data.category][data.level] : viewIds[data.category]
    const env = window.location.host === process.env.REACT_APP_PRODUCTION_HOST ? 'production' : window.location.host === process.env.REACT_APP_TEST_HOST ?  'test' : 'development'
    var detailsObj = data.details ? (typeof data.details === 'string' ? JSON.stringify({...JSON.parse(data.details), env: env}) : JSON.stringify({...data.details, env: env})) : JSON.stringify({env: env})
    if(isPortfolio(criteria)){
        return {pf: data.portfolio ? data.portfolio : criteria.pf, site: data.sites || "", userId: getUserId(), token: getUserToken(), featureId: data.featureId, viewId: viewId, metrics: data.metrics || "", details: detailsObj, usecase: data.usecase, viewCategory: data.category, zone: "", device: ""}
    }else if(isSite(criteria)){
        return {device: "", zone: "", site: data.sites ? data.sites : criteria.site, pf: data.portfolio ? data.portfolio : criteria.pf, userId: getUserId(), token: getUserToken(), featureId: data.featureId, viewId: viewId, metrics: data.metrics || "", details: detailsObj, usecase: data.usecase, viewCategory: data.category}
    }else if(isZone(criteria)){
        return {device: "", zone: criteria.zone, site: data.sites ? data.sites : criteria.site, pf: data.portfolio ? data.portfolio : criteria.pf, userId: getUserId(), token: getUserToken(), featureId: data.featureId, viewId: viewId, metrics: data.metrics || "", details: detailsObj, usecase: data.usecase, viewCategory: data.category}
    }else if(isDevice(criteria)){
        return {device: criteria.device, zone: criteria.zone, site: data.sites ? data.sites : criteria.site, pf: data.portfolio ? data.portfolio : criteria.pf, userId: getUserId(), token: getUserToken(), featureId: data.featureId, viewId: viewId, metrics: data.metrics || "", details: detailsObj, usecase: data.usecase, viewCategory: data.category}
    }
    else if(isInverter(criteria)){
        return {inverter:criteria.inverter, device: "", zone: "", site: data.sites ? data.sites : criteria.site, pf: data.portfolio ? data.portfolio : criteria.pf, userId: getUserId(), token: getUserToken(), featureId: data.featureId, viewId: viewId, metrics: data.metrics || "", details: detailsObj, usecase: data.usecase, viewCategory: data.category}
    }
    return 
}

export const getLevel = (c) => {
    return isPortfolio(c) ? 'pf' : isSite(c) ? 'site' : isZone(c) ? 'zone' : isDevice(c) ? 'rc' : {}
}

export const getWeightedAvg = (data)  => {
    var availabilityNumerator = 0
    var availabilityDenominator = 0
    data.map(obj => availabilityNumerator+=(obj.uptime*obj.devicecount))
    data.map(obj => availabilityDenominator+=obj.devicecount)
    return  (availabilityNumerator/availabilityDenominator).toFixed(2)
}

export const handleStatsAsync = async (url, setResponse, setError, availabilityContext) => {
    const data = await new Promise((resolve,reject) => {
        getStatsData(url,resolve,reject,true)
    })
    
    if (typeof data === 'object' && data.data){
        setResponse && setResponse(data)
        availabilityContext && availabilityContext.setAvailability(data.data.availability_avg[0].availability)
    }else if(Array.isArray(data)){
        setResponse && setResponse(data)
        availabilityContext && availabilityContext.setAvailability(data[0].availability_avg[0].availability)
    }else{
        setError && setError(data)
    }
}

export const getSiteLocalTime = (timestamp, timezone, isHistoricalPerformance) => {
    const utcTime = isHistoricalPerformance ? moment.unix(timestamp).utc() :moment.utc(timestamp)
    if(timezone && !isHistoricalPerformance){
        console.log("utctime",utcTime)
        utcTime.tz(timezone)
    }
    // const siteTime = moment(utcTime.format())
    return utcTime.format("YYYY-MM-DD HH:mm:ss")
}