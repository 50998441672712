import React, { useEffect, useState } from 'react'
import { Button, Modal, ListGroup, Badge } from 'react-bootstrap'
import styled from 'styled-components'
import axios from 'axios'
import { AiFillInfoCircle } from "react-icons/ai";
import { getAccessToken, getUserEmail } from '../auth/userinfo'
import ReactTooltip from 'react-tooltip'


import { useModelContext } from '../../context/model.context';
import 'filepond/dist/filepond.min.css';
import { useCriteriaContext } from '../../context/criteria.context'


const InfoStyle = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  text-align: center;
  background: #ffa2b0;
  padding: 5px 10px;
  border-radius: 0px 0px 4px 4px;
  z-index: 1000;
`

const RealtimeSnapshots = styled.div`

    display: inline-block;
    padding: 0 20px;

    .import-button {
        background: #014059;
        border-color: #014059;

        :active {
            background: #014059 !important;
        }
    }

    .overlay {
        position: absolute;
        z-index: 10;
        border: 1px solid rgba(0, 0, 0, .15);
        box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, .175);
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
    }

    .overlay-body {
        width: 850px;
        height: 500px;
        position: relative;
        overflow-y: scroll;
    }

    .list-group-item {
        color: inherit;
        background-color: white;
    }

    .list-group-item .fw-bold {
        font-weight: 700;
        font-size: 14px;
    }
    
    .list-group {
        padding-top: 10px;
    }

    .badge {
        background-color: green !important;
    }

    .tooltip {
        max-width: 600px;
    }
`

export default props => {

    const [modalOpen, setModalOpen] = useState(false)

    const [error, setError] = useState('')

    const modelContext = useModelContext()
    
    const criteriaContext = useCriteriaContext()

    const realtimeSnapshotGroup = "RS"

    useEffect(() => {
        let timeout
        if (error) {
            timeout = setTimeout(() => {
                setError("")
            }, 3000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [error])



    const hideModal = () => {
        setModalOpen(false)
    }

    const urlPost = (query, data) => {
        let token = getAccessToken()
        return axios({
            url: `custom_metrics?querytype=${query}&restype=json&project=depcom`,
            method: 'POST',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: data
        })
    }



    const deleteMetric = (metric, site) => {
        
        modelContext.setRealtimeMetrics(modelContext.realtimeMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, deleting: true } : t))
        
        urlPost("metric_delete", {site, metric, group_id: realtimeSnapshotGroup})
            .then(() => {
                modelContext.setRealtimeMetrics(modelContext.realtimeMetrics.filter(t => !(t.site === site && t.metric === metric)))
            })
            .catch((err) => {
                setError(err.message)
                modelContext.setRealtimeMetrics(modelContext.realtimeMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, deleting: false } : t))
            })
    }

    

    const shareMetric = (shared, metric, site) => {
        modelContext.setRealtimeMetrics(modelContext.realtimeMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, sharing: true } : t))

        urlPost("metric_share", { shared, metric, site, group_id: realtimeSnapshotGroup })
            .then(() => {
                modelContext.setRealtimeMetrics(modelContext.realtimeMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, shared: shared, sharing: false } : t))
            })
            .catch(err => {
                setError(err.message)
                modelContext.setRealtimeMetrics(modelContext.realtimeMetrics.map(t => (t.site === site && t.metric === metric) ? { ...t, sharing: false } : t))
            })
    }



    const iMetrics = modelContext.realtimeMetrics.filter(t => t.site  === criteriaContext.site && (t.shared || (t.user === getUserEmail())))
    const AllMetrics = <ListGroup as="ol" numbered>
            {iMetrics.map(t => <ListGroup.Item
                as="li"
                className="d-flex justify-content-between"
            >
                <div className="ms-2 me-auto">
                    <div className="fw-bold">{t.metric} <AiFillInfoCircle  style={{fontSize: 20, color: '#014059'}}  data-tip data-for={'info'+t.metric}/> {t.shared ? <Badge pill>Shared</Badge> : null}</div>
                    {/* {t.metricType} */}
                </div>
                <ReactTooltip id={'info'+t.metric} className="tooltip">
                    <span>{t.note}</span>
                </ReactTooltip>
                {t.user === getUserEmail() ? <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                    <Button 
                        className="import-button" 
                        onClick={() => shareMetric(!t.shared, t.metric, t.site)} 
                        disabled={t.sharing}
                    >
                        {(t.shared ? 'Unshar' : 'Shar') + (t.sharing ? 'ing' : 'e')}
                    </Button>
                    <Button 
                        className="import-button"  
                        style={{backgroundColor: '#dc3545', borderColor: "#dc3545"}} 
                        onClick={() => deleteMetric(t.metric, t.site)} 
                        disabled={t.deleting}
                    >
                        {t.deleting ? 'Deleting' : 'Delete'}
                    </Button>
                </div> : null}
            </ListGroup.Item>)}
            
        </ListGroup>

    return !modelContext.error && !modelContext.loading ? <RealtimeSnapshots>
        {iMetrics.length > 0 ? <Button 
            className="import-button" 
            onClick={() => setModalOpen(true)}
        >
            Live Status Snapshots
        </Button> : null}

        {modalOpen ? <Modal.Dialog className="overlay" show={modalOpen}>
            <Modal.Header className="overlay-header" closeButton onHide={hideModal}>
                <Modal.Title>Live Status Snapshots</Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay-body">
                {error ? <InfoStyle><span>{error}</span></InfoStyle> : null}
                {AllMetrics}
            </Modal.Body>

      </Modal.Dialog> : null}
    </RealtimeSnapshots> : null
}