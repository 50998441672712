import { merge } from 'lodash';
import React from 'react';
import { defaults, Line, Scatter } from 'react-chartjs-2';
import './line.css';

defaults.global.maintainAspectRatio = false




function scale(label="", ticks=5, stacked=false ){
    return    [{
        stacked: stacked,
        ticks: {
            fontColor: "#fff",
            maxTicksLimit: ticks,
        },
        scaleLabel: {
            display: true,
            labelString: label,
            fontColor: "#fff"
          }
    }]   
}

function allOptions(options, xLabel, yLabel){
    var optionsBase= {
        responsive: true, // Instruct chart js to respond nicely.
        maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height 
        scales: {
            xAxes: scale(xLabel, 10),
            yAxes: scale(yLabel, 5),
        },
      
    };
    const mergedo =      merge(optionsBase, options)
    //console.log(mergedo)
    return mergedo

}

const LineChart = ({ data, options, xLabel, yLabel }) => {

    return (
        <div className="canvas-container-30">
            <Line data={data} options={allOptions(options, xLabel, yLabel)} />
        </div>
    )

}

export const LineChart2 = ({ data, options, chartheightClass }) => {
    const zlc_height = isNaN(chartheightClass) ? {} : { height: "500px" }
    const canvas_class = isNaN(chartheightClass) ? 'canvas-container-30' : ''
    return (
        <div className="canvas_class" style={zlc_height}>
            <Line data={data} options={options} />
        </div>
    )

}

export const ScatterChart22 = ({ data, options, xLabel, yLabel }) => {

    return (
        <div className="canvas-container-30">
            <Scatter data={data} options={allOptions(options, xLabel, yLabel)} />
        </div>
    )

}

export default LineChart
