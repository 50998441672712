import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import HailSettings from './HailStowIndex'; 
import Analytics from './Analytics';
import './hail.css';


const HailSettingsTabs = () => {
    // State variable to manage active tab
  const [activeTab, setActiveTab] = useState('hailSettings');

    // Function to handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Tabs activeKey={activeTab} onSelect={handleTabChange}>
        <Tab eventKey="hailSettings" title="Settings" style={{ backgroundColor: 'white' }}>
          <div>
            <HailSettings />
          </div>
        </Tab>
        <Tab eventKey="analytics" title="Analytics" style={{ backgroundColor: 'white' }}>
          <div>
            <Analytics />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};


export default HailSettingsTabs;


