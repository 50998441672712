export class IndexDB {
    constructor() {
        window.INDEXDB_SETUP = "progress"
        if (!window.indexedDB) {
            console.log('Your browser doesn\'t support IndexedDB');
            window.INDEXDB_SETUP = "error"
        }
        this.openDB = window.indexedDB.open("scenes", 1);
        this.openDB.onupgradeneeded = function (event) {
            // The database did not previously exist, so create object stores and indexes.
            this.db = event.target.result;
            let oldVer = event.oldVersion;
            if (oldVer <= 1) {
                // to save whole obj
                this.pvLayoutObjStore = this.db.createObjectStore("pvlayoutObj", { keyPath: "id" });
                const pvlayout_idIndex = this.pvLayoutObjStore.createIndex("id", "id", { unique: true});
                // const pvlayout_nameIndex = this.pvLayoutObjStore.createIndex("name", "name", { unique: true});

                this.pvlayoutStore = this.db.createObjectStore("pvlayout", { keyPath: ["uuid"] });
                const pvlayout_objIndex = this.pvlayoutStore.createIndex("objId", "objId", { unique: true });
                const pvlayout_uuidIndex = this.pvlayoutStore.createIndex("uuid", "uuid", { unique: true });

                // Populate with initial data.
                this.pvlayoutStore.put({
                    'objId': 0, 'uuid': '7acb5072-7ebe-4969-9c53-test', 'type': 'Object3D', 'name': 'layout_mesh_test', 'matrix': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], 'position': { x: 0, y: 0, z: 0 }
                });

                this.pvlayout_mesh_Store = this.db.createObjectStore("pvlayout_mesh", { keyPath: ["uuid", "pvlayout_mesh_uuid"] });
                this.pvlayout_mesh_nameIndex = this.pvlayout_mesh_Store.createIndex("name", "name", { unique: true });
                this.pvlayout_mesh_uuidIndex = this.pvlayout_mesh_Store.createIndex("uuid", "uuid", { unique: true });
                this.pvlayout_mesh_name_uuidIndex = this.pvlayout_mesh_Store.createIndex("uuid-name", ["uuid", "name"], { unique: true });

                // Populate with initial data.
                this.pvlayout_mesh_Store.put({
                    'position': { x: -5.0010101, y: 0, z: -1.10010101 }, 'uuid': 'd9c944b2-d4a7-4797-b216-test', 'type': 'Mesh', 'name': 'mesh_182_instance_test', 'matrix': [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -5.016109943389893, 0, -1.1005522012710571, 1], 'pvlayout_mesh_uuid': '7acb5072-7ebe-4969-9c53-test', 'geometryPosition': [0.002134, 0.003455, -0]
                });
            }
        };

        this.openDB.onsuccess = function (event) {
            console.log("successfully created db and added initial records")
            window.INDEXDB_SETUP = "success"
        };

        this.openDB.onerror = function (event) {
            console.log("IndexDB error");
            window.INDEXDB_SETUP = "error"
        }
    }

    /**
     * this will fetch the whole pvlayout object by ID
     * @param name mesh name
     * @returns whole mesh details 
     */
    async getPvlayoutObjById(id) {
        let requestGetPvlayout = this.openDB
        let response = {
            'id': '', 'obj': ''
        };

        const txGetPvlayoutObj = requestGetPvlayout.result.transaction("pvlayoutObj", "readwrite");
        let storeGetPvlayoutObj = txGetPvlayoutObj.objectStore('pvlayoutObj')
        const index = storeGetPvlayoutObj.index("id");

        return new Promise((resolve, reject) => {
            index.get(id).onsuccess = (event) => {
                // console.log(`Pvlayout Object deatils is ${event.target.result.id}`);
                const result = event.target.result
                if (result) {
                    response['id'] = event.target.result.id;
                    response['obj'] = event.target.result.obj;
                    resolve(response);
                } else {
                    resolve(null)
                }
            };
    
            index.get(id).onerror = (event) => {
                // No match was found.
                console.log(`no match found`);
                reject(null)
            }
    
            requestGetPvlayout.oncomplete = function () {
                this.db.close();
            };
        })


        // return response;
    };

    /**
     * this will fetch the data matched with the mesh name
     * @param name mesh name
     * @returns whole mesh details 
     */
    getMeshGeometryByName(name) {
        let requestGetGeometry = this.openDB
        let response = {
            'position': {}, 'uuid': '', 'type': '', 'name': '', 'matrix': [], 'pvlayout_mesh_uuid': '', 'geometryPosition': []
        };
        
        const txGetGeometry = requestGetGeometry.result.transaction("pvlayout_mesh", "readwrite");
        let storeGetGeometry = txGetGeometry.objectStore('pvlayout_mesh')
        const index = storeGetGeometry.index("name");

        index.get(name).onsuccess = (event) => {
            // console.log(`Geometry deatils is ${event.target.result.uuid}`);
            response['uuid'] = event.target.result.uuid;
            response['position'] = event.target.result.position;
            response['type'] = event.target.result.type;
            response['name'] = event.target.result.name;
            response['matrix'] = event.target.result.matrix;
            response['pvlayout_mesh_uuid'] = event.target.result.pvlayout_mesh_uuid;
            response['geometryPosition'] = event.target.result.geometryPosition;
        };

        index.get(name).onerror = (event) => {
            // No match was found.
            console.log(`no match found`);
        }
        
        requestGetGeometry.oncomplete = function () {
            this.db.close();
        };

        return response;
    };

    getLayoutByName(name) {
        let requestGetLayout = this.openDB
        let response = {
            'uuid': '', 'type': '', 'name': '', 'matrix': [], 'position': {} 
        };

        const txGetLayout = requestGetLayout.result.transaction("pvlayout", "readwrite");
        let storeGetLayout = txGetLayout.objectStore('pvlayout')
        const index = storeGetLayout.index("name");

        index.get(name).onsuccess = (event) => {
            console.log(`Layout deatils is ${event.target.result.uuid}`);
            response['uuid'] = event.target.result.uuid;
            response['type'] = event.target.result.type;
            response['name'] = event.target.result.name;
            response['matrix'] = event.target.result.matrix;
        };

        index.get(name).onerror = (event) => {
            // No match was found.
            console.log(`no match found`);
        }

        requestGetLayout.oncomplete = function () {
            this.db.close();
        };

        return response;
    };

    getLayoutByUuid(uuid) {
        let requestGetLayout = this.openDB
        let response = {
            'uuid': '', 'type': '', 'name': '', 'matrix': [], 'position': {}
        };

        const txGetLayout = requestGetLayout.result.transaction("pvlayout", "readwrite");
        let storeGetLayout = txGetLayout.objectStore('pvlayout')
        const index = storeGetLayout.index("uuid");

        index.get(uuid).onsuccess = (event) => {
            console.log(`Mesh deatils is ${event.target.result.uuid}`);
            response['uuid'] = event.target.result.uuid;
            response['type'] = event.target.result.type;
            response['name'] = event.target.result.name;
            response['matrix'] = event.target.result.matrix;
        };

        index.get(uuid).onerror = (event) => {
            // No match was found.
            console.log(`no match found`);
        }

        requestGetLayout.oncomplete = function () {
            this.db.close();
        };

        return response;
    };

    /**
     * this will add/insert data into index db
     */
    add(dbRequest, data, id) {
        // let requestNewAdd = this.openDB;
        // let requestAll[] = [];
        
        // Create transactional obj
        const transaction1 = dbRequest.result.transaction("pvlayout", "readwrite");
        // Get the object stores for each transaction
        const store1 = transaction1.objectStore("pvlayout");

        const transaction2 = dbRequest.result.transaction("pvlayout_mesh", "readwrite");
        const store2 = transaction2.objectStore("pvlayout_mesh");

        // Perform the requests
        for (let i = 0; i < data.length; i++) {
            let tmpData = {
                'objId': id,
                'uuid': data[i].uuid,
                'type': data[i].type,
                'name': data[i].name,
                'matrix': data[i].matrix,
                'position': data[i].position
            }
            const request = store1.add(tmpData);

            request.onsuccess = () => {
                // console.log(`New pvlayout added, uuid: ${request.result}`);
            }

            request.onerror = (err) => {
                // console.log(err);
                // console.error(`Error to add new pvlayout duplicate index entry`)
            }
       
            for (let j = 0; j < data[i].children.length; j++) {
                let tmpDataChildren = {
                    'uuid': data[i].children[j].uuid,
                    'type': data[i].children[j].type,
                    'name': data[i].children[j].name,
                    'matrix': data[i].children[j].matrix,
                    'position': data[i].children[j].position,
                    'pvlayout_mesh_uuid': data[i].uuid,
                    'geometryPosition': data[i].children[j].geometry.attributes.position.array
                }
                const request = store2.add(tmpDataChildren);

                request.onsuccess = () => {
                    // console.log(`New pvlayout mesh added, uuid: ${request.result}`);
                }

                request.onerror = (err) => {
                    // console.log(err);
                    // console.error(`Error to add new pvlayout mesh duplicate entry of index`)
                }
            }
        }
    }

    addObj(id, data) {
        let dbRequest = this.openDB
        
        if (!dbRequest.result)
            return
        // Create transactional obj
        const transactionObj = dbRequest.result.transaction("pvlayoutObj", "readwrite");
        // Get the object stores for each transaction
        const storeObj = transactionObj.objectStore("pvlayoutObj");

        // Perform the requests
        let tmpData = {
            'id': id,
            'obj': data
        }
        const requestObj = storeObj.add(tmpData);

        requestObj.onsuccess = () => {
            console.log(`New pvlayout object added, email: ${requestObj.result}`);
        }

        requestObj.onsuccess = (err) => {
            // console.error(`Error to add new pvlayout object duplicate index entry`)
        }
    }
}