export default {
    'BLE_Fault_21:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Battery_Charger_Fault_19:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'Board_temperature_exceed_Fault_04:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'Communication_Fault_08:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0,1] 
    },
    'EEPROM_Fault_12:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Estop_Fault_20:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'External_EEPROM_Fault_22:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Inclinometer_Fault_09:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0,1] 
    },
    'Locked_track_move_Fault_16:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Locking_system_Fault_15:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Low_Battery_Stow_17:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0,1] 
    },
    'Maintainence_block_33:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0,1] 
    },
    'Mechanical_Overload_Fault_18:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'Mechanical_Overload_Occurred_35:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0,1] 
    },
    'Minimum_battery_voltage_Fault_02:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0,1] 
    },
    'Motor_stall_Fault_06:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'OTA_Fault_11:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'Over_current_Fault_01:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'RTC_Fault_13:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0,1] 
    },
    'SPI_Flash_Memory_Fault_10:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0,1] 
    },
    'Set_Command_Flag_34:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'Undefined_Fault_03:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_05:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_10:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'Undefined_Fault_17:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_18:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0,1] 
    },
    'Undefined_Fault_19:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_20:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_21:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_22:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_23:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_24:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_25:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_26:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_27:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_28:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_29:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_30:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_31:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Fault_32:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Undefined_Ignore_This_00:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'Unknown_Fault_14:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'Zigbee_Fault_07:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [0] 
    },
    'rover_offline:AR': {
        group_id: 'AR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'backTracking:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'batteryVoltage:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'bifacialTracking:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'boardTemp:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'bq:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'bqConnect:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'bqUpload:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'commandQueue:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'dbConnection:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'dbStorage:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'diffusedTracking:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'diffusedTrackingReachable:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'diffusedTrackingService:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'dnsmasq:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'flood:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'floodSensorConnected:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'floodStowSet:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'heartBeatXbee:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'miscAlerts:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'modbus:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'mongodb:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'mosquitto:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'nginx:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'periodicRequests:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'pvVoltage:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'reboot:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0] 
    },
    'shutdown:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'snow:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'snowSensorConnected:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'snowStowSet:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'systemStorage:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'timePublisher:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'webApp:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'wind:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'windSensorConnected:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'windStowSet:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'wlan0:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'wlan1:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'xbeeBackendApi:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'zcEngine:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'zcMqttHelper:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'zcVoltageIndicator:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [0,1] 
    },
    'zigbee:AZ': {
        group_id: 'AZ', 
        valueType: 'Binary', 
        level: 'Zone', 
        range: [1,0] 
    },
    'batteryCurrent:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [0.9,0.34,1.09,2.13,2.38,2.5,1.22,1.85,0.33,1,1.88,2.34,2.36,0.86,0.69,3.36,1.67,2.15,1.83,1.28] 
    },
    'batteryVoltage:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [16.86,31.82,31.97,21.5,34.18,23.87,15.12,12.04,30.31,13.79,15.17,34.45,29.17,1.3,7.24,26.64,11.45,33.85,28.22,10.12] 
    },
    'battery_temp:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [45.22,41.8,14.06,50.61,57.86,55.77,81,65.99,38.42,6.44,1.23,87.98,81.83,-2.71,51.4,3.2,-0.6,80.97,24.35,34.95] 
    },
    'calculatedAngle:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [26.5,55.53,-54.51,50.78,-6.64,3.99,4.86,-13.95,-25.25,-46.13,18.97,5.48,36.87,20.54,-53.15,-32.28,-27.25,-40.68,-7.67,-25.75] 
    },
    'currentAngle:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [1.11,-10.59,7.75,-11.84,-44.89,59.85,7.84,-12.94,46.06,-55.25,47.82,56.8,57.27,-39.94,-31.88,-39.15,3.61,-15.68,21.5,-16.26] 
    },
    'currentMode:SR': {
        group_id: 'SR', 
        valueType: 'Encoded', 
        level: 'Row', 
        range: [7,8,17,6,4,2,5,11,12,3,9,1,15] 
    },
    'drive_temp:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [20.06,13.23,11.7,40.05,94.74,105.45,40.9,48.63,55.81,-85.34,3.42,4.09,71.38,62.92,101.96,84.7,96.48,-4.1,81,78.1] 
    },
    'eastLimit:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [52.35] 
    },
    'errorCode:SR': {
        group_id: 'SR', 
        valueType: 'Encoded', 
        level: 'Row', 
        range: [1,0,2,4,6,7,8,9,10,11,12,13,14,15,16,17,33,34,35] 
    },
    'loadCurrent:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [2.63,0.06,9.18,4.72,3.97,7.97,12.75,4.36,7.78,10.11,8.46,4.29,8.96,13.14,1.22,10.84,3.72,11.91,7.35,3.15] 
    },
    'motor:SR': {
        group_id: 'SR', 
        valueType: 'Encoded', 
        level: 'Row', 
        range: [0,1,-1] 
    },
    'pvCurrent:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [3.3,0.97,2.19,0.33,1.15,1.45,2.22,2.95,0.95,0.99,0.36,0.72,2.27,2.31,0.52,1.99,2.24,0.61,1.82,0.76] 
    },
    'pvVoltage:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [35.76,34.95,1.46,15.99,40.4,36.33,8.29,2.49,32.44,30.89,14.38,35.22,10.45,11.29,6.11,15.71,17.53,31.58,44.02,11.74] 
    },
    'spa_next_timestamp:SR': {
        group_id: 'SR', 
        valueType: 'Epoch', 
        level: 'Row', 
        range: [] 
    },
    'spa_next_value:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [3.45,-2.84,-5.79,33.33,-37.35,-38.03,-3.31,-24.37,-0.46,-18.64,26.25,5.05,3.11,20.58,17.48,-8.22,-23.01,31.27,2.43,-36.61] 
    },
    'spa_present_timestamp:SR': {
        group_id: 'SR', 
        valueType: 'Epoch', 
        level: 'Row', 
        range: [] 
    },
    'spa_present_value:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [17.29,3.06,35.48,-10.74,-33.22,31.16,-11.05,-12.63,-46.55,-29.15,-27.29,-9.95,-12.29,43.22,-10,32.18,18.59,-47.59,-44.59,40.97] 
    },
    'spa_status:SR': {
        group_id: 'SR', 
        valueType: 'Encoded', 
        level: 'Row', 
        range: [1,0,2] 
    },
    'uptime_or_downtime:SR': {
        group_id: 'SR', 
        valueType: 'Binary', 
        level: 'Row', 
        range: [1,0] 
    },
    'westLimit:SR': {
        group_id: 'SR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [28.71] 
    },
    'batteryCurrent:VR': {
        group_id: 'VR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [0.48,1.1,0.08,1.36,0.64,0.54,1.31,1.09,0.07,0.93,0.41,0.26,1.49,1.37,0.52,0.81,0.03,1.71,0.88,0.91] 
    },
    'batteryVoltage:VR': {
        group_id: 'VR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [22.69,24.42,25.03,23.39,24.74,25.95,23.73,24.38,22.86,23.19,24.98,24.97,24.69,24.82,23.32,24.03,25.34,23.49,25.3,24.28] 
    },
    'calculatedAngle:VR': {
        group_id: 'VR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [13.99,-48,38.17,-44,8.13,11,24.97,-52.93,18.86,42,33,1,28.72,-33.19,6,15.99,19.08,-30,-1,32.57] 
    },
    'loadCurrent:VR': {
        group_id: 'VR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [0.64,3.45,3.03,3.94,5.56,4.97,5.15,1.82,4.93,4.35,0.51,2.86,4.72,0.62,0.37,3.13,3.4,1.46,1.97,4.47] 
    },
    'pvCurrent:VR': {
        group_id: 'VR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [1.09,0.86,1.85,2.41,0.9,2.47,1.54,0.66,0.75,3.4,2.49,0.48,0.67,1.6,2.92,3.36,0.13,0.91,1.44,2.3] 
    },
    'pvVoltage:VR': {
        group_id: 'VR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [37.14,1.5,14.46,13.39,39.8,18.92,34.05,8.09,33.55,2.75,23.81,12.3,12.23,32.09,38.35,0.9,26.86,20.14,19.48,1.96] 
    },
    'FLOOD_LEVEL:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [984,351,250,29,277,481,135,172,1287,376,979,206,464,468,454,432,55,1286,1280,1208] 
    },
    'SNOW_FALL:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [102,321,3568,13.05,3053,3102,66.45,3286,2776,2703,4144,107.51,3795,3812,56.9,129.01,4.4,68.01,32.6,39.02] 
    },
    'WIND_DIRECTION:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [314,142.4,137.8,36.79,24.5,278.5,31.7,281.1,104.6,285.8,35.9,222.9,197.7,14.5,9.4,54.2,230.8,37.9,256,98.9] 
    },
    'WIND_SPEED:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [7.64,42.38,38.11,23.39,28.94,33.22,5.47,23.17,0.66,31.93,9.32,3.35,8.09,14.91,41.57,21.78,11.4,33.09,22.15,23.47] 
    },
    'cpuCore1Usage:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [91.1,55.6,39.3,55,24.6,47.9,15.7,83,44,81.7,25.6,32.2,29.4,51.9,87,58.8,57.7,16.2,63.4,7.6] 
    },
    'cpuCore2Usage:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [41.6,7.2,20.5,58.9,67.3,87.5,41.1,58.5,10.8,93.1,23.5,96.3,23.4,3.8,44.8,76.6,69.6,36.5,0.5,53.5] 
    },
    'cpuCore3Usage:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [19.3,95.1,21.2,71.2,31.5,82.9,25.2,6.2,17.6,20.3,15.8,37.3,21.3,61,8.9,95.3,42.4,80,31.4,56.3] 
    },
    'cpuCore4Usage:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [89.2,24.6,87.5,53,45.6,3.5,36.6,65.1,87.2,78.7,64,51.3,93.4,46.9,90.5,11.8,22.2,93,43.1,5.8] 
    },
    'diskStorageOccupied:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [52,40,72,45,34,94,56,51,62,49,81,54,98,84,15,71,50,74,88,16] 
    },
    'ramAvailable:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [433.012736,324.800512,401.526784,180.011008,74.952704,380.792832,246.591488,275.238912,301.248512,192.12288,349.274112,152.12544,152.133632,476.946432,83.808256,168.886272,91.365376,275.218432,84.058112,131.837952] 
    },
    'rpiCoreVoltage:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [1.3312,1.3563,1.3813,1.3438,1.2625,1.2,1.3625,1.3125,1.25,1.3188,1.3938,1.3,1.2438,1.2375,1.2875,1.375,1.2563,1.275,1.2813,1.325] 
    },
    'temperature:VZ': {
        group_id: 'VZ', 
        valueType: 'Dynamic', 
        level: 'Zone', 
        range: [11,84,63,57,65,86,20,60,21,50,52,48,37,85,46,47,55,53,15,34] 
    },
    'positionalAccuracy:PR': {
        group_id: 'PR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [0,100] 
    },
    'trackerAvailability:PR': {
        group_id: 'PR', 
        valueType: 'Dynamic', 
        level: 'Row', 
        range: [0,100] 
    },
}