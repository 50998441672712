import React, { useEffect, useState, useRef } from 'react'
import { 
    ListGroup, 
    ListGroupItem, 
    ListGroupItemHeading, 
    ListGroupItemText,
    Badge,
    Button
} from 'reactstrap'
import RemindLater from './remindLater'
import styled from 'styled-components'
import { AiOutlineBell } from 'react-icons/ai'
import { isAdminUser } from '../auth/userinfo'
import ReleaseNotes from '../releases/releasenotes'
import { useNotificationContext } from "../../context/notification.context"

const Link = styled.span`
    color: white;
    font-weight: 600;
    font-size: 15px;
    margin-right: 8px;
    position: relative;

    cursor: pointer;

    .icon {
        margin-left: -3px;
        margin-top: -1px;
    }

    .bell-icon {
        font-size: 32px;
        margin-top: -1px;
        margin-right: -2px;
    }
    
    .new {
        position: absolute;
        left: 16px;
        top: -8px;
    }
`

const Summary = styled.div`
    width: 500px;
    position: absolute;
    right: 4px;
    top: 42px;
    background-color: white;
    padding: 20px;
    border: 0px solid rgba(0, 0, 0, .15);
    box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, .175);
    max-height: 764px;
    padding-bottom: 0px;
    overflow: scroll;

    &:before {
        content: '';
        width: 0;
        height: 0;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        border-bottom: 32px solid white;
        top: -10px;
        right: 0px;
        position: absolute;
    }

    .list-item {
        cursor: pointer;
        background: white; 
        color: #014059;
        border: none;
        border-bottom: 1px solid lightgray;
        padding: 16px 4px;

        :hover {
            background: #01405905;
        }
    }

    .list-heading {
        color: #014059;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 16px;
    }

    .list-header {
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .list-group-item-heading, .message {
        font-size: 18px;
    }

    .list-group-item-text {
        font-size: 14px;
    }

    .details-link, .form-control {
        font-size: 16px;
        color: #014059;
    }

    .rdt {
        font-size: 14px;
    }

    .details-link:hover {
        text-decoration: underline;
        cursor: pointer
    }
    
    .list-item-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .new-release {
        margin-top: -12px;
    }


    button {
        font-size: 16px;
    }

    .titles {
        display: flex;
        flex-direction: column;
    }

    button.later {
        background: lightgray;
        border: lightgray;
        :active {
            background: lightgray !important;
        }
    }

    .actions {
        display: flex;
        gap: 16px;
    }

    .status {
        gap: 8px;
        padding: 8px 0px;
        display: flex;
        flex-direction: row;
        font-size: 16px;
    }
`

export default props => {
    
    const notificationContext = useNotificationContext()
    const { releases, error, newEntry, readNewEntry, notifications, removeNotification } = notificationContext;
    
    const [showSummary, setShowSummary] = useState(false)
    const [showNotes, setShowNotes] = useState(false)
    const [allReleases, setAllReleases] = useState([])
    const [selectedRelease, setSelectedRelease] = useState(null)
    const summaryRef = useRef()

    const isAdmin = isAdminUser()

    const setSnoozeMode = (id, mode) => {
        setAllReleases(allReleases.map(t => t.id === id ? {...t, snooze: mode} : t))
    }


    const closeSummary = (event) => {
        if (summaryRef.current && !summaryRef.current.contains(event.target)) {
            readNewEntry()
            setShowSummary(false)
        }
    }

    const openSummary = () => {
        if (showSummary === true)
            readNewEntry()
        setShowSummary(!showSummary) 
    }

    const closeReleaseNotes = () => {
        setShowNotes(false)
    }
    
    const openReleaseNotes = (selected) => {
        setSelectedRelease(selected)
        setShowNotes(true)
        readNewEntry()
        setShowSummary(false)
    }

    useEffect(() => {
        document.addEventListener('click', closeSummary, true)

        return () => {
            document.removeEventListener('click', closeSummary, true)
        }
    }, [])

    useEffect(() => {
        if (releases.length > 0 || notifications.length > 0) {
            const r = releases.map(t => ({...t, isRelease: true}))
            setAllReleases([...r.map((t, i) => newEntry && i === 0 ? {...t, new: true} : t), ...notifications].sort((t1, t2) => {
                return t1.published_at > t2.published_at ? -1 : 1
            }))
        }
    }, [releases, notifications, newEntry])

    return <React.Fragment>
        <div style={{position: 'relative'}} ref={summaryRef}>
            <Link onClick={openSummary}>
                <AiOutlineBell className="bell-icon" />
                {newEntry ? <Badge className="new" color="red">New</Badge> : null}
            </Link>
            {showSummary ? <Summary>
                <div className="list-header">
                    <span className="list-heading">Notifications</span>
                    {isAdmin ? <span className="details-link" onClick={() => openReleaseNotes(null)}>Release Notes</span> : null}
                </div>
                <ListGroup>
                    {error ? <span style={{color: 'red'}}>{error}</span> : null}
                    {allReleases.length > 0 ? allReleases.slice(0, 6).map((t,i) => {
                        const content = t.content ? t.content.getCurrentContent() : null
                        const text = content ? content.getPlainText() : null
                        return t.snooze ? <ListGroupItem  className="list-item">
                                <RemindLater 
                                    callback={() => removeNotification(t.id)}
                                    timezone={t.timezone}
                                    cancel={() => setSnoozeMode(t.id, false)}
                                />
                            </ListGroupItem> : <ListGroupItem className="list-item">
                            <div className="list-item-header">
                                <ListGroupItemHeading style={{fontWeight: text === null ? 300 : 'auto'}}>
                                    {typeof t.title === 'object' ? <div className="titles">
                                        {t.title.map((h,i) => <span>{i !== 0 ? " - " : ""}{h}</span>)}
                                    </div> : <span>{t.title}</span>} {t.new ? <Badge color="red" className="new-release">New</Badge> : null}</ListGroupItemHeading>
                                {t.publishedDate ? <span className="published-date">{t.publishedDate}</span> : null}
                            </div>
                            <ListGroupItemText>
                                {text ? text.slice(0, 60) + (text.length > 60 ? ".." : "") : ""}
                            </ListGroupItemText>
                            <div className="actions">
                                {t.action && !t.notAllowLaterAction  ?  <Button 
                                        onClick={() => {
                                            t.action()
                                            removeNotification(t.id)
                                            setShowSummary(false)
                                        }}
                                    >{t.actionText}</Button> : null}
                                {t.isRelease ? <Button 
                                        onClick={() => openReleaseNotes(t)}
                                    >View Details</Button> : null
                                }
                                {t.remindLater ? <Button  
                                    className= "later"

                                    onClick={() => setSnoozeMode(t.id, true)}
                                >Remind Later</Button> : null}
                            </div>
                            {t.status ? <div className="status">
                                <span>Job status:</span> 
                                <span><b>{t.status}</b></span>
                            </div>  : null}
                        </ListGroupItem>
                    }) : !error ? 'No release found' : null}
                </ListGroup>
            </Summary> : null}
            </div>
            <ReleaseNotes showNotes={showNotes}  closeReleaseNotes={closeReleaseNotes} releases={selectedRelease ? [selectedRelease] : null}/>
        </React.Fragment>
}