import { toJS } from "mobx";
import React, { useState, useEffect, useMemo } from "react";
import { Form, Button, Modal, ListGroup, Badge } from "react-bootstrap";
import { EditFieldStyle } from "./editchart";
import { VscCopy, VscFile } from "react-icons/vsc";
import {LiaEditSolid} from 'react-icons/lia'
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { useCriteriaContext } from "../context/criteria.context";
import { SingleSelectDropDownControl } from "../components/controls/multiselect_control";
import {
  frequencyOptions,
  timeRange,
  featureOptions,
  organization_mapping,
  viewOptions,
  valueOptions,
  dataFormatOptions,
} from "../constant/constant";
import { usePageContext } from "../context/page.context";
import {
  getRecipientEmailIds,
  scheduleReport,
  generateReport,
  deleteReport,
  getReports,
  updateReport,
  getCrumbsData,
  updateReportStatus
} from "../utils/apiCall";
import {
  getUserOrg,
  getUserId,
  getUserPortfolios,
  getAccessToken,
  getUserEmail
} from "../components/auth/userinfo";
import { GetFQURLs } from "../components/model/store.swr";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { InfoTooltip } from "../model/info-tooltip";
import ReactDOMServer from "react-dom/server";
import { MdOutlineFileDownload } from "react-icons/md";
import { CSVLink } from "react-csv";
import _ from "lodash";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import { CriteriaTimeFormat } from "../components/layout/criteria";
import { getLevel, getSiteLocalTime } from "../helpers/common";
import { useUserContext } from "../context/user.context";
import { getChartsAsOptions, getMetricsAsOptions, filterMetricsAsPerCriteria } from "../model/metadata";
import { getComp2 } from "../echarts/echarts.render";
import { getChartCompValue2 } from "../components/layout/usercharts";
import { getGroupType, downloadMetricsData } from "./metrics_download";
import { encodeFilename } from "../model/combined";
import BootstrapTable from 'react-bootstrap-table-next';
import { RiDeleteBin7Line } from "react-icons/ri";
import ReactTooltip from "react-tooltip";
import { MdFreeCancellation } from "react-icons/md";



const Header = (props) => {
  const { activeTab, setActiveTab } = props;
  return (
    <div className="tabRow">
      <ul className="nav nav-pills">
        <li className="nav-item" onClick={() => setActiveTab(1)}>
          <label className={"nav-link " + (activeTab === 1 ? "active" : "")}>
            Schedule New Report
          </label>
        </li>
        <li className="nav-item" onClick={() => setActiveTab(2)}>
          <label className={"nav-link " + (activeTab === 2 ? "active" : "")}>
            Edit Scheduled Reports
          </label>
        </li>
        <li className="nav-item" onClick={() => setActiveTab(3)}>
          <label className={"nav-link " + (activeTab === 3 ? "active" : "")}>
            Reports
          </label>
        </li>
      </ul>
    </div>
  );
};

const ActiveTabOneOptions = (props) => {
  const { newChartOption, setNewChartOption, resetFormData } = props;
  return (
    <div className="row newChartOption">
      <div
        className={"col-2 options " + (newChartOption === 1 ? "active" : "")}
        onClick={() => {
          setNewChartOption(1);
        }}
      >
        <VscFile size={20} />
        <label>From Scratch</label>
      </div>
      <div
        className={"col-2 options " + (newChartOption === 2 ? "active" : "")}
        onClick={() => {
          setNewChartOption(2);
          resetFormData();
        }}
      >
        <VscCopy size={20} />
        <label>Clone Existing</label>
      </div>

      {/* <div
            className={
              "col-2 options " + (newChartOption === 3 ? "active" : "")
            }
            onClick={() => {
              setNewChartOption(3);
            }}
          >
            <VscCopy size={20} />
            <label>Shared Charts</label>
          </div> */}
    </div>
  );
};

const ReportTable = (props) => {
  const {reports, handleReportSelect, setActiveTab, setNewChartOption, setSelectedReport, handleReportDelete, handleReportActiveStatus} = props
  let reportData = _.cloneDeep(reports)
  reportData = reportData.map(r => {
    r.recipients = JSON.parse(JSON.parse(r.recipients)[0]).join(", ")
    if(r.view === 'Overview' || r.view === 'My Dashboards'){
      r.feature = JSON.parse(JSON.parse(r.feature)[0])
    }else if(r.view === 'Downloads'){
      r.feature = JSON.parse(JSON.parse(r.feature)[0]).map(ele => ele.metric).join(", ")
    }

    r.lastsent_at = r.lastsent_at ? moment(r.lastsent_at).format("YYYY-MM-DD HH:mm:ss") : r.lastsent_at//new Date(r.lastsent_at).toString()
    return r
  })

  reportData = reportData.sort((a, b) => b.updated_at - a.updated_at);

  const styles = {
    wrapText: {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      maxWidth: '200px', /* Set a maximum width for the column */
    }

    
  }

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log("clicking row...",row)
    return (
      <>
        <span data-tip data-for='clone-report' onClick={() => {setNewChartOption(2); setActiveTab(1); setTimeout(() => {handleReportSelect({value: row.title, label: row.title, isClone: true})},1000)}}>
          <VscCopy size={20} style={{color: '#01668D', strokeWidth: '1px', margin: '10px'}}/> 
        </span>
        <ReactTooltip textColor='#014059' backgroundColor='#B7D0DA' className='sidebar-tooltip' id='clone-report' place="top" type="light" effect="solid" >
          <span>Clone this report</span>
        </ReactTooltip>
        <span data-tip data-for='edit-report' onClick={() => {setActiveTab(2); setTimeout(() => {handleReportSelect({value: row.title, label: row.title})},500)}}>
          <LiaEditSolid size={20} style={{color: '#01668D', strokeWidth: '1px' , margin: '10px'}}/>
        </span>
        <ReactTooltip textColor='#014059' backgroundColor='#B7D0DA' className='sidebar-tooltip' id='edit-report' place="top" type="light" effect="solid" >
          <span>Edit this report</span>
        </ReactTooltip>
        <span data-tip data-for='delete-report' onClick={() => handleReportDelete(row)}>
          <RiDeleteBin7Line size={20} style={{color: '#01668D', strokeWidth: '1px', margin: '10px'}}/>
        </span>
        <ReactTooltip textColor='#014059' backgroundColor='#B7D0DA' className='sidebar-tooltip' id='delete-report' place="top" type="light" effect="solid" >
          <span>Delete this report</span>
        </ReactTooltip>
        <span data-tip data-for='schedule-cancel-report' onClick={() => handleReportActiveStatus(row)}>
          <MdFreeCancellation size={20} style={{color: '#01668D', margin: '10px'}}/>
        </span>
        <ReactTooltip textColor='#014059' backgroundColor='#B7D0DA' className='sidebar-tooltip' id='schedule-cancel-report' place="top" type="light" effect="solid" >
          <span>Schedule/Cancel this report</span>
        </ReactTooltip>
      </>
    );
  };

  const columns = [{
      dataField: 'title',
      text: 'Title',
      headerStyle: {width: '15%'},
      style: styles.wrapText
    },{
      dataField: 'frequency',
      text: 'Frequency',
      headerStyle: {width: '15%'}
    },
    {
      dataField: 'day_range',
      text: 'Last n days',
      headerStyle: {width: '15%'}
    },
    {
      dataField: 'recipients',
      text: 'Members',
      headerStyle: {width: '15%'},
      style: styles.wrapText
    },{
      dataField: "feature",
      text: "Chart/Metrics",
      headerStyle: {width: '15%'},
      style: styles.wrapText
    },{
      dataField: "is_active",
      text: "Active",
      headerStyle: {width: '15%'},
      style: styles.wrapText
    },{
      dataField: "lastsent_at",
      text: "Sent At",
      headerStyle: {width: '15%'},
      style: styles.wrapText
    },{
      dataField: 'actions',
      text: 'Actions',
      formatter: actionFormatter,
      headerStyle: {width: '25%'}
      // headerStyle: (colum, colIndex) => {
      //   return { width: '150px', textAlign: 'center' };
      // }
    }
    
  ];

  const rowClasses = (row, rowIndex) => {
    return 'custom-row-class';
  };

  // const headerStyle = {}

  console.log("report data....",reportData)
  return  <div className="report-table">
    <BootstrapTable keyField='title' columns={ columns } data={reportData}/>
    <style>{
      `
        .report-table{
          max-height: 400px; 
          overflow: auto;
        }
        .report-table thead tr{
          position: sticky;
          top: 0;
          background-color: #F7F8F9;
          z-index: 2;
        }`}</style>
  </div>
}

const ReportEditor = () => {
  const criteria = useCriteriaContext();
  const pageContext = usePageContext();
  const [portfolio, setPortfolio] = useState({
    value: criteria.pf,
    label: criteria.pf,
  });
  const [site, setSite] = useState({
    label: criteria.site,
    value: criteria.site,
  });
  const [device, setDevice] = useState({
    label: criteria.device,
    value: criteria.device,
  });
  const [zone, setZone] = useState({
    label: criteria.zone,
    value: criteria.zone,
  });
  const [activeTab, setActiveTab] = useState(1);
  const [emailIds, setEmailIds] = useState();
  const [recipients, setRecipients] = useState([]);
  const [feature, setFeature] = useState();
  const [recipientList, setRecipientList] = useState([]);
  const [error, setError] = useState("");
  const [newChartOption, setNewChartOption] = useState(1);
  const [urls, setUrls] = useState([]);
  const [response, setResponse] = useState("");
  const [range, setRange] = useState(1);
  const [dayMetric, setDayMetric] = useState(false);
  const [msgStyle, setMsgStyle] = useState("success");
  const [message, setMessage] = useState("");
  const [frequency, setFrequency] = useState();
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Schedule Report");
  const [btnText2, setBtnText2] = useState("Download Sample");
  const [reports, setReports] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [report, setReport] = useState();
  const [title, setTitle] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");
  const [selectedReport, setSelectedReport] = useState();
  const [view, setView] = useState("");
  const [portfolios, setPortfolios] = useState([]);
  const [sites, setSites] = useState([]);
  const [zones, setZones] = useState([]);
  const [devices, setDevices] = useState([]);
  const [valueType, setValueType] = useState();
  const [dataFormat, setDataFormat] = useState();
  const [fileName, setFileName] = useState("");

  const userContext = useUserContext();
  const org = getUserOrg();
  const userOrgObject = organization_mapping.find((m) => m[org]);

  const chartsData = useMemo(() => {
    return getChartsAsOptions(userContext.charts);
  }, [userContext.charts]); //useState([]);
  const metricsData = useMemo(() => {
    const criteriaClone = _.cloneDeep(toJS(criteria));
    criteriaClone.pf = portfolio.value ? portfolio.value : "";
    criteriaClone.site = site.value ? site.value : "";
    criteriaClone.zone = zone.value ? zone.value : "";
    criteriaClone.device = device.value ? device.value : "";
    return getMetricsAsOptions(filterMetricsAsPerCriteria(userContext.metrics, criteriaClone));
  }, [userContext.metrics, portfolio, site, zone, device]); //useState([]);
  console.log("metricsData....", metricsData);

  useEffect(() => {
    if (pageContext.page && pageContext.page.includes("My Dashboards")) {
      setView(viewOptions.find((opt) => opt.value === "My Dashboards"));
    } else {
      setView(viewOptions.find((opt) => opt.value === pageContext.page));
    }
  }, [pageContext.page]);

  const getEmailIds = async (url) => {
    await getRecipientEmailIds(url, setEmailIds, setError);
  };

  useEffect(() => {
    resetFormData();
  }, [activeTab]);
  useEffect(() => {
    const userOrg = userOrgObject[Object.keys(userOrgObject)[0]];
    const url = `/get_email_ids?organization=${userOrg}`;
    getEmailIds(url);
  }, [userOrgObject]);

  useEffect(() => {
    if (emailIds && emailIds.emailIds.length) {
      const data = emailIds.emailIds.map((id) => {
        return { label: id, value: id };
      });
      setRecipientList(data);
    }
  }, [emailIds]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);

  useEffect(() => {
    if (response) {
      let timezone = "";
      let isHistoricalChart = false
      if (site.value) {
        const sites = userContext.sites;
        console.log("site selected", site.value);
        const siteObj = sites.find((s) => s.site_id === site.value);
        console.log("site selected timezone", siteObj.timezone);
        timezone = siteObj ? siteObj.timezone : "";
      }
      console.log("checking response.....",response)
      response.forEach((e) => {
        if (e["timestamp"]) {
          e["timestamp"] = getSiteLocalTime(e["timestamp"], timezone);
        } else if (e["time_key"]) {
          isHistoricalChart = true
          console.log("timezone", timezone);
          e["time_key"] = getSiteLocalTime(e["time_key"], timezone, true);
          delete e['ts']
          //e["ts"] = getSiteLocalTime(e["ts"], timezone, true);
        }
      });

      if(isHistoricalChart && response && response.length){
        response.sort((a, b) => new Date(a.time_key) - new Date(b.time_key));
      }
      setResponse(response);
      if(response && response.length){
        const link = document.querySelector("#csv-link");
        if (link) {
          setTimeout(() => {
            link.click();
          }, 500);
        }
      }else{
        setMessage("No data available for selected values.")
        setMsgStyle("warning")
      }
      

      // if(activeTab === 2){
      //   url['path'] = '/edit-report'
      // }else{
      //   url['path'] = '/schedule-report'

      // }
    }
  }, [response]);

  const getUserReports = async (url) => {
    const user_id = getUserId();
    const urls = [{ path: "/get-reports" }];
    const u = GetFQURLs(
      urls,
      criteria,
      "json",
      null,
      null,
      null,
      {},
      { user_id: user_id }
    );
    await getReports(u[0].url, setReports, setError);
  };

  useEffect(() => {
    console.log("user reports....", reports);
    if (reports.length) {
      const data = reports.map((r) => {
        return { value: r.title, label: r.title };
      });
      setReportList(data);
    }
  }, [reports]);

  useEffect(() => {
    const userPf = getUserPortfolios();
    getUserReports();
    console.log("getting user reports....");
    getCrumbsData(
      { portfolios: userPf ? userPf : "", user: "" },
      setPortfolios,
      setError
    );
  }, []);

  useEffect(() => {
    if (_.isEmpty(portfolio) || portfolio.value == "") {
      return;
    }

    getCrumbsData(
      { portfolios: "", user: "", pf: portfolio.value },
      setSites,
      setError
    );

    setZones([])
    setDevices([])
  }, [portfolio]);

  useEffect(() => {
    if (_.isEmpty(site) || site.value == "") {
      return;
    }

    getCrumbsData(
      { portfolios: "", user: "", pf: portfolio.value, site: site.value },
      setZones,
      setError
    );

    setDevices([])
  }, [site]);

  useEffect(() => {
    if (_.isEmpty(zone) || zone.value == "") {
      return;
    }
    console.log("i am getting called...");
    getCrumbsData(
      {
        portfolios: "",
        user: "",
        pf: portfolio.value,
        site: site.value,
        zone: zone.value,
      },
      setDevices,
      setError
    );
  }, [zone]);

  function isValid(str){
    return !/[~`!#$%\^&*=\\[\]\\';,/{}|\\":<>\?]/g.test(str);
  }

  const handleSubmit = async () => {
    if (!feature) {
      const msg =
        view && view.value === "Downloads"
          ? "Please select atleast one metric"
          : "Please select a chart";
      setMessage(msg);
      setMsgStyle("warning");
      return;
    } else if (!recipients || !recipients.length) {
      setMessage("Please select atleast one user");
      setMsgStyle("warning");
      return;
    } else if (!title) {
      setMessage("Report title is required");
      setMsgStyle("warning");
      return;
    } else if (_.isEmpty(frequency) || !frequency) {
      setMessage("Frequency is required");
      setMsgStyle("warning");
      return;
    } else if (!range) {
      setMessage("Last n day's is required");
      setMsgStyle("warning");
      return;
    } else if (view && view.value === "Downloads" && !dataFormat) {
      setMessage("Data Format is required");
      setMsgStyle("warning");
      return;
    } else if (view && view.value === "Downloads" && !valueType) {
      setMessage("Value Type is required");
      setMsgStyle("warning");
      return;
    } else if (
      reports.length &&
      reports.find((r) => r.title === title) &&
      activeTab !== 2
    ) {
      setMessage(
        "Given report title is already in use. Please give a different report title."
      );
      setMsgStyle("warning");
      return;
    }else if(urls && !urls.length && view && view.value == 'My Dashboards'){
      setMessage("Selected criteria is not correct.");
      setMsgStyle("warning");
      return
    }else if(urls && !urls.length){
      setMessage("Something went wrong. Please contact SUNOPS support team.");
      setMsgStyle("warning");
      return
    }else if(range && range > 30 && view && view.value !== 'Downloads'){
      setMessage("Last n days can not be greater than 30");
      setMsgStyle("warning");
      return
    }else if(range && range > 7 && view && view.value == 'Downloads'){
      setMessage("Last n days can not be greater than 7");
      setMsgStyle("warning");
      return
    }else if(urls && urls.length > 1 && view && view.value == 'My Dashboards'){
      setMessage("Multi charts schedule report is not supported for now.");
      setMsgStyle("warning");
      return
    }else if(view && view.value == 'Downloads' && feature && feature.length > 2){
      setMessage("Schedule report for more than two metrics are not supported for now.");
      setMsgStyle("warning");
      return
    }else if(!isValid(title)){
      setMessage("Special characters in report title are not allowed");
      setMsgStyle("warning");
      return
    }

    setBtnText("Loading...");
    const reportData = {};
    const recipientsData = [];
    recipients.forEach((r) => recipientsData.push(r.value));
    reportData["frequency"] = frequency.value;
    reportData["range"] = range;
    reportData["recipients"] = JSON.stringify(recipientsData);
    reportData["user_id"] = getUserId();
    reportData["sender_email"] = getUserEmail()
    reportData["title"] = title;
    reportData["feature"] =
      view && view.value === "Downloads"
        ? feature
        : view && view.value === "My Dashboards" ? JSON.stringify([feature.label]) : JSON.stringify([feature.value]);
    reportData["user_timezone"] = moment.tz.guess();
    reportData["view"] = view.value;
    reportData["current_title"] = currentTitle;
    reportData["isGenerateReport"] = false;
    reportData['isUtcFeature'] = "no"

    

    if (view && view.value === "Downloads") {
      reportData["merge"] = dataFormat.value;
      reportData["aggr"] = valueType.value;
    }

    if (activeTab === 2) {
      reportData["editReport"] = true;
    }
    const criteriaClone = _.cloneDeep(toJS(criteria));

    // criteriaClone.Ts = moment().subtract(range, "days").set("hour", '00').set("minute", '00').format(CriteriaTimeFormat)
    // criteriaClone.Te = moment().set("hour",'00').set("minute", '00').format(CriteriaTimeFormat)
    criteriaClone.pf = portfolio.value;
    criteriaClone.site = site.value ? site.value : "";
    criteriaClone.zone = zone.value ? zone.value : "";
    criteriaClone.device = device.value ? device.value : "";
    if(view && view.value === "Overview"){
      if(['Historical Performance', 'Availability Impact Summary – Path to 99%', 'Availability vs Errors'].includes(feature.value)){
        reportData['isUtcFeature'] = "yes"
      }

      const level = getLevel(criteriaClone);

      if(feature.value === 'Errors Summary'){
        reportData['featureId'] = `view ${level} ${feature.value}`.toLowerCase().split(" ").join("-")
      }
    }
    console.log("criteria for report", criteriaClone);
    const sites = userContext.sites;
    const siteObj = sites.find((site) => site.site_id === criteriaClone.site);
    criteriaClone.Ts = moment()
      .utc()
      .subtract(range, "days")
      .set("hour", "00")
      .set("minute", "00")
      .format(CriteriaTimeFormat);
    criteriaClone.Te = moment()
      .utc()
      .subtract(1, "days")
      .set("hour", "24")
      .set("minute", "00")
      .format(CriteriaTimeFormat);
    reportData["filename"] =
      view && view.value !== "Downloads"
        ? encodeFilename(criteriaClone, typeof feature.value === 'object' ? feature.value.title : feature.value === 'Availability Impact Summary – Path to 99%' ? 'Availability Impact Summary' : feature.value.replace(/[^a-zA-Z ]/g, "")) + `_${title}`
        : getFileName(criteriaClone, `_${title}_`);
    if (siteObj) {
      const utcTime = moment.utc();
      if (!siteObj.timezone) {
        setMessage(
          "Select site does not have a timezone in database. Please contact SUNOPS support team."
        );
        setMsgStyle("warning");
        setBtnText("Schedule Report");
        return;
      }
      const timeInTimezone = utcTime.tz(siteObj.timezone);
      console.log("timeInTimezone", timeInTimezone);
      criteriaClone.Ts = timeInTimezone
        .clone()
        .subtract(range, "days")
        .set("hour", "00")
        .set("minute", "00")
        .utc()
        .format(CriteriaTimeFormat);
      criteriaClone.Te = timeInTimezone
        .clone()
        .subtract(1, "days")
        .set("hour", "24")
        .set("minute", "00")
        .utc()
        .format(CriteriaTimeFormat);
      reportData["site_tz"] = siteObj.timezone;
    }

    console.log("activeTab", activeTab);
    if (activeTab === 1) {
      if (view.value === "My Dashboards") {
        urls.forEach((u, index) => {
          u["scheduleReportPath"] = "/schedule-report";
        });
      }

      let u;
      if (view && view.value === "Downloads") {
        urls[0]["scheduleReportPath"] = "/schedule-report";
        u = GetFQURLs(
          urls,
          criteriaClone,
          "json",
          null,
          null,
          null,
          null,
          reportData
        );
        const options = {
          url: u[0].url,
          metrics: feature.map(getMetricWithGroup),
          aggr: valueType.value,
          download: "no",
          merge: dataFormat.value,
        };
        if (options.metrics.length > 1) {
          const errorCodeIndex = options.metrics.findIndex(
            (m) => m.metric === "errorCode"
          );
          if (errorCodeIndex !== -1) {
            const errorCodeMetric = options.metrics.find(
              (m) => m.metric === "errorCode"
            );
            const firstMetric = { ...options.metrics[0] };
            options.metrics[0] = errorCodeMetric;
            options.metrics[errorCodeIndex] = firstMetric;
          }
        }

        const requestOptions = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": getAccessToken(),
          },
          body: options,
        };

        console.log("request options......", requestOptions);

        await scheduleReport(
          options.url,
          setMessage,
          setError,
          setMsgStyle,
          resetFormData,
          true,
          requestOptions
        );
        // return { 'url': url, 'metrics': selectedOption.map(getMetricWithGroup), 'aggr': valueType, 'download': download ? 'yes' : 'no', 'merge': merged }
      } else {
        u = GetFQURLs(
          urls,
          criteriaClone,
          "json",
          null,
          null,
          null,
          null,
          reportData
        );
        await scheduleReport(
          u[0].url,
          setMessage,
          setError,
          setMsgStyle,
          resetFormData
        );
      }
    } else {
      if (view.value === "My Dashboards") {
        urls.forEach((u, index) => {
          u["editReportPath"] = "/edit-report";
        });
      }

      if (view && view.value === "Downloads") {
        urls[0]["editReportPath"] = "/edit-report";
      }

      console.log("urls......", urls);

      const u = GetFQURLs(
        urls,
        criteriaClone,
        "json",
        null,
        null,
        null,
        null,
        reportData
      );
      await updateReport(
        u[0].url,
        setMessage,
        setError,
        setMsgStyle,
        resetFormData
      );

      // resetFormData()
    }

    setBtnText("Schedule Report");
    setResponse();
    getUserReports();
  };

  // const handleEmailSelection = (val) => {
  //   setRecipients([...val])
  // }

  useEffect(() => {
    if (urls && urls.length > 0) {
      urls[0].url =
        dataFormat && dataFormat.value === "yes"
          ? "readingsNbyid-d_any"
          : "readingsNbyid";
    }
  }, [dataFormat]);

  useEffect(() => {
    if (newChartOption === 1) {
      resetFormData();
    }
  }, [newChartOption]);

  // useEffect(() => {
  //   console.log("selectedReport....",selectedReport)
  //   if(activeTab === 1 && newChartOption === 1)

  // },[portfolio, site, zone, device])

  const handleFeatureSelection = (val, viewData, metaData) => {
    console.log("value++++++",val)
    setFeature(val);
    let urls;
    const criteriaClone = _.cloneDeep(toJS(criteria));
    criteriaClone.pf = metaData
      ? metaData.pf
      : portfolio.value
      ? portfolio.value
      : "";
    criteriaClone.site = metaData
      ? metaData.site
      : site.value
      ? site.value
      : "";
    criteriaClone.zone = metaData
      ? metaData.zone
      : zone.value
      ? zone.value
      : "";
    criteriaClone.device = metaData
      ? metaData.device
      : device.value
      ? device.value
      : "";
    if (
      (viewData && viewData.value === "My Dashboards") ||
      (viewData && !viewData.value && view.value === "My Dashboards")
    ) {
      const data = getComp2(
        getChartCompValue2(val.value, criteriaClone),
        criteriaClone
      );
      console.log("checking data...", data);
      urls = data.urls.map((u) => u);
      console.log("urls+++++++", urls);
    } else if (
      (viewData && viewData.value === "Downloads") ||
      (viewData && !viewData.value && view.value === "Downloads")
    ) {
      const groupType = getGroupType(criteriaClone);
      console.log("checking group type", criteriaClone, groupType);
      urls = [
        {
          name: "downloadmetrics",
          url: "readingsNbyid-d_any",
          grouptype: groupType,
          slice: 60,
          nocache: true,
          path: "/download",
        },
      ];
      console.log("urls+++++++", urls, site);
    } else if (
      (viewData && viewData.value === "Overview") ||
      (viewData && !viewData.value && view.value === "Overview")
    ) {
      if(val && val.value === 'Availability vs Errors'){
        urls = [{ ...val.data, grouptype:  criteriaClone.site === "" ? 'pf' : criteriaClone.zone === '' ? 'sites' : criteriaClone.device === '' ? 'zones' : 'rcs'}];
      }else{
        urls = [{ ...val.data }];
      }
      
    }
    console.log("my url is======", urls);
    setUrls(urls);

    // if(val.data.dayMetric){
    //   setDayMetric(true)
    // }
  };

  const tooltip = (
    <div>
      <h5>Time Range</h5>
      <p>Time range is an integer number that indicates</p>
      <p>the n number of past days from the frequency.</p>
    </div>
  );

  // useEffect(() => {
  //   if(btnText !== 'Generate Report'){
  //     setBtnText('Generate Report')
  //     setResponse()
  //   }
  // },[recipients, feature, frequency, range, title])
  // useEffect(() => {
  //   if(btnText !== 'Generate Report'){
  //     setBtnText('Generate Report')
  //     setResponse()
  //   }
  // },[recipients, feature, frequency, range, title])

  

  const handleReportSelect = (val) => {
    console.log("selecting report...",val)
    setReport(val);
    const r = reports.find((r) => r.title === val.value);
    console.log("report data...", r);
    if (r) {
      const f = frequencyOptions.find((opt) => opt.value === r.frequency);
      const metaData = r.report_url.split("&");
      console.log("metaData...", metaData);
      let site, portfolio, zone, device;
      metaData.forEach((o) => {
        if (o.split("=")[0] == "pf") {
          portfolio = decodeURI(o.split("=")[1]);
          setPortfolio({ label: portfolio, value: portfolio });
        } else if (o.split("=")[0] == "site") {
          site = decodeURI(o.split("=")[1]);
          setSite({ label: site, value: site });
        } else if (o.split("=")[0] == "zone") {
          zone = decodeURI(o.split("=")[1]);
          setZone({ label: zone, value: zone });
        } else if (o.split("=")[0] == "device") {
          device = decodeURI(o.split("=")[1]);
          setDevice({ label: device, value: device });
        } else if (o.split("=")[0] == "aggr") {
          const obj = valueOptions.find((opt) => opt.value == o.split("=")[1]);
          setValueType(obj);
        } else if (o.split("=")[0] == "merge") {
          const obj = dataFormatOptions.find(
            (opt) => opt.value == o.split("=")[1]
          );
          setDataFormat(obj);
        }
      });
      let feature;
      if (r.view === "My Dashboards") {
        chartsData.forEach((c) => {
          c.options.forEach((op) => {
            if (op.label === JSON.parse(JSON.parse(r.feature)[0])[0]) {
              feature = op;
            }
          });
        });
      } else if (r.view === "Overview") {
        feature = featureOptions[r.view].find(
          (opt) => opt.value == JSON.parse(JSON.parse(r.feature)[0])
        );
      } else {
        const features = JSON.parse(JSON.parse(r.feature)[0]);
        const fs = [];
        const metricsData =  getMetricsAsOptions(filterMetricsAsPerCriteria(userContext.metrics, {pf: portfolio, site: site, zone: zone, device: device}));
        features.forEach((f) => {
          metricsData.forEach((md) => {
            if (md.value === f.metricGroup) {
              const option = md.options.find((opt) => opt.label === f.metric);
              if (option) {
                fs.push(option);
              }
            }
          });
        });
        feature = fs;
        console.log("feature....", feature);
      }
      
      setView(viewOptions.find((opt) => opt.value === r.view));
      setFrequency(f);
      setTitle(
        val.isClone || (activeTab === 1 && newChartOption === 2) ? r.title + "_clone" : r.title
      );
      setRange(r.day_range);
      handleFeatureSelection(
        feature,
        viewOptions.find((opt) => opt.value === r.view),
        { pf: portfolio, site: site, zone: zone, device: device }
      );
      const recipientsData = JSON.parse(JSON.parse(r.recipients)[0]);
      const rData = recipientsData.map((d) => {
        return { label: d, value: d };
      });

      setRecipients(rData);
      setSelectedReport(r);
      setCurrentTitle(r.title);
    }
  };

  const resetFormData = () => {
    // setBtnText('Generate Report')
    // setBtnText('Generate Report')
    setSelectedReport();
    setFrequency({});
    setTitle("");
    setRange(1);
    setFeature();
    setRecipients();
    getUserReports();
    setReport();
    setPortfolio({ label: criteria.pf, value: criteria.pf });
    setSite({ label: criteria.site, value: criteria.site });
    setZone({ label: criteria.zone, value: criteria.zone });
    setDevice({ label: criteria.device, value: criteria.device });
    setResponse();
    setDataFormat();
    setValueType();
  };

  const handleReportDelete = async (row) => {
    const title = row ? encodeURIComponent(row.title) : encodeURIComponent(selectedReport.title)
    const user_id = row ? row.user_id : selectedReport.user_id
    await deleteReport(
      { user_id: user_id, title: title },
      setMessage,
      setError
    );
    setMsgStyle("success");
    const rList = [...reportList];
    const index = rList.findIndex((r) => r.value === title);
    if (index !== -1) {
      rList.splice(index, 1);
      setReportList([...rList]);
    }
    resetFormData();
  };

  const handleReportActiveStatus = async (row) => {
    await updateReportStatus(
      { user_id: row.user_id, is_active: !row.is_active, title: row.title },
      setMessage,
      setError,
      setMsgStyle,  
      resetFormData
    );
  };

  let level;

  if (portfolio.value && !site.value) {
    level = "pf";
  } else if (portfolio.value && site.value && !zone.value) {
    level = "site";
  } else if (portfolio.value && site.value && zone.value && !device.value) {
    level = "zone";
  } else {
    level = "device";
  }

  function getMetricWithGroup(e) {
    return Object.assign(
      {},
      { metric: e.value.metric, metricGroup: e.value.group_id }
    );
  }

  function getFileName(criteria, title) {
    const domain = `${
      criteria.site === ""
        ? "allsites-"
        : criteria.zone === ""
        ? criteria.site + "-"
        : criteria.device === ""
        ? criteria.site + "-" + criteria.zone + "-"
        : criteria.site + "-" + criteria.zone + "-" + criteria.device + "-"
    }`;
    // const time = `${criteria.Ts + '-' + criteria.Te }`
    return domain + title;
  }

  const downloadSample = async () => {
    if (!feature) {
      const msg =
        view && view.value === "Downloads"
          ? "Please select atleast one metric"
          : "Please select a chart";
      setMessage(msg);
      setMsgStyle("warning");
      return;
    } else if (!recipients || !recipients.length) {
      setMessage("Please select atleast one user");
      setMsgStyle("warning");
      return;
    } else if (!title) {
      setMessage("Report title is required");
      setMsgStyle("warning");
      return;
    } else if (_.isEmpty(frequency) || !frequency) {
      setMessage("Frequency is required");
      setMsgStyle("warning");
      return;
    } else if (!range) {
      setMessage("Last n day's is required");
      setMsgStyle("warning");
      return;
    } else if (view && view.value === "Downloads" && !dataFormat) {
      setMessage("Data Format is required");
      setMsgStyle("warning");
      return;
    } else if (view && view.value === "Downloads" && !valueType) {
      setMessage("Value Type is required");
      setMsgStyle("warning");
      return;
    } else if(urls && !urls.length && view && view.value == 'My Dashboards'){
      setMessage("Selected criteria is not correct.");
      setMsgStyle("warning");
      return
    }else if(urls && !urls.length){
      setMessage("Something went wrong. Please contact SUNOPS support team.");
      setMsgStyle("warning");
      return
    }else if(urls && urls.length > 1 && view && view.value == 'My Dashboards'){
      setMessage("Multi charts schedule report is not supported for now.");
      setMsgStyle("warning");
      return
    }else if(view && view.value == 'Downloads' && feature && feature.length > 2){
      setMessage("Schedule report for more than two metrics are not supported for now.");
      setMsgStyle("warning");
      return
    }else if(!isValid(title)){
      setMessage("Special characters in report title are not allowed");
      setMsgStyle("warning");
      return
    }

    setBtnText2("Downloading...");
    const reportData = {};
    const recipientsData = [];
    recipients.forEach((r) => recipientsData.push(r.value));
    reportData["frequency"] = frequency.value;
    reportData["range"] = range;
    reportData["recipients"] = JSON.stringify(recipientsData);
    reportData["user_id"] = getUserId();
    reportData["title"] = title;
    reportData["feature"] =
      view && view.value === "Downloads"
        ? feature
        : view && view.value === "My Dashboards" ? JSON.stringify([feature.label]) : JSON.stringify([feature.value]);
    reportData["user_timezone"] = moment.tz.guess();
    reportData["view"] = view.value;
    reportData["current_title"] = currentTitle;
    reportData["isGenerateReport"] = true;
    reportData['isUtcFeature'] = "no"

    if (view && view.value === "Downloads") {
      reportData["merge"] = dataFormat.value;
      reportData["aggr"] = valueType.value;
    }

    const criteriaClone = _.cloneDeep(toJS(criteria));
    criteriaClone.pf = portfolio.value;
    criteriaClone.site = site.value ? site.value : "";
    criteriaClone.zone = zone.value ? zone.value : "";
    criteriaClone.device = device.value ? device.value : "";

    if(view && view.value === "Overview"){
      if(['Historical Performance', 'Availability Impact Summary – Path to 99%', 'Availability vs Errors'].includes(feature.value)){
        reportData['isUtcFeature'] = "yes"
      }

      const level = getLevel(criteriaClone)

      if(feature.value === 'Errors Summary'){
        reportData['featureId'] = `view ${level} ${feature.value}`.toLowerCase().split(" ").join("-")
      }
    }
    
    const sites = userContext.sites;
    const siteObj = sites.find((site) => site.site_id === criteriaClone.site);
    criteriaClone.Ts = moment()
      .utc()
      .subtract(7, "days")
      .set("hour", "00")
      .set("minute", "00")
      .format(CriteriaTimeFormat);
    criteriaClone.Te = moment()
      .utc()
      .set("hour", "00")
      .set("minute", "00")
      .format(CriteriaTimeFormat);
    if (siteObj) {
      const utcTime = moment.utc();
      if (!siteObj.timezone) {
        setMessage(
          "Select site does not have a timezone in database. Please contact SUNOPS support team."
        );
        setMsgStyle("warning");
        setBtnText2("Download Sample");
        return;
      }
      const timeInTimezone = utcTime.tz(siteObj.timezone);
      console.log("timeInTimezone", timeInTimezone);
      criteriaClone.Ts = timeInTimezone
        .clone()
        .subtract(7, "days")
        .set("hour", "00")
        .set("minute", "00")
        .utc()
        .format(CriteriaTimeFormat);
      criteriaClone.Te = timeInTimezone
        .clone()
        .set("hour", "00")
        .set("minute", "00")
        .utc()
        .format(CriteriaTimeFormat);
      reportData["site_tz"] = siteObj.timezone;
    }
    console.log("criteria for report", criteriaClone, reportData);
    if (view.value === "My Dashboards") {
      urls.forEach((u, index) => {
        u["generateReportPath"] = "/generate-report";
      });
    }
    console.log("urls.....", urls);
    let u;
    if (view && view.value === "Downloads") {
      urls[0]["generateReportPath"] = "/download";
      u = GetFQURLs(
        urls,
        criteriaClone,
        "json",
        null,
        null,
        null,
        null,
        reportData
      );
      console.log("url is++++", criteriaClone);
      downloadMetricsData(
        {
          url: u[0].url,
          metrics: feature.map(getMetricWithGroup),
          aggr: valueType.value,
          download: "yes",
          merge: dataFormat.value,
        },
        getFileName(criteriaClone, `_${title}_`),
        () => {},
        level,
        criteriaClone,
        () => {
          setBtnText2("Download Sample");
        }
      );
      // return { 'url': url, 'metrics': selectedOption.map(getMetricWithGroup), 'aggr': valueType, 'download': download ? 'yes' : 'no', 'merge': merged }

      return;
    }

    u = GetFQURLs(
      urls,
      criteriaClone,
      "json",
      null,
      null,
      null,
      null,
      reportData
    );


    const fn =
      encodeFilename(criteriaClone, typeof feature.value === 'object' ? feature.value.title : feature.value) + `_${title}` + ".csv";
    setFileName(fn);
    await generateReport(u[0].url, setResponse, setError);

    setBtnText2("Download Sample");
  };

  console.log("selected features.....", feature);

  return (
    <EditFieldStyle>
      <Header activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 1 && (
        <ActiveTabOneOptions
          newChartOption={newChartOption}
          setNewChartOption={setNewChartOption}
          response={response}
          resetFormData={resetFormData}
        />
      )}
      {activeTab !== 3 && (activeTab === 2 || newChartOption === 2) ? (
        <div className="row">
          <div className="col-6">
            <Form.Label>Select Report</Form.Label>
            <SingleSelectDropDownControl
              value={report}
              handleChange={handleReportSelect}
              options={reportList}
              multi={false}
            />
          </div>
        </div>
      ) : null}
      {activeTab !== 3 && (
        <>
          <div className="row">
            <div className="col-3">
              <Form.Label>Portfolio</Form.Label>
              <SingleSelectDropDownControl
                isDisabled={activeTab === 2 ? true : false}
                value={portfolio}
                handleChange={(val) => {
                  setPortfolio(val);
                  setSite({});
                  setZone({});
                  setDevice({});
                  setFeature();
                }}
                options={portfolios}
                multi={false}
              />
            </div>
            <div className="col-3">
              <Form.Label>Site</Form.Label>
              <SingleSelectDropDownControl
                isDisabled={activeTab === 2 ? true : false}
                value={site}
                handleChange={(val) => {
                  setSite(val);
                  setZone({});
                  setDevice({});
                  setFeature();
                }}
                options={sites}
                multi={false}
              />
            </div>
            <div className="col-3">
              <Form.Label>Zone</Form.Label>
              <SingleSelectDropDownControl
                isDisabled={activeTab === 2 ? true : false}
                value={zone}
                handleChange={(val) => {
                  setZone(val);
                  setDevice({});
                  setFeature();
                }}
                options={zones}
                multi={false}
              />
            </div>
            <div className="col-3">
              <Form.Label>Device</Form.Label>
              <SingleSelectDropDownControl
                isDisabled={activeTab === 2 ? true : false}
                value={device}
                handleChange={(val) => {
                  setDevice(val);
                  setFeature();
                }}
                options={devices}
                multi={false}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Form.Label>View</Form.Label>
              <SingleSelectDropDownControl
                isDisabled={activeTab === 2 ? true : false}
                value={view}
                handleChange={(val) => {
                  setView(val);
                  setFeature();
                }}
                options={viewOptions}
                multi={false}
              />
            </div>
            <div className="col-3">
              <Form.Label>
                Select{" "}
                {(view && view.value === "Overview") ||
                (view && view.value === "My Dashboards")
                  ? "Chart"
                  : "Metric"}
                <span style={{color: 'red', fontSize: '15px'}}> *</span>
              </Form.Label>
              <SingleSelectDropDownControl
                isDisabled={activeTab === 2 ? true : false}
                value={feature}
                handleChange={handleFeatureSelection}
                options={
                  view && view.value === "My Dashboards"
                    ? chartsData
                    : view && view.value === "Downloads"
                    ? metricsData
                    : view
                    ? featureOptions[view.value].filter((f) =>
                        f.data["level"].includes(level)
                      )
                    : []
                }
                multi={view && view.value === "Downloads" ? true : false}
              />
            </div>
            <div className="col-3">
              <Form.Label>Report Title<span style={{color: 'red', fontSize: '15px'}}> *</span></Form.Label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {/* <SingleSelectDropDownControl options={frequencyOptions} isMulti={false} /> */}
            </div>
            <div className="col-3">
              <Form.Label>Frequency<span style={{color: 'red', fontSize: '15px'}}> *</span></Form.Label>
              <SingleSelectDropDownControl
                value={frequency}
                handleChange={(val) => setFrequency(val)}
                options={frequencyOptions}
                isMulti={false}
              />
            </div>
          </div>
          <div className="row">
            {view && view.value === "Downloads" ? (
              <>
                <div className="col-2">
                  <Form.Label>Data Format<span style={{color: 'red', fontSize: '15px'}}> *</span></Form.Label>
                  <SingleSelectDropDownControl
                    value={dataFormat}
                    handleChange={(val) => setDataFormat(val)}
                    options={dataFormatOptions}
                    isMulti={false}
                  />
                </div>
                <div className="col-2">
                  <Form.Label>Value Type<span style={{color: 'red', fontSize: '15px'}}> *</span></Form.Label>
                  <SingleSelectDropDownControl
                    value={valueType}
                    handleChange={(val) => setValueType(val)}
                    options={valueOptions}
                    isMulti={false}
                  />
                </div>
              </>
            ) : null}

            <div
              className={view && view.value === "Downloads" ? "col-2" : "col-3"}
            >
              <Form.Label>
                Last n day's <span style={{color: 'red', fontSize: '15px'}}> *</span>
                <InfoTooltip
                  content={ReactDOMServer.renderToString(tooltip)}
                  toolTipPointer={"right"}
                />
              </Form.Label>
              <input
                min="1"
                inputmode="numeric"
                className="form-control"
                value={range ? range : ""}
                onChange={(e) => setRange(Math.abs(e.target.value))}
              />
              {/* <SingleSelectDropDownControl options={frequencyOptions} isMulti={false} /> */}
            </div>
            <div className="col-6">
              <Form.Label>Select or Type Email of Users<span style={{color: 'red', fontSize: '15px'}}> *</span></Form.Label>
              <SingleSelectDropDownControl
                value={recipients}
                handleChange={(val) => setRecipients([...val])}
                options={recipientList}
                multi={true}
              />
            </div>
            {/* {
          !dayMetric ? (
            <>
              <div className="col-3">
                <Form.Label>Start Time</Form.Label>
                <input type="time" className="form-control" value="10:05 AM" />
              </div>
              <div className="col-3">
                <Form.Label>End Time</Form.Label>
                <input type="time" className="form-control" value="10:05 AM" />
              </div>
            </>
          ) : null
        } */}

            {/* <div className="col-4">
          <Form.Label>Start Time</Form.Label>
          <SingleSelectDropDownControl options={timeRange} isMulti={false} />
        </div>
        <div className="col-4">
          <Form.Label>End Time</Form.Label>
          <SingleSelectDropDownControl options={timeRange} isMulti={false} />
        </div> */}
          </div>
          <hr />
          <div className="row modalFooter">
            {report ? (
              <Button
                disabled={loading}
                onClick={() => handleReportDelete()}
                className="btn-default"
                active
                color="secondary"
              >
                Delete
              </Button>
            ) : null}
            <Button
              onClick={downloadSample}
              disabled={loading}
              className="btn-default"
              active
              color="secondary"
            >
              {btnText2}
            </Button>
            {response ? (
              <CSVLink
                filename={fileName}
                id="csv-link"
                style={{ display: "none" }}
                data={response}
              >
                csv download
              </CSVLink>
            ) : null}

            <Button
              disabled={loading}
              onClick={handleSubmit}
              className="btn-secondary"
              active
              color="secondary"
            >
              {btnText}
            </Button>
          </div>
        </>
      )}

      {activeTab === 3 && <ReportTable reports={reports} 
          handleReportSelect={handleReportSelect} 
          setActiveTab={setActiveTab}
          setNewChartOption={setNewChartOption} 
          setSelectedReport={setSelectedReport}
          handleReportDelete={handleReportDelete}
          handleReportActiveStatus={handleReportActiveStatus}
        />
      }

      <ToastContainer
        position="top-start"
        style={{ zIndex: "10" }}
        className="p-3"
        containerPosition="absolute"
      >
        <Toast
          show={
            typeof message === "object"
              ? message.status.length
              : message.length > 0
          }
          bg={msgStyle}
        >
          <Toast.Body>
            {typeof message === "object" ? message.status : message}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </EditFieldStyle>
  );
};

export default ReportEditor;
