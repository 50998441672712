import { merge } from "lodash";

const lineTemplate = {
    label: '',
    fill: false,
    lineTension: 0.1,
    backgroundColor: 'rgba(75,192,192,0.4)',
    borderColor: 'rgba(75,192,192,1)',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: 'rgba(75,192,192,1)',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: []
}
var label2title = {}

function GenerateLabels(chart) {
    var data = chart.data;
    if (data.labels.length && data.datasets.length) {
        return data.datasets.map(function (ds, i) {
            var meta = chart.getDatasetMeta(0);
            //var ds = data.datasets[0];
            //var arc = meta.data[i];
            //var custom = arc && arc.custom || {};
            //var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
            //var arcOpts = chart.options.elements.arc;
            //var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
            //var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
            //var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

            // We get the value of the current label
            //var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];
            return {
                // Instead of `text: label,`
                // We add the value to the string
                text: ds.label, //label2title[ds.label], //+ " : " + value,
                fillStyle: ds.borderColor, //fill,
                fontColor: ds.borderColor,
                //strokeStyle: stroke,
                //lineWidth: 1, //bw,
                //hidden: (ds.data && ds.data[i])? isNaN(ds.data[i]) || meta.data[i].hidden : false,
                index: i
            };
        });
    } else {
        return [];
    }
}
const timeFormat = "YYYY-MM-DD HH:mm";
export const getTimeFormat = () => {
    return timeFormat
}
const scalesOptions = {
    xAxes: [{
        type: 'time',
        display: true,
        position: 'bottom',
        time: {
            unit: 'hour',
            //round: "hour",
            displayFormats: {
                'hour': 'HH:mm',
                'day': 'MMM D'
            },

        },
        ticks: {
            fontColor: "#BBB",
            maxTicksLimit: 12,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0
        },
        
    }, {
        type: 'time',
        time: {
            unit: 'day',
            round: "day",
            displayFormats: {
                'hour': 'HH:mm',
                'day': 'MMM D'
            },

        },
        ticks: {
            fontColor: "#BBB",
            fontStyle: "bold",
            fontSize: 14,
            maxTicksLimit: 8,
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0

        }
    }],
    yAxes: [{
        id: 'left-y-axis',
        type: 'linear',
        position: 'left',
        scaleLabel: {
            display: true,
            //labelString: 'Loading'
        },
        ticks: {
            maxTicksLimit: 6,
        }

    }
    ]
}
const legendOptions = {
    display: true,
    //position: 'below',
    labels: { 
        fontColor: '#EEE', 
        boxWidth: 10,           
        usePointStyle: true,
        pointStyle: {
            radius: 1,
            borderWidth: 0
        },
        generateLabels: GenerateLabels 
    }
}
const chartOptions = {
    responsive: true,
    scales: scalesOptions,
    //legend: legendOptions,
    legendCallback: function (chart) {
        // Return the HTML string here.
        var text = [];
        text.push('<ul class="' + chart.id + '-legend">');
        for (var i = 0; i < chart.data.datasets[0].data.length; i++) {
            text.push('<li><span id="legend-' + i + '-item" style="background-color:' + chart.data.datasets[0].backgroundColor[i] + '"   onclick="updateDataset(event, ' + '\'' + i + '\'' + ')">');
            if (chart.data.labels[i]) {
                text.push(chart.data.labels[i]);
            }
            text.push('</span></li>');
        }
        text.push('</ul>');
        return text.join("");
    },
    title: {
        display: false,
        text: 'title'
    },
    animation: {
        animateScale: true,
        animateRotate: true
    },
    tooltips: {
        mode: 'point',
        displayColors: true,
        intersect: false,
        callbacks: {
            label: function (tooltipItem, data) {
                //console.log("tt",data)
                let label = data.datasets[tooltipItem.datasetIndex].label //+ ' - ' + data.labels[tooltipItem.index];
                let datasetLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                return ' ' + label + ' : ' + datasetLabel.y //.toLocaleString();
            },
            title: function (tooltipItem, data) {
                //let title = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                return tooltipItem[0].xLabel;//+ '<br>';
            },
            labelColor: function(tooltipItem, chart) {
                var dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
                return {
                    backgroundColor : dataset.borderColor,
                    
                }
            }
        }
    },
}

const getChartJSOptions = (label="", legend="top", stacked=true) => {
    //console.log(label, legend, stacked)
    const yAxes = { 
        scales: {
            
            yAxes: [{ stacked: stacked, scaleLabel: {labelString: label}}]
        },
        legend: {
            position: legend,
            maxWidth: '50',
            labels: {
              fontSize: 12,
              fontColor: "#fff",
              boxWidth: 15,
              usePointStyle: false,
              pointStyle: {
                radius: 1,
                borderWidth: 0
              },
      
            }
          }
      
    }

    return merge(chartOptions,yAxes); 
}

export default getChartJSOptions;