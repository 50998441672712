import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import React, { useMemo } from "react";
import { isPortfolio, isSite, isZone } from "../../components/layout/criteria";
import { TooltipHelpContent } from "../../components/pages/tooltip-help-content";
import { base_colors, mode_colors, mode_names_gc } from '../../metadata/modes.meta';
import withData from '../../model/combined';
import { axisStyle } from "./common";

//var tinycolor = require("tinycolor2");
import { useStoreActionContext } from "../../context/store.context";
//import { useCommonActionContext } from "../../context/common.context";
import { useCriteriaContext } from "../../context/criteria.context";
import { getSites } from "../../model/metadata";



const defaultOption = {
    grid: [
        { left: '60', right: '5', bottom: "15%", top: "10%" },
        //{ left: '53%', width: '42%', bottom: "5%", top: "5%" },
        //{left: '4%', top: '10%', width: '38%', height: '38%'},
        //{right: '7%', top: '7%', width: '38%', height: '38%'},
        //{left: '7%', bottom: '7%', width: '38%', height: '38%'},
        //{right: '7%', bottom: '7%', width: '38%', height: '38%'}
    ],
    xAxis: [
        axisStyle("Error Count", -40),
        //axisStyle("", -40, 1)
    ],
    yAxis: [
        axisStyle("", 0, "end"),

        //axisStyle("Uptime %", 30, 1)
    ],
    legend: {},
    toolbox: {
        show: false,
        orient: "horizontal",
        right: "0%", top: "-7px",
        iconStyle: { borderColor: '#014059' },
        //emphasis: {iconStyle: {borderColor: 'rgba(28, 28, 228, 0.8)'}},
        feature: {
            // dataView: { readOnly: false },
            //magicType: { type: ["line", "bar", 'stack'] },
            restore: {},
            saveAsImage: {}
        }
    },
    axisPointer: {
        link: { xAxisIndex: 'all' },
        label: {
            color: '#333'
        }
    },
    /*
    tooltip: {
        confine: false,
        //trigger: 'axis',
        axisPointer: { type: 'cross', snap: true },
        position: function (pos, params, el, elRect, size) {
            var obj = { top: 40, bottom: 80 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
        },
        backgroundColor: 'rgba(255,255,205,0.7)',
        extraCssText: 'width: 170px'

    },*/
    tooltip: {
        confine: true,
        axisPointer: { type: 'cross', snap: true },
        backgroundColor: '#B7D0DA',
        borderWidth: 0,
        extraCssText: 'width: 210px',
        textStyle: { fontSize: 14, overflow: 'break', color: "#014059" }

    },

    dataZoom: [
        { type: 'inside', start: 0, end: 100 },
        { type: 'slider', xAxisIndex: 0, show: true, top: '95%', left: '10%', right: '20%', width: '80%', height: '3%', start: 0, end: 100 },
        // { type: 'slider', yAxisIndex: 0, show: true, filterMode: 'empty', width: 12, height: '70%', handleSize: 8, showDataShadow: false,left: '93%' }

    ],


}

//const style = 'style="font-size: 14px;font-weight: 700;padding-right: 5px;display: inline-block;width: 80px;background-color: blue"';
const ttstyle = 'style="font-size: 14px;font-weight: 700;padding-right: 5px;"';
const style2 = 'style="font-size: 14px;font-weight: normal;padding-left: 5px;display: inline-block;width: 80px;background-color: red"';

const Ttformatter = (param) => {

    const value = param.value;
    const name = param.name
    //console.log(value)
    var title = (value[3] === '') ? '' : value[3]
    title += (value[4] === '') ? '' : ' / ' + value[4]
    title += (value[5] === '') ? '' : ' / ' + value[5]

    return (
        `
            <div className="tooltip-title">  ${title}  </div>
            <hr/>

            <div><span ${ttstyle} >${value[0]}</span><label>Uptime %</label></div>
            <div><span ${ttstyle}>${value[1]}</span><label >errors</label></div>
            <div><span ${ttstyle}>${value[2]}</span><label >devices</label></div>
            `
    )


}

function getTooltipFormatter() {
    //var amountIndex = mode === 1 ? 1 : 0;
    //var amountIndex2011 = mode === 1 ? 0 : 1;

    return function (params) {
        const value = params.value;
        //console.log(value)

        return (
            `
            <div className="tooltip-title"> + {value[2]} + </div>
            <div>
            <span>{value[2]} Uptime %</span><br/>
            <span>{value[5]} errors</span><br/>
            <span>{value[4]} devices</span><br/>
            <span>{value[6]} angle diffs</span><br/>
            <span>{value[3]} tracked </span><br/>
            </div>
            `
        )
    }
}
const style = { color: '#ddd', fontSize: 14 }

//https://colourco.de/


function symInRange(v) {
    v = v * 40
    return (v > 40) ? 40 : (v < 15) ? 15 : v
}

function getModeVisualMap(alldata, series, mode_names) {

    const defaultVisualMap = {
        visualMap: {
            min: 0,
            max: 15, //mode_names.length,
            orient: "vertical",
            align: 'auto',
            right: "-10",
            //left: "85%",
            bottom: "5%",
            top: "20%",
            type: "piecewise",
            showLabel: true,
            textStyle: { color: '#231F20', fontSize: 10 },
            show: false,
        },

    }
    if (!alldata || !series || !alldata.values) return defaultVisualMap

    const values = alldata.values //|| []
    const indexes = alldata.indexes //|| []

    const iMode = indexes["mode"]
    const modes_seen = [... new Set(values.map(e => mode_names.indexOf(e[iMode])))]
    // const modes_seen = [... new Set(values.map(e => e[iMode]))]
    
    const modes_pieces = modes_seen.map((e, i) => { return { min: i, max: i, label: mode_names[e], color: base_colors[e], value: mode_names[e] } })
    const visualMap = {
        visualMap: {
            pieces: modes_pieces,
            dimension: iMode,
            show: true,
            seriesIndex: series.map((_, i) => i)
        },
    }

    return _.merge({}, defaultVisualMap, visualMap);
}
function makeOptions(alldata, index) {

    if (!alldata || Object.keys(alldata) <= 0 || !alldata.values) return defaultOption

    const values = alldata.values || []
    const columns = alldata.columns || []
    const indexes = alldata.indexes || []

    // Calculate Display based on domains 
    const allDomains = ['site_id', 'zone_id', 'device_id']
    const sz = (columns.includes('device_id')) ? 3 : (columns.includes('zone_id')) ? 2 : 1
    const domains = allDomains.slice(0, sz)
    const iNameIndex = (columns.includes('device_id')) ? indexes['device_id'] : (columns.includes('zone_id')) ? indexes['zone_id'] : indexes['site_id']


    const iDevicecount = indexes["devicecount"]

    const maxDevicecount = Math.max.apply(Math, values.map(function (o) { return o[iDevicecount]; }))
    const minDevicecount = Math.min.apply(Math, values.map(function (o) { return o[iDevicecount]; }))
    const dynamicSymSize = (minDevicecount / maxDevicecount) < 0.1
    // console.log(maxDevicecount, minDevicecount, dynamicSymSize)


    const series = [
        {
            type: 'scatter',
            //symbolSize: 10,
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(51, 51, 51, 0.3)',
                shadowOffsetY: 5,
                color: function (si) {
                    //console.log(si)
                    return mode_colors[+si.value[indexes["mode"]]]
                },
                textStyle: { color: 'white', fontSize: 12 }
            },
            symbolSize: function (d) {
                return (dynamicSymSize) ? symInRange(d[iDevicecount] / maxDevicecount) : 20

            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    formatter: function (param) {
                        const v = domains.map(d => param.data[indexes[d]]).join('-')
                        return v
                    },
                    position: 'inside-top',
                    textStyle: { color: 'white', fontSize: 12 }
                }
            },
            encode: {
                x: 'errorcount', y: 'uptime', seriesName: 'series' + index, itemName: [iNameIndex],
                tooltip: Array.from(Array(columns.length).keys())
                //tooltip: {                    }
            },
            data: values,
            dimensions: columns,
            xAxisIndex: index,
            yAxisIndex: index,
            gridIndex: index,
            //formatter:  tooltipFormatter(d1)
            /*
            formatter: function (params, ticket, callback) {
                $.get('detail?name=' + params.name, function (content) {
                    callback(ticket, toHTML(content));
                });
                return 'Loading';
            }
            */

        }
    ]

    const option = {

        legend: {},
        toolbox: {
            show: true,
            feature: {
                dataZoom: { yAxisIndex: index },
            }
        },
    }

    return { option: option, series: series }

}
function makeOptionsPerformance(alldata, index, mode_names, uc) {

    if (!alldata || Object.keys(alldata) <= 0 || !alldata.values) return defaultOption
    var values = alldata.values || []
    var columns = alldata.columns || []
    var indexes = alldata.indexes || []
    const sites = uc ? uc.sites : []


    const iNameIndex = indexes['id']
    const iDevicecount = indexes["devices"]
    const iErrorCount = indexes["errors"]
    const iMode = indexes["mode"]
    
    // console.log(mode_names)
    values.forEach(v => {
        let modeIndex = v[iMode] 
        const siteObj = sites.find(s => s.site_id === v[0])
        if(parseInt(modeIndex) || parseInt(modeIndex) === 0){
            var modeName = ''
            if(siteObj){
                modeName = siteObj.tenant === 'ftc' ? mode_names[modeIndex] : mode_names_gc[modeIndex] 
            }else{
                modeName = mode_names[modeIndex]
            }

            v[iMode] = (modeIndex < mode_names.length) ? modeName :"" //mode_names[modeIndex]
        }
    })
    // const iMode = indexes["mode"]
    const iUptime = indexes["availability"]
    const deviceCounts = values.map((o) => o[iDevicecount])
    const maxDevicecount = Math.max.apply(Math, deviceCounts)
    const minDevicecount = Math.min.apply(Math, deviceCounts)
    const dynamicSymSize = (minDevicecount / maxDevicecount) < 0.1
    // console.log(maxDevicecount, minDevicecount, dynamicSymSize)


    const series = [
        {
            type: 'scatter',
            //symbolSize: 10,
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(51, 51, 51, 0.3)',
                shadowOffsetY: 5,
                color: function (si) {
                    return mode_colors[mode_names.indexOf(si.value[iMode])]
                },
                textStyle: { color: '#231F20', fontSize: 10 }
            },
            symbolSize: function (d) {
                return (dynamicSymSize) ? symInRange(d[iDevicecount] / maxDevicecount) : 20

            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    formatter: function (param) {
                        // console.log(param)
                        // const v = domains.map(d => param.data[indexes[d]]).join('-')
                        return param.name
                    },
                    position: 'inside-top',
                    textStyle: { color: '#231F20', fontSize: 10 }
                }
            },
            encode: {
                x: 'errors', y: 'availability', seriesName: 'series' + index, itemName: [iNameIndex],
                tooltip: Array.from(Array(columns.length).keys())
                //tooltip: {                    }
            },
            data: values,
            dimensions: columns,
            xAxisIndex: index,
            yAxisIndex: index,
            gridIndex: index,
            //formatter:  tooltipFormatter(d1)
            /*
            formatter: function (params, ticket, callback) {
                $.get('detail?name=' + params.name, function (content) {
                    callback(ticket, toHTML(content));
                });
                return 'Loading';
            }
            */

        }
    ]

    const option = {

        legend: {},
        toolbox: {
            show: true,
            feature: {
                dataZoom: { yAxisIndex: index },
            }
        },
    }

    return { option: option, series: series }

}

const UptimeVsErrorsChart = (props) => {
    return (
        <ReactEcharts className="mw-100 mh-100"
            option={props.option}
            // style={{ height: "80vh", left: 50, top: 50, width: "90vw" }}
            //style={{ height: "100%", width: "100%", left: 0, top: 0 }}
            onChartReady={props.onRenderComplete}
            opts={{ renderer: "svg", }}
            style={{ height: "25vh", left: -20, top: 0 }}
        />
    )
}
function fillMissingColumns(alldata, colName, value) {
    if (alldata.indexes[colName] === undefined) {
        alldata["values"] = alldata.values.map((e) => e.concat([value]));
        alldata["columns"] = alldata.columns.concat(['errors']);
        alldata.indexes[colName] = Object.keys(alldata.indexes).length;
    }
}


function mergeOptions(data, args, criteria, uc) {
    if (!data || Object.keys(data).length === 0) return defaultOption
    const alldata = data.newperf
    // if (alldata.indexes['errors'] === undefined){
    //     alldata["values"] = alldata.values.map((e)=> e.concat([0]));
    //     alldata["columns"] = alldata.columns.concat(['errors']);
    //     alldata.indexes["errors"] = Object.keys(alldata.indexes).length;
    // }
    const mode_names = data.modelabels && data.modelabels.values ? data.modelabels.values.flat(1) : []
    console.log(mode_names)

    fillMissingColumns(alldata, "errors", 0)
    fillMissingColumns(alldata, "mode", -1)
    // const o1 = makeOptions(data.performance, 0)

    // console.log("performance ..", alldata)
    const o1 = makeOptionsPerformance(alldata, 0, mode_names, uc)
    //const o2 = makeOptions(data, 1)
    // const modevismap = getModeVisualMap(data.performance, o1.series)
    const modevismap = getModeVisualMap(alldata, o1.series, mode_names)
    const series = { series: [].concat.apply([], [o1.series,]) }
    return _.merge({}, defaultOption, o1.option, series, modevismap);


}
export const PerformanceBaseChart = ({data,onRenderComplete,setChartPriority}) => {
    const propValue = useMemo(()=>(data),[data])
  //  const priorityChartList = useCommonContext().priorityChart;

    const storeAction = useStoreActionContext()
   // const commonAction = useCommonActionContext()
    const criteria = useCriteriaContext()
    
    const onChartClick = (e) => {
        const data = e.data[0]
        const value = data.indexOf("/") > 0 ? data.split("/")[1] : data

    //    commonAction.setPriorityChart('availability_chart',true);
            setChartPriority();
        if (isPortfolio(criteria)) 
            storeAction.setSite(value)
        else if (isSite(criteria))
            storeAction.setZone(value)
        else if (isZone(criteria))
            storeAction.setDevice(value)    
    }

    const onChartFinish= (e) => {
       
        //if(priorityChartList && priorityChartList.length>0 && priorityChartList.includes('availability_chart'))
        //{
        //    commonAction.setPriorityChart('availability_chart',false); 
        //}     
    }

    return (
        <PerformanceBaseChart2 onChartClick={onChartClick} id={1} onChartFinish={onChartFinish} data={propValue} onRenderComplete={onRenderComplete}/>
    )
}

export const PerformanceBaseChart2 = React.memo(props => {
    return (
        <ReactEcharts className="mw-100 mh-100"
            option={props.data}
            onChartReady={props.onRenderComplete}
            opts={{ renderer: "svg" }}
            style={{ height: "50vh", left: -20, top: 0 }}

            onEvents= {{
                'click': props.onChartClick,
                //'finished': props.onChartFinish
            }}
        />
    )
},(prevProps, nextProps) => _.isEqual(1, 1))

function transform_performance(v, c) {
    //console.log(v, c);
    if (c > 0) return +v
    return v
}


const getUrls = async (criteria) => {
    
    const keyf = function (o) { return ['site_id', 'zone_id', 'device_id'] } //{ return o.site_id + '-' + o.zone_id}
    var grouptype = {}
    if (criteria && Object.keys(criteria).length > 0) {
        const gt = isPortfolio(criteria) ? "pf" : isSite(criteria) ? "sites" : isZone(criteria) ? "zones" : "rcs"
        // urls = urls.map((e) => Object.assign({},e, { grouptype: grouptype}))
        grouptype = { grouptype: gt }
    }
    
    const data = await new Promise((resolve,reject) => {
        getSites(criteria, resolve, reject)
    })
    

    if(Array.isArray(data)){
        if(data.length){
            var tenant
            if (criteria.site && criteria.site.length > 0) {
                const site = data.filter(site => site.site_id === criteria.site)
                if (site.length > 0 && site[0].tenant) {
                    tenant = {tenant: site[0].tenant}
                }
            }

            if(!isPortfolio(criteria)){
                return [
                    Object.assign({}, { name: "newperf", url: "performance_by_id", mergekey: keyf, }, grouptype),
                    Object.assign({}, { name: "modelabels", url: "mode_labels" }, tenant )
                ]
            }
            var tenantList = []
            data.forEach(s => {
                if(s.portfolios.includes(criteria.pf)){
                    tenantList.push(s.tenant)
                }
            })
            tenantList = [...new Set(tenantList)]
            if(tenantList.length > 1){
                return [
                    Object.assign({}, { name: "newperf", url: "performance_by_id_combined", mergekey: keyf, }, grouptype),
                    Object.assign({}, { name: "modelabels", url: "mode_labels_combined" }, tenant )
                ]
            }else{
                return [
                    Object.assign({}, { name: "newperf", url: "performance_by_id", mergekey: keyf, }, grouptype),
                    Object.assign({}, { name: "modelabels", url: "mode_labels" }, tenant )
                ]
            }
        }
    }

    
}

export function getPerformanceComps(criteria, uc) {
    const comps = { 
        title: 'Availability vs Errors', 
        tooltip: TooltipHelpContent.RCPerformance,
        chart: PerformanceBaseChart,
        urls: getUrls,
        default: defaultOption,
        parser: { f: mergeOptions, args: {} },
    }

    return comps 
}

const comps = getPerformanceComps()


const PerfChart = withData({ ...comps }) //withData(PerfChartBase, urls)
export default PerfChart;

//export default observer(PerfChart);



