import { merge } from 'lodash';
import React from 'react';
import { Line } from 'react-chartjs-2';
import nextId from 'react-id-generator';
export const minorXAxes = (unit = 'hour', round = false) => {
  var axes = {
    id: 'xAxis1',
    type: 'time',
    // time: {ough...looks like it's still showing 24 hours
    //   unit: 'day',
    //   format: 'timeFormat'
    // },
    time: {
      unit: unit,
      //round: "hour",
      displayFormats: {
        'hour': 'HH:mm',
        'day': 'MMM D'
      },

    },

    ticks: {
      fontColor: "#fff",
      maxTicksLimit: 20,
      maxRotation: 0,
      minRotation: 0,
      // autoSkip: true,
      beginAtZero: true,

    }
  }
  if (round) {
    return merge(axes, { time: { round: unit } })
  }
  return axes;
}

export const XAxes = (unit = 'day', round = false) => {

  var axes = {
    id: 'xAxis' + nextId(),
    type: 'time',
    time: {
      unit: unit,
      //round: "day",
      displayFormats: {
        'hour': 'HH:mm',
        'day': 'MMM D'
      },

    },
    ticks: {
      fontColor: "#fff",
      minTicksLimit: 1,
      maxTicksLimit: 6,
      maxRotation: 0,
      minRotation: 0,
      beginAtZero: true,

    }
  }
  if (round) {
    return merge(axes, { time: { round: unit } })
  }
  return axes;

}

export function majorYAxes(label, gridOn = false, stacked = true) {

  return {
    gridLines: {
      display: gridOn,
      color: "rgb(153, 153, 153, .3)",
      lineWidth: 1,
      drawOnChartArea: true
    },
    stacked: stacked,
    ticks: {
      fontColor: "#fff",
      maxTicksLimit: 5,
    },
    scaleLabel: {
      display: true,
      labelString: label,
      fontColor: "#fff"
    },
  }
}

const xAxesDefault = [XAxes('hour'), XAxes('day')]
const yAxesDefault = [majorYAxes('')]
const StackedAreaChart = ({ data, options, xAxes = xAxesDefault, yAxes = yAxesDefault, legend = "top" }) => {


  var options = {
    responsive: true,
    maintainAspectRatio: false,
    animationSteps: 100,
    animationEasing: "easeOutBounce",
    scales: {
      xAxes: xAxes,
      yAxes: yAxes,
    },
    legend: {
      position: legend,
      labels: {
        fontSize: 12,
        fontColor: "#fff",
        boxWidth: 15,
        usePointStyle: false,
        pointStyle: {
          radius: 1,
          borderWidth: 0
        },

      }
    }
  }
  //console.log("StackedAReachart   ", options)
  return (
    <div className="canvas-container-30" >
      <Line data={data} options={options} />
    </div>
  )
}

export default StackedAreaChart;