import moment from "moment";
import React from 'react';
import { IoMdArrowRoundDown, IoMdArrowRoundUp, IoMdWarning } from "react-icons/io";
import nextId from "react-id-generator";
import ReactTooltip from "react-tooltip";

function UpOrDownIcon(props) {
    const size = 20
    if (props.state === undefined) return <span style={{ marginLeft: '1.1rem' }}> </span>
    return (props.state < 0) ? <IoMdArrowRoundDown color="red" size={size} /> : <IoMdArrowRoundUp color="green" size={size} />

}

function UpOrDownIcon2(props) {
    const size = 20
    if (props.state === undefined) return <span style={{ marginLeft: '1.1rem' }}> </span>
    return (props.state < 0) ? <IoMdArrowRoundDown color="green" size={size} /> : <IoMdArrowRoundUp color="red" size={size} />

}

function AlertIcon(props) {
    const size = 12
    if (props.state === undefined) return <span style={{ marginLeft: '1.1rem' }}> </span>
    return (props.state) ? <img src="../assets/img/icon/icon-alert.svg" width="16" height="16"/> : <span style={{ marginLeft: '1.1rem' }} />

}

export const findSite = (row) => {
    //return row.data.find(x => x.siteId == row.row.original.siteId)

    const data = row.data.find(x => x.site_id === row.row.original.site_id)
    if (data) return { id: `${data.site_id}`, data: data }
}

export const findZone = (row) => {
    //return row.data.find(x => x.siteId == row.row.original.siteId)
    const data = row.data.find(x => x.zone_id === row.row.original.zone_id)
    if (data) return { id: `${data.site_id}-${data.zone_id}`, data: data }
}

export const findDevice = (row) => {
    //return row.data.find(x => x.siteId == row.row.original.siteId)
    //console.log(row.row.original.device_id)
    const data = row.data.find(x => x.device_id === row.row.original.device_id)
    if (data) return { id: `${data.site_id}-${data.zone_id}-${data.device_id}`, data: data }

}

export const filterBySite = (data, filterSiteId) => {
    return data.filter(e => e.site_id === filterSiteId)
}


export const filterByZone = (data, filterSiteId, filterByZoneId) => {
    return data.filter(e => e.site_id === filterSiteId && e.zone_id === filterByZoneId)
}

export const filterByDevice = (data, filterSiteId, filterByZoneId, filterByDeviceId) => {
    return data.filter(e => e.site_id === filterSiteId && e.zone_id === filterByZoneId && e.device_id === filterByDeviceId)
}


export const getSiteName = (result) => {
    const id = result.id
    const site = result.data

    const rowid = nextId(id) // `${site.site_id}-31`

    return (
        <>
            <span data-tip data-for={rowid}>
                {site.site_id}
            </span>
            {/* <ReactTooltip id={rowid} place="top" effect="solid"> */}
                {/* <b>{site.site_id}</b> */}

                {/* <br /><b>{(site.zone) ? site.zone.length : 0}</b>&nbsp;-&nbsp;Zones */}
                {/* <br /> <b>00/00/11</b> updates */}
                {/* </ReactTooltip> */}
        </>
    )
}

export const getZoneName = (result) => {
    const id = result.id
    const zone = result.data

    const rowid = nextId(id) // `${site.site_id}-31`
    return (
        <>
            <span data-tip data-for={rowid}>
                {zone.zone_id}
            </span>
            {/* <ReactTooltip id={rowid} place="top" effect="solid"> */}
                {/* <b>{zone.site_id} - {zone.zone_id}</b> */}

                {/* <br /><b>{(zone.device) ? zone.device.length : 0}</b>&nbsp;-&nbsp;Zones */}
                {/* <br /> <b>00/00/11</b> updates */}
                {/* </ReactTooltip> */}
        </>
    )

}
export const getDeviceName = (result) => {
    const id = result.id
    const rc = result.data

    const rowid = nextId(id) // `${site.site_id}-31`

    return (
        <>
            <span data-tip data-for={rowid}>
                {rc.device_id}
            </span>
            {/* <ReactTooltip id={rowid} place="top" effect="solid"> */}
                {/* <b>{rc.site_id} - {rc.zone_id} - {rc.device_id}</b> */}

                {/* <br /> <b>00/00/11</b> updates */}
                {/* </ReactTooltip> */}
        </>
    )

}
export const renderColumn = (row, f_finder, f_column) => {
    const result = f_finder(row)
    if (!result) {
        return <span data-tip={row.value}>{row.value}</span>
    }
    return f_column(result)
}

const ZoneUptime = (props) => {

    if (!props.data) {
        // console.log("props site empty")
        return ""
    }
    return (

        props.data.map((item, i) => {
            return <span> {item.zone_id}: {item.value},  {(i % 3 === 0) ? <br /> : "   "} </span>
        })
    )
}

export const ColumnUptime = (result) => {
    const id = result.id
    const obj = result.data

    const rowid = nextId(id)

    var uptimeState = undefined
    var prevUptime = undefined
    /*
    if (obj.uptime && obj.prevUptime && obj.prevUptime.uptime) {
        uptimeState = (obj.uptime - obj.prevUptime.uptime)
        prevUptime = obj.prevUptime.uptime
    }
    */
    return (
        <>
            <span data-tip data-for={rowid}>
                <span><UpOrDownIcon state={uptimeState} />
                </span>&nbsp;
                {obj.uptime}

            </span>

            {/* <ReactTooltip id={rowid} place="top" effect="solid"> */}
                {/* <b>{id}</b> */}
                {/* <br /> <span>Uptime: {obj.uptime} %</span> */}
                {/* <br /> <span>Previous: {prevUptime} %</span> */}
            {/* </ReactTooltip> */}
        </>
    )

}

export const ColumnTrackerComm = (result) => {
    const id = result.id
    const obj = result.data

    const rowid = nextId(id)

    var uptimeState = undefined
    var prevUptime = undefined
    
    return (
        <>
            <span data-tip data-for={rowid}>
                <span><UpOrDownIcon state={uptimeState} />
                </span>&nbsp;
                {obj.tracker_communication}

            </span>
        </>
    )

}

export const ColumnDeviceCount = (result) => {
    const id = result.id
    const obj = result.data

    const rowid = nextId(id)

    var deviceCountState = undefined
    var prevUptime = undefined
    /*
    if (obj.uptime && obj.prevUptime && obj.prevUptime.uptime) {
        uptimeState = (obj.uptime - obj.prevUptime.uptime)
        prevUptime = obj.prevUptime.uptime
    }
    */
    return (
        <>
            <span data-tip data-for={rowid}>
                <span><UpOrDownIcon state={deviceCountState} />
                </span>&nbsp;
                {obj.devicecount ? obj.devicecount : 0}

            </span>

            {/* <ReactTooltip id={rowid} place="top" effect="solid">
                <span>DeviceCount: {obj.devicecount}</span>
            </ReactTooltip> */}
        </>
    )

}
export const ColumnExpectedPower = (result) => {
    const id = result.id
    const obj = result.data

    const rowid = nextId(id)

    return (
        <>
            <span data-tip data-for={rowid}>
                {obj.expected_power}
            </span>

            <ReactTooltip id={rowid} place="top" effect="solid">
                <b>{id}</b>
                <br /> <span>Estimated: {obj.expected_power} KW</span>
                {/* <br /> <span>Previous: {prevUptime} %</span> */}
            </ReactTooltip>
        </>
    )

}
export const ColumnActualPower = (result) => {
    const id = result.id
    const obj = result.data

    const rowid = nextId(id)

    return (
        <>
            <span data-tip data-for={rowid}>
                {obj.actual_power}
            </span>

            <ReactTooltip id={rowid} place="top" effect="solid">
                <b>{id}</b>
                <br /> <span>Actual: {obj.actual_power} KW</span>
                {/* <br /> <span>Previous: {prevUptime} %</span> */}
            </ReactTooltip>
        </>
    )

}

export const ColumnPowerVariance = (result) => {
    const id = result.id
    const obj = result.data

    const rowid = nextId(id)
    const variance = (obj.expected_power?obj.expected_power:0) - (obj.actual_power?obj.actual_power:0);
    return (
        <>
            <span data-tip data-for={rowid}>
                {variance}
            </span>

            <ReactTooltip id={rowid} place="top" effect="solid">
                <b>{id}</b>
                <br /> <span>Uptime: {obj.actual_power} KW</span>
                {/* <br /> <span>Previous: {prevUptime} %</span> */}
            </ReactTooltip>
        </>
    )
}

export const defaultUptimeImpactValue = {uptimeImpact: 0, parentdevicecount: 0}
export function uptimeImpact (uptimeObj,  parentObj) {
    if (uptimeObj &&  parentObj) {
        return {
            uptimeImpact: (100*((1- uptimeObj.uptime / 100)* uptimeObj.devicecount/parentObj.devicecount)).toFixed(3),
            parentdevicecount: parentObj.devicecount
        }

    }
    return defaultUptimeImpactValue
 
}
export const ColumnUptimeImpact = (result) => {
    const id = result.id
    const obj = result.data

    const rowid = nextId(id)
    /*
    var uptimeimpact = undefined
    var devicecount = undefined
    var parentdevicecount = undefined
    
    if (obj.uptime && obj.devicecount && obj.parentRCCount) {
        devicecount = obj.devicecount
        parentdevicecount = obj.parentRCCount
        uptimeimpact = (100*((1- obj.uptime / 100)* devicecount/parentdevicecount)).toFixed(3)
    }*/
    
    return (
        <>
            <span data-tip data-for={rowid}>
                {/* <span>
                    <UpOrDownIcon state={uptimeimpact} />
                </span>&nbsp; */}
                {obj.uptimeImpact}

            </span>

            {/* <ReactTooltip id={rowid} place="top" effect="solid">
                <b>{id}</b>
                <br /> <span>RC Count: {obj.devicecount} of {obj.parentdevicecount} </span>
            </ReactTooltip> */}
        </>
    )

}
export const getSiteUptime = (result) => {

    return ColumnUptime(result)
}

const AlarmsTooltip = ({ alarms }) => {
    if (alarms) {
        //console.log("tooltip", alarms)
        return (
            <div>
                {alarms.map((e) => <div key={nextId()}>{e}</div>)}
            </div>
        )
    }
    else {

        return ""
    }
}

export function alarmData(obj){
    const alarms = obj.map(e => e.metric) || []
    return { alarms: obj.map(e => e.metric), alarmCount: alarms.length }
}
export const ColumnAlarms = (result) => {
    const id = result.id
    const obj = result.data
    const rowid = nextId(id)
    //const alarmCount = (obj.alarms) ? obj.alarms.length : 0
    //const alarms = (obj.alarms) ? obj.alarms.map(e => `<br> ${e}`): ""
    //console.log("Alarm value", obj)
    if (obj.alarmCount >= 0)
        return (
            <>
                <span data-tip data-for={rowid}>
                    {/* <span><UpOrDownIcon state={obj.alarmCount * -1} />
                    </span>&nbsp; */}
                    {obj.alarmCount}

                </span>


                <ReactTooltip id={rowid} place="top" effect="solid">
                    <b>{id}</b> - {obj.alarmCount}  Alarms
                <AlarmsTooltip alarms={obj.alarms} />
                </ReactTooltip>
            </>
        )
    else return (<></>)
}


export const ColumnErrors = (result) => {
    const id = result.id
    const obj = result.data

    const rowid = nextId(id)

    const errorValue = (obj.errorCount) ? obj.errorCount : 0;
    const rowCount = (obj.rowCount) ? obj.rowCount + " - Rows" : "";
    const errorCodes = (obj.errorCodes) ? obj.errorCodes : "";
    const totalRows = (obj.totalRows) ? obj.totalRows + " - Total Rows" : "";
    const rowsWithErrors = (obj.rowsWithErrors) + " - ErrorRows" ? obj.rowsWithErrors : "";
    const totalHrsAvail = (obj.totalHrsAvail) ? obj.totalHrsAvail + " - Hours Available" : "";
    const FailurePercent = (obj.FailurePercent) ? obj.FailurePercent : "";
    const hoursDownPerRow = (obj.hoursDownPerRow) ? obj.hoursDownPerRow + " - Hours Down" : "";


    var errorState = undefined
    var prevCount = ""
    if (obj.prevErrors && obj.prevErrors.errorCount && obj.errorCount) {
        errorState = (obj.errorCount - obj.prevErrors.errorCount)
        prevCount = obj.prevErrors.errorCount
    }
    if (obj.errorCount >= 0)
        return (
            <>
                <span data-tip data-for={rowid}>
                    <span>
                        <UpOrDownIcon2 state={errorState} />
                    </span>&nbsp;
                    {errorValue}

                </span>

                {/* <ReactTooltip id={rowid} place="top" effect="solid">
                    <b>{id} </b>
                    <br /> <span>{errorValue} Errors</span>
                    <br /> <span>{rowsWithErrors}</span>, <span>{rowCount}</span>, <span>{totalRows}</span>
                    <br /> <span>{totalHrsAvail}</span>, <span>{hoursDownPerRow}</span>
                    <br /> <span>{FailurePercent} % Failure</span>

                    <br /> <span>Row count: {rowCount}</span>
                    <br /> <span>Error Codes: {errorCodes}</span>
                    <br /> <span>Previous Count: {prevCount}</span>
                </ReactTooltip> */}
            </>
        )
    else return (<></>)
}

export const getSiteErrors = (result) => {
    return ColumnErrors(result)
}



export function getLastSeenTime(row) {
    // value in epoch seconds
    const usecs = parseInt(row.value);
    var start = moment.unix(usecs);
    const end = moment();
    var value = ''
    var alert = false;

    if (isNaN(usecs)) {
        value = "> ";
        start = moment().subtract(30, 'days')
    }
    // const start = moment.unix(usecs);
    // const end = moment();

   
    const duration = moment.duration(end.diff(start))
    console.log(duration.days(), duration.hours(), duration.minutes())
    if (duration.days() > 0 ){
         value += duration.days() + 'd '
         alert = true
    } 
    if (duration.hours() > 0){
        value += duration.hours() + 'h '
        alert = alert || true
    }
    if (duration.minutes() > 0) {
        value += duration.minutes() + 'm '
        //alert = alert || (duration.minutes() > 30)
    }
    if (duration.asMinutes() < 1){
        value = '< 1m'
        alert = false
    }
    const rowid = nextId();
    return ( 
        <span data-tip={start.date()} data-for={rowid}>
        <span>
            <AlertIcon state={alert} />
        </span>&nbsp;
        {value}

    </span>
    )

    
}