import axios from "axios";
import React from "react";
import { getAccessToken } from "../components/auth/userinfo";
import { GetFQURL } from "../components/model/store.swr";
import { notNeededMetrics } from "../metadata/modes.meta";
function urlGet(url, onSuccess, onError, urlFor){
    console.log("url",url)
    
    let token = getAccessToken()
   
    axios.get(url,{
      headers: {
      'x-access-token': token
      }
    })
    .then(function (response) {
        if(urlFor === 'metrics'){
          const data = response.data.filter(e => !notNeededMetrics.includes(e.metric))
          onSuccess(data)
          return
        }
        onSuccess(response.data)
    })
    .catch(function (error) {
        console.log(error);
        onError(error)
        
    })
    .then(function () {
        // always executed
    });

}

function urlPost(url, data, onSuccess, onError){
   
    let token = getAccessToken()
    var urlWithParams = url ;
    //+ compressAndEncode(url.split('?')[1]);


    axios.post(urlWithParams, data, {headers: {
      'x-access-token': token
      }
    })
    .then(function (response) {
      // console.log(url, response);
      onSuccess(response.data)
    })
    .catch(function (error) {
        console.log(error);
        onError(error)
        
    })
    .then(function () {
        // always executed
    });

}

export function getUserCharts(userId, onSuccess, onError) {
    urlGet(`/charts/u/${userId}`, onSuccess, onError)
}

export function getOrgCharts(org, userId, onSuccess, onError) {
    urlGet(`/charts/o/${org}/${userId}`, onSuccess, onError)
}

export function getPortfolioDevices(criteria, onSuccess, onError){
    // const devicesUrl = { name: "devices", url: "all_portfolio_devices", format: "json", nocache: true }
    // const url = GetFQURL(devicesUrl, criteria, "json", true);
    // urlGet(url, onSuccess, onError)
    urlPost("/meta?querytype=meta_portfolio_devices&restype=json", {user_id: criteria.user, portfolio_id: criteria.pf}, onSuccess, onError)
    
}

export function getSiteDevices(criteria, onSuccess, onError){
  urlPost("/meta?querytype=meta_site_devices&restype=json", {user_id: criteria.user, portfolio_id: criteria.pf, site_id: criteria.site}, onSuccess, onError)
}

export function getSites(criteria, onSuccess, onError){
  urlPost("/meta?querytype=meta_sites&restype=json", {}, onSuccess, onError)
}
export function getDevicesAsOptions(dlist) {
    // console.log("Creating device options", sites)
    // console.log(" dlist ", dlist)
    if (!dlist  || dlist.length == 0) {
      console.log("Dlist is empty")
      return []
    }
    // const sortAString = (a, b) => a.group_id > b.group_id ? 1 : a.group_id < b.group_id ? -1 : 0
    const formatOption = (e) => { return {label: e, value: e} }
    // const formatDevice = (e) => formatOption( `${e.site_id}/${e.zone_id}/${e.device_id}`)
    const formatDevice = (e) => { const e1 = `${e.site_id}/${e.zone_id}/${e.device_id}`; return {label: e.tracker_id? `${e1} -${e.tracker_id}`: e1, value: e1} } 
    const formatZone = (e) => `${e.site_id}/${e.zone_id}`

    const sites = [...new Set(dlist.map(item => item.site_id))].map(formatOption);
    const zones = [...new Set(dlist.map(item => `${item.site_id}/${item.zone_id}`))].map(formatOption);
    const devices = dlist.map(formatDevice)

    return [...sites, ...zones, ...devices]
    /*
    const options = sites.map(site_id => {
        const obj = {}

        const siteDevices = dlist.filter(f => f.site_id === site_id)
        const devices = siteDevices.map(formatDevice)
        // const zones = [...new Set(siteDevices.map(formatZone))]

        obj['label'] = site_id
        obj['options'] = siteDevices.map(formatDevice).map(e => {
            return { label: e, value: e }
        })
        return obj
    })
    console.log("Device options are like ", options)
    return options
    */

}

export function getAllMetrics(criteria, onSuccess, onError){
    const metricsUrl = { name: "metrics", url: "all_unique_metrics", format: "json", nocache: false }
    const url = GetFQURL(metricsUrl, criteria, "json", false);
    urlGet(url, onSuccess, onError, 'metrics')

}

const createGroup = (groupName, value, options, setValue) => {
    return {
      label: (() => {
        return (
          <div
            onClick={() => { 
                console.log("group value", value);
                setValue(value)
             }
            }
          >
            {groupName}
          </div>
        );
      })(),
      value: value,
      options: options
    };
  };

  const createOption = (optionName, value, setValue) => {
    return {
      label: (() => {
        return (
          <div
            onClick={() =>
              setValue(value)
            }
          >
            {optionName}
          </div>
        );
      })(),
      value: value
    };
  };

function filterMetricList(v, f) {
  return v.filter(metric => f.includes(metric.group_id.charAt(1)))
}
  
export function filterMetricsAsPerCriteria(data, criteria){
  return (criteria.site === "") ? filterMetricList(data, ['Z']) : (criteria.zone.length > 0) ? filterMetricList(data, ['R','I']) : data;

}

export function getMetricsAsOptions(mlist, onGroupSelect) {
  
    const groupDesc = { 'AR': 'RC Alerts', 'AZ': 'ZC Alerts', 'VR': 'RC Data', 'VZ': 'ZC Data', 'SR': 'RC Status', 'SZ': 'ZC Status', 'VI': 'Inverter', 'PR': 'RC Performance', 'CM': 'Custom Metrics', 'RS': 'Live Status Snapshots' }
    const sortAString = (a, b) => a.group_id > b.group_id ? 1 : a.group_id < b.group_id ? -1 : 0

    const options = Object.keys(groupDesc).map(gid => {
        const obj = createGroup(groupDesc[gid], gid,  mlist.filter(f => f.group_id === gid).sort(sortAString).map(e => {
            return { label: e.metric, value: e }
        }), onGroupSelect)
        // const obj = {}
        // obj['label'] = groupDesc[gid]
        // obj['value'] = gid
        // obj['options'] = mlist.filter(f => f.group_id === gid).sort(sortAString).map(e => {
        //     return { label: e.metric, value: e }
        // })
        return obj
    })
    return options
}

export function getChartsAsOptions(clist) {
    function getChartLabel( c){
        // return `${c.metric.label}-${c.datatype.label}-${c.interval.label}`
        return c.charts.reduce((acc, cur) => {
            const o = acc.length>0? acc + "/" : ""
            return `${o}(${cur.metric}.${cur.aggr})`
        }, "")
        //return `${c.metrics[0].label}.${c.metrics.length> 1? ' / '+ c.metrics[1].label: ''}`
    }
    const pages = [...new Set(clist.map(item => item.page))];
    // console.log("Getting Charts ", pages)
    const tobject = {}
    // const pages = ['page1', 'page2']
    const options = pages.map(p => {
        return { ...tobject, label: p, options: clist.filter(f=> f.page === p).map((e) => { 
            return { ...tobject, label: `${e.title} ${getChartLabel(e)}`, value: e } 
        }) }
    })
    return options
}

export function getDashboardAsOptions(clist) {
  const pages = [...new Set(clist.map(item => item.page))];
  const options = pages.map(p => {
    return {
      label: p,
      value: p // You can set 'value' to the same value as 'label' or to a different value if needed
    };
  });

    return options


  
}