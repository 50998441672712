import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { AiFillFilter, AiOutlineDelete } from 'react-icons/ai'


import { useRealtimeContext } from "../../context/realtime.context"

const FilterInfo = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    right: 80px;
    top: 14px;
`

const FilterDetails = styled.div`
    background: white; 
    position: absolute; 
    border: 0px solid rgba(0, 0, 0, .15);
    box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, .175);
    border-radius: 4px;
    padding: 20px;
    width: 440px;
    top: 50px;
    right: 80px;
    z-index: 10;

    .heading {
        color: #014059;
        font-weight: 700;
    }

    .tag {
        padding: 3px 8px;
        border: 1px solid #014059;
        border-radius: 16px;
        color: #014059;
        min-width: 40px;
        text-align: center;
    }

    .filter-row {
        display: flex;
        justify-content: space-between;

        .actions {
            width: 18px;
        }
    }

    .filter-container {
        padding: 10px 0px;
        border-bottom: 1px solid #cecece;
    }

    .tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 8px 0px;
    }

    .connector {
        border-bottom: 1px solid #014059;
        width: 60px;
        position: relative;
        bottom: 12px;
    }

    .clear {
        color: #014059;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
`

const FilterCount = styled.div`
    padding: 0px 5px;
    background: red;
    border-radius: 3px;
    position: absolute;
    right: 70px;
    top: 5px;
    z-index: 2;
    color: white;
    font-size: 12px;
    font-weight: 800;
`

export default props => {

    const realtimeContext = useRealtimeContext()
    const { filterRowAttrs, clearFilters, deleteFilter } = realtimeContext

    const [open, setOpen] = useState(false)
    const ref = useRef()

    useEffect(() => {
        const hideDetails = (event) => {
            if (ref.current && !ref.current.contains(event.target))
                setOpen(false)
        }

        document.addEventListener('click', hideDetails, true)

        return () => {
            document.removeEventListener('click', hideDetails, true)
        }
    })

    const clear = () => {
        clearFilters()
        setOpen(false)
    }

    const deleteF = dataField => {
        const left = deleteFilter(dataField)
        if (left === 0)
            setOpen(false)
    }
    
    const getFiltersApplied = () => {
        return filterRowAttrs.map(curr => {
        
            return {
                dataField: curr.dataField,
                text: curr.text,
                filters: curr.type === 'encoded' ? 
                    curr.filterData.length > curr.selectedFilters.length ? curr.filterData.filter(t => curr.selectedFilters.indexOf(t.value) >= 0).map(t => t.label) : [] : 
                    curr.filterData[0] < curr.selectedFilters[0] || curr.filterData[1]  > curr.selectedFilters[1] ? [curr.selectedFilters[0], curr.selectedFilters[1]] : [],
                type: curr.type
            }
            
        }).filter(t => t.filters.length > 0)
    }

    const filtersApplied = getFiltersApplied()

    if (filtersApplied.length === 0)
        return null

    return <React.Fragment>
        <FilterInfo onClick={() => setOpen(true)}>
            <AiFillFilter size="24px" color="#014059"/>
        </FilterInfo>
        <FilterCount>
            <span>{filtersApplied.length}</span>
        </FilterCount>
        {open ? <FilterDetails ref={ref}>
            <span>Filters applied:</span>
            <span className="clear" onClick={clear}  >Clear All</span>
            {filtersApplied.map(filter => <div className="filter-container">
                    <span className="heading">{filter.text}</span>
                    <div className="filter-row">
                        <div className="tags" style={{gap: filter.type === 'encoded' ? 5 : 0}}>
                            {filter.type === 'encoded' ? 
                                filter.filters.map(selected => <span className="tag">{selected}  </span>) :
                                <React.Fragment>
                                    <span className="tag">{filter.filters[0]}</span>
                                    <span className="connector" />
                                    <span className="tag">{filter.filters[1]}</span>
                                </React.Fragment>
                            }
                        </div>
                        <div className="actions">
                        <AiOutlineDelete onClick={() => deleteF( filter.dataField )} size="16px" color="#014059" title="Clear"/>
                        </div>
                </div>
            </div>)}
        </FilterDetails> : null}
    </React.Fragment>
}