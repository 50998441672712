import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import React from "react";
import withData from "../model/combined";
//var dateFormat = require('dateformat');


function timeFunction(f, l){
    console.time(l)
    const v = f()
    console.timeEnd(l)
}

const defaultOption = {
    title: { text: '' },
    tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross', snap: true },
        position: function (pos, params, el, elRect, size) {
            var obj = { top: 40, bottom: 80 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
        },
        backgroundColor: 'rgba(255,255,205,0.7)',
        extraCssText: 'width: 170px'
    },
    axisPointer: { label: { backgroundColor: '#777' } },
    grid: [
        { left: '10%', right: '10%', height: '80%', bottom: '50%', top: '5%' },
        //{ left: '10%', right: '10%', top: '50%', height: '40%', bottom: '5%' }
    ],
    dataZoom: [
        { type: 'inside', xAxisIndex: [0], start: 0, end: 100 },
        { type: 'slider', xAxisIndex: [0], show: true, top: '94%', left: '15%', right: '60%', width: '70%', height: '3%', start: 0, end: 100 }
    ],

    toolbox: {
        show: true,
        iconStyle: {borderColor: 'rgba(228, 228, 228, 0.8)'},
        //emphasis: {iconStyle: {borderColor: 'rgba(28, 28, 228, 0.8)'}},
        feature: {
            dataZoom: { yAxisIndex: "none" },
            dataView: { readOnly: false },
            magicType: { type: ["line", "bar", 'stack'] },
            restore: {},
            saveAsImage: {}
        }
    },


}

const nameStyle = { color: 'white', fontSize: 16 }

function todate(secs) {
    return new Date(secs * 1000)
}
function addHexColor(c1, c2) {
    var hexStr = (parseInt(c1, 16) + parseInt(c2, 16)).toString(16);
    while (hexStr.length < 6) { hexStr = '0' + hexStr; } // Zero pad.
    return hexStr;
}
function getRandomColor(x) {
    var letters = '0123456789ABCDEF';
    var lc = '#';
    var dc = '#';
    var l = 0;
    for (var i = 0; i < 6; i++) {
        l = Math.floor((Math.random() * 14) + 1);
        lc += letters[l];
        dc += letters[Math.min(l + 2, 15)]
    }
    //var dc = addHexColor(color, '020202')
    return lc //{lc: lc, dc:  dc};
}

function getCalcAngle(diff, calcAngledata) {
    //if (calcAngledata && calcAngledata.length < 1) return []
    const d1 = calcAngledata//.slice(1)
    const iTimestamp = 0
    const iCalcAngle = 1

    const d = d1.map(e => {
        //const c = eval(e[iCalcAngle])
        return [e[iTimestamp], e[iCalcAngle]]
    })
    const option = {
        name: 'Calculated-Angles',
        type: 'line',
        data: d,
        lineStyle: {
            type: 'solid',
            color: 'rgba(210,210,210,0.3)',
            width: 5,
            zlevel: 0,
            //shadowBlur: 50,
            //shadowColor: 'rgba(0,0,0,0.5)',
            showSymbol: false
        },
        symbol: "none",
        gridIndex: 0,// (diff) ? 0 : 1,
        xAxisIndex: 0, //(diff) ? 0 : 1,
        yAxisIndex: 0, //(diff) ? 0 : 1,

    }
    return option

}
function getSummaryCurrentAngle(diff, calcAngledata) {
    //if (calcAngledata && calcAngledata.length < 1) return []
    const d1 = calcAngledata//.slice(1)
    const iTimestamp = 0
    const iCurrentAngle = 2

    const d = d1.map(e => {
        //const c = eval(e[iCalcAngle])
        return [e[iTimestamp], e[iCurrentAngle]]
    })
    const option = {
        name: 'Summary-Angles',
        type: 'line',
        data: d,
        lineStyle: {
            type: 'solid',
            color: 'rgba(255,64,64,0.9)',
            width: 1,
            zlevel: 0,
            //shadowBlur: 50,
            //shadowColor: 'rgba(0,0,0,0.5)',
            showSymbol: false
        },
        symbol: "none",
        gridIndex: 0,// (diff) ? 0 : 1,
        xAxisIndex: 0, //(diff) ? 0 : 1,
        yAxisIndex: 0, //(diff) ? 0 : 1,

    }
    return option

}
function getSeriesData(d, i) {
    return {
        data: d.ydata,
        smooth: true,
        symbol: false,
        itemStyle: {
            width: 1,
            color: (d.diff) ? getRandomColor(i) : 'rgba(100,100,100,0.5)'
        },
        type: 'line',
        name: d.device,
        emphasis: {
            focus: 'series'
        },
        zlevel: 1,
        gridIndex: 0,//(d.diff) ? 0 : 1,
        xAxisIndex: 0,//(d.diff) ? 0 : 1,
        yAxisIndex: 0,//(d.diff) ? 0 : 1,
    }
}
function getAnglesSeriesData(devices, angles, diff) {
    const legendArray = []
    const iDeviceId = 0
    const iAngles = 1
    const seriesArray = !devices ? []:  devices.map((d, i) => {
        const name = d//e[iDeviceId]
        const d_angles = angles.filter(f => f[iDeviceId] === name).map(tsa => [tsa[1], tsa[2]])

        //const ts_angles = (d_angles)? eval(d_angles[iAngles]):[]
        //console.log((i===0)?d_angles:'')

        const ydata = d_angles//(ts_angles)? ts_angles.map(tsa=> [todate(+tsa[0]), +tsa[1]]): []
        //const ddata = angles.filter(f => f[iDeviceId] === name)
        //const ydata = ddata.map(e => [todate(+e[iTimestamp]), +e[iCurrentAngle]])
        //const diff = (ddata.reduce((a, c) => a + c[iDiff], 0) > 0)
        const errs = Math.floor((Math.random() * 500) + 0)
        const color = (diff) ? getRandomColor(i) : 'rgba(200,200,200,0.5)'
        const ldata = { name: name, icon: diff ? 'triangle' : 'pin'  }
        legendArray.push(ldata)
        return getSeriesData({ device: name, diff: diff, color: color, ydata: ydata, errors: errs }, i)
    })
    return { series: seriesArray, legend: legendArray }
}

function makeOptions(alldata, args, criteria) {

    const summary = args? args.summary : false
    // console.log("angles", alldata)
    //console.log("charting ", pa, eam)
    if (!alldata || !alldata.currentAngles || !alldata.calcAngleMode || !alldata.diffDevices) return defaultOption

    const pa = alldata.currentAngles.values.length > 0 ? alldata.currentAngles.values : []
    const eam = alldata.calcAngleMode.values.length > 0 ? alldata.calcAngleMode.values : []
    const dfd = alldata.diffDevices.values.length > 0 ? alldata.diffDevices.values : []
    //console.log("alldata", pa, eam, dfd)
    const iDiffDevices = 0
    const iNoDiffDevices = 1
    const iTimestamp = 0
    const iCurrentAngle = 2

    const angles = pa
    //const devices = [... new Set(angles.map(e => e[iDeviceId]))] 
    const timestamps = [... new Set(eam.map(e => +e[iTimestamp]))] 
    const ldataDiff = []
    const ldataNonDiff = []
    const diffDevices = dfd && dfd[0][iDiffDevices] ? dfd[0][iDiffDevices] : []
    const nodiffDevices = dfd && dfd[0][iNoDiffDevices]? dfd[0][iNoDiffDevices] : []
    //console.log("dfd", diffDevices, nodiffDevices)

    var series_legend_nodiff = [] //getAnglesSeriesData(nodiffDevices, pa, false)
    var series_legend_diff = !summary? getAnglesSeriesData(diffDevices, pa, true): [] 

    const calcLegend = [{ name: 'Calculated-Angles', selectedMode: true }]
    //const summaryAngleLegend = summary? diffDevices.map(d => { return { name: d, selectedMode: false }}):[]
    const summaryAngleLegend = summary? [{ name: 'Summary-Angles', selectedMode: true }]:[]
    const diffLegend = (!summary && series_legend_diff && series_legend_diff.legend) ? series_legend_diff.legend : []
    const nodiffLegend = (!summary && series_legend_nodiff && series_legend_nodiff.legend) ? series_legend_nodiff.legend : []
    //console.log("summary legends ", summaryAngleLegend)
    const legenddata = [].concat.apply([], [calcLegend, summaryAngleLegend, diffLegend, nodiffLegend])


    const xAxisData = timestamps
    const calcAngleList = [
    //    getCalcAngle(false, eam), 
        getCalcAngle(true, eam)
    ]
    const summaryAngleSeries = summary?getSummaryCurrentAngle(true, eam):[]

    const allseries = [].concat.apply([], [calcAngleList, summaryAngleSeries, series_legend_diff.series, series_legend_nodiff.series])
    //console.log(xAxisData)
    //console.log(allseries)
    const option = {
        legend: { type: 'scroll', top: '5%', height: '90%', right: 0, orient: 'vertical', data: legenddata, selector: true, textStyle: { color: "#eee"} },
        xAxis: [
            { data: xAxisData, type: 'time', nameLocation: 'middle' },
            //{ data: xAxisData, gridIndex: 1, nameLocation: 'middle', type: 'time' }
        ],
        yAxis: [
            {
                name: 'RCs with Bad Tracking ', 
                scale: true, nameLocation: 'middle', nameGap: 30,
                splitLine: {lineStyle: {width: 1, color: "rgba(127,127,127,0.5"}},
                nameTextStyle: { align: 'left', fontWeight: 'bolder', fontSize: 12, color: '#eee' }
            },
            /*{
                name: 'RCs Tracking normally ', nameLocation: 'middle', nameGap: 30,
                splitLine: {lineStyle: {width: 1, color: "rgba(127,127,127,0.5"}},
                nameTextStyle: { align: 'left', fontWeight: 'bolder', fontSize: 12, color: '#eee' },
                gridIndex: 1
            }*/],
        series: allseries,

    };


    // console.log(option)
    return _.merge({}, defaultOption, option);
}
/*
setInterval(function () {
    addData(true);
    myChart.setOption({
        xAxis: {
            data: date
        },
        series: [{
            name:'sdfsd',
            data: data
        }]
    });
}, 500);*/

const CurrentAnglesChartBase = (props) => {

    //console.log(defaultOption)
    return (
        <ReactEcharts
            option={props.data}
            // style={{ height: "80vh", left: 50, top: 50, width: "90vw" }}
            style={{ height: "40vh", left: -20, top: 0 }}
        //   opts={{ renderer: "svg" }}

        />

    )
}
const SummaryCurrentAnglesChartBase = (props) => {

    return (
        <ReactEcharts
            option={props.data}
            // style={{ height: "80vh", left: 50, top: 50, width: "90vw" }}
            style={{ height: "40vh", left: -20, top: 0 }}
        //   opts={{ renderer: "svg" }}

        />

    )
}
const interval = 1;
function transform_calc_angles(v, c) {
    //console.log(v, c);
    if (c === 0) return todate(v); //timestamp
    return +v;
}

function transform_diff_devices(v, c) {
    //console.log(v);
    console.time("eval diff")
    const values = eval(v)
    console.timeEnd("eval diff")
    return values;
}

function transform_current_angles_array(v, c) {
    if (c === "angles") {
        const angles = eval(v);
        return angles.map((e) => [todate(e[0]), e[1]]);
    }
    return v;
}
function transform_current_angles(v, c) {
    if (c === 1) return todate(v); //timestamp
    if (c === 2) return +v; //currentangle
    return v;
}


const keyf = function (o) { return ['site_id', 'zone_id', 'device_id', 'timestamp'] } //{ return o.site_id + '-' + o.zone_id}
const urls = [
    { name: "currentAngles", url: "rc_current_angle_csv", slice: interval, transform: transform_current_angles },
    { name: "calcAngleMode", url: "rc_calc_angle_mode_csv", slice: interval, transform: transform_calc_angles },
    { name: "diffDevices", url: "rc_diff_devices_csv", slice: interval, transform: transform_diff_devices },
]


const comps1 = { 
    title: 'Current Angles ', 
    chart: CurrentAnglesChartBase, 
    urls: urls, 
    default: defaultOption, 
    parser: {f: makeOptions, args: { summary: false} } 
}
const CurrentAnglesChart = withData({ ...comps1 }) //withData(PerfChartBase, urls)

const comps2 = { 
    title: 'Summary Current Angles ', 
    chart: SummaryCurrentAnglesChartBase, 
    urls: urls, 
    default: defaultOption, 
    parser: {f: makeOptions, args: { summary: true} } 
}
export const SummaryCurrentAnglesChart = withData({ ...comps2 })

export default CurrentAnglesChart;
