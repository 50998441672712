//import { useCriteriaActionContext, useCriteriaContext } from "./criteria/criteria.context";
import { toJS } from "mobx";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import ReactDOMServer from 'react-dom/server';
import nextId from "react-id-generator";
import { CardFull as Card, CardHeader } from "../components/layout/Card";
import { GetFQURLs } from "../components/model/store.swr";
import { useCriteriaContext } from "../context/criteria.context";
import { InfoTooltip } from "./info-tooltip";
import { parseUrls } from "./papafetch";
import {createActivityLog} from '../utils/apiCall'
import { getLevel, getLogData } from "../helpers/common"
import { isDevice, isPortfolio, isSite, isZone } from "../components/layout/criteria";
import pageContext, {mainPage, usePageContext} from "../context/page.context"
import {useCommonActionContext} from "../context/common.context"
import {useUserContext} from '../context/user.context'

import {
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { getUserId, getUserToken } from "../components/auth/userinfo";
import {viewIds} from '../constant/constant'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { errorCodes, mode_names } from "../metadata/modes.meta";
import _ from 'lodash'
import { LogActivity } from "../components/stats/stats.overview";
import { useAvailabilityContext } from "../context/availability.context";
let processedUrls = [];


export function DownloadCsv (props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const criteria = useCriteriaContext()
    const pageContext = usePageContext()
    /*const csvReport = {
        filename:filename+'.csv',
        headers: headers,
        data: data
    };*/   
    var logData
    const level = getLevel(criteria)
    if(props.data.chartType === 'composite'){
        logData = getLogData(criteria, {featureId: `download-custom-chart`, usecase: `download custom chart`, category: 'overview', level: level, details: {...props.data.details, fileType: 'csv'}})
    }else{
        logData = getLogData(criteria, {featureId: `download ${level} ${props.data.title}`.toLowerCase().split(" ").join("-"), usecase: `download ${level} ${props.data.title} chart`.toLowerCase(), category: 'overview', level: level, details: {tenant: props.data.tenant, fileType: 'csv'}})
    }

    

    const handlePdfDownload = (data) => {
        logData.details = JSON.parse(logData.details)
        if(typeof logData.details === 'string'){
            logData.details = JSON.parse(logData.details)
        }
        logData.details['fileType'] = 'pdf'
        logData.details = JSON.stringify(logData.details)
        createActivityLog(logData)
        const head = []
        const body = []

        props.data.data.forEach((arr,index) => {
            index ? body.push(arr) : head.push(arr)
        })
        const fileName = data.filename.split(".")[0]
        const doc = new jsPDF("l")
        autoTable(doc, {head: head, body: body, horizontalPageBreak: true, horizontalPageBreakRepeat: 0})
        doc.save(`${fileName}.pdf`)
    }
    if(logData)
    logData.details = logData.details;

    return <>
        {/* <div className="iop card-button">
            {<CSVLink {...props.data}><i className="fas fa-download" aria-hidden="true"></i></CSVLink> }
            <CSVLink {...props.data}><img src="../assets/img/icon/icon-download.svg" alt="Download" width="16" height="16" className="chart-download" /></CSVLink>
        </div> */}

        <div className="drop-down-menu">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle ><img src="../assets/img/icon/icon-download.svg" alt="Download" width="16" height="16" className="chart-download" /></DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={() => createActivityLog(logData)}><CSVLink {...props.data}><img src="../assets/img/icon/icon-csv.svg" alt="Download" width="24" height="24" className="chart-download-menu-icon" />Download as CSV</CSVLink></DropdownItem>
                    {
                        props.data.chartType === 'composite' ? null :
                            <DropdownItem onClick={() => handlePdfDownload(props.data)}><img src="../assets/img/icon/icon-pdf.svg" alt="Download" width="24" height="24" className="chart-download-menu-icon" />Download as PDF</DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
            {/* <CSVLink {...props.data}><i className="fas fa-download" aria-hidden="true"></i></CSVLink> */}
        </div>
    </>
}

// function encodeFilename(params, title) {
//     const keys = Object.keys(params).filter(f=> f !== 'project')
//     return keys.length
//         ? keys
//             .map(key => {
//                 const value = params[key]
//                 if (value && typeof value === "object"){
//                     return key + "-" + encodeFilename(value)
//                 }else{
//                     return encodeURIComponent(value)
//                 }
//             } )
//             .join("-")
//         : ""
// }

export function encodeFilename(params, title) {
    // const keys = Object.keys(params).filter(f=> f !== 'project')
    const keys = ["pf", "site", "zone", "device"]
    let name = ""
    for(let k of keys) {
        const value = params[k]
        if(value){
            name += "-" + value
        }
    }
    return title + name
}

const constructCompositeChartCsvData = (keys,props) => {
 
    var data, columns
    keys.forEach((key,index) => {
        if(index === 0){            
            data = _.cloneDeep(props.response[key].values ? props.response[key].values : [])
            columns = props.response[key].columns.slice()
            if(columns && columns.length > 1 && (columns[1].includes('currentMode') || columns[1].includes('errorCode'))){
                data.forEach(ar => {
                    ar.forEach((e,i) => {
                        if(i){
                            ar[i] = columns[1].includes('currentMode') ? mode_names[parseInt(e)] : errorCodes[parseInt(e)]
                        }
                    })
                })
            }        
        }else{
            var c = props.response[key].columns.slice().splice(1,props.response[key].columns.length-1)
            var array = data.map((arr,i) => {
                var el = props.response[key].values[i] ? props.response[key].values[i].slice() : []
                if(c && c.length && (c[0].includes('currentMode') || c[0].includes("errorCode"))){
                    el.forEach((e,i) => {
                        if(i){
                            el[i] = c[0].includes('currentMode') ? mode_names[parseInt(e)] : errorCodes[parseInt(e)]
                        }
                    })
                }
                var a = [...arr, ...el.splice(1,el.length-1)]
                return a
            })
            data = array
            
            columns = [...columns, ...c]
        }
        
    })
    console.log("columns",columns)
    if (columns[1].trim() === ''){
        const oneDotZeroIndices = columns.reduce((indices, col, index) => {
            if (col.includes('1.0')) {
                indices.push(index);
            }
            return indices;
        }, []);
        const filteredColumns = columns.filter((col) => col === 'timestamp' || col.includes('1.0'));
        const timestampIndex = columns.indexOf('timestamp');
        const filteredData = data.map((row) => [getSiteLocalTime(row[timestampIndex]), ...oneDotZeroIndices.map((index) => row[index])]);
        return [filteredColumns, ...filteredData];
    }
    else {
        for (let i = 1; i < data.length; i++) {
            // Convert the timestamp in each row to local time
            data[i][0] = getSiteLocalTime(data[i][0]);
        }
        data.unshift(columns)  
        return data 
    }
}


const constructCompositeChartgroupCsvData = (keys, props) => {
    let data = [];
    let metricsRow = [];
    let zone_value=props.criteria.zone
    const columnValue = props.response["data-0"].columns[1].split("(")[0];
    // Determine whether the column value is a number or not
    if (/^-?\d*\.?\d+$/.test(columnValue)) {
        metricsRow = ["Value", ""];
    } else {
        metricsRow = ["DID", ""];
    }
    let columns = ["Metric","Timestamp"];
    let timestampRow = ["Timestamp"];
    keys.forEach((key, index) => {
        props.response[key].values.forEach((row, rowIndex) => {
            if (!data[rowIndex]) {
                data[rowIndex] = [];
            }
            row.forEach((value, columnIndex) => {
                if (!data[rowIndex][columnIndex]) {
                    data[rowIndex][columnIndex] = [];
                }
                data[rowIndex][columnIndex].push(value);

               });
        });
    });
    const rowLength = data.length > 0 ? data[0].length : 0; // Determine the length of rows in the data
    if (props.response["data-0"] && props.response["data-1"]) {
        // the below code is to push the DID/Value and Metrics name to the metricsRow array
        for (let i = 0; i < rowLength; i++) {
            // Check if both data sets have columns at index i and if they are not timestamp
                if(  props.response["data-0"].columns[i] &&
                props.response["data-1"].columns[i] && props.response["data-0"].columns[i] !== "timestamp" && props.response["data-1"].columns[i] !== "timestamp" ){
                // Add metrics(and ZoneID if available )for the first and second data set respectively
                metricsRow.push(zone_value ? zone_value + "/" + props.response["data-0"].columns[i].split("(")[0] : props.response["data-0"].columns[i].split("(")[0]); 
                columns.push(props.response["data-0"].columns[i].split("(")[1].slice(0, -1));
                metricsRow.push(zone_value ? zone_value + "/" + props.response["data-1"].columns[i].split("(")[0] : props.response["data-1"].columns[i].split("(")[0]);
                columns.push( props.response["data-1"].columns[i].split("(")[1].slice(0, -1));
                }   
        }   
    }   
    data = data.map(row => row.flatMap(componentValues => componentValues));
    let combinedData = [metricsRow,columns,timestampRow, ...data];
    combinedData = combinedData.map(row => {
        row.splice(1, 1); // Remove the second element (index 1) from each row
        return row;
    });
    for (let i = 3; i < combinedData.length; i++) {
        // Convert the timestamp in each row to local time
        combinedData[i][0] = getSiteLocalTime(combinedData[i][0]);
    }
    return combinedData;
}

const getSiteLocalTime = timestamp => {
    const utcTime = moment.utc(timestamp)
    const siteTime = moment(utcTime.format())
    return siteTime.format("YYYY-MM-DD HH:mm:ss")
}

const Header = (props) => {
    const datakeys = props.response  ? Object.keys(props.response) : []
    var data = null
    var columns = null
    let indexes = null;
    if (props.response && datakeys.length > 0 ){
        if((props.chartType === 'composite')&& (datakeys.length === 1 )){
            data = constructCompositeChartCsvData(datakeys,props)
        }
        else if ((props.chartType === 'composite')&& (datakeys.length === 2 )){
            const columns = props.response['data-0'].columns
            if (columns.includes("timestamp") && columns.includes("") && columns.some(column => column.startsWith("1.0("))) {
                data = constructCompositeChartCsvData(datakeys,props)
              } else {
                data = constructCompositeChartgroupCsvData(datakeys,props)
              }  
        }
        else{
            data = props.response[datakeys[0]].values.slice()
            columns = props.response[datakeys[0]].columns
            data.unshift(columns)
        }    
    }
    const enableDownload = (props && props.criteria && data && !props.loading)
    const filename = enableDownload ? encodeFilename(props.criteria, props.title) + '.csv' :''
    const tooltip = props.tooltip ? (
        <div>
            <h5>{props.tooltip.header}</h5>
            {props.tooltip.body}
        </div>
    ) : ''
    const time = moment()
    if(props.title === 'Historical Performance' && data){   
        data = data.map((d,index) => {
            const dCopy = [...d.slice(0,1), ...d.slice(2,5)]
            if(index > 0 && moment.unix(dCopy[0]).utc().format("YYYY-MM-DD HH:mm:ss") !== 'Invalid Date' && moment.unix(dCopy[1]).utc().format("YYYY-MM-DD HH:mm:ss") !== 'Invalid Date'){
                dCopy[0] = moment.unix(dCopy[0]).utc().format("YYYY-MM-DD HH:mm:ss")
                // dCopy[1] = moment.unix(dCopy[1]).utc().format("YYYY-MM-DD HH:mm:ss")
            }else{
                dCopy[1] = 'positional_accuracy'
            }
            return dCopy
        })
    }else if(data && props.chartType !== 'composite' && props.title !== "Availability vs Errors" && props.title !== "Availability Impact Summary – Path to 99%"){
        data = data.map((d,index) => {
            const dCopy = [...d]
            if(index > 0 && getSiteLocalTime(dCopy[0]) !== 'Invalid date'){
                dCopy[0] = getSiteLocalTime(dCopy[0])
            }
            return dCopy
        })
    }

    return (
        <CardHeader title={props.title} loading={props.loading} doc={"Help"}>
            {/* <DownloadCsv_Uptime data={response.data.rcuptime} filename={"rc_uptime_overview_"+criteria.timetype} /> */}
            {(props.showTimezone || props.chartType === 'composite') && (
            <div style={{
                marginRight: 20,
                fontSize: 12,
                color: "#8693AB"
            }}>
                Timezone: {time.tz() || 'Local'}
            </div>
        )}
            {enableDownload && <DownloadCsv data={{filename: filename, data: data, title: props.title, chartType:  props.chartType, details: {...props.chartMetaData}, tenant: props.tenant}} />}
            <div className="card-button">
                <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} />
            </div>
            {/* {!data && <span> missing ... </span> } */}
        </CardHeader>
    )
}

const intervalFor = ["Errors Summary", "Modes Summary", 
'Wind Speed Summary (m/s)', 
'Wind Direction Summary (degrees)', 'Current Angle Summary', 'Flood Level Summary (mm)', 'Snow Fall Summary (mm)', 'Active Power Summary']


const withData = (props) => {
    
    return () => {
        
        const criteria = useCriteriaContext();
        const userContext = useUserContext()
        const pageContext = usePageContext()
        const [refresh, setRefesh] = useState("")
        const [interval,setInterval] = useState((criteria && criteria.interval) ? criteria.interval : 60);
        const [tenant,setTenant] = useState('')
        const [userAction, setUserAction] = useState(false)
        // const [data, setData] = useState(props.default || [])
        const [loading, setLoading] = useState(true)
        const [urlResponse, setUrlResponse] = useState(null)
        const [chartRef, setChartRef] = useState()
        const [isSelectAll, setIsSelectAll] = useState(true);
        const commonAction = useCommonActionContext()
        const [currentCrumbs, setCurrentCrumbs] = useState({pf: criteria.pf, site: criteria.site, zone: criteria.zone, device: criteria.device, Te: criteria.Te, Ts: criteria.Ts})
        const [level, setLevel] = useState(getLevel(criteria))
        const availabilityContext = useAvailabilityContext()
        const data = useMemo(() => {
            // if (loading){
            //     // console.log("Still loading !!!!")
            //     return props.default
            // }
            // console.log("urlReponse changed ", urlResponse)
            
            if (validateResponseData()) {
                return (props.parser && props.parser.f ) ? props.parser.f(urlResponse, props.parser.args, toJS(criteria), userContext, availabilityContext.modeSummaryData) : urlResponse
            }else {
                // console.log("URL Response Empty! ")
                return props.default
            }
        }, [urlResponse, loading]);
        console.log("chartsssssss",isSelectAll)

        function values() {
            return {
                data: data,
                isLoading: () => { return loading }
            }
        }

        function validateResponseData(urlData){
            var urlRes = urlData ? urlData : urlResponse
            var isValid = (urlRes  && Object.keys(urlRes).length > 0 ) 
            const hasAnyValue = (prev, cur) => prev || (urlRes[cur] && urlRes[cur].values && urlRes[cur].values.length > 0)
            isValid = isValid && Object.keys(urlRes).reduce(hasAnyValue, false);
            // console.log(isValid, "Response valid ", urlResponse)
            return isValid
        }

        const RenderHeader = () => {
            const v = useMemo(() => {
                return {
                    loading: loading,
                    title: props.title,
                    tooltip: props.tooltip,
                    criteria: criteria,
                    response: urlResponse,
                    chartType: props.chartType,
                    tenant: tenant,
                    chartMetaData: props.chartMetaData,
                    showTimezone: props.showTimezone
                }
            }, [loading])
            if (props && props.noheader) return ""
            // const downloadOptions = {title: view.head + " > " + view.title, url: GetFQURL({ name: "unknown", url: view.url, grouptype: view.grouptype,  slice: view.interval }, criteria, "csv")}



            if (!props.header) {
                return Header({ ...v })
            }
            return props.header({ ...v })


        }

        function onRenderComplete(){
            console.log('#####----------------------CHART RENDER COMPLETE ---', loading, data, props.default) 
            // setChartRef(chartObj)
            if (loading) {
                setLoading(false);
            }
        }
        function setChartPriority(){
            commonAction.setPriorityChart('availability_chart',true);
        }
        

        const RenderBody = () => {
            const intervals = {
                5: '5 mins',
                10: '10 mins',
                15: '15 mins',
                30: '30 mins',
                60: '1 hour',
                720: '12 hours',
                1440: '1 day'
            }

            // console.log("")


            
            const v = useMemo(() => {

                return {
                    data: data,
                    options: props.options,
                    onRenderComplete: onRenderComplete,
                    setChartRef: setChartRef,
                    setChartPriority:setChartPriority
                }
            }, [data])

            const handleLegends = (event) => {
                console.log("HandleLegendseventIn",event)
                setIsSelectAll(event.target.checked)
                const selected = {}
                
                if(!event.target.checked){
                    if(props.chartType === 'composite'){
                        data.dataset && data.dataset.forEach((set, index) => {
                            set.dimensions.forEach(d => {
                                if (d && !d.includes("0.0(")) {
                                    selected[d] = false;
                                }
                                else {
                                    selected[d] = false;
                                }
                                
                            });
                        });
                    }else{
                        data.dataset && data.dataset.dimensions && data.dataset.dimensions.forEach(d => selected[d] = false)
                    }
                    
                }else{
                    if (props.chartType === 'composite') {
                        data.dataset && data.dataset.forEach((set, index) => {
                            set.dimensions.forEach(d => {
                                console.log("dddd",d)
                                if (d && !d.includes("0.0(")) { 
                                    selected[d] = true;
                                }
                                else {
                                    selected[d] = false;
                                }
                                
                            });
                        });
                    }
                    else{
                        data.dataset && data.dataset.dimensions && data.dataset.dimensions.forEach(d => selected[d] = true)
                    }
                    
                }
                data.legend['selected'] = selected   
            }

            if (props && !props.chart){
                return (
                    <>    
                    <div style={{ textAlign: 'center' }}> No chart available </div>                    
                    </>
                )
            }
            else {
                var style = {}
                if(isPortfolio(criteria) && (props.title === 'Errors Summary' || props.title === 'Modes Summary') && userContext.tenants.length > 1){
                    style['position'] = 'relative'
                    style['left'] = '5px'
                }

                return (
                    <>
                        {
                             
                            intervalFor.includes(props.title) ? (
                                <>
                                {
                                    isPortfolio(criteria) && (props.title === 'Errors Summary' || props.title === 'Modes Summary') && userContext.tenants.length > 1 ? (
                                        <select  value={tenant} name="tenant" onChange={(event) =>  {updateProcessedCharts(props.title); setTenant(event.target.value); setUserAction(true)}} >{
                                            userContext.tenants.map(t => <option  value={t}>{t === 'gc' ? 'Game Change' : 'FTC'}</option>)   
                                        }
                                        </select>
                                    ) : null
                                }
                                <select style={style} value={interval} name="interval" onChange={(event) => {updateProcessedCharts(props.title); setInterval(event.target.value); setUserAction(true)}} >{
                                        Object.keys(intervals).map(k => <option  value={k}>{intervals[k]}</option>)   
                                    }
                                    </select>
                                    <input checked={isSelectAll} onChange={handleLegends} style={{position: 'relative', top: '4px', left: '5px', height: '17px'}} type="checkbox" id="legends" name="legends" value={isSelectAll}/>
                                <label style={{position: 'relative', left: '5px'}} for="legends">All</label>
                            </>
                        ): props.chartType === 'composite' ? <>
                            <input checked={isSelectAll} onChange={handleLegends} style={{position: 'relative', top: '4px', left: '5px', height: '17px'}} type="checkbox" id="legends" name="legends" value={isSelectAll}/>
                            <label style={{position: 'relative', left: '5px'}} for="legends">All</label>
                        </> : null
                        }
                    
                        
                        {props.chart({ ...v })}
                    </>
                    
                )
            }
        }

        
        const RenderNoCard = () => {
            return (
                <>
                    <Card>
                        <RenderHeader />

                        <div style={{ textAlign: "center",
                        //  height:50, 
                        fontWeight: 400,
                        fontSize: '14px',
                        color: '#8693AB', 
                        flex:1,
                         width:"100%", 
                         justifyContent:'center',
                            padding:20,
                            margin:20,
                         alignItems:'center'}}> No data available </div>
                    </Card>
                </>
            )
        }

        const RenderCard = () => {
            return (
                <Card>
                    <RenderHeader />
                    <RenderBody />
                </Card>
            )
        }


        /*function setParsedData(data) {
            //console.log("setting result")
            setData(data)
            
        }*/

        const updateProcessedCharts = (title) => {
            const featureId =   `view ${level} ${title}`.toLowerCase().split(" ").join("-")
            Object.keys(window.processedCharts).forEach(key => {
                const index = window.processedCharts[key].findIndex(id => id === featureId)
                if(index){window.processedCharts[key].splice(index,1)}
            })
        }

        const updateUrlResponse = (urldata) => {
            const data = {...urldata} 
            const idIndex = data.newperf.indexes['id']
            data.newperf.columns.push('availability')
            data.newperf.indexes['availability'] = data.newperf.columns.indexOf('availability')
            availabilityContext.availability.map(item => {
                var index 
                if(isPortfolio(criteria)){
                    index = data.newperf.values.findIndex(v => v[idIndex] === `${item.site_id}`)
                    if(!item.uptime){
                        data.newperf.values.splice(index,1)
                    }else{
                        data.newperf.values[index].push(item.uptime)    
                    }
                    
                }else if(isSite(criteria)){
                    index = data.newperf.values.findIndex(v => v[idIndex] === `${item.site_id}/${item.zone_id}`)
                }else if(isZone(criteria)){
                    index = data.newperf.values.findIndex(v => v[idIndex] === `${item.zone_id}/${item.device_id}`)
                }else{
                    index = data.newperf.values.findIndex(v => v[idIndex] === `${item.device_id}`)
                }

                if(!isPortfolio(criteria)){
                    data.newperf.values[index].push(item.uptime)
                }

            })
            return data
        }

        
 
        const processor = {            
            _urls: [],
            _abortController: undefined,

            init: function (abortController) { setLoading(true); setUrlResponse(null); this._abortController = abortController; return this },
            // getUrls: function () { this._urls = GetFQURLs(props.urls, criteria, "csv"); console.log(this.urls); return this },
            getUrls: async function () { 
                const level = getLevel(criteria)
                const logData  = getLogData(criteria, {featureId: `view ${level} ${props.title}`.toLowerCase().split(" ").join("-"), usecase: `view ${level} ${props.title} chart`.toLowerCase(), category: 'overview', level: level, details: JSON.stringify({tenant: tenant})})
                if(logData)
                logData['userAction'] = userAction
                const urls = GetFQURLs(typeof props.urls === 'function' ? await props.urls(criteria) : props.urls, criteria, "csv",interval,props.title,tenant,logData); 
                /*this.print(urls);*/ return urls
            },
            print: function(message, data) { /*console.log('#####-------------------------', message, data)*/ },
            finish: function () {   this._abortController.abort();  },
            // applyData: function (data) { setData(props.parser ? props.parser.f(data, props.parser.args, toJS(criteria)) : data) },

            fetchData: async function () { 
                let urls = await this.getUrls();
                let urlList = urls.map(x=>x.url);
                if (processedUrls.find(item => urlList.includes(item))) {
                    return true
                } else {
                    parseUrls(urls, this._abortController, props.title , props.chartType, (urldata) => { 
                        if(props.title === 'Availability Impact Summary – Path to 99%'){
                            // urldata = updateUrlResponse(urldata)
                            const uptimeIndex = urldata.uptime.indexes['uptime']
                            urldata.uptime.values.forEach((v,index) => {
                                if(v[uptimeIndex] !== 0 && !v[uptimeIndex]){
                                    v[uptimeIndex] = 'No Data'
                                }
                            })
                            urldata['availabilityAvg'] = availabilityContext.availability
                            
                        }

                        if(props.title == 'Availability vs Errors'){
                            if(urldata && urldata.newperf && urldata.newperf.values.length){
                                urldata.newperf.values.forEach(v => {
                                    if(parseFloat(v[4]) < 0){
                                        v[4] = 'No Data'
                                    }
                                })
                            }
                        }

                        if(props.title === 'Modes Summary'){
                            console.log("urldata result",urldata)
                            if(urldata && urldata.result && urldata.result.values){
                                const dates = []
                                if(urldata.result.values.length > 0){
                                    const timestampIndex = urldata.result.indexes['timestamp']
                                    urldata.result.values.forEach(v => {
                                        const date = v[timestampIndex].split(" ")[0]
                                        if(!dates.find(d => d === date)){
                                            dates.push(date)
                                        }
                                    })
                                }
                                
                                if(dates.length)
                                    availabilityContext.setModeSummaryData(dates)
                                // storeAction.setModeSumData(dates)
                            }
                            
                        }

                        this.print(urldata); 
                        setUrlResponse(urldata) 
                        this.finish();  
                    });
                    // parseUrls(urls, this._abortController, props.title , props.chartType, (urldata) => { 
                    //     console.log("urldata++++",urldata)
                    //     this.print(urldata); 
                    //     setUrlResponse(urldata) 
                    //     this.finish();  
                    // });
                    // if(props.title !== 'Availability Impact Summary – Path to 99%'){
                        
                    // }else{
                    //     if(!_.isEmpty(availabilityContext.impactSummaryData)){
                    //         setUrlResponse(availabilityContext.impactSummaryData)
                    //         this.finish();
                    //     }
                    // }
                    processedUrls.push(urlList);
                    return this;
                }
            },
        }

        // useEffect(() => {
        //     if(props.title !== 'Availability Impact Summary – Path to 99%' && !_.isEmpty(availabilityContext.impactSummaryData)){
        //         debugger
        //         setUrlResponse(availabilityContext.impactSummaryData)
        //     }
        // },[availabilityContext.impactSummaryData])

        

        useEffect(() => {
            setUserAction(false)
        },[criteria])


        useEffect(() => {
            console.log("HOC Criteria change reported ", props.title)

            let isSubscribed = true;
            // const curls = GetFQURLs(props.urls, criteria, "csv")
            // parseUrls(curls, setResult)
            let controller = new AbortController();
            const id = nextId(controller)
            // console.log(id, '>>')
            processor.init(controller).fetchData(interval)
            // processor.init(controller).getUrls().fetchData()
            // console.log(id, '>>')
            /*
            var f = (props.chunks)? FetchDataChunks : FetchData 
            f(props.urls, criteria).then(res =>  {
                if (isSubscribed){
                    console.log(res)
                    setData(res)
                    setLoading(false)
                }

            })*/


            //
            return () => {
                //cleanup
                // console.log('cleanup')
                isSubscribed = false
                processedUrls = []
                // setLoading(false)
            }
        }, [criteria.pf, criteria.site, criteria.zone, criteria.device, criteria.Te, criteria.Ts,tenant,interval])
        // console.log(criteria)
        if ((!loading && !validateResponseData() && props.hideEmpty) || (!loading&&
            data.dataset &&
            data.dataset.length > 0 &&
            data.dataset[0].dimensions &&
            data.dataset[0].dimensions.length === 3 &&
            data.dataset[0].dimensions[0] === "timestamp" &&
            data.dataset[0].dimensions[1]===''&&
            data.dataset[0].dimensions[2] === undefined
          )){
            return( props.chartType == 'composite') ? <RenderNoCard/> : null  
        } 
        return  data && props.nocard ? 
        null : <RenderCard/> 
        
    }

    // DataComponent.displayName = `withData(${WrappedComponent.name})`
    // DataComponent.displayName = `withData(${"comp" + nextId()})`
    // return DataComponent;
}

export default withData;