import _ from "lodash";
import { maxOfArray, stripTimestampData } from "./echart.datafuncs";
import { labelOptionABC } from "./echarts.label";



export const defaultPieOption = {
    grid: [
        { left: '5%', width: '95%', height: "95%", top: "5%" },
        //{left: '4%', top: '10%', width: '38%', height: '38%'},
        //{right: '7%', top: '7%', width: '38%', height: '38%'},
        //{left: '7%', bottom: '7%', width: '38%', height: '38%'},
        //{right: '7%', bottom: '7%', width: '38%', height: '38%'}
    ],
    xAxis: [
        { show: false },
        //axisStyle("", -40, 1)
    ],
    yAxis: [
        { type: 'category', show: false, },

        //axisStyle("Uptime %", 30, 1)
    ],
    legend: { type: 'scroll', bottom: '0%', orient: 'horizontal', /*data: legenddata, selector: true,*/ textStyle: { color: "#4C4345", fontWeight: "400", fontFamily: "'Montserrat', sans-serif;", fontSize: "10" } },
    tooltip: {
        confine: true,
        axisPointer: { type: 'cross', snap: true },
        backgroundColor: '#B7D0DA',
        textStyle: { color: "#014059", fontSize: "14px", overflow: 'break' },
        extraCssText: 'max-height:400px; width:170px; overflow:auto; white-space:pre-wrap;'

    },


}

const pieTemplate = {
    type: 'pie',
    radius: [0, "70%"],
    top: '0%',
    bottom: "15%",
    height: 'auto',
    // left: 'right',
    width: 'auto%',
    z: 10,
    itemStyle: {

        borderColor: '#8693AB',
        borderWidth: 1,
        opacity: 0.75
    },
    center: ['50%', '50%'],
    label: labelOptionABC,
    // labelLayout: { y: '10%', verticalAlign: 'top', moverOverlap: 'shiftY'}
    /*labelLayout: (params) => {
        console.log(params)
        return {
            x:  + params.rect.x + params.rect.width - (params.labelRect.width * params.dataIndex) ,
            y:  params.rect.y - params.rect.height, //(params.dataIndex * params.rect.height + params.labelRect.height),
            verticalAlign: 'top',
            align: 'right',
            // fontSize: Math.max(params.rect.width / 10, 5)
        }
    }*/
    // data: data [ {name: '', value: 23}, {name: 'ads', value: 983} ]
};
function getMajoritySeries(data, iTimestamp) {
    const summaryTotal = stripTimestampData(maxOfArray(data.values), iTimestamp)
    const majorityData = stripTimestampData(data.columns, iTimestamp).map((e, i) => { return { name: e, value: summaryTotal[i] } })

    return Object.assign({}, pieTemplate, { name: 'majority', type: 'pie', data: majorityData })

    // return Object.assign({}, summaryBarTemplate, {xAxisIndex: 1, yAxisIndex:1, name: 'majority', type: 'bar', stack: true, data: [10, 20, 55] })
}
export function makePieOptions(alldata, args, criteria) {
    // console.log("line chart data", alldata, args)
    const data = alldata.result

    if (!data) return defaultPieOption

    const iTimestamp = data.indexes[args.options.timeColumn]

    const option = {
        //legend: { type: 'scroll', top: '5%', height: '90%', right: 0, orient: 'vertical', data: legenddata, selector: true, textStyle: { color: "#eee"} },
        dataset: {
            source: data.values,
            dimensions: data.columns
        },
        legend: {},
        xAxis: [],
        yAxis: [],
        series: _.concat(getMajoritySeries(data, iTimestamp)),
        // tooltip: args.options.tooltip === 'category'? getToolTipCategory(iTimestamp): getToolTipNonZero(iTimestamp)

    };
    // console.log(option)
    return _.merge({}, defaultPieOption, option);
}

