import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleTimeOutModal = ({showModal, handleContinue, handleLogout, remainingTime}) => {
   
    useEffect(()=>{
      var interval = setTimeout(() => {
          handleLogout();
      }, 30000)
      return (() => {
          clearTimeout(interval);
      })

    },[])
    return (
        <Modal show={true} onHide={handleContinue} style={{width:"400px",left:"40%",top:"25%"}} className='idleModal'>
            <Modal.Header closeButton>
            <Modal.Title>You Have Been Idle!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your session is Timed Out. You want to stay?</Modal.Body>
            <Modal.Footer>
            <Button variant="danger" onClick={handleLogout}>
                Logout
            </Button>
            <Button variant="primary" onClick={handleContinue}>
                Continue Session
            </Button>
            </Modal.Footer>
        </Modal>
    )
}