import React, { useState } from "react";
import nextId from "react-id-generator";
import styled from "styled-components";

const Styles = styled.div`

.clickable {
    border-style: solid;
    border-width: 1px;
    border-color: #aaa;
    padding: 4px 16px;
    background-image: linear-gradient(180deg,#528ad6,#006cff);
    cursor: pointer;
    font-size: .79375rem;
    text-align: center;
    float: right;
    /* margin-right: 10px; */
    border-radius: 4px;
}
.download-clickable {
    float: right;
    margin-bottom: 20px;
}
.form-outline {
    display: flex;
}
.form-control {
    border-width: 0px;
    padding: 4px 16px;
    cursor: auto;
    font-size: 14px;
    text-align: left;
    border-radius: 4px;
    background-color: #fff;
    height: 43px;
    color: #014059;
    border: solid 1px #014059;
}

.form-control::hover {
    border-style: solid;
    border-width: 1px;
    border-color: #aaa;

}

.form-check {
    display: inline-block;
    margin: 4px 16px;
    cursor: auto;
    font-size: .79375rem;
    vertical-align: middle;
}
.form-check-input {
    margin: 3px -30px;
    width: 24px;
    height: 24px;
    border: solid 1px #01668D;
    border-radius: 4px
}
.form-check-label {
    margin: 4px 8px 4px 4px;
    font-size: 16px;
    color: #014059;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    transition: .4s;
    width: 16px;
    height: 16px;
    left: 2px;
    top: 2px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
  }
  
  input:checked + .slider {
    background-color: #01668D;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #01668D;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  label {
    font-size: 14px;
    color: #014059;
  }
`

const ButtonControl = (props) => {

    return (
        <Styles>
            <div className="download-clickable" onClick={props.handleClick}> <img src="../assets/img/icon/icon-download.svg" alt="Download" width="16" height="16" className="chart-download" /> </div>
        </Styles>
    )
}

export const TextControl = (props) => {
    //const [hasFocus, setFocus] = useState(false);
    const [value, setValue] = useState(props.value)

    const key = nextId("textbox")

    function handleChange(e) {
        setValue(e.target.value)

    }
    function handleBlur(e) {
        props.handleChange(value)
    }

    return (
        <Styles>
            {/* <input onChange={props.handleChange} >{props.value}</input> */}
            <div className="form-outline">
                <input
                    type="text"
                    id={key}
                    className="form-control"
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}

                />
                {/* <label className="form-label" for="form12">{props.title}</label> */}

            </div>
        </Styles>

    )
}

export const CheckBoxControl = (props) => {

    const key = nextId("checked")
    function handleChange(e) {
        props.handleChange(e.target.checked)
    }

    return (
        <Styles style={{display: "inline-block"}}>
            {/* <input onChange={props.handleChange} >{props.value}</input> */}
                <div className="form-check" >
                    <input className="form-check-input" type="checkbox" id={key} checked={props.value} onChange={handleChange} />
                    <label className="form-check-label" htmlFor={key}>
                        {props.title}
                    </label>
                </div>
        </Styles>

    )
}

export const SwitchControl = (props) => {
    const key = nextId("checked")
    function handleChange(e) {
        props.handleChange(e.target.checked)
    }

    return (
        <Styles>
            <div><label>{props.title}</label></div>
            <label className="switch">
                <input type="checkbox" id={key} checked={props.value} onChange={handleChange} />
                <span className="slider round"></span>
            </label>
        </Styles>

    )
}
export default ButtonControl;