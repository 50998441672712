import { toJS } from "mobx";
import React, { useEffect, useMemo, useState } from "react";
import { useCriteriaContext } from "../../context/criteria.context";
import { mainPage, modelPage, detailsPage, usePageActionContext, usePageContext, userPage, sessionPage, settingsPage,energyMeterPage, maintenancePage, addonfeaturePage,changePasswordPage ,diffusePage  } from "../../context/page.context";

import { useUserContext } from "../../context/user.context";
import { getValueTypeGroup, isDevice, isPortfolio, isSite, isZone } from "./criteria";
import SideNavbar from "./sidenavbar";
import {getLogData} from '../../helpers/common'
import { useStore } from "../../context/store.context";
import { getUserID, isAdminUser, isHailAdminUser,ischangePasswordPage } from "../auth/userinfo";

import { usePageNotFoundContext } from "../../context/pageNotFound.context";
import _ from 'lodash'

const defaultChartData = { datatype: {}, metric: {}, interval: {}, page: "", title: "", charttype: {}, grouptype: {}, stacked: true, filled: false, majority: true, percent: false, titleX: "", titleY: "" }

function getChartCompValue(f) {
    console.log("Chart !!!", f)
    if (!f || !f.key) {
        return defaultChartData
    }
    const comp = {
        title: f.title,
        page: f.page,
        source: `${f.datatype.value}_${f.metric.value.metric}`,
        charttype: f.charttype.value,
        interval: f.interval.value,
        grouptype: f.grouptype.value,
        hideEmpty: true,
        // chartOptions: { timeColumn: 'timestamp', stacked: false, fill: true, titleX: '', titleY: '' } 
        chartOptions: { timeColumn: 'timestamp', showMajority: f.majority, showPercent: f.percent, stacked: f.stacked, fill: f.filled, titleX: f.titleX, titleY: f.titleY }
    }
    console.log("Chart Saved !!!", f, comp)
    return comp

}
export function getChartCompValue2(c1, criteria) {
    var chartMetaData = {id: c1.chartId, meta: c1.charts.map(c => { return {metric: c.metric,  aggr: c.aggr}})}
    var c2 = {
        title: c1.title, charttype: 'composite', chartMetaData: chartMetaData
    }
    const c2defs = c1.charts.map((c, i) => {
        const metricsArray = Array.isArray(c1.metrics) ? c1.metrics : [];
        const m = metricsArray.find((f) => f.value.metric === c.metric);
        // const m = c1.metrics.find((f) => f.value.metric === c.metric)
        if (!m) {
            return undefined //{v: "metric not found "}
        }
        c2.title = c2.title + " - " + m.label
        const vtg = getValueTypeGroup(c.query, m.value.group_id) // .find((f)=> f.valueType === c.valueType && f.metricType === m.value.group_id.charAt(1))
        if (!vtg) {
            return undefined // {v: "VTG not found  "}
        }
        const cs = toJS(criteria)
        // console.log(c1.title, c)
        // console.log(cs)
        // console.log(vtg)
        // console.log("PF ", vtg.valid.pf, isPortfolio(criteria))
        // console.log("Sites ", vtg.valid.site, isSite(criteria))
        // console.log("Zones ", vtg.valid.zone, isZone(criteria))
        // console.log("Device ", vtg.valid.device, isDevice(criteria))

        if ((vtg.valid.pf && isPortfolio(criteria))
            || (vtg.valid.site && isSite(criteria))
            || (vtg.valid.zone && isZone(criteria))
            || (vtg.valid.device && isDevice(criteria))
        ) {
            // { id: "data-0", source: "readings1byidcount_errorCode", grouptype: 'rcs', interval: 30 },
            // { sourceId: "data-0", charttype: 'line', timeColumn: 'timestamp', showMajority: false, showPercent: false, stacked: true, fill: true, titleX: '', titleY: 'Device Count' },

            return {
                source: { id: `data-${i}`, source: `${c.query}_${c.metric}`, metricName: c.metric, grouptype: c.groupType, interval: parseInt(c.interval),  metricGroup: m.value.group_id, aggr: c.aggr, metricType: vtg.metricType },
                options: {
                    sourceId: `data-${i}`, charttype: c.chartType, timeColumn: 'timestamp',
                    showMajority: c.summary === 'Yes', showPercent: c.summary === 'Yes',
                    stacked: c.stacked === 'Yes', fill: c.filled === 'Yes', titleX: c1.titleX, titleY: c.titleY
                },

            }
        }
    }).filter((f) => f !== undefined)
    console.log("chart def....",c2defs)
    c2["sources"] = c2defs.map((c, i) => c.source)
    c2["chartOptions"] = c2defs.map((c, i) => c.options)
    //   console.log(c2)
    return c2
}


function fetchJSONData(url, onSuccess, onError) {
    fetch(url)
        .then(res => res.json())
        .then(data => {
            // if (!ref.current) return null
            onSuccess(data)
        })
        .catch(err => {
            onError(err)
        });
}

const UserChartNav = (props) => {
    const pageAction = usePageActionContext()
    const pageContext = usePageContext()
    const userContext = useUserContext()
    const pageNotFoundContext = usePageNotFoundContext();
    // const selected = useMemo(()=> {return pageContext.page},[pageContext.page])
    const [selected, setSelected] = useState(pageContext.page)
    const [userCharts, setUserCharts] = useState(userContext.charts)
    const userCatalogName = userPage;
    const criteriaData = toJS(useStore().criteria)
    const criteria = useCriteriaContext()
    const chartNavData = useMemo(() => 
    { return getPageNavData(userCatalogName, getUserChartPages(userCatalogName, userContext.charts)) }, 
    [userContext.charts, criteria]) //useState([]);
    
    
    function getPageNavData(userCatalogName, userPages) {
        const level = isPortfolio(criteria) ? 'pf' : isSite(criteria) ? 'site' : isZone(criteria) ? 'zone' : isDevice(criteria) ? 'rc' : null
        const overviewChartPages = [
            { 'id': "summary", 'title': "Summary", 'items': [], sub: true, group: mainPage, for: ['pf', 'site', 'zone'], keys: [`view ${level} summary`]},
            { 'id': "performance", 'title': "Performance", 'items': [], sub: true, group: mainPage, for: ['pf', 'site', 'zone', 'rc'], keys: [`view ${level} historical performance`]},
            { 'id': "availability", 'title': "Availability", 'items': [], sub: true, group: mainPage, for: ['pf', 'site', 'zone', 'rc'], keys: [`view ${level} availability vs erros`, `view ${level} Availability Impact Summary – Path to 99%`]},
            { 'id': "errors", 'title': "Errors", 'items': [], sub: true, group: mainPage, for: ['pf', 'site', 'zone', 'rc'], keys: [`view ${level} errors summary`]},
            { 'id': "modes", 'title': "Modes", 'items': [], sub: true, group: mainPage, for: ['pf', 'site', 'zone', 'rc'], keys: [`view ${level} modes summary`]},
            { 'id': "sensors", 'title': "Sensors", 'items': [], sub: true, group: mainPage, for: ['site', 'zone']},
            { 'id': "angles", 'title': "Angles", 'items': [], sub: true, group: mainPage, for: ['zone', 'rc']},
            { 'id': "power", 'title': "Power", 'items': [], sub: true, group: mainPage, for: ['site', 'zone'], keys: [`view ${level} active power summary`]}
        ]
        const modelViewerPages = [
            { 'id': "trackerAvailability", 'title': "Availability", 'items': [], sub: true, group: modelPage},
            { 'id': "currentMode", 'title': "Mode", 'items': [], sub: true, group: modelPage},
            { 'id': "currentAngle", 'title': "Angle", 'items': [], sub: true, group: modelPage},
            { 'id': "Over_current_Fault_01", 'title': "Over Current", 'items': [], sub: true, group: modelPage},
            { 'id': "errorCode", 'title': "Error", 'items': [], sub: true, group: modelPage }
        ]
        const overviewPageName = mainPage
        const overviewPages = [
            { 'id': overviewPageName, 'title': overviewPageName, 'items': [] },
            ...overviewChartPages.filter(page => page.for.indexOf(level) >= 0).map((page, i) => ({...page, first: i === 0}))
        ]

        const modelPageName = modelPage
        const detailsPageName = detailsPage
        const detailsPages = [{ 'id': detailsPageName, 'title': detailsPageName, 'items': [] }]
        const modelPages = [
            { 'id': modelPageName, 'title': modelPageName, 'items': [] },
            ...modelViewerPages
        ]
        const EnergyMeterPageName = energyMeterPage
        const EnergyMeterPages = [{ 'id': EnergyMeterPageName, 'title': EnergyMeterPageName, 'items': [] }]

        const sessionPage = "User Analytics"
        const sessionPageName = sessionPage
        const sessionPages = [
            { 'id': sessionPageName, 'title': sessionPageName, 'items': [] },
        ];

        const settingsPage ="Settings"
        const settingsPageName = settingsPage
        const settingsPages =[
            {'id':settingsPageName, "title": settingsPageName, 'items': []},
        ]

        const diffusePage ="Diffuse"
        const diffusePageName = diffusePage
        const diffusePages = [
            { 'id': diffusePageName, 'title': diffusePageName, 'items': [] },
        ]

        const addonfeature ="addonfeature"
        const addonfeaturePageName = addonfeature
        const addonfeaturePages = [
            { 'id': addonfeaturePageName, 'title': addonfeaturePageName, 'items': [] },
        ];

        const changePassword ="changePassword"
        const changePasswordPageName = changePassword
        const changePasswordPages = [
            { 'id': changePasswordPageName, 'title': changePasswordPageName, 'items': [] },
        ];


        const isVisibleSettings = level === 'site';

        const maintenancePageName = "Site Maintenance"
        const maintenancePages = [
            { 'id': maintenancePageName, 'title': maintenancePageName, 'items': [] },
        ]

        var arr = [
            { id: overviewPageName, items: overviewPages, grouptype: false, visible: true },
            { id: detailsPageName, items: detailsPages, grouptype: false, visible: true },
            { id: modelPageName, items: modelPages, grouptype: false, visible: true },
            { id: EnergyMeterPageName, items: EnergyMeterPages, grouptype: false,  visible: true },
            { id: 'Downloads', items: [{id: 'Downloads', title: 'Downloads', items: []}], grouptype: false, visible: true},
            { id: userCatalogName, items: userPages, grouptype: true, visible: true },
            { id: maintenancePageName, items: maintenancePages, grouptype: false, visible: true},
        ]


        if(isHailAdminUser()){
            arr = arr.filter(a => a.id === addonfeaturePageName && a.items.length === 1)
        }

        if(ischangePasswordPage()){
            arr = arr.filter(a => a.id === changePasswordPageName && a.items.length === 1)
        }

        return arr

    }


    function getUserChartPages(catalogName, clist) {

        const pages = [...new Set(clist.map(item => item.page))];
        // console.log("Getting Charts ", pages)
        const tobject = {}
        // const pages = ['page1', 'page2']
        return pages.map(p => {
            // return {...tobject, id: `${catalogName}/${p}`, title: p, items: clist.filter((f) => f.page === p ).map((e)=> getChartCompValue2(e, criteria))}
            return { ...tobject, id: `${catalogName}/${p}`, title: p, items: clist.filter((f) => f.page === p) }
        })
    }

    const toggleSidebar = () => {
        pageAction.toggleSidebar(selected)
    }

    useEffect(() => {
        if (selected !== mainPage && selected !==  modelPage && selected !== detailsPage && selected !== 'Downloads' && selected != sessionPage && selected != settingsPage && selected !== energyMeterPage && selected !== maintenancePage && selected !== addonfeaturePage && selected !== diffusePage) {
            const idarray = selected.split("/")
            if (idarray && idarray.length > 1) {
                var catalog = chartNavData.find(f => f.id === idarray[0])
                if (catalog) {
                    var page = catalog.items.find(f => f.id === selected)
                    pageAction.setPage(selected, page ? page.items : [], true) 
                }
            }
        } else {
            console.log('Step 1 getting called in page setting...');
            sessionStorage.setItem('page',selected)
            pageAction.setPage(selected, [], false)
        }
    }, [selected])

    useEffect(() => {
        if (selected !== mainPage && selected !==  modelPage && selected !== detailsPage && selected !== 'Downloads') {
            const idarray = selected.split("/")
            if (idarray && idarray.length > 1) {
                var catalog = chartNavData.find(f => f.id === idarray[0])
                if (catalog) {
                    var page = catalog.items.find(f => f.id === selected)
                    if(page){
                        pageAction.setPage(selected, page.items, true)
                    }else{
                        if(catalog.items.length){
                            pageNotFoundContext.setPageNotFoundStatus(true)
                        }
                    }
                }
            }
        }
    },[userContext.charts])

    

    return (
      <>
        {chartNavData && chartNavData.length > 0 ? (
          <SideNavbar
            data={chartNavData}
            onSelect={setSelected}
            selected={selected}
            toggleSidebar={toggleSidebar}
          />
        ) : (
          <SideNavbar
            onSelect={setSelected}
            selected={selected}
            toggleSidebar={toggleSidebar}
          />
        )}
      </>
    );

}

export default UserChartNav;
