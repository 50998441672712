import React from 'react'
import styled from 'styled-components';
import { Input, Label } from 'reactstrap'
import ReactSlider from 'react-slider'

const StyledDiv = styled.div `
    display: inline-block;
    width: 180px;
    margin: 3px 16px auto 5px; 
    position: relative;
    padding-top: 10px;

    .heading {
        font-weight: 800;
    }

    .formfield {
        padding: 10px 3px 10px 18px;
    }

    .formfield input {
        margin-top: -3px;
    }

    .formfield label {
        padding-left: 10px;
    }

    .slider-container {
        padding: 20px 0px 30px;
    }

    .track {
        background: gray;
        height: 3px;
    }
    
    .thumb {
        width: 46px;
        height: 25px;
        background: #014059;
        border-radius: 6px;
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: -10px;
    }
`

export default props => {
    
    return (
        <React.Fragment>
            <StyledDiv>
                <span className="heading">Filters</span>
                {props.type === 'encoded' ? props.filterData.map(t1 => <div  className="formfield">
                    <Input
                        id={"check" + t1.value}
                        name={"check" + t1.value}
                        type="checkbox"
                        onChange={() => props.toggleFilter(props.dataField, t1.value)}
                        checked={props.selectedFilters.indexOf(t1.value) >= 0}
                    />
                    <Label
                        check
                        for={"check" + t1.value}
                    >
                    {t1.label}
                    </Label>
                </div>) : <div className="slider-container"><ReactSlider
                    className="slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    defaultValue={props.filterData}
                    value={props.selectedFilters}
                    ariaLabelledby={['first-slider-label', 'second-slider-label']}
                    ariaValuetext={state => `Thumb value ${state.valueNow}`}
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    pearling
                    minDistance={0}
                    min={props.filterData[0]}
                    max={props.filterData[1]}
                    step={0.01}
                    onAfterChange={(value, index) =>
                        props.toggleFilter(props.dataField, value)
                    }
                /></div>}
            </StyledDiv>
        </React.Fragment>
    );
      
}