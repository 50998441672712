import moment from 'moment';
import React from 'react';
import useSWR from 'swr';
import { useStore } from '../../context/store.context';
import { LineChart2 as LineChart } from "../chartjs/line";
import getChartJSOptions from '../chartjs/line.options';
import { Card6 as Card, CardHeader } from "../layout/Card";
import { getAngleDataSets } from "../model/angles.data";
import { FetchData2 } from "../model/store.swr";
import { DownloadCsv_Current_Angle } from './csv.download';

const interval = 1;

const urls = [
    { name: "rcangles", url: "rc_current_angle", slice: interval },
]

const ZonesCurrentAngleLines = (props) => {
    //const response = FetchData(useSWR, urls)
    const criteria = useStore().criteria
    const response = FetchData2(useSWR, criteria, urls)

    if (!response.isReady) return <Card>Loading Current Angles ...</Card>

    //const data = parseFWSWData(response.data.fwsw, filter)
    const data = response.data.rcangles;
    const start_time = moment(criteria.Ts)

    //console.log(data)
    const ds = getAngleDataSets(data, start_time, 'ts', 'a' , null)
    //const labels = formatLabels(getAngleTimes(data, "device_id"), start_time,  urls[0], criteria)
    const dps = { datasets: ds }
    //console.log("angles", dps.datasets)
    const options = getChartJSOptions('currentanle', "right", false)

    return (
         <>
        <Card >
            <CardHeader title={'Current Angles - Summary'} >
                <DownloadCsv_Current_Angle data={ds} filename={"current-angle"+criteria.timetype}/>
            </CardHeader>
            <LineChart data={dps} options={options} chartheightClass='500'/>
        </Card>
         </>
    );
        
}


export default ZonesCurrentAngleLines;
