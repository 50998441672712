import { defaultBarOption, makeBarOptions } from "./barchart";
import { defaultCompositeOption, makeCompositeOptions } from "./compositechart";
import { defaultLineOption, makeLineOptions } from "./linechart";
import { defaultPieOption, makePieOptions } from "./piechart";
import { makeWaterfallOptions } from "./waterfall";


export const chartdef = {
    'composite': { default: defaultCompositeOption, maker: makeCompositeOptions },
    'line': { default: defaultLineOption, maker: makeLineOptions },
    'bar': { default: defaultBarOption, maker: makeBarOptions },
    'waterfall': { default: defaultBarOption, maker: makeWaterfallOptions },
    'pie': { default: defaultPieOption, maker: makePieOptions },
}


export function getChartDef(type) {
    return chartdef[type]
}