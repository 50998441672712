import React, {useEffect} from "react";
import { Card4Opacity80 as Card, CardHeader } from '../layout/Card';
import TableContainer from "./TableContainer";



function getRowValue(row){
    return <span data-tip={row.value}>{row.value}</span>
}
function handleRowClick(){
    
}
function handleClick(e) {
    e.preventDefault();
    var popup = document.getElementById("closeDiv");
    popup.classList.toggle("hide");
}
export const ZonesOfflineTable = (props) => {
    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Escape') {
            event.preventDefault();
            var popup = document.getElementById("closeDiv");
            popup.classList.add("hide");
          }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []);

    // console.log("zones offline ", props)
    const offlineData = props.data;
    

    const columns = [
        { accessor: 'site_id', Header: 'Site', width: 205, Cell: (row) => { return getRowValue(row) }},
        { accessor: 'zones_count', Header: '#', Cell: (row) => { return getRowValue(row) } },
        { accessor: 'zones', Header: 'Arrays / Zones', Cell: (row) => { return getRowValue(row) }, style: { 'whiteSpace': 'unset' } }
    ]

    return (

        <div className="col-sm-12 no-gutters " id="closeDiv" style={{padding: "0px"}}>
            <Card>
                <CardHeader title={'Zones Offline'} >
                    {/* <span className="ml-2 float-right bg-transparent d-flex">
                        <DownloadCsv_Summary_table data={data} filename={"portfoliosummary" + criteria.timetype} />
                    </span> */}
                    <button className="zonePopUpClose" onClick={handleClick}>
                        X
                    </button>
                </CardHeader>
                <div className="d-flex  align-items-center mb-1 zone-offline-table">
                    <TableContainer
                        columns={columns}
                        data={offlineData}
                        className='-striped -highlight'
                        defaultPageSize={10}
                        trHandler={handleRowClick}
                    />
                </div>

            </Card>
        </div>

    );
};