import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import ButtonControl from "../components/controls/button_control";
import MultiSelectDropDownControl from "../components/controls/multiselect_control";
import RadioControl from "../components/controls/radio_control";
import { GetFQURL } from "../components/model/store.swr";
import { useCriteriaContext } from "../context/criteria.context";
import { useUserContext } from "../context/user.context";
import { filterMetricsAsPerCriteria, getMetricsAsOptions } from "../model/metadata";
import {createActivityLog} from '../utils/apiCall'
import { getPayloadForLogs, getLogData } from "../helpers/common"
import { toJS } from 'mobx';
import { useStore } from "../context/store.context"
import { isDevice, isPortfolio, isSite, isZone } from "../components/layout/criteria";
import {usePageContext} from "../context/page.context"
import { getAccessToken } from '../components/auth/userinfo'

const groupDesc = { 'AR': 'RC Alerts', 'AZ': 'ZC Alerts', 'VR': 'RC Data', 'VZ': 'ZC Data', 'SR': 'RC Status', 'SZ': 'ZC Status', 'VI': 'Inverter' }

export function getGroupType(cr) {
    //return cr.site === ""? 'pf': cr.zone === ''? 'sites': cr.device === ''? 'zones': 'rcs'
    return cr.site === "" ? 'pf' : cr.zone === '' ? 'zones' : cr.device === '' ? 'zones' : 'rcs'

}
/*
function filterMetricList(v, f) {
    return v.filter(e => f.includes(e[1].charAt(1)))
}

function filterMetricByType(v, mtype) {
    console.log(mtype)
    return v.filter(e => mtype.includes(e[1].charAt(1)))//.sort((a,b) => a[0] > b[0])
}


function getMetricAsOptions(mlist){

    const options = Object.keys(groupDesc).map(gid => {
        const obj = {}

        obj['label'] = groupDesc[gid]
        obj['options'] = mlist.filter(f => f[1] === gid).sort((a, b) => a[0].toUpperCase() > b[0].toUpperCase()).map(e => {
            return { 'label': e[0], 'value': e[0] }
        })
        return obj
    })
    return options
}

function parseMetrics(data, args, criteria) {
    const gt = getGroupType(criteria);
    // const mtype = (criteria.zone.length > 0) ? ['R'] : (criteria.site === "") ? ['Z'] : (criteria.zone.length > 0) ? ['R'] : data.metrics.values;
    const mlist = (criteria.device.length > 0) ? filterMetricByType(data.metrics.values, ['R']) : data.metrics.values //filterMetricByType(data.metrics.values, ['Z'])
    // const heads = [...new Set(mlist.map(item => getHead(item)))];
    const options = getMetricAsOptions(mlist)

    // console.log(mlist, options)
    return { 'options': options, 'criteria': criteria }

}
*/
const mergeOptions = [
    { label: "Consolidated", value: "yes" },
    { label: "Individual", value: "no" },

]
const intervalOptions = [
    { label: "1 min", value: 1 },
    { label: "5 mins", value: 5 },
    { label: "10 mins", value: 10 },
    { label: "30 mins", value: 30 },
    { label: "1 hour", value: 60 },
    { label: "12 hours", value: 12 * 60 },
    { label: "1 day", value: 24 * 60 },
]

const valueOptions = [
    { label: "Last Value", value: "last" },
    { label: "First Value", value: "first" },
    { label: "Average", value: "mean" },
    { label: "Minimum", value: "min" },
    { label: "Maximum", value: "max" },
]

//const userData = localStorage.getItem('user')
let token = getAccessToken()
//if (userData) {
//    const jsonUserData = JSON.parse(userData)
//    token = jsonUserData.token
//}

export function downloadMetricsData(options, filename, notify, level, criteria, cb) {
    if(options.metrics.length > 1){
        const errorCodeIndex = options.metrics.findIndex(m => m.metric === 'errorCode')
        if(errorCodeIndex !== -1){
            const errorCodeMetric = options.metrics.find(m => m.metric === 'errorCode')
            const firstMetric = {...options.metrics[0]}
            options.metrics[0] = errorCodeMetric
            options.metrics[errorCodeIndex] = firstMetric
        }
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify(options),
    }
    notify(true)
    fetch(options.url, requestOptions)
        .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename + ".xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            notify(false)
            cb && cb()
        })
        .catch((err) => {
            return Promise.reject({ Error: 'Something Went Wrong', err });
            notify(false)
            cb && cb()
        })

    const metricsName = options.metrics.map(m => m.metric).join(" ,")

    createActivityLog(getLogData(criteria, {featureId: `download-${level}-metrics`.toLowerCase(), metrics: metricsName, usecase: `download metrics data`.toLowerCase(), category: 'overview', level: level, details: JSON.stringify({merge: options.merge, aggr: options.aggr})}))
}

function fetchMetricsData(options, notify) {

    const requestOptions = {
        // mode: 'no-cors',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify(options),
    }
    notify(true)
        (async () => {
            const rawResponse = await fetch(options.url, requestOptions);
            const content = await rawResponse.json();

            // console.log(content);
            notify(false)
        })();
}


const Styles = styled.div`
    max-width: 100%;
    min-width: 30%;
    background-color: #F7F8F9;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 0px;

    .clickparent {
        padding: 6px 4px;
        width: 100%;
        margin: 0px;
        display: inline-block;
    }
    .clickparent:hover {
        // background-color: blue;

    }
    
`
const MetricsDownloaderViewerBase = (props) => {
    const [selectedOption, setSelectedOption] = useState([]);
    const criteria = useCriteriaContext()
    const userContext = useUserContext()
    const pageContext = usePageContext()

    const metricsData = useMemo(() => { return  getMetricsAsOptions(filterMetricsAsPerCriteria(userContext.metrics, criteria), handleGroupSelection) }, [userContext.metrics, criteria]) //useState([]);

    const [interval, setInterval] = useState(criteria.interval)
    const [valueType, setValueType] = useState(valueOptions[0].value)
    const [merged, setMerged] = useState(mergeOptions[0].value)
    const [loading, setLoading] = useState(false);
    const sortAString = (a, b) => a.group_id > b.group_id ? 1 : a.group_id < b.group_id ? -1 : 0
    const level = isPortfolio(criteria) ? 'pf' : isSite(criteria) ? 'site' : isZone(criteria) ? 'zone' : isDevice(criteria) ? 'rc' : {}
    
    function handleGroupSelection(grpValue){
        // console.log("gru p value ", grpValue, metricsData)
        const groupData = metricsData.filter(f => f.value === grpValue)
        // console.log(groupData)

        if (groupData && groupData.length > 0) {
            // console.log(" options", groupData[0].options)
            setSelectedOption(value => {console.log(value); return value ? value.concat(groupData[0].options): groupData[0].options })

            // groupData[0].options.map(e => {
            //     setSelectedOption(e)
                
            // })
        }
        
    }
    useEffect(()=> {
        // console.log(selectedOption)
    }, [selectedOption])
    function getMetricWithGroup(e){
        return Object.assign({}, {metric: e.value.metric, metricGroup: e.value.group_id })
    }
    function getUrlOptions(download = true, merge = true) {
        const groupType = getGroupType(criteria);
        
        const url = GetFQURL({ name: 'downloadmetrics', url: 'readingsNbyid-d_any', grouptype: groupType, slice: interval,  nocache: true,  path:"/download" }, criteria)
        // console.log("Checking Interval", interval, toJS(criteria), url)
        // return { 'url': url, 'metrics': selectedOption.map(e => e.value.metric), 'aggr': valueType, 'download': download ? 'yes' : 'no', 'merge': merged }
        console.log("checking object",{ 'url': url, 'metrics': selectedOption.map(getMetricWithGroup), 'aggr': valueType, 'download': download ? 'yes' : 'no', 'merge': merged })
        return { 'url': url, 'metrics': selectedOption.map(getMetricWithGroup), 'aggr': valueType, 'download': download ? 'yes' : 'no', 'merge': merged }
    }

    function getFileName(){
        const domain = `${criteria.site === ""? "allsites-": criteria.zone === ""? criteria.site + '-' : criteria.device === ''? criteria.site + '-' + criteria.zone + '-' :   criteria.site + '-' + criteria.zone + '-' + criteria.device + '-'}`
        const time = `${criteria.Ts + '-' + criteria.Te }`
        return  domain + time 
    }

    function handleViewData() {
        fetchMetricsData(getUrlOptions(false, true), setLoading)
    }

    function handleDownload() {
        downloadMetricsData(getUrlOptions(true, true), getFileName(), setLoading, level, criteria)
    }

    // console.log(props)
    return (
        <Styles>
            {selectedOption && selectedOption.length > 0 && <>
                <div className="clickparent">
                    {/* <div  onClick={handleViewData}><span > View</span></div>  */}
                    {!loading ? <ButtonControl handleClick={handleDownload} /> :<div class="spinner-grow spinner-grow-sm mr-2 text-primary" style={{width: '0rem', height: '0rem'}}  role="status" aria-hidden="true"> fetching... </div>} 
                    {/* {!loading ? <ButtonControl handleClick={handleDownload} title={'Download'} /> :<div class="spinner-grow spinner-grow-sm mr-2 text-primary"  role="status" aria-hidden="true"> fetching... </div>}  */}
                </div>
                <RadioControl value={merged} options={mergeOptions} handleChange={setMerged}></RadioControl>
                <RadioControl value={interval} options={intervalOptions} handleChange={setInterval}></RadioControl>
                <RadioControl value={valueType} options={valueOptions} handleChange={setValueType}></RadioControl>
            </>}
            <MultiSelectDropDownControl options={metricsData} handleChange={setSelectedOption} title={'Select Metrics'} value={selectedOption}></MultiSelectDropDownControl>

        </Styles>

    )

}

/*
const urls = [
    { name: "metrics", url: "all_unique_metrics", },
]

const comps = {
    title: 'Metrics',
    chart: MetricsDownloaderViewerBase,
    urls: urls,
    noheader: true,
    nocard: true,
    default: [],
    // parser: {f: metricParser, args: {} } 
    parser: { f: parseMetrics, args: {} }
}

const MetricsDownloaderViewer = withData({ ...comps }) //withData(PerfChartBase, urls)
*/
const MetricsDownloaderViewer = MetricsDownloaderViewerBase;
export default MetricsDownloaderViewer;
