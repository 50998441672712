import { toJS } from 'mobx';
// import { observer } from "mobx-react";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import React, { useEffect, useMemo, useState } from "react";
import nextId from "react-id-generator";
import styled from 'styled-components';
import { useDataviewActionContext } from '../../context/dataview.context';
import { useUserActionContext, useUserContext } from "../../context/user.context";
import { useStore, useStoreActionContext } from "../../context/store.context";
import { getUserPortfolios } from '../auth/userinfo';
import { useModelContext } from '../../context/model.context';
import DropDownControl from '../controls/dropdown';
import { energyMeterPage, modelPage, settingsPage,addonfeaturePage, usePageContext,diffusePage,changePasswordPage } from '../../context/page.context';
import { isPortfolio, isSite, isZone } from '../layout/criteria';
import { postJSONData } from '../model/store.swr';
import {compressAndEncode} from "../model/utils"

const CrumbItem1 = ({ value, handler }) => {
    const [selectValue, setSelectValue] = useState(value)
    const items = [value, 'one', 'two']

    function handleChange(e) {
        e.preventDefault();
        setSelectValue(e.target.value)
        handler(selectValue)

    }

    return (
        <select defaultValue={selectValue} className="form-select form-select-sm  border-0   p-0 m-0"
            onChange={handleChange} >{items.map((e) => <option value={e}>{e}</option>)}</select>
    )

} 

const dfilltypes = { 'pf': [], 'site': ['pf'], 'zone': ['pf', 'site'], 'inverter': ['pf', 'site'],  'device': ['pf', 'site', 'zone'] }

const CrumbItem = (props) => {
    const storeContext = useStore()
    const pageContext = usePageContext()
    const modelContext = useModelContext()
    const [data, setData] = useState([])
    let value = props.value
    if (pageContext.page === modelPage) {
        if (props.dkey === 'zone')
            value = modelContext.selectedZone
        else if (props.dkey === 'device')
            value = modelContext.selectedDevice
    }
    const [selected, setSelected] = useState(value)
    const [loading, setLoading] = useState(false)
    const criteria = useMemo(() => {return toJS(storeContext.criteria)}, [storeContext.criteria]) //toJS(storeContext.criteria)
    const navFilter = useMemo(() => {return storeContext.navFilter}, [storeContext.navFilter])

    if (pageContext.page === modelPage) {
        criteria.zone = modelContext.selectedZone
        criteria.device = modelContext.selectedZone ? modelContext.selectedDevice : ''
    }
    
    const userContext = useUserContext()
    const userAction = useUserActionContext()

    function getParams() {
        var p = {user: '', portfolios: getUserPortfolios() || '' }
        dfilltypes[props.dkey].forEach(e => {
            var o = {}
            o[e] = criteria[e]
            p = Object.assign(p, o)
        })
        // console.log("----------------------- params ----------------------------", p)
        return p
    }

    function isKeyFiterable(key){
        return navFilter && key in navFilter && navFilter[key] != ""
    }
    function filterFixedNavData(res){
        if ( res && Array.isArray(res)) {
            if (isKeyFiterable(props.dkey)) {
                return res.filter(f=> f.id  == navFilter[props.dkey]).map(e => e.id)
            }else {
                return res.map(e => e.id)
            }
        } 
        return []

    }

    function onSelect(id){
        // console.log("/ / / / / / / / / Crumb ", props.dkey, "  old ", props.value, " new ", id, " / / / // / / / / ", criteria)
        if (props.dkey === 'pf') {
            moment.tz.setDefault()
        } else if (props.dkey === 'site') {
            if (userContext.sites.length > 0) {
                const site = userContext.sites.filter(site => site.site_id === id)
                if (site.length > 0 && site[0].timezone) 
                    moment.tz.setDefault(site[0].timezone)
                else 
                    moment.tz.setDefault()
            }
        }
        if(pageContext.page === modelPage){
            props.action1 && props.action1(id)
        }

        props.action(id)
    }
    useEffect(() => {
        let isMounted = true
        postJSONData(
            `/meta?querytype=meta_domain_by_id&isInverter=${pageContext.page === 'Inverter' ? true : ""}&isEnergyPage=${pageContext.page === energyMeterPage ? true : false}&restype=json`,
            getParams(),
            (res) => {
                // console.log(res)
                // const d = filterFixedNavData(res) 
                const d = res && Array.isArray(res) ? res.map(e => e.id) : []
                if(isMounted){
                    setData(d)
                    setSelected(d && d.length > 0 ? d[0] : "")

                    if(pageContext.page === energyMeterPage && isSite(criteria) && props.dkey==="inverter"){
                        if(!Array.isArray(res)){
                            userAction.updateInverters([])
                        } else {
                            userAction.updateInverters(res)
                        }
                    }
                }
                
            }
        )

        return () => {
            isMounted = false; // cleanup: mark component as unmounted
        };
    }, [props.dkey])
    // console.log(props)

    return (
        <DropDownControl options={data} selected={selected} onSelect={onSelect} label={props.label} disabled={isKeyFiterable(props.dkey)}/>
    )


}

const CrumbAction = (props) => {
    const dataviewaction = useDataviewActionContext()

    const dfill = props.data

    function changeValue(v) {
        if (v === undefined || v === null) {
            dfill.a()
        } else {
            dfill.a(v)
        }
        dataviewaction.clearView()

    }

    function handleClick(e) {
        e.preventDefault();
        changeValue(dfill.x)
        // StoreActionContext.setPortfolio()
    }
    // console.log(props)
    const CrumbLabel = ({ title }) => { return (<button className="btn btn-link  text-white p-0 m-0" ><h4>{title}</h4></button>) }
    // const CrumbLabel = ({title}) => {return (<CrumbItem value={title} handleChange={changeValue} /> ) }
    // const CrumbButton = ({title}) => {return (<button type="button" className="btn btn-link link-primary p-0 m-0" onClick={handleClick}><h4>{title}</h4></button>) }
    const CrumbButton = (props) => {/*console.log(props);*/ return (<CrumbItem dkey={props.fillIndex} action={props.data.a} action1={props.data.a1} value={props.data.x} label={props.data.s}/>) }
    
    return (
        <span>
            {/* {props.fillIndex === props.index ? <CrumbLabel title={dfill.t} /> : <CrumbButton title={dfill.t} {...props}/>} */}
            <CrumbButton title={dfill.t} {...props} />
            {/* {props.fillIndex === props.index && dfill.s !== dfill.t ? <label className="btn  px-1 mb-0"> {" - " + dfill.s} </label> : <label className="px-1 mb-0"> {'-'} </label>} */}
            {props.fillIndex !== props.index ? <label className="btn custom-breadcrumbs px-1 mb-0"> <img src="../assets/img/icon/chevron_right.svg" /></label> : ""}
        </span>
    )
}

const StyledCrumbs = styled.div`
    margin-bottom: 2px;
    margin-left: 5px;
    display: inline-flex;
`

const Crumbs = () => {
    const storeAction = useStoreActionContext()
    const criteria = toJS(useStore().criteria)
    
    const pageContext = usePageContext()
    const modelContext = useModelContext()

    if (pageContext.page === modelPage) {
        criteria.zone = modelContext.selectedZone
    }
    
    
    // console.log("****************************", criteria)
    const d = {
        // 'pf': { t: "Home", s: "All Sites", a: storeAction.setPortfolio, x: null,  },
        'pf': { t: criteria.pf, s: "All Portfolios", a: storeAction.setPortfolio, x: criteria.pf, },
        'site': { t: criteria.site, s: "All Sites", a: storeAction.setSite, x: criteria.site },
        'zone': { t: criteria.zone, s: "All Zones", a: pageContext.page === modelPage ? modelContext.setSelectedZone : storeAction.setZone, x: criteria.zone, a1:  storeAction.setZone },
        'device': { t: criteria.device, s: "All RCs", a: pageContext.page === modelPage ? modelContext.setSelectedDevice : storeAction.setDevice, x: criteria.device, a1:  storeAction.setDevice },
    }
    if(pageContext.page === 'Inverter' || pageContext.page === energyMeterPage){
        d['inverter'] = { t: criteria.inverter, s: "All Inverters", a: storeAction.setInverter, x: criteria.inverter }
    }

    const dfilltypes = { 'pf': ['pf'], 'site': ['pf', 'site'], 'zone': ['pf', 'site', 'zone'], 'device': ['pf', 'site', 'zone', 'device'] }

    // const dfill = isPortfolio(criteria) ? ['pf'] : isSite(criteria) ? ['pf', 'site'] : isZone(criteria) ? ['pf', 'site', 'zone'] : ['pf', 'site', 'zone', 'device']
    // const dfill = isPortfolio(criteria) ? ['pf', 'site'] : isSite(criteria) ? ['pf', 'site', 'zone'] : isZone(criteria) ? ['pf', 'site', 'zone', 'device'] : ['pf', 'site', 'zone', 'device']

    let dfill;

        if ( pageContext.page === changePasswordPage || pageContext.page === settingsPage || pageContext.page === addonfeaturePage || pageContext.page === diffusePage) {

        dfill = isPortfolio(criteria) ? ['pf', 'site'] : isSite(criteria) ? ['pf', 'site'] : ['pf', 'site'];
        } else {
            dfill = isPortfolio(criteria) ? ['pf', 'site'] : ((pageContext.page !== 'Inverter' && pageContext.page !== energyMeterPage) ? (isSite(criteria) ? ['pf', 'site', 'zone'] : isZone(criteria) ? ['pf', 'site', 'zone', 'device'] : ['pf', 'site', 'zone', 'device']) : ['pf', 'site', 'inverter'])        }

// Now you can use dfill outside of the if-else statement

    // const dfill = isPortfolio(criteria) ? ['pf', 'site'] : ((pageContext.page !== 'Inverter' && pageContext.page !== energyMeterPage) ? (isSite(criteria) ? ['pf', 'site', 'zone'] : isZone(criteria) ? ['pf', 'site', 'zone', 'device'] : ['pf', 'site', 'zone', 'device']) : ['pf', 'site', 'inverter'])
    return (
        <StyledCrumbs>
            <h4 className="my-0 py-0">
                {dfill.map(e => <CrumbAction data={d[e]} fillIndex={e} index={dfill.at(-1)} key={nextId()} />)}
            </h4>
        </StyledCrumbs>
    )/*
    return (
        <div>
            <span>{isPortfolio(criteria) ? "All Sites" : <CrumbAction title={"All Sites"} action={storeAction.setPortfolio} /> + " / " +
                isSite(criteria) ? criteria.site : <CrumbAction title={criteria.site} action={storeAction.setSite} args={criteria.site} /> + " / " +
                    isZone(criteria) ? criteria.zone : <CrumbAction title={criteria.zone} action={storeAction.setZone} args={criteria.zone} /> + " / " +
                        isDevice(criteria) ? criteria.device : <CrumbAction title={criteria.device} action={storeAction.setDevice} args={criteria.device} /> }
            </span>
        </div>

    )*/

}

// export default observer(Crumbs);
export default Crumbs;