// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { memo, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Button } from "reactstrap";
import styled, { css } from "styled-components";
import { getUserID } from "../components/auth/userinfo";
import { CheckBoxField, FormProvider, TextField, useFormActionContext, useFormContext } from "../components/controls/formcontrol";
import { SingleSelectDropDownControl } from "../components/controls/multiselect_control";
import { getValueTypeGroup } from "../components/layout/criteria";
import { postJSONData } from "../components/model/store.swr";
import { useCriteriaContext } from "../context/criteria.context";
import { useUserActionContext, useUserContext } from "../context/user.context";
import { getChartsAsOptions, getMetricsAsOptions } from "../model/metadata";
import { fetchJSONData } from "./portfolio";


/*
const MetricPicker = (props) => { 

    const props2 = Object.assign(props, {onSelect: props.options.onSelect })
    return MetricsListBaseChart({...props2});
    
}
function metricsTableParser(data, args, criteria) {
    const mlist = data.metrics.values;
    return  mlist.map(e => { return { head: getHead(e), title: e[0], metric: e[0], group: e[1] } })
}
*/
const hideHeader = { hidden: true }

const metricColumns = [
    { dataField: 'metric', text: 'Metric', editable: false, sort: true },
    { dataField: 'group_id', text: 'Type', editable: false, sort: true },
];

const chartColumns = [
    { dataField: 'metric', text: 'Metric', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'type', text: 'Type', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'a', text: 'a', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'a1', text: 'a1', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'a2', text: 'a2', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'a3', text: 'a3', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'a4', text: 'a4', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'a5', text: 'a5', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'a6', text: 'a6', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'a7', text: 'a7', editable: false, sort: true, headerAttrs: hideHeader },
    { dataField: 'a8', text: 'a8', editable: false, sort: true, headerAttrs: hideHeader },
];

const charts123 = [
    { metric: "error", type: "line", a: "caption", a1: "color", a2: "caption", a3: "color", a4: "caption", a5: "color", a6: "caption", a7: "color", a8: "caption" },
    { metric: "error23", type: "line", a: "", a1: "", a2: "", a3: "", a4: "", a5: "", a6: "", a7: "", a8: "" },
    { metric: "error312", type: "line", a: "", a1: "", a2: "", a3: "", a4: "", a5: "", a6: "", a7: "", a8: "" },
    { metric: "error2423", type: "line", a: "", a1: "", a2: "", a3: "", a4: "", a5: "", a6: "", a7: "", a8: "" },
]
const TableContainer = styled.div`
    // height: 70vh;
    max-height: 50vh;
    min-height: 20vh;
    overflow: auto;

    table caption {
        caption-side: top;
        color: #231F20;
        font-weight: 900;
    }
`
const TableContainerSmall = styled.div`
    // height: 20vh;
    max-height: 10vh;
    min-height: 5vh;
    overflow: auto;

    table caption {
        caption-side: top;
        color: #231F20;
        font-weight: 900;
    }
`

const ChartDesc = styled.pre`
    display: flex;
    position: relative;
    margin: 6px 0;
    border-radius: 6px;
    font-size: 14px;
    color: #231F20;
    background-color: #fff;
    max-height: 40vh;
    height: 20vh;
    border: solid 1px #014059;   

`

const ChartControls = styled.div`
    display: flex;
    margin: 5px 0;
    // border-radius: 6px;
    font-size: 14px;
    color: #231F20;
    // background-color: rgba(3,3,3,0.2);
    max-height: 30px;
    height: 25px;

    .right {
        position: absolute;
        right: 16px;
        margin: 3px 5px;
        top: 15px;
    }
    .right svg {
        font-size: 1.3em;
        margin: 0 5px;
        cursor: pointer;
        // color: #006cff;
        color: #014059;
    }

    .spacing {
        margin-right: 10px;
    }
    .right span img{
        cursor: pointer;
    }

`
const Label = styled.div`
    display: flex;
    font-size: 14px;
    // background-color: rgba(255,255,255,0.5);
    color: #014059;
    span{
        padding: 8px 0px 0px 0px;
    }
    ${props =>
        props.top &&
        css`
            // position: absolute;
            margin: 5px 0;
       `
    };
    ${props =>
        props.left &&
        css`
            float: left;
            margin: 12px;
            min-width: 120px;
            text-align: right;
       `
    };
`

/*
    const example =  { 
        title: 'Wind Speed Summary (m/s)', 
        source: "readings1byid0_WIND_SPEED", 
        charttype: 'line', 
        interval: c.interval, 
        grouptype: "zones", 
        hideEmpty: true, 
        chartOptions: { timeColumn: 'timestamp', stacked: false, fill: true, titleX: '', titleY: '' } 
        { timeColumn: 'timestamp', showMajority: true, showPercent: true, stacked: true, fill: true, titleX: '', titleY: 'Device Count' } 
    }

*/


const ChartRowControl = (props) => {
    return (
        <div className="row" >
            <div className="col-12">
                <Label left>{props.label}</Label>
                {props.children}
            </div>
        </div>
    )
}

function getChartPages() {
    const tobject = {}

    const pages = ['page1', 'page2']
    return pages.map(p => {
        return { ...tobject, label: p, value: p }
    })
}




// func_codes = {'first': 'first', 'last': 'last', 'min': 'min', 'max': 'max', 'mean': 'mean', 'count': 'count'}
const dataTypeOptions = [
    // { label: "RC Count", value: "readings1byidcount" },
    // { label: "RC Value", value: "readings1byid0" },
    { label: "Total RCs", value: "by-value", query: "readings1byidcount", grouptype: ["rcs"], aggr: "count" },
    { label: "Recent Value", value: "by-rc-last", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "last" },
    { label: "Max Value", value: "by-rc-max", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "max" },
    { label: "Avg Value", value: "by-rc-avg", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "mean" },
    { label: "Min Value", value: "by-rc-min", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "min" },
    { label: "First Value", value: "by-rc-first", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "first" },
]

const chartIntervalOptions = [
    { label: "10 Minutes", value: 10 },
    { label: "30 Minutes", value: 30 },
    { label: "1 Hour", value: 60 },
    { label: "3 Hours", value: 60 * 3 },
    { label: "6 Hours", value: 60 * 6 },
    { label: "12 Hours", value: 60 * 12 },
    { label: "1 Day", value: 60 * 24 },
    { label: "3 Days", value: 60 * 24 * 3 },
    { label: "7 Days", value: 60 * 24 * 7 },
]

const chartTypeOptions = [
    { label: "Line Chart", value: "line" },
    { label: "Bar Chart", value: "bar" },
]

const groupTypeOptions = [
    { label: "Porfolio", value: "pf" },
    { label: "Sites", value: "sites" },
    { label: "Zones", value: "zones" },
    { label: "Devices", value: "rcs" },
]
function selectformatter(options, cell) {
    const o = options.find(x => String(x.value) === cell)
    // console.log(o, options, cell);  
    return o 
    ? 
    <select type="select" class="form-control editor edit-select">
        <option value={o.value} selected="">{o.label}</option>
    </select> 
    : <select type="select" class="form-control editor edit-select">
      </select> ;
}
function textformatter(cell) {
     return <input type="text" class="form-control editor edit-text" value={cell} />
}
function checkBoxformatter(cell) {
    return <input type="checkbox" class="editor edit-chseckbox checkbox" checked={ String(cell) == "Yes" ? true : false } />
}
function groupTypeFormatter(cell, row) {
    return cell.length>0 
    ? 
    <select type="select" class="form-control editor edit-select">
        <option value={cell} selected="">{cell}</option>
    </select> 
    : <select type="select" class="form-control editor edit-select">
      </select> ;
}

function checkboxFormatter(cell, row, rowIndex) {
    return (
        <input type="checkbox" checked={row.inStock} disabled />
    )
}
function valueTypeSelectFormatter(cell, row, rowIndex) {

    const vt = dataTypeOptions.find((f) => f.value === row["valueType"])
    row["aggr"] = vt && vt.aggr ? vt.aggr : ""
    row["query"] = vt && vt.query ? vt.query : ""
    return selectformatter(dataTypeOptions, cell)
}

function getGroupTypes(setOptions, { row, column }) {
    if (row.metric.length > 0 && row.query.length > 0 && row.groupId.length > 0) {
        const vtg = getValueTypeGroup(row.query, row.groupId)
        if (vtg) {
            return  vtg.groupTypes.map((e) => { return { label: e, value: e } })
        }
    }
    
    return []
}
/*
      {
        dataField: 'inStock',
        text: 'In Stock',
        formatter: (cellContent, row) => (
          <div className="checkbox disabled">
            <label>
              <input type="checkbox" checked={ row.inStock } disabled />
            </label>
          </div>
        )
      },

*/

const chartColumns22 = [
    { dataField: 'id', text: 'id', hidden: true, },
    { dataField: 'metric', text: 'Metric', editable: false,},
    { dataField: 'groupId', text: 'Group', hidden: true },
    { dataField: 'aggr', text: 'Aggr', hidden: true },
    { dataField: 'query', text: 'Query', hidden: true },
    { dataField: 'valueType', text: 'Value Type', editor: { type: Type.SELECT, options: dataTypeOptions }, formatter: (cell, row, rowIndex) => valueTypeSelectFormatter(cell, row, rowIndex), },
    { dataField: 'groupType', text: 'Group By', editor: { type: Type.SELECT, getOptions: getGroupTypes }, formatter: (cell, row) => groupTypeFormatter(cell, row) },
    { dataField: 'interval', text: 'Interval', editor: { type: Type.SELECT, options: chartIntervalOptions }, formatter: (cell, row) => selectformatter(chartIntervalOptions, cell), },
    { dataField: 'chartType', text: 'Chart Type', editor: { type: Type.SELECT, options: chartTypeOptions }, formatter: (cell, row) => selectformatter(chartTypeOptions, cell), },
    { dataField: 'titleY', text: 'YAxis Title',  formatter: (cell, row) => textformatter(cell)},
    { dataField: 'stacked', text: 'Stacked?', editor: { type: Type.CHECKBOX, value: 'Yes:No' } , formatter: (cell, row) => checkBoxformatter(cell)},
    { dataField: 'filled', text: 'Filled?', editor: { type: Type.CHECKBOX, value: 'Yes:No' }, formatter: (cell, row) => checkBoxformatter(cell) },
    { dataField: 'summary', text: 'Summary?', editor: { type: Type.CHECKBOX, value: 'Yes:No' }, formatter: (cell, row) => checkBoxformatter(cell) },
];

const chart22def = [{ id: "", metric: "", groupId: "", aggr: "", query: "", valueType: "", groupType: "", chartType: "", interval: '30', stacked: true, filled: false, titleY: "", summary: true }]

const StyledBSTable = styled.div`
    select {
       
    }

    option {
        color: #111;
        background-color: #eee;
    }

    option[selected="selected"] {
    }
    .edit-select {
        width:auto;
        min-width:104px !important;
    }
`
const TableField = memo(({ name, options, observe }) => {
    const formValues = useFormContext();
    const formSetValues = useFormActionContext().setValues;

    const value = useMemo(() => { return formValues[name] }, [formValues[observe]]) //values[name] || defValue;

    function beforeSaveCell(oldValue, newValue, row, column, done) {
        // console.log("Saving Table ..", row, oldValue, newValue, value)

        setTimeout(() => {
            if (oldValue !== newValue) {
                // setValue(row)
                // console.log([...values[name]])
                // console.log(values[name])
                formSetValues(prev => ({
                    ...prev,
                    [name]: value
                }));

                done(true);
                console.log('After Saving Cell!!', row);

            } else {
                done(false);
            }
        }, 0);
        return { async: true };
      }
      
    return (
        <>
            {(value) &&
                <StyledBSTable>
                    <BootstrapTable
                        keyField='id'
                        data={value}
                        columns={options.columns}
                        // rowEvents={rowEvents}
                        // selectRow={{ mode: 'radio',   hideSelectColumn: true, clickToSelect: true, style: { backgroundColor: 'rgba(10,56,112,1)' }, selected: [portfolio] }}

                        cellEdit={cellEditFactory({
                            mode: 'click',
                            // beforeSaveCell,
                            
                            blurToSave: true,
                            
                            afterSaveCell: (oldValue, newValue, row, column) => {
                                // console.log("Saving Table ..", row, oldValue, newValue, value)
                                // console.log("Saving Table ..", row, oldValue, newValue)
                                if (oldValue !== newValue) {
                                    // console.log('After Saving Cell!!', row, value);
                                    // setValue(row)
                                    // console.log([...values[name]])
                                    // console.log(values[name])
                                    formSetValues(prev => ({
                                        ...prev,
                                        [name]: value
                                    }));

                                    // postJSONData("/meta?querytype=meta_sites_update", row, (data)=>{setResponse(`Saved Site ! ${JSON.stringify(data)}`); setReloadUrls(reloadUrls + 1)})
                                }
                            }

                        })}

                    />
                    </StyledBSTable>
            }
        </>
    )
});

export const ChartEditingBaseView = (props) => {
    const userContext = useUserContext()
    const UserActionContext = useUserActionContext()
    const criteria = useCriteriaContext()
    const [reloadUrls, setReloadUrls] = useState(0)
    const [orgChartSelected, setOrgChartSelected] = useState(false)

    const metricsData = useMemo(() => { return getMetricsAsOptions(userContext.metrics) }, [userContext.metrics]) //useState([]);
    const chartsData = useMemo(() => { return getChartsAsOptions(userContext.charts) }, [userContext.charts]) //useState([]);
    const orgChartsData = useMemo(() => { return getChartsAsOptions(userContext.orgCharts) }, [userContext.orgCharts]) //useState([]);


    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(true)
    const formValues = useFormContext();
    const formSetValues = useFormActionContext().setValues;
    const [chart, setChart] = useState()
    const [orgChart, setOrgChart] = useState()
    const chartDesc = useMemo(() => { return getChartCompValue() }, [formValues]) //useState([]);


    function getKey() {
        // return formValues.key
        return isKey() ? formValues.chartId : undefined
    }
    function isKey() {
        return formValues && formValues.chartId
    }

    useEffect(() => {

        const timeout = setTimeout(UserActionContext.refreshCharts, 2000);
        return () => {
            clearTimeout(timeout);
        }
    }, [reloadUrls])

    function onChartDelete() {
        var answer = window.confirm("Do you want to delete the chart data?");
        if (answer) {
            const url = `/charts/delete/${userContext.org}/${getKey()}`
            // postJSONData(url, postData, (data)=> { console.log(data); setReloadUrls(reloadUrls+1) } )
            fetchJSONData(url, (status) => {
                // console.log(status);
                setChart(null)
                setReloadUrls(reloadUrls + 1)
                // UserActionContext.refreshCharts()
            }, (err) => { console.log("Error in deleting", err) })

        }
    }
    function onChartEdit() {
        // console.log("Chart Edit !!!")
    }
    function onChartNew() {
        // console.log("Chart New !!!")
        setOrgChart(null)
        setChart(null)
        setOrgChart(null)
        setMessage("")
    }
    const ChartExtraControls = () => {
        return (
            <ChartControls>
                <div className="right">
                {/* {isKey() ? <FaTrash aria-labelledby="Delete Chart" onClick={onChartDelete} /> : ''} */}
                    {isKey() ? <span onClick={onChartDelete} aria-labelledby="Add New Chart"><img src="../assets/img/icon/icon-trash.svg" aria-labelledby="Delete Chart" alt="Delete Chart" width="20" height="20" /></span> : ''}
                    
                    {/* <FaEdit aria-labelledby="Edit Chart" onClick={onChartEdit} /> */}
                    <span className="spacing" />
                    {/* <FaPlus aria-labelledby="Add New Chart" onClick={onChartNew}  /> */}
                    <span onClick={onChartNew} aria-labelledby="Add New Chart"><img src="../assets/img/icon/icon-plus.svg" aria-labelledby="Add New Chart" alt="Add New Chart" width="24" height="24" /></span>
                </div>
                {/* <ButtonControl title={"X"} handleClick={onChartDelete}/> */}
            </ChartControls>

        )
    }

    function getChartCompValue() {
        const f = formValues
        // console.log("Chart !!!", f)
        const comp = {
            title: f.title,
            page: f.page,
            charts: f.charts,
            XAxisTitle: f.titleX,
            lastupdated: f.lastupdated,
            metrics: f.metrics,
            // source:  `${f.datatype.value}_${f.metric.value.metric}`,
            // charttype: f.charttype.value,
            // interval:  f.interval.value ,
            // grouptype: f.grouptype.value,
            hideEmpty: true,
            // chartOptions: { timeColumn: 'timestamp', stacked: false, fill: true, titleX: '', titleY: '' } 
            // chartOptions: { timeColumn: 'timestamp', showMajority: f.majority, showPercent: f.percent, stacked: f.stacked, fill: f.filled, titleX: f.titleX, titleY: f.titleY }
        }
        // console.log("Chart Saved !!!", f, comp)
        return comp

    }

    function onSaveShare() {
        // console.log(formValues)
        const fvalues = { ...formValues, shared: true }
        // console.log(fvalues)
        formSetValues(fvalues)

        // onSave()

    }

    function onSave() {
        // console.log("Saving ...", formValues)
        if (!validate()) {
            alert("Chart Definition not complete")
            return
        }
        setMessage("Saving Chart Data")
        const url = `/charts/save/${getUserID()}`
        const postData = [formValues].map((e) => Object.assign(e, { org: userContext.org }));
        postJSONData(url, postData, (data) => {
            // console.log(data);
            // setChart(formValues)
            setReloadUrls(reloadUrls + 1)
            setMessage("Chart Data Saved !!")
            // UserActionContext.refreshCharts()
        })

    }

    function getValueTypeGroupList() {

    }


    /*
    useEffect(() => {
        console.log(" peeking formvalues", formValues)
        if (formValues["metrics"]) {
            const formcharts = formValues["charts"]
            const c22 = formValues["metrics"].map((m, i) => {
                const c = formcharts.find((c) => m.value.metric === c.metric)
                if (c) {
                    return { ...c, id: `chart-${i}` }
                }
                return { ...chart22def[0], id: `chart-${i}`, metric: m.value.metric, groupId: m.value.group_id }
            })
            console.log(formValues["metrics"], c22)

            formSetValues(prev => ({
                ...prev,
                ["charts"]: c22
            }));
        }
    }, [formValues["metrics"]])

    */


    function validate() {
        if (formValues['title'].length < 2) {
            setMessage("Chart title must be 2 chars or more ")
            return false
        }
        if (formValues['page'].length < 2) {
            setMessage("Page name must be 2 chars or more ")
            return false
        }
        if (formValues['titleX'].length < 2) {
            setMessage("X Axis title must be 2 chars or more ")
            return false
        }

        const charts = formValues["charts"]
        //  [{id: "", metric: "", valueType: "", chartType: "", interval: '30',stacked: true, filled: false, titleY: "", summary: true}]

        return charts.reduce((acc, cur) => {

            const dtype = dataTypeOptions.find((f) => f.value === cur.valueType)
            const ctype = chartTypeOptions.find((f) => f.value === cur.chartType)
            const itype = chartTypeOptions.find((f) => f.value === cur.chartType)

            if (!dtype) {
                setMessage(`Select Value Type for ${cur.metric} !!`)
                return false
            }
            if (!cur.groupId.length > 0) {
                setMessage(`Select Group Type for ${cur.metric} !!`)
                return false
            }

            if (!ctype) {
                setMessage(`Select Chart Type for ${cur.metric} !!`)
                return false
            }
            if (!itype) {
                setMessage(`Select Interval for ${cur.metric} !!`)
                return false
            }
            if (cur['titleY'].length < 2) {
                setMessage(`Y Axis ${cur.metric} must be 2 chars or more !`)
                return false
            }
            return acc && true

        })

    }

    function getMetricCharts(metrics){
        
            const formcharts = formValues["charts"]

            const c22 =  metrics.map((m, i) => {
                const c = formcharts.find((c) => m.value.metric === c.metric)
                if (c) {
                    return { ...c, id: `chart-${i}` }
                }
                return { ...chart22def[0], id: `chart-${i}`, metric: m.value.metric, groupId: m.value.group_id }
            })

            console.log(metrics, c22)
            return c22

            /*
            console.log(metrics, c22)

            formSetValues(prev => ({
                ...prev,
                ["charts"]: c22
            }));
            */

    }

    const MetricsSelection = ({ options }) => {

        const metrics = formValues["metrics"];
        const selectionLimit = 2
    
        return (
            <SingleSelectDropDownControl  
              options={options}  title={"Metrics"} value={metrics} multi={true}
              handleChange={e => {
                if (e.length >= selectionLimit && metrics.length >= selectionLimit ) {
                  return
                }
                // e.persist();
                formSetValues(prev => ({
                  ...prev,
                  ["metrics"]: e,
                  ["charts"]: getMetricCharts(e)
                }));
                
              }}
              />
        )
    }
    const ChartPropertyEditor = () => {

        return (
            <>
                <div className="row">
                    <span style={{ color: "#EF8354" , fontSize: "16px", paddingTop: "18px", paddingLeft: "14px", marginBottom: "-42px"}}>{message}</span>

                </div>
                <div className="row">
                    <ChartControls>
                    <span className="top-label d-none">{isKey() ? getKey() : ""}</span>

                        <div className="right-section-top">
                            {/* <ButtonControl title={"Save"} handleClick={onChartSave} /> */}

                            {formValues && orgChart  
                            ? <span style={{ padding: "0px 10px", fontWeight:"600", color: "#01668D" }} > {`Shared by ${formValues.user} !!`} </span> 
                            : <CheckBoxField name="shared" title={"Share with Others"} />}
                            {/* : <span style={{padding:"0px 10px"}} className="text-success">Chart Shared!! </span>  */}

                            <Button active onClick={onSave} color="secondary">Save</Button>
                        </div>
                    </ChartControls>
                </div>
                <div className="row form-elements-section">
                    <div className="col-6">
                        <Label top>Dashboard Title</Label>
                        <TextField name="page" />
                    </div>
                    <div className="col-6">
                        <Label top>Chart Title</Label>
                        <TextField name="title" />
                    </div>
                    <div className="col-6 select-metric" style={{ marginTop: "8px" }}>
                        <Label top>Select Metric</Label>
                        {/* <DropDownField options={metricsData} name="metrics" multi={true} selectionLimit={2} /> */}
                        <MetricsSelection options={metricsData}  />
                    </div>
                    <div className="col-6" style={{ marginTop: "8px" }}>
                        <Label top>X-Axis Title</Label>
                        <TextField name="titleX" />
                    </div>
                </div>

                <div className="row">
                </div>

                <div className="row" style={{ height: "20vh" }}>
                    <div className="col-12 edit-chart-table"><TableField options={{ columns: chartColumns22 }} name={"charts"} observe={"metrics"}/></div>
                    {/* <TableField options={{ columns: chartColumns22 }} name={"charts"} /> */}
                </div>

                {/* <div className="row">
                <div className="col-4">
                    <Label top>Group Type</Label>
                    <DropDownField options={groupTypeData} name="grouptype" />
                </div>
                <div className="col-4">
                    <Label top>X-Axis Title</Label>
                    <TextField  name="titleX" />
                </div>
                <div className="col-4">
                    <Label top>Y-Axis Title</Label>
                    <TextField name="titleY" />
                </div>
            </div>
            <div className="row">
                <CheckBoxField name="stacked" />
                <CheckBoxField name="filled" />
                <CheckBoxField name="majority" />
                <CheckBoxField name="percent" />
            </div> */}
            </>
        )
    }

    const vheight = "100%";
    function selectChart(c) {
         console.log(".........chart selected .............inCharts", c);

        formSetValues(c ? c.value : { ...defaultChartData, org: userContext.org })

    }
    useEffect(() => {
        setOrgChartSelected(false)
        selectChart(chart)
    }, [chart])

    useEffect(() => {
        setOrgChartSelected(true)
        selectChart(orgChart)
    }, [orgChart])

    return (
        // <div className="d-inline-block" style={{maxHeight: "70vh", overflow: "auto"}}>


        <div className="edit-chart-wrapper">
            <div className="row" style={{ maxHeight: "100%", display: "flex", marginRight: "-1px", overflowY: "scroll" }}>
                <div className="col-3 left-section">

                    {chartsData && chartsData.length > 0
                        ? <>
                            <Label top><span style={{paddingTop: "15px"}}>Your Charts</span> <ChartExtraControls /></Label>
                            <SingleSelectDropDownControl options={chartsData} handleChange={setChart} title={'Select Chart'} value={chart} />
                        </>
                        : <Label top><span>Your Charts</span> <ChartExtraControls /></Label>}


                    {orgChartsData && orgChartsData.length > 0
                        ? <>
                            <Label top><span>Shared Charts</span></Label>
                            <SingleSelectDropDownControl options={orgChartsData} handleChange={setOrgChart} title={'Shared Chart'} value={orgChart} />
                        </>
                        : ""}


                    <Label top><span>Chart Description </span></Label>
                    {/* <ChartDesc id="json">{formValues ? JSON.stringify(formValues, undefined, 2): ""}</ChartDesc> */}
                    <ChartDesc id="json">{isKey() ? JSON.stringify(chartDesc, undefined, 2) : ""}</ChartDesc>
                </div>
                <div className="col-9" >
                    <ChartPropertyEditor />
                </div>
            </div>
        </div>

    )

}

// const defaultChartData = { datatype: {}, metric: [], interval: {}, page: "", title: "", charttype: {}, grouptype: {}, stacked: true, filled: false, majority: true, percent: false, titleX: "",titleY: "Device Count", chart22: []}

const defaultChartData = { page: "", title: "", titleX: "", metrics: [], charts: [], shared: false, org: "" }
export const UDCEditor = (props) => {
    const initial = useMemo(() => defaultChartData, [])

    return (
        <FormProvider initialValues={initial}>
            <ChartEditingBaseView {...props} />
        </FormProvider>

    )
}
