import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { AiOutlineSearch, AiFillInfoCircle, AiOutlineClose } from 'react-icons/ai'

import { useRealtimeContext } from '../../context/realtime.context'
import { useCriteriaContext } from '../../context/criteria.context'

const RealtimeSearch = styled.div`
    display: inline-block;
    position: relative;
    .realtime-search {
        width: 600px;
        height: 39px;
        position: relative;
        top: 2px;
        border-radius: 4px;
        color: rgb(1, 64, 89);
        font-size: 14px;
        border: 1px solid rgb(179, 179, 179);
        padding-left: 8px;
        padding-right: 34px;
    }
    
    .realtime-search:hover {
        border-color: rgb(1, 64, 89);
    }
    .queryapplied, .queryapplied:hover {
        border: 1px solid hsl(0, 0%, 90%);
        background: hsl(0, 0%, 95%);
        cursor: pointer;
    }
    .icon {
        font-size: 16px;
        position: absolute;
        top: 14px;
        right: 10px;
    }
    .hint-icon {
        font-size: 20px;
        position: absolute;
        top: 11px;
        right: -30px;
    }
    .suggestions {
        position: absolute;
        top: 221px;
        right: -922px;
        width: 100%;
    }
    .menu {
        width: 100%;
    }
`

const InfoDiv = styled.div`
    position: absolute;
    top: 10px;
    right: -334px;
    width: 300px;
    min-height: 100px;
    padding: 20px;
    z-index: 100;
    border: 0px solid rgba(0, 0, 0, .15);
    box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, .175);
    background: white;
    .heading {
        font-weight: 800;
        font-size: 12px;
        color: #014059;
    }
    .hint {
        padding-top: 10px;
    }
`

const Suggestions = styled.div`
    position: absolute;
    width: 100%;
    z-index: 10;
    border: 1px solid  #dedede;
`

const Suggestion = styled.div`
    width: 100%;
    border-bottom: 1px solid #dedede;
    padding: 8px;
`

const DOWN = 40
const UP = 38
const ENTER = 13
const ESC = 27


export default props => {

    const [showInfo, setShowInfo] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [showSuggestions, setShowSuggestions]   = useState(false)
    const [selected, setSelected]  =  useState(0)

    const realtimeContext = useRealtimeContext()
    const { autoSuggest, search, clearSearch, query, setQuery, setSearchApplied, queryApplied, setQueryApplied } = realtimeContext

    const criteriaContext = useCriteriaContext()

    const inputRef = useRef()
    const suggestionsRef = useRef()

    const handleChange = (event) => {
        // if (queryApplied) {
        //     return false
        // }
        if (event.keyCode === UP)
            event.preventDefault()
        const q = event.target.value
        setQuery(q)
        const  results = autoSuggest(q)
        setSuggestions(results)
        if (results.length >  0) {
            setShowSuggestions(true)
            setSelected(0)
        } else {
            setShowSuggestions(false)
        }
    }

    const doSearch = () => {
        setShowSuggestions(false)
        // setup search input
        const query  = suggestions[selected].suggestion
        setQuery(query)
        setQueryApplied({ mode: 'readonly', query: query })

        search(query)
    }

    const moveSelection = (event) => {
        if (showSuggestions) {
            if ( suggestions.length > 1) {
                if (event.keyCode === DOWN && selected < suggestions.length -1)
                    setSelected(selected + 1)
                if (event.keyCode === UP && selected > 0) {
                    setSelected(selected - 1)
                }
            }
            if (event.keyCode === ESC) {
                setShowSuggestions(false)
            }

            if (event.keyCode === ENTER) {
                doSearch()
            }

            if (event.keyCode === UP) {
                if (inputRef.current)
                    setTimeout(function(){ inputRef.current.selectionStart = inputRef.current.selectionEnd = 10000; }, 0);
            }
        }

        if (queryApplied.mode === 'editing' && event.keyCode === ESC) {
            setQueryApplied({ ...queryApplied, mode: 'readonly' })
            setQuery(queryApplied.query)
        }
    }

    const removeSearch = () => {
        setQueryApplied({ mode: "", query: "" })
        setQuery("")
        clearSearch()
    }

    const selectQuery = event => {
        if (showSuggestions && suggestionsRef.current && inputRef.current) {
            if (!suggestionsRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
                setShowSuggestions(false)
                if (queryApplied.mode === 'editing') {
                    setQueryApplied({ ...queryApplied, mode: 'readonly' })
                    setQuery(queryApplied.query)
                }
            }
        }

        if (queryApplied.mode === 'readonly' && inputRef.current && inputRef.current.contains(event.target)) {
            setQueryApplied({ ...queryApplied, mode: 'editing' })
            setTimeout(function(){ inputRef.current.selectionStart = inputRef.current.selectionEnd = 10000; }, 0);
        }

        if (queryApplied.mode === 'editing' && !showSuggestions && inputRef.current && !inputRef.current.contains(event.target)) {
            setQueryApplied({ ...queryApplied, mode: 'readonly' })
            setQuery(queryApplied.query)
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', moveSelection, true)
        document.addEventListener('click', selectQuery, true)

        return () => {
            document.removeEventListener('keydown', moveSelection, true)
            document.removeEventListener('click', selectQuery, true)
        }
    })

    // useEffect(() => {
    //     setQuery("")
    //     setSuggestions([])
    //     setShowSuggestions(false)
    //     setSelected(0)
    //     setQueryApplied({ mode: "", query: ""})
    // }, [criteriaContext.site])

    useEffect(() => {
        if (inputRef.current) {
            if (queryApplied.mode === 'readonly')
                inputRef.current.blur()
            else
                inputRef.current.focus()
        }

        setSearchApplied(queryApplied.mode === 'readonly')
    }, [queryApplied])

    return <React.Fragment>
        <RealtimeSearch>
            <input 
                className={`realtime-search ${queryApplied.mode === 'readonly' ? 'queryapplied' : ''}`}
                // style={ { color: 'white' } }
                // delay={ 1000 }
                placeholder="Search realtime data.."
                value={query}
                onChange={handleChange}
                ref={inputRef}
                // disabled={queryApplied.mode === 'readonly'}
            />
            {queryApplied.mode === 'readonly' ? 
                <AiOutlineClose className="icon" color="#014059"   style={{cursor: 'pointer'}} onClick={removeSearch}/> : 
                <AiOutlineSearch className="icon" color="#014059"/>
            }
            <AiFillInfoCircle 
                className="hint-icon" 
                color="#014059"

                onMouseOver={() => setShowInfo(true)}
                onMouseLeave={() => setShowInfo(false)}
            />
            {showInfo ? <InfoDiv>
                <div className="heading"><span>How to search:</span></div>
                <div className="hint"><span>Type any text. <br/>For example: manual, A1T12R12, etc.</span></div>
                <div className="hint"><span>Column specific. <br/>For example: Alerts: Over current</span></div>
            </InfoDiv> : null}
            {showSuggestions ? <Suggestions ref={suggestionsRef}>
                {suggestions.map((t, i) => <Suggestion style={{
                        backgroundColor: i === selected ? "#014059" : 'white',
                        color: i   === selected ? 'white'  : '#014059'
                    }} 
                    onMouseMove={() => setSelected(i)}
                    onClick={doSearch}
                >
                    {t.suggestion}
                </Suggestion>)}
            </Suggestions> : null}

        </RealtimeSearch>
    </React.Fragment>
}