import React, { useState } from "react";
import nextId from "react-id-generator";
import { Popover } from "reactstrap";
import { createActivityLog } from "../../utils/apiCall";
import { getLogData } from "../../helpers/common";
import {FiArrowLeft} from 'react-icons/fi'
function CardImage(props) {

    return (
        < div className="col-xl-5 col-lg-4 align-items-center d-flex justify-content-center" >
            <img src={props.url} height="150px" className="d-none d-lg-block" alt="" />
        </div >
    );

}
export const CardHeaderSelect = ({ value, selected, options, optionLabel, optionKey, usedFor, metric, level, criteria }) => {
    function handler(e) {
        // var arr = e.target.value.split(",").map(function(item) {
        //     return item.trim();
        //   });
        //   console.log("e  ", e.target.value, arr)
        selected(e.target.value)
        if(usedFor === 'csvDownloadViewer'){
            createActivityLog(getLogData(criteria, {featureId: `view-${level}-metrics`.toLowerCase(), metrics: metric, usecase: `view metrics data`.toLowerCase(), category: 'downloads', level: level, details: '{}'}))
        }
        
    }
    //console.log(options)
    return (
        <select className="popup-select-control" value={value} onChange={handler} >{options.map((e) => <option key={nextId()} value={e[optionKey]}>{e[optionLabel]}</option>)}</select>
    )
}
function tt(title, doc) {
    return `
        <em>${title}</em> this is the doc
    `
}
export const CardHeader = (props) => {

    //const title = domain + ' - ' + suffix
    return (
        <div className="mb-1">
            {props.showBack ? <span><FiArrowLeft size={25} style={{marginRight:5,marginBottom:4}} onClick={props.onClick?props.onClick:null}></FiArrowLeft></span>:""}
            {props.loading ? <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span> : ""}
            <b className="mb-3 chart-card-header" data-toggle="popover" data-trigger="hover" data-title={props.title} data-placement="top" data-content={props.title} onClick={(props.showBack && props.onClick)?props.onClick:null} >{props.title}</b>
            {/* <span className="ml-2">
                <i className="fa fa-info-circle" 
                data-toggle="popover" data-trigger="hover"  data-title={props.title} data-placement="top" data-content={props.title}
                // data-toggle="tooltip" data-html="true" title={tt("rest in peace")}
                >
                   
                </i>
            </span> */}

            <span className="ml-12 float-right bg-transparent d-flex card-top-action">
                {props.children}

            </span>
        </div>

    )
}


export function Card2(props) {
    return (
        <div className="" >
            <div className="card border-0 text-truncate mb-3">
                {/* <!-- begin card-body --> */}
                <div className="card-body">
                    {props.children}
                </div>
                {/* <!-- end card-body --> */}
            </div>

        </div>

    );
}



export function CardSummary(props) {
    return (
        // col-xl-2 col-lg-3 col-md-4 col-sm-8 
        <div className="col-xl-3 col-lg-3 col-xs-12 col-md-6 col-sm-6 pl-0" >

            {/* <!-- end widget-stats --> */}
            <div className={props.class + " card summary-cards ml-0"}>
                {props.children}

            </div>
            {/* <!-- end widget-stats --> */}
        </div>


    );
}
export function CardStats(props) {
    return (
        // col-xl-2 col-lg-3 col-md-4 col-sm-8 
        <div className="col-xl-3 col-lg-3 col-xs-12 col-md-6 col-sm-6 pl-0" >

            {/* <!-- end widget-stats --> */}
            <div className={props.class + " card summary-cards"}>
                {props.children}

            </div>
            {/* <!-- end widget-stats --> */}
        </div>


    );
}

export function CardPopover(props) {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [pid] = useState('Popover' + nextId())

    const toggle = () => setPopoverOpen(!popoverOpen);
    const Child = () => { return props.popChild(props.popData) }
    return (
        <>
            <div id={pid} type="button" style={{ width: "100%" }}>
                {props.children}
            </div>
            <Popover placement="bottom" isOpen={popoverOpen} target={pid} toggle={toggle} hideArrow={true} >
                <Child />
                {/* <PopoverHeader>Popover Title</PopoverHeader>
        <PopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</PopoverBody> */}
            </Popover>
        </>

    );
}


export function Card4Opacity80(props) {
    return (
        <div className=" col-md-auto" >
            <div className="card border-0 mb-7 popup-background">
                {/* <!-- begin card-body --> */}
                <div className="card-body">
                    {props.children}
                </div>
                {/* <!-- end card-body --> */}
            </div>

        </div>

    );
}

export function CardDialog(props) {
    return (
        // <div className=" col-md-auto" >
            <div className="border-0  mb-7">
                {/* <!-- begin card-body --> */}
                {/* <div className="card-body"> */}
                    {props.children}
                {/* </div> */}
                {/* <!-- end card-body --> */}
            </div>

        // </div>

    );
}



export function Card6Half(props) {
    return (
        // <div className="" >
        //{/* <!-- begin card --> */}
        //Removed chart-ht css class
        <div className="col-md-6 card border-0 text-truncate mb-3 d-flex flex-wrap-reverse">
            {/* <!-- begin card-body --> */}
            <div className="card-body">
                {props.children}
            </div>
            {/* <!-- end card-body --> */}
        </div>
        // {/* <!-- end card --> */}
        // </div>

    );
}
export function Card6(props) {
    return (
        // <div className="" >
        //{/* <!-- begin card --> */}
        <div className="col-md-12 card border-0 text-truncate mb-3">
            {/* <!-- begin card-body --> */}
            <div className="card-body">
                {props.children}
            </div>
            {/* <!-- end card-body --> */}
        </div>
        // {/* <!-- end card --> */}
        // </div>

    );
}
export function Card10(props) {
    return (
        // <div className="" >
        //{/* <!-- begin card --> */}
         //Removed chart-ht css class
        <div className="col-md-10 card border-0 text-truncate mb-3 chart-card">
            {/* <!-- begin card-body --> */}
            <div className="card-body">
                {props.children}
            </div>
            {/* <!-- end card-body --> */}
        </div>
        // {/* <!-- end card --> */}
        // </div>

    );
}
export function CardFull(props) {
    return (
        // <div className="" >
        //{/* <!-- begin card --> */}
         //Removed chart-ht css class
        <div className="col-12 card border-0 text-truncate mb-3 chart-card">
            {/* <!-- begin card-body --> */}
            <div className="card-body ">
                {props.children}
            </div>
            {/* <!-- end card-body --> */}
        </div>
        // {/* <!-- end card --> */}
        // </div>

    );
}

