import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ContextFactory from './base.context'
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js'
import { showNotification } from "../components/notifications/notify"

import { usePageActionContext } from "./page.context"
import jwt from 'jsonwebtoken'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { getStatsData } from '../utils/apiCall';
import { getAccessToken, getUserEmail } from '../components/auth/userinfo'


const NotificationContext = React.createContext()

String.prototype.escapeSpecialChars = function() {
    return this.replace(/[\\]/g, '\\\\').replace(/\'/g, "\\'").replace(/\"/g, '\\"').replace(/\n/g, "\\n")
};

export const NotificationContextProvider = props => {
    const [jobs, setJobs] = useState([])
    const [runningJobs, setRunningJobs] = useState([])
    const [jobId, setJobId] = useState(null)
    const [timezone, setTimezone] = useState(null)
    const [notificationShown, setNotificationShown] = useState(false)
    const [reminders, setReminders] = useState([])
    const [showOngoing, setShowOngoing] = useState(false)

    const [inprogressJob, setInprogressJob] = useState(null)
    const [updatedJob, setUpdatedJob] = useState(null)
    const [doneJob, setDoneJob] = useState(null)

    const [releases, setReleases] = useState([])
    const [error, setError] = useState("")
    const [newEntry, setNewEntry] = useState(false)
    const [notifications, setNotifications] = useState([])

    const pageActionContext = usePageActionContext()

    useEffect(() => {
        setReleases([])
        setError("")
        
        let token = getAccessToken()

        axios({
            url: `releasenotes?querytype=releases&restype=json&project=depcom`,
            method: 'GET',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }).then(result => {
            setReleases(result.data.map(t => {
                const jsonContent = t.content ? JSON.parse(t.content) : {}
                const contentState = convertFromRaw(jsonContent)
                const content = EditorState.createWithContent(contentState)
                return {...t, content: content, publishedDate: new Date(t.published_at).toDateString()}
            }))

            if (result.data.length > 0) {
                const id = result.data[0].id
                const userData = localStorage.getItem('user')
                let token = ''
                if (userData) {
                    const jsonUserData = JSON.parse(userData)
                    token = jsonUserData.token
                }
                const decoded = jwt.decode(token)
                const lastRelease = localStorage.getItem('lastRelease' + decoded.name)
                if (id !== lastRelease) {
                    setNewEntry(true)
                }
            }
        }).catch(err => {
            setError(err.message)
        })

        const url = `maintenance?querytype=reminders&user=${getUserEmail()}&restype=json&project=depcom`
        getStatsData(url, response => {
            setReminders(response)
        }, err => {
            setError(err.message)
        })

        const jobsUrl = `jobs?querytype=jobs_get&user=${getUserEmail()}&restype=json&project=depcom`
        getStatsData(jobsUrl, response => {
            setJobs(response)
            setRunningJobs(response)
        }, err => {
            setError(err.message)
        })

    }, [])

    useEffect(() => {
        let interval
        if (jobs.length > 0) {
            interval = setInterval(() => {
                if (jobId === null) {
                    console.log('job_status: set job id ' + jobs[0].id)
                    setJobId({
                        id: jobs[0].id,
                        message: jobs[0].done_message,
                        progressMessage: jobs[0].progress_message,
                        status: 'in progress'
                    })

                    setJobs(jobs.filter((t, i) => i !== 0))
                }
            }, 100)
        } else  {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    }, [jobs])

    useEffect(() => {
        if (!notificationShown && reminders && reminders.length > 0 && timezone !== null){
            showNotification(
                getMessage(reminders),
                () => {
                    setShowOngoing(true)
                    pageActionContext.setPage("Site Maintenance", [], false)
                },
                "Do it now",
                timezone,
                false,
                true
            )
            setNotificationShown(true)
        }
    }, [reminders, timezone])

    useEffect(() => {
        if (doneJob) {
            console.log('job_status: done job ' + doneJob.id)
            showNotification(
                doneJob.message,
                () => {
                    window.location.reload()
                },
                "Reload",
                "Etc/UTC",
                true,
                false,
                true
            )
            removeNotification(doneJob.id)
            setDoneJob(null)
        }
    }, [doneJob])

    useEffect(() => {
        if (jobId) {
            console.log('job_status: added job ' + jobId.id)
            const notification = { 
                title: jobId.progressMessage,
                published_at: moment().valueOf(),  
                action: null, 
                timezone: "Etc/UTC", 
                remindLater: false,
                id: jobId.id,
                status: jobId.status || "starting"
            }
            addNotification(notification)
            if (runningJobs.filter(t => t.id === jobId.id).length === 0) {
                setRunningJobs([...runningJobs, jobId])
            }
            setInprogressJob(jobId)
            setJobId(null)
            console.log("job_status: addNotification", notifications)
        } 
    }, [jobId])

    useEffect(() => {
        if (inprogressJob) {
            console.log('job_status: in progess job ' + inprogressJob.id)
            inprogressJob.timeout = setTimeout(() => {
                console.log('job_status: 5 seconds later..' + inprogressJob.id)
                startPolling({...inprogressJob})
                setInprogressJob(null)
            }, 5000)
            console.log('job_status: ', inprogressJob.id, inprogressJob.timeout)
        }
    }, [inprogressJob])

    useEffect(() => {
        if (updatedJob) {
            console.log('job_status: updated job ' + updatedJob.id + " " + updatedJob.result)
            const inprogress = handleJobResult({...updatedJob})
            console.log('job_status: job in progress: ' + inprogress)
            setUpdatedJob(null)
        }
    }, [updatedJob])

    const startPolling = (job) => { 
        console.log('job_status: starting polling..' + job.id)
        let token = getAccessToken()
        axios({
            url: `/job_status?job_id=${job.id}`,
            method: 'GET',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log('job_status: got response ' + response.data + " " + job.id)
            setUpdatedJob({...job, result: response.data})
        })
        .catch(err => {
            setUpdatedJob({...job, result: "error"})
        })
        
    }

    const handleJobResult = (job) => {
        if (job.result === 'finished') {
            setDoneJob(job)
        } else if (job.result === 'failed') {                
            updateNotification(job.id, "failed") 
        } else if (job.result === 'started') {
            updateNotification(job.id, "in progress")                
            setInprogressJob({...job})
        } else if (job.result === 'error') {
            updateNotification(job.id, 'error') 
        } else {
            updateNotification(job.id, job.result)                
            setInprogressJob({...job})
        }

        clearTimeout(job.timeout)
    }

    const getScope = (site_id, zone_id,  device_id) => {
        let scopeArr = [site_id]
        if (zone_id) {
            scopeArr.push(zone_id)
        }

        if (device_id) {
            scopeArr.push(device_id)
        }

        return scopeArr.join("/")
    }

    const getMessage = reminders => {
        if (reminders.length > 1) {
            let message = ['Do you want to close the following ongoing maintenance activities?']
            reminders.forEach(t => {
                const scope = getScope(t.site_id, t.zone_id, t.device_id)
                message.push(`${t.type} for ${scope}`)
            })
            return message
        } else {
            const scope = getScope(reminders[0].site_id, reminders[0].zone_id, reminders[0].device_id)
            return `Do you want to close the ongoing ${reminders[0].type} activity for ${scope}?`
        }
    }

    const readNewEntry = () => {
        if (releases.length > 0 && newEntry) {
            const userData = localStorage.getItem('user')
            let token = ''
            if (userData) {
                const jsonUserData = JSON.parse(userData)
                token = jsonUserData.token
            }
            const decoded = jwt.decode(token)
            const id = releases[0].id
            localStorage.setItem('lastRelease' + decoded.name, id)
            setNewEntry(false)
        }
    }

    const saveReleaseSync = (type, id, title, content, callback, errorCallback) => {
        //const userData = localStorage.getItem('user')
        let token = getAccessToken()
       // if (userData) {
       //     const jsonUserData = JSON.parse(userData)
       //     token = jsonUserData.token
       // }

        const contentState = content.getCurrentContent()
        const rawContent = convertToRaw(contentState)
        const querytype = type === 'add' ? 'release_add' : 'release_edit'


        axios({
            url: `releasenotes?querytype=${querytype}&restype=json&project=depcom`,
            method: 'POST',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: {
                id: id,
                title: title.escapeSpecialChars(),
                content: JSON.stringify(rawContent).escapeSpecialChars()
            }
        }).then(result => {
            callback()
        }).catch(err => {
            errorCallback(err.message)
        })
    }

    const addNotification  =  notification => {
        setNotifications([notification, ...notifications])
    }

    const removeNotification = id => {
        setNotifications(notifications.filter(t => t.id !== id))
    }

    const updateNotification = (id, status) => {
        setNotifications(notifications.map(t => t.id === id ? {...t, status} : t))
    }

    return <NotificationContext.Provider value={{
        releases,
        saveReleaseSync,
        setReleases,
        error,
        newEntry,
        readNewEntry,
        notifications,
        addNotification,
        removeNotification,
        showOngoing,
        setShowOngoing,
        setTimezone,
        setJobId,
        runningJobs
    }}>
        {props.children}
    </NotificationContext.Provider>
}

export const useNotificationContext = ContextFactory("NotificationContext", NotificationContext)