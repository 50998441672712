import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ContextFactory from "./base.context";
import { useCriteriaContext } from "./criteria.context";
import { setWindowLocation } from "../navigation/link";
import { getUrlFromCriteria, isZone, isInverter, isDevice } from "../components/layout/criteria";
import { toJS } from "mobx";
import _ from 'lodash'
import { usePageNotFoundContext } from "./pageNotFound.context";
import { getUserToken, isHailAdminUser, isHailUser, ischangePasswordPage, isSunpathUser } from "../components/auth/userinfo";

const PageContext = React.createContext();
const PageActionContext = React.createContext();

// const defaultPage = {Pagelist, setPageList} //{time: '', domain: ''}
export const usePageContext = ContextFactory('PageContext', PageContext)
export const usePageActionContext = ContextFactory('PageActionContext', PageActionContext)
// export const mainPage = isHailAdminUser() ? "addonfeature" :"Overview";
export const mainPage = ischangePasswordPage() 
    ? "changePassword" 
    : isHailAdminUser() 
        ? "addonfeature" 
        : "Overview";
export const userPage = "My Dashboards";
export const modelPage = "Model Viewer"
export const detailsPage = "Live Status"
export const sessionPage = "User Analytics"
export const maintenancePage = "Site Maintenance"
export const settingsPage = "Settings"
export const energyMeterPage = "Energy Insights"
export const addonfeaturePage = "addonfeature"
export const diffusePage = "Diffuse"
export const changePasswordPage = "changePassword"
export const dataImportPage = "Data Import"



const featureObj = {
    "Overview": 'overview',
    "My Dashboards": 'dashboards',
    "Model Viewer": 'model',
    "Live Status": 'live',
    "Downloads": 'downloads',
    "User Analytics": 'useranalytics',
    "Settings": 'settings',
    "Energy Insights":"energy",
    "Site Maintenance": 'maintenance',
    "addonfeature": 'addonfeature',
    "Diffuse": 'diffuse',
    "changePassword": 'changePassword',
    'Data Import': 'Data Import'
}

const PageContextProvider = (props) => {
    const initState = {        page: mainPage,        charts: [], sidebar: 'expanded', isChart: false    } 

    const [pageData, setPageData] = useState(initState)
    const criteria = useCriteriaContext()
    const pageNotFoundContext = usePageNotFoundContext();
    const clearPage = () => {
        setPageData({...pageData, page: mainPage})
    }

    const setPage = (page, charts=[], isChart) => {
        setPageData(Object.assign({}, pageData, {page: page, charts: charts, isChart: isChart}))
        
    }

    const toggleSidebar = (page) => {
        setPageData(Object.assign({}, pageData, {page: page, sidebar: pageData.sidebar === 'expanded' ? 'collapsed' : 'expanded'}))
    }

    const initActionState = {
        setPage: setPage,
        // setChartData: setChartData,
        clearPage: clearPage,
        toggleSidebar: toggleSidebar
    } 
    const [actionState, setActionState] = useState(initActionState)


    useEffect(() => {
        if (pageData && !_.isEmpty(criteria) && getUserToken()){
            var page = pageData.page.includes(userPage) ? `${pageData.page.replace(userPage,featureObj[userPage])}` : featureObj[pageData.page]
            let criteriaUpdated = resetSelectionForOverviewEnergySwitch(toJS(criteria), page);
            setWindowLocation(getUrlFromCriteria(criteriaUpdated, page))
        }
    }, [pageData])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function resetSelectionForOverviewEnergySwitch(criteria, page) {
        if(isInverter(criteria) && page!=="" && page!==undefined && page!==featureObj[energyMeterPage]){
            criteria.inverter = ""    
            return Object.assign(criteria, { site: criteria.site, zone: "", device: "", grouptype: "zones" })    
        } else if((isZone(criteria) || isDevice(criteria)) && page === featureObj[energyMeterPage]){
            criteria.zone = "";   
            criteria.device = "";     
            return criteria;
        }
        return criteria
    }

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let pathName = params.get("page")        
        if(!pathName){pathName = featureObj[mainPage]}
        const featureKey = Object.keys(featureObj).find(k => featureObj[k] === pathName)
        const sString = pathName.split("/")[0]
        console.log(featureKey,sString)
        if (isHailAdminUser() && featureKey !== addonfeaturePage && featureKey !== settingsPage && featureKey !== changePasswordPage){
            pageNotFoundContext.setPageNotFoundStatus(true)
        }else if((!isHailUser() && featureKey == 'Settings') || (!isSunpathUser() && featureKey == 'Diffuse')){
            pageNotFoundContext.setPageNotFoundStatus(true)
        }
        else if(pathName && featureKey){
            setPageData({...pageData, page: featureKey})
        }else if (pathName && sString === featureObj[userPage]){
            pathName = pathName.replace(featureObj[userPage],userPage)
            setPageData({...pageData, page: pathName})
        }else{
            pageNotFoundContext.setPageNotFoundStatus(true)
        }
        
    },[])
    
    const setChartData = (charts) => {
        // setPageData({...pageData, charts: charts})
        pageData.charts = charts
        // console.log("Charts Selected ", pageData )

    }



    return (
        <PageContext.Provider value={pageData}>
            <PageActionContext.Provider value={actionState}>
                {props.children}
            </PageActionContext.Provider>
        </PageContext.Provider>
    )
}

export default observer(PageContextProvider)
