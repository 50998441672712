// @ts-check
import { useEffect, useState } from 'react'

function getCurrentLocation() {
    // console.log("Clearing Current Session STORAGE")
    window.sessionStorage.removeItem("usernav")

    return {
        pathname: window.location.pathname,
        search: window.location.search,
        usernav: false
    }
}

/**
 * @type {Array<() => void>}
 */
const listeners = []

/**
 * Notifies all location listeners. Can be used if the history state has been manipulated
 * in by another module. Effectifely, all components using the 'useLocation' hook will
 * update.
 */
export function notify() {
    listeners.forEach(listener => listener())
}

export function useLocation() {

    const [{ pathname, search, usernav }, setLocation] = useState(getCurrentLocation())

    useEffect(() => {
        window.addEventListener('popstate', handleChange);
        return () => window.removeEventListener('popstate', handleChange);
    }, [])

    function handleChange(event) {
        console.log("HNDLE CHANGE Step4::", event)
        setLocation(Object.assign({}, getCurrentLocation(), {usernav: true}))
    }

    /**
     * @param {string} url
     */
    function push(url) {
        window.history.pushState(null, '', url)
        notify()
    }

    /**
     * @param {string} url
     */
    function replace(url) {
        window.history.replaceState(null, '', url)
        notify()
    }

    return {
        push,
        replace,
        pathname,
        search,
        usernav
    }
}