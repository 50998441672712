import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import styled from "styled-components";
import { getUserPortfolios, isAdminUser,getAccessToken } from "../components/auth/userinfo";
import { GetFQURL, postJSONData } from "../components/model/store.swr";
import { useCriteriaContext } from "../context/criteria.context";
import { createActivityLog } from "../utils/apiCall";
import { getLogData } from "../helpers/common";

const Styles = styled.div`
tbody {
    background-color: rgba(255,255,255,0.2);
    color: black;
}
.table td, .table th {
    font-size: 1em;
    // padding: 10px;
    color: white;
    background-color: rgba(3,3,3,0.6);
}


`


const urls = [
    { name: "sites", url: "meta_sites", format: "json" },
]

function makeColumnsBT(columns) {
    return columns.map(column => {
        return {
            dataField: column,
            text: column
        }
    });
}
// site_id,	owner,	longitude,	latitude,	utc,	dst,  disabled,	internal
const editable = isAdminUser()
const siteColumns = [
    { dataField: 'site_id', text: 'Site Name', editable: false, sort: true },
    { dataField: 'longitude', text: 'Longitude', editable: editable },
    { dataField: 'latitude', text: 'Latitude', editable: editable },
    // { dataField: 'utc_offset', text: 'UTC', sort: true, editable: editable },
    { dataField: 'timezone', text: 'Timezone', sort: true, editable: editable },
    { dataField: 'dst', text: 'DST?', type: 'bool', editor: { type: Type.CHECKBOX, value: 'YES:NO', }, formatter: boolFormatter,  sort: true, editable: editable },
    { dataField: 'model_url', text: 'Model Url', editable: editable },
    { dataField: 'solcast_resource_id', text: 'Solcast Resource Id', editable: editable },
    { dataField: 'owner', text: 'Customer', sort: true, editable: editable },
    { dataField: 'disabled', text: 'Disabled?', type: 'bool', editor: { type: Type.CHECKBOX, value: 'true:false' }, formatter: boolFormatter, sort: true, editable: editable },
    { dataField: 'internal', text: 'Testing?', type: 'bool', editor: { type: Type.CHECKBOX, value: 'true:false' }, formatter: boolFormatter, sort: true, editable: editable },
];

const formatBool = (cell, row) => {
    console.log("formmater called");
    return cell === null ? false : cell
}

const ScrollableStyled = styled.div`
display: inline-block;
max-height: 80%;
overflow: auto;
margin: auto 1.5rem;

`
function boolFormatter(cell, row, rowIndex, formatExtraData) {
    //glyphicon glyphicon-ok
    //
    return (
      <span className={ cell? "d-block": "d-block" } > {cell ? "Yes": "No"}</span>
    );
  }

export const MetaSitesBaseView = (props) => {
    const [urls, setUrls] = useState([{ name: "sites", url: "meta_sites", format: "json" }])
    const criteria = useCriteriaContext()
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(props.args.loading);
    const [selectOnly] = useState(props.args.selectOnly);
    const mountedRef = useRef(true)
    const [row, setRow] = useState()
    const [response, setResponse] = useState("Please wait loading sites ...")
    const [selected, setSelected] = useState( [])
    const [reloadUrls, setReloadUrls] = useState(0)

   // const userData = localStorage.getItem('user')
    let token = getAccessToken()
   //if (userData) {
   //    const jsonUserData = JSON.parse(userData)
   //    token = jsonUserData.token
   //}

    function filterByUserPortfolios(data){
        const pfs = getUserPortfolios()
        if (pfs && pfs.length > 0) {
            const apfs = pfs.split(",").map(e=> e.trim())
            // console.log(apfs)
            const filteredData = data.filter(f=> f.portfolios.split(",").some(s => apfs.includes(s)))
            // console.log(filteredData)
            setData(filteredData)
            return
        }
        setData(data)

    }

    function fetchJSONData(url) {
        fetch(url, {headers: {'Content-Type': 'application/json','x-access-token': token}})
            .then(res => res.json())
            .then(data => {
                if (!mountedRef.current) return null
                // console.log('Checkout this JSON! ', data)
                // setColumns(data && data.length > 0 ? makeColumnsBT(Object.keys(data[0])) : [])
                // setData(data);
                filterByUserPortfolios(data)
                setLoading(false)
            })
            .catch(err => {
                if (!mountedRef.current) return null
                setLoading(false)
                throw err
            });
    }


    useEffect(() => {
        mountedRef.current = true
        setLoading(true)
        const url = GetFQURL(urls[0], criteria, "json", true)
        // console.log("Fetch Json " + url)

        // handleUrlChange()
        fetchJSONData(url)
        return () => {
            mountedRef.current = false
        }
    }, [urls, reloadUrls])

    function handleRowClick(row, history) {
        console.log("Row Selected ", row)
        setRow(row)
    }

    const handleOnSelect = (row, isSelect) => {
        // setSelected(selected.filter(e => e !== row.site_id))
        var changed = []
        if (isSelect) {
            changed = [...selected, row.site_id]
        } else {
            changed = selected.filter(e => e !==  row.site_id)
        }
        props.args.onSelect(changed)
        setSelected(changed)

        // return true; // return true or dont return to approve current select action
    }
    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.site_id);
        if (isSelect) {
            props.args.onSelect(ids)

            setSelected(ids)

        } else {
            setSelected([])
        }
        // return true; // return true or dont return to approve current select action
    }
/*
    useEffect(() => {
        // handleUrlChange()
        if (selectOnly) {
            props.args.onSelect(selected)
            console.log(selected)
        }
        
    }, [selected])
*/
    useEffect(() => {
        console.log("Props selcected", props.sitesSelected)
        // handleUrlChange()
        setSelected(props.sitesSelected)

        
    }, [props.sitesSelected])


    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selected: selected,
        // bgColor: '#F5F4F4'

    };
    return (
        <div className="d-inline-block edit-sites-table" style={{height: "55vh", overflow: "auto", margin: "auto 0", width: "100%"}}>
        {/* <> */}
            {(!data || data.length === 0) && <div className="loading-message">{response}</div>}

            {(data && data.length > 0) &&

                // <CardDialog>

                <BootstrapTable 
                    // sytle={{ overflow: "auto", display: "inline-block", height: "70vh" }}
                    keyField='site_id'
                    data={data}
                    columns={siteColumns}
                    // selectRow={selectRow} 
                    {...(selectOnly ? { 'selectRow': selectRow } : {})}
                    // striped={ true }

                    cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, column) => {
                            if (oldValue !== newValue) {
                                createActivityLog(getLogData(criteria, {featureId: `edit-sites`,  usecase: `edit sites data`, category: 'edit-sites', sites: row.site_id}))
                                postJSONData("/meta?querytype=meta_sites_update", row, (data)=>{setResponse(`Saved Site ! ${JSON.stringify(data)}`); setReloadUrls(reloadUrls + 1)})
                            }
                        }

                    })}
                // pagination={ paginationFactory()}
                />
                // </CardDialog>
            }
        {/* </> */}
        <style>
            {`
                .edit-sites-table .react-bootstrap-table tbody td {
                    word-wrap: break-word;
                }

                .edit-sites-table .react-bootstrap-table thead th {
                    width: 112px;
                }

                .edit-sites-table .react-bootstrap-table thead th .order{
                    margin-left: 5px
                }
            `}
        </style>
        </div>

    )
}
/*
function parseSites(data, args, criteria) {
    console.log("Parser Meta Sites ", data)
    return data.sites
}

const comps = {
    title: 'Plant List',
    chart: MetaSitesBase,
    urls: urls,
    noheader: true,
    nocard: true,
    default: [],
    parser: { f: parseSites, args: {} }
}

const MetaSitesList = withData({ ...comps }) //withData(PerfChartBase, urls)
export default MetaSitesList;
*/