import React, { createContext, useState, useEffect } from "react";
import ContextFactory from "./base.context";
import { postJSONData } from '../components/model/store.swr';
import { getUserPortfolios } from "../components/auth/userinfo";

const PageNotFoundContext = createContext()

export const usePageNotFoundContext = ContextFactory('PageNotFound', PageNotFoundContext) 

export const PageNotFoundContextProvider = (props) => {
    const [isPageNotFound, setPageNotFoundStatus] = useState(false)

    
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParams = urlParams.get('path') && urlParams.get('path').split("/")
        if(!myParams){return}
        const userPf = getUserPortfolios()
        if(userPf && userPf !== myParams[0]){
            setPageNotFoundStatus(true)
            return
        }
        const validateUrlParams = async (params) => {
            await new Promise((resolve,reject) => {
                postJSONData(
                    "/meta?querytype=meta_url_validation&restype=json",
                    {pf: params[0], site: params[1] ? params[1] : '', zone: params[2] ? params[2] : '', device: params[3] ? params[3] : ''},
                    (res) => {
                        console.log("response",res)
                        if(res && Array.isArray(res)){
                            resolve()
                        }else if (res && res.status === 'completed'){
                            setPageNotFoundStatus(true)
                            reject()
                        }
                        
                })
            })
            
        }
        validateUrlParams(myParams)
    },[])

    return(
        <PageNotFoundContext.Provider value={{setPageNotFoundStatus: setPageNotFoundStatus, isPageNotFound: isPageNotFound}} >
            {props.children}
        </PageNotFoundContext.Provider>
    )
} 

