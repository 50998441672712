import React from "react";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { FaLightbulb } from "react-icons/fa";

export const ViewRecommendation = ({ rec }) => {
    const parseString = (item) => {
        const str = item.split("|")
        return str.map((s) => (
            <div style={{ whiteSpace: "normal", wordWrap: "normal", maxWidth: "1000px" }}>
                {s}
            </div>
        ));
    }
    const items = Array.isArray(rec)? rec : JSON.parse(rec)
    const [randomID, setRandomID] = useState(String(Math.random()));
    return (
        <div>
            <span data-tip data-for={randomID}>
                <FaLightbulb style={{fill: "#205888", marginLeft: "55px"}}/>
            </span>
            <ReactTooltip id={randomID} place="left" effect="solid">
                <ul style={{listStyleType: "none", padding: 0}}>
                    {items.map((item, index) => (
                        <li key={index}>
                            <h4><b>{item.split(":")[0]}</b></h4>
                            <p>{parseString(item.split(":")[1])}</p>
                        </li>
                    ))}
                </ul>
            </ReactTooltip>
        </div>
        // <OverlayTrigger
        //     placement="left"
        //     delay={{ show: 250, hide: 400 }}
        //     overlay={renderTooltip}
        // >
        //     <span>View Recommendation</span>
        // </OverlayTrigger>
    );
};


