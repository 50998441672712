import { observer } from "mobx-react";
import React, { useState } from "react";
import ContextFactory from "./base.context";

const DataviewContext = React.createContext();
const DataviewActionContext = React.createContext();

// const defaultDataview = {dataviewlist, setDataviewList} //{time: '', domain: ''}
export const useDataviewContext = ContextFactory('DataviewContext', DataviewContext)
export const useDataviewActionContext = ContextFactory('DataviewActionContext', DataviewActionContext)

const DataviewContextProvider = (props) => {
    const setDataView = (data) => {
        setState({...state, views: [data]})
    }
    
    const clear = () => {
        setState({...state, views: []})
    }

    const initState = {
        views: [],
    } 

    const initActionState = {
        setView: setDataView,
        clearView: clear
    } 
    
    const [state, setState] = useState(initState)
    const [actionState, setActionState] = useState(initActionState)

    return (
        <DataviewContext.Provider value={state}>
            <DataviewActionContext.Provider value={actionState}>
                {props.children}
            </DataviewActionContext.Provider>
        </DataviewContext.Provider>
    )
}

export default observer(DataviewContextProvider)
