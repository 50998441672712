
import * as echarts from 'echarts';
import tinycolor from 'tinycolor2';


export const mode_names = [
    //"OFFLINE",
    "IDLE",
    "MANUAL",
    "WIND-STOW",
    "SNOW-STOW",
    "CLEAN-STOW",
    "NIGHT-STOW",
    "EMERGENCY-STOW",
    "COMMERROR-STOW",
    "AUTO",
    "CYCLE-TEST",
    "UNKNOWN",
    "HAIL-STOW",
    "AROM",
    "WLOCK_STOW",
    "WLOCK_REL_STOW",
    "RSTMANUAL",
    "LOCK-CALIB-MODE",
    "LOWBAT-STOW"
]

export const mode_names_gc = ["initialization", "tracking", "antishading", "overnight position", "smart stow", "manual stow overwrite"]


export const errorCodes = {
    0: 'NO FAULT',
    1: 'Over Current Fault',
    2: 'Minimum Battery Voltage fault',
    4: 'Board Temperature Exceed fault',
    6: 'Motor Stall Fault',
    7: 'Zigbee Fault',
    8: 'Communication Fault',
    9: 'Inclinometer Fault',
    10: 'SPI Flash Fault',
    11: 'OTA Fault',
    12: 'EEPROM Fault',
    13: 'RTC Failure',
    14: 'Unknown Fault',
    15: 'Locking System Fault',
    16: 'Locked Moved Fault',
    17: 'Low Battery Stow Fault',
    18: 'Mechanical Overload Fault',
    33: 'ZC Blocked State',
    34: 'Set Command Flag Enabled',
    35: 'Mechanical Overload Occurred',
    19: 'Battery Charger Fault',
    20: 'Estop Fault',
    21: 'BLE Fault',
    22: 'External EEPROM Fault',
    23: 'Low temperature fault',
    36: 'Input Ac power not detected',
    37: 'Heating pad not detected',
    38: 'Energy Save State'
}

export const notNeededMetrics = ['Motor stall Fault – 06 ', 'Undefined_Fault_26', 'Undefined_Fault_21', 'Undefined Fault – 03', 'RTC Fault – 13', 'Undefined Fault – 05', 
    'Communication Fault – 08', 'Undefined_Fault_03', 'Undefined_Fault_17', 'Undefined_Fault_22', 'Undefined_Fault_28',
    , 'Undefined_Fault_25', 'OTA Fault – 11', 'Locking_system_Fault_15', 'Undefined_Fault_27',
    'Locked_track_move_Fault_16', 'EEPROM Fault – 12', 'Board temperature exceed Fault – 04', 'Zigbee Fault -07',
    'Undefined_Fault_32', 'Undefined_Fault_19', 'Set_Command_Flag_34', 'Over current Fault – 01',
    , 'Undefined_Fault_05', 'Undefined_Fault_20', 'Undefined_Ignore_This_00',
    'Undefined_Fault_23', 'Undefined_Fault_30', 'Undefined_Fault_29', 'Undefined_Fault_10', 'Undefined_Fault_31', 'Undefined_Fault_14',
    'Inclinometer Fault – 09', 'Undefined_Fault_24', 'Undefined_Fault_10', 'Undefined Fault – 10', 'Unknown Fault – 14', 'Undefined Ignore This - 00', 'Minimum battery voltage Fault – 02'
]

export const base_text =   ['#idle',   '#manual', '#wind',   '#snow',   '#clean', '#night',   '#emerg', '#comm',    '#auto',   '#cycle', '#unknow', '#hail', '#arom',     '#wlock', '#relst',    '#rst',  '#calib', '#lowbat']
export const base_colors = ['#a9a9a9', '#e6194B', '#f58231', '#42d4f4', '#469990', '#fff',    '#800000', '#4363d8', '#3cb44b', '#aaffc3', '#fabed4', '#808000', '#469990', '#f032e6', '#f032e6', '#911eb4', '#ddd8b1', '#dcbeff', '#463f3f', '#a51237', '#883d06', '#097c95', '#326c65', '#ffc7c7']


function getModeNamedColors() {
    var mnc = {}
    mode_names.forEach((x, i) => mnc[x] = base_colors[i]);
    return mnc
}
export const modeNamedColor = getModeNamedColors()

export const mode_colors = base_colors.map((c) => {
    //console.log(c)
    return new echarts.graphic.RadialGradient(0.20, 0.40, .5, [{
        offset: 0,
        color: tinycolor(c).darken(80).toString()
    }, {
        offset: 1,
        color: tinycolor(c).lighten(40).toString()
    }])
})
