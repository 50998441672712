import React, { useState, useEffect } from 'react'
import ContextFactory from './base.context'
import { ObjectLoader } from 'three'
import axios from 'axios'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { useCriteriaContext } from "../context/criteria.context";
import { IndexDB } from '../model/indexdb';
import { getSiteDevices, getSites } from '../model/metadata';
import { getAccessToken, ischangePasswordCountNull } from '../components/auth/userinfo';
import { logout } from '../components/auth/Login'
import { usePageContext } from './page.context';

const indexDB = new IndexDB()

const ModelContext = React.createContext()

export const ModelContextProvider = (props) => {

    const [selectedVersion, setSelectedVersion] = useState('')
    const [selectedZone, setSelectedZone] = useState('')
    const [selectedDevice, setSelectedDevice] = useState('')
    const [selectedMetric, setSelectedMetric] = useState(null)
    const [model, setModel] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [noModel, setNoModel] = useState(false)
    const [url, setUrl] = useState("")
    const [clear, setClear] = useState(false)
    const [devices, setDevices] = useState([])
    const [sites, setSites] = useState([])
    const [inIndexDb, setInIndexDb]   = useState(null)
    const [importingMetric, setImportingMetric] = useState(null)
    const [importedMetrics, setImportedMetrics] = useState([])
    const [realtimeMetrics, setRealtimeMetrics] = useState([])
    

    const criteriaContext = useCriteriaContext()
    const pageContext = usePageContext()
    const getUrl = (versionUrl) => {  
        const site = sites.filter(site => site.site_id === criteriaContext.site)
        if (site.length > 0 && site[0].model_url) {
          const _url = (site[0].model_url) + versionUrl
          return _url
        }

        return ""
    }
    
    let delay
    const getFromIndexDb = async (site, version) => {
      const id = site + version
      if (delay)
          clearTimeout(delay)
      if (window.INDEXDB_SETUP === "success") {
        const pvlayoutObj = await indexDB.getPvlayoutObjById(id)
        if (pvlayoutObj) {
          const obj = new ObjectLoader().parse(pvlayoutObj.obj);
          setModel(obj)
          setUrl("")
          setInIndexDb("yes")
        } else {
          setInIndexDb("no")
        }
      } else if (window.INDEXDB_SETUP === "error") {
        setInIndexDb("no")
      } else {
        delay = setTimeout(() => {
          getFromIndexDb(site, version)
        }, 200)
      }
    }

    const urlGet = (query) => {
      let token = getAccessToken()
      return axios({
          url: `custom_metrics?querytype=${query}&restype=json&project=depcom`,
          method: 'GET',
          headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'
          }
      })
    }

    useEffect(() => {
      urlGet('metrics')
        .then(res => {
          setImportedMetrics(res.data.filter(t => t.group_id === "CM").map(t => ({
              disabled: t.disabled,
              group_id:  t.group_id,
              metric: t.metric,
              tenant: t.tenant,
              minMaxValue: { minValue: t.lower_limit, maxValue: t.upper_limit }, 
              metricType: t.metric_type, 
              site: t.site_id,
              shared: t.shared,
              user: t.lastupdated_by
          })))

          setRealtimeMetrics(res.data.filter(t => t.group_id === "RS").map(t => ({
            disabled: t.disabled,
            group_id:  t.group_id,
            metric: t.metric,
            tenant: t.tenant,
            minMaxValue: { minValue: t.lower_limit, maxValue: t.upper_limit }, 
            metricType: t.metric_type, 
            site: t.site_id,
            shared: t.shared,
            user: t.lastupdated_by,
            note: t.note
          })))
        })
        .catch(err => {
          setError(err.message)
        })
    }, [])

    useEffect(() => {
      if (criteriaContext.site && inIndexDb === 'no') {
        if (sites.length > 0 && devices.filter(t => t.site_id === criteriaContext.site).length > 0) {
          const versionUrl = selectedVersion ? ("/" + selectedVersion) : ""
          const url = getUrl(versionUrl)
          setUrl(url)  
        } else {
          if (sites.length === 0)
            getSites(criteriaContext, (data) => {
              setSites(data)
            }, setError)
          if (devices.filter(t => t.site_id === criteriaContext.site).length === 0)
            getSiteDevices(criteriaContext, (data) => {
              setDevices(data)
            }, setError)
        }
      }
    }, [inIndexDb])

    useEffect(() => {
        if (criteriaContext.site) {
          setInIndexDb(null)
          // setClear(true)
          setLoading(true)
          setError("")
          getFromIndexDb(criteriaContext.site, selectedVersion)
          setSelectedZone(criteriaContext.zone || "")
          setSelectedDevice(criteriaContext.device || "")
        }
      },  [selectedVersion])

      useEffect(() => {
        if (criteriaContext.site) {
          setInIndexDb(null)
          setClear(true)
          setModel(null)
          setNoModel(false)
          setDevices([])
          getFromIndexDb(criteriaContext.site, "")
        } else {
          setInIndexDb(null)
          setLoading(false)
          setError("Please select a site")
          setUrl("")
          setClear(true) 
          setModel(null)
          setNoModel(true)
        }
      }, [criteriaContext.site, criteriaContext.pf])

      useEffect(() => {
        if (devices && !Array.isArray(devices)){
          setError(" Devices not present for the site")
          setLoading(false)
          return
      }
      
        if (criteriaContext.site) {
            if (sites.length > 0 && devices.filter(t => t.site_id === criteriaContext.site).length > 0) {
            const versionUrl = selectedVersion ? ("/" + selectedVersion) : ""
            const url = getUrl(versionUrl)
            if (url) {
              setUrl(url)
            } else {
              setLoading(false)
              setError("Site model not setup. Please contact admin")
              setNoModel(true)
              setUrl("")
            }
          } else {
            setLoading(true)
            setError("")
          }
        }
      },  [sites, devices])

      useEffect(() => {
        if (url) {
          importScene(url)
        }
  
      }, [url])

      useEffect(() => {
        const currentPage = pageContext.page ? pageContext.page : 'Loading...';
        if (ischangePasswordCountNull() && currentPage !== 'changePassword') {
          logout();
        }
      }, [pageContext.page, ischangePasswordCountNull])

    const importScene = async (url) => {
        const id = criteriaContext.site+selectedVersion
        const loader = new GLTFLoader()
        const userData = localStorage.getItem('user')
        if (userData) {
          const jsonUserData = JSON.parse(userData)
          loader.setRequestHeader({
              'x-access-token': getAccessToken()
          })
        }
        const modelUrl = `/model/blob?url=${url}`
        
        // eslint-disable-next-line no-unused-vars
        loader.load( modelUrl, ( gltf ) => {
          const pvLayout = gltf.scene.getObjectByName('pvLayout')
          pvLayout.children.forEach(mesh => {
            mesh.children.forEach(table => {
              const device = getDeviceId(table.name)
              table.userData.deviceId = device.deviceId
              table.userData.zoneId = device.zoneId
            })
          })
          const sceneObj = gltf.scene.toJSON()
          indexDB.addObj(id, sceneObj)
          setModel(gltf.scene)
        }, null, (err) => {
          setLoading(false)
          setError(err.response && err.response.statusText ? err.response.statusText : err.message)
        });      
    }

    const getDeviceId = tableName => {
        if (tableName.indexOf("A") >= 0 && tableName.indexOf("T") >= 0) {
          const shortTableName = tableName.replace("A0", "A").replace("T0", "T")
          const devicesWithTableName = devices.filter(t => t.tracker_id === shortTableName && t.site_id === criteriaContext.site)
          return devicesWithTableName.length > 0 ? {
            deviceId: devicesWithTableName[0].device_id,
            zoneId: devicesWithTableName[0].zone_id
           } : {deviceId: null, zoneId: null}
        } 
  
        return {deviceId: "not-table", zoneId: "not-table"}
      }

    return <ModelContext.Provider value={{
        selectedVersion, 
        setSelectedVersion, 
        selectedZone, 
        setSelectedZone, 
        selectedDevice, 
        setSelectedDevice,
        selectedMetric,
        setSelectedMetric,
        model,
        loading,
        error,
        clear,

        setClear,
        setLoading,

        importingMetric, 
        setImportingMetric,
        importedMetrics,
        setImportedMetrics,

        realtimeMetrics,
        setRealtimeMetrics,

        noModel
    }}>
        {props.children}
    </ModelContext.Provider>
}

export const useModelContext = ContextFactory("ModelContext", ModelContext)