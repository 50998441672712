import _ from "lodash";
import { axisStyle } from "./components/common";
import { getBarMajoritySeries, getPieMajoritySeries, linebarTemplate } from "./echarts.series";
import moment from 'moment';

const yAxisTemplate = {
    nameLocation: 'center',
    nameGap: 40,
    nameTextStyle: { color: '#8693AB', fontSize: 10, fontWeight: '400' },
    axisLine: { lineStyle: { color: "rgba(127,127,127,0.8" } },
    axisLabel: { color: '#8693AB', fontSize: 10, fontWeight: 'normal' },
    scale: true,
    splitLine: { lineStyle: { width: 1, color: "rgba(127,127,127,0.5" } },
}
const summaryGrid = {
    grid: [
        { id: 'time', left: '5%', width: '70%', bottom: "25%", top: "10%" },
        { id: 'majority', left: '70%', width: '30%', bottom: "25%", top: "10%", z: 20, },
        { id: 'top', left: '15%', width: '55%', height: '15%', top: "0%", containLabel: true, z: 10 },
    ],
}
export const defaultCompositeOption = {
    grid: [
        { id: 'time', left: '5%', width: '90%', bottom: "25%", top: "10%" },
        { id: 'majority', right: '5%', width: '5%', bottom: "25%", top: "10%", z: 20, },
        { id: 'top', left: '25%', left: '15%', width: '55%', height: '15%', top: "0%", containLabel: true, z: 10 },
    ],
    xAxis: [
        { ...axisStyle("Datetime", 20), type: "time", gridIndex: 0, show: true },
        { gridIndex: 1, show: false, type: 'value', inverse: true }, // bar summary
        { gridIndex: 2, show: false, type: 'value' },
        // { gridIndex: 1, show: false}, // pie summary
        // { gridIndex: 1, show: false, type: 'value', inverse: true }, // bar summary
        // { gridIndex: 2, show: false, type: 'value' },
        //axisStyle("", -40, 1)
    ],
    yAxis: [
        { ...yAxisTemplate, position: 'left', gridIndex: 0, show: false, name: "Errors " },
        { ...yAxisTemplate, position: 'right', gridIndex: 0, show: false, name: "Mode ", },
        { type: 'category', gridIndex: 2, show: false, data: [] },

    ],
    legend: { type: 'scroll', bottom: '5%', left: "5%", orient: 'horizontal', itemGap: 40,/*data: legenddata, selector: true,*/ textStyle: { color: "#4C4345", fontWeight: "400", fontFamily: "'Montserrat', sans-serif;", fontSize: "10" } },
    toolbox: {
        show: false,
        orient: "horizontal",
        left: "76%", bottom: "92%",
        iconStyle: { borderColor: 'rgba(228, 228, 228, 0.8)' },
        //emphasis: {iconStyle: {borderColor: 'rgba(28, 28, 228, 0.8)'}},
        feature: {
            dataView: { readOnly: false },
            //magicType: { type: ["line", "bar", 'stack'] },
            restore: {},
            saveAsImage: {}
        }
    },
    axisPointer: {
        link: { xAxisIndex: 'all' },
        label: {
            color: '#333',
            backgroundColor: '#eee'
        },
        snap: true
    },
    /*
        tooltip: {
            confine: true,
            axisPointer: { type: 'cross', snap: true },
            backgroundColor: 'rgba(0,0,0,0.6)',
            borderWidth: 0,
            extraCssText: 'width: 170px',
            textStyle: { fontSize: 11, color: "#fff" }
    
        },*/
    dataZoom: [
        { type: 'inside', start: 0, end: 100 },
        { type: 'slider', xAxisIndex: 0, show: true, top: '95%', left: '10%', right: '20%', width: '80%', height: '3%', start: 0, end: 100 },
        // { type: 'slider', yAxisIndex: 0, show: true, filterMode: 'empty', width: 12, height: '70%', handleSize: 8, showDataShadow: false,left: '93%' }

    ],
}
    ;


function getToolTipNonZero(iTimestamp,params) {
    return {
        tooltip: {
            trigger: "axis",
            confine: true,
            backgroundColor: '#B7D0DA',
            borderColor: '#B7D0DA',
            axisPointer: { type: 'cross', snap: true },
            extraCssText: 'max-height:400px; max-width: 200px; overflow:auto; white-space:pre-wrap;',
            textStyle: { fontSize: 14, color: "#014059", overflow: 'break' },
            enterable: true,
            formatter: (params) => {
                const seenDimensions = {};
                let tt = '';
                if (params.length === 0) return '';
                params.sort((a, b) => {
                    const aDim = a.seriesName;
                    const bDim = b.seriesName;
                    if (aDim < bDim) return -1;
                    if (aDim > bDim) return 1;
                    return 0;
                });
                const getSiteLocalTime = timestamp => {
                    const utcTime = moment.utc(timestamp)
                    const siteTime = moment(utcTime.format())
                    return siteTime.format("YYYY-MM-DD HH:mm:ss")
                }
                const timestamp = (params[0].data[iTimestamp]);
                // const timestamp = params[0].data[iTimestamp];
                tt += `<h5><b>${timestamp}</b></h5>`;
                if(params.some(p => 
                    p.dimensionNames.length === 3 &&
                    p.dimensionNames[0].includes("timestamp") &&
                    p.dimensionNames[1].includes("") &&
                    p.dimensionNames[2].includes("1.0(")
                )){
                    tt += params
                    .sort((a, b) => b.data[b.dimensionNames.indexOf(b.seriesName)] - a.data[a.dimensionNames.indexOf(a.seriesName)])
                    .sort((a, b) => {
                        const aDim = a.seriesName;
                        const bDim = b.seriesName;
                        if (aDim < bDim) return -1;
                        if (aDim > bDim) return 1;
                        return 0;
                    })
                    .map((p, i) => {
                        const seriesIndex = p.dimensionNames.indexOf(p.seriesName);
                        const val = p.data[seriesIndex];
                        const col = p.seriesName;
                        return val !== '' ? `<b>${val}</b>  -  ${col},<br/>` : '';
                    })
                    .join('');
                }
                else{
                params.forEach((p) => {
                    if (p.seriesName) {
                        const seriesIndex = p.dimensionNames.indexOf(p.seriesName);
                        const val = p.data[seriesIndex];
                        const seriesNameParts = p.seriesName.split('(');
                        if (seriesNameParts.length > 1) {
                            const dimName = seriesNameParts[0].trim(); // Extract dimension name without additional information
                            const propName = seriesNameParts[1].split(')')[0]; // Extract property name
                            if (val !== null) {
                                if (!seenDimensions[dimName]) {
                                    tt += `<b>${dimName}</b><br/>`;
                                    seenDimensions[dimName] = true;
                                }
                                tt += `${val}-${propName}<br/>`;

                            }
                        }
                    }
                });
                }
                return tt;
            },
        }
    };
}

export function makeCompositeOptions(alldata, args, criteria) {
    const combinedOptions = {
        //legend: { type: 'scroll', top: '5%', height: '90%', right: 0, orient: 'vertical', data: legenddata, selector: true, textStyle: { color: "#eee"} },
        dataset: [],
        series: [],
        yAxis: [
            { ...yAxisTemplate, position: 'left', gridIndex: 0, show: false, name: "Errors " },
            { ...yAxisTemplate, position: 'right', gridIndex: 0, show: false, name: "Mode ", },
            { type: 'category', gridIndex: 2, show: false, data: [] },
        ],
        xAxis: defaultCompositeOption.xAxis,
        showMajority: false,
        showPercent: false,
        majoritySeries: {},
        percentSeries: {},
    };
    const getSiteLocalTime = timestamp => {
        const utcTime = moment.utc(timestamp)
        const siteTime = moment(utcTime.format())
        return siteTime.format("YYYY-MM-DD HH:mm:ss")
    }
    const ChartTypes = { "line": "line", "bar": "bar" }
    args.options.forEach((co, coIndex) => {
        const data = alldata[co.sourceId]
        const iTimestamp = data.indexes[co.timeColumn]
        var majoritySeries = {}
        var percentSeries = {}
        const lengthOfIndexes = Object.keys(data.indexes).length;
        const indexes = data.indexes
        const targetValue = 1;
        let correspondingKeyName = null;

        for (const indexName in indexes) {
            if (indexes[indexName] === targetValue) {
                correspondingKeyName = indexName;
                break; // Break the loop once the corresponding key is found
            }
        }
        data.columns = data.columns.map(column => {
            if (column.startsWith("0.0")) {
              return '';
            }
            return column;
          });
        if ((lengthOfIndexes === 3 || lengthOfIndexes === 2) && correspondingKeyName && correspondingKeyName.startsWith('0.0(')) {
            const filteredValues = data.values.map(row => [getSiteLocalTime(row[0]), '', row[2]]);
            
            combinedOptions.iTimestamp = combinedOptions.iTimestamp ? combinedOptions.iTimestamp : iTimestamp;
            combinedOptions.dataset.push({
                source: filteredValues,
                dimensions: [data.columns[0], data.columns[1], data.columns[2]]
            });
        }
        else {            
            const convertedValues = data.values.map(row => {
                const timestamp = getSiteLocalTime(row[0]) // Convert date tomlocal timestamp
                return [timestamp, ...row.slice(1)]; // Replace the first element with the timestamp
            });
            combinedOptions.iTimestamp = combinedOptions.iTimestamp ? combinedOptions.iTimestamp : iTimestamp
            combinedOptions.dataset.push({
                source: convertedValues,
                dimensions: data.columns
            })
        }

        if (!combinedOptions.showMajority && co.showMajority) {
            combinedOptions.majoritySeries = getPieMajoritySeries(data, iTimestamp)
            combinedOptions.showMajority = true
        }
        if (!combinedOptions.showPercent && co.showPercent) {
            combinedOptions.percentSeries = getBarMajoritySeries(data, iTimestamp)
            combinedOptions.showPercent = true
        }
        combinedOptions.yAxis[coIndex].show = true
        combinedOptions.yAxis[coIndex].name = co.titleY
        combinedOptions.xAxis[coIndex].name = co.titleX

        const fill = co.fill && co.fill === true ? { opacity: 0.4 } : { opacity: 0 }
        const template = { ...linebarTemplate, type: ChartTypes[co.charttype], areaStyle: fill, stack: co.stacked, datasetIndex: coIndex, yAxisIndex: coIndex }
        const historicalSeries = data.columns.map((e, i) => {
            if (i !== iTimestamp) {
                const series = Object.assign({}, template, { name: e, encode: { x: iTimestamp, y: i, tooltip: [i] } });
                return series;
            }
        })
        // console.log(combinedOptions.series)
        // const series = _.concat(combinedOptions.series, historicalSeries, [majoritySeries], [percentSeries])
        const series = _.concat(combinedOptions.series, historicalSeries)
        // console.log(series)
        // combinedOptions.series = series.filter(f=> f && Object.keys(f).length > 0)
        combinedOptions.series = series

    })
    const series = _.concat(combinedOptions.series, [combinedOptions.majoritySeries], [combinedOptions.percentSeries])
    combinedOptions.series = series.filter(f => f && Object.keys(f).length > 0) 
    // combinedOptions.series.map(f => console.log(Object.keys(f)))
    // console.log(combinedOptions.series)

    const tooltip = getToolTipNonZero(combinedOptions.iTimestamp)
    const color = args.options.color ? { color: args.options.color } : {}
   
    const grid = combinedOptions.showMajority ? summaryGrid : {}
    const option = _.merge({}, defaultCompositeOption, combinedOptions, grid, color, tooltip);
    return option;
}


