import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const SingleSelectDropDownControl = ({ options, value, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    setSelectedOption(value); // Update selectedOption state when the value prop changes
  }, [value]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (onChange) {
      onChange(selectedOption);
    }
  };

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleChange}
      isMulti={false}
    />
  );
};

export default SingleSelectDropDownControl;
