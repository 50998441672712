import React, { useEffect, useState, useMemo } from "react";
import nextId from "react-id-generator";
import {
  useCommonActionContext,
  useCommonContext,
} from "../../context/common.context";
import { useCriteriaContext } from "../../context/criteria.context";
import {
  detailsPage,
  sessionPage,
  mainPage,
  modelPage,
  usePageContext,
  maintenancePage,
  settingsPage,
  diffusePage,
  energyMeterPage,
  nonSunopsPage,
  addonfeaturePage,
  changePasswordPage,
  dataImportPage
} from "../../context/page.context";
import { useStoreActionContext } from "../../context/store.context";
import CurrentAnglesChart from "../../echarts/angles";
// import PerfChart, { getPerformanceComps } from '../../echarts/components/perfchart';
import { getPerformanceComps } from "../../echarts/components/perfchart";
import UptimeSummary from "../../echarts/components/uptime.summary";
import { getUptimeImpactComps } from "../../echarts/components/uptime_impact";
import {
  getComp,
  getComp2,
  UDChartItem,
  UDChartRow,
} from "../../echarts/echarts.render";
import ErrorSummaryChart from "../../echarts/errorhistory";
import DataviewClient from "../../layout/dataview";
import { modeNamedColor } from "../../metadata/modes.meta";
import withData from "../../model/combined";
import { useLocation } from "../../navigation/use-location";
import PortfolioModeMajority from "../chart/pf.modemajor";
import {
  PortfolioErrorSummaryLines,
  SiteErrorSummaryLines,
  ZoneErrorSummaryLines,
} from "../chart/rcerrorsummary";
import {
  PortfolioModeSummaryLines,
  SiteModeSummaryLines,
  ZoneModeSummaryLines,
} from "../chart/rcmodesummary";
import SitesModeMajority from "../chart/sites.modemajor";
import ZonesCurrentAngleLines from "../chart/zone.currentangle";
import ZonesFWSWTable from "../chart/zone.fwsw";
import ZonesModeMajority from "../chart/zones.modemajor";
import "../custombutton/custombtn.css";
import criteria, {
  getCriteriaFromLocation,
  getValueTypeGroup,
  isDevice,
  isPortfolio,
  isSite,
  isZone,
  isInverter
} from "../layout/criteria";
import "../layout/layout.css";
import { getChartCompValue2 } from "../layout/usercharts";
import PortfolioOverview from "../stats/pf.overview";
// import { default as SiteOverview, default as ZoneOverview } from '../stats/zone.overview';
import SiteOverview from "../stats/site.overview";
import ZoneOverview from "../stats/zone.overview";
import DeviceOverview from "../stats/device.overview";

import _ from "lodash";
import { useInView } from "react-intersection-observer";
import { useUserContext } from "../../context/user.context";
import Downloads from "../downloads/index";
import { CardFull as Card } from "../layout/Card";
import ModelViewer from "../modelviewer";
import VersionSelection from "../modelviewer/VersionSelection";
import SiteMaintenance from "../sitemaintenance"
import RealTimeUI from "../realtime";
import ColumnSelection from "../realtime/ColumnSelection";
import FiltersApplied from "../realtime/FiltersApplied";
import RealtimeSearch from "../realtime/Search";
import RealtimeDownload from "../realtime/Download";
import PortfolioSummaryTable from "../table/pf.summary";
import SiteSummaryTable from "../table/site.summary";
import ZoneSummaryTable from "../table/zone.summary";
import RcSummaryTable from "../table/rc.summary";
import { TooltipHelpContent } from "./tooltip-help-content";
import CustomOverlay from "../modelviewer/CustomOverlay"
import { getLevel, getLogData } from "../../helpers/common";
import RealtimeOverlay from '../realtime/RealtimeOverlay'
import RealtimeSnapshots from '../modelviewer/RealtimeSnapshots'
import { createActivityLog } from "../../utils/apiCall";
import TableComponent from "../useranalytics/tablecomponent";
import HailSettingsTabs from "../hailstow/Tab";
import DiffuseIndex from "../diffuse/diffuseIndex";
import EnergyProduction from '../../echarts/components/energy_production'
import DeviceIssueSummary from "../recengine/deviceIssueSummary";
import ZoneIssueSummary from "../recengine/zoneIssueSummary";
import SiteIssueSummary from "../recengine/siteIssueSummary";
import { useAvailabilityContext } from "../../context/availability.context";
import PfIssueSummary from "../recengine/pfIssueSummary";
import NonSunops from "./nonSunops";
import ChangePassword1 from "./changePassword";
import { ImportData } from "./import-data";

// function isPortfolio(c) { console.log(c); return c.site === "" }
// function isSite(c) { return !isPortfolio(c) && c.zone === "" }
// function isZone(c) { return !isSite(c) && c.device === "" }
// function isDevice(c) { return !isZone(c) }
window.processedCharts = {}

const Overview = () => {
  
  const [isEnergyConfigured, setIsEnergyConfigured] = useState(true);
  const userContext = useUserContext();
  const pageContext = usePageContext();
  //const c = useStore().criteria
  const c = useCriteriaContext();
  const priorityChart = useCommonContext().priorityChart;

  useEffect(() => {
    if(isSite(c) && userContext.inverters.length===0 && pageContext.page===energyMeterPage)
    setIsEnergyConfigured(false);
  else 
    setIsEnergyConfigured(true);
  }, [userContext.inverters]);

  // console.log("Overview  Entered ************** ", toJS(c))

  return isPortfolio(c) ? (
    <PortfolioOverview priorityChart={priorityChart} />
  ) : isSite(c) ? (
    isEnergyConfigured ? <SiteOverview priorityChart={priorityChart} /> :""
  ) : (isZone(c) || isInverter(c)) ? (
    <ZoneOverview priorityChart={priorityChart} />
  ) : (
    <DeviceOverview priorityChart={priorityChart} />
  );
};

const Summary = () => {
  //const c = useStore().criteria
  const c = useCriteriaContext();
  // console.log("Summary Entered ", toJS(c))

  return isPortfolio(c) ? (
    <PortfolioSummaryTable />
  ) : isSite(c) ? (
    <SiteSummaryTable site_id={c.site} />
  ) : isZone(c) ? (
    <ZoneSummaryTable site_id={c.site} zone_id={c.zone} />
  ) : (
    <RcSummaryTable site_id={c.site} zone_id={c.zone} device_id={c.device} />
  );
};
/*
const MetricsViewer = () => {
  return (
    <div className="col-2 d-none d-lg-block">
      <MetricsDownloaderViewer />
      <MetricsList />
    </div>

  )
}
*/
const ModeMajority = () => {
  //const c = useStore().criteria
  const c = useCriteriaContext();
  // console.log("ModeMajority Entered ")

  return isPortfolio(c) ? (
    <PortfolioModeMajority />
  ) : isSite(c) ? (
    <SitesModeMajority site_id={c.site} />
  ) : isZone(c) ? (
    <ZonesModeMajority site_id={c.site} zone_id={c.zone} />
  ) : (
    ""
  );
};

const ModeSummaryLines = () => {
  //const c = useStore().criteria
  const c = useCriteriaContext();
  // console.log("Summary Entered ")

  return isPortfolio(c) ? (
    <PortfolioModeSummaryLines />
  ) : isSite(c) ? (
    <SiteModeSummaryLines site_id={c.site} />
  ) : isZone(c) ? (
    <ZoneModeSummaryLines site_id={c.site} zone_id={c.zone} />
  ) : (
    ""
  );
};


const PerformanceById = React.memo(
  (props) => {
    const { ref, inView, entry } = useInView({
      threshold: 0,
      triggerOnce: true,
    });
    const [isInView, setIsInView] = useState(false);
    const c = useCriteriaContext();
    const uc = useUserContext();
    const availabilityContext = useAvailabilityContext()

    const comps = getPerformanceComps({ pf: c.pf, site: c.site, device: c.device, zone: c.zone },uc);
    
    useEffect(() => {
      //console.log("PerformanceById render")
      setIsInView(inView);
    }, [inView]);

    const PerformanceChart = useMemo(() => withData({ ...comps }), [isInView,c]);    
    // const PerformanceChart = withData({ ...comps })
    return <div ref={ref}>{isInView && <PerformanceChart />}</div>;
  },
  (prevProps, nextProps) => _.isEqual(1, 1)
);

const UptimeImpactSummary = React.memo(
  (props) => {
    const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
    const [isInView, setIsInView] = useState(false);
    const c = useCriteriaContext();
    const uc = useUserContext();
    const availabilityContext = useAvailabilityContext()

    const comps = getUptimeImpactComps(c,uc);
    // console.log(comps)
    useEffect(() => {
      setIsInView(inView);
    }, [inView]);


    const ImpactChart = useMemo(() => withData({ ...comps }), [isInView,c,availabilityContext.availability]);

    return <div ref={ref}>{isInView && <ImpactChart />}</div>;
  },
  (prevProps, nextProps) => _.isEqual(1, 1)
);

const OverviewSummaries = React.memo(
  (props) => {
    const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
    const [isInView, setIsInView] = useState(false);
    //const c = useStore().criteria
    const c = useCriteriaContext();

    useEffect(() => {
      setIsInView(inView);
    }, [inView]);
    // const interval = c.interval <= 6 * 60 ? c.interval : 6 * 60
    // console.log(modeNamedColor)
    var charts = [
      {
        id: "errors",
        title: "Errors Summary",
        tooltip: TooltipHelpContent.ErrorSummary,
        source: "readings1byidcount_errorCode",
        charttype: "line",
        interval: 60,
        metricGroup: "SR",
        showTimezone: true,
        grouptype: "rcs",
        chartOptions: {
          timeColumn: "timestamp",
          showMajority: false,
          stacked: true,
          fill: true,
          titleX: "",
          titleY: isDevice(c) ? "Error Count" : "Device Count",
        },
      },
      {
        id: "modes",
        title: "Modes Summary",
        tooltip: TooltipHelpContent.ModeSummary,
        source: "readings1byidcount_currentMode",
        charttype: "bar",
        interval: 60,
        metricGroup: "SR",
        showTimezone: true,
        grouptype: "rcs",
        chartOptions: {
          timeColumn: "timestamp",
          showMajority: false,
          stacked: true,
          fill: true,
          titleX: "",
          titleY: isDevice(c) ? "Mode Count" : "Device Count",
          colors: modeNamedColor,
        },
      },
      // { title: 'Modes Summary ', source: "readings1byvalue_currentMode", charttype: 'pie', interval: 6*60*1, chartOptions: { timeColumn: 'timestamp', showMajority: true, stacked: true, titleX: '', titleY: 'Device Count' }, layout: { row: 1, column:  layouts["sm"]} },
      // { title: 'Current Angle Summary ', source: "readings1byid0_currentAngle", charttype: 'line', interval: 60*1, chartOptions: {timeColumn: 'timestamp',  showMajority: false, stacked: false, titleX: '', titleY: 'Angles' }}
    ];

  

    return <div ref={ref}>{isInView && <UDChartPage charts={charts} />}</div>;
  },
  (prevProps, nextProps) => _.isEqual(1, 1)
);

const OverviewSensorSummaries = () => {
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
  const [isInView, setIsInView] = useState(false);
  const c = useCriteriaContext();
  useEffect(() => {
    setIsInView(inView);
  }, [inView]);

  const charts = [
    {
      title: "Wind Speed Summary (m/s)",
      tooltip: TooltipHelpContent.WindSpeedSummary,
      source: "readings1byid0_WIND_SPEED",
      charttype: "line",
      interval: c.interval,
      metricGroup: "VZ",
      grouptype: "zones",
      showTimezone: true,
      hideEmpty: true,
      chartOptions: {
        timeColumn: "timestamp",
        stacked: false,
        fill: true,
        titleX: "",
        titleY: "",
      },
    },
    {
      title: "Wind Direction Summary (degrees)",
      tooltip: TooltipHelpContent.WindDirectionSummary,
      source: "readings1byid0_WIND_DIRECTION",
      charttype: "line",
      interval: c.interval,
      metricGroup: "VZ",
      grouptype: "zones",
      showTimezone: true,
      hideEmpty: true,
      chartOptions: {
        timeColumn: "timestamp",
        stacked: false,
        fill: true,
        titleX: "",
        titleY: "",
      },
    },
    {
      title: "Flood Level Summary (mm)",
      tooltip: TooltipHelpContent.FloodLevelSummary,
      source: "readings1byid0_FLOOD_LEVEL",
      charttype: "line",
      interval: c.interval,
      metricGroup: "VZ",
      grouptype: "zones",
      showTimezone: true,
      hideEmpty: true,
      chartOptions: {
        timeColumn: "timestamp",
        stacked: false,
        fill: true,
        titleX: "",
        titleY: "",
      },
    },
    {
      title: "Snow Fall Summary (mm)",
      tooltip: TooltipHelpContent.SnowLevelSummary,
      source: "readings1byid0_SNOW_FALL",
      charttype: "line",
      interval: c.interval,
      metricGroup: "VZ",
      grouptype: "zones",
      showTimezone: true,
      hideEmpty: true,
      chartOptions: {
        timeColumn: "timestamp",
        stacked: false,
        fill: true,
        titleX: "",
        titleY: "",
      },
    },
  ];
  if (isSite(c) || isZone(c)) {
    return (
      <div ref={ref}>
        {isInView && (
          <UDChartRow>
            {charts.map((e) => (
              <div
                className="col-xl-6 col-lg-12 col-md-12 col-sm-12"
                key={nextId()}
              >
                <UDChartItem data={getComp(e)} comp={e} key={nextId()} />
              </div>
            ))}
          </UDChartRow>
        )}
      </div>
    );
  }
  return "";
};

const OverviewAngleSummaries = () => {
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
  const [isInView, setIsInView] = useState(false);
  const c = useCriteriaContext();
  useEffect(() => {
    setIsInView(inView);
  }, [inView]);
  const charts = [
    {
      title: "Current Angle Summary",
      tooltip: TooltipHelpContent.AngleSummary,
      source: "readings1byid0_currentAngle",
      charttype: "line",
      interval: 10,
      metricGroup: "SR",
      grouptype: "rcs",
      chartOptions: {
        timeColumn: "timestamp",
        stacked: false,
        titleX: "",
        titleY: "degrees",
      },
    },
    // { title: 'Current Angle Summary ', tooltip: TooltipHelpContent.AngleSummary, source: "readings1byidnull_currentAngle", charttype: 'line', interval: 10, metricGroup: "SR", showTimezone: true, grouptype: "rcs", chartOptions: { timeColumn: 'timestamp', stacked: false, titleX: '', titleY: 'degrees', joinnull: true } }
  ];

  if (isZone(c) || isDevice(c)) {
    return <div ref={ref}>{isInView && <UDChartPage charts={charts} />}</div>;
  }
  return "";
};

const IssueSummaryTable = () => {
  const c =  useCriteriaContext();
  if (isSite(c)) {
    return (
      <>
        <SiteIssueSummary />
      </>
    )
  }
  if (isZone(c)) {
    return (
        <>
            <ZoneIssueSummary />
        </>
    );
  }
  if (isDevice(c)) {
    return (
        <>
            <DeviceIssueSummary/>
        </>
    );
  }
  return (
    <>
      <PfIssueSummary/>
    </>
  );
}

const OverviewAngleAnamolies = () => {
  const c = useCriteriaContext();

  const groupType = isDevice(c)
    ? "rcs"
    : isZone(c)
    ? "rcs"
    : isSite(c)
    ? "zones"
    : undefined;
  if (groupType) {
    const charts = [
      {
        title: "Anomalous Angles Summary ",
        tooltip: TooltipHelpContent.AnomalousAngleSummary,
        source: "anomalies1byid0_currentAngle",
        charttype: "bar",
        metricGroup: "SR",
        interval: 10,
        grouptype: groupType,
        chartOptions: {
          typeX: "category",
          timeColumn: "id",
          stacked: false,
          titleX: "",
          titleY: "trackers",
        },
      },
    ];
    return <UDChartPage charts={charts} />;
  }
  return "";
};

export const OverviewActivePowerSummaries = () => {
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });
  const [isInView, setIsInView] = useState(false);
  const pageContext = usePageContext();
  const c = useCriteriaContext();
  useEffect(() => {
    setIsInView(inView);
  }, [inView]);
  const charts = [
    {
      title: "Active Power Summary",
      tooltip: TooltipHelpContent.ActivePowerSummary,
      source: "readings1byid0_Active Power",
      charttype: "line",
      metricGroup: "VI",
      interval: 10,
      grouptype: "zones",
      showTimezone: true,
      hideEmpty: true,
      chartOptions: {
        timeColumn: "timestamp",
        stacked: false,
        fill: false,
        titleX: "",
        titleY: "kilo watt",
      },
    },
  ];

  if (isZone(c) || isSite(c) || isInverter(c)) {
    return <div ref={ref}>{(isInView || pageContext.page === energyMeterPage) && <UDChartPage charts={charts} />}</div>;
  }
  return "";
};
const ErrorSummaryLines = () => {
  //const c = useStore().criteria
  const c = useCriteriaContext();
  // console.log("Error Sumary Entered ")

  return isPortfolio(c) ? (
    <PortfolioErrorSummaryLines />
  ) : isSite(c) ? (
    <SiteErrorSummaryLines site_id={c.site} />
  ) : isZone(c) ? (
    <ZoneErrorSummaryLines site_id={c.site} zone_id={c.zone} />
  ) : (
    ""
  );
};

const FWSWSummaryLines = () => {
  //const c = useStore().criteria
  const c = useCriteriaContext();
  // console.log("FWSW  Entered ", c)

  if (c.site === undefined) {
    // console.log("site empty")
  }
  return isZone(c) ? <ZonesFWSWTable site_id={c.site} zone_id={c.zone} /> : "";
};

const AllErrorSummaryChart = () => {
  //const c = useStore().criteria
  const c = useCriteriaContext();
  // console.log("Error summary chart Entered ")

  return isPortfolio(c) || isSite(c) || isZone(c) ? <ErrorSummaryChart /> : "";
};

const AllCurrentAnglesChart = () => {
  //const c = useStore().criteria
  const c = useCriteriaContext();

  // console.log("Angles Entered ")

  return isZone(c) || isDevice(c) ? (
    <>
      {" "}
      <ZonesCurrentAngleLines /> <CurrentAnglesChart />{" "}
    </>
  ) : (
    ""
  );
};

const sampleCharts = [
  {
    title: "Errors Summary ",
    source: "readings1byvalue0_errorCode",
    charttype: "line",
    interval: 30,
    grouptype: "rcs",
    chartOptions: {
      timeColumn: "timestamp",
      showMajority: true,
      showPercent: true,
      stacked: true,
      fill: true,
      titleX: "",
      titleY: "Device Count",
    },
  },
  {
    title: "Modes Summary ",
    source: "readings1byvalue_currentMode",
    charttype: "bar",
    interval: 30,
    grouptype: "rcs",
    chartOptions: {
      timeColumn: "timestamp",
      showMajority: true,
      showPercent: true,
      stacked: true,
      titleX: "",
      titleY: "Device Count",
    },
  },
  // { title: 'Current Angle Summary ', source: "readings1byid0_currentAngle", charttype: 'line', interval: 10, grouptype: "rcs", chartOptions: { timeColumn: 'timestamp', stacked: false, titleX: '', titleY: 'degrees' } },

  // { title: 'Modes Summary ', source: "readings1byvalue_currentMode", charttype: 'pie', interval: 6*60*1, chartOptions: { timeColumn: 'timestamp', showMajority: true, showPercent: true,  stacked: true, titleX: '', titleY: 'Device Count' }, layout: { row: 1, column:  layouts["sm"]} },
  // { title: 'Current Angle Summary ', source: "readings1byid0_currentAngle", charttype: 'line', interval: 60*1, chartOptions: {timeColumn: 'timestamp',  stacked: false, titleX: '', titleY: 'Angles' }}
];

const sampleCharts2 = [
  {
    title: "Composity Summary ",
    charttype: "composite",
    sources: [
      {
        id: "data-0",
        source: "readings1byidcount_errorCode",
        grouptype: "rcs",
        interval: 30,
      },
      {
        id: "data-1",
        source: "readings1byidcount_currentMode",
        grouptype: "rcs",
        interval: 30,
      },
    ],
    chartOptions: [
      {
        sourceId: "data-0",
        charttype: "line",
        timeColumn: "timestamp",
        showMajority: false,
        showPercent: false,
        stacked: true,
        fill: true,
        titleX: "",
        titleY: "Device Count",
      },
      {
        sourceId: "data-1",
        charttype: "bar",
        timeColumn: "timestamp",
        showMajority: false,
        showPercent: false,
        stacked: true,
        fill: false,
        titleX: "",
        titleY: "Device Count",
      },
    ],
  },
];

function getSampleCharts(criteria) {
  const c1 = {
    title: "Chart 123",
    page: "Page 1",
    charts: [
      {
        chartType: "bar",
        titleY: "sa",
        metric: "currentMode",
        stacked: true,
        summary: true,
        filled: false,
        valueType: "readings1byidcount",
        id: "chart-0",
        groupType: "rcs",
        interval: "30",
      },
      {
        filled: false,
        summary: false,
        stacked: false,
        titleY: "sadf",
        valueType: "readings1byid0",
        metric: "currentAngle",
        chartType: "line",
        id: "chart-1",
        groupType: "rcs",
        interval: "30",
      },
    ],
    metrics: [
      {
        label: "currentMode",
        value: {
          group_id: "SR",
          metric: "currentMode",
        },
      },
      {
        value: {
          metric: "currentAngle",
          group_id: "SR",
        },
        label: "currentAngle",
      },
    ],

    XAxisTitle: "timestamp",
    lastupdated: 1641887106965,
    hideEmpty: true,
  };

  var c2 = {
    title: c1.title,
    charttype: "composite",
  };
  const c2defs = c1.charts
    .map((c, i) => {
      const m = c1.metrics.find((f) => f.value.metric === c.metric);
      if (!m) {
        return undefined; //{v: "metric not found "}
      }
      const vtg = getValueTypeGroup(c.valueType, m.value.group_id); // .find((f)=> f.valueType === c.valueType && f.metricType === m.value.group_id.charAt(1))
      if (!vtg) {
        return undefined; // {v: "VTG not found  "}
      }

      if (
        (vtg.valid.pf && isPortfolio(criteria)) ||
        (vtg.valid.site && isSite(criteria)) ||
        (vtg.valid.zone && isZone(criteria)) ||
        (vtg.valid.device && isDevice(criteria))
      ) {
        // { id: "data-0", source: "readings1byidcount_errorCode", grouptype: 'rcs', interval: 30 },
        // { sourceId: "data-0", charttype: 'line', timeColumn: 'timestamp', showMajority: false, showPercent: false, stacked: true, fill: true, titleX: '', titleY: 'Device Count' },

        return {
          source: {
            id: `data-${i}`,
            source: `${c.valueType}_${c.metric}`,
            grouptype: c.groupType,
            interval: parseInt(c.interval),
          },
          options: {
            sourceId: `data-${i}`,
            charttype: c.chartType,
            timeColumn: "timestamp",
            showMajority: c.summary,
            showPercent: c.summary,
            stacked: c.stacked,
            fill: c.filled,
            titleX: c1.XAxisTitle,
            titleY: c.titleY,
          },
        };
      }
    })
    .filter((f) => f !== undefined);
  // console.log(c2defs)
  c2["sources"] = c2defs.map((c, i) => c.source);
  c2["chartOptions"] = c2defs.map((c, i) => c.options);
  // console.log(c2)
  return [c2];
}

const UDChartPage = (props) => {
  const layouts = { lg: "col-12", md: "col-8", sm: "col-4" };
  const chart = (props) => (
    <UDChartRow key={nextId()}>
      <div className="col-12 pl-0">
        <UDChartItem data={getComp(props)} comp={props} />
      </div>
    </UDChartRow>
  );
  return (
    <>
      {props.charts.map((e) => {
        return e.id ? <ScrollView id={e.id}>{chart(e)}</ScrollView> : chart(e);
      })}
    </>
  );
};
const UDChartPage2 = React.memo((props) => {
  const c = useCriteriaContext();
  const pageContext = usePageContext();
  const layouts = { lg: "col-12", md: "col-8", sm: "col-4" };
  // console.log(props.charts)
  // const chartCompList = getChartCompValue2(e, c)
  // console.log(chartCompList)
  var cloneObj = [];
  cloneObj =
    props.charts["items"] !== undefined ? props.charts.items : props.charts;
  // if(props.charts && props.charts.length > 0 && props.charts[0].charts.length > 1) {
  //     props.charts.forEach((mainElement,index)=>{
  //       mainElement.charts.forEach((chartEle,chartIndex)=>{
  //         var newChart = {};
  //         Object.keys(mainElement).forEach(key => {
  //           if(key == "charts")
  //           newChart[key] = [chartEle];
  //           else if(key == "metrics")
  //           newChart[key] = [mainElement[key][chartIndex]];
  //           else
  //           newChart[key] = mainElement[key];
  //         });
  //         cloneObj.push(newChart);
  //       })
  //     })
  //     console.log("clone object is",cloneObj);
  // } else {
  //   cloneObj = props.charts["items"] !== undefined ? props.charts.items: props.charts;
  // }

  return (
    <>
      <h5
        style={{
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "18px",
          color: "#4C4345",
          padding: "10px 0px 10px 6px",
        }}
      >
        {pageContext.page}
      </h5>
      {cloneObj.map((e) => {
        return (
          <UDChartRow key={nextId()}>
            <div className="col-12 ">
              <UDChartItem
                data={getComp2(getChartCompValue2(e, c), c)}
                comp={e}
              />
            </div>
          </UDChartRow>
        );
      })}
    </>
  );
});

const HomeViewBase3 = () => {
  const criteria = useCriteriaContext();
  // console.log("Started Home page ")
  // console.log(charts)
  return (
    <div id="content" className="content" style={{ marginLeft: 0 + "em" }}>
      <div className="row">
        <div className="col-10">
          {/* <div className="row">
            <Summary />
            <Overview />
          </div> */}

          <div className="row">
            <DataviewClient />
            {/* <RBTable /> */}
          </div>
          <div className="row">
            <div className="col-12">{/* <Crumbs /> */}</div>
          </div>
          <UDChartPage2 charts={getSampleCharts(criteria)} />
          {/* <OverviewSensorSummaries /> */}
        </div>
        <div className="col-2 d-none d-lg-block">{/* <MetricsList /> */}</div>
      </div>
    </div>
  );
};

const ScrollView = (props) => (
  <div style={{ position: "relative" }}>
    <div style={{ position: "absolute", top: -80 }} id={props.id}></div>
    {props.children}
  </div>
);



const OverviewInsights = (props) => {
  const priorityChart = useCommonContext().priorityChart;

  // Create a reference to the element that we want to observe
  // const targetElement = useRef(null);
  // const [isInView, setIsInView] = useState(false);
  //
  // // Set up the intersection observer when the component mounts
  // useEffect(() => {
  //   const observer = new IntersectionObserver(onIntersection, {});
  //   observer.observe(targetElement.current);
  //
  //   // Clean up the observer when the component unmounts
  //   return () => observer.unobserve(targetElement.current);
  // }, []);

  // This is the callback function that is executed whenever the
  // target element intersects with the viewport
  //function onIntersection(entries) {
  //  // Do something with the intersection data, such as triggering
  //  // an animation or lazy loading content
  //  setIsInView(true);
  //}

  return (
      <>
          <div className="row">
            <div className="col-12 pl-0">
              <IssueSummaryTable />{" "}
            </div>

          </div>
          <div className="row">
              <div className="col-12 pl-0">
                  <ScrollView id="performance">
                      {priorityChart.length === 0 ? (
                          <UptimeSummary />
                      ) : (
                          <Card>
                              <div
                                  style={{
                                      textAlign: "center",
                                      flex: 1,
                                      width: "100%",
                                      justifyContent: "center",
                                      padding: 20,
                                      margin: 20,
                                      alignItems: "center",
                                  }}
                              >
                                  {" "}
                                  Loading Chart Data...{" "}
                              </div>
                          </Card>
                      )}
                  </ScrollView>
              </div>
          </div>
          <div className="row">
              <div className="col-12 col-xl-6 pl-0 rc-performance-chart">
                  <ScrollView id="availability">
                      <PerformanceById />
                  </ScrollView>
              </div>
              <div className="col-12 col-xl-6 pl-0">
                  <ScrollView id="availability_impact">
                      <UptimeImpactSummary />
                  </ScrollView>
              </div>
              <div className="col-12 pl-0">
                  <ScrollView id="overviewsummaries">
                      <OverviewSummaries />
                  </ScrollView>
              </div>
              <div className="col-12 pl-0">
                  <ScrollView id="sensors">
                      <OverviewSensorSummaries />{" "}
                  </ScrollView>
              </div>
              <div className="col-12 pl-0">
                  <ScrollView id="angles">
                      <OverviewAngleSummaries />{" "}
                  </ScrollView>
              </div>
              <div className="col-12 pl-0">
                  <ScrollView id="power">
                      <OverviewActivePowerSummaries />{" "}
                  </ScrollView>
              </div>
          </div>
      </>
  );
};

const HomeViewBase1 = () => {
  const pageContext = usePageContext();
  const commonAction = useCommonActionContext();
  const priorityChart = useCommonContext().priorityChart;

  useEffect(() => {
    if (priorityChart.length > 0) {
      var interval = setTimeout(() => {
        commonAction.setPriorityChart("availability_chart", false);
      }, 2000);
    }
    return () => clearTimeout(interval);
  }, [priorityChart]);

  if(pageContext.page !== mainPage){window.processedCharts = {}}


  return (
    <div style={{ marginLeft: 0 + "em" }}>    
      <div className="row">
        <div className="col-12">
          {pageContext.page === modelPage ? (
            <React.Fragment>
              <VersionSelection />

              <CustomOverlay />
              
              <RealtimeSnapshots />
            </React.Fragment>
          ) : pageContext.page === detailsPage ? (
            <React.Fragment>
              <ColumnSelection />
              <RealtimeSearch />
              <RealtimeDownload />
              <RealtimeOverlay />
              <FiltersApplied />
              {/* <Filters  attrs={filterRowAttrs} select={selectFilter} closeFilters={closeFilters} toggleFilter={toggleFilter}/> */}
            </React.Fragment>
          ) : null}
        </div>
      </div>
      {pageContext.page === settingsPage ? (
        <HailSettingsTabs/>
      ):pageContext.page === diffusePage ? (
        <DiffuseIndex/>
      ):pageContext.page === addonfeaturePage ? (
        <NonSunops/>
      ): pageContext.page ===changePasswordPage ? (
        <ChangePassword1/>
      ):
      pageContext.page === sessionPage ? (
        <TableComponent/>
      ) : pageContext.page === modelPage ? (
        <ModelViewer />
      ) : pageContext.page === detailsPage ? (
        <RealTimeUI />
      ) : pageContext.page === "Downloads" ? (
        <Downloads />
      ) : pageContext.page === maintenancePage ? (
        <SiteMaintenance />
      ) : pageContext.page === dataImportPage ? (
        <ImportData/>
      ): (
        <div className="row">
          <div className="col-lg-12 col-xl-12 col-12">
            <div className="row summary-wrapper">
              {priorityChart.length === 0 && pageContext.page !== energyMeterPage && <Summary />}
              <Overview />
            </div>

            {/* <div className="row zc-table">
                        <DataviewClient />
                    </div> */}
             {
              pageContext.page === energyMeterPage 
              ?
              <>
                <EnergyProduction/>
                <OverviewActivePowerSummaries />
              </>
              :
              null 
            }
            {pageContext.page === mainPage ? (
              <OverviewInsights />
            ) : (
              pageContext.page !== energyMeterPage && <UDChartPage2 charts={pageContext.charts} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(HomeViewBase1);
