import React, { useEffect, useState } from "react";
import nextId from "react-id-generator";
import styled from "styled-components";
import CsvViewer from "../components/table/csvviewer";
import { useDataviewContext } from "../context/dataview.context";
import {FiDatabase} from 'react-icons/fi'
import { createActivityLog } from "../utils/apiCall";
import { getLevel, getLogData } from "../helpers/common";
import { useCriteriaContext } from "../context/criteria.context";


const Styles = styled.div`
    max-width: calc( 100% - 10px );
    min-width: 30%;
    width: 100%;
`
const SingleView = (props) => {
    const [data] = useState(props.data)
    return (
        // <>{data.head} {data.type} {data.title} {data.url}  </>
        <CsvViewer view={data} />
    )
}

const NoData = (props) => {
    return (
        <div className="no-data-comp">
            <FiDatabase size="40px" color="#004059"/>
            <h4>{props.msg}</h4>
        </div>
    )

}

const inPortfolio= (level,data)=>{
    if(level === "pf" && (data[0].head === "ZC Alerts" || data[0].head ==="ZC Data")){
        return true
    }
    else if(level !== "pf")
    {
        return true
    }
    else{
        return false
    }     
}

const DataviewClient = () => {
    const [data, setData] = useState()
    const dataview = useDataviewContext()
    const criteria = useCriteriaContext()
    //  {head: "Plant Data", type: "time", title: "Metric 1", url: "ssss" },
    
    useEffect(() => {
        if(dataview.views.length){
            let view = dataview.views[0]
            const level = getLevel(criteria)
            createActivityLog(getLogData(criteria, {featureId: `view-${level}-metrics`.toLowerCase(), metrics: view.title, usecase: `view metrics data`, category: 'downloads', level: level, details: '{}'}))
        }
    },[dataview, criteria.pf, criteria.site, criteria.zone, criteria.device, criteria.Te, criteria.Ts])
    
    useEffect(() => {
        
        setData(dataview.views)

    }, [dataview])

    return (
        <Styles>
            {(data && data.length>0) && inPortfolio(getLevel(criteria),data) ?   data.map(e => <SingleView key={nextId()} data={e} />) : <NoData msg="Choose a metric to view data"/>}
        </Styles> 
    )
}

export default DataviewClient