import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import useSWR from 'swr';
import { useStore } from '../../context/store.context';
import { applyToSampleByTime2, getStackedAreadata } from "../chartjs/chartjs.data";
import StackedAreaChart from '../chartjs/stackedAreachart';
import { Card6 as Card, CardHeader } from "../layout/Card";
import { getTimeInMinutes } from '../layout/criteria';
import { FetchData2 } from "../model/store.swr";
import { getColorCode } from "../model/utils";
import { DownloadCsv_Mode_Summary } from './csv.download';

function getLabelPicker (e) {
    return e
}

const intervals = {'hour': 10, 'day': 30, 'week': 60 * 24}

const ModeSummaryLines = (props) => {
    //const response = FetchData(useSWR, urls)
    const criteria = useStore().criteria
    const key = Object.keys(intervals).find(k => criteria.timetype.includes(k))

    var interval = criteria.interval;
    if (key){
        interval = intervals[key];
    } 
    // const interval = criteria.timetype.includes('hour') ? 10 : 30;
    //console.log("PF RC MODE SUMMARY INTERVAL ", interval)
    const urls = [
        { name: "modesummary", url: "rc_mode_summary", slice: interval },
    ]
    const start_time = moment(criteria.Te)
    const response = FetchData2(useSWR, criteria, urls)

    if (!response.isReady) return <Card>Loading RC Mode Summary ...</Card>

    const data = response.data.modesummary;
    //console.log("rcmodesummary-raw", data)
    const sampledData = applyToSampleByTime2(data, getTimeInMinutes(criteria), interval)
    if (!sampledData)    return <Card>Error Loading !!!</Card>

    const startsec = (start_time.valueOf() / 1000).toFixed(0)
    //console.log("rcmodesummary-sampled", sampledData, start_time.toString(), interval)

    const tdf = sampledData.map((e) => { return { mode: e.mode, ts: (startsec - (e.time_key * interval * 60)), total: e.total } })
    //console.log("rcmodesummary-tdf", tdf)
    const ds = getStackedAreadata(tdf, "ts", "mode", "ts", "total", getLabelPicker, getColorCode, 'true')
    const dps = { datasets: ds }
    return (
        <>
            <Card >
                <CardHeader title={'Mode - Summary'} >
                    <DownloadCsv_Mode_Summary data={dps.datasets} filename={"mode-summary"+criteria.timetype}/>
                </CardHeader>
                <StackedAreaChart data={dps} ylabel={'RC Count'}/>
            </Card>
        </>
    );

}


export const PortfolioModeSummaryLines = observer(ModeSummaryLines) 
export const SiteModeSummaryLines =  observer(ModeSummaryLines) 
export const ZoneModeSummaryLines =  observer(ModeSummaryLines) 

//export default observer(ModeSummaryLines);
