import Papa from "papaparse";
import React, { useEffect, useMemo, useRef, useState } from "react";
import nextId from "react-id-generator";
import { useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
// import "react-table/react-table.css";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import styled from "styled-components";
import { CardFull as Card, CardHeader, CardHeaderSelect } from "../../components/layout/Card";
import { useCriteriaContext } from "../../context/criteria.context";
import { useDataviewActionContext } from "../../context/dataview.context";
// import "../../lib/download-min";
import { GetFQURL } from "../model/store.swr";
import { toJS } from 'mobx';
import { useStore } from "../../context/store.context"
import criteria, { isDevice, isPortfolio, isSite, isZone } from "../../components/layout/criteria";
import {createActivityLog} from '../../utils/apiCall'
import { getLevel, getLogData } from "../../helpers/common"
import {usePageContext} from "../../context/page.context"
import { mode_names, errorCodes } from '../../metadata/modes.meta';
import { getAccessToken } from '../../components/auth/userinfo'
import _ from 'lodash'

const rows = [{
    "site_id": "Wisconsin-7",
    "uptime": 96.4,
    "total": 146829,
    "devicecount": 156,
    "errorcount": 5,
    "anglediffcount": 4755,
    "mode": 8,
    "cnt": 145984,
    "lastmode": 8
}]
const cols = Object.keys(rows).map(e => { return { Header: e, accessor: e } })
const TWO_HUNDRED_MS = 200;

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length

  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    console.log("value change ", value)

    setGlobalFilter(value || undefined)
  }, TWO_HUNDRED_MS);

  return (
    <input
      value={value || ""}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
    />
  )
}
function Table({ columns, data , loading}) {
      /**
   * Custom Filter Fucntion ----
   * Only filter by: code * name
   */
  /*
    const ourGlobalFilterFunction = useCallback(
        (rows, ids, query) => {
        return matchSorter(rows, query, {
            keys: filters.map((columnName) => `values.${columnName}`)
        });
        },
        [filters]
    );
    */
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { globalFilter, pageIndex, pageSize  },
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter, useSortBy, usePagination
    )

    // Render the UI for your table
    return (
        <> 
            <div className="tablecontainer" >

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={nextId()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}  key={nextId()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} key={nextId()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>
            {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
            <div className="pagination">
                {/* <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                /> */}
                <button className="pagination-previous-btn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {/* {'<<'} */}
                    <img src="../assets/img/icon/icon-pagination-previous.svg" className="pagination-icon" alt="" width="16" height="16" />
                </button>{' '}
                <button className="pagination-back-btn" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {/* {'<'} */}
                    <img src="../assets/img/icon/icon-pagination-back.svg" className="pagination-icon" alt="" width="16" height="16" />
                </button>{' '}
                <button className="pagination-next-btn" onClick={() => nextPage()} disabled={!canNextPage}>
                    {/* {'>'} */}
                    <img src="../assets/img/icon/icon-pagination-next.svg" className="pagination-icon" alt="" width="16" height="16" />
                </button>{' '}
                <button className="pagination-forward-btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {/* {'>>'} */}
                    <img src="../assets/img/icon/icon-pagination-forward.svg" className="pagination-icon" alt="" width="16" height="16" />
                </button>{' '}
                <span style={{margin: '0px 25px', padding: '7px 4px'}}>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span style={{padding: '7px 4px'}}>
                    Go to page:{' '}
                </span>
                <span className="mr-25 goto" style={{padding: '0px'}}>
                    <input
                        type="number"
                        defaultValue={pageIndex}
                        min = "1"
                        max = {pageOptions.length}
                        onChange={e => {
                            // const page = e.target.value ? Number(e.target.value) - 1 : 0
                            // gotoPage(page)
                            const enteredPage = e.target.value ? Number(e.target.value) : 1;
                            const validPage = Math.min(Math.max(1, enteredPage), pageOptions.length);
                            gotoPage(validPage - 1)
                            if (enteredPage !== validPage) {
                                e.target.value = validPage;}                       
                                                    }}
                        style={{ width: '50px', borderRadius: '4px', border: '1px solid #014059', padding: '0px 4px' }}
                    />
                </span>{' '}
                <select style={{margin: '0px 25px', padding: '4px', borderRadius: '4px', border: '1px solid #014059'}}
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}


// const intervalOptions = [
//     { label: "1 min", value: 1},
//     { label: "5 mins", value: 5},
//     { label: "10 mins", value: 10},
//     { label: "30 mins", value: 30},
//     { label: "1 hour", value: 60},
//     { label: "12 hours", value: 12*60},
//     { label: "1 day", value: 24*60},
// ]
function downloadCSV(options,level,criteria, metricGroup, metricName) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'text/csv', 'x-access-token': getAccessToken() }
    };

    fetch(`${options.url}`, requestOptions)
        .then((res) => {
            return res.blob();
        })
        .then(async (blob) => {
            let csvText = await blob.text()
            let csv = ''
            csvText.split('\n').forEach(row => {
                if(row){
                    if(!csv){
                        csv += row
                        csv += '\n'
                    }else{
                        let rowElements = row.split(',')
                        rowElements[0] = getSiteLocalTime(rowElements[0])
                        if(metricName === 'currentMode' || metricName === 'errorCode'){
                            const arr = rowElements.slice(1,rowElements.length).map(e => metricName === 'currentMode' ? mode_names[parseInt(e)] : errorCodes[parseInt(e)])
                            rowElements = [rowElements[0],...arr]
                        }
                        csv += rowElements.join(',')
                        csv += '\n'
                    }
                }
                
            })
            
            const link = document.createElement('a');
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
            link.setAttribute('download', options.title + ".csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((err) => {
            return Promise.reject({ Error: 'Something Went Wrong', err });
        })
    const metric = options.title.split(">")[1]
    const head = options.title.split(">")[0]
    createActivityLog(getLogData(criteria, {featureId: `download-${level}-metrics`.toLowerCase(), metrics: metric, usecase: `download metrics data`.toLowerCase(), category: 'downloads', level: level, details: '{}'}))
}

const getSiteLocalTime = timestamp => {
    const utcTime = moment.utc(timestamp)
    const siteTime = moment(utcTime.format())
    return siteTime.format("YYYY-MM-DD HH:mm:ss")
}

const Header = (props) => {
    // const [interval, setInterval] = useState((allKeys)?"snow_fall":"");
let intervalOptions;
  if (props.view.metricGroup !== "VI") {
    intervalOptions = [
      { label: "5 mins", value: 5 },
      { label: "10 mins", value: 10 },
      { label: "30 mins", value: 30 },
      { label: "1 hour", value: 60 },
      { label: "12 hours", value: 12 * 60 },
      { label: "1 day", value: 24 * 60 },
    ];
  } else {
    intervalOptions = [
        { label: "1 min", value: 1},
        { label: "5 mins", value: 5},
        { label: "10 mins", value: 10},
        { label: "30 mins", value: 30},
        { label: "1 hour", value: 60},
        { label: "12 hours", value: 12*60},
        { label: "1 day", value: 24*60},
        ]
  }
    const [downloadOptions] = useState(props.downloadOptions)
    const c = useCriteriaContext()
    const pageContext = usePageContext()
    const level = isPortfolio(c) ? 'pf' : isSite(c) ? 'site' : isZone(c) ? 'zone' : isDevice(c) ? 'rc' : {}

    return (
        <CardHeader title={downloadOptions.title} loading={props.loading} doc={"Help"}>
            

            {/* <DownloadCsv_Uptime data={response.data.rcuptime} filename={"rc_uptime_overview_"+criteria.timetype} /> */}
            {!props.loading && 
            <>
            <CardHeaderSelect  level={level} criteria={c} metric={props.view.title} usedFor="csvDownloadViewer" value={props.getInterval()} selected={props.setInterval} options={intervalOptions}  optionLabel={"label"} optionKey={"value"}/>
            <div style={{cursor: 'pointer', 'marginLeft': "15px"}} onClick={() => props.downloader(downloadOptions,level,c,props.metricGroup, props.view.title)}><img src="../assets/img/icon/icon-download.svg" alt="Download" width="16" height="16" className="chart-download" /></div>
                &nbsp;&nbsp;
            <div style={{cursor: 'pointer'}} onClick={() => props.close()}><img src="../assets/img/icon/icon-clear.svg" alt="Close" width="24" height="24" className="chart-download" /></div>
            </>
            }
            
        </CardHeader>
    )
}
const Styles = styled.div`
    margin: 0px;
    padding: 0px;

    // overflow-x: scroll;
    .tablecontainer{
        overflow-x: scroll;
        margin-bottom: 35px;
    }
    table {
        border-spacing: 0;
        border: 1px solid black;
        tr {
        :last-child {
            td {
            border-bottom: 0;
            }
        }

    }
    tbody {
        background-color: rgba(255,255,255,0.2)
    }

    th,
    td {
      margin: 0;
      padding: 0.1rem 0.3rem;
      border-bottom: 1px solid #333;
      border-right: 1px solid #333;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
    position: absolute;
    bottom: 0;
  }
  .verticalTableHeader {
    text-align:center;
    white-space:nowrap;
    g-origin:50% 50%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    
    }
    .verticalTableHeader p {
        margin:0 -100% ;
        display:inline-block;
    }
    .verticalTableHeader p:before{
        content:'';
        width:0;
        padding-top:110%;/* takes width as reference, + 10% for faking some extra padding */
        display:inline-block;
        vertical-align:middle;
    }

  
`
 
function CsvViewer(props) {
    const [view, setView] = useState(props.view);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [close, setClose] = useState(false);
    const criteria = useCriteriaContext()
    const mountedRef = useRef(true)
    const dataviewaction = useDataviewActionContext()



    
    useEffect(() => {
        handleViewChange()
        return () => {
            mountedRef.current = false
        }
    }, [view,criteria])

    /*
    useEffect(() => {
        console.log(data, columns)
        if (data.length && columns.length) setLoading(false);
    }, [data, columns]);
    */
    /*
    const handleFileChange = file => {
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            delimiter: ',',
            download: true,
            skipEmptyLines: true,
            complete: handleDataChange
        });
    };
    */

    function columnName(c, i) {
        return i>0? <span><br/>{c}</span>:<span>{c}</span>
        
    }
    const makeColumns = rawColumns => {
        return rawColumns.map(column => {
            return { 
                //Header: column, 
                Header: () => column.split('/').map((e,i) => columnName(e,i)),
                accessor: column 
            };
        });
    };

    

    const handleDataChange = url => {
        if (!mountedRef.current) return null
        if (url.data && url.data.length > 0) {
            // const cols = url.data[0]//.split(",");
            // const rows = url.data.slice(1)
            //console.log(rows, cols)
            // console.log("CSV Response", url.data)
            const data = url.data.map(entry => ({...entry, timestamp: getSiteLocalTime(entry.timestamp)}))
            setData(data);
            setColumns(makeColumns(url.meta.fields));
            //setColumns(makeColumns(cols))

        } else {
            setData([]);
            setColumns([]);
        }
        setLoading(false)

    };

    const handleViewChange = () => {

        mountedRef.current = true
        setLoading(true)
        const metricGroup = view.metricGroup ? { metricGroup: view.metricGroup } : {}

        const urlobj = Object.assign({}, { name: "unknown", url: view.url, grouptype: view.grouptype, slice: view.interval}, metricGroup)

        const url = GetFQURL(urlobj, criteria, "csv")
        
        Papa.parse(url, {
            header: true,
            dynamicTyping: true,
            delimiter: ',',
            download: true,
            skipEmptyLines: true,
            complete: handleDataChange,
            downloadRequestHeaders:{
                'x-access-token': getAccessToken()
            }
        });

    }


    function handleClose() {
        dataviewaction.clearView()

        setClose(true)
    }

    function handleInterval(i){
        setView({...view, interval: i})
    }

    const RenderHeader = () => {
        const v = useMemo(() => {
            const metricGroup = view.metricGroup ? { metricGroup: view.metricGroup } : {}

            const urlobj = Object.assign({}, { name: "unknown", url: view.url, grouptype: view.grouptype, slice: view.interval}, metricGroup)
    
            const uriObj = GetFQURL(urlobj, criteria, "csv")
            // console.log(view, uriObj)

            const downloadOptions = {title: view.head + " > " + view.title, url: uriObj}
            return {
                loading: loading,
                data: data,
                view: view,
                downloadOptions: downloadOptions,
                downloader: downloadCSV,
                close: handleClose,
                setInterval: handleInterval,
                getInterval: () => view.interval,
                metricGroup: metricGroup,
                view: view

            }
        }, [loading])


        return Header({ ...v })
    }

    const RenderBody = () => {
        const v = useMemo(() => {
            var dataCopy = []
            if(view && (view.title === 'currentMode' || view.title === 'errorCode')){   
                dataCopy = data.map(obj => {
                    const objCopy = {...obj}
                    for(let key in obj){

                        if(key !== 'timestamp'){
                            objCopy[key] = view.title === 'currentMode' ? mode_names[obj[key]] : errorCodes[obj[key]]
                        }
                    }
                    return objCopy
                })
            }

            return {
                data: dataCopy.length ? dataCopy : data,
            }
            
        }, [data])
        
        return (
            <>
           {data && data.length && 
            <Styles>
                <Table
                    data={v.data}
                    columns={columns}
                // className="-striped -highlight"
                    className="container-fluid"
                />
                
            </Styles>
           }
           </>
            
        );
    }
    /*
    return (
        <div>
            {!loading && (
                <Styles>
                <Table
                    data={data}
                    columns={columns}
                    // className="-striped -highlight"
                />

                </Styles>
            )}
        </div>
    );*/
    return (
        <>
        {!close && (
        <Card>
            <RenderHeader />
            <RenderBody />
        </Card>
        )}
        </>
    )
}

export default CsvViewer;