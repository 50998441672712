import { Chart } from "chart.js";
import { parse } from "papaparse";
import nextId from "react-id-generator";
import { getAccessToken } from "../components/auth/userinfo";

const updateCompositeChartHeader = (u,rows) => {
    let updatedheader = rows[0].split(',').splice(1,rows[0].split(',').length - 1).map(e => e + '(' + u.metricName + ')')
    return [rows[0].split(',')[0], ...updatedheader].join(',')
}

export const parseUrls = (urls, abortController, chartTitle, chartType, cb) => {
    var cols = []
   
    let token = getAccessToken()
       
    Promise.all(
        [...urls].map(
            (u, i) =>
                new Promise((resolve, reject) => {
                    parse(u.url, {
                        header: false,
                        complete: resolve, // Resolve each promise
                        error: reject,
                        download: true,
                        skipEmptyLines: true,
                        transform: u.transform,
                        dynamicTyping: true,
                        beforeFirstChunk: function (chunk) {
                            var rows = chunk.split(/\r\n|\r|\n/);
                            if(chartType === 'composite'){
                                rows[0] = updateCompositeChartHeader(u,rows)
                            }
                            //var headings = rows[0].toUpperCase();
                            //rows[0] = headings;
                            cols[i] = rows[0].split(",");
                            //console.log(rows.slice(1));
                            return rows.slice(1).join("\r\n");
                        },
                        downloadRequestHeaders:{
                            'x-access-token': token
                        }

                    });
                    abortController.signal.addEventListener('abort', () => reject('oops'));
                })
        )
    ).then((results) => {
        const id = nextId()
        const logs = []
        const urlData = {}
        //console.log("papa fetch result",results)
        results.forEach((result, index) => {
            
            const u = urls[index];
            logs.push([id, '>>', u.name, '', new Date(Date.now()).toISOString()].join(", "))
            const columns = cols[index]
            const indexes = Object.assign({}, ...columns.map((p, i) => ({ [p]: i })));
            if(chartTitle === 'Active Power Summary'){
                result.data.forEach(d => {
                    d.forEach((el,i) => {
                        if(i>0){
                            d[i] = Math.round(el/1000)
                        }
                    })
                })
            }
            urlData[u.name] = Object.assign({ columns: columns, indexes: indexes, values: result.data })
            logs.push([id, '<<', u.name, '', new Date(Date.now()).toISOString()].join(", "))
        });
        logs.push([id, 'cb>>',  new Date(Date.now()).toISOString()].join(", "))
        cb(urlData)
        logs.push([id, '<<cb',  new Date(Date.now()).toISOString()].join(", "))
        //console.log(urlData); // now since .then() excutes after all promises are resolved, filesData contains all the parsed files.
        // logs.map(e => console.log(e))
    }).catch((err) => console.log("Something went wrong:", err));
};
 