import React, { useState, useRef, useEffect } from 'react';
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ListGroup, 
    ListGroupItem, 
    ListGroupItemHeading, 
    ListGroupItemText,
    Input,
    Label,
    Alert,
} from 'reactstrap';
import styled from 'styled-components'
import { isAdminUser } from '../auth/userinfo'
import { AiOutlineEdit, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import Editor from './editor'
import { useNotificationContext } from '../../context/notification.context'
import { uuid } from 'uuidv4';

const ReleaseNotes = styled.div`

    height: ${props => window.innerHeight - 158}px;

    .button {
        background: #014059;
        color: white;
        font-size: 16px;
    }

    .cancel {
        background: #eeeeee;
        border: 1px solid #dedede;
        color: #000;
    }

    .add-release {
        padding: 15px;
    }

    .add-release-button {
        background: #014059;
        color: white;
        font-size: 36px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        padding: 0px;
        line-height: 0;
        position: fixed;
        right: 60px;
        bottom: 60px;
        z-index: 100;
        cursor: pointer;
    }

    .add-release-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 0px;
    }

    .add-release-form label {
        font-size: 16px;
        line-height: 0.75;
    }

    .error {
        background: pink;
        color: red;
        position: fixed;
        top: 83px;
        width: auto;
        height: 44px;
        font-size: 16px;
        left: 50%;
        transform: translateX(-50%);
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        z-index: 100;
    }

    .list-item {
        background: white;
        color: #014059;
        border: none;
    }

    .list-item-header {
        border-bottom: 1px solid #dedede;
    }

    .release-title {
        font-size: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .release-title .icon {
        cursor: pointer;
        color: #014059;
        align-self: end;
    }

    .release-text {
        font-size: 16px;
        padding: 20px 0px;
    }

    .no-release {
        font-size: 17px;
        color: #014059;
        padding: 20px;
    }
`

export default props => {

    const isAdmin = isAdminUser()

    const [addingRelease, setAddingRelease] = useState(false)
    const [addingReleaseLoading, setAddingReleaseLoading] = useState(false)
    const [title, setTitle] = useState("")
    const [content, setContent] = useState(null)
    const [inFocus, setInFocus] = useState(false)
    const [error, setError] = useState(false)
    const [tempTitle, setTempTitle] = useState("")
    const [tempContent, setTempContent] = useState(null)

    const notificationContext  = useNotificationContext()
    const { releases, setReleases, saveReleaseSync } = notificationContext

    const r = props.releases || releases

    const headingRef = useRef()
    
    const onChange = (content, id) => {
        setReleases(releases.map(t => t.id === id ? { ...t, content: content } : t))
    }
    
    const editRelease = (id) => {
        setReleases(releases.map(t => ({ ...t, 
            title: t.editing ? tempTitle : t.title, 
            content: t.editing ? tempContent : t.content,
            editing: t.id === id, 
            inFocus: t.id === id 
        })))
        setAddingRelease(false)
        const release = releases.filter(t => t.id === id)
        if (release.length > 0) {
            setTempTitle(release[0].title)
            setTempContent(release[0].content)
        }
    }

    const doneEditRelease = (id, title, content) => {
        if (!title) {
            setError("Please add a heading!")
            // headingRef.current.focus()
            return
        }

        if (content) {
            const contentState   = content.getCurrentContent()
            if (!contentState.hasText()) {
                setError("Please add notes for the release!")
                // setInFocus(true)
                return
            }
        } else {
            setError("Please add notes for the release!")
            // setInFocus(true)
            return
        }
        setReleases(releases.map(t => ({ ...t, saving: t.id === id })))
        saveReleaseSync('edit', id, title, content, () => {
            setReleases(releases.map(t => ({...t, editing: false, saving: false})))
            setTempTitle("")
            setTempContent(null)
        }, (err) => {
            setError(err)
            setReleases(releases.map(t => ({...t, saving: false})))
        })
    }

    const saveRelease = () => {
        if (!title) {
            setError("Please add a heading!")
            headingRef.current.focus()
            return
        }

        if (content) {
            const contentState   = content.getCurrentContent()
            if (!contentState.hasText()) {
                setError("Please add notes for the release!")
                setInFocus(true)
                return
            }
        } else {
            setError("Please add notes for the release!")
            setInFocus(true)
            return
        }

        setAddingReleaseLoading(true)
        const id = uuid()
        saveReleaseSync('add', id, title, content, () => {
            setReleases([{title: title, publishedDate: (new Date()).toDateString(), content: content, id: id}].concat(releases))
            setAddingRelease(false)
            setTitle("")
            setContent(null)
            setAddingReleaseLoading(false)
        }, (err) => {
            setAddingReleaseLoading(false)
            setError(err)
        })
    }

    const cancelEditing = () => {
        setReleases(releases.map(t => ({...t, 
            title: t.editing ? tempTitle : t.title, 
            content: t.editing ? tempContent : t.content,
            editing: false, 
            saving: false
        })))
    }

    const closeReleaseNotes = () => {
        props.closeReleaseNotes()
        setAddingRelease(false)
        cancelEditing()
    }

    const handleChange = (id, value) => {
        setReleases(releases.map(t => t.id === id ? { ...t, title: value } : t))
    }

    useEffect(() => {
        if (addingRelease && headingRef && headingRef.current){
            headingRef.current.focus()
        }
    }, [addingRelease])

    useEffect(() => {
        let timeout;
        if (error) {
            timeout = setTimeout(() => {
                setError("")
            }, 5000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [error])

    return <Modal isOpen={props.showNotes} scrollable toggle={closeReleaseNotes} fullscreen className="release-notes-modal">
        <ModalHeader toggle={closeReleaseNotes}>Release Notes</ModalHeader>
        <ModalBody >
            <ReleaseNotes >
                {error ? <Alert className= {"error"}>
                    {error}
                </Alert> : null}
                {!addingRelease && isAdmin ? <Button
                    className="add-release-button"
                    onClick={() => {
                        setAddingRelease(true)
                        cancelEditing()
                    }}
                >
                    +
                </Button> : null}
                {addingRelease && isAdmin ? <div className="add-release">
                    <React.Fragment>
                        <div className="add-release-form">
                            <div>
                                <Label>Heading</Label>
                                <Input placeholder="Release title.." value={title} onChange={e => setTitle(e.target.value)} innerRef={headingRef}  style={{ width: '80%' }} />
                            </div>
                            <div>
                                <Label>Notes</Label>
                                <Editor content={content} setContent={setContent} inFocus={inFocus} resetFocus={() => setInFocus(false)}/>
                            </div>
                            <Button
                                className="button"
                                onClick={saveRelease}
                                disabled={addingReleaseLoading}
                            >
                                {addingReleaseLoading ? 'Saving..' : 'Save'}
                            </Button>
                            <Button
                                className="button cancel"
                                onClick={() => setAddingRelease(false)}
                                disabled={addingReleaseLoading}
                            >
                                Cancel
                            </Button>
                        </div>

                    </React.Fragment>
                </div> : null}
                <ListGroup  className="release-data">
                    {r.length > 0 ? r.map(t => <ListGroupItem className="list-item">
                        <div className="list-item-header">
                            <ListGroupItemHeading className="release-title">
                                {t.editing ? <Input value={t.title} onChange={e => handleChange(t.id, e.target.value)} style={{ width: '80%' }} /> : <span>{t.title}</span>}
                                {isAdmin ? (!t.editing ? 
                                    <AiOutlineEdit className="icon" onClick={() => editRelease(t.id)} /> : 
                                    t.saving ? <span style={{fontSize: 12, marginTop: 20}}>saving..</span> : <span><AiOutlineClose className="icon" onClick={cancelEditing} />  <AiOutlineCheck className="icon" onClick={() => doneEditRelease(t.id, t.title, t.content)} /></span>)
                                : null}
                            </ListGroupItemHeading>
                            <span className="published-date">{t.publishedDate}</span>
                        </div>
                        <ListGroupItemText className="release-text">
                            <Editor readonly={!t.editing} content={t.content} setContent={content => onChange(content, t.id)} inFocus={t.inFocus} resetFocus={() => setReleases(releases.map(t => ({...t, inFocus: false})))}/>
                        </ListGroupItemText>
                    </ListGroupItem>) : <span className="no-release">No release found</span>}
                </ListGroup>
            </ReleaseNotes>
        </ModalBody>
      </Modal>
}