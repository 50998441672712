import React from "react";
import { Modal } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
import styled, { css } from "styled-components";
import { useDialogActionContext, useDialogContext } from "../context/dialog.context";

class DraggableModalDialog extends React.Component {
  render() {
      return <Draggable handle=".modal-title"><ModalDialog {...this.props} /> 
 </Draggable>
  }
}

function Dialog() {
  const context = useDialogContext()
  const actionContext = useDialogActionContext()

  const StyledModal = styled(Modal)`

  .modal-dialog {
    width: 90vw;
  }
  
  .modal-content {
    // background: rgba(20,70,90,.9);
    ${(props) => {
      switch (props.$size) {
        case 4:
          return css`
            width: 40%;  
        `;
        default:
          return css`
            width: 100%;  
        `;
      }
    }}

    ${(props) => {
      if(props.isEditReport){
        return css`min-width: 1000px;`
      }else{
        return css`min-width: 480px;`
      }
    }}
    
    margin: auto 0;
  
  }
  
  .modal .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 5px;
    border-bottom: 1px solid rgba(255,255,255,.25);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  `
  
  const handleClose = event => {
    actionContext.closeDialog()
  }
  
  const handleApply = event => {
    actionContext.applyDialog()
  }

  const Content123 = () => {
    return <context.Content args={context.args}/>
  }

  const ContentStyled = styled.div`
  display: inline-block;
  max-height: 70vh;
  height: 100%;
  // overflow: auto;
  margin: auto 0;
  width: 100%;
  `
  return (
    // <Styles>
      <StyledModal 
        // dialogAs={DraggableModalDialog} 
        isEditReport = {context.title === 'Manage Reports' ? true : false} show={context.showDialog} $size={context.size} onHide={handleClose} animation={false} centered={true} className={context.customClass} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{context.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><ContentStyled><Content123 /></ContentStyled></Modal.Body>
        {/* <Modal.Footer>
          <Button variant="Primary" onClick={handleApply}>Apply</Button>

          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer> */}
      </StyledModal>
    // </Styles>
  );
}

export default Dialog