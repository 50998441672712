import axios from 'axios'
import React, { useState, useEffect, useMemo } from 'react'
import ContextFactory from './base.context'
import { columns } from '../components/realtime/columns'
import { modes,  errorCodes, trackingModes, errorCodesGC } from '../components/modelviewer/heatmap'
import MiniSearch from 'minisearch'
import { getAllMetrics, getSites } from '../model/metadata'

import { useCriteriaContext } from './criteria.context'

import { getAccessToken } from '../components/auth/userinfo'
import {compressAndEncode} from '../components/model/utils'
import moment from 'moment'

const RealtimeContext = React.createContext()


export const RealtimeContextProvider = (props) => {

    const [rowAttrs, setRowAttrs] = useState([])

    const [products, setProducts] = useState([]);
    const [productsShown, setProductsShown] = useState([])
    const [query, setQuery] = useState("")
    const [queryApplied, setQueryApplied] = useState({ mode: "", query: ""})

    const [error, setError] = useState(false)

    const [miniSearch, setMiniSearch] = useState(null)
    const [metrics, setMetrics] = useState([])
    const [searchApplied, setSearchApplied] = useState(false)
    const [sites, setSites] = useState([])

    const [realtimeMetric, setRealtimeMetric] = useState(null)

    const criteriaContext = useCriteriaContext()

    const filterData = {
      'rover_offline': [],
      'errorCode': [],
      'errorCodeGC': [],
      'currentAngle': [],
      'currentMode': [],
      'pvVoltage': [],
      'pvCurrent': [],
      'batteryVoltage': [],
      'batteryCurrent': [],
      'comm loss': [],
      'tracking disabled': [],
      'tracker angle': [],
      'tracking mode': [],
      'target angle': [],
    }

    // let miniSearch = useMemo(() => new MiniSearch({
    //   fields: columns.map(t => t.dataField).concat(['currentMode_encoded', 'errorCode_encoded', 'rover_offline_encoded']),
    //   storeFields: columns.map(t => t.dataField),
    //   processTerm: (term, _fieldName) => term,
    //   tokenize: (string, _fieldName) => string.split(" "),
    //   searchOptions: {
    //     combineWith: "AND",
    //   }
    // }), criteriaContext)
    
    const convertEncoding = (filterData, name, type) => {

      if (type === 'encoded') {
        if (name === 'rover_offline')
          return filterData.reduce((acc, curr)=> {
            if (acc.indexOf(curr || 0) < 0)
              acc.push(curr || 0)
            return acc
          }, []).map(t => ({value: t, label: t === 1 ? 'Offline' : 'Online'}))  
        else if (name === "tracking disabled" || name === "comm loss")
            return filterData
                .reduce((acc, curr) => {
                    if (acc.indexOf(curr || 0) < 0) acc.push(curr || 0);
                    return acc;
                }, [])
                .map((t) => ({ value: t, label: t === 1 ? "Yes" : "No" }));
        else if (name === "errorCode") {
          const uniqueLabels = new Set();
          const decoder = (t) => {
              const val = t.includes(",") ? t.split(",") : [t];
              const decodedValues = val.map((v) => {
                  const label = errorCodes[v];
                  if (!uniqueLabels.has(label)) {
                      uniqueLabels.add(label);
                      return { key: v, value: label };}
                  return null;
              }).filter((item) => item !== null);
              return decodedValues;};
          return filterData.map((t) => decoder(t)).flat().map((t) => ({
              value: t.key,
              label: t.value
          }));
        } else if (name === "errorCodeGC")
            return filterData.map((t) => ({
                value: t,
                label: errorCodesGC[t] || "",
            }));
        else if (name === "currentMode")
            return filterData.map((t) => ({ value: t, label: modes[t] || "" }));
        else if (name === "tracking mode")
            return filterData.map((t) => ({
                value: t,
                label: trackingModes[t] || "",
            }));
        else return filterData.map((t) => ({ value: t, label: t }));
      } else {
        return filterData.length > 0 ? filterData.reduce((acc, curr) => {
          if (acc[0] > curr)
            acc[0] = curr
          if (acc[1] < curr)
            acc[1] = curr

          return acc
        }, [filterData[0], filterData[0]]) : []
      }
    }

    const setupRealtime = (data) => {
      if (data && data.length > 0) {
        let uniqueData = [
          {metric: 'tracker_id'},
          {metric: 'site_id'},
          {metric: 'zone_id'},
          {metric: 'device_id'},
        ].concat(data.filter(t => t.live_status).reduce((acc, curr) => {
          if (acc.filter(t => t.metric === curr.metric).length === 0)
            acc.push(curr)

          return acc
        }, [])).concat({metric: 'last_updated'})

        if (uniqueData.filter(t => t.metric === 'tracking mode').length > 0)
          uniqueData = uniqueData.map(t => t.metric === 'errorCode' ? { metric: 'errorCodeGC'} : t)

        setRowAttrs(uniqueData
          .filter(f => f.dataField !== "device_id")
          .map(t => ({dataField: t.metric, text: t.metric})))

        let miniSearch = new MiniSearch({
          fields: uniqueData.map(t => t.metric).concat(['currentMode_encoded', 'errorCode_encoded', 'rover_offline_encoded', 'tracking mode_encoded', 'errorCodeGC_encoded','tracking disabled_encoded', 'comm loss_encoded']),
          storeFields: uniqueData.map(t => t.metric),
          processTerm: (term, _fieldName) => term,
          tokenize: (string, _fieldName) => string.split(" "),
          searchOptions: {
            combineWith: "AND",
          }
        })

        setMiniSearch(miniSearch)
        setMetrics(uniqueData)

        setSortRowAttrs(
          uniqueData.map(t => ({dataField: t.metric, sort: null}))
        )
      }
    }

    const addZero = (number, type) => {
      const d = Math.abs(number)
      const t = number < 0 ? "-" : "+"
      return (type === 'h' ? t : "") + (d < 10 ? ("0" + d) : d)
    }

    useEffect(() => {
      setMetrics([])
      setProducts([])
      setProductsShown([])
      if (criteriaContext.site) {
        if (sites.length === 0) {
          getSites(
            criteriaContext, 
            data => setSites(data && data.length > 0 ? data : []),
            err => setError("Failed to fetch sites: " + err.message)
          )
        }
        getAllMetrics(criteriaContext, (data) => {
          setupRealtime(data)
        }, (err) => {
          setError("Failed to fetch metrics: "+ err.message)
        })
      } else  {
        setError("Details view is only accessible at the Site and Zone levels.  Please select a Site or Zone.")
      }

    }, [criteriaContext.site])

    useEffect(() => {
      if (metrics.length > 0 && sites.length > 0) {
        const { pf, site, zone, device } = criteriaContext
        fetchRealtimeData(miniSearch, pf, site, zone, device)
      }
    }, [criteriaContext.zone, criteriaContext.device, metrics, sites])

    const fetchRealtimeData = (miniSearch, pf, site, zone, device) => {
      setProducts([])
      setError("")
      let token = getAccessToken()

      if (site) {
          let currentdate = moment()
          const siteObj = sites.filter(s => s.site_id === site)
          if (siteObj.length > 0) {
            const timezone = siteObj[0].timezone
            currentdate = timezone ? moment().tz(timezone) : moment()
          }
          
          const date = currentdate.format("YYYY-MM-DD") + "T00:00:00.000" + addZero(Math.floor(currentdate.utcOffset() / 60), 'h') + ":" + addZero(currentdate.utcOffset() % 60)
          const t = new Date(date);
          const d = t.toISOString().replace("Z", "")
          
          axios({
              url: `query/details?` + compressAndEncode(`pf=${pf}&site=${site}&zone=${zone}&device=${device}&querytype=readingsRealTime_1&restype=json&project=depcom&grouptype=rcs&timetype=7-day&currentdate=${d}`),
              method: 'GET',
              headers: {
                  'x-access-token': token,
                  'Content-Type': 'application/json'
              }
          }).then(result => {
              setProducts(result.data)
              setRowAttrs(rowAttrs
                // .filter(t => result.data && result.data.length > 0 ? Object.keys(result.data[0]).indexOf(t.dataField) >= 0 : t)
                .map(t => columns.filter(e => e.dataField === t.dataField)[0] || {...t, selected: true}))
              const filterRowAttrs = metrics.filter(f => Object.keys(filterData).indexOf(f.metric) >= 0)
                  .map(t => ({dataField: t.metric, selected: false, text: t.metric, open: false, type: t.type, filterData: filterData[t.metric], selectedFilters: []}))
              const filterDataAvailable = result.data.reduce((acc, curr) => {
                  Object.keys(acc).forEach(key => {
                    if (acc[key].indexOf(curr[key]) < 0)
                      acc[key].push(curr[key])
                  })
                  return acc
              }, filterData)
              setProductsShown(result.data)  
              if (result.data && result.data.length === 0)
                setError("No data from today")
              const newFilterRowAttrs = filterRowAttrs.map(t => {
                const details = columns.filter(e => e.dataField === t.dataField)
                const type = details.length > 0 ? details[0].type : ''
                const encodedFilterData = convertEncoding(filterDataAvailable[t.dataField], t.dataField, type)
                return {
                  ...t, 
                  filterData: encodedFilterData,
                  selectedFilters: type === 'encoded' ? encodedFilterData.map(t1 => t1.value) : encodedFilterData,
                  selected: false,
                  type: type
                }
              })      
              setFilterRowAttrs(newFilterRowAttrs)
              const documents = result.data.map(t => ({
                ...t,
                id: t.device_id, 
                currentMode_encoded: encodeText(t.currentMode, 'currentMode'),
                errorCode_encoded: encodeText(t.errorCode, 'errorCode'),
                errorCodeGC_encoded: encodeText(t.errorCodeGC, 'errorCodeGC'),
                rover_offline_encoded: encodeText(t.rover_offline, 'rover_offline'),
                'tracking mode_encoded': encodeText(t['tracking mode'], 'tracking mode'),
                'tracking disabled_encoded': encodeText(t['tracking disabled'], 'tracking disabled'),
                'comm loss_encoded': encodeText(t['comm loss'], 'comm loss')
              }))
              miniSearch.removeAll()
              miniSearch.addAll(documents)
              setQuery("")
              setQueryApplied({query: "", mode: ""})
          }).catch(err => {
              setError("Failed to fetch realtime data"+ err.message)
          })
      }
      else  {
          setError("Details view is only accessible at the Site and Zone levels.  Please select a Site or Zone.")
      }
    }
      
      const [filterRowAttrs, setFilterRowAttrs] = useState([])

      const [sortRowAttrs, setSortRowAttrs] = useState([])


      const errorCodesToString = (string) => {
        string = string.split(",")
        const errors = string.map((value, index) => errorCodes[value])
        return errors
      }

      const getSortValue = (dataField, value) => {
        if (dataField === 'rover_offline')
          return value === 1 ? 'Offline' : 'Online'
        else if (dataField === 'tracking disabled' || dataField === 'comm loss')
          return value === 1 ? 'Yes' : 'No'
        else if (dataField === 'errorCode')
          return (value === 0 || !errorCodes[value]) ? "" : errorCodes[value]
        else if (dataField === 'errorCodeGC')
          return !errorCodesGC[value] ? "" : errorCodesGC[value]
        else if (dataField === 'currentMode')
          return modes[value]
        else if (dataField === 'tracking mode')
          return trackingModes[value]
        else
          return value
      }

      const sortProductsShown = (sortRowAttrs, productsShown) => {
        const sorted = sortRowAttrs.filter(t => t.sort === 'asc' || t.sort === 'desc')
        if (sorted.length > 0) {
          const sort = sorted[0].sort
          const dataField = sorted[0].dataField
          
          const productsShownSorted = productsShown.slice().sort((a, b) => {
            if (sort === 'asc') {
              return getSortValue(dataField, a[dataField]) > getSortValue(dataField, b[dataField]) ? 1 : -1
            } else {
              return getSortValue(dataField, a[dataField]) > getSortValue(dataField, b[dataField]) ? -1 : 1
            }
          })

          return productsShownSorted
        } else {
          return productsShown
        }
      }

      const applySort = (dataField, sort) => {
        const newSortRowAttrs = sortRowAttrs.map(t => ({...t, sort: t.dataField === dataField ? sort : null}))
        setSortRowAttrs(newSortRowAttrs)

        const productsShownSorted = sortProductsShown(newSortRowAttrs, productsShown)
        setProductsShown(productsShownSorted)
      }

      const toggleFilter = (dataField, value) => {
        const newFilterRowAttrs = filterRowAttrs.map(t => t.dataField === dataField ? {
            ...t,
            selectedFilters: t.type === 'encoded' ?
              t.selectedFilters.includes(value) ? t.selectedFilters.filter(q => q !== value) :[...t.selectedFilters, value] :
              value,
            selected: t.type === 'encoded' ?
              t.selectedFilters.includes(value)? t.selectedFilters.length - 1 < t.filterData.length : true : 
              t.filterData[0] < value[0] || t.filterData[1] > value[1]
          } : t
        )
        setFilterRowAttrs(newFilterRowAttrs)

        const results = query !== "" ? searchProducts(query) : products
        const productsShown = filterProductsShown(results, newFilterRowAttrs)

        const productsShownSorted = sortProductsShown(sortRowAttrs, productsShown)
        setProductsShown(productsShownSorted)
      }

      const filterProductsShown = (productsShown, newFilterRowAttrs) => {
        
        let filterData = newFilterRowAttrs.map(t => t.dataField === 'rover_offline' && t.selectedFilters.indexOf(0) >= 0 ? 
          {...t, selectedFilters: t.selectedFilters.concat(null).concat(undefined)} : t)
        

        filterData.forEach(data => {
          productsShown = productsShown.filter(t => 
            {
              if(data.type === 'encoded'){
                if(data.dataField === "errorCode"){
                  return data.selectedFilters.some(f => t[data.dataField].split(',').includes(f));
                }else{
                  return data.selectedFilters.indexOf(t[data.dataField]) >= 0;
                }
              }
              else{
                return t[data.dataField] >= data.selectedFilters[0] && t[data.dataField] <= data.selectedFilters[1];
              }
            }
          )
        })
        return productsShown
      }

  
      const selectColumn = value => {
        setRowAttrs(rowAttrs.map(t => t.dataField === value ? {...t, selected: !t.selected} : t))
      }
      
      const selectFilter = value => {
        setFilterRowAttrs(filterRowAttrs.map(t => ({...t, open: t.dataField === value  })))
      }
  
      const closeFilters = () => setFilterRowAttrs(filterRowAttrs.map(t => ({...t, open: false})))

      const clearFilters = () => {
        setFilterRowAttrs(filterRowAttrs.map(t => {
          return {
            ...t, 
            selectedFilters: t.type === 'encoded' ? t.filterData.map(t1 => t1.value) : t.filterData,
            selected: false
          }
        }))

        const results = query !== "" ? searchProducts(query) : products

        const productsShownSorted = sortProductsShown(sortRowAttrs, results)
        setProductsShown(productsShownSorted)
      }

      const deleteFilter = dataField => {
        const newFilterRowAttrs = filterRowAttrs.map(t => {
          return t.dataField === dataField ? {
            ...t, 
            selectedFilters: t.type === 'encoded' ? t.filterData.map(t1 => t1.value) : t.filterData,
            selected: false
          } : t
        })

        const results = query !== "" ? searchProducts(query) : products
        const productsShown = filterProductsShown(results, newFilterRowAttrs)

        const productsShownSorted = sortProductsShown(sortRowAttrs, productsShown)

        setFilterRowAttrs(newFilterRowAttrs)
        setProductsShown(productsShownSorted)

        return newFilterRowAttrs.filter(t => t.selected).length
      }

      const detectColumn = query => {
        if (query.indexOf(":") >= 0) {
            const qParts = query.split(":")
            const text = qParts[1].trim()
            const column = qParts[0].trim()
            if (column) {
              const matched = columns.filter(c => c.text.toLowerCase() === column.toLowerCase())
              if (matched.length > 0) {
                const field = matched[0].dataField
                let encoded = field
                if (field === 'currentMode' || field === 'errorCode' || field === 'rover_offline' || field === 'errorCodeGC' || field === 'tracking mode' || field === 'tracking disabled' || field === 'comm loss')
                  encoded = field + '_encoded'
                return { text: text, field: encoded, column: column }
              }
            }
        } 
  
        return { text: query, column: "", field: ""}
      }
  
      const decodeText = (text, field) => {
        const getKeyFromValue = (obj, value) => Object.keys(obj).reduce((acc, curr) => {
          if (obj[curr].toLowerCase() === value.toLowerCase())
            acc = curr
          return acc
        }, "")
  
        if (field === 'currentMode') {
          return getKeyFromValue(modes, text)
        } else if (field === 'errorCode') {
          return getKeyFromValue(errorCodes, text)
        } else if (field === 'rover_offline') {
          return text.toLowerCase() === 'online' ? null : 1
        } else {
          return text
        }
      }
  
      const encodeText = (text, field) => {
        if (field === 'currentMode')
          return modes[text]
        if (field === 'tracking mode')
          return trackingModes[text]
        else if (field === 'tracking disabled' || field === 'comm loss')
          return text === 1 ? 'Yes' : 'No'
        else if (field === 'errorCode'){
          const errors = errorCodesToString(text)
          return errors
        }
        else if (field === 'errorCodeGC')
          return errorCodesGC[text]
        else if (field === 'rover_offline')
          return text === 1 ? 'Offline' : 'Online'
        else
          return text
      }
  
      const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  
      const autoSuggest = text => {
        const columnData = detectColumn(text)
        let results = []
        if (columnData.column !== "") {
          results = miniSearch.autoSuggest(columnData.text, {
              fields: [columnData.field],
              combineWith: "OR",
              fuzzy: 0.2,
              processTerm: (term, _fieldName) => [term.toUpperCase(), term.toLowerCase(), capitalize(term)],
              prefix: true
          })
          .map(t => ({...t, suggestion: columnData.column + ":" + t.suggestion}))
        } else {
          results = miniSearch.autoSuggest(columnData.text, { 
  
            combineWith: "OR",
            fuzzy: 0.2, 
  
            processTerm: (term, _fieldName) => [term.toUpperCase(), term.toLowerCase(), capitalize(term)],
            prefix: true
          })
        }
  
        const t = results.length
        return t > 9 ? results.slice(0, 8).concat (results[t-1]) : results
      }
  
      const searchProducts = (query) => {
        let results = []
        const columnData = detectColumn(query)
        if (columnData.column !== "") {
          results = miniSearch.search(columnData.text, {
            fields: [columnData.field],
            fuzzy: false
          })
        } else {
          results = miniSearch.search(columnData.text, { fuzzy: false })
        }
  
        return results
      }
  
      const search = text => {
  
        const results = searchProducts(text)
        const productsShown = filterProductsShown(results, filterRowAttrs)
        const productsShownSorted = sortProductsShown(sortRowAttrs, productsShown)
  
        setProductsShown(productsShownSorted)
      }
  
      const clearSearch = () => {
        const productsShown = filterProductsShown(products, filterRowAttrs)
        const productsShownSorted = sortProductsShown(sortRowAttrs, productsShown)
        setProductsShown(productsShownSorted)
      }

      const urlPost = (query, data) => {
        let token = getAccessToken()
        return axios({
            url: `custom_metrics?querytype=${query}&restype=json&project=depcom`,
            method: 'POST',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: data
        })
      }

      const saveRealtimeSnapshot = (realtimeMetric,  callback, errorCallback) => {
        const {
            group_id,
            metric,
            tenant,
            metricData,
            minMaxValue, 
            metricType, 
            encodings,
            shared,
            user,
            modeData,
            angleData,
            errorData,
            site,
            note
        } = realtimeMetric

        let requests = []

        const metricInfo = {site, metric, group_id, tenant, metricType, minValue: minMaxValue.minValue || '', maxValue: minMaxValue.maxValue || '', shared, user, note: note.escapeSpecialChars()}
        const r1 = urlPost('metric_add', metricInfo)
        requests.push(r1)

        if (metricData && metricData.length > 0 && metricData[0].metrics) {
            const m = metricData[0].metrics
            const r3 = urlPost('readings_add', {site, metric, group_id, metrics: Object.keys(m).map(t => ({device: t, value: m[t], zone: 'zone', mode: modeData[0].metrics[t], angle: angleData[0].metrics[t], error: parseInt(errorData[0].metrics[t])}))}) //TODO: zoneid
            requests.push(r3)
        }

        Promise.all(requests)
            .then(callback)
            .catch(err => errorCallback(err.message))
      }
    
    return <RealtimeContext.Provider value={{
        products,
        productsShown,
        error,
        rowAttrs,
        filterRowAttrs,
        selectColumn,
        selectFilter,
        closeFilters,
        clearFilters,
        clearSearch,
        deleteFilter,
        toggleFilter,
        sortRowAttrs,
        applySort,
        autoSuggest,
        search,
        query,
        setQuery,
        searchApplied,
        setSearchApplied,
        realtimeMetric,
        setRealtimeMetric,
        queryApplied,
        setQueryApplied,
        
        
        saveRealtimeSnapshot
    }}>
        {props.children}
    </RealtimeContext.Provider>
}

export const useRealtimeContext = ContextFactory("RealtimeContext", RealtimeContext)