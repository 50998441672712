
  const pallette = ["#222", "#308be3","#E91E63","#9C27B0","#00BCD4","#FF9800","#8BC34A","#FFC107","#308be3","#1dd43a","#3cad52","#dfc090"]

const  parseModeData = (modeTotals, modes, criteria) => {
    //const selected = inputdata.filter(e => criteria(e))
    // console.log("mode",modeTotals, modes)
    const labels= []
    const datay = []
    var totals = 0
    const data = modes.forEach((m) => {
        const t = modeTotals.find(t => m.value === t.value)
        //console.log("totalobj", t)
        if (t && t.total > 0){
            const v = (t)?t.total:0
            labels.push(m.label)
            datay.push(v)
    
            totals += v
        }

    
    })
    // console.log("mode 2", labels, datay, totals)

    return { l: labels, d: datay, t: totals }
}

function parseModeData2(inputdata, criteria) {
    const selected = inputdata.filter(e => criteria(e))

    //console.log(selected)
    const labels = []
    const datay = []
    selected.forEach((e) => {
        labels.push(e.label)
        datay.push((e.total) ? parseInt(e.total) : 0)
    })
    const totals = selected.reduce((a, v) => a + ((v.total) ? parseInt(v.total) : 0), 0)
    return { l: labels, d: datay, t: totals }
}

export function ModeDomainToChart(modeTotals, modes, criteria){
    //console.log("mode",modeTotals, modes)
    const pcfactor = 100 / modeTotals.reduce((t,e)=> t + e.total, 0)
    const ds = modeTotals.map((e) => {
        const mode = modes.find(m => m.value === e.value)
        const label = (mode)? mode.label: ""
        return {
            barPercentage: 1,
            label: label,
            data: [(e.total * pcfactor).toFixed(2)],
            backgroundColor: pallette[e.value+1] //'#22aa99'
         }
    })
    return  {
        labels: ["Modes"],
        datasets: ds
    }
}

export function ModeDomainToChartLines(l, d ){
    const data = {
        labels: l,
        datasets: [{
            barPercentage: 0.2,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            data: d,
            backgroundColor: pallette//["red", "blue", "green", "blue", "red", "blue"]
        }]
        
    }
    return data
}
export default parseModeData;