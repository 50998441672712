

export const getHBarOptions = (title, totals, xLabel) => {

    const scalesOption = {
        xAxes: [
            {
                ticks: {
                    min: 0,
                    max: totals,// Your absolute max value
                    callback: function (value) {
                        return (value / totals * 100).toFixed(0) + '%'; // convert it to percentage
                    },
                },
                scaleLabel: {
                    display: true,
                    labelString: xLabel,
                },
            },
        ],
    }
    const options = {
        title: {
            display: true,
            text: title
        },
        legend: { display: false },
        scales: scalesOption
    }
    return options
}
const generateLabels = (chart) => {
    var data = chart.data;
    if (data.labels.length && data.datasets.length) {
        return data.datasets.map(function (ds, i) {
            /*
            var meta = chart.getDatasetMeta(0);
            var ds = data.datasets[i];
            console.log("legends", data.datasets)
            var arc = meta.data[i];
            var custom = arc && arc.custom || {};
            var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
            var arcOpts = chart.options.elements.arc;
            var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
            var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
            var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

            // We get the value of the current label
            var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];
            */
            return {
                // Instead of `text: label,`
                // We add the value to the string
                text: ds.data[0] + "% : " + ds.label ,
                fillStyle: ds.backgroundColor, //fill,
                //strokeStyle: stroke,
                //lineWidth: bw,
                //hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                index: i
            };
        });
    } else {
        return [];
    }
}

export const getStackedBarOptions = () => {
    const axesbase = {
        gridLines: {
            display: false
        },
        ticks: {
            max: 100,
            suggestedMin: 0,
            maxTicksLimit: 6,
            scaleBeginAtZero: false

        },
        stacked: true // this should be set to make the bars stacked
    }

    return {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
            align: "start",
            position: 'bottom', // place legend on the right side of chart
            //onHover: (e, item) => console.log(`Item with text ${item.text} and index ${item.index} hovered`),
            labels: {
                boxWidth: 20,

                generateLabels: generateLabels,
            },
        },
        tooltips: {
            position: 'left',
        },
        scales: {
            xAxes: [axesbase],
            yAxes: [axesbase]
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    //const s = data['datasets'].map(e => e['label'] + ' : ' + e['data'][tooltipItem['index']] + '') 
                    const index = tooltipItem['datasetIndex']
                    const dsdata = data['datasets'][index]
                    const s = dsdata['label'] + ' ' + dsdata['data'][0] + '%';

                    return s;
                }
            }
        }

    }
}

export const getBarChartOptions = () => {
    const axesbase = {
        gridLines: {
            display: false
        },
        ticks: {
            max: 100,
            suggestedMin: 0,
            maxTicksLimit: 3,
            scaleBeginAtZero: false

        },
        stacked: false // this should be set to make the bars stacked
    }

    return {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        legend: {
            align: "start",
            position: 'bottom', // place legend on the right side of chart
            //onHover: (e, item) => console.log(`Item with text ${item.text} and index ${item.index} hovered`),
            labels: {
                boxWidth: 20,

                generateLabels: generateLabels,
            },
        },
        tooltips: {
            position: 'left',
        },
        scales: {
            xAxes: [{
                // id:'xAxis1',
                barPercentage: 0.1,
                ticks: {
                    fontColor:'#fff',
                    callback: function(value, index, values) {
                        const d = new Date(value)
                        return  d.toString().split(" ")[1] +' ' + d.toString().split(" ")[2];
                    }
                }

            },
            ],
            yAxes: [axesbase]
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const index = tooltipItem['datasetIndex']
                    const dsdata = data['datasets'][index]
                    const s = ' '+dsdata['label'] + ' ' + tooltipItem['value'] + '%';
                    return s;
                }
            }
        },
        legend: {
                display: false
            },     
        }

    }
