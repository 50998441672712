import React from "react";
import { errorCodes } from "../modelviewer/heatmap";

const Alert = ({ cellData }) => {
    const data = cellData.split(",").map((v) => (errorCodes[v]))
    return (
        <>
            {
                data.map((d) => (
                    <div>{d}</div>
                ))
            }
        </>
    );
}
export default Alert;
