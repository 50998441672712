import React, { useEffect, useState } from "react";
import nextId from "react-id-generator";
import styled from "styled-components";

const StylesRadio = styled.div`
border-top: solid;
border-width: 1px;
border-color: #e6e6e6;
padding: 8px 0px;
.radio {
    // background: #454857;
    padding: 4px;
    border-radius: 1px;
    // box-shadow: inset 0 0 0 3px rgba(35, 33, 45, 0.3),
        // 0 0 0 3px rgba(185, 185, 185, 0.3);
    position: relative;
}

.radio input {
    width: auto;
    padding: 6px 16px;
    height: 29px;
    appearance: none;
    outline: none;
    cursor: pointer;
    border-radius: 1px;
    line-height: 16px;
    // background: #454857;
    color: #014059;
    font-size: 14px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    transition: all 100ms linear;
}

.radio input:checked {
    background-color: #D6F3FF;
    color: #014059;
    font-weight: 600;
    border-radius: 33px; 
    padding: 6px 16px;
    height: 29px;
}

.radio input:before {
    content: attr(label);
    display: inline-block;
    text-align: center;
    width: 100%;
}
`

const RadioControl = (props) => {
    const [value, setValue] = useState(props.value)
    const name = "radio" + nextId();
    // console.log("Radio default value", value, props.options, props.value)
    function handleChange(event) {
        // console.log(event.target.value);
        setValue(event.target.value)
    }

    useEffect(() => {
        // console.log("after change   ", value)

        props.handleChange(value)

    }, [value])

    return (
        <StylesRadio>
            
                <div class="radio">
                    {props.options.map(o => {
                        const id = nextId()
                        // console.log(o.value, value)
                       return <input label={o.label} type="radio" key={id} id={id} name={name} value={o.value} checked={value.toString() === o.value.toString()} onChange={handleChange} disabled={props.disabled}></input>
                    })}

                </div>
        </StylesRadio>
    )
}


export default RadioControl;