import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import ReactTooltip from "react-tooltip";
import { Table } from 'reactstrap';
import "./table.css";

function columnName(c, i) {
  return i>0? <span><br/>{c}</span>:<span>{c}</span>
  
}
export const makeColumns = rawColumns => {
  return rawColumns.map(column => {
      return { 
          //Header: column, 
          Header: () => column.split('/').map((e,i) => columnName(e,i)),
          accessor: column 
      };
  });
};

const TableContainer = ({ columns, data, trHandler, filter }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  useSortBy
  );

  // AiOutlineRise
  // AiFillCaretUp
  const generateSortingIndicator = column => {
    // return column.isSorted ? (column.isSortedDesc ? "🔽" : " 🔼") : ""
    return column.isSorted ? (column.isSortedDesc ? <img  src="../assets/img/icon/icon-carbon_caret-sort-down.svg" width="24" height="24"  /> : <img src="../assets/img/icon/icon-carbon_caret-sort-up.svg" width="24" height="24"  />) : ""
  }
  const history = useHistory();

  return (
    < >
    <ReactTooltip html={true} multiline={true} />
    
    <Table responsive hover {...getTableProps()} >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {generateSortingIndicator(column)}
                </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {
        rows
        .map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} onClick={() => trHandler(row.original, history)}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })
        }
      </tbody>
    </Table>
    </>
  );
};

export default TableContainer;