import React from 'react';
import styled from 'styled-components'
import { useCriteriaContext } from "../../context/criteria.context";
import criteria, {
    isPortfolio
  } from "../layout/criteria";


const Error = styled.span`
  padding: 0px;
  padding-top: 30px;
  color: red;
`;

const NonSunops = () => {
    const c = useCriteriaContext();
    if (isPortfolio(c)) {
        return (
            <div>
                <Error>The add-on feature option is available at the site level only. Please choose the site to view the subscribed features.</Error>
            </div>
        );
    } else {
        // Render other content here if needed
        return null; // Or return null if nothing else needs to be rendered
    }
};

export default NonSunops;
