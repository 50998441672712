import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useState } from 'react';

export function InfoTooltip (data) {
    const [randomID, setRandomID] = useState(String(Math.random()))
    return <>
        {/* <i className="fas fa-info-circle" data-for={randomID} data-tip={data.content} aria-hidden="true"></i> */}
        <img
                    src="../assets/img/icon/icon-info.svg"
                    data-for={randomID}
                    data-tip={data.content}
                    style={data.style}
                    width="16"
                    height="16"
                    className="tooltip-icon"
                />
        <ReactTooltip className='custom-tooltip' id={randomID} html={true} multiline={true} place={data.toolTipPointer} />
    </>
}