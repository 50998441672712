import React, { useState } from 'react'
import { useNotificationContext } from '../../context/notification.context'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import RemindLater from  './remindLater'
import { AiOutlineClose } from 'react-icons/ai'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'


const Notification = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: 'Montserrat', sans-serif !important;
    padding: 8px;

    .message {
        font-size: 14px;
    }

    .actions {
        
        display: flex;
        flex-direction: row;
        gap: 12px;
        position: relative;
    }

    .actions button {
        background: #014059;
        border: #014059;
        :active {
            background: #014059 !important;
        }
    }

    .actions button.later {
        background: lightgray;
        border: lightgray;
        :active {
            background: lightgray !important;
        }
    }

    .titles {
        display: flex;
        flex-direction: column;
    }
`

export const CloseButton = ({ closeToast, title, action, actionText, timezone, remindLater, notAllowLaterAction }) => {
    const notificationContext = useNotificationContext()
    const { addNotification } = notificationContext

    return <AiOutlineClose 
        style={{fontSize: 18}}
        onClick={() => {
            addNotification({
                title,
                published_at: moment().valueOf(),  
                action, 
                actionText,
                timezone, 
                remindLater,
                id: Math.random(),
                notAllowLaterAction
            })
            closeToast()
        }}
    />
}

export const Message = ({ closeToast, title, action, actionText, timezone, showLater, remindLater, notAllowLaterAction }) => {
    const [snoozeMode, setSnoozeMode] = useState(false)
    
    const notificationContext = useNotificationContext()
    const { addNotification } = notificationContext

    const close = () => {
        addNotification({ 
            title,
            published_at: moment().valueOf(),  
            action,
            actionText, 
            timezone, 
            remindLater,
            id: Math.random(),
            notAllowLaterAction
        })
        closeToast()
    }
    
    return snoozeMode ? <RemindLater 
        timezone={timezone} 
        callback={closeToast} 
        cancel={() => setSnoozeMode(false)}
    /> : <Notification>
      {typeof title === 'object' ? <div className="titles">
            {title.map((t,i) => <span  className="message">{i !== 0 ? " - " : ""}{t}</span>)}
        </div> : <span className="message">{title}</span>}
      <div className="actions">
        {action ? <Button onClick={() => {
            action()
            closeToast()
        }}>{actionText}</Button> : null}
        {remindLater ? <Button onClick={() => setSnoozeMode(true)} className="later" >Remind me later</Button> : null}
        {showLater ? <Button onClick={close} className="later" >Later</Button> : null}
      </div>
    </Notification>
};

