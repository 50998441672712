import React, { useEffect, useMemo, useState , useCallback} from "react";
import ReactDOMServer from 'react-dom/server';
import { CardFull as Card, CardHeader, CardSummary } from "../../components/layout/Card";
import { FetchData2 } from "../../components/model/store.swr";

import { useStore,useStoreActionContext } from '../../context/store.context';
import { useUserContext } from '../../context/user.context';
import useSWR from 'swr';
import styled, { css } from "styled-components";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import 'echarts/lib/component/graphic';
import './energy_production.css'
import { ResponsiveContainer } from "recharts";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { FaChartBar, FaTable } from 'react-icons/fa';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { TooltipHelpContent } from '../../components/pages/tooltip-help-content';
import { InfoTooltip } from "../../model/info-tooltip";
import {DownloadCsv} from "../../model/combined";
import { isPortfolio, isSite, isZone,isDevice,isInverter } from "../../components/layout/criteria";
import { color } from "d3";
import {renderToStaticMarkup  } from 'react-dom/server'
const ChartIcon  = styled(FaChartBar)`
padding: 7px;
background: ${props => props.active ? "#D6F3FF" : "#F7F8F9"};
border: 1px solid ${props => props.active ? "#01668D" : "#E2DFDF"};
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
margin-left: 10px;
`;
const ChartTable  = styled(FaTable)`
padding: 7px;
background: ${props => props.active ? "#D6F3FF" : "#F7F8F9"};
border: 1px solid ${props => props.active ? "#01668D" : "#E2DFDF"};
border-top-right-radius: 4px;
border-bottom-right-radius: 4px;
`;
const urls = [
  { name: "energy_details", url: "expected_actual", path:"/energy",  aggr: "avg" }
]
const timeLineUrls = [
  { name: "energy_details", url: "energy_timeline", path:"/energy",  aggr: "avg", interval:undefined }
]

const ZoneEnergyDetailsColumns = [
  { dataField: 'id', text: 'Inverter Names', editable: false, sort: true },
  { dataField: 'actual_power', text: 'Actual(KWh)', editable: false, sort: true },
  { dataField: 'expected_power', text: 'Expected (KWh)', editable: false, sort: true },
  { dataField: 'variance', text: 'Variance (KWh)', editable: false, sort: true },
];
const PowerTimelineTableColumns = [
  { dataField: 'timestamp', text: 'Date', editable: false, sort: true },
  { dataField: 'actual_power', text: 'Actual  (KWh)', editable: false, sort: true },
  { dataField: 'expected_power', text: 'Expected (KWh)', editable: false, sort: true }
];

const energyDataSortOptions = {
  '': 'Sort By',
  'highprod': 'Higher Production',
  'lowerprod': 'Lower Production',
  'highvar': 'High Variance',
  'lowvar': 'Low Variance'
}



const getPercentage = (obj) => {
  var val1 = obj.actual_power;
  var val2 = obj.expected_power;
  if (val1 > 0 && val2 > 0) {
    var per = (val1 / val2) * 100;

    return per.toFixed(2);
  }
  return 0;
}

const sortJSON = (arr, key, asc=true) => {
  return arr.sort((a, b) => {
    let x = a[key];
    let y = b[key];
    if (asc) { return ((x < y) ? -1 : ((x > y) ? 1 : 0)); }
    else { return ((x > y) ? -1 : ((x < y) ? 1 : 0)); }
  });
}

const energyTooltip = TooltipHelpContent.PowerTimeline;
const tooltip =(toolTipContent)=> (
  <div>
       <h5>{toolTipContent.header}</h5>
        {toolTipContent.body}
  </div>
)

const InfoIcons=(props)=>{
 let prop = JSON.parse(JSON.stringify(props));
 let dataList = prop.data;
 let headers=[];
 if(dataList && dataList.length>0){
   headers = Object.keys(dataList[0]);
 }
 prop.data=prop.data.map((x,index)=>{
  let arr = [headers.length];
  headers.map((y,indx)=>{
    arr[indx]=x[y];
  })
  return arr;
 })
 prop.data.unshift(headers);
return (
  <ul className={"chartAction " + (props.customClass? props.customClass:"")}>
  <li><DownloadCsv data={{filename: props.fileName, data: prop.data, title: props.title }}></DownloadCsv></li>
  <li><InfoTooltip content={ReactDOMServer.renderToString(tooltip(props.ToolTipContent))} toolTipPointer={'right'} /></li>
  </ul>
)
}

const BarChart = React.memo((props) => {
  
  var dataSet = [
    {
      dimensions: props.dimensions,
      source: props.data
    }
  ];
  if(props.sort){
    dataSet.push({
      transform:{
        type: 'sort',
        config: { dimension: props.sortColumn, order: props.sortDirection }
      }
    });
  } else {
    dataSet.push({
      transform:{
        type: 'filter',
        config: { dimension: 'actual_power', ">": -1 }
      }
    });
  }
  var colorList = {expected:'#6D64B9',actual:'#06D6A0'}
  if(props.colorList){
    colorList.expected = props.colorList.expected_power;
    colorList.actual = props.colorList.actual_power;
  }
  
  const option = {
    tooltip: {
      trigger: 'axis',
      confine:true
    },
    legend: {
      data: ['Expected', 'Actual'],
      bottom:12,
      textStyle: {
        fontSize: '10px'
      }
      //padding: [10, 10]
    },
    dataset: dataSet,
    xAxis: {
      type: 'category',
      axisLabel: { interval: 0,rotate: props.data.length>5?23:0 }
    },
    yAxis: [
      {
        type: 'value',
        name: "MWh",
        nameLocation: "center",
        nameTextStyle: {
          padding: [0, 0, 30, 0],
          fontSize: 14,
          fontWeight: 600,
          fontFamily: "Montserrat",
        }
      }
    ],
    series: [
      {
        name: "Expected",
        type: 'bar',
        encode: { x: props.xAxisVal, y: 'expected_power' },
        datasetIndex: 1,
        itemStyle: {
          color:colorList.expected
        }
      },
      {
        name: "Actual",
        type: 'bar',
        encode: { x: props.xAxisVal, y: 'actual_power' },
        datasetIndex: 1,
        itemStyle: {
          color: colorList.actual
        }
      }
    ],
    toolbox: {
      show: true,
      feature: {
      dataZoom: {
        yAxisIndex: 'none'
      },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      { type: 'inside', start: 0, end: 100 },
      { type: 'slider', xAxisIndex: 0, show: true, left: '10%', right: '20%', width: '80%',bottom: '2.5%', height: '3%', start: 0, end: 100 },
  ]
  };
  var bChart = useMemo(() => <ResponsiveContainer width="100%" height={300}>
  <ReactEChartsCore
    echarts={echarts}
    option={option}
    notMerge={true}
    lazyUpdate={true}
    theme={'theme_name'}
    opts={{ renderer: "svg" }}
    style={{ width: "100%" }}
    onEvents= {{
      'click': params => {
        if(props.onChartClick){
          props.onChartClick(params)
        }
        }
    }}
  />
</ResponsiveContainer> , [props.data,props.sortColumn,props.sortDirection]);
  
return bChart;
})

const EnergyDetailTable = React.memo((props) => {
  const data = props.data.map(x => {
    if (x.actual_power && x.expected_power) {
      x.variance = (x.actual_power - x.expected_power).toFixed(2);
    } else x.variance = 0
    return x;
  })
  return (
    <div className="energyTableRecord">
    <BootstrapTable
      keyField='id'
      data={data}
      bootstrap4
      columns={ZoneEnergyDetailsColumns}
      pagination={paginationFactory({
        sizePerPage: 5,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true
      })}
    />
    </div>
  )
})

const PowerTimelineTable = React.memo((props) => {
  
  return (
    <div className="energyTableRecord">
    <BootstrapTable
      keyField='id'
      data={props.data}
      bootstrap4
      columns={PowerTimelineTableColumns}
    />
    </div>
  )
})
const DonutChart = React.memo((props) => {
  var totalPer = useMemo(() => getPercentage(props.data), [props.data]);
  
  const option = {
    title: {
      text: props.data.id,
      left: 'left',
      textStyle: {
        fontSize: 14,
        fontWeight: 600,
        fontFamily: "Montserrat",
      },
      padding: [0, 0, 5, 0]
    },

    graphic: {
      elements: [{
        type: 'text',
        left: 'center',
        top: 'middle',
        z: 999,
        style: {
          text: `${totalPer}%`,
          textAlign: 'center',
          fontSize: 16,
          fontWeight: 600
        }
      }]
    },

    series: [{
      name: 'Energy Production',
      type: 'pie',
      radius: ['50%', '70%'],
      startAngle: 180,
      avoidLabelOverlap: true,
      label: {
        show: false,
        color: '#000',
        fontSize: '80',
        position: 'center'
      },
      emphasis: {
        label: {
          show: false,
          fontSize: '30',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [{
        value: totalPer,
        name: 'Total Production',
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            color: '#6D64B9'
          }
        }
      },
      {
        value: (100 - totalPer),
        name: 'Remaining Production',
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            color: '#06D6A0'
          }
        }
      },
      {
        // make an record to fill the bottom 50%
        value: 100,
        itemStyle: {
          // stop the chart from rendering this piece
          color: 'none',
          decal: {
            symbol: 'none'
          }
        },
        label: {
          show: false
        }
      }
      ]
    }]
  };
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={'theme_name'}
      opts={{ renderer: "svg" }}
      style={{ minHeight: "180px", height: "auto" }}
    />
  )
})
const ZoneEnergyDetails = React.memo((props) => 
{
  const storeAction = useStoreActionContext()
  const criteria = useStore().criteria;
  const criteria2 = Object.assign({}, criteria, { site: props.siteId });
  const [selectedTab, setTab] = useState('chart');
  const [sortValue, setSortValue] = useState('');
  const [energyDataSort,setEnergyDataSort] = useState({sortColumn:"actual_power",sortDirection:"asc"});

  const response = FetchData2(useSWR, criteria2, urls)

  const onChartClick = (e) => {
    if(e && e.data){
      if(e.data.id !== criteria.inverter) {
        storeAction.setInverter(e.data.id);
      }
    }  
}

  const setSortData=(sortkey)=>{
    setSortValue(sortkey);

    switch (sortkey) {
      case 'highprod':
        setEnergyDataSort({sortColumn:"actual_power",sortDirection:"desc"})
        break;
      case 'lowerprod':
        setEnergyDataSort({sortColumn:"actual_power",sortDirection:"asc"})
        break;
      case 'highvar':
        setEnergyDataSort({sortColumn:"variance",sortDirection:"asc"})
        break;
      case 'lowvar':
        setEnergyDataSort({sortColumn:"variance",sortDirection:"desc"})
        break;
      default:
        setEnergyDataSort({sortColumn:"id",sortDirection:"asc"})
        break;
    }
  }

  if (!response.isReady) return(
    <Card>
      <CardHeader loading={true} title={criteria.inverter.length>0?criteria.inverter:props.siteId}> </CardHeader>
    </Card>
  )
  
  if(response.isReady) {
    response.data.energy_details.map(x=>{
      if(x.actual_power>0 && x.expected_power>0){
        x.variance = (x.actual_power - x.expected_power).toFixed(2);
      } else {
        x.variance = 0;
      }
      return x;
    })
  }
  
  return (
    <Card>
      <CardHeader title={criteria.inverter.length>0?criteria.inverter:props.siteId} onClick={props.onBackClick} showBack={props.showBack}> </CardHeader>
      <Card>
        <CardHeader title={"Energy Production (Inverter Level)"}>
          {
             selectedTab === "chart" &&
             <select className="selectChartFilter" disabled={criteria.inverter.length>0} value={sortValue} name="zoneEnergyDataSort" onChange={(event) => setSortData(event.target.value)} >{
              Object.keys(energyDataSortOptions).map(k => <option key={k} value={k}>{energyDataSortOptions[k]}</option>)   
              }
            </select>
          }
         
          <ChartIcon active={selectedTab === "chart"} size={30} onClick={() => setTab("chart")} /><ChartTable active={selectedTab === "table"} size={30} onClick={() => setTab("table")} />
          <InfoIcons fileName="Energy_Production_(Inverter Level)" ToolTipContent={TooltipHelpContent.EnergyProduction} data={response.data.energy_details} title="Energy Production Inverter Level"/>
        </CardHeader>
        {
          selectedTab === "chart"
            ?
           <BarChart data={response.data.energy_details} dimensions={['id', 'actual_power', 'expected_power','variance']} xAxisVal="id" sort={true} sortColumn={energyDataSort.sortColumn} sortDirection={energyDataSort.sortDirection} onChartClick={onChartClick}/>
            :
           <EnergyDetailTable data={response.data.energy_details} />
        }
      </Card>
    </Card>
  )
})

export const EnergyProduction =() => {
  console.log("##loading Energy Production")
  const storeAction = useStoreActionContext()
  const userContext = useUserContext()
  const [energyDataSort,setEnergyDataSort] = useState('');
  const [selectedSite, setSite] = useState("");
  const [siteList, setSiteList] = useState([]);

  const [isEnergyConfigured, setIsEnergyConfigured] = useState(true);
  const criteria = useStore().criteria
  const response = FetchData2(useSWR, criteria, urls)


  useEffect(() => {
    if(response.isReady) {
      response.data.energy_details.map(x=>{
        if(x.actual_power>0 && x.expected_power>0){
          x.variance = (x.actual_power - x.expected_power).toFixed(2);
        } else {
          x.variance = 0;
        }
        x.reporting_device = x.reporting_device !==null ? x.reporting_device : 0;
        x.not_reporting_device = x.not_reporting_device !==null ? x.not_reporting_device : 0;
        return x;
      })
      setSiteList(response.data.energy_details);

    }
  }, [response]);

  useEffect(() => {
    setSite(criteria.site);
  }, [criteria]);
  useEffect(() => {
    if(isSite(criteria) && userContext.inverters.length===0)
    setIsEnergyConfigured(false);
  else 
    setIsEnergyConfigured(true);
  }, [userContext.inverters]);

  useEffect(()=>{
    switch (energyDataSort) {
      case 'highprod':
        setSiteList(prevSiteList=> ([...prevSiteList, ...sortJSON(siteList,"actual_power",false)]))
        break;
      case 'lowerprod':
        setSiteList(prevSiteList=> ([...prevSiteList, ...sortJSON(siteList,"actual_power",true)]))
        break;
      case 'highvar':
        setSiteList(prevSiteList=> ([...prevSiteList, ...sortJSON(siteList,"variance",true)]))
        break;
      case 'lowvar':
        setSiteList(prevSiteList=> ([...prevSiteList, ...sortJSON(siteList,"variance",false)]))
        break;
      default:
        setSiteList(prevSiteList=> ([...prevSiteList, ...sortJSON(siteList,"id",true)]))
        break;
    }

  },[energyDataSort])

  useEffect(() => {
    storeAction.setZone("")
    return () => {
        storeAction.setInverter("")
    }
},[])

  if (!response.isReady) return(
    <Card>
      <CardHeader loading={true} title={"Expected Vs Actual Energy Production"}> </CardHeader>
    </Card>
  )

  const onBackClick = () => {
    if (isInverter(criteria) || isDevice(criteria)){
      storeAction.setSite(criteria.site)
    } else if(isSite(criteria)) {
      storeAction.setPortfolio(criteria.pf)
    }
  }
  const ChangeSite = (value)=>{
    console.log("#=change site")
    storeAction.setSite(value)
  }


  return (
    <>
      {
        (!selectedSite.length > 0 && criteria.site.length===0)
          ?
          <Card>
            <CardHeader title={"Expected Vs Actual Energy Production"}> 
              <select className="selectChartFilter" value={energyDataSort} name="energyDataSort" onChange={(event) => setEnergyDataSort(event.target.value)} >{
                                        Object.keys(energyDataSortOptions).map(k => <option key={k} value={k}>{energyDataSortOptions[k]}</option>)   
                                    }
              </select>
              <InfoIcons fileName="Energy_Production" ToolTipContent={TooltipHelpContent.EnergyProduction} data={siteList} title="Energy Production"/>
            </CardHeader>
            <div className="energyDetailMain">
              {
                siteList.length > 0 ?
                siteList.map(obj => {
                    return (
                      <div className="energyDetailSub" onClick={() => ChangeSite(obj.id)}>
                        <div className="energyDetailBox">
                          { (obj.actual_power!==null && obj.expected_power!==null) ?
                             <div>
                             <DonutChart data={obj} key={obj.id} />
                             <div className="energyChart-val">
                               <ul className="energyChart-actual">
                                 <li><span className="dot-sig" style={{ background: '#6D64B9' }}></span>Actual</li>
                                 <li><span className="energyvalue">{obj.actual_power}</span> MWh</li>
                               </ul>
                               <ul className="energyChart-expected">
                                 <li><span className="dot-sig" style={{ background: '#06D6A0' }}></span>Expected</li>
                                 <li><span className="energyvalue">{obj.expected_power}</span> MWh</li>
                               </ul>
                             </div>
                             <div className="energyChart-val marginTopZero">
                               <ul className="energyChart-actual">
                                 <li><span className="dot-sig" style={{ background: '#cd5423' }}></span>Reporting Inverters</li>
                                 <li><span className="energyvalue">{obj.reporting_device +"/" + (obj.reporting_device + obj.not_reporting_device)}</span></li>
                               </ul>
                             </div>
                           </div>
                           :
                           <div className="siteBox">
                            <label className="siteBoxTitle">{obj.id}</label>
                            <div className="siteBoxContent">Not enough data available for comparison!</div>
                           </div>
                          }
                         
                        </div>
                      </div>
                    )
                  })
                  :
                  <h5 style={{ paddingLeft: "10px" }}>No Data Available</h5>
              }
            </div>
          </Card>
          :
          (
            isEnergyConfigured ? <ZoneEnergyDetails siteId={selectedSite} onBackClick={onBackClick} showBack={true}/> :
            <Card>
            <CardHeader title={selectedSite} onClick={onBackClick} showBack={true}> </CardHeader>
            <h4 style={{color:'red',paddingTop:'10px'}}>Inverters not configured for selected site</h4>
            </Card>
          )
      }
      {isEnergyConfigured && <EnergyTimeLine siteId={selectedSite}/>}
    </>
  )
}

const EnergyTimeLine = React.memo((props) => {
  const criteria = useStore().criteria;
  const [interval,setIntervalTime] = useState((criteria && criteria.interval) ? criteria.interval : 60);

  const criteria2 = Object.assign({}, criteria, {site: props.siteId}) 
  const timeLineUrls2 = [Object.assign(timeLineUrls[0], {interval:interval})]
  var response = FetchData2(useSWR, criteria2, timeLineUrls2,"json");
  const chartTitle =  "Power Timeline" + (props.siteId.length > 0 ? " (Inverter Level)" : "" );
  const intervals = {
                5: '5 mins',
                10: '10 mins',
                15: '15 mins',
                30: '30 mins',
                60: '1 hour',
                720: '12 hours',
                1440: '1 day'
  }
  
  if (!response.isReady) return(
    <Card>
      <CardHeader loading={true} title={chartTitle}> </CardHeader>
    </Card>
  )


  if(response.isReady) {
    var dataList = response.data.energy_details.map(x=>{
      const utcTime = moment.utc(x.timestamp)
      const siteTime = moment.unix(utcTime).format('YYYY-MM-DD HH:mm:ss')
      x.timestamp = siteTime;
      return x;
  });
  }
 
const option = {
  dataset: {
    source: dataList,
    dimensions: ['timestamp','id', 'actual_power', 'expected_power'],
},
  tooltip: {
    trigger: 'axis',
    confine:true
  },
  legend: {
   // bottom:20, padding: [10, 10]
    bottom:12,
  },
  xAxis: [{
    type: 'time',
    nameLocation: 'middle', name: "DateTime",
    nameTextStyle: {padding: [6, 0, 0, 0],bottom: 0, align: 'center', fontWeight: '600', fontSize: 10, color: '#8693AB', fontFamily: 'Montserrat' },
  }],
  yAxis: {
    type: 'value',
    nameLocation: 'middle',
    name:'MW',
    nameTextStyle: {padding: [0, 0, 35, 0], align: 'center', fontWeight: '600', fontSize: 10, color: '#8693AB', fontFamily: 'Montserrat' },
  },
  series: [
    // {
    //   name: 'Id',
    //   type: 'line',
    //   encode: { x: 0, y: 1}
    // },
    {
      name: 'Expected Power',
      type: 'line',
      encode: { x: 0, y: 3},
      itemStyle: {
        color: '#6D64B9'
      },
      symbol:'none'
    },
    {
      name: 'Inverter Actual Power',
      type: 'line',
      encode: { x: 0, y: 2},
      itemStyle: {
        color: '#06D6A0'
      },
      symbol:'none'
    }
  ],
  toolbox: {
    show: true,
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      },
      dataView:{
        show:true,
        optionToContent: function(opt) {
          return  renderToStaticMarkup(<PowerTimelineTable data={dataList} />)
        }
      },
      restore: {},
      saveAsImage: {}
    }
  },
  dataZoom: [
    { type: 'inside', start: 0, end: 100 },
    { type: 'slider', xAxisIndex: 0, show: true, left: '10%', right: '20%', width: '80%',bottom: '2.5%', height: '3%', start: 0, end: 100 },
]
};

  return (
    <Card>
      <CardHeader title={chartTitle}>
        <div className="selectChartAction">
       <select className="selectChartFilter" value={interval} name="interval" onChange={(event) => setIntervalTime(event.target.value)} >{
                                        Object.keys(intervals).map(k => <option key={k}  value={k}>{intervals[k]}</option>)   
                                    }

                                    </select>

                                    <InfoIcons fileName="Power_Timeline" ToolTipContent={TooltipHelpContent.PowerTimeline} data={dataList} title="Power Timeline"/>
                                    </div>
      </CardHeader>
      {
        response.data.energy_details.length > 0 ?
          <ReactEChartsCore
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            opts={{ renderer: "svg" }}
            style={{ height: "50vh", left: 0, top: 0 }}
        />
        :
        <h5 style={{ paddingTop: "15px" }}>No Data Available</h5>
      }
      
    </Card>
  );

})



export default React.memo(EnergyProduction);