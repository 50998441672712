export const previewData = {
  "data": {
    "scales": {
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90
        }
      }
    },
    "xAxis": [
      {
        "name": "Date & Time",
        "nameLocation": "center",
        "nameGap": 20,
        "nameTextStyle": {
          "color": "#014059",
          "fontFamily": "Montserrat",
          "fontSize": 11,
          "fontWeight": "500"
        },
        "axisLine": {
          "lineStyle": {
            "color": "rgba(127,127,127,0.8"
          }
        },
        "axisLabel": {
          "color": "#8693AB",
          "fontSize": 8,
          "fontWeight": "500",
          "fontFamily": "Montserrat",
          "textOrientation": "sideways"
        },
        "scale": true,
        "splitLine": {
          "lineStyle": {
            "width": 1,
            "color": "rgba(127,127,127,0.5"
          }
        },
        "gridIndex": 0,
        "type": "time",
        "show": true
      },
      {
        "gridIndex": 1,
        "show": false,
        "type": "value",
        "inverse": true
      },
      {
        "gridIndex": 2,
        "show": false,
        "type": "value"
      }
    ],
    "yAxis": [
      {
        "nameLocation": "center",
        "nameGap": 40,
        "nameTextStyle": {
          "color": "#0283B6",
          "fontSize": 10,
          "fontWeight": "500"
        },
        "axisLine": {
          "lineStyle": {
            "color": "rgba(127,127,127,0.8"
          }
        },
        "axisLabel": {
          "color": "#8693AB",
          "fontSize": 10,
          "fontWeight": "normal"
        },
        "scale": true,
        "splitLine": {
          "lineStyle": {
            "width": 1,
            "color": "rgba(127,127,127,0.5"
          }
        },
        "position": "left",
        "gridIndex": 0,
        "show": true,
        "name": ""
      },
      {
        "nameLocation": "center",
        "nameGap": 40,
        "nameTextStyle": {
          "color": "#0283B6",
          "fontSize": 10,
          "fontWeight": "500"
        },
        "axisLine": {
          "lineStyle": {
            "color": "rgba(127,127,127,0.8"
          }
        },
        "axisLabel": {
          "color": "#8693AB",
          "fontSize": 10,
          "fontWeight": "normal"
        },
        "scale": true,
        "splitLine": {
          "lineStyle": {
            "width": 1,
            "color": "rgba(127,127,127,0.5"
          }
        },
        "position": "right",
        "gridIndex": 0,
        "show": true,
        "name": ""
      },
      {
        "type": "category",
        "gridIndex": 2,
        "show": false,
        "data": [

        ]
      }
    ],
    "axisPointer": {
      "link": {
        "xAxisIndex": "all"
      },
      "label": {
        "color": "#333",
        "backgroundColor": "#eee"
      },
      "snap": true
    },
    "dataZoom": [
      {
        "type": "inside",
        "start": 0,
        "end": 100
      }
    ],
    "dataset": [
      {
        "source": [
          [
            "2023-06-16 12:00:00",
            34,
            29
          ],

          [
            "2023-06-16 15:00:00",
            36,
            31
          ],


          [
            "2023-06-16 18:00:00",
            35,
            31
          ],


          [
            "2023-06-16 21:00:00",
            35,
            31
          ],

          [
            "2023-06-17 00:00:00",
            38,
            33
          ],


          [
            "2023-06-17 03:00:00",
            36,
            33
          ],


          [
            "2023-06-17 06:00:00",
            35,
            30
          ],


          [
            "2023-06-17 09:00:00",
            35,
            31
          ],

          [
            "2023-06-17 12:00:00",
            36,
            31
          ],

          [
            "2023-06-17 15:00:00",
            36,
            32
          ],

          [
            "2023-06-17 18:00:00",
            38,
            33
          ],


          [
            "2023-06-17 21:00:00",
            37,
            32
          ],

          [
            "2023-06-18 00:00:00",
            37,
            32
          ],


          [
            "2023-06-18 03:00:00",
            38,
            32
          ],


          [
            "2023-06-18 06:00:00",
            36,
            31
          ],


          [
            "2023-06-18 09:00:00",
            35,
            31
          ],

          [
            "2023-06-18 12:00:00",
            37,
            32
          ],


          [
            "2023-06-18 15:00:00",
            36,
            31
          ],


          [
            "2023-06-18 18:00:00",
            37,
            33
          ],


          [
            "2023-06-18 21:00:00",
            38,
            34
          ],
        ],
        "dimensions": [
          "timestamp",
          "0.0",
          "1.0"
        ]
      },
      {
        "source": [
          [
            "2023-06-16 12:00:00",
            30,
            73
          ],

          [
            "2023-06-16 15:00:00",
            32,
            82
          ],


          [
            "2023-06-16 18:00:00",
            31,
            78
          ],


          [
            "2023-06-16 21:00:00",
            32,
            70
          ],

          [
            "2023-06-17 00:00:00",
            33,
            78
          ],



          [
            "2023-06-17 03:00:00",
            33,
            73
          ],



          [
            "2023-06-17 06:00:00",
            31,
            71
          ],



          [
            "2023-06-17 09:00:00",
            31,
            76
          ],



          [
            "2023-06-17 12:00:00",
            31,
            77
          ],



          [
            "2023-06-17 15:00:00",
            33,
            78
          ],


          [
            "2023-06-17 18:00:00",
            34,
            82
          ],


          [
            "2023-06-17 21:00:00",
            32,
            80
          ],



          [
            "2023-06-18 00:00:00",
            33,
            78
          ],



          [
            "2023-06-18 03:00:00",
            33,
            80
          ],



          [
            "2023-06-18 06:00:00",
            32,
            78
          ],



          [
            "2023-06-18 09:00:00",
            31,
            74
          ],



          [
            "2023-06-18 12:00:00",
            33,
            75
          ],



          [
            "2023-06-18 15:00:00",
            31,
            77
          ],

          [
            "2023-06-18 18:00:00",
            33,
            78
          ],


          [
            "2023-06-18 21:00:00",
            34,
            79
          ]

        ],
        "dimensions": [
          "timestamp",
          "0.0",
          "1.0"
        ]
      }
    ],
    "series": [],
    "showMajority": false,
    "showPercent": false,

    "iTimestamp": 0,
    "tooltip": {
      "trigger": "axis",
      "confine": true,
      "backgroundColor": "#B7D0DA",
      "borderColor": "#B7D0DA",
      "axisPointer": {
        "type": "cross",
        "snap": true
      },
      "extraCssText": "max-height:400px; width:170px; overflow:auto; white-space:pre-wrap;",
      "textStyle": {
        "fontSize": 14,
        "color": "#014059",
        "overflow": "break"
      },
      "enterable": true
    }
  }
};

export const seriesLine = [
  {
    "smooth": true,
    "areaStyle": {
    },
    "linestyle": {
      "width": 0.5,
      "opacity": 0.5
    },
    "symbol": "none",
    "symbolSize": 0,
    "type": "line",
    "stack": true,
    "datasetIndex": 0,
    "yAxisIndex": 0,
    "name": "0.0",
    "encode": {
      "x": 0,
      "y": 1,
      "tooltip": [
        1
      ]
    }
  },
  {
    "smooth": true,
    "areaStyle": {
    },
    "linestyle": {
      "width": 0.5,
      "opacity": 0.5
    },
    "symbol": "none",
    "symbolSize": 0,
    "type": "line",
    "stack": true,
    "datasetIndex": 0,
    "yAxisIndex": 0,
    "name": "1.0",
    "encode": {
      "x": 0,
      "y": 2,
      "tooltip": [
        2
      ]
    }
  }
]
export const seriesBar = [
  {
    "smooth": true,
    "areaStyle": {
    },
    "linestyle": {
      "width": 0.5,
      "opacity": 0.5
    },
    "symbol": "none",
    "symbolSize": 0,
    "type": "bar",
    "stack": true,
    "datasetIndex": 0,
    "yAxisIndex": 0,
    "name": "0.0",
    "encode": {
      "x": 0,
      "y": 1,
      "tooltip": [
        1
      ]
    }
  },
  {
    "smooth": true,
    "areaStyle": {
    },
    "linestyle": {
      "width": 0.5,
      "opacity": 0.5
    },
    "symbol": "none",
    "symbolSize": 0,
    "type": "bar",
    "stack": true,
    "datasetIndex": 0,
    "yAxisIndex": 0,
    "name": "1.0",
    "encode": {
      "x": 0,
      "y": 2,
      "tooltip": [
        2
      ]
    }
  }
]