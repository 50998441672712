import { observer } from "mobx-react";
import React, { useState } from "react";
import ContextFactory from "./base.context";

const CommonContext = React.createContext();
const CommonActionContext = React.createContext();


export const useCommonContext = ContextFactory('CommonContext', CommonContext)
export const useCommonActionContext = ContextFactory('CommonActionContext', CommonActionContext)

const CommonContextProvider = (props) => {
    const initState = { priorityChart:[]  } 
    const initActionState = {
        setPriorityChart: setChartPriority
    } 
    function setChartPriority(value,add) {
        const currentChartValue = state.priorityChart;
        if(add)
            setState({...state, priorityChart: [...state.priorityChart, value]});
        else
            setState({...state, priorityChart: []});
    }

    const [state, setState] = useState(initState)
    const [actionState, setActionState] = useState(initActionState)

    return (
        <CommonContext.Provider value={state}>
            <CommonActionContext.Provider value={actionState}>
                {props.children}
            </CommonActionContext.Provider>
        </CommonContext.Provider>
    )
}

export default observer(CommonContextProvider)
