import React from "react";
import ReactDOM from "react-dom";

import Auth from "./Authentication";
import CriteriaContextProvider from "./context/criteria.context";
import DataviewContextProvider from "./context/dataview.context";
import DialogContextProvider from "./context/dialog.context";
import PageContextProvider from "./context/page.context";
import StoreContextProvider from "./context/store.context";
import { PageNotFoundContextProvider } from "./context/pageNotFound.context";
import "./index.css";
//import DevTools from 'mobx-react-devtools';
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./ErrorBoundary";
//import MyApp from './components/model/testApp'
//import { StoreProvider } from './components/model/store';

//https://auth0.com/blog/managing-the-state-of-react-apps-with-mobx/

//const ObservedApp = observer(App);
const rootElement = document.getElementById("root");
// console.log("INdex app")
ReactDOM.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <PageNotFoundContextProvider>
      <StoreContextProvider>
        <CriteriaContextProvider>
          <PageContextProvider>
            <DataviewContextProvider>
              <DialogContextProvider>
                        <Auth />
              </DialogContextProvider>
            </DataviewContextProvider>
          </PageContextProvider>
        </CriteriaContextProvider>
      </StoreContextProvider>
    </PageNotFoundContextProvider>,
  </ErrorBoundary>,
  document.getElementById("root")

  // </React.StrictMode>,
  // rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
