import React from 'react'
import { toast } from 'react-toastify';
import { Message, CloseButton } from "./message"

export const showNotification = (title, action, actionText, timezone, showLater, remindLater, notAllowLaterAction) => {
    toast(<Message  
        title={title}
        timezone={timezone}
        action={action}
        actionText={actionText}
        showLater={showLater}
        remindLater={remindLater}
        notAllowLaterAction={notAllowLaterAction}
    />, {
        closeOnClick: false,
        hideProgressBar: true,
        autoClose: false,
        closeButton: ({closeToast}) => <CloseButton
            closeToast={closeToast}
            title={title}
            timezone={timezone}
            action={action}
            actionText={actionText}
            remindLater={remindLater}
            notAllowLaterAction={notAllowLaterAction}
        />
    });
}