import React from "react";
/* eslint-disable */
const ContextFactory = (name, context) => {
    return () => {
        const ctx = React.useContext(context)
        if (ctx === undefined) {
            throw new Error(`use${name}Context must be used withing a ${name}ContextProvider.`)
        }
        return ctx
    }
}
/* eslint-enable */


export default ContextFactory;