import * as d3 from 'd3';
import $ from 'jquery';
import React, { useEffect, useRef } from 'react';
import nextId from 'react-id-generator';
import './CommonChartStyle.css';

function SingleStackedBar({height, data, heading}){
    const ref = useRef();
    var colors = {"OFFLINE": '#af4a4a', "SNOW-STOW": '#377eb8',"MANUAL": '#984ea3',"WIND-STOW": '#ffff33',"AUTO": '#4daf4a',"EMERGENCY-STOW": '#4aaf9b'}
    const getColor = data_slice => colors[data_slice.label] ? colors[data_slice.label] : colors[data_slice.backgroundColor]

    var width = $('.d3-chart-ref').width() - 50;
    var margin = {top: 10, right: 10, bottom: 5, left: 10}
    , width = width - margin.left - margin.right // Use the window's width 
    , height = height - margin.top - margin.bottom; // Use the window's height

    
    useEffect(() => {
      $('.stackedbarlegend')
      .on({
        mouseover: function() {
          $('.rect-stacked').addClass('rect-stacked-bar')
          if (this.getAttribute('map-class')) {
            $('.'+this.getAttribute('map-class')).removeClass('rect-stacked-bar')
          }
        }, mouseleave: function() {
          $('.rect-stacked').removeClass('rect-stacked-bar')
        }
      })
      const svg = d3.select(ref.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr('viewBox','0 0 '+ (width + margin.left + margin.right) +' '+ (height + margin.top + margin.bottom))
      .attr('preserveAspectRatio','xMinYMin')
    }, []);

    useEffect(() => {
      draw(width, height);

      var elem = $(".d3-chart-ref")[0]; 

      let resizeObserver = new ResizeObserver(() => {
        var height = $(ref.current).height()
        var width = $('.d3-chart-ref').width() - 50;
        
        var margin = {top: 10, right: 50, bottom: 5, left: 10}
        , width = width - margin.left - margin.right // Use the window's width 
        , height = height - margin.top - margin.bottom; // Use the window's height
        
        const svg = d3.select(ref.current)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr('viewBox','0 0 '+ (width + margin.left + margin.right) +' '+ (height + margin.top + margin.bottom))
        .attr('preserveAspectRatio','xMinYMin')

        draw(width, height)
      }); 
      if(elem)
        resizeObserver.observe(elem);

    }, [data]);

    const draw = (width, height) => {
      var newFontSize = width > 300 ? 10 : 9
      const total = d3.sum(data, d => parseFloat(d.data[0]))

      var xScale = d3.scaleLinear()
      .domain([0, 100]) // input
      .range([0, width + margin.left + margin.right]); // output

      d3.select(ref.current).selectAll("g").remove()
      const svg = d3.select(ref.current)
        .append("g")
        .attr("transform", "translate(0, 0)")

      var barHeight = 30;  
      var stack_width = 0
      
      data.map(function(d,i){
        // Add rect stacked bar
        svg.append('rect')
          .attr('class', 'rect-stacked '+d.label.toLowerCase().replace(/\s/g, ''))
          .attr('x', stack_width)
          .attr('y', 0)
          .attr('height', barHeight)
          .attr('width', xScale((parseFloat(d.data[0])/total) *100))
          .style('fill', getColor(d))
        stack_width = stack_width + xScale((parseFloat(d.data[0])/total) *100)

      })
    }

    return (
      <div className="row chart singlestackeddiv" >
        <div class="col-md-12"> <svg ref={ref}></svg></div>
        <div class="col-md-12 legend1"> 
          { data.map(function(d){
            return(<span class="legend2 pr-2 stackedbarlegend" key={nextId()} map-class={d.label.toLowerCase().replace(/\s/g, '')}><span class="key-square" key={nextId()} style={{backgroundColor: getColor(d)}}></span>{parseFloat(d.data[0])+'% : '+d.label}</span>)
          })}
        </div>
      </div>
    )

}

export default SingleStackedBar;