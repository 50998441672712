import { observable, toJS } from 'mobx';
import React, { useEffect, useMemo, useState } from "react";
import { getCriteriaFromLocation, getCriteriaFromTime, getUrlFromCriteria } from '../components/layout/criteria';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { setWindowLocation } from "../navigation/link";
import { useLocation } from '../navigation/use-location';
import ContextFactory from "./base.context";
import { usePageNotFoundContext } from './pageNotFound.context';

const StoreContext = React.createContext();
const StoreActionContext = React.createContext();
const defaultStore = {} //{time: '', domain: ''}
const CriteriaTimeFormat = 'YYYYMMDDTHH00'

export const useStore = ContextFactory('StoreContext', StoreContext)
export const useStoreActionContext = ContextFactory('StoreActionContext', StoreActionContext)

const StoreContextProvider = (props) => {

    const pageNotFoundContext = usePageNotFoundContext()
    // const getRootStore = (location) => {
       
    //     const criteriaStore = observable(getCriteriaFromLocation(location.search))
    //     // const criteriaStore = getCriteriaFromLocation(location.search)
    //     const navigationStore = observable({ contenttype: "summary", pagetype: "pf" })
    //     // const navigationStore = { contenttype: "summary", pagetype: "pf" }

    //     return {criteria: criteriaStore, navigation: navigationStore}
      
    // } 

    const getDatesFromTimeType = (timeType, timezone) => {

        const timeParts = timeType.split("-")
        let number, type
        if (timeParts.length > 1) {
            number = parseInt(timeParts[0])
            type = timeParts[1] + "s"
        } else {
            number = 7
            type = "days"
        }
        const startDate = moment().subtract(number, type).tz(timezone).format(CriteriaTimeFormat)
        const endDate = moment().tz(timezone).format(CriteriaTimeFormat)

        return { startDate, endDate }
    }

    const updateTimezone = (newCriteria, pTimetype, preset) => {
        const timetype = newCriteria.timetype || pTimetype
        const presets = ['7-day', '2-day'   , '1-day', '12-hour', '6-hour', '1-hour']
        let newTz, tzType
        if (newCriteria.site === "") {
            newTz = "Etc/UTC"
            tzType =   "utc"
        }
        const timezonesJson = localStorage.getItem('timezones')
        const timezones = timezonesJson ? JSON.parse(timezonesJson) : []
        if (timezones && timezones.length > 0) {
            const site = timezones.filter(site => site.site_id === newCriteria.site)
            if (site.length > 0 && site[0].timezone) {
                newTz = site[0].timezone
                tzType =   "site"
            }
            else {
                newTz = "Etc/UTC"
                tzType =   "utc"
            }

        } else {
            newTz = "Etc/UTC"
            tzType =   "utc"
        }
        
        // if (t !== prevTz) {
            if (presets.indexOf(preset) >= 0) {
                // const start = moment.tz(Ts, prevTz || "Etc/UTC")
                // const end = moment.tz(Te, prevTz || "Etc/UTC")
                // const startNew = start.tz(newTz).format(CriteriaTimeFormat)
                // const endNew = end.tz(newTz).format(CriteriaTimeFormat)
                const { startDate, endDate } = getDatesFromTimeType(preset, newTz)
                
                moment.tz.setDefault(newTz)
                return { ...newCriteria, tz: tzType, Ts: startDate, Te: endDate, tzvalue: newTz, preset}
            } else {
                moment.tz.setDefault(newTz)
                return { ...newCriteria, tz: tzType, tzValue: newTz, preset }
            }
        // }
    }


    function updateStoreCriteria(partCriteria){
        const cs = toJS(criteria)
        if (partCriteria.site !== undefined && cs.site !== partCriteria.site)
            partCriteria = updateTimezone(partCriteria,  cs.timetype, partCriteria.preset || cs.preset)
        else
            partCriteria = { ...partCriteria, tz: cs.site ? "site" : "utc", preset: partCriteria.preset || cs.preset }
        console.log("% %% %% %% %% %% %% %% %% %  updating store criteria ", cs, partCriteria)
        setWindowLocation(getUrlFromCriteria(Object.assign(cs, partCriteria)))
    }

    const setPortfolio = (id) => {
        // store.criteria = Object.assign(store.criteria, { site: "", zone: "", device: "", grouptype: "sites" })
        updateStoreCriteria( { pf: id, site: "", zone: "", device: "", grouptype: "sites" , inverter: ""})
    }


    const setSite = (id) => {
        // store.criteria = Object.assign(store.criteria, { site: id, zone: "", device: "", grouptype: "zones" })
        updateStoreCriteria( { site: id, zone: "", device: "", grouptype: "zones", inverter: "" })
    }

    const setSiteAbs = (pfid, siteid) => {
        // store.criteria = Object.assign(store.criteria, { site: siteid, zone: zoneid, device: "", grouptype: "rcs" })
        updateStoreCriteria( { pf: pfid, site: siteid, zone: "", device: "", grouptype: "zones", inverter: "" })
    }


    const setZone = (id) => {
        // store.criteria = Object.assign(store.criteria, { zone: id, device: "", grouptype: "rcs" })
        updateStoreCriteria( { zone: id, device: "", grouptype: "rcs", inverter: "" })

    }

    const setInverter = (id) => {
        updateStoreCriteria( { inverter: id, zone: "", device: "" , grouptype: "" })
    }

    const setZoneAbs = (siteid, zoneid) => {
        // store.criteria = Object.assign(store.criteria, { site: siteid, zone: zoneid, device: "", grouptype: "rcs" })
        updateStoreCriteria( { site: siteid, zone: zoneid, device: "", grouptype: "rcs" })
    }

    const setDevice = (id) => {
        // store.criteria = Object.assign(store.criteria, { device: id, grouptype: "rcs" })
        updateStoreCriteria( { device: id, grouptype: "rcs" })

    }

    const setDeviceAbs = (siteid, zoneid, deviceid) => {
        // store.criteria = Object.assign(store.criteria, { site: siteid, zone: zoneid, device: deviceid, grouptype: "rcs" })
        updateStoreCriteria( { site: siteid, zone: zoneid, device: deviceid, grouptype: "rcs" })

    }
    const setDomain = (fqDomain) => {
        console.log(fqDomain)
        const arr = fqDomain.split("/")
        // split domain string as Site/zone/device

        setDeviceAbs(arr[0], arr[1], arr[2])
    }
    function setTime(start, end, preset){
        // console.log('#########################', start, end, '###########################')
        updateStoreCriteria(getCriteriaFromTime(start, end, null, preset))
    }
    function setTenant(tenant){
        
        updateStoreCriteria({tenant: tenant})
    }

    function setStoreContext (criteria) {
        // console.log('>>>>>>>>>>>>>>>> ', criteria, ' <<<<<<<<<<<<')
        store.criteria = Object.assign(store.criteria, criteria)
        // store.criteria.actions.setCriteria( Object.assign(store.criteria, criteria))

        
    }


    const initActionState = {
        setStoreContext: setStoreContext,
        setPortfolio: setPortfolio,
        setSite: setSite,
        setZone: setZone,
        setSiteAbs: setSiteAbs,
        setZoneAbs: setZoneAbs,
        setDevice: setDevice,
        setDeviceAbs: setDeviceAbs,
        setDomain: setDomain,
        setTime: setTime,
        setTenant: setTenant,
        setInverter: setInverter
    }

    const location = useLocation()
    const criteria = useMemo(() => getCriteria(), [location])
    const navFilter = useMemo(() => {} /*getNavFilter()*/, [criteria])
    

    // const [store, setStore] = useState({criteria: observable(criteria), navigation: observable({})})
    const [actionState, setActionState] = useState(initActionState)
    const store = useMemo(() => getStore(), [criteria])
    useEffect(() => {
        // console.log("########### STORE CHANGED ########################  ###############", toJS( store.criteria))
    }, [store])

    function getCriteria(){
        console.log("STORE-CRITERIA: LOCATION CHANGED ", location)
        const cs = getCriteriaFromLocation(location, pageNotFoundContext.isPageNotFound)
        console.log(" CRITERIA CRITERIA %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% ", location, cs)
        return cs
    }

    function hasUserNav(){
        return location && 'usernav' in location 
    }
    function isUserNav(){
        return location.usernav
    }

    function getNavFilter(){
        const usernav = window.sessionStorage.getItem("usernav")
        if (usernav && usernav != ""){
            console.log(usernav)
            return JSON.parse(usernav)
        }
        // console.log(navFilter)
        // if (navFilter && 'location' in navFilter && navFilter.location != "" ){
        //     return navFilter
        // }
        
        if (hasUserNav() && !isUserNav()){
            var filter = {}
            filter['location'] = getUrlFromCriteria(criteria)
            if (criteria.pf != "" && criteria.site != "" ) { filter['pf'] = criteria.pf } 
            if (criteria.site != "" ) { filter['site'] = criteria.site } 
            if (criteria.zone != "" ) { filter['zone'] = criteria.zone } 
            window.sessionStorage.setItem("usernav", JSON.stringify(filter))
            console.log("FILLLLTEREEREREE ", filter)
            return filter
        }
        return null
    }

    function getStore(){
        // console.log(" STORE STORE CRITERIA ", toJS(criteria))
        return {criteria: observable(criteria), navigation: observable({}), navFilter: navFilter}
    }
    return (
        <StoreContext.Provider value={store}>
            <StoreActionContext.Provider value={actionState}>
                {props.children}
            </StoreActionContext.Provider>
        </StoreContext.Provider>
    )
}

export default StoreContextProvider;
