import React from 'react'
import ReactSlider from 'react-slider'

import styled from 'styled-components';

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`;

const StyledThumb = styled.div`
    height: 30px;
    line-height: 30px;
    width: 60px;
    text-align: center;
    background-color: #014059;
    color: #fff;
    border-radius: 999px;
    cursor: grab;
    top: -3px;
`;

const StyledMark = styled.span`
    color: #014059;
    background: none;
    border-left: ${props => (props.style.left === 0 ? 'none' : '1px dashed #014059')};
    opacity: 80%;
`;

const Thumb = (props, state) => <StyledThumb {...props}>{props.label}</StyledThumb>;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => (props.index === 2 ? '#f00' : props.index === 1 ? '#F7F8F9' : '#F7F8F9')};
    border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

export const SliderControl = props => {
    const label = props.label
    const marks  = props.marks
    
    return <StyledSlider 
        defaultValue={[50]} 
        renderTrack={Track} 
        value={props.value}
        label={props.label}
        renderMark={(props, state) => {
            let value
            const count = Object.keys(marks).length
            if (count > 36) {
                value = ''
            } else if (count > 14) {
                value = marks[props.key].split(" ")[0]
            } else {
                value = marks[props.key]
            }
            return <StyledMark {...props}>{value}</StyledMark>
        }}
        renderThumb={(props, state) => {
            return <StyledThumb {...props}>{label}</StyledThumb>;
        }} 
        onChange={props.onChange}
        min={props.minValue}
        max={props.maxValue}
        marks={Object.keys(props.marks)}
        step={props.step}
    /> 
};