import _ from "lodash";
import moment from 'moment';
import useSWR from 'swr';
import { useStore } from "../../context/store.context";
import { getAccessToken } from '../auth/userinfo';
import { getGroupByType, getIntervalByCriteria, MIN_INTERVAL, URLCriteriaTimeFormat } from "../layout/criteria";
import { compressAndEncode } from './utils';


//let token = getAccessToken();
function formatUrl2(){
    const u = "http://localhost:5090/query" 
    //console.log(u)
    return u 
}

function formatUrl(uri) {
    const u = "http://localhost:5080/" + compressAndEncode(uri)
    //console.log(u)
    return u
}


const fetcher = (...args) => fetch(...args,{headers: { 'x-access-token': getAccessToken(), "Content-Type": "application/json" },cache: "no-store"}).then((res) => res.json())

const FetchData = (swr, urls) => {
    var alldata = {}
    var isReady = true
    urls.forEach((e) => {
        const uri = formatUrl(e.url)
        const { data: jsondata, error: err} = swr(uri, fetcher)
        isReady = isReady && (!err) && (jsondata)
        //console.log(jsondata, err)
        alldata[e.name] = jsondata
    })
    return {data: alldata, isReady: isReady}
}



function encodeQueryString(params, excludeFields) {
    const keys = Object.keys(params)
    return keys.length
        ? "?" + keys
            .map(key => {
                const value = excludeFields && excludeFields.indexOf(key) < 0 ? params[key] : ''
                if (value && typeof value === 'object'  ){
                    return key + "-" + encodeQueryString(value)
                }else{
                    return encodeURIComponent(key)  + "=" + encodeURIComponent(value)
                }
            } )
            .join("&")
        : ""
}

function encodeQueryString2(params, excludeFields) {
    const keys = Object.keys(params)
    var params= keys.length
        ? keys
            .map(key => {
                const value = excludeFields.indexOf(key) < 0 ? params[key] : ''
                if (value && typeof value === 'object'  ){
                    return key + "-" + encodeQueryString(value)
                }else{
                    return encodeURIComponent(key)  + "=" + encodeURIComponent(value)
                }
            } )
            .join("&")
        : ""

        return "?"+compressAndEncode(params);
}



export const getIntervalByUrl2 = (refInterval, urlobj) => {
    var interval = refInterval
    if ('slice' in urlobj && urlobj.slice !== undefined) {
        interval = urlobj.slice
    }
    if ('interval' in urlobj && urlobj.interval !== undefined) {
        interval = urlobj.interval
    }

    return interval >= MIN_INTERVAL ? interval : MIN_INTERVAL;
}

export const getIntervalByUrl = (t, urlobj) => {


    // Older Time selection code below
    if ('slice' in urlobj) {
        const resolution = urlobj.slice;
        if (t && resolution > 0 && resolution < t.minimum) return t.minimum;
        if (t && resolution >= t.minimum && resolution <= t.interval) return resolution;

        if (t && resolution===-1) return ""; //t.interval;    // is obvious
        // if (t && resolution > 0 && resolution > t.interval) return t.interval; // is obvious  
    }
    if (t) return t.interval;
    return 60; // if no resolution matches and timetype is bad return 60 minutes.. this is configuration error
    
}

const customGroupType = (pl, urlobj) => {
    
    if ('grouptype' in urlobj) {
        const grpType = getGroupByType(urlobj.grouptype)
        //console.log("overring grouptype", urlobj.grouptype, grpType)

        if (grpType) return grpType.type
    }
    return pl.grouptype
}


const deviceByMetricType = (pl, urlobj) => {
    
    if ('metricType' in urlobj) {
        if (urlobj.metricType === 'Z'){
            return "ZC"
        }
        
    }
    return pl.device
}

export const GetFQChunkURLs = (urls, criteria, restype="json") => {
    //20210621T1722
    const startDate= moment(criteria['Ts'])
    const endDate= moment(criteria['Te'])
    const duration = moment.duration(24, 'hours')
    var dstart = startDate.add(moment.duration(0, 'hours'))
    var dend = dstart.add(duration)
    const result = []
    do {
        dend = (dend>endDate) ? endDate: dstart.add(duration)
        var cs = Object.assign({}, criteria, {Ts: dstart.format(URLCriteriaTimeFormat), Te: dend.format(URLCriteriaTimeFormat)})
        var m = _.values(_.merge({}, urls.map(u => {
            var o = Object.assign({}, u, { url: GetFQURL(u, cs, restype)})
            //console.log(o)
            return o
        })))
        //console.log(m)
        result.push(m)
        dstart = dend.add(moment.duration(1, 'seconds'))
    } while(dend < endDate )
    
    // console.log("Chuncked", result)
    return result
    

}

function getResType(url, def_restype){
    return url.format ? url.format : def_restype 
}

let previousTimeCriteria = null; 
let currentTimeFormat = null
let previousSite = null

function getURLTimeCriteria(cs) {
    if (previousTimeCriteria && 
        cs['Ts'] === previousTimeCriteria['Ts'] &&
        cs['Te'] === previousTimeCriteria['Te'] && 
        previousSite === cs.site) {
            return currentTimeFormat;
    } 
    else {
        let currentTimeObj = {Ts: cs['Ts'], Te: cs['Te']};
        previousTimeCriteria = {...currentTimeObj};

        previousSite = cs.site
        const o =  {Ts: moment(cs['Ts']).utc().format(URLCriteriaTimeFormat), Te: moment(cs['Te']).utc().format(URLCriteriaTimeFormat)}

        // Update the current time criteria
        currentTimeFormat = {...o};
        return o;
    }
}

export const GetFQURLs = (urls, criteria, restype="json", interval, title, tenant, logData, reportData) => {
    const excludeUrlsForLogs = ['device_summary', 'device_summary_combined', 'mode_labels', "mode_labels_combined"]
    console.log("urls in fns",urls)
    return urls.map(u =>  Object.assign({}, u, { url: GetFQURL(u, criteria, getResType(u, restype), false, [], interval, title, tenant, excludeUrlsForLogs.includes(u.url)  ? {} : logData, reportData)}))
}
export const GetFQURL = (urlObj, criteria, restype="json", nocache=false, excludeFields=[], interval,title, tenant, logData={}, reportData={}) => {
    //const criteria = useStore().criteria
    // debugger
    // console.log(toJS(criteria))
    let plobj  
    if(!_.isEmpty(reportData)){
        plobj = Object.assign({}, criteria);
        plobj = {...plobj, ...reportData}
    }else{
        plobj = Object.assign({}, criteria, getURLTimeCriteria(criteria));
    }

    if (urlObj.url ==='all_unique_metrics') {
        currentTimeFormat = null

        previousTimeCriteria = null; 
        plobj =  Object.assign({}, criteria); 
    } 
    
    if(!_.isEmpty(logData)){
        plobj['isLogData'] = true
        plobj['featureId'] = logData['featureId']
        plobj['token'] = logData['token']
        plobj['usecase'] = logData['usecase']
        plobj['userId'] = logData['userId']
        plobj['viewCategory'] = logData['viewCategory']
        plobj['viewId'] = logData['viewId']
        plobj['details'] = logData.details ? logData.details : '{}'
        plobj['metrics'] = ''
        if(logData.userAction){
            plobj['userAction'] = 'true'
        }
          
    }
    
    plobj.interval = interval ? interval : getIntervalByUrl2(getIntervalByCriteria(plobj), urlObj)
    plobj.querytype = urlObj.url
    if (urlObj.nocache) { plobj.force = "yes" }
    plobj.grouptype = customGroupType(plobj, urlObj)
    plobj["aggr"] = ('aggr' in urlObj)? urlObj.aggr : "last"

    if(!_.isEmpty(reportData)){
        if(reportData.view === 'Downloads'){
            plobj["aggr"] = reportData['aggr']
        } 
    }
    if ('metricGroup' in urlObj){
        plobj["MTGRP"] = urlObj.metricGroup 
    }
    
    if ('tenant' in criteria && criteria.tenant)  {
        plobj['tnt'] = criteria.tenant
    }

    if(tenant && (title === 'Errors Summary' || title === 'Modes Summary')){
        plobj['tenant'] = tenant
    }
    // getTenant(uc, plobj)
    plobj['restype'] = restype
    if ('metricType' in urlObj) {
        plobj['mtype'] = urlObj.metricType 
        plobj.device = deviceByMetricType(plobj, urlObj)
    }
    if (nocache) {
        plobj["force"] = "yes"
    }

    // console.log('url criteria', plobj)
    //console.log("/query" + encodeQueryString(plobj))
    let path = urlObj.path ? urlObj.path : "/query"
    if(!_.isEmpty(reportData) && reportData.isGenerateReport){
        path = urlObj.generateReportPath
    }else if(!_.isEmpty(reportData) && reportData.isGenerateReport === false && reportData.editReport){
        path = urlObj.editReportPath
    }else if(!_.isEmpty(reportData) && reportData.isGenerateReport === false){
        path = urlObj.scheduleReportPath
    }

    console.log("path....",path,urlObj)
    const url =  path + encodeQueryString2(plobj, excludeFields)
    // console.log(">>>>>>>>>>>>>> >>>>>>>>>>>>>>>>> ", urlObj, plobj, url)
    return url

}

const fetcherText = (url) => fetch(url,{headers: { 'x-access-token': getAccessToken(), "Content-Type": "application/json" }}).then((r) => r.text());

export const FetchData2 = (swr, criteria2, urls, restype="json",logData={}) => { //(swr, criteria, urls) => {
    var alldata = {}
    var isReady = true
    const criteria =(criteria2 !=undefined)? criteria2 : useStore().criteria;
    const options = {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        suspense: false,
        revalidateOnReconnect: false
      }
      

    urls.map((e) => {
        const url2 = GetFQURL(e, criteria, restype, false,[],null,null,null,logData)
        // console.log(url2)
        const { data: results, error: err} = swr(url2, (restype === "json") ? fetcher: fetcherText,options)
        if (err) {
            //console.log(err)
        }
          
        isReady = isReady && (!err) && (results)
        alldata[e.name] = results
    })
    return {data: alldata, isReady: isReady}
}

export function postJSONData(url, data, onSuccess) {
    let token = getAccessToken();
    const dataToSend = JSON.stringify(data);
    fetch(url, {
        credentials: "same-origin",
        mode: "same-origin",
        method: "post",
        headers: { 'x-access-token': token, "Content-Type": "application/json" },
        body: dataToSend
    })
        .then(resp => {
            if (resp.status === 200) {
                const response = resp.json()
                // console.log(response)
                return response
            } else {
                console.log("Status: " + resp.status)
                return Promise.reject("server")
            }
        })
        .then(dataJson => {
            // console.log(dataJson)
            onSuccess(dataJson)
        })
        .catch(err => {
            console.log(err)

            if (err === "server") return
        })

}


export const FetchDataText = (urls) => { 
    const criteria = useStore().criteria
    const swr = useSWR

    return FetchData2(swr, criteria, urls, "csv")
}
  
export default FetchData;