import React from 'react';
import { Bar } from 'react-chartjs-2';

const StackedChart1 = ({ data }) => {
  const chartData = {
    labels: data.map(entry => entry.user_id),
    datasets: [
      {
        label: 'Total Time (seconds)',
        data: data.map(entry => entry.total_time),
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'User ID',
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Total Time (seconds)',
          },
        },
      ],
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem, chartData) => {
          const dataIndex = tooltipItem.index;
          const dataset = chartData.datasets[tooltipItem.datasetIndex];
          const value = dataset ? dataset.data[dataIndex] : undefined;
          const userEntry = data[dataIndex];

          if (userEntry && value !== undefined) {
            return [
              `Date: ${userEntry.start_date}`,
              `Total Time: ${value.toFixed ? value.toFixed(2) : value} seconds`,
              `Number of Sessions: ${userEntry.number_of_sessions}`,
            ];
          } else {
            return ['No data available'];
          }
        },
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

export default StackedChart1;
