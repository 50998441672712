import React from 'react'
import styled from 'styled-components'
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { AiOutlineDownload } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { Button } from 'react-bootstrap'
import { CSVLink } from "react-csv";
import { useCriteriaContext } from "../../context/criteria.context"

import { useRealtimeContext } from "../../context/realtime.context"
import moment from 'moment'
import { LessEqualStencilFunc } from 'three/src/constants.js';


const RealtimeDownload = styled.div`
    display: inline-block;
    margin-left: 56px;
    position: absolute;
    top: 8px;
    right: 20px;
    

    .download-button {
        background: white;
        cursor: pointer;
        border: white;
        width: 48px;
        height: 34px;
        color: #014059;

        :active {
            background: #014059 !important;
            color: white;
        }

        :disabled {
            background: white !important;
            color: #014059;
            cursor: auto;
        }


        .icon {
            font-size: 24px;
            margin-top: -3px;
            margin-right: 2px;
        }
    }
`

export default props => {

    const realtimeContext = useRealtimeContext()
    const { productsShown, rowAttrs } = realtimeContext

    const criteria = useCriteriaContext()
    let headers = rowAttrs
        .filter(t => t.selected && !t.gc)
        .map(t => ({ label: t.text, key: t.dataField }))


    const formatters = rowAttrs
        .filter(t => t.selected && !t.gc)
        .reduce((acc, curr) => {
            if (curr.formatter2 || curr.formatter) {
                acc[curr.dataField] = curr.formatter2 ? curr.formatter2 : curr.formatter
            }

            return acc
        }, {})

    let productsShownModified = productsShown.map(t => {
        return Object.keys(t).reduce((acc, curr) => {
            acc[curr] = formatters[curr] ? formatters[curr](t[curr]) : t[curr]
            return acc
        }, {})
    })

    let arr = []
    productsShownModified.map(obj => {
        const parts = obj.errorCode.split(','); // Split the string by spaces
        if(!arr.includes(parts)){
            arr = [...arr, ...parts]
        }
        // return parts.length >= 1 ? parts.slice(-1)[0] : parts[0]; // Get the last word
    });

    console.log("arr++++",arr)

    arr = [... new Set(arr)]

    productsShownModified = productsShownModified.map(p => {
        arr.map(v => {
            const parts = p.errorCode.split(',')
            p[v] = parts.includes(v)
        })
        return p
    })

    headers = [...headers, ...arr.map(v => ({ label: v, key: v }))]

    const filename = `LiveStatus_${criteria.site}_${moment().format("DD_MM_YYYY_HH_mm")}.xlsx`

    const handleDownload = () => {
        // Sheet 2: Array of Object

        const groupedProducts = productsShownModified.reduce((acc, item) => {
            // If the category doesn't exist, initialize it
            if (!acc[item.site_id]) {
                acc[item.site_id] = [];
            }
            // Push the current item into the category array
            acc[item.site_id].push(item);
            return acc;
        }, {});

        console.log("grouped products.....",groupedProducts)

        const issueSummary = []

        Object.keys(groupedProducts).forEach(key => {
            issueSummary.push([{v: key, s: { font: { bold: true }, fill: { fgColor: { rgb: "A6D785" } } }}])
            issueSummary.push(['Trackers in Auto', groupedProducts[key].filter(d => d.currentMode === 'AUTO').length])
            issueSummary.push(['Trackers need field troubleshooting', groupedProducts[key].filter(d => d['Minimum Battery Voltage fault'] === true || d['Motor Stall Fault'] === true || 
                d['Over Current Fault'] === true || d['Mechanical Overload Fault'] === true || d['Low Battery Stow Fault'] === true || (d.rover_offline === 'Offline' && d['NO FAULT'] === true)).length])
            issueSummary.push(['Trackers in reachable ZCs', groupedProducts[key].length])
            issueSummary.push(['ZC reachable', [... new Set(groupedProducts[key].map(item => item.zone_id))].length])
            issueSummary.push(['Trackers with no issues', groupedProducts[key].filter(item => item.rover_offline === 'Online' && item['NO FAULT']).length])
            issueSummary.push(['Trackers having issues', groupedProducts[key].filter(d => d['Minimum Battery Voltage fault'] === true || d['Motor Stall Fault'] === true || 
                d['Over Current Fault'] === true || d['Mechanical Overload Fault'] === true || d['Low Battery Stow Fault'] === true || (d.rover_offline === 'Offline' && d['NO FAULT'] === true)).length])
            issueSummary.push(['MBF', groupedProducts[key].filter(item => item['Minimum Battery Voltage fault']).length])
            issueSummary.push(['MSF', groupedProducts[key].filter(item => item['Motor Stall Fault']).length])
            issueSummary.push(['OFFLINE', groupedProducts[key].filter(item => item.rover_offline === 'Offline').length])
            issueSummary.push(['OCF', groupedProducts[key].filter(item => item['Over Current Fault']).length])
            issueSummary.push(['LBS', groupedProducts[key].filter(item => item['Low Battery Stow Fault']).length])
            issueSummary.push(['Mechanical Overload Fault', groupedProducts[key].filter(item => item['Mechanical Overload Fault']).length])
            issueSummary.push([])
        })

        console.log("issueSummary",issueSummary)

        // Convert each array to a worksheet
        const sheet1 = XLSX.utils.json_to_sheet(productsShownModified);
        const sheet2 = XLSX.utils.aoa_to_sheet(issueSummary);

        // Create a new workbook and append sheets
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet1, "Issues"); // Sheet 1
        XLSX.utils.book_append_sheet(workbook, sheet2, "Issues summary"); // Sheet 2

        // Write the workbook to a binary file
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        // Save the file using FileSaver
        const data = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(data, filename);
    };

    console.log("product shown", productsShownModified)
    return <RealtimeDownload data-tip data-for='info-download' data-tooltip-place='bottom'>
        {productsShown.length > 0 ? <Button
            className="download-button"
            onClick={handleDownload}
        >
            <AiOutlineDownload className="icon" />
        </Button> : <Button
            className="download-button"
            disabled
        >
            <AiOutlineDownload className="icon" />
        </Button>}
        {productsShown.length > 0 ? <ReactTooltip id='info-download'>
            <span>Download CSV</span>
        </ReactTooltip> : null}
    </RealtimeDownload>
}