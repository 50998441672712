
const nameStyle = { color: '#8693AB', fontFamily: 'Montserrat', fontSize: 10, fontWeight: '400' }

export const axisStyle = (t, gap, position, index) => {
    return {
        name: t,
        nameLocation: position || 'center',
        nameGap: gap || 10,
        nameTextStyle: nameStyle,
        axisLine: { lineStyle: { color: "rgba(127,127,127,0.8" } },
        axisLabel: { color: '#8693AB', fontSize: 10, fontWeight: '400', fontFamily: 'Montserrat' },
        scale: true,
        splitLine: { lineStyle: { width: 1, color: "rgba(127,127,127,0.5" } },
        gridIndex: index || 0,
        max: function (value) {
            return parseFloat((value.max + 0.05).toFixed(2));

        },
        min: function (value) {
                         
            return parseFloat((value.min - 0.05).toFixed(2));
        }
    }

}

