import React, { useState } from 'react';
import useSWR from 'swr';
import { useStore } from '../../context/store.context';
import LineChart from '../chartjs/line';
import getChartJSOptions from '../chartjs/line.options';
import { Card6 as Card, CardHeader, CardHeaderSelect } from '../layout/Card';
import { allKeys, fwswOptions, getDataSets, getFWSWOptionByValue, getFWSWUnit, getMoment } from '../model/fwsw.data';
import { FetchData2 } from '../model/store.swr';




const urls = [
    { name: "fwsw", url: "latest_fwsw2", slice: 5 },
]

const ZonesFWSWTable = (props) => {
    const [key, setKey] = useState((allKeys)?"wind_speed":"");
    //const response = FetchData(useSWR, urls)
    const criteria = useStore().criteria
    const start_time = getMoment()
    const response = FetchData2(useSWR, criteria, urls)

    if (!response.isReady) return <Card>Loading ...</Card>

    //const data = parseData(response.data, props)
    function filter(data){
        return (data.site_id === props.site_id && data.zone_id === props.zone_id)
    }

    //const data = parseFWSWData(response.data.fwsw, filter)

    const data = response.data.fwsw;
    const ds = getDataSets(data, key.toUpperCase(), getFWSWOptionByValue(key))
    //const labels = formatLabels(getDataTimes(data), start_time, urls[0], criteria)
    const dps = { datasets: ds }
    //console.log(dps)
    const options = getChartJSOptions(getFWSWUnit(key), "top", false)

    return (
         <>
        <Card >
            <CardHeader title={'Sensor - Summary'} >
                <CardHeaderSelect   value={key} selected={setKey} options={fwswOptions} optionLabel={"label"} optionKey={"value"}/>
            </CardHeader>
            <LineChart data={dps} options={options} />
        </Card>
         </>
    );

}


export default ZonesFWSWTable;
