import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import useSWR from 'swr';
import { useStore } from '../../context/store.context';
import SingleStackedBar from '../d3customchart/SingleStackedBar';
import { Card6 as Card, CardHeader } from '../layout/Card';
import { ModeDomainToChart } from '../model/mode.data';
import { FetchData2 } from '../model/store.swr';
import { DownloadCsv_Operational_Modes } from './csv.download';



const urls = [
    { name: "allmodes", url: "all_unique_modes"}, 
    { name: "modemajoritytotal", url: "mode_majority_total" },
]

const SitesModeMajority = (props) => {
    const criteria = useStore().criteria

    //const response = FetchData(useSWR, urls)
    const response = FetchData2(useSWR, criteria, urls)

    if (!response.isReady) return <Card>Loading Majority Modes </Card>

    function filter(data){
        return (data.site_id === props.site_id)
    }
    
    //const { l, d, t } = parseModeData(response.data.modemajoritytotal, response.data.allmodes, filter)
    //const dps = ModeDomainToChart(l, d)
    //const percentOf = "Majority % "
    const dps = ModeDomainToChart(response.data.modemajoritytotal, response.data.allmodes, filter)
    //console.log("mode", dps)
    return (
        <div>
         <Card  class="d3-chart-ref">
            <CardHeader title={'Operational Modes'} >
                <DownloadCsv_Operational_Modes data={response.data} filename={"operational_Modes_overview_"+criteria.timetype} />
            </CardHeader>
            <SingleStackedBar height={80} data={dps.datasets} heading="Operational Modes" />
         </Card>
         </div>
    );
        
}


export default SitesModeMajority;