import { postJSONData } from '../../components/model/store.swr'


export const getCrumbsData = async (payload, setResponse, setError) => {
    
 
    postJSONData(
        `/meta?querytype=meta_domain_by_id&restype=json`,
        payload,
        (res) => {
            if(res && res.length){
                const data = res.map(ele => {
                    return {label: ele.id, value: ele.id}
                })
                setResponse(data)
            }
        }
    )
    
}
 