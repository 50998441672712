import React, { memo, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Button, Input } from "reactstrap";
import styled, { css } from "styled-components";
import { getUserID } from "../components/auth/userinfo";
import { CheckBoxField, FormProvider, TextField, useFormActionContext, useFormContext,CheckBoxGroupField  } from "../components/controls/formcontrol";
import { SingleSelectDropDownControl } from "../components/controls/multiselect_control";
import { CheckBoxControl, TextControl, SwitchControl } from "../components/controls/button_control";
import { getValueTypeGroup } from "../components/layout/criteria";
import { postJSONData } from "../components/model/store.swr";
import { useCriteriaContext } from "../context/criteria.context";
import { usePageActionContext } from "../context/page.context";
import { useUserActionContext, useUserContext } from "../context/user.context";
import { getChartsAsOptions, getMetricsAsOptions,getDashboardAsOptions } from "../model/metadata";
import { fetchJSONData } from "./portfolio";
import { style } from "d3";
import CriteriaDatePicker from '../components/layout/datepicker';
import { CriteriaDatePickerWithProp } from '../components/layout/datepicker';
import nextId from "react-id-generator";
import Select from "react-select";
import { ImFileEmpty, ImCopy, ImInfo } from "react-icons/im";
import { VscCopy ,VscFile} from "react-icons/vsc";
import { AiOutlineBarChart } from "react-icons/ai";
import { BsCalendar4 } from "react-icons/bs";
import ReactEcharts from "echarts-for-react";
import { previewData, seriesBar, seriesLine } from "../echarts/components/chartpreviewdata";
import { getIntervalTimetype } from '../components/layout/criteria';
import { object } from "prop-types";
import { useStore, useStoreActionContext } from "../context/store.context";
import { useDialogActionContext } from "../context/dialog.context"
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import ReactTooltip from 'react-tooltip';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'
import { createActivityLog } from "../utils/apiCall";
import { getLogData } from "../helpers/common"; 

export const EditFieldStyle = styled.div`
.tabRow {
    background: #F7F8F9;
}
.outline {
    border: 1px solid #E2DFDF;
    border-radius: 5px;
    height:100%;
}
.modalFooter {
  
}
hr{
    background: rgb(45 43 43 / 10%);
    margin: 0px;
}
.nav.nav-pills .nav-item .nav-link {
   
    font-weight: 500;
    color: #014059;
}
.nav.nav-pills .nav-item .nav-link:focus, .nav.nav-pills .nav-item .nav-link:hover {
    color: #014059;
}
.nav.nav-pills .nav-item .nav-link.active {
    color: #0283B6;
    background: none;
    border-bottom: 2px solid #0283B6;
    border-radius: 0px;
    font-weight: 600;
}
.dateSelector {
    background: #F7F8F9;
    border-radius: 4px;
    padding: 16px;
    text-align: left;
    display: block;
}
.dateSelector > label{
    width: 100%;
    color:#014059;
    margin-bottom: 5px;
}
.dateSelector > input {
    color: #014059;
    background: white;
    border-radius: 4px;
    border: 1px solid #014059;
    background: #FFF;
    width: 100%;
    text-align: left;
}
.dateSelector > i {
    position: absolute;
    padding: 10px;
    min-width: 40px;
    top: 65px;
    left: 208px;
}
.noMetric{

}
.chartData{
    width: auto;
    height: 100px;
    background: aliceblue;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding-top: 30px;
    padding-left: 80px;

}
.chartFieldBox {
    background: #F7F8F9;
    border-radius: 4px;
    padding-top: 10px;
    border-right: 1px solid white;
}
.chartFieldBox > label{
    font-size: 13px;
    font-weight: 600;
}
.newChartOption > .options.active {
    height: 61px;
    background: #D6F3FF;
    border: 1px solid #01668D;
    border-radius: 8px;
    text-align: center;
    margin: 2px;
}
.newChartOption > .options {
    height: 61px;
    background: #FFFF;
    border: 1px solid #9a969675;
    border-radius: 8px;
    text-align: center;
    margin: 2px;
    max-width: 135px;
    cursor: pointer;
    margin-left: 8px;
}
.newChartOption > .options > svg{
   margin-top: 10px;
   margin-bottom: 5px;
   color: #01668D;
   stroke-width: 1;
}
.newChartOption > .options.active label {
    font-weight: 600;
    color:#0283B6
}
.newChartOption > .options label {
    font-weight: 500;
    color:#0283B6;
    width: 100%;
    white-space: nowrap;
}
.newChartOption {
    padding:8px 0px 0px 8px;
}
.noChartData {
    display: block;
    text-align: center;
    width: 100%;
}
.noChartData > label{
    color: #01668D;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
}
.chartPreview .alert-primary {
    background: #D6F3FF;
    border-radius: 4px;
    padding: 16PX;
    color: #01668D;
    font-weight: 400;
    margin-bottom: 0px;
    position: relative;
    padding-left: 35px;
}
.chartPreview .alert-primary svg {
    position: absolute;
    left: 10px;
    top: 18px;
}
.chartPreview descText:before {
    padding-righ:4px;
}
.chartLoading {
    height: 100px;
    text-align: center;
    padding-top: 30px;
}
.chartLoading label{
    font-size: 12px;
    font-weight: 600;
    color: #0283B6;
}
.toast-container {
    position:absolute;
    top: -5px;
    right: 42%;
}

.toast-container .bg-success {
    color: #3c763d;
    background-color: #ccebbf !important;
    border-color: #d6e9c6;
    font-weight: 500
}
.toast-container .bg-info{
    color: #31708f;
    background-color: #d9edf7 !important;
    border-color: #bce8f1;
    font-weight: 500
}
.toast-container .bg-warning{
    color: #a94442;
    background-color: #f2dede !important;
    border-color: #ebccd1;
    font-weight: 500
}
.toast-container toast {
    max-width:auto;
}

.list-item {
  display: flex; /* Use flex to make the checkbox and label appear in the same line */
//   align-items: center; /* Align items vertically in the center */
}

.checkbox-label {
  margin-left: 10px;
  line-height: 16px; /* Adjust the line-height to center the label vertically */
  vertical-align: middle; 
}
.checkbox-item {
    // margin-right: 8px;
    margin-bottom: 16px;
    width: 20px; 
    height: 20px; 
    vertical-align: middle; 

  }

  .checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  /* Adjust styles for screens with a maximum width of 768px */
  .checkbox-container {
    flex-direction: column; /* Change to column layout for smaller screens */
    align-items: flex-start; /* Align items to the start of the container */
  }

  .form-check {
    margin-bottom: 8px; /* Add some bottom margin for spacing between checkboxes */
  }
}


`
const hideHeader = { hidden: true }


const ChartDesc = styled.pre`
    display: flex;
    position: relative;
    margin: 6px 0;
    border-radius: 6px;
    font-size: 14px;
    color: #231F20;
    background-color: #fff;
    max-height: 40vh;
    height: 20vh;
    border: solid 1px #014059;   

`

const ChartControls = styled.div`
    display: flex;
    margin: 5px 0;
    // border-radius: 6px;
    font-size: 14px;
    color: #231F20;
    // background-color: rgba(3,3,3,0.2);
    max-height: 30px;
    height: 25px;

    .right {
        position: absolute;
        right: 16px;
        margin: 3px 5px;
        top: 15px;
    }
    .right svg {
        font-size: 1.3em;
        margin: 0 5px;
        cursor: pointer;
        // color: #006cff;
        color: #014059;
    }

    .spacing {
        margin-right: 10px;
    }
    .right span img{
        cursor: pointer;
    }
    .bold-label {{
        font-weight: bold;
    }
        
      }

`
const Label = styled.div`
    display: flex;
    font-size: 14px;
    // background-color: rgba(255,255,255,0.5);
    color: #014059;
    span{
        padding: 8px 0px 0px 0px;
    }
    ${props =>
        props.top &&
        css`
            // position: absolute;
            margin: 5px 0;
       `
    };
    ${props =>
        props.left &&
        css`
            float: left;
            margin: 12px;
            min-width: 120px;
            text-align: right;
       `
    };
`
const dataTypeOptions = [
    // { label: "RC Count", value: "readings1byidcount" },
    // { label: "RC Value", value: "readings1byid0" },
    { label: "Total RCs", value: "by-value", query: "readings1byidcount", grouptype: ["rcs"], aggr: "count" },
    { label: "Recent Value", value: "by-rc-last", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "last" },
    { label: "Max Value", value: "by-rc-max", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "max" },
    { label: "Avg Value", value: "by-rc-avg", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "mean" },
    { label: "Min Value", value: "by-rc-min", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "min" },
    { label: "First Value", value: "by-rc-first", query: "readings1byid0", grouptype: ["rcs", "zones", "sites"], aggr: "first" },
]

const chartIntervalOptions = [
    { label: "10 Minutes", value: 10 },
    { label: "30 Minutes", value: 30 },
    { label: "1 Hour", value: 60 },
    { label: "3 Hours", value: 60 * 3 },
    { label: "6 Hours", value: 60 * 6 },
    { label: "12 Hours", value: 60 * 12 },
    { label: "1 Day", value: 60 * 24 },
    { label: "3 Days", value: 60 * 24 * 3 },
    { label: "7 Days", value: 60 * 24 * 7 },
]

const chartTypeOptions = [
    { label: "Line Chart", value: "line" },
    { label: "Bar Chart", value: "bar" },
]

function selectformatter(options, cell) {
    const o = options.find(x => String(x.value) === cell)
    return o
        ?
        <select type="select" className="form-control editor edit-select">
            <option value={o.value} selected="">{o.label}</option>
        </select>
        : <select type="select" className="form-control editor edit-select">
        </select>;
}
function textformatter(cell) {
    return <input type="text" className="form-control editor edit-text" value={cell} />
}
function checkBoxformatter(cell) {
    return <input type="checkbox" className="editor edit-chseckbox checkbox" checked={String(cell) == "Yes" ? true : false} />
}
function groupTypeFormatter(cell, row) {
    return cell.length > 0
        ?
        <select type="select" className="form-control editor edit-select">
            <option value={cell} selected="">{cell}</option>
        </select>
        : <select type="select" className="form-control editor edit-select">
        </select>;
}

function valueTypeSelectFormatter(cell, row, rowIndex) {

    const vt = dataTypeOptions.find((f) => f.value === row["valueType"])
    row["aggr"] = vt && vt.aggr ? vt.aggr : ""
    row["query"] = vt && vt.query ? vt.query : ""
    return selectformatter(dataTypeOptions, cell)
}

function getGroupTypes(setOptions, { row, column }) {
    if (row.metric.length > 0 && row.query.length > 0 && row.groupId.length > 0) {
        const vtg = getValueTypeGroup(row.query, row.groupId)
        if (vtg) {
            return vtg.groupTypes.map((e) => { return { label: e, value: e } })
        }
    }

    return []
}
function getGroupTypeList(element) {
    if (element.metric.length > 0 && element.query.length > 0 && element.groupId.length > 0) {
        const vtg = getValueTypeGroup(element.query, element.groupId)
        if (vtg) {
            return vtg.groupTypes.map((e) => { return { label: e.toUpperCase() + " Level", value: e } })
        }
    }

    return []
}

const chartColumns22 = [
    { dataField: 'id', text: 'id', hidden: true, },
    { dataField: 'metric', text: 'Metric', editable: false, },
    { dataField: 'groupId', text: 'Group', hidden: true },
    { dataField: 'aggr', text: 'Aggr', hidden: true },
    { dataField: 'query', text: 'Query', hidden: true },
    { dataField: 'valueType', text: 'Value Type', editor: { type: Type.SELECT, options: dataTypeOptions }, formatter: (cell, row, rowIndex) => valueTypeSelectFormatter(cell, row, rowIndex), },
    { dataField: 'groupType', text: 'Group By', editor: { type: Type.SELECT, getOptions: getGroupTypes }, formatter: (cell, row) => groupTypeFormatter(cell, row) },
    { dataField: 'interval', text: 'Interval', editor: { type: Type.SELECT, options: chartIntervalOptions }, formatter: (cell, row) => selectformatter(chartIntervalOptions, cell), },
    { dataField: 'chartType', text: 'Chart Type', editor: { type: Type.SELECT, options: chartTypeOptions }, formatter: (cell, row) => selectformatter(chartTypeOptions, cell), },
    { dataField: 'titleY', text: 'YAxis Title', formatter: (cell, row) => textformatter(cell) },
    { dataField: 'stacked', text: 'Stacked?', editor: { type: Type.CHECKBOX, value: 'Yes:No' }, formatter: (cell, row) => checkBoxformatter(cell) },
    { dataField: 'filled', text: 'Filled?', editor: { type: Type.CHECKBOX, value: 'Yes:No' }, formatter: (cell, row) => checkBoxformatter(cell) },
    { dataField: 'summary', text: 'Summary?', editor: { type: Type.CHECKBOX, value: 'Yes:No' }, formatter: (cell, row) => checkBoxformatter(cell) },
];

const chart22def = [{ id: "", metric: "", groupId: "", aggr: "", query: "", valueType: "", groupType: "", chartType: "", interval: '30', stacked: "No", filled: "No", titleY: "", summary: "No" }]

const StyledBSTable = styled.div`
    select {
       
    }

    option {
        color: #111;
        background-color: #eee;
    }

    option[selected="selected"] {
    }
    .edit-select {
        width:auto;
        min-width:104px !important;
    }
`
const TableField = memo(({ name, options, observe }) => {
    const formValues = useFormContext();
    const formSetValues = useFormActionContext().setValues;

    const value = useMemo(() => { return formValues[name] }, [formValues[observe]]) //values[name] || defValue;

    return (
        <>
            {(value) &&
                <StyledBSTable>
                    <BootstrapTable
                        keyField='id'
                        data={value}
                        columns={options.columns}
                        // rowEvents={rowEvents}
                        // selectRow={{ mode: 'radio',   hideSelectColumn: true, clickToSelect: true, style: { backgroundColor: 'rgba(10,56,112,1)' }, selected: [portfolio] }}

                        cellEdit={cellEditFactory({
                            mode: 'click',
                            // beforeSaveCell,

                            blurToSave: true,

                            afterSaveCell: (oldValue, newValue, row, column) => {

                                if (oldValue !== newValue) {

                                    formSetValues(prev => ({
                                        ...prev,
                                        [name]: value
                                    }));

                                    // postJSONData("/meta?querytype=meta_sites_update", row, (data)=>{setResponse(`Saved Site ! ${JSON.stringify(data)}`); setReloadUrls(reloadUrls + 1)})
                                }
                            }

                        })}

                    />
                </StyledBSTable>
            }
        </>
    )
});
const ChartData = (props) => {
    let handleChange = (e, i) => {
        console.log("e is ", e);
        let newFormValues = Object.assign({}, props.formValues);
        newFormValues["charts"][i][e.target.name] = e.target.value;

        if (e.target.name === "valueType") {
            newFormValues["charts"][i]["aggr"] = e.target.aggr ? e.target.aggr : "";
            // newFormValues["charts"][i]["query"] = e.target.query ? e.target.query : "";
            newFormValues["charts"][i]["query"] = e.target.query ? e.target.query +"-c" : "";
            var grpList = getGroupTypeList(newFormValues["charts"][i]);
            if(grpList && grpList.length === 1) {
                newFormValues["charts"][i]["groupType"] = grpList[0].value;
            }
        }
        props.formSetValues(prev => ({
            ...prev,
            ["charts"]: newFormValues["charts"]
        }));
        if (e.target.name === "chartType" || e.target.name === "stacked" || e.target.name === "filled" ){
            props.onChartValueChange();
        }
    }
    return (
        props.charts.map((element, index) => {
            return (
                <div className={(props.charts.length == 1 ? "col-12": "col-6") + " chartFieldBox"} key={"chartData-" + index}>
                    <label>{element.metric}</label>
                    <div className="row">
                        <div className="col-6">
                            <Label>Value Type</Label>
                            <SingleSelectDropDownControl options={dataTypeOptions} isMulti={false} value={dataTypeOptions.find(o => o.value === element.valueType)}
                                handleChange={e => {
                                    e.name = "valueType"
                                    handleChange({ target: e }, index)
                                }} />
                        </div>
                        <div className="col-6">
                            <Label>Chart Type</Label>
                            <SingleSelectDropDownControl options={chartTypeOptions} isMulti={false} value={chartTypeOptions.find(o => o.value === element.chartType)}
                                handleChange={e => {
                                    e.name = "chartType"
                                    handleChange({ target: e }, index)
                                }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Label>View By</Label>
                            <SingleSelectDropDownControl options={getGroupTypeList(element)} isMulti={false} value={getGroupTypeList(element).find(o => o.value === element.groupType)}
                                handleChange={e => {
                                    e.name = "groupType"
                                    handleChange({ target: e }, index)
                                }} />
                        </div>
                        <div className="col-6">
                            <Label>Y Axis Title</Label>
                            <TextControl
                                value={element.titleY}
                                handleChange={v => handleChange({ target: { name: "titleY", value: v } }, index)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <SwitchControl title="Stacked"
                                value={element.stacked === "No" ? false : true}
                                handleChange={v => handleChange({ target: { name: "stacked", value: (v ? "Yes" : "No") } }, index)}></SwitchControl>
                        </div>
                        <div className="col-4">
                            <SwitchControl title="Filled"
                                value={element.filled === "No" ? false : true}
                                handleChange={v => handleChange({ target: { name: "filled", value: (v ? "Yes" : "No") } }, index)}></SwitchControl>
                        </div>
                        <div className="col-4">
                            <SwitchControl title="Summary"
                                value={element.summary === "No" ? false : true}
                                handleChange={v => handleChange({ target: { name: "summary", value: (v ? "Yes" : "No") } }, index)}></SwitchControl>
                        </div>
                    </div>
                </div>
            )
        })
    )
}
const PreviewChart = (props) => {
   return props.showChart ? <ReactEcharts option={props.chartPreviewData} opts={{ renderer: "svg", responsive: true, }} style={{ height: "230px", width: "auto" }} /> : <div className="chartLoading"><label>loading..</label></div>
}
const ChartEditingBaseView = (props) => {
    const userContext = useUserContext()
    const UserActionContext = useUserActionContext()
    const criteria = useCriteriaContext()
    const pageAction = usePageActionContext()
    const storeAction = useStoreActionContext()
    const [orgChartSelected, setOrgChartSelected] = useState(false)
    const [chartPreviewData, setChartPreviewData] = useState(previewData.data)
    const dialogActionContext = useDialogActionContext();

    const metricsData = useMemo(() => { return getMetricsAsOptions(userContext.metrics) }, [userContext.metrics]) //useState([]);
    const chartsData = useMemo(() => { return getChartsAsOptions(userContext.charts) }, [userContext.charts]) //useState([]);
    const orgChartsData = useMemo(() => {
        return getChartsAsOptions(userContext.orgCharts).map(e => {
            e.label = (e.label ? e.label : "") + "  Shared";
            return e;
        })
    }, [userContext.orgCharts]) //useState([]);
    const combinedChart = [...chartsData, ...orgChartsData];
    const MyChart =[...orgChartsData];
    const DashboardCharts =[...chartsData]
    const AvailableDashboards = useMemo(() => { return getDashboardAsOptions(userContext.charts) })
    const DashboardTitles =[...AvailableDashboards]
    const [showChartPreview, setShowChart] = useState(true);
    const [message, setMessage] = useState("")
    const [msgStyle,setMsgStyle]  = useState("success")
    const [sharedByDetail,setSharedByDetail] = useState("")
    const [loading, setLoading] = useState(false)
    const formValues = useFormContext();
    const formSetValues = useFormActionContext().setValues;
    const [chart, setChart] = useState()
    const [DashboardTtile, setDashboardTtile] = useState()
    const [orgChart, setOrgChart] = useState()
    const chartDesc = useMemo(() => { return getChartCompValue() }, [formValues]) //useState([]);
    const [activeTab, setActiceTab] = useState(1);
    const [newChartOption, setNewChartOption] = useState(1);
    const [dateRange, setDateRange] = useState({
        startDate: moment(criteria['Ts']),
        endDate: moment(criteria['Te'])
    })
    function getKey() {
        // return formValues.key
        return isKey() ? formValues.chartId : undefined
    }
    function isKey() {
        return formValues && formValues.chartId
    }

    useEffect(() => {
        changeChartPreview();
    }, [formValues["charts"]])

    function changeChartPreview() {
        setShowChart(false);
        let seriesArray = [];
        let prevData = chartPreviewData;
        if (formValues && formValues["charts"] && Array.isArray(formValues["charts"])){
            formValues["charts"].forEach((chart,index) =>{
                let chartStacked = (chart.stacked === "Yes") ? true: false;
                let chartFilled = (chart.filled === "Yes") ? true: false;

                let lineClone = JSON.parse(JSON.stringify(seriesLine));
                let barClone = JSON.parse(JSON.stringify(seriesBar));
                lineClone.map((x) => {
                    x.datasetIndex = index;
                    x.yAxisIndex = index;
                    return x;
                })
                barClone.map((x) => {
                    x.datasetIndex = index;
                    x.yAxisIndex = index;
                    return x;
                })
                if(chart.chartType.length > 0) {
                    if (chart.chartType === "line") {
                        for(var i=0;i<lineClone.length;i++){
                            lineClone[i].stack = chartStacked;
                            if(chartFilled)
                                lineClone[i].areaStyle =  {};
                            else
                                delete lineClone[i].areaStyle;

                            seriesArray.push(lineClone[i]) ;
                        }
                    } else if (chart.chartType === "bar") {
                        for(var i=0;i<barClone.length;i++){
                            barClone[i].stack = chartStacked;
                            if(chartFilled)
                                barClone[i].areaStyle =  {};
                            else
                                delete barClone[i].areaStyle;

                            seriesArray.push(barClone[i]) ;
                        }
                    }
                }
            })
            prevData.series = seriesArray;
            console.log("chart prev data is ", prevData.series);
            setChartPreviewData(prevData);
            setTimeout(() => {
                setShowChart(true);
            }, 400);        
        }}

    /**
     * Function to Render charts. Should be called after chart 
     * save or delete
     * @returns Void
     */
    function renderChart() {
        storeAction.setTime(dateRange.startDate, dateRange.endDate)
        let latestChartData = {};
        latestChartData.id = "My Charts/" + formValues.page;
        latestChartData.title = formValues.title;
        let chartsToBeAppended = userContext.charts.filter(i=>i.page==formValues.page);
        latestChartData.items = [].concat(chartsToBeAppended);
        pageAction.setPage(latestChartData.id, latestChartData)
        dialogActionContext.closeDialog();
        // This is to unblock save and delete button.
        setLoading(false)
    }

    /**
     * Function to Delete charts from user context. This is 
     * later used to render the charts.
     * @returns Void
     */
    function deleteChartFromUserContext() {
        let userCharts = userContext.charts
        for (let chartIndex in userCharts) {
            if (formValues.chartId == userCharts[chartIndex].chartId) {
                userCharts.splice(chartIndex, 1);
                break
            }
        }
        UserActionContext.updateUserChart([...userCharts]);
    }

    /**
     * Function to delete charts. Pops a confirmation window before deleting the
     * chart. It first removes chart from firestore using the charts API.
     * If the API call was successful, it then removes it from UserContext. The charts
     * are then re-rendered.
     * @returns Void
     */
    function onChartDelete() {
        if ( ! window.confirm("Do you want to delete the chart data?")) {
            return
        }
        setLoading(true)
        const url = `/charts/delete/${userContext.org}/${getKey()}`
        const SuccessfulDelete = () => {
            setMessage("Chart deleted successfully")
            setMsgStyle("success");
            formSetValues(defaultChartData)
            setChart()
            var timeout = setTimeout(() => {
                setMessage("")
                deleteChartFromUserContext();
                // renderChart();
                clearTimeout(timeout);
            }, 1500);
        }
        const FailedDelete = (err) => { console.log("Error in deleting", err) }
        fetchJSONData(url, SuccessfulDelete, FailedDelete)
    }

    function onChartNew() {
        // console.log("Chart New !!!")
        setChart(null)
        setOrgChart(null)
        setMessage("")
        setMsgStyle("success");
    }
    const ChartExtraControls = () => {
        return (
            <ChartControls>
                <div className="right">
                    {/* {isKey() ? <FaTrash aria-labelledby="Delete Chart" onClick={onChartDelete} /> : ''} */}
                    {isKey() ? <span onClick={onChartDelete} aria-labelledby="Add New Chart"><img src="../assets/img/icon/icon-trash.svg" aria-labelledby="Delete Chart" alt="Delete Chart" width="20" height="20" /></span> : ''}

                    {/* <FaEdit aria-labelledby="Edit Chart" onClick={onChartEdit} /> */}
                    <span className="spacing" />
                    {/* <FaPlus aria-labelledby="Add New Chart" onClick={onChartNew}  /> */}
                    <span onClick={onChartNew} aria-labelledby="Add New Chart"><img src="../assets/img/icon/icon-plus.svg" aria-labelledby="Add New Chart" alt="Add New Chart" width="24" height="24" /></span>
                </div>
                {/* <ButtonControl title={"X"} handleClick={onChartDelete}/> */}
            </ChartControls>

        )
    }

    function getChartCompValue() {
        const f = formValues
        // console.log("Chart !!!", f)
        const comp = {
            title: f.title,
            page: f.page,
            charts: f.charts,
            XAxisTitle: f.titleX,
            lastupdated: f.lastupdated,
            metrics: f.metrics,
            hideEmpty: true,
        }
        return comp

    }

    function onSaveShare() {
        const fvalues = { ...formValues, shared: true }
        formSetValues(fvalues)
    }

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    let sharedchartlist={};
    //This SaveForSharedchart function is only called in shared chart page.
    function SaveForSharedchart() {

        if (formValues && formValues.selectedCharts !== undefined){ 
            const lenOfSelectChart= (formValues.selectedCharts).length
            const SelectChart= (formValues.selectedCharts)
            for (let i = 0; i < lenOfSelectChart; i++){
                formValues["title"]=SelectChart[i].label
                formValues["metrics"]=SelectChart[i].value.metrics
                formValues["charts"]= SelectChart[i].value.charts
                onSave()   
            } 
            const currentUrl = window.location.href;
            const newWord = "dashboards/"+formValues.page;
            const updatedUrl = currentUrl.replace(/(page=)[^&]*/, `$1${newWord}`); 
            if (validate()){
                if (lenOfSelectChart >3){
                    setTimeout(() => {
                        window.location.href = updatedUrl;
                    }, 2000);

                }
                else{
                    window.location.href = updatedUrl;
                }   
            }     
        }
        else{
            setMsgStyle("warning");
            
            setMessage("Please select any Shared Charts to save. ")
            var interval = setTimeout(() => {
                setMessage("")
                clearTimeout(interval);
            }, 2000);
            return   
        }    
    }

    /**
     * Function to Save charts. It first validates the chart data from the form.
     * If validation was un-successful, it does not save the chart. It calls an 
     * API to store data into firestore. If the API call was successful, 
     * it adds the chart from formContext to UserContext. The charts
     * are then re-rendered.
     * @returns Void
     */
    function onSave() {
        setLoading(true)
        if (!validate()) {
            setMsgStyle("warning");
            var interval = setTimeout(() => {
                setMessage("")
                clearTimeout(interval);
            }, 2000);
            setLoading(false)
            return
        } 
        setMsgStyle("success");
        //setMessage("Saving Chart Data");
        const url = `/charts/save/${getUserID()}`
        if (activeTab == 1 && (newChartOption == 2 )) {
            formValues.chartId = undefined;
        }
        const postData = [formValues].map((e) => Object.assign(e, { org: userContext.org }));
        
        postJSONData(url, postData, (data) => {
            let chartList = {}; 
            if(userContext.charts.some(x=>x.chartId == data.chartId)) {
                chartList = userContext.charts.map(x=>{
                    if(x.chartId == data.chartId) {
                        return formValues;
                    } 
                    else return x;
                })  
            } else {
                formValues.chartId = data.chartId;
                userContext.charts.push(formValues);
                chartList = userContext.charts;
            }
            
            UserActionContext.updateUserChart(chartList);
            renderChart();
            setMessage("Chart data saved. Generating chart !!")
        })

        const metrics = postData[0].charts.map(c => c.metric)
        const chart = {id: postData.chartId}
        chart['meta'] = postData[0].charts.map(c => {
            const val = {'metric': c.metric, 'aggr': c.aggr}
            return val
        })
        createActivityLog(getLogData(criteria, {featureId: `${activeTab === 2 ? 'edit' : newChartOption === 1 ? 'create' : 'clone'}-chart`, metrics: metrics.join(), usecase: `${activeTab === 2 ? 'edit' : newChartOption === 1 ? 'create' : 'clone'} custom chart`, category: 'manage-chart', details: JSON.stringify(chart)}))
    }

    function validate() {

        // if (newChartOption === 3 ) {
        //     // If newTabOption is 3, no validation is needed
        //     return true;
        // }
        var d = combinedChart;
        if (formValues['page'].length < 2) {
            setMessage(newChartOption === 3 ?  "Please select any Dashboard title. " : "Dashboard title must be two characters or more.")
            // setMessage("Dashboard title must be two characters or more.")
            return false
        }
        if (formValues['title'].length < 2) {
            setMessage("Chart title must be two characters or more.")
            return false
        }
        if (formValues['metrics'].length == 0) {
            setMessage("Please select metric(s) for the chart")
            return false
        }
        try {
            let existingPage = combinedChart.filter(y=>y.label== formValues['page']).map(x=>x.options);
            if(activeTab === 1) {
                if(existingPage.length > 0 && existingPage[0].some(z=>z.value.title === formValues['title'])){
                    setMessage(`Chart title ${formValues['title']} already exists for ${formValues['page']} `)
                    return false
                }
            } else {
                if(existingPage.length > 0) {
                    if(!(existingPage.length > 0 && existingPage[0].some(z=>z.value.chartId === formValues['chartId']))){
                        setMessage("Dashboard title already exists")
                        return false
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }

        const charts = formValues["charts"]
        //  [{id: "", metric: "", valueType: "", chartType: "", interval: '30',stacked: true, filled: false, titleY: "", summary: true}]
        let validConfig = true;
        charts.forEach(chartConfig =>{
            if(validConfig) {
                if(chartConfig.valueType === "") {
                    setMessage(`Select Value Type for ${chartConfig.metric} !!`)
                    validConfig = false;
                    return; 
                } 
                else if(chartConfig.chartType === "") {
                    setMessage(`Select Chart Type for ${chartConfig.metric} !!`) 
                    validConfig = false;
                    return; 
                }
                else if(chartConfig.groupType === "") {
                    setMessage(`Select View by for ${chartConfig.metric} !!`) 
                    validConfig = false;
                    return; 
                } 
                else if(chartConfig.titleY === "" || chartConfig.titleY.length < 2){
                    setMessage(`Y Axis for ${chartConfig.metric} must be 2 chars or more !`)
                    validConfig = false;
                    return;
                }
            }
        });
        return validConfig;
    }
   

    function getMetricCharts(metrics) {

        const formcharts = (formValues && formValues["charts"]) || [];

        const c22 = metrics.map((m, i) => {
            const c = formcharts.find((c) => m.value.metric === c.metric)
            if (c) {
                return { ...c, id: `chart-${i}`, interval: 60 }
            }
            return { ...chart22def[0], id: `chart-${i}`, metric: m.value.metric, groupId: m.value.group_id, interval: 60 }
        })

        console.log(metrics, c22)
        return c22
    }

    const MetricsSelection = ({ options }) => {

        const metrics = formValues["metrics"];
        const selectionLimit = 2

        return (
            <SingleSelectDropDownControl
                options={options} title={"Metrics"} value={metrics} multi={true}
                handleChange={e => {
                    if (e.length >= selectionLimit && metrics.length >= selectionLimit) {
                        return
                    }
                    // e.persist();
                    formSetValues(prev => ({
                        ...prev,
                        ["metrics"]: e,
                        ["charts"]: getMetricCharts(e)
                    }));
                }}
            />
        )
    }
    const DashboardSelection = ({ options }) => {

        const dashboard = formValues["page"];
        return (
            <SingleSelectDropDownControl
                options={options} title={<span style={{ fontSize: '12px' }}>Select Dashboard Title</span>} value={options.find(option => option.value === dashboard)}  multi={false}
                handleChange={e => {
                    const selectedValue = e.value.toString();
                    formSetValues(prev => ({
                        ...prev,
                        ["page"]: selectedValue,
                    }));
                }}
                
            />
        )
    }

    const ChartPropertyEditor = () => {

        return (
            <>
                <div className="row">
                    <span style={{ color: "#EF8354", fontSize: "16px", paddingTop: "18px", paddingLeft: "14px", marginBottom: "-42px" }}>{message}</span>

                </div>
                <div className="row">
                    <ChartControls>
                        <span className="top-label d-none">{isKey() ? getKey() : ""}</span>

                        <div className="right-section-top">
                            {/* <ButtonControl title={"Save"} handleClick={onChartSave} /> */}

                            {formValues && orgChart
                                ? <span style={{ padding: "0px 10px", fontWeight: "600", color: "#01668D" }} > {`Shared by ${formValues.user} !!`} </span>
                                : <CheckBoxField name="shared" title={"Share with Others"} />}
                            {/* : <span style={{padding:"0px 10px"}} className="text-success">Chart Shared!! </span>  */}

                            <Button active onClick={onSave} color="secondary">Save</Button>
                        </div>
                    </ChartControls>
                </div>
                <div className="row form-elements-section">
                    <div className="col-6">
                        <Label top>Dashboard Title</Label>
                        <TextField name="page" />
                    </div>
                    <div className="col-6">
                        <Label top>Chart Title</Label>
                        <TextField name="title" />
                    </div>
                    <div className="col-6 select-metric" style={{ marginTop: "8px" }}>
                        <Label top>Select Metric</Label>
                        {/* <DropDownField options={metricsData} name="metrics" multi={true} selectionLimit={2} /> */}
                        <MetricsSelection options={metricsData} />
                    </div>
                    <div className="col-6" style={{ marginTop: "8px" }}>
                        <Label top>X-Axis Title</Label>
                        <TextField name="titleX" />
                    </div>
                </div>

                <div className="row">
                </div>

                <div className="row" style={{ height: "20vh" }}>
                    <div className="col-12 edit-chart-table"><TableField options={{ columns: chartColumns22 }} name={"charts"} observe={"metrics"} /></div>
                    {/* <TableField options={{ columns: chartColumns22 }} name={"charts"} /> */}
                </div>
            </>
        )
    }
    const ChartFields = () => {

        return (
            <>
                <EditFieldStyle>
                    <div className="tabRow">
                        <ul className="nav nav-pills">
                            <li className="nav-item" onClick={() => {
                                setChart(null);
                                setNewChartOption(1);
                                setActiceTab(1);
                            }}>
                                <label className={'nav-link ' + (activeTab === 1 ? "active" : "")} >Create New Chart</label>
                            </li>
                            <li className="nav-item" onClick={() => {
                                setChart(null);
                                setActiceTab(2)
                            }}>
                                <label className={'nav-link ' + (activeTab === 2 ? "active" : "")} >Edit Existing Chart</label>
                            </li>
                        </ul>
                    </div>
                    {activeTab === 1 &&
                        <div className="row newChartOption">
                            <div className={'col-2 options ' + (newChartOption === 1 ? "active" : "")} onClick={() => {
                                setChart(null);
                                setNewChartOption(1)
                            }}>
                                <VscFile size={20} />
                                <label>From Scratch</label>
                            </div>
                            <div className={'col-2 options ' + (newChartOption === 2 ? "active" : "")} onClick={() => {
                                setChart(null);
                                setNewChartOption(2)
                            }}>
                                <VscCopy size={20} />
                                <label>Clone Existing</label>
                                
                            </div>
                            <div className={'col-2 options ' + (newChartOption === 3 ? "active" : "")} onClick={() => {
                                setChart(null);
                                setNewChartOption(3)
                            }}>
                                <VscCopy size={20} />
                                <label>Shared Charts</label>
                                
                            </div>
                        </div>
                    }
                    {/* New chart with from scratch option */}
                    {activeTab === 1 && newChartOption === 1 &&
                        <div className="row">
                            <div className="col-3">
                                <Label top>Dashboard Title</Label>
                                <TextField name="page" />
                            </div>
                            <div className="col-3">
                                <Label top>Chart Title</Label>
                                <TextField name="title" />
                            </div>
                            <div className="col-6">
                                <Label top>Select Metric(s)</Label>
                                <MetricsSelection options={metricsData} />
                            </div>
                        </div>
                    }
                    {/* New chart with Clone OR Existing Chart option */}
                    {(activeTab === 2 || (activeTab === 1 && newChartOption === 2)) &&
                        <>
                            <div className="row">
                                <div className="col-6">
                                    <Label top>{activeTab === 2 ? "Your Charts" : "Clone From"}</Label>
                                    <SingleSelectDropDownControl options={activeTab === 2 ? chartsData : combinedChart} handleChange={setChart} title={'Select Chart'} value={chart} />
                                </div>
                                <div className="col-6">
                                    <Label top>{activeTab === 2 ? "Dashboard Title" : "New Dashboard Title"}</Label>
                                    <TextField name="page" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <Label top>Chart Title</Label>
                                    <TextField name="title" />
                                </div>
                                <div className="col-6">
                                    <Label top>Select Metric(s)</Label>
                                    <MetricsSelection options={metricsData} />
                                </div>
                            </div>
                        </>
                    }
                     {/* Multiple shared chart selection tab*/}
                     {(activeTab === 1 && newChartOption === 3) &&
                        <div className="row">
                            <div className="col-6">
                                <Label top style={{ fontWeight: 'bold' }}>{activeTab === 2 ? "Your Charts" : "Assign to Dashboard"}</Label>
                                <DashboardSelection options={DashboardTitles}  />
                             </div>
                            <div className="col-6">
                                <Label top style={{ fontWeight: 'bold' }}>{activeTab === 2 ? "Your Charts" : "Select Shared Chart "}</Label>
                                {MyChart.length > 0 ? (<CheckBoxGroupField options={MyChart} name="title" metrics="metrics" charts="charts" AvailableDashboards={DashboardCharts} />) : (
                                 <p>No shared charts available.</p>
                               )}

                             </div>
                        </div>
                    }

                    {!(activeTab === 1 && newChartOption === 3) &&   
                    <>
                        <div className="row" style={{ padding: "0px 8px 0px 8px" }}>
                        <Label top style={{ color: "#231F20", fontWeight: "600" }}>
                            Configure Chart
                        </Label>
                        </div>
                        <div className="row" style={{ padding: "0px 8px 0px 8px" }}>
                        {formValues && formValues["charts"] && formValues["charts"].length > 0 ?  (
                            <ChartData
                            charts={formValues["charts"]}
                            formValues={formValues}
                            formSetValues={formSetValues}
                            onChartValueChange={changeChartPreview}
                            />
                        ) : (
                            <div className="noChartData">
                            <img
                                src="../assets/img/svg/no-data.svg"
                                alt="No Data"
                                width="70"
                                height="60"
                            />
                            <label>No Data Available!</label>
                            <label>
                                <small>Looks like you haven’t selected any metric(s)</small>
                            </label>
                            </div>
                        )}
                        </div>
                    </>
                    }



                </EditFieldStyle>
            </>
        )
    }

    const vheight = "100%";
    function selectChart(c) {
        //  console.log(".........chart selected .............",c);
         
        //if (activeTab == 1 && newChartOption == 2 && c) {
        //    c.value.chartId = undefined;
        //}
        // added new chart option 3 in the if below
        if(activeTab == 1 && (newChartOption == 2) && c && c.value.shared) {
            if(c.value.user !== getUserID()) {
                c.value.page = "";
                setSharedByDetail(c.value.user);
            } else {
                setSharedByDetail("");
            } 
        } else {
            setSharedByDetail("");
        }
        
        formSetValues(c ? c.value : { ...defaultChartData, org: userContext.org })
    }
    useEffect(() => {
        setOrgChartSelected(false)
        selectChart(chart)
    }, [chart])

    useEffect(() => {
        setOrgChartSelected(true)
        selectChart(orgChart)
    }, [orgChart])

    function handleDateRange(start, end, label) {
        let timeDiff = getIntervalTimetype(start, end);
        console.log("time diff is = ", timeDiff);
        setDateRange({ startDate: start, endDate: end });

        //set interval
        if (formValues && formValues["charts"].length > 0) {
            let newFormValues = Object.assign({}, formValues);
            for (var i = 0; i < newFormValues["charts"].length; i++) {
                newFormValues["charts"][i].interval = timeDiff.interval;
            }
            formSetValues(prev => ({
                ...prev,
                ["charts"]: newFormValues["charts"]
            }));
        }

    }
    function getChartDescription() {
        const temp=[...temp,formValues];
        console.log("tempdata0",temp)

        
        let timeDiff = getIntervalTimetype(dateRange.startDate, dateRange.endDate);
        if (formValues["charts"].length > 0)
            var chartDesc = "The chart will display " + formValues["metrics"].length + " metrics: " + formValues["charts"][0].metric + " at the " + formValues["charts"][0].groupType + " level " +
                (formValues["charts"].length > 1 ? "& " + formValues["charts"][1].metric + " at the " + formValues["charts"][1].groupType + " level " : "") + ". It will cover the duration of last "
                + timeDiff.diff + " " + timeDiff.type;
        return chartDesc;
    }

    console.log("formvalues+++++",formValues)

    return (
        <EditFieldStyle>
            <div className="row">
                <div className="col-8 pl-0">
                    <div className="outline chartFields-box">
                        <ChartFields />
                    </div>
                </div>
                <div className="col-4 pr-0" >
                    <div className="outline chartFields-graph">
                        <div className="row" style={{ padding: "6px" }}>
                            <Label top style={{ color: "#231F20", fontWeight: "600" }}>Chart Preview</Label>
                        </div>
                        {/* <div className="row dateSelector">
                            <Label>Select Data Range</Label>
                            <div className="dateSelectPicker">
                                <CriteriaDatePickerWithProp handleCallback={handleDateRange} />
                                <BsCalendar4 size={20}></BsCalendar4>

                            </div>
                        </div> */}
                        <div className="row">
                            <div style={{ width: "100%" }}>
                                {
                                    formValues && formValues["metrics"] && formValues["metrics"].length > 0
                                        ?
                                        (activeTab === 1 && newChartOption === 3) ? <div> </div> :
                                        <div className="chartPreview">
                                            <div className="chartOutline">
                                                <div className="chartPreviewTitle">
                                                    <h6>{formValues["title"].length > 0 ? formValues["title"] : "Chart Title"}</h6>
                                                    <ImInfo data-tip="Please note that the graph below is a sample preview of the chart type selected." data-for="chartTooltip" size={16} color="#01668D"></ImInfo>
                                                    <ReactTooltip id="chartTooltip" />
                                                </div>
                                                <div className="chartPreviewBody">
                                                    <label className="y-axis-1"><span>{formValues["charts"][0].titleY.length > 0 ? formValues["charts"][0].titleY : "Y-Axis-1"}</span></label>
                                                    {
                                                        formValues["charts"].length > 1 &&
                                                        <label className="y-axis-2">
                                                            <span>{formValues["charts"][1].titleY.length > 0 ? formValues["charts"][1].titleY : "Y-Axis-2"}</span></label>
                                                    }
                                                    {
                                                         <PreviewChart chartPreviewData={chartPreviewData} showChart={showChartPreview}></PreviewChart>
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <Alert key="primary" variant="primary">
                                                <ImInfo size={16} color="#01668D"></ImInfo>  <p className="descText">{getChartDescription()}</p>
                                            </Alert>
                                        </div>
                                        :


                                    (activeTab === 1 && newChartOption === 3) ? <div> </div> :

                                        <div className="noChartData">
                                            <img src="../assets/img/svg/no-chart-data.svg" alt="Download" width="70" height="60" />
                                            <label>No Charts - Yet!</label>
                                            <label><small>To preview chart, please complete required fields</small></label>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row modalFooter">
            <ToastContainer
                position="top-start"
                style={{ zIndex: "10" }}
                className="p-3"
                containerPosition="absolute"
                
            >
                <Toast show={ message.length > 0} bg={msgStyle}>
                <Toast.Body>{message}</Toast.Body>
                </Toast>
                </ToastContainer>
                {sharedByDetail.length > 0 && <span className="chartMessage">{sharedByDetail}</span>}
                {!(activeTab === 1 && newChartOption === 3) &&   
                <CheckBoxField name="shared" title={"Share within organization"} />
                }
                {activeTab === 2 && (formValues && formValues.chartId) && <Button active disabled={loading} onClick={onChartDelete} color="default">Delete</Button>}
                <Button active disabled={loading} onClick={newChartOption === 3 ?  SaveForSharedchart : onSave} color="secondary">Save & Generate</Button>
            </div>
        </EditFieldStyle>  
    )
}

// const defaultChartData = { datatype: {}, metric: [], interval: {}, page: "", title: "", charttype: {}, grouptype: {}, stacked: true, filled: false, majority: true, percent: false, titleX: "",titleY: "Device Count", chart22: []}

const defaultChartData = { page: "", title: "", titleX: "", metrics: [], charts: [], shared: false, org: "" }
export const ChartEditor = (props) => {
    const initial = useMemo(() => defaultChartData, [])

    return (
        <FormProvider initialValues={initial}>
            <ChartEditingBaseView {...props} />
        </FormProvider>

    )
}
