import _ from "lodash";
import { defaultOption, getBarMajoritySeries, getPieMajoritySeries, linebarTemplate, summaryGrid } from "./echarts.series";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { getToolTipCategory, getToolTipNonZero } from "./echarts.tooltip";



export const defaultBarOption = defaultOption;
export function makeBarOptions(alldata, args, criteria) {
    const data = alldata.result

    if (!data) return defaultBarOption

    const iTimestamp = data.indexes[args.options.timeColumn]
    
    // const majorityData = sumArray(data.values).map((e,i) => { return  i !== iTimestamp ? {name: data.columns[i], value: e}: undefined })
    const majorityPieSeries = args.options.showMajority? getPieMajoritySeries(data, iTimestamp) : []
    // const majorityPieSeries = args.options.showMajority? getMaxBarMajoritySeries(data, iTimestamp) : {}  //[]
    const majorityBarSeries = args.options.showPercent? getBarMajoritySeries(data, iTimestamp) : []
    const grid = args.options.showMajority? summaryGrid : {}
    const itemColor = args.options && args.options.colors ? { itemStyle: { color: (si) => { return args.options.colors[si.seriesName] } } } : {}
    // console.log(args, itemColor)
    const xType = args.options.typeX && args.options.typeX === 'category' ? 'category' : 'time'

    const historicalSeries = data.columns.map((e, i) => {
        if (i !== iTimestamp) {
            return Object.assign({}, linebarTemplate, { name: e, type: 'bar', stack: args.options.stacked, encode: { x: iTimestamp, y: i, tooltip: [i] } },)
        }
    })


    const option = {
        //legend: { type: 'scroll', top: '5%', height: '90%', right: 0, orient: 'vertical', data: legenddata, selector: true, textStyle: { color: "#eee"} },
        dataset: {
            source: xType === 'time' ? data.values.map(value => {
                const timestamp = value[0]
                const utcTime = moment.utc(timestamp).format()
                const siteTime = moment(utcTime).format('YYYY-MM-DD HH:mm:ss')
                const otherValues = value.slice(1)
                return [siteTime, ...otherValues]
            }) : data.values,
            dimensions: data.columns
        },
        xAxis: [
            {
                type: xType, nameLocation: 'middle', name: args.options.titleX || '',
                axisLabel: (xType === 'category') ? { interval: 0, rotate: 45 } : {}
            }
        ],
        yAxis:  [ //_.concat([],
            {
                name: args.options.titleY || '',
                scale: true, nameLocation: 'middle', nameGap: 45,
                splitLine: { lineStyle: { width: 1, color: "rgba(127,127,127,0.5" } },
                nameTextStyle: { align: 'center', fontWeight: '400', fontSize: 10, color: '#8693AB' },
                min: function (value) {
                    if(args.options.titleY == 'Device Count'){
                        return 0;
                    }
                    return parseFloat((value.min - 0.05).toFixed(2));
                }
            },
        ],
        // series: _.concat(historicalSeries, majorityPieSeries.series, majorityBarSeries),
        series: _.concat(historicalSeries, majorityPieSeries, majorityBarSeries),
        // tooltip: args.options.tooltip && args.options.tooltip === 'category'? getToolTipCategory(iTimestamp): getToolTipNonZero(iTimestamp)
        tooltip: args.options.typeX && args.options.typeX === 'category' ? getToolTipCategory(iTimestamp) : getToolTipNonZero(iTimestamp, args.title)

    };

    // console.log(option)
    const o =  _.merge({}, defaultBarOption, option, grid);
    // console.log('BAR CHART ', o)
    return o;
}


