import React, { useEffect, useState } from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import useSWR from 'swr';
import { useDataviewActionContext } from '../../context/dataview.context';
import NotifyTooltip from "../../model/notify-tooltip"
import { useStore, useStoreActionContext } from '../../context/store.context';
import { InfoTooltip } from '../../model/info-tooltip';
import { DownloadCsv_Summary_table } from '../chart/csv.download';
import { Card4Opacity80 as Card, CardHeaderSelect, CardSummary } from '../layout/Card';
import { SidebarData } from '../layout/SidebarData';
import { allKeys, fwswOptions, getFWSWOptionByValue } from '../model/fwsw.data';
import { FetchData2 } from '../model/store.swr';
import { TooltipHelpContent } from '../pages/tooltip-help-content';
import TableContainer from './TableContainer';
import { ColumnErrors, ColumnExpectedPower,ColumnActualPower,ColumnPowerVariance,ColumnUptime, ColumnDeviceCount, ColumnUptimeImpact, findSite, getLastSeenTime, getSiteName, renderColumn, uptimeImpact, ColumnTrackerComm } from './summary.row';
import { createActivityLog, getStatsData } from '../../utils/apiCall';
import { getLogData, getWeightedAvg, handleStatsAsync } from '../../helpers/common';
import { useUserContext } from '../../context/user.context';
import { GetFQURL } from '../model/store.swr';
import _ from 'lodash'
import { usePageContext, energyMeterPage, usePageActionContext } from '../../context/page.context';
import { useAvailabilityContext } from '../../context/availability.context';

function getSite(row) {
    return renderColumn(row, findSite, getSiteName)
}

function getUptime(row) {
    return renderColumn(row, findSite, ColumnUptime)
}

function getTrackerComm(row) {
    return renderColumn(row, findSite, ColumnTrackerComm)
}

function getDeviceCount(row) {
    return renderColumn(row, findSite, ColumnDeviceCount)
}

function getExpectedPower(row) {
    return renderColumn(row, findSite, ColumnExpectedPower)
}
function getActualPower(row) {
    return renderColumn(row, findSite, ColumnActualPower)
}
function getVariance(row) {
    return renderColumn(row, findSite, ColumnPowerVariance)
}


function getErrors(row) {
    return renderColumn(row, findSite, ColumnErrors)
}

function getUptimeImpact(row) {
    return renderColumn(row, findSite, ColumnUptimeImpact)
}

const columns_list = [
    {
        Header: "Site Name",
        accessor: "siteId",
        Cell: (row) => {
            return getSite(row)
        }
    },
    {
        Header: "AVAILABILITY",
        accessor: "uptime",
        Cell: (row) => {
            return getUptime(row)
        }
    },
    {   
        Header: () => <span>AVAILABILITY <br/>Impact</span>,
        accessor: "uptimeImpact",
        Cell: (row) => {
            return getUptimeImpact(row)
        }
    },
    {   
        Header: () => <span>Device Count</span>,
        accessor: "deviceCount",
        Cell: (row) => {
            return getDeviceCount(row)
        }
    },
    {
        id: "SENSORS",
        Header: "Wind Speed",
        accessor: "wind_speed",
        Cell: (row) => {
            return <span>{row.value ? row.value : 0}</span>;
        }
    },
    {
        Header: "Errors",
        accessor: "errorCount",
        Cell: (row) => {
            return getErrors(row)
        }
    },
    {
        Header: "Tracker Comms",
        accessor: "tracker_communication",
        Cell: (row) => {
            return getTrackerComm(row)
        }
    },
    {
        Header: () => <span>Last Sent</span>,
        accessor: "lastUpdated",
        Cell: (row) => {
            //const rv = getTimeDiff(row); //isNaN(parseInt(row.value)) ? "" : parseInt(row.value)
            return getLastSeenTime(row)//<span data-tip={rv}>{rv}</span>;
        }
    },

];

const energymeter_columns_list = [
    {
        Header: "Site Name",
        accessor: "id",
        Cell: (row) => {
            return getSite(row)
        }
    },
    {
        Header: () => <span>Energy<br/>Estimated(KW)</span>,
        accessor: "expected_power",
        Cell: (row) => {
            return getExpectedPower(row)
        }
    },
    {   
        Header: () => <span>Energy<br/>Actual(KW)</span>,
        accessor: "actual_power",
        Cell: (row) => {
            return getActualPower(row)
        }
    },
    {
        Header: "Variance (KW)",
        accessor: "errorCount",
        Cell: (row) => {
            return getVariance(row)
        }
    },
    {
        Header: () => <span>Last Sent</span>,
        accessor: "lastUpdated",
        Cell: (row) => {
            return getLastSeenTime(row)
        }
    },
];

function parseData(alldata) {

    const sites = alldata.sites
    const fwsw = alldata.fwsw
    const down = alldata.down
    const errors = alldata.errors
    const uptime = alldata.uptime
    var parentdevice = alldata.device
    //const energy = alldata.energy_details
    // const preverrors = alldata.preverrors
    // const prevuptime = alldata.prevuptime
    
    // const uptimeAll = uptime.map((item, i) => Object.assign({}, item,
    //     // { prevUptime: prevuptime.find(x => item.site_id === x.site_id) }
    // ))
    // //console.log("all uptime", JSON.stringify(uptimeAll))
    
    // const errorsAll = errors.map((item, i) => Object.assign({}, item,
    //     // { prevErrors: preverrors.find(x => item.site_id === x.site_id) }
    // ))
    // //console.log("all errors", JSON.stringify(errorsAll))
    
    var deviceCount = 0
    if(uptime.length){
        uptime.forEach(u => {
            if(u.uptime !== null){
                deviceCount += u.devicecount
            }
        })
    }
    
    
    
    const tracker_communication = alldata.tracker_communication.map(t => deviceCount && t.tracker_communication !== null ? t  :    {...t, tracker_communication: "No Data"})
    const uptimeAll = uptime.map((item, i) => Object.assign({}, item,
        deviceCount && item.uptime !== null ? uptimeImpact(item, {devicecount: deviceCount}) :{uptimeImpact: 'No Data', parentdevicecount: 0, uptime: 'No Data'}

    ))



    // const sites2 = sites.map(e=> {  var o = {}; o['site_id'] = e.id; return o; })
    const data = sites.map((item, i) => Object.assign({}, item,
        down.find(x => item.site_id === x.site_id),
        errors.find(x => item.site_id === x.site_id) ? errors.find(x => item.site_id === x.site_id) : {errorCount: 0},
        fwsw.find(x => item.site_id === x.site_id) ? fwsw.find(x => item.site_id === x.site_id) : {wind_speed: 0},
        uptimeAll.find(x => item.site_id === x.site_id) ? uptimeAll.find(x => item.site_id === x.site_id) : {uptime: 'No Data', uptimeImpact: 'No Data'},
        tracker_communication.find(x => item.site_id === x.site_id) ? tracker_communication.find(x => item.site_id === x.site_id) : { tracker_communication: 'No Data'}
        //energy.find(x => item.site_id === x.id)
        
    ))

    console.log("data....",data)

    ReactTooltip.rebuild();

    return data;
}

function parseDetailData(alldata) {

    const sites_zones = alldata.sites_zones
    const sites = alldata.sites
    let tracker_communication_zones = alldata.tracker_communication_zones

    if (!sites_zones)
        return null
    
    sites_zones.sort((a, b) => a.zone_id > b.zone_id ? 1 : -1)
    let zones = []
    let uptimeAll = []
    let tracker_communication_zones_all = []
    sites.forEach(site => {
        zones = zones.concat(sites_zones.filter(t => t.site_id === site.site_id))
        const uptime = alldata.uptime_zones.filter(t => t.site_id  === site.site_id)
        var deviceCount = 0
        if(uptime.length){
            uptime.forEach(u => {
                if(u.uptime !== null){
                    deviceCount += u.devicecount
                }
            })
        }
        tracker_communication_zones_all = tracker_communication_zones_all.concat(
            tracker_communication_zones.map(t => deviceCount && t.tracker_communication !== null ? t  :    {...t, tracker_communication: "No Data"})
        )
        uptimeAll = uptimeAll.concat(uptime.map((item, i) => Object.assign({}, item,
            deviceCount && item.uptime !== null ? uptimeImpact(item, {devicecount: deviceCount}) :{uptimeImpact: 'No Data', parentdevicecount: 0, uptime: 'No Data'}
    
        )))
    })

    const errors = alldata.errors_zones
    
    const data = uptimeAll.map((item, i) => Object.assign({}, item,
        errors.find(x => item.site_id === x.site_id && item.zone_id === x.zone_id) ? errors.find(x => item.site_id === x.site_id && item.zone_id === x.zone_id) : {errorCount: 0},
        uptimeAll.find(x => item.site_id === x.site_id && item.zone_id === x.zone_id) ? uptimeAll.find(x => item.site_id === x.site_id && item.zone_id === x.zone_id) : {uptime: 'No Data', uptimeImpact: 'No Data'},  
        tracker_communication_zones_all.find(x => item.site_id === x.site_id && item.zone_id === x.zone_id) ? tracker_communication_zones_all.find(x => item.site_id === x.site_id && item.zone_id === x.zone_id) : { tracker_communication: 'No Data'}  
    ))

    return data;
}

export const goToPage = (level, site, criteria, history) => {

    const linkData = SidebarData.find(sbd => sbd.title === level);
    if (linkData) {
        const sitePageLink = {
            pathname: linkData.path,
            search: level === 'Sites' ? ('?siteId=' + site.site_id) : (level === 'Zones' ? ('?siteId=' + site.site_id + '&zoneId=' + site.zone_id) : ('?siteId=' + site.site_id + '&zoneId=' + site.zone_id + '&rcId=' + site.device_id)),
            state: level === 'Sites' ? { site_id: site.site_id } : (level === 'Zones' ? { site_id: site.site_id, zone_id: site.zone_id } : { site_id: site.site_id, zone_id: site.zone_id, device_id: site.device_id })
        }
        criteria.site = site.site_id
        if (level === 'Zones' || level === 'Devices') {
            criteria.zone = site.zone_id
        }
        if (level === 'Devices') {
            criteria.device = site.device_id
        }
        sitePageLink.search = sitePageLink.search + "" + history.location.search.replace('?&', '&').replace('?', '&')
        history.push(sitePageLink)
    }
}

const urls = [
    { name: "sites", url: "all_unique_sites", slice: -1, nocache: true },
    // { name: "fwsw", url: "latest_fwsw_pf" },
    { name: "fwsw", url: "sensor_summary" },
    // { name: "down", url: "latest_down" },
    { name: "down", url: "rc_down_summary" },
    // { name: "errors", url: "latest_errors" },
    { name: "errors", url: "error_summary_card" },
    // { name: "uptime", url: "latest_uptime" },
    { name: "uptime", url: "availability_summary" },
    { name: "device", url: "device_summary", grouptype: "pf" },
    { name: "availability_avg", url: "rc_availability_avg"},
    { name: "energy_details", url: "expected_actual",  path:"/energy", aggr: "avg"}
    // { name: "preverrors", url: "previous_errors" },
    // { name: "prevuptime", url: "previous_uptime" },
]

const PortfolioSummaryTable = () => {
    var urls = [{name: 'summaryData', url: "overview_summary"}] 
    const [response, setResponse] = useState({})
    const [error, setError] = useState({})
    const pageContext = usePageContext();
    const pageName = pageContext.page;
    const [key, setKey] = useState((allKeys.length > 0) ? allKeys[0] : "");
    var columnList = pageName === energyMeterPage ? energymeter_columns_list : columns_list;
    const [columns, setColumns] = useState(columnList)
    const dataviewaction = useDataviewActionContext()

    const history = useHistory();
    const criteria = useStore().criteria
    const userContext = useUserContext()
    const storeAction = useStoreActionContext()

    const [tenants, setTenants] = useState([])

    const pageActionContext = usePageActionContext()

    const availabilityContext = useAvailabilityContext()

    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Escape') {
            event.preventDefault();
            var popup = document.getElementById("myPopup");
            popup.classList.remove("show");
          }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []);

      useEffect(() => {
        if(criteria){
            createActivityLog(getLogData(criteria, {featureId: `view-pf-summary`, usecase: `view porfolio summary`, category: 'overview', level: 'pf'}))
        }
      },[criteria.pf, criteria.Te, criteria.Ts])

    //const columns = useMemo(() => columns_list, []);

    //const response = FetchData(useSWR, urls)
    console.log("tenants are",tenants)

    useEffect(() => {
        if(Array.isArray(userContext.tenants)){
            setTenants(userContext.tenants)
        }
    },[userContext.tenants])
    
    
    useEffect(() => {
        setResponse({})
        setError({})
        if(Array.isArray(tenants) && tenants.length > 1){
            urls = [{name: 'summaryData', url: "overview_summary_combined"}]
        }
        
        
        if(tenants.length){
            let url = GetFQURL(urls[0], criteria, 'json', false,[],null,null,null, {})
            handleStatsAsync(url, setResponse, setError, availabilityContext)
        }
        
    },[tenants, criteria.Ts, criteria.Te])



    // const response = FetchData2(useSWR, criteria, urls)

    console.log("pf summary data...",response)

    if (_.isEmpty(response)) return <CardSummary>Loading Portfolio Summary ...</CardSummary>
    const res_availability = Array.isArray(response) ? (response[0].availability_avg || null) : (response.data.availability_avg || null)
    // const availability_avg = availabilityContext.withMaintenance ? "Coming soon" : res_availability[0].availability
    const availability_avg = res_availability[0].availability
    const res_tracker_communication = Array.isArray(response) ? (response[0].tracker_communication_avg || null) : (response.data.tracker_communication_avg || null)
    const tracker_communication_avg = res_tracker_communication ? res_tracker_communication[0].tracker_communication : null
    const data = parseData(Array.isArray(response) ? response[0] : response.data)
    const detaildata = criteria.pf !== "All" ? parseDetailData(Array.isArray(response) ? response[0] :  response.data) : null
    // console.log("Pf.summary parsed data", data)

    const editSensorColumn = (key) => {
        columns_list.forEach((e, i) => {
            if (e.id === "SENSORS") {
                //console.log('Before the shallow copying:', e) // initial value
                //const newSensor = Object.assign({}, e, { accessor: key })
                const header = getFWSWOptionByValue(key).toUpperCase().split(" ")
                e.Header = () => { return (<span>{header.map((e) => <>{e} <br /></>)} </span>) }
                e.accessor = key
                //columns_list[i] = newSensor;
            }
        })
        setKey(key);
        setColumns([...columns_list])
        createActivityLog(getLogData(criteria, {metrics: key, featureId: `view-pf-summary-details`, usecase: `view porfolio summary detailed data`, category: 'overview', level: 'pf'}))
        //console.log('After the shallow copy is taken, the value in the state is still:', columns_list) // initial value

    }

    function handleRowClick(site, history) {
        // goToPage("Sites", site, criteria, history)
        storeAction.setSite(site.site_id)
        dataviewaction.clearView()

    }
    //console.log(data)
    const totalchildren = data.length
    // const uptimedata = data.filter(a => a.uptime).map(a => { return { uptime: a.uptime, devicecount: a.devicecount } });
    // const uptimecount = uptimedata.length
    //console.log(uptimecount)
    // const uptimeavg = uptimecount>0 ? (uptimedata.reduce((a, b) => (a + b)) / uptimedata.length).toFixed(2) : '';
    // const uptimeavg = (uptimedata.reduce((acc, e) => acc + (e.uptime * e.devicecount), 0) / uptimedata.reduce((acc, e) => acc + e.devicecount, 0)).toFixed(2)
    // const uptimeavg = (uptimedata.reduce((acc, e) => acc + e.uptime , 0) / uptimedata.reduce((acc, e)=> acc + e.devicecount, 0)).toFixed(2)

    const errordata = data.map(d => d.errorCount || 0)
    console.log("error data",errordata)
    const errorcount = errordata.reduce((a, b) => a + b, 0)
    //console.log(uptimeavg)

    function handleClick(e) {
        e.preventDefault();
        var popup = document.getElementById("myPopup");
        popup.classList.toggle("show");
        if(popup.classList.contains("show")){
            createActivityLog(getLogData(criteria, {metrics: key, featureId: `view-pf-summary-details`, usecase: `view porfolio summary detailed data`, category: 'overview', level: 'pf'}))
        }
    }

    const tooltipContent = TooltipHelpContent.PortfolioSummary
    const tooltip = (
        <div>
            <h5>{tooltipContent.header}</h5>
            {tooltipContent.body}
        </div>
    )

    return (
        <CardSummary  >
            {/* <a href="#"> */}

            <div className="popup" >
                <div className="card-body" onClick={handleClick}>
                    <h5 className="card-title">Portfolio Summary <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} toolTipPointer={'right'} /></h5>
                    
                    <div className="text-white h6 card-text"><span>Total Sites : {totalchildren}</span></div>
                    {/* <div className="text-white h6 card-text"><span>Availability Avg. : {uptimeavg} %</span></div> */}
                    <div className="text-white h6 card-text"><span>Availability Avg. : {availability_avg ? `${availability_avg}%` : 'No Data'}</span>{availabilityContext.withMaintenance ? 
                        <NotifyTooltip  content =   "Adjusted for maintenance activities"   onClick={() => pageActionContext.setPage("Site Maintenance", [], false)} />
                        : null}
                    </div>
                    <div className="text-white h6 card-text"><span>Tracker Comms : {tracker_communication_avg ? `${tracker_communication_avg}%` : 'No Data'}</span></div>
                    {/* <div className="text-white h6 card-text"><span>Error total : {errorcount}</span></div> */}
                
                </div>
                <span className="popuptext" id="myPopup">
                    {/* A Simple Popup! */}
                    <div className="col-sm-12 no-gutters ">
                        <Card>
                        {
                                    (pageName !== energyMeterPage) ?
                                    <div className="mb-3 popup-header">
                                        <b className="mb-3">{'Portfolio Summary'}</b>
                                        <span className="ml-2">
                                            <CardHeaderSelect value={key} selected={editSensorColumn} options={fwswOptions} optionLabel={"label"} optionKey={"value"} />
                                            {/* <i className="fa fa-info-circle" data-toggle="popover" data-trigger="hover" data-title="Uptime %" data-placement="top" data-content="Portfolio Summary"></i> */}
                                        </span>
                                        <span className="ml-12 float-right bg-transparent d-flex">
                                            <DownloadCsv_Summary_table data={data} filename={"portfoliosummary" + criteria.timetype} detaildata={detaildata}/>
                                        </span>
                                    </div>
                                    :
                                    <div className="mb-3 popup-header">
                                    <b className="mb-3">{'Portfolio Summary: Energy'}</b>
                                    <span className="ml-12 float-right bg-transparent d-flex">
                                        <DownloadCsv_Summary_table data={data} filename={"portfoliosummary" + criteria.timetype} detaildata={detaildata}/>
                                    </span>
                                    </div>
                            }
                            <div className="d-flex  align-items-center mb-1">

                                <TableContainer
                                    columns={columnList}
                                    data={data}
                                    trHandler={handleRowClick}
                                // renderRowSubComponent={renderRowSubComponent}


                                />
                            </div>

                        </Card>
                    </div>
                </span>

            </div>
            <div className="stats-desc text-right py-2 px-2 " onClick={handleClick} style={{ "position": "absolute", "bottom": "0", "right": "0", "cursor": "pointer" }}>
                <span >View Sites <span className="fas fa-angle-double-down	"></span></span></div>
        </CardSummary>
    );

}


export default PortfolioSummaryTable;
