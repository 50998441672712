import React, { useState, useEffect } from 'react'
import Datetime from 'react-datetime';
import axios from 'axios'
import { getAccessToken, getUserEmail } from '../auth/userinfo'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const RemindLater = styled.div`

    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: 'Montserrat', sans-serif !important;
    padding: 8px;

    .message {
        font-size: 14px;
    }

    .actions {
        
        display: flex;
        flex-direction: row;
        gap: 12px;
        position: relative;
    }

    .actions button {
        
        background: #014059;
        border: #014059;
        :active {
            background: #014059 !important;
        }
    }

    .actions button.later {
        background: lightgray;
        border: lightgray;
        :active {
            background: lightgray !important;
        }
    }

    .snooze-options {
        position: absolute;
        width: 200px;
        height: 140px;
        background: white;
        top: -95px;
        left: 36px;
        z-index: 117;
        box-shadow: 0px 5px 15px #dddddd;
    }

    .rdtPicker {
        position: fixed;
    }

    .rdtPicker td.rdtDisabled {
        color: #bbbbbb;
    }

    .rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
        background-color: #014059;
    }

    .snooze-error {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        padding: 4px 8px;
        background: pink;
        top: 0px;
        width: max-content;
        border-radius: 0px 0px 2px 2px;
        box-shadow: 0px 2px 10px #efefef;
    }

`

export default props => {

    const [error, setError] = useState({ type: "none", text: "" })
    const [remindOn, setRemindOn] = useState(moment().tz(props.timezone).add(1, 'days'))
    const [loading, setLoading] = useState(false)

    const isValidEndDate = (date) => {
        const currentDate = moment()
        return currentDate.isBefore(date, 'day')
    }

    const urlPost = (query, data) => {
        let token = getAccessToken()
        return axios({
            url: `maintenance?querytype=${query}&restype=json&project=depcom`,
            method: 'POST',
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            data: data
        })
    }

    const snoozeNotifications = date => {

        if (typeof date !== 'object') {
            setError({ type: "error", text: "Invalid date" })
            return
        }

        setLoading(true)
        urlPost("snooze", {start_date: date, user: getUserEmail()})
        .then(() => {
            setLoading(false)
            setError({ type: "success", text: "Done!" })
            // closeToast()
        })
        .catch(err => {
            setLoading(false)
            setError({ type: "error", text: err.message })
        })
    }

    useEffect(() => {
        let timeout
        if (error.text) {
            if (error.type === "error")
                timeout = setTimeout(() => {
                    setError({ type: "none", text: "" })
                }, 2000)
            if (error.type === "success")
                timeout = setTimeout(() => {
                    setError({ type: "none", text: "" })
                    props.callback()
                }, 1000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [error])

    return <RemindLater>
        {error.text ? <div className="snooze-error">{error.text}</div> : null}
        <span className="message">Remind me on:</span>
        <Datetime 
            value={remindOn}
            dateFormat="MMM DD, YYYY"
            onChange={setRemindOn}
            isValidDate={isValidEndDate}
            displayTimeZone={props.timezone}
        />
        <div className="actions">
            <Button onClick={() => {
                    snoozeNotifications(remindOn)
                }}
                disabled={loading}
            >
                {loading ? "Saving.." : "Snooze!"}
            </Button>
            <Button onClick={props.cancel}  className="later">Cancel</Button>
        </div>
    </RemindLater>
}