import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import React, {useEffect, useState} from 'react';
import { useStore } from '../../context/store.context';
import { CardPopover, CardStats } from '../layout/Card';
import NotifyTooltip from '../../model/notify-tooltip'
import { InfoTooltip } from '../../model/info-tooltip'
import ReactDOMServer from 'react-dom/server';
import { HiOutlineRefresh } from "react-icons/hi";
import { useCriteriaContext } from '../../context/criteria.context';

const ProgressBar = ({ value }) => {
  return (
    <div className="stats-progress progress bg-dark-darker my-2">
      { (value) ? <div className="progress-bar " style={{ "width": value + "%" }}></div> : <div ></div>}
    </div>
  )
}


const StatsContent = (props) => {
  const { onRefreshClick } = props;
  console.log('StatsContentfor refresh', props)
      // Function to handle refresh button click
  const handleRefreshClick = (e) => {
    e.stopPropagation();  // Prevents the modal from opening
    onRefreshClick();
    };

  const value = (props.data.value2) ? <span>{props.data.value}<span className="h5"> / {props.data.value2}</span></span> : <span>{props.data.value}</span>;
  console.log('StatsContent', props.data)
  const c = toJS(useStore().criteria)
  const [criteria,setCriteria] = useState(c)
  const tooltip = props.tooltip ? (
    <div>
        <h5>{props.tooltip.header}</h5>
        {props.tooltip.body}
    </div>
  ) : ''
  return (
    <>
    <div className="card-body" onClick={props.onClick} style={{cursor: props.onClick ? 'pointer' : 'auto'}}>
    <h5 className="card-title">{props.title} <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} toolTipPointer={props.toolTipPointer}/>
    {/* {props.title === "Trackers not communicating" && (
          <button onClick={handleRefreshClick} style={{ marginLeft: '5px', background: 'transparent', border: 'none' }}>
          <HiOutlineRefresh size={16} />
          </button>
        )} */}
    </h5>
    {/* <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div> */}
    
    <h4 className="card-text">
        {(props.title === "Trackers not communicating" && props.data.value <= 0 && (!props.data.value2 || props.data.value2 <= 0) && (!props.data.percentage_offline || props.data.percentage_offline <= 0) && (!props.data.progress || props.data.progress <= 0)) ? 
            (criteria.pf && criteria.site === "" ? 'All sites are currently offline.' : 'All zones are currently offline.') :
            <>
                {props.data.value !== undefined ? props.data.value : ''}
                <span className="h5"> {(props.data.value2 !== undefined) ? "/ "+ props.data.value2 : " "} {props.data.suffix}
                {props.data.percentage_offline !== undefined && 
                    <span> ({props.data.percentage_offline}%)</span>
                }
                {(criteria.pf && criteria.site !== "" && props.title === "Trackers not communicating" && props.data.value3 !== undefined && props.data.value4 !== undefined) && 
                    " and " + props.data.value4 + " / " + props.data.value3 + "  Zones"
                }
            </span>
                {props.data.info ? 
                    <NotifyTooltip  content =   {props.data.info} onClick={props.data.infoclick} />
                    : null
                }
            </>
        }
    </h4> 

    {(props.data.progress !== undefined)? <ProgressBar value={props.data.progress}/> : ''}
    {props.data.tracker_communication ? <div className="text-white card-text"><span style={{fontSize: 12, opacity: 0.8}}>Tracker Comms : {props.data.tracker_communication} %</span></div> : null}
</div>
{
    props.data.totalDataCount && props.data.totalDataCount.length>0 &&
    <h5 className="text-left my-2 mx-3" style={{"position":"absolute","bottom":"0", "left":"0"}}>{props.data.totalDataCount}</h5>
}
<h5 className="text-right my-2 mx-3" style={{"position":"absolute","bottom":"0", "right":"0"}}>{props.data.timeperiod}</h5>
</>
)

}


const StatsContentComponent = (props) => {
  // console.log('StatsContentfor refresh', props)
      // Function to handle refresh button click

  const c = toJS(useStore().criteria)
  const [criteria,setCriteria] = useState(c)
  const tooltip = props.tooltip ? (
    <div>
        <h5>{props.tooltip.header}</h5>
        {props.tooltip.body}
    </div>
  ) : ''
  return (
    <>
    <div className="card-body" onClick={props.onClick} style={{cursor: props.onClick ? 'pointer' : 'auto'}}>
    <h5 className="card-title">{props.title} <InfoTooltip content={ReactDOMServer.renderToString(tooltip)} toolTipPointer={props.toolTipPointer}/>
    {/* {props.title === "Trackers not communicating" && (
          <button onClick={handleRefreshClick} style={{ marginLeft: '5px', background: 'transparent', border: 'none' }}>
          <HiOutlineRefresh size={16} />
          </button>
        )} */}
    </h5>
    {/* <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i></div> */}
    
    <h4 className="card-text">
        {props.children}
        {/* {props.data.info ? 
                    <NotifyTooltip  content =   {props.data.info} onClick={props.data.infoclick} />
                    : null
                } */}

    </h4> 

    </div>

    </>
)

}

export const StatsOverviewBase = observer((props) => {

  return (
    <CardStats>
      <StatsContentComponent {... props}/>

    </CardStats>


  );
})

const StatsOverview = (props) => {

  return (
    <CardStats>
      <StatsContent {... props}/>
    </CardStats>


  );
}

export const StatsOverviewPopup = observer((props) => {
  console.log('StatsOverviewPopup', props)
  const { style, ...restProps } = props;


    return (
      <CardStats className="hover-effect" style={style}>
        <CardPopover {...restProps}>
          <StatsContent {...restProps} />
        </CardPopover>
      </CardStats>
    );
})


export const StatsOverviewPopupOfflineTracker = observer((props) => {
  const { style, onClick, onRefreshClick, ...restProps } = props; 
  console.log('StatsOverviewPopupOfflineTracker', props)

  return (
    <CardStats className="hover-effect" style={style}>
        <div className="stats-overview-container" onClick={onClick}>
          {/* <StatsContent {...restProps} /> */}
          <StatsContent
                    {...restProps}
                    onClick={onClick}
                    onRefreshClick={onRefreshClick} // Pass it to StatsContent here
                />
        </div>
    </CardStats>
  );
});





export default observer(StatsOverview);
