import { toJS } from 'mobx';
import { observer } from "mobx-react";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import React, { useMemo, useState, useEffect } from "react";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { useStore, useStoreActionContext } from "../../context/store.context";
import { useLocation } from '../../navigation/use-location';
import './datepicker.css';

// https://www.npmjs.com/package/react-bootstrap-daterangepicker
function ranges() {
    return {
        "Last 1 Hour": [moment().subtract(1, "hours"), moment()],
        "Last 6 Hours": [moment().subtract(6, "hours"), moment()],
        "Last 12 Hours": [moment().subtract(12, "hours"), moment()],
        "Last 24 Hours": [moment().subtract(24, "hours"), moment()],
        "Last 2 Days": [moment().subtract(2, "days"), moment()],
        "Last 7 Days": [moment().subtract(7, "days"), moment()],
    }
};

const presets = {
    "1-hour": "Last 1 Hour",
    "6-hour": "Last 6 Hours",
    "12-hour": "Last 12 Hours",
    "1-day": "Last 24 Hours",
    "2-day": "Last 2 Days",
    "7-day": "Last 7 Days",
}

const presets1 = {
    "Last 1 Hour": "1-hour",
    "Last 6 Hours": "6-hour",
    "Last 12 Hours": "12-hour",
    "Last 24 Hours": "1-day",
    "Last 2 Days": "2-day",
    "Last 7 Days": "7-day",
    "Custom Range": "custom",
}


const CriteriaDatePicker = () => {
    const store = useStore();
    const location = useLocation()
    const [searchPath, setSearchPath] = useState("");
    const [r, setR] = useState(ranges())

    // const [cs, setCS] = useState(toJS(store.criteria))
    // const [settings, setSettings] = useState(initializeSettings(toJS(store.criteria)))
    const settings = useMemo(() => initializeSettings(toJS(store.criteria)), [store.criteria])

    const storeAction = useStoreActionContext()


    useEffect(() => {
        let cs = store.criteria;
        if(document.querySelector('.text-truncate'))
        document.querySelector('.text-truncate').value = moment(cs['Ts']).format("MM/DD/YYYY") + " - " + moment(cs['Te']).format("MM/DD/YYYY");

    }, [store.criteria])
   

    useEffect(() => {
        if(document && document.querySelector('.navbar.calendar-container') && document.querySelector('.daterangepicker')){
            document.querySelector('.navbar.calendar-container').appendChild(document.querySelector('.daterangepicker'))
        }
    },[])

    function getDates (cs) {
        if (Object.keys(presets).indexOf(cs['preset']) >= 0) {
            const key = presets[cs['preset']]
            return {
                startDate: r[key][0],
                endDate: r[key][1]
            }
        } else {
            return {
                startDate: moment(cs['Ts']),
                endDate: moment(cs['Te']),
            }
        }
    }
   
    function initializeSettings(cs) {
        // const r = ranges()
        const { startDate, endDate } = getDates(cs)

        return {

            startDate: startDate,
            endDate: endDate,
            timePicker: true,
            timePicker24Hour: true,
            //showDropdowns: true,
            ranges: r,
            minDate: moment("20200606"),
            maxDate: moment().toDate(),
            showCustomRangeLabel: true,
            buttonClasses: "btn btn-sm",
            showWeekNumbers: true,
        }
    };

    function handleEvent(event, picker) {
        if (event.type === "show" || event.type === "hide") {
            // picker.ranges = ranges()
        }
        //console.log("datepicker event", event, picker);

    }

    function handleCallback(start, end, label) {
        storeAction.setTime(start, end, presets1[label])
        /*
        const timesdata =   getIntervalTimetype(start, end);
        cs['interval'] =  timesdata.interval
        cs['timetype'] = `${timesdata.diff}-${timesdata.type}`


        cs['Ts'] = start.format(CriteriaTimeFormat);
        cs['Te'] = end.format(CriteriaTimeFormat);
        store.criteria=  Object.assign({}, cs)
        */
    }

    const updateDisplayProp = () => {
        if(document && document.querySelector('.daterangepicker')){
            if(document.querySelector('.daterangepicker').style.display === 'block'){
                document.querySelector('.daterangepicker').style.display = 'flex'
            }
        }
    }

    //render() {
    return (
        <DateRangePicker className="btn btn-default mr-2 text-truncate"
            initialSettings={settings}
            onEvent={handleEvent}
            onCallback={handleCallback}
            onShow={updateDisplayProp}
        >
        <input readOnly className="btn btn-default mr-2 text-truncate datePickerMain"></input>
        </DateRangePicker>
    )
    //}
}
export const CriteriaDatePickerWithProp = (prop) => {
    const datePickerTemplate = '<div class="daterangepicker checkDateClass">' +
    '<div class="ranges"></div>' +
    '<div class="drp-calendar left">' +
        '<div class="calendar-table"></div>' +
        '<div class="calendar-time"></div>' +
    '</div>' +
    '<div class="drp-calendar right">' +
        '<div class="calendar-table"></div>' +
        '<div class="calendar-time"></div>' +
    '</div>' +
    '<div class="drp-buttons">' +
        '<span class="drp-selected"></span>' +
        '<button class="cancelBtn" type="button"></button>' +
        '<button class="applyBtn" disabled="disabled" type="button"></button> ' +
    '</div>' +
'</div>'
    const store = useStore();
    const location = useLocation()
    const [searchPath, setSearchPath] = useState("");

    const settings = useMemo(() => initializeSettings(toJS(store.criteria)), [store.criteria])

    const storeAction = useStoreActionContext()

    useEffect(() => {
        if(document && document.querySelector('.dateSelector') && document.querySelector('.checkDateClass')){
            document.querySelector('.dateSelector').appendChild(document.querySelector('.checkDateClass'))
        }
    },[])
   
    function initializeSettings(cs) {

        return {

            startDate: moment(cs['Ts']),
            endDate: moment(cs['Te']),
            timePicker: true,
            timePicker24Hour: true,
            //showDropdowns: true,
            ranges: ranges(),
            minDate: moment("20200606"),
            maxDate: moment().toDate(),
            showCustomRangeLabel: true,
            buttonClasses: "btn btn-sm",
            showWeekNumbers: true,
            template: datePickerTemplate
        }
    };

    function handleEvent(event, picker) {
        console.log("I am getting called....")
        if (event.type === "show" || event.type === "hide") {
            picker.ranges = ranges()
        }

    }
    //render() {
    return (
        <DateRangePicker className="btn btn-default mr-2 text-truncate"
            initialSettings={settings}
            onEvent={handleEvent}
            onCallback={prop.handleCallback}
        >
            <input readOnly className="btn btn-default mr-2 text-truncate"></input>

        </DateRangePicker>

    )
    //}
}


export default observer(CriteriaDatePicker);