import { Accordion } from "../layout/accordian.vertical/accordian";
import React, { useMemo, useState, useEffect } from "react";
import nextId from "react-id-generator";
import { useStore } from "../context/criteria.context";
import { getAccessToken } from '../components/auth/userinfo'
import {createActivityLog} from '../utils/apiCall'
import { getPayloadForLogs, getLogData } from "../helpers/common"
import { isDevice, isPortfolio, isSite, isZone } from "../components/layout/criteria";
import { useCriteriaContext } from "../context/criteria.context";
import MarqueeContainer from "../layout/Mq";
import styled from 'styled-components';
import { setWindowLocation } from "../navigation/link";

//const userData = localStorage.getItem('user')
let token = getAccessToken()
//if (userData) {
//    const jsonUserData = JSON.parse(userData)
//    token = jsonUserData.token
//}

export const fetchBomData = async (criteria, filename) => {
    let token = getAccessToken()
    const isDownload = (filename && filename != "")
    var requestBody = {}
    if (isDownload){
        requestBody = {...criteria, "filename": filename, "response-type": "xlsx"}
    } else {
        requestBody = {...criteria, "response-type": "json"}
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify(requestBody),
    }
    // console.log(requestOptions)
    // console.log("requesting bom data ")
    const url = isDownload?"/bom?download=true":"/bom?no-download=true"
        return fetch(url, requestOptions)
            .then((res) => {
                if(res.status===404){
                    console.log("404 not found");
                    throw new Error("Bom data not found")
                } else {
                    if (isDownload){
                        return res.blob()
                    } else {
                        return res.json();
                    }
                    
                }            
            })
            
    
  
}

export function downloadBomData(options, filename, notify, level, criteria, cb) {

    const readDeviceBom = () => {

    }

    

    createActivityLog(getLogData(criteria, {featureId: `download-${level}-bom`.toLowerCase(), metrics: "bom", usecase: `download bom data`.toLowerCase(), category: 'overview', level: level, details: JSON.stringify({merge: options.merge, aggr: options.aggr})}))
}

export const BomDownloadLink= () => {
    const criteria = useCriteriaContext()
    const device = useMemo(()=> { return criteria.device}, [criteria.device]);

    const level = isPortfolio(criteria) ? 'pf' : isSite(criteria) ? 'site' : isZone(criteria) ? 'zone' : isDevice(criteria) ? 'rc' : {}
    const [enabled, setEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const filename = `${criteria.site === ""? "allsites-": criteria.zone === ""? criteria.site + '-' : criteria.device === ''? criteria.site + '-' + criteria.zone + '-' :   criteria.site + '-' + criteria.zone + '-' + criteria.device + '-'}`;
 

    // const getFileName = () => {
    //     const domain = `${criteria.site === ""? "allsites-": criteria.zone === ""? criteria.site + '-' : criteria.device === ''? criteria.site + '-' + criteria.zone + '-' :   criteria.site + '-' + criteria.zone + '-' + criteria.device + '-'}`
    //     // const time = `${criteria.Ts + '-' + criteria.Te }`
    //     return  domain 

    // }

    function handleDownload() {
        setLoading(true)

        // console.log("handle download called ")
        // downloadBomData("", getFileName(), setLoading, level, criteria)
        fetchBomData(criteria, filename)
        .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename + ".xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // notify(false)
            // cb && cb()
        })
        .catch((err) => {
            return Promise.reject({ Error: 'Something Went Wrong', err });

        })
        .finally (()=> {
            setLoading(false)
            // cb && cb()
        })
        createActivityLog(getLogData(criteria, {featureId: `download-${level}-bom`.toLowerCase(), metrics: "bom", usecase: `download bom data`.toLowerCase(), category: 'overview', level: level, details: JSON.stringify({})}))

    }
    useEffect(()=>{
        fetchBomData(criteria, null)
        .then((device_bom) => {
            setEnabled((device_bom  && device_bom.length > 0))
            console.log("Enabled .... ", enabled)
            console.log("bom data .... ", device_bom)
        })
        .catch((err) => {
            setEnabled(false)

            return Promise.reject({ Error: 'Something Went Wrong', err });
        })
    },[criteria.device, criteria.zone, criteria.site])

    if(!enabled || isPortfolio(criteria)){
        return ""
    }
    return (
       
        <Accordion open={1}>
                    <Accordion.Item key={nextId()}>
                        <Accordion.Header>{"BOM"}</Accordion.Header>
                        <Accordion.Body>
                        <h6 className="datalink" key={nextId()} enabled={loading? 'false': 'true'}
                        onClick={() =>handleDownload()}
                        >{"Download BOM"}</h6>
                        </Accordion.Body>
                    </Accordion.Item>
        </Accordion>
    );

}

Object.defineProperty(String.prototype, 'capitalize', {
    value: function() {
      return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
  });


  const Container = styled.div`
  height: 70px;
  overflow-y: scroll;
  
`;

const Item = styled.div`
display: block;

 height: 2em;  
 font-size: 12px;
`;

const Label = styled.div`
  opacity: 0.8;
  float: left; clear: left; width: 10em; 

  
`;

const Value = styled.div`
  padding-left: 2px;
  float: left;
  font-weight: bold;
`;
export function DeviceBomData(props) {
    // const { items } = props.items;
    const criteria = useCriteriaContext();

    return (
        <Container>
            {Object.entries(props.items).map(([k,v]) => {
                return <Item key={nextId()}><Label >{k}</Label><Value >{v}</Value></Item>
            })}
        </Container>
        // <MarqueeContainer items={items} />
    )
}

