import { observer } from "mobx-react";
import moment from 'moment';
import React, { useState,  useEffect } from 'react';
import nextId from "react-id-generator";
import { useStore } from "../../context/store.context";
import { CSVToArray, getUserPortfolios } from "../auth/userinfo";
import CriteriaDatePicker from '../layout/datepicker';
import { energyMeterPage } from '../../context/page.context'

const CriteriaOptions = (props) => {
    const store = useStore()
    const [cs, setCS] = useState(store.criteria)
    const data = props.data
    const ckey = props.ckey
    //console.log("dropdown", data)
    function handler(e) {
        cs[props.ckey] = e.target.value;
        //console.log("observable", isObservable(cs))
        store.criteria = Object.assign({}, cs)
        //console.log("updated cs", toJS(cs))

    }

    return (

        <div className="form-group px-3 pt-2">


            <select id={nextId()} className="form-control"
                value={cs[props.ckey]}
                onChange={handler}
            >
                {data.map((e) => <option key={nextId()} value={e.type}>{e.title}</option>)}
            </select>
        </div>
    )
}
export const CriteriaTimeFormat = 'YYYYMMDDTHH00';
export const URLCriteriaTimeFormat = 'YYYYMMDDTHHmm';

function getPastDaysTime(days = 0) {
    return moment.utc().subtract(days, "day")
}
export const MIN_INTERVAL = 1; // 10 minutes
function initializeTime() {
    const yesterday = moment.utc().subtract(7, "day")
    const today = moment.utc() //'20210101T0000'
    const Ts = getPastDaysTime(7).format(CriteriaTimeFormat)
    const Te = getPastDaysTime().format(CriteriaTimeFormat) //'20210101T0000'

    const timesdata = getIntervalTimetype(yesterday, today);

    return {
        timetype: `${timesdata.diff}-${timesdata.type}`,
        interval: timesdata.interval,
        Ts: Ts, Te: Te,
        tz: 'utc',
        preset: "7-day"
    }
}

const getDefaultPortfolio = () => {
    console.log(process.env.REACT_APP_PORTFOLIO_ID)
    const defaultPfs = process.env.REACT_APP_PORTFOLIO_ID || "All";
    console.log(defaultPfs)
    const userPfs = getUserPortfolios()
    console.log(userPfs)

    if (userPfs) {
        const pfs = CSVToArray(userPfs)
        if (pfs && pfs.length > 0  )  {
            console.log("portfolios selected ", pfs)
            return  pfs[0] 
        }
    }
    return defaultPfs
}

export const defaultPortfolio = getDefaultPortfolio(); //"FTC"

function initializeDomain() {
    return {
        grouptype: "sites",
        querytype: "", project: "depcom",
        pf: defaultPortfolio,
        site: "", zone: "", device: "",inverter: "",
        user: "", aggr: "last"
    }
}

export function getInitialCriteria() {
    console.log(" ******************** INITIALIZING CRITERIA *************************")
    return Object.assign({}, initializeDomain(), initializeTime())
}


export function isPortfolio(c) { return c.pf !== "" && c.site === "" }
export function isSite(c) { return c.site.length > 0 && (c.zone === "" && !c.inverter) }
export function isZone(c) { return c.site !== "" && c.zone !== "" && c.device === "" }
export function isInverter(c) { return c.site !== "" && c.inverter !== ""}
export function isDevice(c) { return c.site !== "" && c.zone !== "" && c.device !== "" }
export function domainsByCriteria(criteria) {
    return isPortfolio(criteria) ? ['pf'] : isSite(criteria) ? ['pf', 'site']  : isInverter(criteria) ? ['pf', 'site', 'inverter'] : isZone(criteria) ? ['pf', 'site', 'zone'] : ['pf', 'site', 'zone', 'device']
}
export const valueTypeGroups = [
    {query: 'readings1byid0', metricType: 'R', groupTypes: ['rcs'], valid: {pf: false, site: false, zone: true, device: true} },
    {query: 'readings1byid0', metricType: 'Z', groupTypes: ['zones'], valid: {pf: false, site: true, zone: true, device: true}},
    {query: 'readings1byidcount', metricType: 'R', groupTypes: ['rcs'], valid: {pf: true, site: true, zone: true, device: true}},
    {query: 'readings1byidcount', metricType: 'Z', groupTypes: ['zones'], valid: {pf: true, site: true, zone: true, device: false}},
    {query: 'readings1byid0-c', metricType: 'R', groupTypes: ['rcs'], valid: {pf: false, site: false, zone: true, device: true} },
    {query: 'readings1byid0-c', metricType: 'Z', groupTypes: ['zones'], valid: {pf: false, site: true, zone: true, device: true}},
    {query: 'readings1byid0-c', metricType: 'I', groupTypes: ['zones'], valid: {pf: false, site: true, zone: true, device: true}},
    {query: 'readings1byidcount-c', metricType: 'R', groupTypes: ['rcs'], valid: {pf: true, site: true, zone: true, device: true}},
    {query: 'readings1byidcount-c', metricType: 'Z', groupTypes: ['zones'], valid: {pf: true, site: true, zone: true, device: false}},
  ] 


export function getValueTypeGroup(query, group_id){
    return valueTypeGroups.find((f)=> f.query === query && f.metricType === group_id.charAt(1))
}

export function getUrlFromCriteria(criteria, page) {
    const path = domainsByCriteria(criteria).map(key => criteria[key]).join("/")
    console.log("MYYYYY", criteria, path)

    var feature = page
    if(!feature){
        let params = new URLSearchParams(window.location.search);
        feature = params.get("page")
    }

    console.log("STEP 2:: feature in criteria",feature)

    const url = `/?page=${feature}&path=${path}&time=${criteria.Ts}/${criteria.Te}&tz=${criteria.tz}`
    return { 'url': url, 'state': criteria }
}

function validatePathIndex(path, index, validate = isNotEmpty) {
    return (path && index < path.length && validate(path[index])) //?path[index]: defaultValue
}

function isNotEmpty(s) {
    return s !== ""
}

function validateDateTime(s) {
    return isNotEmpty(s) && moment(s).isValid()
}

function getDomainFromPath(path) {
    console.log('domain path' , path)
    const d = path.split("/")
    const indices = {pf: 0, site: 1, zone: 2, device: 3}
    if(sessionStorage.getItem('page') === 'Inverter' || sessionStorage.getItem('page') === energyMeterPage){
        delete indices['zone']
        indices['inverter'] = 2
    }

    const domain = { 
        pf: validatePathIndex(d, indices.pf) ? d[indices.pf] : "", 
        site: validatePathIndex(d, indices.site) ? d[indices.site] : "", 
        zone: validatePathIndex(d, indices.zone) ? d[indices.zone] : "", 
        device: validatePathIndex(d, indices.device) ? d[indices.device] : "" ,
        inverter: validatePathIndex(d, indices.inverter) ? d[indices.inverter] : ""
    }
    console.log(path ,  " & & & &&& & && & & & && & & & & ", domain)
    return Object.assign(domain, getGroupTypeByDomain(domain), { querytype: "", project: "depcom" })
}

function getTimeFromPath(path, tz, timezone) {
    console.log('time path' , path)

    const d = path.split("/")
    const start = validatePathIndex(d, 0, validateDateTime) ? moment.tz(d[0], timezone) : getPastDaysTime(7)
    const end = validatePathIndex(d, 1, validateDateTime) ? moment.tz(d[1], timezone) : getPastDaysTime(0)
    return getCriteriaFromTime(start, end, tz)

}

function getGroupTypeByDomain(domain) {
    return { grouptype: isPortfolio(domain) ? 'sites' : isSite(domain) ? 'zones' : isZone(domain) ? 'rcs' : 'rcs'}
}

export const getCriteriaFromLocation = (location, isPageNotFound) => {
    if (location.search === "" || isPageNotFound) { 
        return getInitialCriteria() 
    }

    // const match = /^\?path=(.*)&Ts=(\w+)&Te=(\w+)?/.exec(search)
    const match = /^\?page=(.*)&path=(.*)&time=(.*)&tz=(.*)?/.exec(unescape(location.search))
    console.log("REGEXXXXXXXXXXXXXXXXXXXXXXXXXX", match)
    var domain 
    var time 
    if(!match){
        domain = initializeDomain()
        time = initializeTime()
    }else{
        domain = (match.length > 1 && match[2] !== "") ? getDomainFromPath(match[2]) : initializeDomain()
        const t = getTimezone(domain.site)
        const tz = domain.site === "" ? "utc" : "site" //match[4]
        moment.tz.setDefault(t.timezone)
        time = (match.length > 4 && match[3] && match[4]) ? getTimeFromPath(match[3],tz,t.timezone) : initializeTime()
    }
    
    // console.log('+++++++++++', domain, time, initializeDomain())

    return Object.assign({}, initializeDomain(), domain, time)

}

function getTimezone (site) {
    if (site === "")
        return { timezone: "Etc/UTC", tz: 'utc' }

    const timezones = localStorage.getItem("timezones")
    if (timezones) {
        const tzs = JSON.parse(timezones)
        const tz = tzs.filter(t => t.site_id === site)
        return tz.length > 0 ? {timezone: tz[0].timezone, tz: 'site' } : { timezone: "Etc/UTC", tz: 'utc' }
    } else {
        return { timezone: "Etc/UTC", tz: 'utc' }
    }
}

const projects = [
    { title: "Depcom", type: "depcom" },
    { title: "Pine Gate", type: "pine-gate" },
    { title: 'neighborhood power', type: 'neighborhood-power' },

]


export const getProjectByType = (type) => {
    return projects.find((e) => e.type === type)

}


const times = [
    { title: "Last 1 Hour", type: "1-hour", by: "hours", time: 1, interval: 1, minimum: 1, format: "HH:mm" },
    { title: "Last 6 Hours", type: "6-hour", by: "hours", time: 6, interval: 1 * 10, minimum: 1, format: "HH:mm" },
    { title: "Last 12 Hours", type: "12-hour", by: "hours", time: 12, interval: 12 * 60, minimum: 1, format: "HH:mm" },
    { title: "Last 24 Hours", type: "24-hour", by: "hours", time: 24, interval: 24 * 60, minimum: 1, format: "DD:HH" },
    { title: "Last 2 Days", type: "2-days", by: "days", time: 2, interval: 2 * 24 * 60, minimum: 1, format: "DD:MM" },
    { title: "Last 7 Days", type: "7-days", by: "days", time: 7, interval: 7 * 24 * 60, minimum: 1, format: "DD:MM" },
    { title: "More than 7 Days", type: "7+days", by: "weeks", time: 8, interval: 7 * 24 * 60, minimum: 1, format: "DD:MM" },
]


export const getTimesByType = (type) => {
    const a = times.find((e) => e.type === type)
    //console.log("gettimes", a)
    return a

}

const groups = [
    { type: "pf", grp: "" },
    { type: "sites", grp: "site_id " },
    { type: "zones", grp: "site_id, zone_id " },
    { type: "rcs", grp: "site_id, zone_id, device_id " },
]


export function getIntervalTimetype(start, end) {
    const startH = moment(start.format(CriteriaTimeFormat))
    const endH = moment(end.format(CriteriaTimeFormat))
    const daysdiff = endH.diff(startH, 'days')
    const monthsdiff = end.diff(start, 'month')
    // > 30 - 60 * 24
    // 7 to 30 - 60 * 3 
    if (daysdiff > 90) return { interval: 1440, diff: end.diff(start, 'weeks'), type: "week" }
    if (daysdiff > 21) return { interval: 720, diff: end.diff(start, 'weeks'), type: "week" }
    if (daysdiff >= 1) return { interval: 60, diff: daysdiff, type: "day" }

    const hoursdiff = end.diff(start, 'hours')

    if (hoursdiff >= 12) return { interval: 10 * 1, diff: hoursdiff, type: "hour" }
    if (hoursdiff >= 6) return { interval: 10 * 1, diff: hoursdiff, type: "hour" }
    if (hoursdiff >= 1) return { interval: 2 * 1, diff: hoursdiff, type: "hour" }

    const minutesdiff = end.diff(start, 'minutes')
    return { interval: 1, diff: minutesdiff, type: "minute" }

}

export function getIntervalByCriteria(c) {
    const timesdata = getIntervalTimetype(moment(c.Ts), moment(c.Te));
    return timesdata.interval
}

export function getCriteriaFromTime(start, end, tz, preset) {
    const timesdata = getIntervalTimetype(start, end)
    return {
        interval: timesdata.interval,
        timetype: `${timesdata.diff}-${timesdata.type}`,
        Ts: start.format(CriteriaTimeFormat),
        Te: end.format(CriteriaTimeFormat),
        tz: tz,
        preset: preset || getPresetValue(start, end)
    }

}

function getPresetValue (start, end) {
    const now = moment()
    const diff = now.diff(end)
    const durationFromNow = moment.duration(diff).as('hours')
    if (durationFromNow < 1) {
        var duration = moment.duration(end.diff(start))
        const hours = duration.as('hours')
        const presets = {
            [7*24]: '7-day',
            [2*24]: '2-day',
            [24]: '1-day',
            [12]: '12-hour',
            [6]: '6-hour',
            [1]: '1-hour'
        }
        if (Object.keys(presets).indexOf(hours.toString()) >= 0) {
            return presets[hours]
        } else {
            return 'custom'
        }
    } else {
        return 'custom'
    }

    
}

export const SetCriteriaTime = (start, end, label) => {
    const store = useStore();
    const [cs, setCS] = useState(store.criteria)
    store.criteria = Object.assign({}, cs, getCriteriaFromTime(start, end))
    //console.log(label)
    return store.Criteria
}

export const getGroupByType = (type) => {
    return groups.find((e) => e.type === type)

}

export const getTimeInMinutes = (criteria) => {
    return Math.floor(moment(criteria.Te).diff(moment(criteria.Ts), 'minutes'))
    // a.diff(b, 'minutes')
    // const d1 = new Date(criteria.Ts) , 
    // d2 = new Date(criteria.Te); 

    // const diff =  d2 - d1;

    // const m =  Math.floor(diff / 60e3);
    // console.log(toJS(criteria), d2, d1, diff, m )
    // return m

}

export function isTimeTypeInDays(time_id) {
    const ttime = times.find(e => e.type === time_id)
    return ttime.by !== "hours"
}

export function isTimeInHours(criteria) {
    return criteria.timetype.includes('hour')
    // const ttime = times.find(e => e.type === time_id)
    // return ttime.by !== "hours"
}

export function isTimeInWeeks(criteria) {
    return criteria.timetype.includes('week')
    // const ttime = times.find(e => e.type === time_id)
    // return ttime.by !== "hours"
}


export function isTimeInDays(criteria) {
    return criteria.timetype.includes('day')
    // const ttime = times.find(e => e.type === time_id)
    // return ttime.by !== "hours"
}

export function isTimeInMinutes(criteria) {
    return criteria.timetype.includes('minute')
    // const ttime = times.find(e => e.type === time_id)
    // return ttime.by !== "hours"
}

const Criteria = (props) => {
    const store = useStore()
    // const cs = store.criteria


    const [rand, setRand] = useState(Math.random())


    useEffect(() => {
        // let cs = store.criteria;
        setRand(Math.random())

    }, [store.criteria])

    return (
        <div className="panel-body " style={{visibility: props.hidden ? 'hidden' : 'visible'}} key={rand}>

            <div className="form-group row  col-sm-12">
                
                <CriteriaDatePicker />

            </div>
        </div>
    )
}

export default observer(Criteria)