import React, { useMemo } from "react";
import { FormProvider } from "../components/controls/formcontrol";

// const Redis = require("ioredis");
const UserInvitationBaseView = (props) => {

    return (
        <>
        <h5>Invitation !! </h5>
        </>
    )
}

const defaultChartData = { page: "", title: "", titleX: "", metrics: [], charts: [], shared: false, org: "" }
export const UserInvitation = (props) => {
    const initial = useMemo(() => defaultChartData, [])

    return (
        <FormProvider initialValues={initial}>
            <UserInvitationBaseView {...props} />
        </FormProvider>

    )
}
