import React from 'react'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import styled from 'styled-components'

import { useRealtimeContext } from '../../context/realtime.context';


const Sort = styled.div`
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1fe;

    .heading {
        font-weight: 800;
    }
`

const SortOptions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    span {
        padding: 0px 3px;
        border-radius: 4px 4px 0px 0px;
    }

    span:hover {
        background-color: #eeeeee;
    }

    .icon {
        margin-top: -3px;
    }
`

export default props => {
    const realtimeContext = useRealtimeContext()
    const { sortRowAttrs, applySort } = realtimeContext

    const sortRowAttr = sortRowAttrs.filter(t => t.dataField === props.dataField)
    const sort = sortRowAttr.length > 0 ? sortRowAttr[0].sort : null

    return <Sort>
            <span className="heading">Sort</span>
            <SortOptions>
                <span
                    onClick={ sort !== 'asc' ? () => applySort(props.dataField, 'asc') : () => {}} 
                    style={{ 
                        color: sort === 'asc' ? '#014059' : '#4C4345', 
                        borderBottom: sort === 'asc' ? '3px solid #014059' : 'none'
                    }}
                ><AiOutlineArrowUp  className="icon"/></span>
                <span
                    onClick={ sort !== 'desc' ? () => applySort(props.dataField, 'desc') : () => {}} 
                    style={{ 
                        color: sort === 'desc' ? '#014059' : '#4C4345', 
                        borderBottom: sort === 'desc' ? '3px solid #014059' : 'none'
                    }}
                ><AiOutlineArrowDown  className="icon"/></span>
            </SortOptions>
    </Sort>

}