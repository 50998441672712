import React, { createContext, memo, useState, useEffect} from "react";
import ContextFactory from "../../context/base.context";
import { CheckBoxControl, TextControl } from "./button_control";
import { SingleSelectDropDownControl } from "./multiselect_control";
import nextId from "react-id-generator";
export const FormContext = createContext();
export const FormActionContext = createContext();
export const useFormContext = ContextFactory('FormContext', FormContext)
export const useFormActionContext = ContextFactory('FormActionContext', FormActionContext)
export const FormProvider = ({ initialValues, children }) => {
  const [values, setValues] = useState(initialValues);

  const value = {
    values,
    setValues
  };
  const actions = {
      setValues: setValues
  }

  return (
    <FormContext.Provider value={values}>
        <FormActionContext.Provider value={actions}>
            {children}
        </FormActionContext.Provider>
    </FormContext.Provider>
  );
};


export const TextField = memo(
    ({ name }) => {
        const  values = useFormContext();
        const  setValues = useFormActionContext().setValues;
  
      const value = values[name];
    
      // console.log(name, value);
      return (
          <TextControl 
          value={value} 
          handleChange={v => {
            // e.persist();

            setValues(prev => ({
              ...prev,
            //   [name]: e.target.value
            [name]: v
            }));
          }}
          />
      )
    },
    (prev, next) => prev.value === next.value
  );
  
export const CheckBoxField = memo(
    ({ name, title }) => {
    //   const { values, setValues } = useContext(FormContext);
    const  values = useFormContext();
    const  setValues = useFormActionContext().setValues;
    const value = values[name];
      return (
          <CheckBoxControl 
          value={value} 
          title={title? title: name}
          handleChange={v => {
            // e.persist();
            setValues(prev => ({
              ...prev,
            //   [name]: e.target.checked
            [name]: v
            }));
          }}
          />
      )
    },
    (prev, next) => prev.value === next.value
  );

  export const CheckBoxGroupField = ({ name, val,title, options,metrics,charts, AvailableDashboards}) => {
    const values = useFormContext();
    const setValues = useFormActionContext().setValues;
    const selectedCharts = values[val] || [];
    const chartsInSharedChart = options[0].options
    let defaultCheckedIndices=[]
    let chartsInsideSelectedDashboard = [];
    const getSelectedPageInfo = () => {
      if (values['page']) {
        const selectedPage = AvailableDashboards.find((dashboard) => dashboard.label === values['page']);
        if (selectedPage) {
          const selectedOptions = selectedPage.options.map((opt) => opt.value);
          return { selectedPage: values['page'], selectedOptions };
        }
      }
      return { selectedPage: null, selectedOptions: [] };
    };
    const selectedDashboardTtiles = getSelectedPageInfo().selectedPage 
    const selectedoptions=getSelectedPageInfo().selectedOptions
    const isDashboardAvailable = AvailableDashboards.some((dashboard) => dashboard.label === selectedDashboardTtiles);
    if (isDashboardAvailable) {
      const selectedDashboard = AvailableDashboards.find((dashboard) => dashboard.label === selectedDashboardTtiles);
      chartsInsideSelectedDashboard = selectedDashboard.options;
    }
    const chartsInsideSelectedDashboardModified = chartsInsideSelectedDashboard.map(item => {
      const lastSpaceIndex = item.label.lastIndexOf(' ');
      const splitLabel = lastSpaceIndex !== -1
        ? [item.label.slice(0, lastSpaceIndex + 1).trim(), item.label.slice(lastSpaceIndex + 1).trim()]
        : [item.label.trim()]; // If no space found, keep the original label
      return {
        label: splitLabel[0], // Split after the last space and remove leading/trailing spaces
        value: item.value
      };
    });
    for (let i =0; i<chartsInSharedChart.length; i++){
      for (let j=0; j<chartsInsideSelectedDashboardModified.length; j++){
        if ((chartsInSharedChart[i].label===chartsInsideSelectedDashboardModified[j].label)){
          defaultCheckedIndices.push(i)
        }
      }
    }
    if (selectedDashboardTtiles === null){
      defaultCheckedIndices=[]
    }


    return (
      <div style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'auto'  }}>
      <div>
        {options.map((group, groupIndex) => (
          <div key={group.label} >
            {group.options.map((option, optionIndex) => (
              <CheckBoxControl1
                key={option.label}
                name={name}
                metrics={metrics}
                charts={charts}
                title={option.label}
                value={selectedCharts.some((chart) => chart.value === option.value)||((defaultCheckedIndices && defaultCheckedIndices.includes(optionIndex)))}
                handleChange={(isChecked) => {
                  let updatedCharts;
                  if (isChecked) {
                    updatedCharts = [...selectedCharts, { value: option.value, label: option.label }];
                  } else {
                    updatedCharts = selectedCharts.filter((chart) => chart.value !== option.value);
                  }
                    setValues((prev) => ({
                      ...prev,
                      [val]: updatedCharts,
                      ["selectedCharts"]:updatedCharts
                    }));
                    let leng=updatedCharts.length-1
                  }}
              />
            ))}
          </div>
        ))}
      </div>
      </div>
    );
  };

   const CheckBoxControl1 = (props) => {
   const key = nextId("checked");
  
    function handleChange(e) {
      props.handleChange(e.target.checked);
    }
    return (
      <div style={{ marginBottom: '12px' }}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={key}
                checked={props.value}
                onChange={handleChange}
                style={{ marginRight: '15px' }}
              />
              <label className="form-check-label" htmlFor={key} style={{ marginLeft: '10px' }}>
                {props.title}
              </label>
            </div>
        </div>
    );
  };
  
  export const DropDownField = memo(
    ({ name, options, multi, selectionLimit }) => {
    //   const { values, setValues } = useContext(FormContext);
    const  values = useFormContext();
    const  setValues = useFormActionContext().setValues;

      const value = values[name];
    
      // console.log(name, value);
      return (
          <SingleSelectDropDownControl  
          options={options}  title={name} value={value} multi={multi}
          handleChange={e => {
            if (multi && e.length >= selectionLimit && values[name].length >= selectionLimit ) {
              return
            }
            // e.persist();
            setValues(prev => ({
              ...prev,
              [name]: e
            }));
          }}
          />
      )
    },
    (prev, next) => prev.value === next.value
  );
  
/*
export const TextField = memo(
  ({ name }) => {
    const { values, setValues } = useContext(FormContext);

    const value = values[name];
  
    console.log(name);
    return (
      <input
        type="text"
        value={value}
        onChange={e => {
          e.persist();
          setValues(prev => ({
            ...prev,
            [name]: e.target.value
          }));
        }}
      />
    );
  },
  (prev, next) => prev.value === next.value
);


const Field = ({ name }) => {
  const { values, setValues } = useContext(FormContext);

  const value = values[name];

  return <TextField name={name} value={value} setValues={setValues} />;
};

const App = () => (
  <FormProvider initialValues={{ firstName: "Marr", lastName: "Keri" }}>
    First name: <Field name="firstName" />
    <br />
    Last name: <Field name="lastName" />
  </FormProvider>
);

export default App;
*/