import React, { Fragment, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { SingleSelectDropDownControl } from "../controls/multiselect_control";
import { Form, Button, Modal, ListGroup, Badge } from "react-bootstrap";
import { getActiveSites, readFileData, getSiteDIDs, deleteDeviceIds } from "../../utils/apiCall";
import { GetFQURLs } from "../model/store.swr";
import { useCriteriaContext } from "../../context/criteria.context";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import { InfoStyle } from "../sitemaintenance";
import { getUserPortfolios } from "../auth/userinfo";
import { CSVLink } from "react-csv";
import ReactTooltip from "react-tooltip";
import { RiDeleteBin7Line } from "react-icons/ri";

const Heading = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .add-button {
    background: #014059;
    font-size: 16px;

    :active,
    :hover,
    :focus {
      background: #014059 !important;
    }
  }

  .section {
    flex-direction: row;
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .timezone {
    font-size: 12px;
    color: #8693ab;
  }
`;



const SiteDataTable = (props) => {
  const { siteData, siteId, selected, setSelected, handleRecordDelete } = props.data
  const styles = {
    wrapText: {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      maxWidth: '200px', /* Set a maximum width for the column */
    }
  }

  const options = {
    // pageStartIndex: 0,
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };


  // const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return (
  //     <>
  //       <span data-tip data-for='delete-report' onClick={() => handleRecordDelete([row.device_id], row.site_id)}>
  //         <RiDeleteBin7Line size={20} style={{color: '#01668D', strokeWidth: '1px', margin: '10px'}}/>
  //       </span>
  //       <ReactTooltip textColor='#014059' backgroundColor='#B7D0DA' className='sidebar-tooltip' id='delete-report' place="top" type="light" effect="solid" >
  //         <span>Delete record</span>
  //       </ReactTooltip>
  //     </>
  //   );
  // };

  const columns = [{
    dataField: 'site_id',
    text: 'Site ID',
    headerStyle: { width: '15%' },
    style: styles.wrapText
  }, {
    dataField: 'zone_id',
    text: 'Zone ID',
    headerStyle: { width: '15%' }
  },
  {
    dataField: 'tracker_id',
    text: 'Tracker ID',
    headerStyle: { width: '15%' }
  },
  {
    dataField: 'device_id',
    text: 'Device ID',
    headerStyle: { width: '15%' },
    style: styles.wrapText
  }
  ]



  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    headerColumnStyle: {
      width: '5%'
    },
    selectColumnStyle: {
      width: '5px'
    },
    onSelect: (row, isSelect) => handleOnSelect(row, isSelect),
    onSelectAll: (isSelect, rows) => handleOnSelectAll(isSelect, rows),
    selected: selected
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected([...selected, row.device_id])
    } else {
      setSelected(selected.filter(id => id !== row.device_id))
    }
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.device_id);
    if (isSelect) {
      setSelected(ids)
    } else {
      setSelected([])
    }
  }

  console.log("selected....", siteData)

  return <div className="report-table">
    <BootstrapTable selectRow={selectRow} keyField='device_id' columns={columns} data={siteData} pagination={paginationFactory(options)} />
  </div>
}

export const ImportData = () => {
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState({});
  const [siteData, setSiteData] = useState([]);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false)
  const criteria = useCriteriaContext();
  const [btnTxt, setBtnTxt] = useState('Import')
  const [deleteBtnTxt, setDeleteBtnTxt] = useState('')
  const hiddenFileInput = useRef(null)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    const pf = getUserPortfolios()
    const fetchSites = async () => {
      const urls = [{ name: "acitve-sites", url: "meta_active_sites" }];
      const u = GetFQURLs(urls, criteria, "json", null, null, null, {}, { portfolioId: pf ? pf : '' });
      await getActiveSites(u[0].url, setSites, setError);
    };

    fetchSites();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError("")
      setSuccessMsg("")
    }, 5000)
  }, [error, successMsg])

  console.log("site is++++", site);

  const handleSubmit = async () => {
    setBtnTxt("Loading...")
    if (site && !site.value) {
      setError("Site is required")
      setBtnTxt("Import")
      return
    } else if (!file) {
      setError("File is required")
      setBtnTxt("Import")
      return
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("site_id", site.value);
    await readFileData(formData, setSuccessMsg, setError);
    // setSite({})
    // setFile(null)
    // hiddenFileInput.current.value = ''
    setBtnTxt("Import")
    getSiteData(site.value)
  };

  const handleRecordDelete = async (deviceIds, siteId) => {
    setDeleteBtnTxt("Deleting...")
    await deleteDeviceIds({ siteId: siteId, deviceIds: JSON.stringify(deviceIds) }, setSuccessMsg, setError)
    setSelected([])
    getSiteData(siteId)
    setDeleteBtnTxt('')
  }

  const handleFileUpload = () => {
    hiddenFileInput.current.click()
  }

  const getSiteData = async (siteId) => {
    setLoading(true)
    const urls = [{ url: 'meta_site_data' }]
    const u = GetFQURLs(
      urls,
      criteria,
      "json",
      null,
      null,
      null,
      {},
      { siteId: siteId }
    );
    await getSiteDIDs(u[0].url, setSiteData, setError)
    setLoading(false)
  }

  console.log("sites+++++",sites)
  return (
    <Fragment>
      {successMsg ? <InfoStyle type={'success'}> {successMsg} </InfoStyle> :
        error ? <InfoStyle type={''}> {error} </InfoStyle> : null
      }
      <Heading>
        <h3>Import DID's</h3>
      </Heading>
      <div className="row" style={{ position: 'relative', left: '5px' }}>
        <div className="col-3 site-dropdown">
          <Form.Label>Select Site</Form.Label>
          <div style={{position: 'relative', zIndex: '9999'}}>
            <SingleSelectDropDownControl
              value={site}
              handleChange={(val) => { setSite(val); setSiteData([]); getSiteData(val.value); setFile(null) }}
              options={sites.sort((a, b) => a.label.localeCompare(b.label))}
              multi={false}
            />
          </div>
        </div>
        <div className="file-input">
          {/* <Form.Label>Upload file</Form.Label> */}
          <Button
            onClick={handleFileUpload}
            className="btn-secondary import-btn"
            active
            color="secondary"
          >
            Upload DID Sheet
          </Button>
          <p style={{ marginTop: '30px' }}>{file && file.name}</p>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={hiddenFileInput}
            accept=".xls,.xlsx"
            // className="file"
            onChange={(event) => setFile(event.target.files[0])}
          />
        </div>
        <div className="col-4">
          <Button
            onClick={handleSubmit}
            className="btn-secondary import-btn"
            active
            color="secondary"
            disabled={btnTxt !== 'Import' ? true : false}
          >
            {btnTxt}
          </Button>
          {
            siteData && siteData.length ? (
              <Button
                onClick={() => handleRecordDelete(selected, site.value)}
                className="btn-secondary import-btn delete-btn"
                active
                color="secondary"
                disabled={deleteBtnTxt === '' ? false : true}
              >
                {deleteBtnTxt !== '' ? deleteBtnTxt : `Delete ${selected.length ? selected.length == 1 ? `${selected.length} Record` : `${selected.length} Records` : 'All Records'} `}
              </Button>
            ) : null
          }
        </div>


      </div>
      {
        site && site.value ? (
          <div className="row" style={{ position: 'relative', top: '30px' }}>
            <Heading>
              <h5>DID's for {site.value}</h5>
              {
                siteData.length ? (
                  <CSVLink filename={`${site.value}_data`} data={siteData} style={{ position: 'relative', top: '-5px', left: '10%' }}>
                    <img src="../assets/img/icon/icon-download.svg" alt="Download" width="16" height="16" className="chart-download" />
                  </CSVLink>
                ) : null
              }

            </Heading>
            {siteData.length ? <SiteDataTable data={{ siteData: siteData, siteId: site.value, selected: selected, setSelected: setSelected }} /> : <h5 style={{ position: 'absolute', top: '35vh', left: '35vw' }}>{loading ? 'Fetching data...' : 'No Data Available'}</h5>}
          </div>
        ) : null
      }

      <style>
        {`
            .file-input {
              position: relative;
              overflow: hidden;
              display: inline-block;
              text-algin: center;
            }

            .file-input .file {
              position: absolute;
              top: 25px;
              right: 0;
              margin: 0;
              padding: 0;
              font-size: 100px;
              cursor: pointer;
              opacity: 0;
              height: 100%;
            }
            
            .import-btn{
              position: relative;
              top: 25px;
            }
            
            .import-btn.delete-btn{
              margin-left: 10px
            }
            
            .site-dropdown .css-t6do6-control{
              height: 40px !important;
            }
            
            .btn-secondary:disabled{
              background-color: #01668D !important;
              border-color: #01668D !important;
            }
          `}
      </style>
    </Fragment>
  );
};
