import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import React from "react";
import { getAllErrorCodeDesc, parseErrorCode } from "../components/model/utils";
import withData from "../model/combined";
import { axisStyle } from "./components/common";

const defaultOption = {
    grid: [
        { left: '5%', width: '85%', bottom: "15%", top: "10%"},
        //{ left: '53%', width: '42%', bottom: "5%", top: "5%" },
        //{left: '4%', top: '10%', width: '38%', height: '38%'},
        //{right: '7%', top: '7%', width: '38%', height: '38%'},
        //{left: '7%', bottom: '7%', width: '38%', height: '38%'},
        //{right: '7%', bottom: '7%', width: '38%', height: '38%'}
    ],
    xAxis: [
        axisStyle("Datetime", -40),
        //axisStyle("", -40, 1)
    ],
    yAxis: [
        axisStyle("Devices", 0, "end"),

        //axisStyle("Uptime %", 30, 1)
    ],
    legend: {},
    toolbox: {
        show: false,
        orient: "horizontal",
        left: "76%", bottom: "92%",
        iconStyle: { borderColor: 'rgba(228, 228, 228, 0.8)' },
        //emphasis: {iconStyle: {borderColor: 'rgba(28, 28, 228, 0.8)'}},
        feature: {
            dataView: { readOnly: false },
            //magicType: { type: ["line", "bar", 'stack'] },
            restore: {},
            saveAsImage: {}
        }
    },
    axisPointer: {
        link: {xAxisIndex: 'all'},
        label: {
            color: '#333',
            backgroundColor: '#eee'
        }
    },

    tooltip: {
        /*
        formatter:  function (param) {
           // console.log(Ttformatter(param))
            //console.log("......")
            return Ttformatter(param) // || ''; //param.name + '<br>' + (param.data.coord || '');
        }*/
        confine: false,
        //trigger: 'axis',
        axisPointer: { type: 'cross', snap: true },
        /*
        position: function (pos, params, el, elRect, size) {
            var obj = { top: 40, bottom: 80 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
        },*/
        backgroundColor: 'rgba(255,255,205,0.9)',
        extraCssText: 'width: 170px'

    },
    dataZoom: [
        { type: 'inside', start: 0, end: 100 },
        { type: 'slider', xAxisIndex: 0, show: true, top: '95%', left: '10%', right: '20%', width: '80%', height: '3%', start: 0, end: 100 },
        // { type: 'slider', yAxisIndex: 0, show: true, filterMode: 'empty', width: 12, height: '70%', handleSize: 8, showDataShadow: false,left: '93%' }

    ],
}

function getNonZeroIndex(arr){
    const idxs = _.zipWith(...arr, (...args) => _.sum(args)).map((e,i) => e > 0? i: -1).filter(f => f > -1);
    return idxs

}

function getZeroIndex(arr){
    const idxs = _.zipWith(...arr, (...args) => _.sum(args)).map((e,i) => e === 0? i: -1).filter(f => f > -1);
    return idxs

}

function removeZeros(arr, idxs){
    if (idxs === 0 ) return arr
    // var idxsRev = idxs.slice().reverse()
    var mutable = [...arr]
    _.pullAt(mutable, idxs)        
    return mutable

}

function removeZeros2D(arr, idxs){
    return arr.map((r, i) => {
        return removeZeros(r, idxs)
    })

}
function cleanData(errdata) {
    const idxs = getZeroIndex(errdata.values);

    const values = removeZeros2D(errdata.values, idxs)
    const columns = removeZeros(errdata.columns, idxs)
    const indexes = Object.assign({}, ...columns.map((p, i) => ({ [p]: i })));

    return {values: values, columns: columns, indexes: indexes}

}
function stripEndComma(s){
    return s.replace(/,\s*$/, "")
}
function transform(errdata) {
    const ts = []
    const counts = {}
    const errorObj = {}
    const errorDevices = {}
    const ignoreCols = ['ts', 'devices']
    const allErrorDesc = getAllErrorCodeDesc()
    const errorCodes = ignoreCols.concat(Object.keys(allErrorDesc).map(e=>allErrorDesc[e]))
    errorCodes.forEach(k => {
        const errorCode = `${k}`
        counts[errorCode] = []
        errorObj[errorCode] = 0
        errorDevices[errorCode] = ''
    })
        
    // console.log(counts, errorObj)
    //const counts = {1: [], 2: [], 3: []};
    const iTimestamp = errdata.indexes['timestamp']
    const res = []
    errdata.values.map(tsr => {
        // const obj = Object.assign({}, {1: 0, 2: 0, 3: 0});
        const obj = Object.assign({}, errorObj)
        const devices = Object.assign({}, errorDevices)
        const arr = [tsr[iTimestamp]] // add the datetime / time X axis data 
        tsr.map((v,i) => {
            if (i !== iTimestamp && v > 0){
                const ecodedesc = parseErrorCode(v)
                //console.log(v, ecodedesc)
                Object.keys(ecodedesc).forEach(ec => {
                    const ecode = ecodedesc[ec]
                    obj[ecode] += 1
                    devices[ecode] += `"${errdata.columns[i]}", ` // + ', '
                })
                //obj[v] += 1
                //devices[v] += `"${errdata.columns[i]}", `
            }
        })
        //console.log(devices)
        const d = Object.keys(devices).reduce((p, o) => {
             p += (devices[o].length > 0)? `"${o}": [${stripEndComma(devices[o])}], `:''
             return p
        }, '' )
        arr.push(`{${stripEndComma(d)}}`) // set the devices for tooltip
        Object.keys(obj).map(o => arr.push(obj[o])) // set the y axis series values 
        res.push(arr) // add the timestamp data to result

        // ts.push(tsr[iTimestamp])
        // Object.keys(obj).map(o => counts[o].push(obj[o]))
        // counts['ts'].push(tsr[iTimestamp])
    })
    // const idxs = _.zipWith(...res, (...args) => _.sum(args)).map((e,i) => e > 0? i: -1).filter(f => f > -1);
    const idxs = getZeroIndex(res)
    const myres = removeZeros2D(res, idxs)
    // console.log('888888888888888 ', Object.keys(counts))
    const codes = removeZeros(errorCodes, idxs)
    // const myres = res.map(r => {
    //     return _.pullAt(r, idxs)
    
    // } )

    
    

    // console.log("Summary ", idxs, myres, codes)
    // return {ts: ts, counts: counts, result: res, codes: errorCodes}
    return {ts: ts, counts: counts, result: myres, codes: codes, ignore: ignoreCols}
}

function makeOptions(alldata, args, criteria) {
    // const summary = args? args.summary : false
    // console.log("errors devices", alldata)
    //console.log("charting ", pa, eam)
    if (!alldata || !alldata.errors) return defaultOption

    const errors = alldata.errors.values.length > 0 ? alldata.errors.values : []
    // console.log(":Cleaned data", cleanData(alldata.errors))
    //console.log("alldata", pa, eam, dfd)
    const iTimestamp = alldata.errors.indexes['timestamp']
    const trdata = transform(alldata.errors)
    // console.log(trdata)

    const option = {
        //legend: { type: 'scroll', top: '5%', height: '90%', right: 0, orient: 'vertical', data: legenddata, selector: true, textStyle: { color: "#eee"} },
        dataset: {
            source: trdata.result
        },
        legend: { type: 'scroll', top: '5%', height: '90%', right: 0, orient: 'vertical', /*data: legenddata, selector: true,*/ textStyle: { color: "#eee"} },
        xAxis: [
            {type: 'time', nameLocation: 'middle' }
            // { data: trdata.ts, type: 'time', nameLocation: 'middle' },
            /*
            {
                type: 'category',
                data: trdata.ts,
                scale: true,
                boundaryGap : false,
                axisLine: {onZero: false},
                splitLine: {show: false},
                splitNumber: 20,
                min: 'dataMin',
                max: 'dataMax',
                axisPointer: {
                    z: 100
                },
            }*/
            //{ data: xAxisData, gridIndex: 1, nameLocation: 'middle', type: 'time' }
        ],
        yAxis: [
            {
                name: 'Devices Count ', 
                scale: true, nameLocation: 'middle', nameGap: 30,
                splitLine: {lineStyle: {width: 1, color: "rgba(127,127,127,0.5"}},
                nameTextStyle: { align: 'left', fontWeight: 'bolder', fontSize: 12, color: '#eee' }
            },
            /*{
                name: 'RCs Tracking normally ', nameLocation: 'middle', nameGap: 30,
                splitLine: {lineStyle: {width: 1, color: "rgba(127,127,127,0.5"}},
                nameTextStyle: { align: 'left', fontWeight: 'bolder', fontSize: 12, color: '#eee' },
                gridIndex: 1
            }*/],
        series: trdata.codes.filter(f=> !trdata.ignore.includes(f)).map((e, i) => {
                return {
                    name: e,
                    type: 'line',
                    smooth: true,
                    stack: true,
                    areaStyle: {},
                    encode: {
                        x: 0,      // Dimension 3, 1, 5 is mapped to x axis.
                        y: i+trdata.ignore.length,              // Dimension 2 is mapped to y axis.
                        tooltip: [1] // Dimension 3, 2, 4 will be displayed in tooltip.
                    }
                }
            }),
        /*series: Object.keys(trdata.counts).map(e=> {
            return {
                data: trdata.counts[e], 
                name: e,
                type: 'line',
                smooth: true,
                stack: true,
                areaStyle: {},
                emphasis: {
                    // focus: 'series'
                },
            }
        }),*/
        
        tooltip: {
            trigger: "axis",
            confine: true,
            //trigger: 'axis',
            axisPointer: { type: 'cross', snap: true },
            
            position: function (pos, params, el, elRect, size) {
                var obj = { top: 40, bottom: 80 };
                obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                return obj;
            },
            //backgroundColor: 'rgba(255,255,205,0.9)',
            extraCssText: 'width: 370px',
            textStyle: { overflow: 'break'},
            formatter: (params) => {
                
                //console.log(params)

                if (params.length === 0) return ''

                // const tsdata = alldata.errors.values.filter(f => f.timestamp === params[0].name )[0]
                return params.map((p,i) => {
                    if (i>0) return ''
                    //console.log(p)
                    const errorCode = parseInt(p.seriesName)
                    //console.log(p.value)
                    const ttdevices = JSON.parse(p.value[1])
                    var tt = `<h5>${p.value[0]}</h5>`
                    tt +=  Object.keys(ttdevices).reduce((p, code) => {
                        const pd = ttdevices[code].slice(0,5).reduce((p2,v2) => p2 += ` ${v2},`)
                        p += `<h6><b>${code}-${ttdevices[code].length}</b></h6><p style="display:block;overflow-wrap: break-word;max-width:120px;">${pd}</p>` 
                        return p
                    },'')
                    //style="display:block;word-wrap:break-word;width:350px;text-overflow: ellipsis"
                    //console.log(tt)
                    return tt
                    // const devices = Object.keys(tsdata).filter(e =>  e !== 'timestamp' && tsdata[e]=== errorCode).slice(0,2).map(e=> e)
                    // return (devices.length >0)? `${errorCode} <br/>${devices.join('<br/>')}`: ''
                }
                ).join('<br/>')
                
            //     const errorCode = parseInt(params.seriesName)
            //     // console.log(errorCode , params)
            //     const a = alldata.filter(f => f.timestamp === params.name )[0]
            //     const devices = Object.keys(a).map(e=> (e !== 'timestamp' && a[e]=== errorCode? `${e}<br/>`: '')).join('')
            //     // console.log(devices)
            //     return devices
            //     //console.log( params[0].seriesName, params[0].axisIndex) 
            //   return `
            //             Tooltip: <br />
            //             ${tr.devices[params[0].seriesName]}: ${tr.devices[params[0].seriesName]}<br />
                        
            //             `;
                        
            },
        },
    
        
    };
    // console.log(option)
    return _.merge({}, defaultOption, option);
}

const ErrorsChartBase = (props) => {

    //console.log(defaultOption)
    return (
        <ReactEcharts
            option={props.data}
            // style={{ height: "80vh", left: 50, top: 50, width: "90vw" }}
            style={{ height: "40vh", left: -20, top: 0 }}
        //   opts={{ renderer: "svg" }}

        />

    )
}

const keyf = function (o) { return ['site_id', 'zone_id', 'device_id', 'timestamp'] } //{ return o.site_id + '-' + o.zone_id}
const urls = [
    { name: "errors", url: "metric_errorCode", slice: 10 },
]


const comps1 = { 
    title: 'ErrorsSummary ', 
    chart: ErrorsChartBase, 
    urls: urls, 
    default: defaultOption, 
    parser: {f: makeOptions, args: { summary: false} } 
}
const ErrorSummaryChart = withData({ ...comps1 }) //withData(PerfChartBase, urls)

// const ErrorSummaryChart = () => { return ""}
export default ErrorSummaryChart;

