import React from 'react';
import styled from 'styled-components'
import ChangePassword from  "../../components/auth/password";
import {getUserEmail} from '../../components/auth/userinfo';


const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  padding-top: -5vh;
`;

const ContentWrapper = styled.div`
  text-align: center; // Center text horizontally
`;

const emailOfUser = getUserEmail();

const ChangePassword1 = () => {
    const handleSubmitPasswordChange = (formData) => {
        // Handle password change form submission here
        console.log("Submitted password change form with data:", formData);
    };


    return (
        <Container>
        <div>
            <div>
            <ContentWrapper>
                <h1>Reset Account Password</h1>
                <p>Enter a new password for {emailOfUser} </p>
            </ContentWrapper>
                <ChangePassword onSubmit={handleSubmitPasswordChange} />
            </div>
        </div>
        </Container>
    );
    }


export default ChangePassword1;